import {
  GET_SUBTOPICS,
  GET_SUBTOPICS_SUCCESS,
  GET_SUBTOPICS_ERROR,
  POST_SUBTOPIC,
  POST_SUBTOPIC_SUCCESS,
  POST_SUBTOPIC_ERROR,
  PUT_SUBTOPIC,
  PUT_SUBTOPIC_SUCCESS,
  PUT_SUBTOPIC_ERROR,
  DELETE_SUBTOPIC,
  DELETE_SUBTOPIC_SUCCESS,
  DELETE_SUBTOPIC_ERROR,
  PATCH_MOVE_SUBTOPIC,
  PATCH_MOVE_SUBTOPIC_SUCCESS,
  PATCH_MOVE_SUBTOPIC_ERROR
} from './actionTypes';

const doGetSubtopics = topicUuid => ({
  type: GET_SUBTOPICS,
  payload: {
    topicUuid
  }
});

const doGetSubtopicsSuccess = data => ({
  type: GET_SUBTOPICS_SUCCESS,
  payload: {
    subtopics: data.subtopics
  }
});

const doGetSubtopicsError = error => ({
  type: GET_SUBTOPICS_ERROR,
  payload: {
    error
  }
});

const doPostSubtopic = newSubtopic => ({
  type: POST_SUBTOPIC,
  payload: {
    newSubtopic
  }
});

const doPostSubtopicSuccess = data => ({
  type: POST_SUBTOPIC_SUCCESS,
  payload: {
    createdSubtopic: data.createdSubtopic
  }
});

const doPostSubtopicError = error => ({
  type: POST_SUBTOPIC_ERROR,
  payload: {
    error
  }
});

const doPutSubtopic = subtopicUpdates => ({
  type: PUT_SUBTOPIC,
  payload: {
    subtopicUpdates
  }
});

const doPutSubtopicSuccess = data => ({
  type: PUT_SUBTOPIC_SUCCESS,
  payload: {
    updatedSubtopic: data.updatedSubtopic,
    updatedMicros: data.updatedMicros
  }
});

const doPutSubtopicError = error => ({
  type: PUT_SUBTOPIC_ERROR,
  payload: {
    error
  }
});

const doPatchMoveSubtopic = (uuid, destinationCodeUuid, moveAbove) => ({
  type: PATCH_MOVE_SUBTOPIC,
  payload: {
    uuid,
    destinationCodeUuid,
    moveAbove
  }
});

const doPatchMoveSubtopicSuccess = data => ({
  type: PATCH_MOVE_SUBTOPIC_SUCCESS,
  payload: {
    movedSubtopic: data.movedSubtopic,
    updatedSubtopics: data.updatedSubtopics
  }
});

const doPatchMoveSubtopicError = error => ({
  type: PATCH_MOVE_SUBTOPIC_ERROR,
  payload: {
    error
  }
});

const doDeleteSubtopic = subtopicUuid => ({
  type: DELETE_SUBTOPIC,
  payload: {
    subtopicUuid
  }
});

const doDeleteSubtopicSuccess = data => ({
  type: DELETE_SUBTOPIC_SUCCESS,
  payload: {
    deletedSubtopic: data.deletedSubtopic,
    updatedSubtopics: data.updatedSubtopics
  }
});

const doDeleteSubtopicError = error => ({
  type: DELETE_SUBTOPIC_ERROR,
  payload: {
    error
  }
});

export {
  doGetSubtopics,
  doGetSubtopicsSuccess,
  doGetSubtopicsError,
  doPostSubtopic,
  doPostSubtopicSuccess,
  doPostSubtopicError,
  doPutSubtopic,
  doPutSubtopicSuccess,
  doPutSubtopicError,
  doPatchMoveSubtopic,
  doPatchMoveSubtopicSuccess,
  doPatchMoveSubtopicError,
  doDeleteSubtopic,
  doDeleteSubtopicSuccess,
  doDeleteSubtopicError
};
