import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { doGeneralErrorNotification } from '../../../redux/actions/notificationActions';

import { doGetAccreditationAgencies } from '../../../redux/actions/accreditationAgencyActions';
import {
  institutionalAccreditationAgenciesSelector,
  programAccreditationAgenciesSelector
} from '../../../redux/selectors/accreditationAgenciesSelectors';
import AccreditationAgencyDashboard from './AccreditationAgencyDashboard';

class AccreditationAgencyDashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newAccreditationAgency: null,
      showSelectAgencyType: false
    };

    this.clearNewAccreditationAgency = this.clearNewAccreditationAgency.bind(
      this
    );
    this.addAccreditationAgency = this.addAccreditationAgency.bind(this);
    this.showSelectAgencyTypeOptions = this.showSelectAgencyTypeOptions.bind(
      this
    );
    this.hideSelectAgencyTypeOptions = this.hideSelectAgencyTypeOptions.bind(
      this
    );
    this.addInstitutionAgency = this.addInstitutionAgency.bind(this);
    this.addProgramAgency = this.addProgramAgency.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.onFetchDispatch();
  }

  clearNewAccreditationAgency = () => {
    this.setState({ newAccreditationAgency: null });
  };

  addAccreditationAgency = agencyType => {
    const { newAccreditationAgency } = this.state;

    if (newAccreditationAgency) {
      this.props.onGeneralErrorNotification(
        'Cannot create more than one agency simultaneously'
      );
      return;
    }

    const emptyAgency = {
      agencyType,
      name: '',
      isNew: true
    };

    this.setState({
      newAccreditationAgency: emptyAgency
    });
  };

  addInstitutionAgency = () => {
    this.addAccreditationAgency('INSTITUTION');
    this.hideSelectAgencyTypeOptions();
  };

  addProgramAgency = () => {
    this.addAccreditationAgency('PROGRAM');
    this.hideSelectAgencyTypeOptions();
  };

  showSelectAgencyTypeOptions = () => {
    this.setState({ showSelectAgencyType: true });
  };

  hideSelectAgencyTypeOptions = () => {
    this.setState({ showSelectAgencyType: false });
  };

  render() {
    const { institutionAgencies, programAgencies } = this.props;
    const { newAccreditationAgency, showSelectAgencyType } = this.state;

    return (
      <AccreditationAgencyDashboard
        showSelectAgencyType={showSelectAgencyType}
        institutionAgencies={institutionAgencies}
        programAgencies={programAgencies}
        newAccreditationAgency={newAccreditationAgency}
        showSelectAgencyTypeOptions={this.showSelectAgencyTypeOptions}
        hideSelectAgencyTypeOptions={this.hideSelectAgencyTypeOptions}
        addAccreditationAgency={this.addAccreditationAgency}
        clearNewAccreditationAgency={this.clearNewAccreditationAgency}
        addInstitutionAgency={this.addInstitutionAgency}
        addProgramAgency={this.addProgramAgency}
      />
    );
  }
}

AccreditationAgencyDashboardContainer.propTypes = {
  institutionAgencies: PropTypes.arrayOf(PropTypes.object),
  programAgencies: PropTypes.arrayOf(PropTypes.object),
  onFetchDispatch: PropTypes.func,
  onGeneralErrorNotification: PropTypes.func
};

AccreditationAgencyDashboardContainer.defaultProps = {
  institutionAgencies: [],
  programAgencies: [],
  onFetchDispatch: undefined,
  onGeneralErrorNotification: undefined
};

const mapStateToProps = state => ({
  institutionAgencies: institutionalAccreditationAgenciesSelector(state),
  programAgencies: programAccreditationAgenciesSelector(state)
});

const mapDispatchToProps = dispatch => ({
  onFetchDispatch: () => dispatch(doGetAccreditationAgencies()),
  onGeneralErrorNotification: errorMessage =>
    dispatch(doGeneralErrorNotification(errorMessage))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccreditationAgencyDashboardContainer);
export { AccreditationAgencyDashboardContainer };
