import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';
import { sortByProperty } from '../../helpers/utilities';

export const ormSelector = state => state.orm;

// export const allProgramDisciplinesSelector = createSelector(
//   ormSelector,
//   ormCreateSelector(orm, session =>
//     session.ProgramDiscipline.all()
//       .toModelArray()
//       .map(disc => Object.assign({}, disc.ref))
//   )
// );
export const allProgramDisciplinesSelector = createSelector(
  state => state,
  redux => {
    const selectorORM = ormCreateSelector([orm], session =>
      session.ProgramDiscipline.all()
        .toModelArray()
        .map(disc => Object.assign({}, disc.ref))
    );
    return selectorORM(redux);
  }
);

export const getCipcodeUuid = (state, props) => props.cipcode.uuid;

// export const makeProgramCipDisciplinesSelector = () =>
//   createSelector(
//     ormSelector,
//     getCipcodeUuid,
//     ormCreateSelector(orm, (session, cipcodeUuid) =>
//       session.ProgramDiscipline.all()
//         .filter(disc => disc.cipcodeUuid === cipcodeUuid)
//         .toModelArray()
//         .map(disc => Object.assign({}, disc.ref))
//         .sort((discA, discB) => sortByProperty(discA, discB, 'code'))
//     )
//   );

export const makeProgramCipDisciplinesSelector = () =>
  createSelector(
    state => state,
    getCipcodeUuid,
    (redux, cipcodeUuid) => {
      const selectorORM = ormCreateSelector([orm], session =>
        session.ProgramDiscipline.all()
          .filter(disc => disc.cipcodeUuid === cipcodeUuid)
          .toModelArray()
          .map(disc => Object.assign({}, disc.ref))
          .sort((discA, discB) => sortByProperty(discA, discB, 'code'))
      );
      return selectorORM(redux);
    }
  );
