import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  doPutStateProvince,
  doDeleteStateProvince
} from '../../../../redux/actions/locationActions';
import StateProvince from './StateProvince';

class StateProvinceContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: '',
      isPublishModalOpen: false,
      isDeleteModalOpen: false
    };

    this.onPublish = this.onPublish.bind(this);
    this.handlePublishModalOpen = this.handlePublishModalOpen.bind(this);
    this.handlePublishModalClose = this.handlePublishModalClose.bind(this);
  }

  toggleEditView = () => {
    this.setState(prevState => ({
      view: prevState.view !== 'EDIT' ? 'EDIT' : ''
    }));
  };

  handlePublishModalOpen = () => {
    this.setState({
      isPublishModalOpen: true
    });
  };

  handlePublishModalClose = () => {
    this.setState({
      isPublishModalOpen: false
    });
  };

  onPublish = () => {
    const { stateProvince } = this.props;
    const payload = {
      ...stateProvince,
      published: true
    };
    this.props.onPostUpdateStateProvince(payload);
    this.handlePublishModalClose();
  };

  handleDeleteClick = () => {
    this.setState({
      isDeleteModalOpen: true
    });
  };

  handleDeleteModalClose = () => {
    this.setState({
      isDeleteModalOpen: false
    });
  };

  handleDelete = () => {
    const { stateProvince } = this.props;

    this.props.onDelete(stateProvince.uuid);
    this.handleDeleteModalClose();
  };

  render() {
    const { stateProvince, country, className } = this.props;
    const { view, isPublishModalOpen, isDeleteModalOpen } = this.state;

    return (
      <StateProvince
        view={view}
        stateProvince={stateProvince}
        parentCountry={country}
        className={className}
        isPublishModalOpen={isPublishModalOpen}
        isDeleteModalOpen={isDeleteModalOpen}
        onPublish={this.onPublish}
        handlePublishModalOpen={this.handlePublishModalOpen}
        handlePublishModalClose={this.handlePublishModalClose}
        handleDeleteClick={this.handleDeleteClick}
        handleDeleteModalClose={this.handleDeleteModalClose}
        handleDelete={this.handleDelete}
        toggleEditView={this.toggleEditView}
      />
    );
  }
}

StateProvinceContainer.propTypes = {
  stateProvince: PropTypes.object,
  country: PropTypes.object,
  className: PropTypes.string,
  onPostUpdateStateProvince: PropTypes.func,
  onDelete: PropTypes.func
};

StateProvinceContainer.defaultProps = {
  stateProvince: {},
  country: {},
  className: '',
  onPostUpdateStateProvince: undefined,
  onDelete: undefined
};

const mapDispatchToProps = dispatch => ({
  onPostUpdateStateProvince: stateProvinceUpdates =>
    dispatch(doPutStateProvince(stateProvinceUpdates)),
  onDelete: countryUuid => dispatch(doDeleteStateProvince(countryUuid))
});

export default connect(
  undefined,
  mapDispatchToProps
)(StateProvinceContainer);
