import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EditMicrocompetency from './EditMicrocompetency';
import { doPutMicrocompetency } from '../../../../../redux/actions/microcompetencyActions';
import {
  validateCodeTitle,
  validateCodeDescription
} from '../../../../../helpers/validateCodes';
import { allFieldsAreValid } from '../../../../../helpers/validateGeneric';

class EditMicrocompetencyContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      level_one_code: '',
      level_two_code: '',
      title: '',
      description: '',
      subtopicUuid: '',
      showValidationErrors: false
    };

    this.setExistingMicro = this.setExistingMicro.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { level_one_code, level_two_code } = this.state;
    const { microcompetency } = this.props;
    this._isMounted = true;
    if (!level_one_code || !level_two_code) {
      this.setExistingMicro(microcompetency);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  setExistingMicro = microcompetency => {
    const { ...existingMicro } = microcompetency;
    if (this._isMounted) {
      this.setState(() => ({
        ...existingMicro
      }));
    }
  };

  setValidationErrors = existingMicro => {
    const titleError = validateCodeTitle(existingMicro.title);
    const descriptionError = validateCodeDescription(existingMicro.description);
    const newValidationErrors = {
      title: titleError,
      description: descriptionError
    };

    return newValidationErrors;
  };

  handleMicrocompetencyUpdate = () => {
    const { showValidationErrors, ...existingMicro } = this.state;
    const newValidationErrors = this.setValidationErrors(existingMicro);

    if (allFieldsAreValid(newValidationErrors)) {
      const payload = {
        uuid: existingMicro.uuid,
        title: existingMicro.title,
        description: existingMicro.description
      };
      this.props.onPostUpdateMicrocompetency(payload);
      this.setState({ showValidationErrors: false });
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const {
      level_one_code,
      level_two_code,
      title,
      description,
      subtopicUuid,
      showValidationErrors
    } = this.state;
    const { cip, discipline, topic, subtopic, toggleEditView } = this.props;

    const thisMicro = {
      level_one_code,
      level_two_code,
      title,
      description,
      subtopicUuid
    };

    return (
      <EditMicrocompetency
        existingMicro={thisMicro}
        cip={cip}
        discipline={discipline}
        topic={topic}
        subtopic={subtopic}
        handleChange={this.handleChange}
        onPostUpdateMicrocompetency={this.handleMicrocompetencyUpdate}
        showValidationErrors={showValidationErrors}
        toggleEditView={toggleEditView}
      />
    );
  }
}

EditMicrocompetencyContainer.propTypes = {
  microcompetency: PropTypes.object,
  cip: PropTypes.object,
  discipline: PropTypes.object,
  topic: PropTypes.object,
  subtopic: PropTypes.object,
  toggleEditView: PropTypes.func,
  onPostUpdateMicrocompetency: PropTypes.func
};
EditMicrocompetencyContainer.defaultProps = {
  microcompetency: {},
  cip: {},
  discipline: {},
  topic: {},
  subtopic: {},
  toggleEditView: undefined,
  onPostUpdateMicrocompetency: undefined
};

const mapDispatchToProps = dispatch => ({
  onPostUpdateMicrocompetency: microUpdates =>
    dispatch(doPutMicrocompetency(microUpdates))
});

export default connect(
  undefined,
  mapDispatchToProps
)(EditMicrocompetencyContainer);
export { EditMicrocompetencyContainer };
export { EditMicrocompetency };
