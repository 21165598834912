/* eslint no-unused-vars: off */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import NewInstitution from './NewInstitution';
import { doPostInstitution } from '../../../../../redux/actions/institutionActions';
import { makeGetSystemStateProvinceSelector } from '../../../../../redux/selectors/locationsSelectors';
import { makeGetInstitutionsInstitutionalSystemSelector } from '../../../../../redux/selectors/institutionalSystemsSelectors';
import { makeSystemInstitutionSubtypeSelector } from '../../../../../redux/selectors/institutionTypesSelectors';
import { allInstitutionsSelector } from '../../../../../redux/selectors/institutionsSelectors';
import { validateStateProvinceCode } from '../../../../../helpers/validateLocations';
import { validateInstitutionCode } from '../../../../../helpers/validateInstitutions';
import {
  allFieldsAreValid,
  validateEntityName,
  validateRequired,
  validateSelectUserFieldMultiple
} from '../../../../../helpers/validateGeneric';

class NewInstitutionContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      institutionCode: '',
      city: '',
      selectedCountryUuid: '',
      selectedInstitutionTypeUuid: '',
      institutionSubtypeUuid: '',
      stateProvinceUuid: '',
      institutionalSystemUuid: '',
      institutionAdmins: [],
      showValidationErrors: false
    };

    this.setNewInstitution = this.setNewInstitution.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUserFieldChange = this.handleUserFieldChange.bind(this);
    this.handleSubmission = this.handleSubmission.bind(this);
  }

  componentDidMount() {
    const { name } = this.state;
    const { institution } = this.props;
    this._isMounted = true;
    if (!name && institution) {
      this.setNewInstitution(institution);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      institution,
      institutionModalView,
      institutionSubtypeUuid,
      systemUuid,
      locations,
      types,
      stateProvinceUuid
    } = this.props;

    if (
      prevState.selectedInstitutionTypeUuid &&
      this.state.selectedInstitutionTypeUuid !==
        prevState.selectedInstitutionTypeUuid
    ) {
      this.clearSubtypeAndSystem();
    }
    if (
      this.state.institutionSubtypeUuid !== prevState.institutionSubtypeUuid &&
      prevState.institutionSubtypeUuid !== ''
    ) {
      this.setAttribute('institutionalSystemUuid', '');
    }

    if (!institution && prevProps.institution !== null) {
      this.clearInstitutionState(institution);
    }
    if (institutionModalView !== prevProps.institutionModalView) {
      this.clearInstitutionState(institution);
    }
    if (institutionSubtypeUuid !== prevProps.institutionSubtypeUuid) {
      this.setAttribute('institutionSubtypeUuid', institutionSubtypeUuid);
    }
    if (systemUuid !== prevProps.systemUuid) {
      this.setAttribute('institutionalSystemUuid', systemUuid);
    }
    if (stateProvinceUuid !== prevProps.stateProvinceUuid) {
      this.setAttribute('stateProvinceUuid', stateProvinceUuid);
    }
    if (
      locations.country.uuid &&
      locations.country.uuid !== prevProps.locations.country.uuid
    ) {
      this.setAttribute('selectedCountryUuid', locations.country.uuid);
    }
    if (
      types.institutionType.uuid &&
      types.institutionType.uuid !== prevProps.types.institutionType.uuid
    ) {
      this.setAttribute(
        'selectedInstitutionTypeUuid',
        types.institutionType.uuid
      );
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  setAttribute = (attribute, value) => {
    if (this._isMounted) {
      this.setState({
        [attribute]: value
      });
    }
  };

  handleUserFieldChange = (values, fieldName) => {
    this.setState(() => ({
      [fieldName]: values
    }));
  };

  setNewInstitution = institution => {
    if (this._isMounted) {
      this.setState(state => ({
        ...institution
      }));
    }
  };

  clearSubtypeAndSystem = () => {
    this.setState({
      institutionSubtypeUuid: '',
      institutionalSystemUuid: ''
    });
  };

  clearInstitutionState = () => {
    if (this._isMounted) {
      this.setState(state => ({
        name: '',
        institutionCode: '',
        city: '',
        selectedCountryUuid: '',
        selectedInstitutionTypeUuid: '',
        institutionSubtypeUuid: '',
        stateProvinceUuid: '',
        institutionalSystemUuid: '',
        institutionAdmins: [],
        showValidationErrors: false
      }));
    }
  };

  setValidationErrors = newInstitution => {
    const { allInstitutions } = this.props;
    let institutionalSystemError = false;

    const nameError = validateEntityName(newInstitution.name, allInstitutions);
    const cityError = validateEntityName(newInstitution.city, []);
    const institutionCodeError = validateInstitutionCode(
      newInstitution.institutionCode
    );
    const subtypeError = validateRequired(
      newInstitution.institutionSubtypeUuid
    );
    const stateProvinceError = validateRequired(
      newInstitution.stateProvinceUuid
    );
    if (newInstitution.institutionalSystemUuid) {
      institutionalSystemError = validateRequired(
        newInstitution.institutionalSystemUuid
      );
    }
    const newValidationErrors = {
      name: nameError,
      city: cityError,
      institutionCode: institutionCodeError,
      institutionSubtypeUuid: subtypeError,
      stateProvinceUuid: stateProvinceError,
      institutionalSystemUuid: institutionalSystemError
    };

    return newValidationErrors;
  };

  handleSubmission = () => {
    const {
      showValidationErrors,
      selectedCountryUuid,
      selectedInstitutionTypeUuid,
      ...newInstitution
    } = this.state;

    const newValidationErrors = this.setValidationErrors(newInstitution);
    if (allFieldsAreValid(newValidationErrors)) {
      this.props.onPostCreateInstitution(newInstitution);
      this.setState({ showValidationErrors: false });
      this.props.clearNewInstitution();
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const {
      name,
      institutionCode,
      city,
      selectedCountryUuid,
      selectedInstitutionTypeUuid,
      institutionSubtypeUuid,
      stateProvinceUuid,
      institutionalSystemUuid,
      institutionAdmins,
      showValidationErrors
    } = this.state;
    const {
      institution,
      isModalOpen,
      handleModalClose,
      allInstitutions,
      clearNewInstitution,
      institutionalSystem,
      systemUuid,
      locations,
      types
    } = this.props;

    let hasSystemParent = false;
    if (institutionalSystem) {
      hasSystemParent = {
        ...institutionalSystem
      };
    } else if (
      this.props.institutionSubtypeUuid &&
      this.props.stateProvinceUuid
    ) {
      hasSystemParent = {
        name: 'Unaffiliated'
      };
    }
    const newInstitution = {
      name,
      institutionCode,
      city,
      selectedCountryUuid,
      selectedInstitutionTypeUuid,
      institutionSubtypeUuid,
      stateProvinceUuid,
      institutionalSystemUuid,
      institutionAdmins
    };

    return (
      <NewInstitution
        newInstitution={newInstitution}
        isModalOpen={isModalOpen}
        allInstitutions={allInstitutions}
        hasSystemParent={hasSystemParent}
        types={types}
        locations={locations}
        showValidationErrors={showValidationErrors}
        handleModalClose={handleModalClose}
        handleChange={this.handleChange}
        handleUserFieldChange={this.handleUserFieldChange}
        onPostCreateInstitution={this.handleSubmission}
        clearNewInstitution={clearNewInstitution}
      />
    );
  }
}

NewInstitutionContainer.propTypes = {
  institution: PropTypes.object,
  institutionalSystem: PropTypes.object,
  allInstitutions: PropTypes.arrayOf(PropTypes.object),
  isModalOpen: PropTypes.bool,
  locations: PropTypes.object,
  types: PropTypes.object,
  institutionModalView: PropTypes.string,
  institutionSubtypeUuid: PropTypes.string,
  systemUuid: PropTypes.string,
  stateProvinceUuid: PropTypes.string,
  handleModalClose: PropTypes.func,
  onPostCreateInstitution: PropTypes.func,
  clearNewInstitution: PropTypes.func
};
NewInstitutionContainer.defaultProps = {
  institution: {},
  institutionalSystem: null,
  allInstitutions: [],
  isModalOpen: false,
  locations: {
    country: {},
    stateProvince: {}
  },
  types: {
    institutionType: {},
    institutionSubtype: {}
  },
  institutionModalView: '',
  institutionSubtypeUuid: '',
  systemUuid: '',
  stateProvinceUuid: '',
  handleModalClose: undefined,
  onPostCreateInstitution: undefined,
  clearNewInstitution: undefined
};

const makeMapStateToProps = () => {
  const getInstitutionalSystem = makeGetInstitutionsInstitutionalSystemSelector();
  const getSystemStateProvinceSelector = makeGetSystemStateProvinceSelector();
  const getSystemInstitutionSubtypeSelector = makeSystemInstitutionSubtypeSelector();

  const mapStateToProps = (state, props) => ({
    institutionalSystem: getInstitutionalSystem(state, props),
    locations: getSystemStateProvinceSelector(state, props),
    types: getSystemInstitutionSubtypeSelector(state, props),
    institutionModalView: state.institutionsDashboardState.institutionModalView,
    institutionSubtypeUuid:
      state.institutionsDashboardState.institutionSubtypeUuid,
    systemUuid: state.institutionsDashboardState.systemUuid,
    stateProvinceUuid: state.institutionsDashboardState.stateProvinceUuid,
    allInstitutions: allInstitutionsSelector(state, props)
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  onPostCreateInstitution: newInstitution =>
    dispatch(doPostInstitution(newInstitution))
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(NewInstitutionContainer);
export { NewInstitutionContainer };
