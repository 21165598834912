import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  doPutAccreditationAgency,
  doDeleteAccreditationAgency
} from '../../../../redux/actions/accreditationAgencyActions';
import AccreditationAgency from './AccreditationAgency';

class AccreditationAgencyContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: '',
      isPublishModalOpen: false,
      isDeleteModalOpen: false
    };

    this.onPublish = this.onPublish.bind(this);
    this.handlePublishModalOpen = this.handlePublishModalOpen.bind(this);
    this.handlePublishModalClose = this.handlePublishModalClose.bind(this);
  }

  toggleEditView = () => {
    this.setState(prevState => ({
      view: prevState.view !== 'EDIT' ? 'EDIT' : ''
    }));
  };

  handlePublishModalOpen = () => {
    this.setState({
      isPublishModalOpen: true
    });
  };

  handlePublishModalClose = () => {
    this.setState({
      isPublishModalOpen: false
    });
  };

  onPublish = () => {
    const { accreditationAgency } = this.props;
    const payload = {
      ...accreditationAgency,
      published: true
    };
    this.props.onPutPublish(payload);
    this.handlePublishModalClose();
  };

  handleDeleteClick = () => {
    this.setState({
      isDeleteModalOpen: true
    });
  };

  handleDeleteModalClose = () => {
    this.setState({
      isDeleteModalOpen: false
    });
  };

  handleConfirmDelete = () => {
    const { accreditationAgency } = this.props;

    this.props.onDelete(accreditationAgency.uuid);
    this.handleDeleteModalClose();
  };

  render() {
    const { accreditationAgency, className } = this.props;
    const { view, isPublishModalOpen, isDeleteModalOpen } = this.state;

    return (
      <AccreditationAgency
        view={view}
        accreditationAgency={accreditationAgency}
        className={className}
        isPublishModalOpen={isPublishModalOpen}
        isDeleteModalOpen={isDeleteModalOpen}
        onPublish={this.onPublish}
        handlePublishModalOpen={this.handlePublishModalOpen}
        handlePublishModalClose={this.handlePublishModalClose}
        handleDeleteClick={this.handleDeleteClick}
        handleDeleteModalClose={this.handleDeleteModalClose}
        handleConfirmDelete={this.handleConfirmDelete}
        toggleEditView={this.toggleEditView}
      />
    );
  }
}

AccreditationAgencyContainer.propTypes = {
  accreditationAgency: PropTypes.object,
  className: PropTypes.string,
  onPutPublish: PropTypes.func,
  onDelete: PropTypes.func
};

AccreditationAgencyContainer.defaultProps = {
  accreditationAgency: {},
  className: '',
  onPutPublish: undefined,
  onDelete: undefined
};

const mapDispatchToProps = dispatch => ({
  onPutPublish: updates => dispatch(doPutAccreditationAgency(updates)),
  onDelete: uuid => dispatch(doDeleteAccreditationAgency(uuid))
});

export default connect(
  undefined,
  mapDispatchToProps
)(AccreditationAgencyContainer);
