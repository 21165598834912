import React from 'react';
import PropTypes from 'prop-types';

import { convertNullToString } from '../../../helpers/utilities';
import SelectField from '../../SelectField/SelectFieldWithError';

const InstitutionTypeSelect = ({
  className,
  disabled,
  institutionTypes,
  selectedInstitutionType,
  showValidationErrors,
  handleChange
}) => {
  const selectOptions = institutionTypes.map(type => ({
    value: type.uuid,
    text: type.name
  }));

  const error =
    showValidationErrors && !selectedInstitutionType
      ? { message: 'Please select a Type' }
      : false;

  return (
    <SelectField
      className={className}
      select
      error={error}
      disabled={disabled || !institutionTypes || institutionTypes.length < 1}
      name="selectedInstitutionTypeUuid"
      labelText="InstitutionType*"
      inputLabelWidth={110}
      value={convertNullToString(selectedInstitutionType)}
      onChange={handleChange}
      options={selectOptions}
    />
  );
};

InstitutionTypeSelect.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  institutionTypes: PropTypes.arrayOf(PropTypes.object),
  selectedInstitutionType: PropTypes.string,
  showValidationErrors: PropTypes.bool,
  handleChange: PropTypes.func
};

InstitutionTypeSelect.defaultProps = {
  className: '',
  disabled: false,
  institutionTypes: [],
  selectedInstitutionType: '',
  showValidationErrors: false,
  handleChange: undefined
};

export default InstitutionTypeSelect;
