import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { Heading1 } from '@xcomp/xcomp-design-library/dist/components/Typography';
import CipcodeContainer from './Cipcode/CipcodeContainer';

import {
  HeaderWrapper,
  HeaderLayout,
  HeaderText,
  ButtonHeader,
  ButtonPrimarySC,
  PageActions
} from '../SharedSC/HeaderSC';
import PageHeader from '../../Header/PageHeader';
import {
  Panel,
  PanelHeader,
  PanelLabel,
  Page,
  Wrapper
} from '../SharedSC/DashboardSC';
import NoItemsMessage from '../../NoItemsMessage/NoItemsMessage';
import NewCipcodeContainer from './Cipcode/NewCipcode/NewCipcodeContainer';

const ProgramDashboard = ({
  programCipcodes,
  newCipcode,
  addProgramCipcode,
  clearNew
}) => {
  const noItemsMessage = 'There are currently no program CIP codes created.';
  const someUuid = uuidv4();
  const allCipcodes = newCipcode
    ? programCipcodes.concat(newCipcode)
    : programCipcodes;
  return (
    <Page>
      <PageHeader>
        <HeaderWrapper>
          <HeaderLayout>
            <HeaderText>
              <Heading1>Program Codes</Heading1>
            </HeaderText>
          </HeaderLayout>
        </HeaderWrapper>
      </PageHeader>
      <PageActions>
        <HeaderWrapper>
          <ButtonHeader>
            <ButtonPrimarySC onClick={addProgramCipcode}>
              Add CIP
            </ButtonPrimarySC>
          </ButtonHeader>
        </HeaderWrapper>
      </PageActions>

      <Wrapper>
        {allCipcodes && allCipcodes.length > 0 ? (
          <Panel>
            <PanelHeader>
              <PanelLabel>CIP</PanelLabel>
              <PanelLabel marginLeft="22px">Code Name</PanelLabel>
            </PanelHeader>
            {newCipcode && (
              <NewCipcodeContainer
                key={someUuid}
                cipcode={newCipcode}
                allCipcodes={programCipcodes}
                clearNew={clearNew}
              />
            )}
            {programCipcodes.map(cip => (
              <CipcodeContainer
                key={cip.uuid}
                cipcode={cip}
                addProgramCipcode={addProgramCipcode}
              />
            ))}
          </Panel>
        ) : (
          <Panel>
            <NoItemsMessage message={noItemsMessage} />
          </Panel>
        )}
      </Wrapper>
    </Page>
  );
};

ProgramDashboard.propTypes = {
  programCipcodes: PropTypes.arrayOf(PropTypes.object),
  newCipcode: PropTypes.object,
  addProgramCipcode: PropTypes.func,
  clearNew: PropTypes.func
};

ProgramDashboard.defaultProps = {
  programCipcodes: [],
  newCipcode: {},
  addProgramCipcode: undefined,
  clearNew: undefined
};

export default ProgramDashboard;
