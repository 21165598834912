import { combineReducers } from 'redux';
import { createReducer } from 'redux-orm';
import notificationReducer from './notificationReducer';
import successfulCreationReducer from './successfulCreationReducer';
import successfulUpdateReducer from './successfulUpdateReducer';
import moveCodeReducer from './moveCodeReducer';
import institutionsDashboardReducer from './institutionsDashboardReducer';
import dragDropReducer from './dragDropReducer';
import searchReducer from './searchReducer';
import { orm } from '../models';

const rootReducer = combineReducers({
  moveCodeState: moveCodeReducer,
  institutionsDashboardState: institutionsDashboardReducer,
  notificationState: notificationReducer,
  successfulCreationState: successfulCreationReducer,
  successfulUpdateState: successfulUpdateReducer,
  dragDropState: dragDropReducer,
  searchState: searchReducer,
  orm: createReducer(orm)
});

export default rootReducer;
