import { Model, attr, fk } from 'redux-orm';
import {
  GET_INSTITUTION_TYPES_SUCCESS,
  POST_INSTITUTION_SUBTYPE_SUCCESS,
  PUT_INSTITUTION_SUBTYPE_SUCCESS,
  DELETE_INSTITUTION_SUBTYPE_SUCCESS,
  DELETE_INSTITUTION_TYPE_SUCCESS
} from '../actions/actionTypes';

class InstitutionSubtype extends Model {
  static reducer(action, InstitutionSubtype, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_INSTITUTION_TYPES_SUCCESS: {
        if (
          payload.institutionSubtypes.length !==
          InstitutionSubtype.all().count()
        ) {
          InstitutionSubtype.all().delete();
        }

        const institutionSubtypes = [...payload.institutionSubtypes];
        return institutionSubtypes.map(subtype => {
          const subtypeUpsert = InstitutionSubtype.upsert(subtype);
          return subtypeUpsert;
        });
      }
      case POST_INSTITUTION_SUBTYPE_SUCCESS: {
        const { createdInstitutionSubtype } = payload;
        return InstitutionSubtype.upsert(createdInstitutionSubtype);
      }
      case PUT_INSTITUTION_SUBTYPE_SUCCESS: {
        const { updatedInstitutionSubtype } = payload;
        return InstitutionSubtype.upsert(updatedInstitutionSubtype);
      }
      case DELETE_INSTITUTION_TYPE_SUCCESS: {
        const { deletedInstitutionType } = payload;
        return InstitutionSubtype.all()
          .filter(subtype => {
            if (
              !subtype.institutionTypeUuid ||
              subtype.institutionTypeUuid === deletedInstitutionType.uuid
            ) {
              return subtype;
            }
            return false;
          })
          .delete();
      }
      case DELETE_INSTITUTION_SUBTYPE_SUCCESS: {
        const { deletedInstitutionSubtype } = payload;
        return InstitutionSubtype.withId(
          deletedInstitutionSubtype.uuid
        ).delete();
      }
      default:
        return session;
    }
  }
}

InstitutionSubtype.fields = {
  uuid: attr(),
  name: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  institutionTypeUuid: fk('InstitutionType', 'subtypes')
};

InstitutionSubtype.modelName = 'InstitutionSubtype';
InstitutionSubtype.options = {
  idAttribute: 'uuid'
};

export default InstitutionSubtype;
