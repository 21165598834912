import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { makeCountryStateProvincesPublishedSelector } from '../../../../redux/selectors/locationsSelectors';
import Country from './Country';

class CountryContainer extends PureComponent {
  render() {
    const { country, stateProvinces, className } = this.props;

    return (
      <Country
        country={country}
        stateProvinces={stateProvinces}
        className={className}
      />
    );
  }
}

CountryContainer.propTypes = {
  country: PropTypes.object,
  stateProvinces: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string
};

CountryContainer.defaultProps = {
  country: {},
  stateProvinces: [],
  className: ''
};

const makeMapStateToProps = () => {
  const getCountryStateProvinces = makeCountryStateProvincesPublishedSelector();
  const mapStateToProps = (state, props) => ({
    stateProvinces: getCountryStateProvinces(state, props)
  });
  return mapStateToProps;
};

export default connect(
  makeMapStateToProps,
  undefined
)(CountryContainer);
