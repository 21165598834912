import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { doGeneralErrorNotification } from '../../../redux/actions/notificationActions';

import { doGetProgramCipcodes } from '../../../redux/actions/programCipcodeActions';
import { programCipcodesSelector } from '../../../redux/selectors/programCipcodeSelectors';
import ProgramDashboard from './ProgramDashboard';

class ProgramDashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newCipcode: null
    };

    this.clearNew = this.clearNew.bind(this);
    this.addProgramCipcode = this.addProgramCipcode.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.onFetchProgramCipcodes();
  }

  clearNew = () => {
    this.setState({ newCipcode: null });
  };

  addProgramCipcode = () => {
    const { newCipcode } = this.state;

    if (newCipcode) {
      this.props.onGeneralErrorNotification(
        'Cannot create more than one program CIP code simultaneously'
      );
      return;
    }

    const empty = {
      name: '',
      code: '',
      isNew: true
    };

    this.setState({
      newCipcode: empty
    });
  };

  render() {
    const { newCipcode } = this.state;
    const { programCipcodes } = this.props;

    return (
      <ProgramDashboard
        newCipcode={newCipcode}
        programCipcodes={programCipcodes}
        addProgramCipcode={this.addProgramCipcode}
        clearNew={this.clearNew}
      />
    );
  }
}

ProgramDashboardContainer.propTypes = {
  programCipcodes: PropTypes.arrayOf(PropTypes.object),
  onFetchProgramCipcodes: PropTypes.func,
  onGeneralErrorNotification: PropTypes.func
};

ProgramDashboardContainer.defaultProps = {
  programCipcodes: [],
  onFetchProgramCipcodes: undefined,
  onGeneralErrorNotification: undefined
};

const mapStateToProps = state => ({
  programCipcodes: programCipcodesSelector(state)
});

const mapDispatchToProps = dispatch => ({
  onFetchProgramCipcodes: () => dispatch(doGetProgramCipcodes()),
  onGeneralErrorNotification: errorMessage =>
    dispatch(doGeneralErrorNotification(errorMessage))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgramDashboardContainer);
export { ProgramDashboardContainer };
