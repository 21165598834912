import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ButtonMenu, Heading1 } from '@xcomp/xcomp-design-library';
import PageHeader from '../../Header/PageHeader';
import {
  HeaderWrapper,
  HeaderLayout,
  HeaderText,
  ButtonHeader,
  PageActions
} from '../SharedSC/HeaderSC';
import {
  Panel,
  PanelHeader,
  PanelLabel,
  Page,
  Wrapper
} from '../SharedSC/DashboardSC';
import Country from './Country/CountryContainer';
import NewInstitutionalSystemContainer from './InstitutionalSystem/NewInstitutionalSystem/NewInstitutionalSystemContainer';
import NoItemsMessage from '../../NoItemsMessage/NoItemsMessage';
import EditInstitutionalSystemContainer from './InstitutionalSystem/EditInstitutionalSystem/EditInstitutionalSystemContainer';
import EditInstitutionContainer from './Institution/EditInstitution/EditInstitutionContainer';
import NewInstitutionContainer from './Institution/NewInstitution/NewInstitutionContainer';

const AddSystemMenu = styled(ButtonMenu)`
  && {
    display: flex;
    margin-left: auto;
  }
`;

const InstitutionsDashboard = ({
  countries,
  systemModalView,
  institutionModalView,
  systemUuid,
  institutionUuid,
  newInstitutionalSystem,
  addInstitutionalSystem,
  newInstitution,
  addInstitution,
  handleSystemModalClose,
  clearNewState
}) => {
  const clearNewInstitution = () => {
    clearNewState('newInstitution');
  };
  const clearNewInstitutionalSystem = () => {
    clearNewState('newInstitutionalSystem');
  };
  const noCountriesMessage = 'There are currently no published Countries.';
  return (
    <Page>
      <PageHeader>
        <HeaderWrapper>
          <HeaderLayout>
            <HeaderText>
              <Heading1>Institutions</Heading1>
            </HeaderText>
          </HeaderLayout>
        </HeaderWrapper>
      </PageHeader>
      <PageActions>
        <HeaderWrapper>
          <ButtonHeader>
            <AddSystemMenu
              buttonText="Add"
              options={[
                {
                  label: 'System',
                  onClick: addInstitutionalSystem
                },
                {
                  label: 'Institution',
                  onClick: addInstitution
                }
              ]}
            />
          </ButtonHeader>
        </HeaderWrapper>
      </PageActions>

      <NewInstitutionalSystemContainer
        institutionalSystem={newInstitutionalSystem}
        isNewSystemModalOpen={systemModalView === 'NEW'}
        clearNewInstitutionalSystem={clearNewInstitutionalSystem}
        handleModalClose={handleSystemModalClose}
      />
      <EditInstitutionalSystemContainer
        systemUuid={systemUuid}
        isEditSystemModalOpen={systemModalView === 'EDIT'}
        handleModalClose={handleSystemModalClose}
      />
      <NewInstitutionContainer
        institution={newInstitution}
        isModalOpen={institutionModalView === 'NEW'}
        clearNewInstitution={clearNewInstitution}
        handleModalClose={handleSystemModalClose}
      />
      <EditInstitutionContainer
        institutionUuid={institutionUuid}
        isEditInstitutionModalOpen={institutionModalView === 'EDIT'}
        handleModalClose={handleSystemModalClose}
      />
      <Wrapper>
        {countries && countries.length > 0 ? (
          <Panel>
            <PanelHeader>
              <PanelLabel>Code</PanelLabel>
              <PanelLabel marginLeft="22px">Country</PanelLabel>
            </PanelHeader>
            {countries.map(country => (
              <Country key={country.uuid} country={country} />
            ))}
          </Panel>
        ) : (
          <Panel>
            <NoItemsMessage message={noCountriesMessage} />
          </Panel>
        )}
      </Wrapper>
    </Page>
  );
};

InstitutionsDashboard.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.object),
  systemModalView: PropTypes.string,
  institutionModalView: PropTypes.string,
  systemUuid: PropTypes.string,
  institutionUuid: PropTypes.string,
  newInstitutionalSystem: PropTypes.object,
  newInstitution: PropTypes.object,
  addInstitutionalSystem: PropTypes.func,
  addInstitution: PropTypes.func,
  clearNewState: PropTypes.func,
  handleSystemModalClose: PropTypes.func
};

InstitutionsDashboard.defaultProps = {
  countries: [],
  systemModalView: '',
  institutionModalView: '',
  systemUuid: null,
  institutionUuid: null,
  newInstitutionalSystem: {},
  newInstitution: {},
  addInstitutionalSystem: undefined,
  addInstitution: undefined,
  clearNewState: undefined,
  handleSystemModalClose: undefined
};

export default InstitutionsDashboard;
