import { call, put } from 'redux-saga/effects';
import {
  getInstitutionalSystems,
  postInstitutionalSystem,
  putInstitutionalSystem,
  deleteInstitutionalSystem
} from '../../api/instituionalSystemsApi';
import {
  doGetInstitutionalSystemsSuccess,
  doGetInstitutionalSystemsError,
  doPostInstitutionalSystemSuccess,
  doPostInstitutionalSystemError,
  doPutInstitutionalSystemSuccess,
  doPutInstitutionalSystemError,
  doDeleteInstitutionalSystemSuccess,
  doDeleteInstitutionalSystemError
} from '../actions/institutionalSystemActions';

function* handleFetchInstitutionalSystems() {
  try {
    const result = yield call(getInstitutionalSystems);
    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doGetInstitutionalSystemsSuccess(payload));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doGetInstitutionalSystemsError(error));
  }
}

function* handlePostCreateInstitutionalSystem(action) {
  const { newInstitutionalSystem } = action.payload;

  try {
    const result = yield call(postInstitutionalSystem, newInstitutionalSystem);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostInstitutionalSystemSuccess(payload));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doPostInstitutionalSystemError());
  }
}

function* handlePutUpdateInstitutionalSystem(action) {
  const { institutionalSystem } = action.payload;

  try {
    const result = yield call(putInstitutionalSystem, institutionalSystem);

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doPutInstitutionalSystemSuccess(payload));
    } else {
      const payload = yield result.json();
      yield put(doPutInstitutionalSystemError(payload));
    }
  } catch (error) {
    yield put(doPutInstitutionalSystemError());
  }
}

function* handleDeleteInstitutionalSystem(action) {
  const { uuid } = action.payload;

  try {
    const result = yield call(deleteInstitutionalSystem, uuid);

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doDeleteInstitutionalSystemSuccess(payload));
    } else {
      const errorText = yield result.text();
      yield put(
        doDeleteInstitutionalSystemError({
          text: errorText
        })
      );
    }
  } catch (error) {
    yield put(doDeleteInstitutionalSystemError());
  }
}

export {
  handleFetchInstitutionalSystems,
  handlePostCreateInstitutionalSystem,
  handlePutUpdateInstitutionalSystem,
  handleDeleteInstitutionalSystem
};
