import {
  OPEN_NOTIFICATION,
  CLOSE_NOTIFICATION,
  NEW_GENERAL_ERROR
} from './actionTypes';

const doOpenNotification = () => ({
  type: OPEN_NOTIFICATION
});

const doCloseNotification = () => ({
  type: CLOSE_NOTIFICATION
});

const doGeneralErrorNotification = error => ({
  type: NEW_GENERAL_ERROR,
  payload: {
    error
  }
});

export { doOpenNotification, doCloseNotification, doGeneralErrorNotification };
