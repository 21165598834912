import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PublishModal from '../../../DialogModal/PublishModal/PublishModal';
import DeleteModal from '../../../DialogModal/DeleteModal/DeleteModal';
import AccordionRowContainer from '../../../AccordionRow/AccordionRowContainer';
import EditAccreditationAgencyContainer from './EditAccreditationAgency/EditAccreditationAgencyContainer';

const AgencyAccordion = styled(AccordionRowContainer)`
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const Layout = styled.div`
  width: 100%;
`;

const AccreditationAgency = ({
  accreditationAgency,
  toggleEditView,
  view,
  isPublishModalOpen,
  isDeleteModalOpen,
  handlePublishModalOpen,
  handlePublishModalClose,
  handleDeleteClick,
  handleDeleteModalClose,
  handleConfirmDelete,
  onPublish
}) => {
  const agencyActions = [
    {
      label: 'Edit',
      onClick: toggleEditView,
      shouldOpenOnClick: false,
      icon: 'edit'
    },
    {
      label: 'Publish',
      onClick: handlePublishModalOpen,
      shouldOpenOnClick: false,
      icon: 'edit'
    },
    {
      label: 'Delete',
      onClick: handleDeleteClick,
      shouldOpenOnClick: false,
      icon: 'delete'
    }
  ];

  return (
    <Layout>
      <PublishModal
        isModalOpen={isPublishModalOpen}
        handleModalClose={handlePublishModalClose}
        handlePublish={onPublish}
        entityLabel="Agency"
        publishConfirmMessage="This will make the agency available to institutions and lock the agency from being deleted."
      />
      <DeleteModal
        modalOpen={isDeleteModalOpen}
        handleModalClose={() => handleDeleteModalClose()}
        handleDelete={() => handleConfirmDelete()}
        type="Agency"
      />
      {view === 'EDIT' ? (
        <EditAccreditationAgencyContainer
          accreditationAgency={accreditationAgency}
          key={accreditationAgency.uuid}
          toggleEditView={toggleEditView}
        />
      ) : (
        <AgencyAccordion
          columnOne={accreditationAgency.name}
          columnTwo=""
          actions={agencyActions}
          columnGutters="1.5rem"
          isPublished={accreditationAgency.published}
          isTopLevel
          leaf
        />
      )}
    </Layout>
  );
};

AccreditationAgency.propTypes = {
  view: PropTypes.oneOf(['EDIT', '']),
  accreditationAgency: PropTypes.object.isRequired,
  isPublishModalOpen: PropTypes.bool,
  isDeleteModalOpen: PropTypes.bool,
  onPublish: PropTypes.func,
  handlePublishModalOpen: PropTypes.func,
  handlePublishModalClose: PropTypes.func,
  handleDeleteClick: PropTypes.func,
  handleDeleteModalClose: PropTypes.func,
  handleConfirmDelete: PropTypes.func,
  toggleEditView: PropTypes.func
};

AccreditationAgency.defaultProps = {
  view: '',
  isPublishModalOpen: false,
  isDeleteModalOpen: false,
  onPublish: undefined,
  handlePublishModalOpen: undefined,
  handlePublishModalClose: undefined,
  handleDeleteClick: undefined,
  handleDeleteModalClose: undefined,
  handleConfirmDelete: undefined,
  toggleEditView: undefined
};

export default AccreditationAgency;
