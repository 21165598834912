import React from 'react';
import styled from 'styled-components';
import { Body2 } from '@xcomp/xcomp-design-library';

export const Panel = styled.div`
  background: ${props => props.theme.colors.white};
  max-width: 1200px;
  display: block;
  margin: 0 auto;
  padding: 0;
  box-shadow: ${props => props.theme.boxShadows.low};
  border: 1px solid rgba(155, 155, 155, 0.1);
  border-radius: 3px;
`;

export const PanelHeader = styled.div`
  height: 48px;
  width: 100%;
  display: flex;
  flex-wrap: no-wrap;
  align-items: center;
  justify-content: flex-start;
  padding-left: 66px;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

export const PanelLabel = styled(({ marginLeft, ...rest }) => (
  <Body2 {...rest} />
))`
  margin-left: ${props => (props.marginLeft ? props.marginLeft : 0)};
`;

export const Page = styled.div`
  width: 100%;
`;

export const Wrapper = styled.div`
  padding: 0 1.5rem;
`;

export const RowSummary = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: 0.9375rem;
  font-weight: 300;
  color: ${props => props.theme.fontColors.dark};

  h2 {
    font-size: inherit;
    font-weight: 400;
    color: ${props => props.theme.fontColors.darker};
    margin: 0;
    padding: 0;
  }

  svg {
    vertical-align: middle;
  }
`;
