import React from 'react';
import PropTypes from 'prop-types';
import { Heading1 } from '@xcomp/xcomp-design-library/dist/components/Typography';
import { v4 as uuidv4 } from 'uuid';

import {
  HeaderWrapper,
  HeaderLayout,
  HeaderText,
  ButtonHeader,
  ButtonPrimarySC,
  PageActions
} from '../SharedSC/HeaderSC';
import PageHeader from '../../Header/PageHeader';
import {
  Panel,
  PanelHeader,
  PanelLabel,
  Page,
  Wrapper
} from '../SharedSC/DashboardSC';
import CIPCodeContainer from './CIPCode/CIPCodeContainer';
import NewCIPCodeContainer from './CIPCode/NewCIPCode/NewCIPCodeContainer';
import NoItemsMessage from '../../NoItemsMessage/NoItemsMessage';
import SelectionModalContainer from './SelectionModal/SelectionModalContainer';

const MMDashboard = ({ cips, addCip, clearNewCip }) => {
  const noCipsMessage = 'There are currently no CIP codes created.';
  const someUuid = uuidv4();

  return (
    <Page>
      <PageHeader>
        <HeaderWrapper>
          <HeaderLayout>
            <HeaderText>
              <Heading1>Microcompetency Codes</Heading1>
            </HeaderText>
          </HeaderLayout>
        </HeaderWrapper>
      </PageHeader>
      <PageActions>
        <HeaderWrapper>
          <ButtonHeader>
            <ButtonPrimarySC onClick={addCip}>Add CIP</ButtonPrimarySC>
          </ButtonHeader>
        </HeaderWrapper>
      </PageActions>

      <Wrapper>
        {cips && cips.length > 0 ? (
          <Panel>
            <PanelHeader>
              <PanelLabel>CIP</PanelLabel>
              <PanelLabel marginLeft="22px">Code Name</PanelLabel>
            </PanelHeader>
            {cips &&
              cips.map(cip =>
                !cip.isNew ? (
                  <CIPCodeContainer key={cip.uuid} cip={cip} addCip={addCip} />
                ) : (
                  <NewCIPCodeContainer
                    cip={cip}
                    key={someUuid}
                    clearNewCip={clearNewCip}
                  />
                )
              )}
          </Panel>
        ) : (
          <Panel>
            <NoItemsMessage message={noCipsMessage} />
          </Panel>
        )}
      </Wrapper>
      <SelectionModalContainer />
    </Page>
  );
};

MMDashboard.propTypes = {
  cips: PropTypes.arrayOf(PropTypes.object),
  addCip: PropTypes.func,
  clearNewCip: PropTypes.func
};

MMDashboard.defaultProps = {
  cips: [],
  addCip: undefined,
  clearNewCip: undefined
};

export default MMDashboard;
