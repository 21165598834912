import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Body1 } from '@xcomp/xcomp-design-library';
import DeleteModal from '../../../DialogModal/DeleteModal/DeleteModal';
import DisciplineContainer from '../Discipline/DisciplineContainer';
import NewDisciplineContainer from '../Discipline/NewDiscipline/NewDisciplineContainer';
import AccordionRowContainer from '../../../AccordionRow/AccordionRowContainer';
import EditCIPCodeContainer from './EditCIPCode/EditCIPCodeContainer';

const CipcodeAccordion = styled(AccordionRowContainer)`
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const NoTopicsMessage = styled(({ rowOffset, ...rest }) => <div {...rest} />)`
  margin-left: ${props => props.rowOffset};
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  font-weight: 300;
`;

const Body1SC = styled(Body1)`
  margin-top: 0;
`;

const CipCode = ({
  cip,
  disciplines,
  view,
  isDeleteModalOpen,
  addDiscipline,
  addCip,
  handleDeleteClick,
  handleDeleteModalClose,
  handleDelete,
  toggleEditView,
  clearNewDiscipline
}) => {
  const cipcodeActions = [
    {
      label: 'Add CIP',
      onClick: () => addCip(),
      shouldOpenOnClick: false,
      icon: 'add'
    },
    {
      label: 'Add Discipline',
      onClick: () => addDiscipline(cip.uuid),
      shouldOpenOnClick: true,
      icon: 'add'
    },
    {
      label: 'Edit',
      onClick: toggleEditView,
      shouldOpenOnClick: false,
      icon: 'edit'
    },
    {
      label: 'Delete',
      onClick: handleDeleteClick,
      shouldOpenOnClick: false,
      icon: 'delete'
    }
  ];

  const someUuid = uuidv4();
  return (
    <div>
      <DeleteModal
        modalOpen={isDeleteModalOpen}
        handleModalClose={() => handleDeleteModalClose()}
        handleDelete={() => handleDelete()}
        type="CIP"
      />
      {view === 'EDIT' ? (
        <EditCIPCodeContainer
          cip={cip}
          key={cip.uuid}
          toggleEditView={toggleEditView}
        />
      ) : (
        <CipcodeAccordion
          columnOne={cip.code}
          columnTwo={cip.title}
          actions={cipcodeActions}
          columnGutters="1.75rem"
          isTopLevel
        >
          {disciplines && disciplines.length > 0 ? (
            disciplines
              .sort((discA, discB) => discA.code - discB.code)
              .map(disc =>
                !disc.isNew ? (
                  <DisciplineContainer
                    cip={cip}
                    discipline={disc}
                    key={disc.uuid}
                    addDiscipline={addDiscipline}
                  />
                ) : (
                  <NewDisciplineContainer
                    cip={cip}
                    discipline={disc}
                    key={someUuid}
                    clearNewDiscipline={clearNewDiscipline}
                  />
                )
              )
          ) : (
            <NoTopicsMessage rowOffset="97px">
              <Body1SC>No disciplines exist for this CIP</Body1SC>
            </NoTopicsMessage>
          )}
        </CipcodeAccordion>
      )}
    </div>
  );
};

CipCode.propTypes = {
  cip: PropTypes.object,
  view: PropTypes.oneOf(['EDIT', '']),
  disciplines: PropTypes.arrayOf(PropTypes.object),
  isDeleteModalOpen: PropTypes.bool,
  handleDeleteClick: PropTypes.func,
  handleDeleteModalClose: PropTypes.func,
  handleDelete: PropTypes.func,
  addDiscipline: PropTypes.func,
  addCip: PropTypes.func,
  toggleEditView: PropTypes.func,
  clearNewDiscipline: PropTypes.func
};

CipCode.defaultProps = {
  cip: {},
  view: '',
  disciplines: [],
  isDeleteModalOpen: false,
  handleDeleteClick: undefined,
  handleDeleteModalClose: undefined,
  handleDelete: undefined,
  addDiscipline: undefined,
  addCip: undefined,
  toggleEditView: undefined,
  clearNewDiscipline: undefined
};

export default CipCode;
export { CipcodeAccordion };
