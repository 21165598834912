import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EditCIPCode from './EditCIPCode';
import { cipsSelector } from '../../../../../redux/selectors/cipsSelectors';
import { doPutCipcode } from '../../../../../redux/actions/cipCodeActions';
import {
  validateCodeTitle,
  validateCipcode
} from '../../../../../helpers/validateCodes';
import { allFieldsAreValid } from '../../../../../helpers/validateGeneric';

class EditCIPCodeContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      code: '',
      title: '',
      showValidationErrors: false
    };

    this.setExistingCipcode = this.setExistingCipcode.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCipcodeUpdate = this.handleCipcodeUpdate.bind(this);
  }

  componentDidMount() {
    const { code } = this.state;
    const { cip } = this.props;
    this._isMounted = true;
    if (!code) {
      this.setExistingCipcode(cip);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  setExistingCipcode = cip => {
    const { ...existingCipcode } = cip;
    if (this._isMounted) {
      this.setState(() => ({
        ...existingCipcode
      }));
    }
  };

  setValidationErrors = existingCipcode => {
    const { allCipcodes } = this.props;
    const siblingCipcodes = allCipcodes.filter(
      cip => cip.uuid !== existingCipcode.uuid
    );
    const titleError = validateCodeTitle(existingCipcode.title);
    const codeError = validateCipcode(existingCipcode.code, siblingCipcodes);
    const newValidationErrors = {
      title: titleError,
      code: codeError
    };

    return newValidationErrors;
  };

  handleCipcodeUpdate = () => {
    const { showValidationErrors, ...existingCipcode } = this.state;
    const newValidationErrors = this.setValidationErrors(existingCipcode);

    if (allFieldsAreValid(newValidationErrors)) {
      const payload = {
        uuid: existingCipcode.uuid,
        title: existingCipcode.title,
        code: existingCipcode.code
      };
      this.props.onPostUpdateCipcode(payload);
      this.setState({ showValidationErrors: false });
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const { code, title, uuid, showValidationErrors } = this.state;
    const { cip, allCipcodes, toggleEditView } = this.props;

    const thisCipcode = {
      uuid,
      code,
      title
    };

    const siblingCipcodes = allCipcodes.filter(
      cip => cip.uuid !== thisCipcode.uuid
    );

    return (
      <EditCIPCode
        existingCipcode={thisCipcode}
        cip={cip}
        allCipcodes={siblingCipcodes}
        handleChange={this.handleChange}
        onPostUpdateCipcode={this.handleCipcodeUpdate}
        showValidationErrors={showValidationErrors}
        toggleEditView={toggleEditView}
      />
    );
  }
}

EditCIPCodeContainer.propTypes = {
  cip: PropTypes.object,
  allCipcodes: PropTypes.arrayOf(PropTypes.object),
  toggleEditView: PropTypes.func,
  onPostUpdateCipcode: PropTypes.func
};
EditCIPCodeContainer.defaultProps = {
  cip: {},
  allCipcodes: [],
  toggleEditView: undefined,
  onPostUpdateCipcode: undefined
};

const mapStateToProps = state => ({
  allCipcodes: cipsSelector(state)
});

const mapDispatchToProps = dispatch => ({
  onPostUpdateCipcode: cipcodeUpdates => dispatch(doPutCipcode(cipcodeUpdates))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCIPCodeContainer);
export { EditCIPCodeContainer };
