import { call, put } from 'redux-saga/effects';
import {
  getDegreeLevels,
  postDegreeLevel,
  putDegreeLevel,
  deleteDegreeLevel,
  patchMoveDegreeLevel
} from '../../api/degreeLevelsApi';
import {
  doGetDegreeLevelsSuccess,
  doGetDegreeLevelsError,
  doPostDegreeLevelSuccess,
  doPostDegreeLevelError,
  doPutDegreeLevelSuccess,
  doPutDegreeLevelError,
  doDeleteDegreeLevelSuccess,
  doDeleteDegreeLevelError,
  doPatchMoveDegreeLevelSuccess,
  doPatchMoveDegreeLevelError
} from '../actions/degreeLevelActions';

function* handleFetchDegreeLevels() {
  try {
    const result = yield call(getDegreeLevels);
    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doGetDegreeLevelsSuccess(payload));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doGetDegreeLevelsError(error));
  }
}

function* handlePostCreateDegreeLevel(action) {
  const { newDegreeLevel } = action.payload;

  try {
    const result = yield call(postDegreeLevel, newDegreeLevel);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostDegreeLevelSuccess(payload));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doPostDegreeLevelError());
  }
}

function* handlePutUpdateDegreeLevel(action) {
  const { degreeLevel } = action.payload;

  try {
    const result = yield call(putDegreeLevel, degreeLevel);

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doPutDegreeLevelSuccess(payload));
    } else {
      const payload = yield result.json();
      yield put(doPutDegreeLevelError(payload));
    }
  } catch (error) {
    yield put(doPutDegreeLevelError());
  }
}

function* handlePatchMoveDegreeLevel(action) {
  const { uuid, destinationOrder } = action.payload;

  try {
    const result = yield call(patchMoveDegreeLevel, uuid, destinationOrder);

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doPatchMoveDegreeLevelSuccess(payload));
    } else {
      const payload = yield result.json();
      yield put(doPatchMoveDegreeLevelError(payload));
    }
  } catch (error) {
    yield put(doPatchMoveDegreeLevelError());
  }
}

function* handleDeleteDegreeLevel(action) {
  const { uuid } = action.payload;

  try {
    const result = yield call(deleteDegreeLevel, uuid);

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doDeleteDegreeLevelSuccess(payload));
    } else {
      const errorText = yield result.text();
      yield put(
        doDeleteDegreeLevelError({
          text: errorText
        })
      );
    }
  } catch (error) {
    yield put(doDeleteDegreeLevelError());
  }
}

export {
  handleFetchDegreeLevels,
  handlePostCreateDegreeLevel,
  handlePutUpdateDegreeLevel,
  handlePatchMoveDegreeLevel,
  handleDeleteDegreeLevel
};
