import { call, put } from 'redux-saga/effects';
import {
  getAccreditationAgencies,
  postAccreditationAgencies,
  putAccreditationAgency,
  deleteAccreditationAgency
} from '../../api/accreditationAgenciesApi';
import {
  doGetAccreditationAgenciesSuccess,
  doGetAccreditationAgenciesError,
  doPostAccreditationAgencySuccess,
  doPostAccreditationAgencyError,
  doPutAccreditationAgencySuccess,
  doPutAccreditationAgencyError,
  doDeleteAccreditationAgencySuccess,
  doDeleteAccreditationAgencyError
} from '../actions/accreditationAgencyActions';

function* handleFetchAccreditationAgencies() {
  try {
    const result = yield call(getAccreditationAgencies);
    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doGetAccreditationAgenciesSuccess(payload));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doGetAccreditationAgenciesError(error));
  }
}

function* handlePostCreateAccreditationAgency(action) {
  const { newAccreditationAgency } = action.payload;

  try {
    const result = yield call(
      postAccreditationAgencies,
      newAccreditationAgency
    );
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostAccreditationAgencySuccess(payload));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doPostAccreditationAgencyError());
  }
}

function* handlePutUpdateAccreditationAgency(action) {
  const { accreditationAgency } = action.payload;

  try {
    const result = yield call(putAccreditationAgency, accreditationAgency);

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doPutAccreditationAgencySuccess(payload));
    } else {
      const payload = yield result.json();
      yield put(doPutAccreditationAgencyError(payload));
    }
  } catch (error) {
    yield put(doPutAccreditationAgencyError());
  }
}

function* handleDeleteAccreditationAgency(action) {
  const { uuid } = action.payload;

  try {
    const result = yield call(deleteAccreditationAgency, uuid);

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doDeleteAccreditationAgencySuccess(payload));
    } else {
      const errorText = yield result.text();
      yield put(
        doDeleteAccreditationAgencyError({
          text: errorText
        })
      );
    }
  } catch (error) {
    yield put(doDeleteAccreditationAgencyError());
  }
}

export {
  handleFetchAccreditationAgencies,
  handlePostCreateAccreditationAgency,
  handlePutUpdateAccreditationAgency,
  handleDeleteAccreditationAgency
};
