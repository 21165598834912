import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EditDegreeLevel from './EditDegreeLevel';
import { degreeLevelsSelector } from '../../../../../redux/selectors/degreeLevelSelectors';
import { doPutDegreeLevel } from '../../../../../redux/actions/degreeLevelActions';
import {
  allFieldsAreValid,
  validateStringProperty
} from '../../../../../helpers/validateGeneric';

class EditDegreeLevelContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      uuid: '',
      degreeLevel: '',
      showValidationErrors: false
    };
  }

  componentDidMount() {
    const { degreeLevel } = this.props;
    this._isMounted = true;
    if (!this.state.degreeLevel) {
      this.setExistingState(degreeLevel);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  setExistingState = degreeLevel => {
    if (this._isMounted) {
      this.setState(() => ({
        ...degreeLevel
      }));
    }
  };

  setValidationErrors = existingDegreeLevel => {
    const { allDegreeLevels } = this.props;
    const { uuid } = this.state;
    const siblings = allDegreeLevels.filter(
      degreeLevel => degreeLevel.uuid !== uuid
    );
    const degreeLevelError = validateStringProperty(
      existingDegreeLevel.degreeLevel,
      siblings,
      'degreeLevel'
    );
    const newValidationErrors = {
      degreeLevel: degreeLevelError
    };

    return newValidationErrors;
  };

  hanldeSave = () => {
    const { showValidationErrors, ...existingDegreeLevel } = this.state;
    const newValidationErrors = this.setValidationErrors(existingDegreeLevel);

    if (allFieldsAreValid(newValidationErrors)) {
      const payload = {
        uuid: existingDegreeLevel.uuid,
        degreeLevel: existingDegreeLevel.degreeLevel
      };
      this.props.onPutUpdate(payload);
      this.setState({ showValidationErrors: false });
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const { degreeLevel, order, uuid, showValidationErrors } = this.state;
    const { allDegreeLevels, toggleEditView } = this.props;

    const thisDegreeLevel = {
      uuid,
      degreeLevel,
      order
    };

    const siblings = allDegreeLevels.filter(
      degreeLevel => degreeLevel.uuid !== thisDegreeLevel.uuid
    );

    return (
      <EditDegreeLevel
        existingDegreeLevel={thisDegreeLevel}
        allDegreeLevels={siblings}
        handleChange={this.handleChange}
        onPutUpdate={this.hanldeSave}
        showValidationErrors={showValidationErrors}
        toggleEditView={toggleEditView}
      />
    );
  }
}

EditDegreeLevelContainer.propTypes = {
  degreeLevel: PropTypes.object,
  allDegreeLevels: PropTypes.arrayOf(PropTypes.object),
  toggleEditView: PropTypes.func,
  onPutUpdate: PropTypes.func
};
EditDegreeLevelContainer.defaultProps = {
  degreeLevel: {},
  allDegreeLevels: [],
  toggleEditView: undefined,
  onPutUpdate: undefined
};

const mapStateToProps = (state, props) => ({
  allDegreeLevels: degreeLevelsSelector(state, props)
});

const mapDispatchToProps = dispatch => ({
  onPutUpdate: updates => dispatch(doPutDegreeLevel(updates))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditDegreeLevelContainer);
export { EditDegreeLevelContainer };
