import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Microcompetency from './Microcompetency';
import { doOpenMoveCodeModal } from '../../../../redux/actions/moveCodeActions';
import { types } from '../../../../redux/reducers/moveCodeReducer';
import {
  doPatchPublishMicrocompetency,
  doDeleteMicrocompetency
} from '../../../../redux/actions/microcompetencyActions';
import { makeL2MicrocompetenciesSelector } from '../../../../redux/selectors/microcompetencySelectors';
import { doGeneralErrorNotification } from '../../../../redux/actions/notificationActions';

class MicrocompetencyContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: '',
      isPublishModalOpen: false,
      isDeleteModalOpen: false
    };

    this.toggleEditView = this.toggleEditView.bind(this);
    this.onPublish = this.onPublish.bind(this);
    this.handlePublishModalOpen = this.handlePublishModalOpen.bind(this);
    this.handlePublishModalClose = this.handlePublishModalClose.bind(this);
  }

  toggleEditView = () => {
    this.setState(prevState => ({
      view: prevState.view !== 'EDIT' ? 'EDIT' : ''
    }));
  };

  handlePublishModalOpen = () => {
    this.setState({
      isPublishModalOpen: true
    });
  };

  handlePublishModalClose = () => {
    this.setState({
      isPublishModalOpen: false
    });
  };

  onPublish = uuid => {
    this.props.onPostPublishMicrocompetency(uuid);
    this.handlePublishModalClose();
  };

  handleDeleteClick = () => {
    const { microL2Siblings, microcompetency } = this.props;
    if (
      microcompetency.level_two_code === '00' &&
      microL2Siblings &&
      microL2Siblings.length > 0
    ) {
      this.props.onDoGeneralErrorNotification(
        'Error: cannot delete an L1 code that has relative L2 codes, please delete or move them first'
      );
      return;
    }

    this.setState({
      isDeleteModalOpen: true
    });
  };

  handleDeleteModalClose = () => {
    this.setState({
      isDeleteModalOpen: false
    });
  };

  handleDelete = () => {
    const { microL2Siblings, microcompetency } = this.props;
    if (
      microcompetency.level_two_code === '00' &&
      microL2Siblings &&
      microL2Siblings.length > 0
    ) {
      this.props.onDoGeneralErrorNotification(
        'Error: cannot delete an L1 code that has relative L2 codes, please delete or move them first'
      );
      return;
    }

    this.props.onDeleteMicrocompetency(microcompetency.uuid);
    this.handleDeleteModalClose();
  };

  render() {
    const { view, isPublishModalOpen, isDeleteModalOpen } = this.state;
    const {
      microcompetency,
      subtopic,
      topic,
      discipline,
      cip,
      addMicrocompetency,
      onOpenMoveCodeModal
    } = this.props;
    return (
      <Microcompetency
        microcompetency={microcompetency}
        subtopic={subtopic}
        topic={topic}
        discipline={discipline}
        cip={cip}
        view={view}
        isPublishModalOpen={isPublishModalOpen}
        isDeleteModalOpen={isDeleteModalOpen}
        addMicrocompetency={addMicrocompetency}
        toggleEditView={this.toggleEditView}
        handlePublishModalOpen={this.handlePublishModalOpen}
        handlePublishModalClose={this.handlePublishModalClose}
        handleDeleteClick={this.handleDeleteClick}
        handleDeleteModalClose={this.handleDeleteModalClose}
        handleDelete={this.handleDelete}
        onPublish={this.onPublish}
        openMoveModal={(uuid, fullCode, parentCodeString) =>
          onOpenMoveCodeModal(uuid, fullCode, parentCodeString)
        }
      />
    );
  }
}

MicrocompetencyContainer.propTypes = {
  microcompetency: PropTypes.object,
  subtopic: PropTypes.object,
  topic: PropTypes.object,
  discipline: PropTypes.object,
  cip: PropTypes.object,
  microL2Siblings: PropTypes.arrayOf(PropTypes.object),
  addMicrocompetency: PropTypes.func,
  onPostPublishMicrocompetency: PropTypes.func,
  onOpenMoveCodeModal: PropTypes.func,
  onDeleteMicrocompetency: PropTypes.func,
  onDoGeneralErrorNotification: PropTypes.func
};

MicrocompetencyContainer.defaultProps = {
  microcompetency: {},
  subtopic: {},
  topic: {},
  discipline: {},
  cip: {},
  microL2Siblings: [],
  addMicrocompetency: undefined,
  onPostPublishMicrocompetency: undefined,
  onOpenMoveCodeModal: undefined,
  onDeleteMicrocompetency: undefined,
  onDoGeneralErrorNotification: undefined
};

const makeMapStateToProps = () => {
  const getL2SiblingMicros = makeL2MicrocompetenciesSelector();
  const mapStateToProps = (state, props) => ({
    microL2Siblings: getL2SiblingMicros(state, props),
    open: state.moveCodeState.open
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  onOpenMoveCodeModal: (microToMoveUuid, fullCodeToMove, parentCodeString) =>
    dispatch(
      doOpenMoveCodeModal(
        microToMoveUuid,
        types.micro,
        fullCodeToMove,
        parentCodeString
      )
    ),
  onPostPublishMicrocompetency: microUuid =>
    dispatch(doPatchPublishMicrocompetency(microUuid)),
  onDeleteMicrocompetency: microUuid =>
    dispatch(doDeleteMicrocompetency(microUuid)),
  onDoGeneralErrorNotification: error =>
    dispatch(doGeneralErrorNotification(error))
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(MicrocompetencyContainer);
export { MicrocompetencyContainer };
