import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EditProgram from './EditProgram';
import { doPutProgramCode } from '../../../../../redux/actions/programCodeActions';
import { makeDisciplineProgramCodesSelector } from '../../../../../redux/selectors/programCodeSelectors';
import {
  validateProgramCode,
  validateCodeDescription
} from '../../../../../helpers/validateCodes';
import {
  validateStringProperty,
  allFieldsAreValid
} from '../../../../../helpers/validateGeneric';

class EditProgramContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      uuid: '',
      code: '',
      title: '',
      description: '',
      relatedPrograms: [],
      showValidationErrors: false
    };

    this.setExisting = this.setExisting.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  componentDidMount() {
    const { code } = this.state;
    const { program } = this.props;
    this._isMounted = true;
    if (!code) {
      this.setExisting(program);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  handleRelatedProgramFieldChange = (values, fieldName) => {
    this.setState(() => ({
      [fieldName]: values
    }));
  };

  handleRelatedProgramAdd = program => {
    const { relatedPrograms } = this.state;
    const newPrograms = [
      ...relatedPrograms,
      { uuid: program.value, label: program.label }
    ];
    this.setState({
      relatedPrograms: newPrograms
    });
  };

  handleRelatedProgramRemove = uuid => {
    const { relatedPrograms } = this.state;
    const newPrograms = relatedPrograms.filter(
      program => program.uuid !== uuid
    );
    this.setState({
      relatedPrograms: newPrograms
    });
  };

  setExisting = program => {
    const { ...existingProgram } = program;
    const relatedPrograms = existingProgram.relatedPrograms.map(program => ({
      uuid: program.uuid,
      label: `${program.program_cipcode.code}.${
        program.program_discipline.code
      }${program.code} - ${program.title}`
    }));

    if (this._isMounted) {
      this.setState(() => ({
        ...existingProgram,
        relatedPrograms
      }));
    }
  };

  setValidationErrors = existingProgram => {
    const { allProgramCodes } = this.props;
    const { uuid } = this.state;
    const siblingProgramCodes = allProgramCodes.filter(
      program => program.uuid !== uuid
    );
    const titleError = validateStringProperty(
      existingProgram.title,
      siblingProgramCodes,
      'title'
    );
    const codeError = validateProgramCode(
      existingProgram.code,
      siblingProgramCodes,
      2
    );
    const descriptionError = validateCodeDescription(
      existingProgram.description
    );

    const newValidationErrors = {
      title: titleError,
      code: codeError,
      description: descriptionError
    };

    return newValidationErrors;
  };

  handleUpdate = () => {
    const {
      showValidationErrors,
      relatedPrograms,
      ...existingProgram
    } = this.state;
    const { discipline } = this.props;

    const newValidationErrors = this.setValidationErrors(existingProgram);

    if (allFieldsAreValid(newValidationErrors)) {
      const payload = {
        disciplineUuid: discipline.uuid,
        uuid: existingProgram.uuid,
        title: existingProgram.title,
        description: existingProgram.description,
        code: existingProgram.code,
        relatedPrograms: relatedPrograms.map(program => program.uuid)
      };
      this.props.onPutUpdate(payload);
      this.setState({ showValidationErrors: false });
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const {
      code,
      title,
      description,
      uuid,
      relatedPrograms,
      showValidationErrors
    } = this.state;
    const {
      program,
      discipline,
      cipcode,
      allProgramCodes,
      toggleEditView
    } = this.props;

    const thisProgram = {
      uuid,
      code,
      title,
      description,
      relatedPrograms
    };

    const siblingProgramCodes = allProgramCodes.filter(
      program => program.uuid !== thisProgram.uuid
    );

    return (
      <EditProgram
        cipcode={cipcode}
        discipline={discipline}
        program={program}
        existingProgram={thisProgram}
        allProgramCodes={siblingProgramCodes}
        handleChange={this.handleChange}
        handleRelatedProgramFieldChange={this.handleRelatedProgramFieldChange}
        handleRelatedProgramAdd={this.handleRelatedProgramAdd}
        handleRelatedProgramRemove={this.handleRelatedProgramRemove}
        onPutUpdate={this.handleUpdate}
        showValidationErrors={showValidationErrors}
        toggleEditView={toggleEditView}
      />
    );
  }
}

EditProgramContainer.propTypes = {
  program: PropTypes.object.isRequired,
  discipline: PropTypes.object.isRequired,
  cipcode: PropTypes.object.isRequired,
  allProgramCodes: PropTypes.arrayOf(PropTypes.object),
  toggleEditView: PropTypes.func,
  onPutUpdate: PropTypes.func
};
EditProgramContainer.defaultProps = {
  allProgramCodes: [],
  toggleEditView: undefined,
  onPutUpdate: undefined
};

const makeMapStateToProps = () => {
  const getProgramCodes = makeDisciplineProgramCodesSelector();
  const mapStateToProps = (state, props) => ({
    allProgramCodes: getProgramCodes(state, props)
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  onPutUpdate: updates => dispatch(doPutProgramCode(updates))
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(EditProgramContainer);
export { EditProgramContainer };
