import { Model, attr, fk } from 'redux-orm';
import {
  GET_DISCIPLINE_TOPICS_SUCCESS,
  POST_TOPIC_SUCCESS,
  PUT_TOPIC_SUCCESS,
  DELETE_TOPIC_SUCCESS,
  DELETE_DISCIPLINE_SUCCESS,
  DELETE_CIP_CODE_SUCCESS,
  PATCH_MOVE_TOPIC_SUCCESS
} from '../actions/actionTypes';

class Topic extends Model {
  static reducer(action, Topic, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_DISCIPLINE_TOPICS_SUCCESS: {
        const topics = [...payload.topics];
        return topics.map(topic => {
          const topicUpsert = Topic.upsert(topic);
          return topicUpsert;
        });
      }
      case POST_TOPIC_SUCCESS: {
        const newTopic = { ...payload.createdTopic };
        return Topic.upsert(newTopic);
      }
      case PUT_TOPIC_SUCCESS: {
        const updatedTopic = { ...payload.updatedTopic };
        return Topic.upsert(updatedTopic);
      }
      case DELETE_TOPIC_SUCCESS: {
        const { deletedTopic, updatedTopics } = payload;
        Topic.withId(deletedTopic.uuid).delete();
        return updatedTopics.map(topic => {
          const topicUpsert = Topic.upsert(topic);
          return topicUpsert;
        });
      }
      case DELETE_DISCIPLINE_SUCCESS: {
        const { deletedDiscipline } = payload;
        return Topic.all()
          .filter(topic => {
            if (
              !topic.disciplineUuid ||
              topic.disciplineUuid === deletedDiscipline.uuid
            ) {
              return topic;
            }
            return false;
          })
          .delete();
      }
      case PATCH_MOVE_TOPIC_SUCCESS: {
        const { movedCode, updatedCodes } = payload.updatedTopics;
        Topic.upsert(movedCode);
        return updatedCodes && updatedCodes.length > 0
          ? updatedCodes.map(topic => {
              const topicUpsert = Topic.upsert(topic);
              return topicUpsert;
            })
          : false;
      }
      case DELETE_CIP_CODE_SUCCESS: {
        const { deletedCipcode } = payload;
        if (
          deletedCipcode.disciplines &&
          deletedCipcode.disciplines.length > 0
        ) {
          deletedCipcode.disciplines.map(discipline =>
            Topic.all()
              .filter(topic => {
                if (
                  !topic.disciplineUuid ||
                  topic.disciplineUuid === discipline.uuid
                ) {
                  return topic;
                }
                return false;
              })
              .delete()
          );
        }
        return false;
      }
      default:
        return session;
    }
  }
}

Topic.fields = {
  uuid: attr(),
  title: attr(),
  level_one_code: attr(),
  level_two_code: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  disciplineUuid: fk('Discipline', 'topics')
};

Topic.modelName = 'Topic';
Topic.options = {
  idAttribute: 'uuid'
};

export default Topic;
