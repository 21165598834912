import { GET_USERS, GET_USERS_SUCCESS, GET_USERS_ERROR } from './actionTypes';

const doGetUsers = () => ({
  type: GET_USERS
});

const doGetUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: {
    users
  }
});

const doGetUsersError = error => ({
  type: GET_USERS_ERROR,
  payload: {
    error
  }
});

export { doGetUsers, doGetUsersSuccess, doGetUsersError };
