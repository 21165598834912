import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RowSummary } from '../../../SharedSC/DashboardSC';
import DegreeLevelForm from '../../DegreeLevelForm';
import { validateStringProperty } from '../../../../../helpers/validateGeneric';

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const NewDegreeLevel = ({
  className,
  newDegreeLevel,
  allDegreeLevels,
  showValidationErrors,
  handleChange,
  onPostCreate,
  clearNewForm,
  refProp
}) => (
  <Layout ref={refProp} className={className}>
    <RowSummary>
      <DegreeLevelForm
        degreeLevel={newDegreeLevel}
        entityLabel="Degree Level"
        buttonText="Create"
        showValidationErrors={showValidationErrors}
        validateDegreeLevel={() =>
          validateStringProperty(
            newDegreeLevel.degreeLevel,
            allDegreeLevels,
            'degreeLevel'
          )
        }
        handleChange={event => handleChange(event)}
        onSubmit={onPostCreate}
        onFormClose={clearNewForm}
      />
    </RowSummary>
  </Layout>
);

NewDegreeLevel.propTypes = {
  newDegreeLevel: PropTypes.object,
  allDegreeLevels: PropTypes.arrayOf(PropTypes.object),
  showValidationErrors: PropTypes.bool,
  className: PropTypes.string,
  refProp: PropTypes.func,
  handleChange: PropTypes.func,
  onPostCreate: PropTypes.func,
  clearNewForm: PropTypes.func
};
NewDegreeLevel.defaultProps = {
  newDegreeLevel: {},
  allDegreeLevels: [],
  showValidationErrors: false,
  className: '',
  refProp: undefined,
  handleChange: undefined,
  onPostCreate: undefined,
  clearNewForm: undefined
};

export default NewDegreeLevel;
