/* eslint no-param-reassign: off */
import { Model, attr, fk } from 'redux-orm';
import {
  GET_PROGRAM_DISCIPLINES_SUCCESS,
  POST_PROGRAM_DISCIPLINE_SUCCESS,
  PUT_PROGRAM_DISCIPLINE_SUCCESS,
  DELETE_PROGRAM_DISCIPLINE_SUCCESS,
  DELETE_PROGRAM_CIPCODE_SUCCESS
} from '../actions/actionTypes';

class ProgramDiscipline extends Model {
  static reducer(action, ProgramDiscipline, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_PROGRAM_DISCIPLINES_SUCCESS: {
        const disciplines = [...payload.programDisciplines];
        return disciplines.map(disc => {
          const discUpsert = ProgramDiscipline.upsert(disc);
          return discUpsert;
        });
      }
      case POST_PROGRAM_DISCIPLINE_SUCCESS: {
        const newProgramDiscipline = {
          ...payload.createdProgramDiscipline
        };
        return ProgramDiscipline.upsert(newProgramDiscipline);
      }
      case PUT_PROGRAM_DISCIPLINE_SUCCESS: {
        const updatedProgramDiscipline = {
          ...payload.updatedProgramDiscipline
        };
        return ProgramDiscipline.upsert(updatedProgramDiscipline);
      }
      case DELETE_PROGRAM_DISCIPLINE_SUCCESS: {
        const { deletedProgramDiscipline } = payload;
        return ProgramDiscipline.withId(deletedProgramDiscipline.uuid).delete();
      }
      case DELETE_PROGRAM_CIPCODE_SUCCESS: {
        const { deletedProgramCipcode } = payload;
        return ProgramDiscipline.all()
          .filter(discipline => {
            if (
              !discipline.cipcodeUuid ||
              discipline.cipcodeUuid === deletedProgramCipcode.uuid
            ) {
              return discipline;
            }
            return false;
          })
          .delete();
      }

      default:
        return session;
    }
  }
}

ProgramDiscipline.fields = {
  uuid: attr(),
  title: attr(),
  code: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  cipcodeUuid: fk('ProgramCipcode', 'disciplines')
};

ProgramDiscipline.modelName = 'ProgramDiscipline';
ProgramDiscipline.options = {
  idAttribute: 'uuid'
};

export default ProgramDiscipline;
