import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';

export const ormSelector = state => state.orm;

export const getSubtopicUuid = (state, props) => props.subtopic.uuid;
export const getMicro = (state, props) => props.microcompetency;

export const makeMicrocompetenciesSelector = () =>
  createSelector(
    state => state,
    getSubtopicUuid,
    (redux, subtopicUuid) => {
      const selectorORM = ormCreateSelector([orm], session =>
        session.Microcompetency.all()
          .filter(
            microcompetency => microcompetency.subtopicUuid === subtopicUuid
          )
          .toModelArray()
          .map(microcompetency => Object.assign({}, microcompetency.ref))
      );
      return selectorORM(redux);
    }
  );

export const makeL2MicrocompetenciesSelector = () =>
  createSelector(
    state => state,
    getMicro,
    (redux, micro) => {
      const selectorORM = ormCreateSelector([orm], session =>
        session.Microcompetency.all()
          .filter(
            microcompetency =>
              microcompetency.subtopicUuid === micro.subtopicUuid &&
              microcompetency.level_one_code === micro.level_one_code &&
              microcompetency.uuid !== micro.uuid
          )
          .toModelArray()
          .map(microcompetency => Object.assign({}, microcompetency.ref))
      );
      return selectorORM(redux);
    }
  );
