/* eslint no-unused-vars: off */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeGetSystemAdmin } from '../../../../../redux/selectors/userSelectors';
import ShowAdmin from '../../ShowAdmin';

class ShowSystemAdminContainer extends PureComponent {
  render() {
    const { uuid, className, systemAdmin } = this.props;
    return <ShowAdmin admin={systemAdmin} className={className} />;
  }
}

ShowSystemAdminContainer.propTypes = {
  uuid: PropTypes.string.isRequired,
  className: PropTypes.string,
  systemAdmin: PropTypes.object
};

ShowSystemAdminContainer.defaultProps = {
  className: '',
  systemAdmin: {}
};

const makeMapStateToProps = () => {
  const getSystemAdmin = makeGetSystemAdmin();

  const mapStateToProps = (state, props) => ({
    systemAdmin: getSystemAdmin(state, props)
  });
  return mapStateToProps;
};

export default connect(
  makeMapStateToProps,
  undefined
)(ShowSystemAdminContainer);
export { ShowSystemAdminContainer };
