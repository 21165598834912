import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Microcompetency from './Microcompetency';
import { doSetMoveCodeDestination } from '../../../../../redux/actions/moveCodeActions';
import { codeToMoveSelector } from '../../../../../redux/selectors/moveCodeSelectors';

const MicrocompetencyContainer = ({
  microcompetency,
  subtopic,
  topic,
  discipline,
  cip,
  codeToMove,
  destinationCode,
  onSetMoveCodeDestination
}) => (
  <Microcompetency
    microcompetency={microcompetency}
    subtopic={subtopic}
    topic={topic}
    discipline={discipline}
    cip={cip}
    codeToMove={codeToMove}
    onCodeSelect={onSetMoveCodeDestination}
    destinationCode={destinationCode}
  />
);

MicrocompetencyContainer.propTypes = {
  microcompetency: PropTypes.object,
  subtopic: PropTypes.object,
  topic: PropTypes.object,
  discipline: PropTypes.object,
  cip: PropTypes.object,
  codeToMove: PropTypes.object,
  destinationCode: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  onSetMoveCodeDestination: PropTypes.func
};

MicrocompetencyContainer.defaultProps = {
  microcompetency: {},
  subtopic: {},
  topic: {},
  discipline: {},
  cip: {},
  codeToMove: {
    title: '',
    code: ''
  },
  destinationCode: false,
  onSetMoveCodeDestination: undefined
};

const mapStateToProps = state => ({
  destinationCode: state.moveCodeState.destination,
  codeToMove: codeToMoveSelector(state)
});

const mapDispatchToProps = dispatch => ({
  onSetMoveCodeDestination: (microUuid, fullCode, isGeneralCode, isOtherCode) =>
    dispatch(
      doSetMoveCodeDestination(microUuid, fullCode, isGeneralCode, isOtherCode)
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MicrocompetencyContainer);
export { MicrocompetencyContainer };
