import fetch from "../helpers/fetch";

const getMicrocompetencies = subtopicUuid =>
  fetch(`/api/subtopic/${subtopicUuid}/microcompetencies/all`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      }
      throw new Error(`(status code: ${res.status})`);
    })
    .catch(error => {
      throw error;
    });

const postMicrocompetency = newMicro =>
  fetch(`/api/microcompetency/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(newMicro)
  })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      }
      throw new Error(`(status code: ${res.status})`);
    })
    .catch(error => {
      throw error;
    });

const putMicrocompetency = ({ uuid, ...microUpdates }) =>
  fetch(`/api/microcompetency/${uuid}/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(microUpdates)
  })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      }
      throw new Error(`(status code: ${res.status})`);
    })
    .catch(error => {
      throw error;
    });

const patchMoveMicrocompetency = ({ uuid, destinationCodeUuid, moveAbove }) =>
  fetch(`/api/microcompetency/${uuid}/move`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      destinationCodeUuid,
      moveAbove
    })
  })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      }
      throw new Error(`(status code: ${res.status})`);
    })
    .catch(error => {
      throw error;
    });

const patchPublishMicrocomptency = microUuid =>
  fetch(`/api/microcompetency/${microUuid}/publish`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      }
      throw new Error(`(status code: ${res.status})`);
    })
    .catch(error => {
      throw error;
    });

const deleteMicrocompetency = microUuid =>
  fetch(`/api/microcompetency/${microUuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({})
  }).then(res => {
    if (res.status === 200) {
      return res.json();
    } else {
      throw res;
    }
  });

export {
  getMicrocompetencies,
  postMicrocompetency,
  putMicrocompetency,
  patchMoveMicrocompetency,
  patchPublishMicrocomptency,
  deleteMicrocompetency
};
