/* eslint arrow-body-style: off, array-callback-return: off, no-unused-expressions: off */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Subtopic from './Subtopic';
import { generateNewCode } from '../../../../helpers/utilities';
import { doDeleteSubtopic } from '../../../../redux/actions/subtopicActions';
import { makeL2SubtopicsSelector } from '../../../../redux/selectors/subtopicSelectors';
import { makeMicrocompetenciesSelector } from '../../../../redux/selectors/microcompetencySelectors';
import { doGetMicrocompetencies } from '../../../../redux/actions/microcompetencyActions';
import { doClearSuccessfullyCreatedMicrocompetency } from '../../../../redux/actions/successfulCreationActions';
import { doClearSuccessfullyUpdatedMicrocompetency } from '../../../../redux/actions/successfulUpdateActions';
import { doGeneralErrorNotification } from '../../../../redux/actions/notificationActions';
import { doOpenMoveCodeModal } from '../../../../redux/actions/moveCodeActions';
import { types } from '../../../../redux/reducers/moveCodeReducer';

class SubtopicContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newMicro: null,
      addMicroAfterFetch: false,
      view: '',
      isDeleteModalOpen: false
    };
    this.getSubtopicMicros = this.getSubtopicMicros.bind(this);
    this.addMicrocompetency = this.addMicrocompetency.bind(this);
    this.clearAddMicroAfterFetch = this.clearAddMicroAfterFetch.bind(this);
    this.toggleEditView = this.toggleEditView.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { subtopic } = this.props;
    if (
      nextProps.successfullyUpdatedMicrocompetency &&
      nextProps.successfullyUpdatedMicrocompetency.subtopicUuid ===
        subtopic.uuid
    ) {
      this.props.onClearUpdatedMicrocompetency();

    }

    if (nextState.view !== this.state.view) {
      return true;
    }

    return true;
  }

  componentDidUpdate() {
    const {
      successfullyCreatedMicrocompetency,
      subtopic,
      microcompetencies
    } = this.props;
    const { addMicroAfterFetch } = this.state;
    if (
      addMicroAfterFetch &&
      microcompetencies &&
      microcompetencies.length > 0
    ) {
      this.addMicrocompetency(
        addMicroAfterFetch.subtopicUuid,
        addMicroAfterFetch.isLevelTwo,
        addMicroAfterFetch.levelOneParentCode
      );
      this.clearAddMicroAfterFetch();
    }

    if (
      successfullyCreatedMicrocompetency &&
      successfullyCreatedMicrocompetency.subtopicUuid === subtopic.uuid
    ) {
      this.clearNewMicro(successfullyCreatedMicrocompetency);
      this.props.onClearCreatedMicrocompetency();
    }
  }

  clearAddMicroAfterFetch = () => {
    this.setState({
      addMicroAfterFetch: false
    });
  };

  clearNewMicro = () => {
    this.setState({
      newMicro: null
    });
  };

  addMicrocompetency = (subtopicUuid, isLevelTwo, levelOneParentCode) => {
    const { microcompetencies, subtopic } = this.props;
    const { newMicro } = this.state;
    if (!microcompetencies || microcompetencies.length < 1) {
      this.getSubtopicMicros(subtopicUuid);
      this.setState({
        addMicroAfterFetch: {
          subtopicUuid,
          isLevelTwo,
          levelOneParentCode
        }
      });
      return;
    }

    if (newMicro) {
      this.props.onGeneralErrorNotification(
        'Cannot create more than one Microcompetency simultaneously'
      );
      return;
    }

    const newCodes = generateNewCode(
      subtopic.level_one_code,
      microcompetencies,
      isLevelTwo,
      levelOneParentCode
    );

    if (newCodes.error) {
      this.props.onGeneralErrorNotification(newCodes.message);
      return;
    }

    const emptyMicro = {
      level_one_code: newCodes.level_one_code,
      level_two_code: newCodes.level_two_code,
      title: '',
      description: '',
      subtopicUuid,
      isNew: true
    };
    this.setState({
      newMicro: emptyMicro
    });
  };

  getSubtopicMicros = subtopicUuid => {
    const { microcompetencies } = this.props;
    if (!microcompetencies || microcompetencies.length < 1) {
      this.props.onFetchMicros(subtopicUuid);
    }
  };

  toggleEditView = () => {
    this.setState(prevState => ({
      view: prevState.view !== 'EDIT' ? 'EDIT' : ''
    }));
  };

  handleDeleteClick = () => {
    const { subtopicL2Siblings, subtopic } = this.props;
    if (
      subtopic.level_two_code === '00' &&
      subtopicL2Siblings &&
      subtopicL2Siblings.length > 0
    ) {
      this.props.onGeneralErrorNotification(
        'Error: cannot delete an L1 code that has relative L2 codes, please delete or move them first'
      );
      return;
    }
    this.setState({
      isDeleteModalOpen: true
    });
  };

  handleDeleteModalClose = () => {
    this.setState({
      isDeleteModalOpen: false
    });
  };

  handleDelete = () => {
    const { subtopicL2Siblings, subtopic } = this.props;
    if (
      subtopic.level_two_code === '00' &&
      subtopicL2Siblings &&
      subtopicL2Siblings.length > 0
    ) {
      this.props.onGeneralErrorNotification(
        'Error: cannot delete an L1 code that has relative L2 codes, please delete or move them first'
      );
      return;
    }

    this.props.onDeleteSubtopic(subtopic.uuid);
    this.handleDeleteModalClose();
  };

  render() {
    const {
      microcompetencies,
      discipline,
      cip,
      topic,
      subtopic,
      onOpenMoveCodeModal,
      addSubtopic
    } = this.props;
    const { newMicro, view, isDeleteModalOpen } = this.state;

    const allMicros =
      newMicro && microcompetencies
        ? microcompetencies.concat(newMicro)
        : microcompetencies;
    return (
      <Subtopic
        microcompetencies={allMicros}
        subtopic={subtopic}
        topic={topic}
        discipline={discipline}
        cip={cip}
        view={view}
        isDeleteModalOpen={isDeleteModalOpen}
        getSubtopicMicros={this.getSubtopicMicros}
        addMicrocompetency={this.addMicrocompetency}
        addSubtopic={addSubtopic}
        clearNewMicro={this.clearNewMicro}
        toggleEditView={this.toggleEditView}
        handleDeleteClick={this.handleDeleteClick}
        handleDeleteModalClose={this.handleDeleteModalClose}
        handleDelete={this.handleDelete}
        openMoveModal={(uuid, fullCode, parentCodeString) =>
          onOpenMoveCodeModal(uuid, fullCode, parentCodeString)
        }
      />
    );
  }
}

SubtopicContainer.propTypes = {
  subtopic: PropTypes.object,
  topic: PropTypes.object,
  discipline: PropTypes.object,
  cip: PropTypes.object,
  subtopicL2Siblings: PropTypes.arrayOf(PropTypes.object),
  successfullyCreatedMicrocompetency: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]),
  successfullyUpdatedMicrocompetency: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]),
  onFetchMicros: PropTypes.func,
  onClearCreatedMicrocompetency: PropTypes.func,
  onClearUpdatedMicrocompetency: PropTypes.func,
  onGeneralErrorNotification: PropTypes.func,
  onDeleteSubtopic: PropTypes.func,
  addSubtopic: PropTypes.func,
  onOpenMoveCodeModal: PropTypes.func,
  microcompetencies: PropTypes.arrayOf(PropTypes.object)
};

SubtopicContainer.defaultProps = {
  subtopic: {},
  topic: {},
  discipline: {},
  cip: {},
  subtopicL2Siblings: [],
  successfullyCreatedMicrocompetency: false,
  successfullyUpdatedMicrocompetency: false,
  onFetchMicros: undefined,
  onClearCreatedMicrocompetency: undefined,
  onClearUpdatedMicrocompetency: undefined,
  onGeneralErrorNotification: undefined,
  onDeleteSubtopic: PropTypes.func,
  addSubtopic: undefined,
  onOpenMoveCodeModal: undefined,
  microcompetencies: []
};

const makeMapStateToProps = () => {
  const getSubtopicMicros = makeMicrocompetenciesSelector();
  const getL2SiblingSubtopics = makeL2SubtopicsSelector();
  const mapStateToProps = (state, props) => ({
    microcompetencies: getSubtopicMicros(state, props),
    subtopicL2Siblings: getL2SiblingSubtopics(state, props),
    moveModalOpen: state.moveCodeState.open,
    successfullyCreatedMicrocompetency:
      state.successfulCreationState.successfullyCreatedMicrocompetency,
    successfullyUpdatedMicrocompetency:
      state.successfulUpdateState.successfullyUpdatedMicrocompetency
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  onFetchMicros: subtopicUuid => dispatch(doGetMicrocompetencies(subtopicUuid)),
  onClearCreatedMicrocompetency: () =>
    dispatch(doClearSuccessfullyCreatedMicrocompetency()),
  onClearUpdatedMicrocompetency: () =>
    dispatch(doClearSuccessfullyUpdatedMicrocompetency()),
  onDeleteSubtopic: subtopicUuid => dispatch(doDeleteSubtopic(subtopicUuid)),
  onGeneralErrorNotification: errorMessage =>
    dispatch(doGeneralErrorNotification(errorMessage)),
  onOpenMoveCodeModal: (microToMoveUuid, fullCodeToMove, parentCodeString) =>
    dispatch(
      doOpenMoveCodeModal(
        microToMoveUuid,
        types.subtopic,
        fullCodeToMove,
        parentCodeString
      )
    )
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(SubtopicContainer);
export { SubtopicContainer, mapDispatchToProps };
