import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { doGeneralErrorNotification } from '../../../redux/actions/notificationActions';
import { doGetInstitutionalSystems } from '../../../redux/actions/institutionalSystemActions';
import { doGetInstitutions } from '../../../redux/actions/institutionActions';
import { doGetLocations } from '../../../redux/actions/locationActions';
import { doGetInstitutionTypes } from '../../../redux/actions/institutionTypeActions';
import { publishedCountriesSelector } from '../../../redux/selectors/locationsSelectors';

import InstitutionsDashboard from './InstitutionsDashboard';
import {
  doOpenNewSystemModal,
  doCloseSystemModal,
  doCloseInstitutionModal,
  doOpenNewInstitutionModal
} from '../../../redux/actions/institutionsDashboardActions';
import { doGetUsers } from '../../../redux/actions/userActions';

class InstitutionsDashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newInstitutionalSystem: null,
      newInstitution: null
    };

    this.clearNewState = this.clearNewState.bind(this);
    this.addInstitutionalSystem = this.addInstitutionalSystem.bind(this);
    this.addInstitution = this.addInstitution.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.onFetchLocations();
    this.props.onFetchInstitutionTypes();
    this.props.onFetchInstitutionalSystems();
    this.props.onFetchInstitutions();
    this.props.onFetchUsers();
  }

  clearNewState = attribute => {
    this.setState({ [attribute]: null });
    this.props.onCloseSystemModal();
  };

  addInstitutionalSystem = () => {
    const { newInstitutionalSystem } = this.state;
    const { systemModalView, institutionModalView } = this.props;
    const isModalOpen = systemModalView || institutionModalView;

    if (newInstitutionalSystem && isModalOpen) {
      this.props.onGeneralErrorNotification(
        'Cannot create more than one System or Institutiton simultaneously, click the x to cancel and open a new form. All unsaved changes will be lost.'
      );
    } else if (newInstitutionalSystem && !isModalOpen) {
      this.props.onOpenNewSystemModal();
    } else {
      const emptyInstitutionalSystem = {
        name: '',
        isNew: true
      };

      this.setState({
        newInstitutionalSystem: emptyInstitutionalSystem
      });
      this.props.onOpenNewSystemModal();
    }
  };

  addInstitution = () => {
    const { newInstitution } = this.state;
    const { systemModalView, institutionModalView } = this.props;

    const isModalOpen = systemModalView || institutionModalView;

    if (newInstitution && isModalOpen) {
      this.props.onGeneralErrorNotification(
        'Cannot create more than one System or Institutiton simultaneously, click the x to cancel and open a new form. All unsaved changes will be lost.'
      );
    } else if (newInstitution && !isModalOpen) {
      this.props.onOpenNewInstitutionModal();
    } else {
      const emptyInstitution = {
        name: '',
        city: '',
        institutionCode: '',
        institutionalSystemUuid: '',
        institutionSubytypeUuid: '',
        stateProvinceUuid: '',
        isNew: true
      };

      this.setState({
        newInstitution: emptyInstitution
      });
      this.props.onOpenNewInstitutionModal();
    }
  };

  render() {
    const {
      countries,
      systemModalView,
      institutionModalView,
      systemUuid,
      institutionUuid
    } = this.props;
    const { newInstitutionalSystem, newInstitution } = this.state;

    return (
      <InstitutionsDashboard
        countries={countries}
        systemUuid={systemUuid}
        institutionUuid={institutionUuid}
        systemModalView={systemModalView}
        institutionModalView={institutionModalView}
        newInstitutionalSystem={newInstitutionalSystem}
        newInstitution={newInstitution}
        handleSystemModalClose={this.props.onCloseSystemModal}
        clearNewState={this.clearNewState}
        addInstitutionalSystem={this.addInstitutionalSystem}
        addInstitution={this.addInstitution}
      />
    );
  }
}

InstitutionsDashboardContainer.propTypes = {
  systemModalView: PropTypes.oneOf(['', 'NEW', 'EDIT', 'SHOW']),
  institutionModalView: PropTypes.oneOf(['', 'NEW', 'EDIT', 'SHOW']),
  systemUuid: PropTypes.string,
  institutionUuid: PropTypes.string,
  countries: PropTypes.arrayOf(PropTypes.object),
  onFetchLocations: PropTypes.func,
  onFetchInstitutionalSystems: PropTypes.func,
  onFetchInstitutions: PropTypes.func,
  onFetchInstitutionTypes: PropTypes.func,
  onFetchUsers: PropTypes.func,
  onOpenNewSystemModal: PropTypes.func,
  onOpenNewInstitutionModal: PropTypes.func,
  onCloseSystemModal: PropTypes.func,
  onGeneralErrorNotification: PropTypes.func
};

InstitutionsDashboardContainer.defaultProps = {
  systemModalView: '',
  institutionModalView: '',
  systemUuid: null,
  institutionUuid: null,
  countries: [],
  onFetchLocations: undefined,
  onFetchInstitutionalSystems: undefined,
  onFetchInstitutions: undefined,
  onFetchInstitutionTypes: undefined,
  onFetchUsers: undefined,
  onOpenNewSystemModal: undefined,
  onOpenNewInstitutionModal: undefined,
  onCloseSystemModal: undefined,
  onGeneralErrorNotification: undefined
};

const mapStateToProps = state => ({
  countries: publishedCountriesSelector(state),
  systemModalView: state.institutionsDashboardState.systemModalView,
  institutionModalView: state.institutionsDashboardState.institutionModalView,
  systemUuid: state.institutionsDashboardState.systemUuid,
  institutionUuid: state.institutionsDashboardState.institutionUuid
});

const mapDispatchToProps = dispatch => ({
  onFetchLocations: () => dispatch(doGetLocations()),
  onFetchInstitutionTypes: () => dispatch(doGetInstitutionTypes()),
  onFetchInstitutionalSystems: () => dispatch(doGetInstitutionalSystems()),
  onFetchInstitutions: () => dispatch(doGetInstitutions()),
  onFetchUsers: () => dispatch(doGetUsers()),
  onOpenNewSystemModal: () => dispatch(doOpenNewSystemModal()),
  onOpenNewInstitutionModal: () => dispatch(doOpenNewInstitutionModal()),
  onCloseSystemModal: () => dispatch(doCloseSystemModal()),
  onCloseInstitutionModal: () => dispatch(doCloseInstitutionModal()),
  onGeneralErrorNotification: errorMessage =>
    dispatch(doGeneralErrorNotification(errorMessage))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstitutionsDashboardContainer);
export { InstitutionsDashboardContainer };
