import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import AccordionRowContainer from '../../../AccordionRow/AccordionRowContainer';
import DeleteModal from '../../../DialogModal/DeleteModal/DeleteModal';
import EditDisciplineContainer from './EditDiscipline/EditDisciplineContainer';
import ProgramCodeContainer from '../Program/ProgramContainer';
import NewProgramContainer from '../Program/NewProgram/NewProgramContainer';

const Layout = styled.div`
  width: 100%;
`;

const NoProgramsMessage = styled(({ rowOffset, ...rest }) => <div {...rest} />)`
  margin-left: ${props => props.rowOffset};
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  font-weight: 300;
`;

const Discipline = ({
  view,
  discipline,
  cipcode,
  programs,
  newProgram,
  isDeleteModalOpen,
  addProgramDiscipline,
  addProgram,
  handleDeleteClick,
  handleDeleteModalClose,
  handleDelete,
  getChildPrograms,
  toggleEditView,
  clearNew
}) => {
  const disciplineActions = [
    {
      label: 'Add Discipline',
      onClick: () => addProgramDiscipline(cipcode.uuid),
      shouldOpenOnClick: true,
      icon: 'add'
    },
    {
      label: 'Add Program',
      onClick: () => addProgram(),
      shouldOpenOnClick: true,
      icon: 'add'
    },
    {
      label: 'Edit',
      onClick: toggleEditView,
      shouldOpenOnClick: false,
      icon: 'edit'
    },
    {
      label: 'Delete',
      onClick: handleDeleteClick,
      shouldOpenOnClick: false,
      icon: 'delete'
    }
  ];

  const codeString = `${cipcode.code}.${discipline.code}`;
  const someUuid = uuidv4();
  return (
    <Layout>
      <DeleteModal
        modalOpen={isDeleteModalOpen}
        handleModalClose={() => handleDeleteModalClose()}
        handleDelete={() => handleDelete()}
        type="Discipline"
      />
      {view === 'EDIT' ? (
        <EditDisciplineContainer
          cipcode={cipcode}
          discipline={discipline}
          key={discipline.uuid}
          toggleEditView={toggleEditView}
        />
      ) : (
        <AccordionRowContainer
          label="Discipline"
          columnOne={codeString}
          columnTwo={discipline.title}
          actions={disciplineActions}
          expandWidth="42px"
          height="38px"
          rowOffset="60px"
          onRowOpen={() => getChildPrograms()}
        >
          {newProgram && (
            <NewProgramContainer
              key={someUuid}
              program={newProgram}
              discipline={discipline}
              cipcode={cipcode}
              allProgramCodes={programs}
              clearNew={clearNew}
            />
          )}
          {programs && programs.length > 0 ? (
            programs.map(program => (
              <ProgramCodeContainer
                key={program.uuid}
                program={program}
                discipline={discipline}
                cipcode={cipcode}
                addProgramCode={addProgram}
              />
            ))
          ) : (
            <NoProgramsMessage rowOffset="150px">
              No programs exist for this discipline
            </NoProgramsMessage>
          )}
        </AccordionRowContainer>
      )}
    </Layout>
  );
};

Discipline.propTypes = {
  discipline: PropTypes.object.isRequired,
  cipcode: PropTypes.object.isRequired,
  programs: PropTypes.arrayOf(PropTypes.object),
  newProgram: PropTypes.object,
  view: PropTypes.oneOf(['EDIT', '']),
  isDeleteModalOpen: PropTypes.bool,
  handleDeleteClick: PropTypes.func,
  handleDeleteModalClose: PropTypes.func,
  handleDelete: PropTypes.func,
  addProgramDiscipline: PropTypes.func,
  getChildPrograms: PropTypes.func,
  toggleEditView: PropTypes.func,
  addProgram: PropTypes.func,
  clearNew: PropTypes.func
};
Discipline.defaultProps = {
  newProgram: null,
  programs: [],
  view: '',
  isDeleteModalOpen: false,
  handleDeleteClick: undefined,
  handleDeleteModalClose: undefined,
  handleDelete: undefined,
  addProgramDiscipline: undefined,
  getChildPrograms: undefined,
  toggleEditView: undefined,
  addProgram: undefined,
  clearNew: undefined
};

export default Discipline;
