import { Model, attr } from 'redux-orm';
import {
  GET_INSTITUTIONAL_SYSTEMS_SUCCESS,
  POST_INSTITUTIONAL_SYSTEM_SUCCESS,
  PUT_INSTITUTIONAL_SYSTEM_SUCCESS,
  DELETE_INSTITUTIONAL_SYSTEM_SUCCESS
} from '../actions/actionTypes';

class InstitutionalSystem extends Model {
  static reducer(action, InstitutionalSystem, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_INSTITUTIONAL_SYSTEMS_SUCCESS: {
        InstitutionalSystem.all().delete();
        const systems = [...payload.institutionalSystems];
        return systems.map(({ institutional_system_admins, ...rest }) => {
          const institutionalSystemAdmins =
            institutional_system_admins &&
            institutional_system_admins.length > 0
              ? institutional_system_admins.map(admin => admin.uuid)
              : [];

          const newSystem = {
            institutionalSystemAdmins,
            ...rest
          };

          const systemUpsert = InstitutionalSystem.upsert(newSystem);
          return systemUpsert;
        });
      }
      case POST_INSTITUTIONAL_SYSTEM_SUCCESS: {
        const { createdInstitutionalSystem } = payload;

        const {
          institutional_system_admins,
          ...rest
        } = createdInstitutionalSystem;

        const institutionalSystemAdmins =
          institutional_system_admins && institutional_system_admins.length > 0
            ? institutional_system_admins.map(admin => admin.userUuid)
            : [];

        const newSystem = {
          institutionalSystemAdmins,
          ...rest
        };

        const systemUpsert = InstitutionalSystem.upsert(newSystem);
        return systemUpsert;
      }
      case PUT_INSTITUTIONAL_SYSTEM_SUCCESS: {
        const { updatedInstitutionalSystem } = payload;
        const {
          institutional_system_admins,
          ...rest
        } = updatedInstitutionalSystem;
        const institutionalSystemAdmins =
          institutional_system_admins && institutional_system_admins.length > 0
            ? institutional_system_admins.map(admin => admin.userUuid)
            : [];

        const systemUpdates = {
          institutionalSystemAdmins,
          ...rest
        };

        const systemUpsert = InstitutionalSystem.upsert(systemUpdates);
        return systemUpsert;
      }
      case DELETE_INSTITUTIONAL_SYSTEM_SUCCESS: {
        const { deletedInstitutionalSystem } = payload;

        return InstitutionalSystem.withId(
          deletedInstitutionalSystem.uuid
        ).delete();
      }
      default:
        return session;
    }
  }
}

InstitutionalSystem.fields = {
  uuid: attr(),
  name: attr(),
  websiteAddress: attr(),
  institutionSubtypeUuid: attr(),
  stateProvinceUuid: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

InstitutionalSystem.modelName = 'InstitutionalSystem';
InstitutionalSystem.options = {
  idAttribute: 'uuid'
};

export default InstitutionalSystem;
