import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ButtonInline, Heading2, Subtitle1 } from '@xcomp/xcomp-design-library';
import MMSelectionHierarchyContainer from './MMSelectionHierarchyContainer';
import SimpleModal from '../../../DialogModal/SimpleModal';
import { types } from '../../../../redux/reducers/moveCodeReducer';
import { CancelButton } from '../../../Forms/FormComponents';

const ModalBody = styled.div`
  && {
    width: 100%;
    padding: 1rem 1.25rem;
    height: 700px;
    display: flex;
    flex-direction: column;
    flex-wrap: no-wrap;
    align-items: flex-start;
    justify-content: space-between;

    h3 {
      margin-bottom: 0.5rem;
    }
  }
`;

const ModalHeader = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 66px;
  flex-basis: 66px;
  flex-shrink: 0;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  padding: 0 1.5rem;
`;

const Heading2SC = styled(Heading2)`
  margin: 0;
`;

const BodyContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
`;

const ModalSubheader = styled.div`
  && {
    width: 100%;
    height: 56px;
    flex-basis: 56px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
    padding-left: 1.5rem;
  }
`;

const Subtitle1SC = styled(Subtitle1)`
  margin: 0;
`;

const StyledMMSelectionHierarchy = styled(MMSelectionHierarchyContainer)`
  && {
    width: 100%;
    max-height: calc(100% - 56px);
    border-radius: 5px;
    flex-grow: 1;
    overflow-y: scroll;
  }
`;

const ModalButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const SaveButton = styled(ButtonInline)`
  && {
    margin-left: 1rem;
  }
`;

const SelectionModal = ({
  codeToMove,
  modalOpen,
  codeType,
  fullCodeToMove,
  parentCodeString,
  destinationCode,
  newDisciplineUuid,
  handleModalClose,
  handleMoveSubmit,
  handleMoveTopicToEmptyDiscipline
}) => {
  const isEmptyDisciplineMove = newDisciplineUuid && !destinationCode;
  return (
    <SimpleModal
      open={modalOpen}
      onClose={handleModalClose}
      maxWidth="md"
      fullWidth={true}
    >
      <>
        <ModalHeader>
          <Heading2SC>Move {codeType}</Heading2SC>
        </ModalHeader>
        <ModalBody>
          <BodyContainer>
            <ModalSubheader>
              <Subtitle1SC>
                {fullCodeToMove} - {codeToMove && codeToMove.title}
              </Subtitle1SC>
            </ModalSubheader>
            <StyledMMSelectionHierarchy
              codeType={codeType}
              isL2Code={codeToMove && codeToMove.level_two_code !== '00'}
              parentCodeString={parentCodeString}
            />
          </BodyContainer>
          <ModalButtons>
            <CancelButton onClick={() => handleModalClose()}>
              Cancel
            </CancelButton>
            {isEmptyDisciplineMove && handleMoveTopicToEmptyDiscipline ? (
              <SaveButton onClick={() => handleMoveTopicToEmptyDiscipline()}>
                Move To
              </SaveButton>
            ) : (
              <SaveButton
                onClick={() => handleMoveSubmit(false)}
                disabled={!destinationCode || destinationCode.isOtherCode}
              >
                Move Below
              </SaveButton>
            )}
            <SaveButton
              onClick={() => handleMoveSubmit(true)}
              disabled={!destinationCode || destinationCode.isGeneralCode}
            >
              Move Above
            </SaveButton>
          </ModalButtons>
        </ModalBody>
      </>
    </SimpleModal>
  );
};

SelectionModal.propTypes = {
  codeToMove: PropTypes.object,
  codeType: PropTypes.oneOf([types.topic, types.subtopic, types.micro, '']),
  fullCodeToMove: PropTypes.string,
  parentCodeString: PropTypes.string,
  newDisciplineUuid: PropTypes.string,
  modalOpen: PropTypes.bool,
  destinationCode: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  handleModalClose: PropTypes.func,
  handleMoveTopicToEmptyDiscipline: PropTypes.func,
  handleMoveSubmit: PropTypes.func
};

SelectionModal.defaultProps = {
  codeToMove: {
    title: '',
    code: ''
  },
  codeType: '',
  fullCodeToMove: '',
  parentCodeString: '',
  newDisciplineUuid: '',
  modalOpen: false,
  destinationCode: false,
  handleModalClose: undefined,
  handleMoveTopicToEmptyDiscipline: undefined,
  handleMoveSubmit: undefined
};

export default SelectionModal;
