import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  makeStateSubtypeUnaffiliatedInstitutionsSelector,
  makeGetSystemInstitutionsSelector
} from '../../../../redux/selectors/institutionsSelectors';
import { doDeleteInstitutionalSystem } from '../../../../redux/actions/institutionalSystemActions';
import {
  doOpenEditSystemModal,
  doOpenNewInstitutionModal
} from '../../../../redux/actions/institutionsDashboardActions';
import InstitutionalSystem from './InstitutionalSystem';

class InstitutionalSystemContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleteModalOpen: false
    };
  }

  handleDeleteClick = () => {
    this.setState({
      isDeleteModalOpen: true
    });
  };

  handleDeleteModalClose = () => {
    this.setState({
      isDeleteModalOpen: false
    });
  };

  handleSystemDelete = event => {
    const { institutionalSystem } = this.props;
    if (event) event.stopPropagation();
    this.props.onDeleteInstitutionalSystem(institutionalSystem.uuid);

    this.handleDeleteModalClose();
  };

  render() {
    const { isDeleteModalOpen } = this.state;
    const {
      institutionalSystem,
      stateProvinceUuid,
      institutionSubtypeUuid,
      institutions,
      isUnaffiliated,
      className
    } = this.props;

    return (
      <InstitutionalSystem
        className={className}
        institutionalSystem={institutionalSystem}
        institutions={institutions}
        stateProvinceUuid={stateProvinceUuid}
        institutionSubtypeUuid={institutionSubtypeUuid}
        isUnaffiliated={isUnaffiliated}
        isDeleteModalOpen={isDeleteModalOpen}
        handleDeleteClick={this.handleDeleteClick}
        handleDeleteModalClose={this.handleDeleteModalClose}
        handleSystemDelete={this.handleSystemDelete}
        onOpenEditSystemModal={
          !isUnaffiliated
            ? () =>
                this.props.onOpenEditSystemModal(
                  institutionalSystem.uuid,
                  institutionalSystem.stateProvinceUuid,
                  institutionalSystem.institutionSubtypeUuid
                )
            : undefined
        }
        onOpenNewInstitutionModal={this.props.onOpenNewInstitutionModal}
      />
    );
  }
}

InstitutionalSystemContainer.propTypes = {
  institutionalSystem: PropTypes.object.isRequired,
  institutions: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  stateProvinceUuid: PropTypes.string,
  institutionSubtypeUuid: PropTypes.string,
  isUnaffiliated: PropTypes.bool,
  onOpenNewInstitutionModal: PropTypes.func,
  onDeleteInstitutionalSystem: PropTypes.func,
  onOpenEditSystemModal: PropTypes.func
};

InstitutionalSystemContainer.defaultProps = {
  institutions: [],
  className: '',
  stateProvinceUuid: '',
  institutionSubtypeUuid: '',
  isUnaffiliated: false,
  onOpenNewInstitutionModal: undefined,
  onDeleteInstitutionalSystem: undefined,
  onOpenEditSystemModal: undefined
};

const makeMapStateToProps = () => {
  const getUnaffiliatedInstitutionsSelector = makeStateSubtypeUnaffiliatedInstitutionsSelector();
  const getSystemInstitutionsSelector = makeGetSystemInstitutionsSelector(); 
  const mapStateToProps = (state, props) => ({
    institutions: props.isUnaffiliated
      ? getUnaffiliatedInstitutionsSelector(state, props)
      : getSystemInstitutionsSelector(state, props)
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  onOpenEditSystemModal: (uuid, stateProvinceUuid, institutionSubtypeUuid) =>
    dispatch(
      doOpenEditSystemModal(uuid, stateProvinceUuid, institutionSubtypeUuid)
    ),
  onOpenNewInstitutionModal: (
    uuid,
    stateProvinceUuid,
    institutionSubtypeUuid
  ) =>
    dispatch(
      doOpenNewInstitutionModal(uuid, stateProvinceUuid, institutionSubtypeUuid)
    ),
  onDeleteInstitutionalSystem: systemUuid =>
    dispatch(doDeleteInstitutionalSystem(systemUuid))
});
export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(InstitutionalSystemContainer);
export { InstitutionalSystemContainer };
