import {
  GET_DISCIPLINE_TOPICS,
  GET_DISCIPLINE_TOPICS_SUCCESS,
  GET_DISCIPLINE_TOPICS_ERROR,
  POST_TOPIC,
  POST_TOPIC_SUCCESS,
  POST_TOPIC_ERROR,
  PUT_TOPIC,
  PUT_TOPIC_ERROR,
  PUT_TOPIC_SUCCESS,
  DELETE_TOPIC,
  DELETE_TOPIC_SUCCESS,
  DELETE_TOPIC_ERROR,
  PATCH_MOVE_TOPIC,
  PATCH_MOVE_TOPIC_SUCCESS,
  PATCH_MOVE_TOPIC_ERROR,
  PATCH_MOVE_TOPIC_EMPTY_DISCIPLINE,
  PATCH_MOVE_TOPIC_EMPTY_DISCIPLINE_SUCCESS
} from './actionTypes';

const doGetTopics = disciplineUuid => ({
  type: GET_DISCIPLINE_TOPICS,
  payload: {
    disciplineUuid
  }
});

const doGetTopicsSuccess = data => ({
  type: GET_DISCIPLINE_TOPICS_SUCCESS,
  payload: {
    topics: data.topics
  }
});

const doGetTopicsError = error => ({
  type: GET_DISCIPLINE_TOPICS_ERROR,
  payload: {
    error
  }
});

const doPostTopic = newTopic => ({
  type: POST_TOPIC,
  payload: {
    newTopic
  }
});

const doPostTopicSuccess = data => ({
  type: POST_TOPIC_SUCCESS,
  payload: {
    createdTopic: data.createdTopic
  }
});

const doPostTopicError = error => ({
  type: POST_TOPIC_ERROR,
  payload: {
    error
  }
});
const doPutTopic = topicUpdates => ({
  type: PUT_TOPIC,
  payload: {
    topicUpdates
  }
});

const doPutTopicSuccess = data => ({
  type: PUT_TOPIC_SUCCESS,
  payload: {
    updatedTopic: data.updatedTopic,
    updatedSubtopics: data.updatedSubtopics,
    updatedMicros: data.updatedMicros
  }
});

const doPutTopicError = error => ({
  type: PUT_TOPIC_ERROR,
  payload: {
    error
  }
});

const doPatchMoveTopic = (uuid, destinationCodeUuid, moveAbove) => ({
  type: PATCH_MOVE_TOPIC,
  payload: {
    uuid,
    destinationCodeUuid,
    moveAbove
  }
});

const doPatchMoveTopicToEmptyDiscipline = (uuid, newDisciplineUuid) => ({
  type: PATCH_MOVE_TOPIC_EMPTY_DISCIPLINE,
  payload: {
    uuid,
    newDisciplineUuid
  }
});

const doPatchMoveTopicSuccess = data => ({
  type: PATCH_MOVE_TOPIC_SUCCESS,
  payload: {
    movedTopic: data.movedTopic,
    updatedTopics: data.updatedTopics,
    previousDisciplineUuid: data.previousDisciplineUuid
  }
});

const doPatchMoveTopicToEmptyDisciplineSuccess = (uuid, response) => ({
  type: PATCH_MOVE_TOPIC_EMPTY_DISCIPLINE_SUCCESS,
  payload: {
    destinationDisciplineUuid: uuid,
    previousDisciplineUuid: response.previousDisciplineUuid,
    updatedTopics: response.updatedTopics
  }
});

const doPatchMoveTopicError = error => ({
  type: PATCH_MOVE_TOPIC_ERROR,
  payload: {
    error
  }
});

const doDeleteTopic = topicUuid => ({
  type: DELETE_TOPIC,
  payload: {
    topicUuid
  }
});

const doDeleteTopicSuccess = data => ({
  type: DELETE_TOPIC_SUCCESS,
  payload: {
    deletedTopic: data.deletedTopic,
    updatedTopics: data.updatedTopics
  }
});

const doDeleteTopicError = error => ({
  type: DELETE_TOPIC_ERROR,
  payload: {
    error
  }
});

export {
  doGetTopics,
  doGetTopicsSuccess,
  doGetTopicsError,
  doPostTopic,
  doPostTopicSuccess,
  doPostTopicError,
  doPutTopic,
  doPutTopicSuccess,
  doPutTopicError,
  doPatchMoveTopic,
  doPatchMoveTopicToEmptyDiscipline,
  doPatchMoveTopicToEmptyDisciplineSuccess,
  doPatchMoveTopicSuccess,
  doPatchMoveTopicError,
  doDeleteTopic,
  doDeleteTopicSuccess,
  doDeleteTopicError
};
