import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import EditInstitution from './EditInstitution';
import { doPutInstitution } from '../../../../../redux/actions/institutionActions';
import { makeGetInstitutionsInstitutionalSystemSelector } from '../../../../../redux/selectors/institutionalSystemsSelectors';
import {
  allInstitutionsSelector,
  makeEditInstitutionSelector
} from '../../../../../redux/selectors/institutionsSelectors';
import { makeSystemInstitutionSubtypeSelector } from '../../../../../redux/selectors/institutionTypesSelectors';
import { makeGetSystemStateProvinceSelector } from '../../../../../redux/selectors/locationsSelectors';
import {
  allFieldsAreValid,
  validateEntityName,
  validateRequired
} from '../../../../../helpers/validateGeneric';
import { validateInstitutionCode } from '../../../../../helpers/validateInstitutions';

class EditInstitutionContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      uuid: '',
      name: '',
      institutionCode: '',
      city: '',
      selectedCountryUuid: '',
      selectedInstitutionTypeUuid: '',
      institutionSubtypeUuid: '',
      stateProvinceUuid: '',
      institutionalSystemUuid: '',
      institutionAdmins: [],
      showValidationErrors: false
    };
  }

  componentDidMount() {
    const { name } = this.state;
    const { institution } = this.props;
    this._isMounted = true;
    if (!name && institution) {
      this.setExistingInstitution(institution);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { institution, locations, types } = this.props;

    if (
      prevState.selectedInstitutionTypeUuid &&
      this.state.selectedInstitutionTypeUuid !==
        prevState.selectedInstitutionTypeUuid
    ) {
      this.clearSubtypeAndSystem();
    }
    if (
      this.state.institutionSubtypeUuid !== prevState.institutionSubtypeUuid &&
      prevState.institutionSubtypeUuid !== ''
    ) {
      this.setAttribute('institutionalSystemUuid', '');
    }

    if (institution && !prevProps.institution) {
      this.setExistingInstitution(institution);
    } else if (
      institution &&
      prevProps.institution &&
      !_.isEqual(institution, prevProps.institution)
    ) {
      this.setExistingInstitution(institution);
    }

    if (locations.country.uuid && !prevProps.locations.country.uuid) {
      this.setAttribute('selectedCountryUuid', locations.country.uuid);
    }
    if (types.institutionType.uuid && !prevProps.types.institutionType.uuid) {
      this.setAttribute(
        'selectedInstitutionTypeUuid',
        types.institutionType.uuid
      );
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  handleUserFieldChange = (values, fieldName) => {
    this.setState(() => ({
      [fieldName]: values
    }));
  };

  setExistingInstitution = institution => {
    if (this._isMounted) {
      this.setState(() => ({
        ...institution
      }));
    }
  };

  setAttribute = (attribute, value) => {
    if (this._isMounted) {
      this.setState({
        [attribute]: value
      });
    }
  };

  clearSubtypeAndSystem = () => {
    this.setState({
      institutionSubtypeUuid: '',
      institutionalSystemUuid: ''
    });
  };

  setValidationErrors = existingInstitution => {
    const { allInstitutions } = this.props;
    let institutionalSystemError = false;

    const allSiblingSystems =
      allInstitutions && allInstitutions.length > 0
        ? allInstitutions.filter(
            institution => institution.uuid !== this.state.uuid
          )
        : [];

    const nameError = validateEntityName(
      existingInstitution.name,
      allSiblingSystems
    );
    const cityError = validateEntityName(existingInstitution.city, []);
    const institutionCodeError = validateInstitutionCode(
      existingInstitution.institutionCode
    );
    const subtypeError = validateRequired(
      existingInstitution.institutionSubtypeUuid
    );
    if (existingInstitution.institutionalSystemUuid) {
      institutionalSystemError = validateRequired(
        existingInstitution.institutionalSystemUuid
      );
    }
    const newValidationErrors = {
      name: nameError,
      city: cityError,
      institutionCode: institutionCodeError,
      institutionSubtypeUuid: subtypeError,
      institutionalSystemUuid: institutionalSystemError
    };

    return newValidationErrors;
  };

  handleUpdate = () => {
    const {
      showValidationErrors,
      isDeleteModalOpen,
      ...existingInstitution
    } = this.state;

    const newValidationErrors = this.setValidationErrors(existingInstitution);

    if (allFieldsAreValid(newValidationErrors)) {
      const payload = {
        uuid: existingInstitution.uuid,
        name: existingInstitution.name,
        institutionCode: existingInstitution.institutionCode,
        city: existingInstitution.city,
        institutionSubtypeUuid: existingInstitution.institutionSubtypeUuid,
        institutionAdmins: existingInstitution.institutionAdmins,
        institutionalSystemUuid: existingInstitution.institutionalSystemUuid
      };
      this.props.onPutInstitution(payload);
      this.setState({ showValidationErrors: false });
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const {
      uuid,
      name,
      institutionCode,
      city,
      selectedCountryUuid,
      selectedInstitutionTypeUuid,
      institutionSubtypeUuid,
      stateProvinceUuid,
      institutionalSystemUuid,
      institutionAdmins,
      showValidationErrors
    } = this.state;
    const {
      institutionalSystem,
      locations,
      types,
      systemUuid,
      isEditInstitutionModalOpen,
      handleModalClose,
      allInstitutions
    } = this.props;

    const existingInstitution = {
      uuid,
      name,
      institutionCode,
      city,
      selectedCountryUuid,
      selectedInstitutionTypeUuid,
      institutionSubtypeUuid,
      stateProvinceUuid,
      institutionalSystemUuid,
      institutionAdmins
    };

    let hasSystemParent = false;
    if (systemUuid && institutionalSystem) {
      hasSystemParent = {
        ...institutionalSystem
      };
    } else {
      hasSystemParent = {
        name: 'Unaffiliated'
      };
    }

    return (
      <EditInstitution
        existingInstitution={existingInstitution}
        systemUuid={systemUuid}
        isEditInstitutionModalOpen={isEditInstitutionModalOpen}
        locations={locations}
        types={types}
        hasSystemParent={hasSystemParent}
        handleModalClose={handleModalClose}
        handleChange={this.handleChange}
        handleUserFieldChange={this.handleUserFieldChange}
        onPutUpdate={this.handleUpdate}
        allInstitutions={allInstitutions}
        showValidationErrors={showValidationErrors}
      />
    );
  }
}

EditInstitutionContainer.propTypes = {
  institution: PropTypes.object,
  institutionalSystem: PropTypes.object,
  systemUuid: PropTypes.string,
  locations: PropTypes.object,
  types: PropTypes.object,
  allInstitutions: PropTypes.arrayOf(PropTypes.object),
  isEditInstitutionModalOpen: PropTypes.bool,
  handleModalClose: PropTypes.func,
  onPutInstitution: PropTypes.func
};
EditInstitutionContainer.defaultProps = {
  institution: {},
  institutionalSystem: null,
  systemUuid: '',
  locations: {
    country: {},
    stateProvince: {}
  },
  types: {
    institutionType: {},
    institutionSubtype: {}
  },
  allInstitutions: [],
  isEditInstitutionModalOpen: false,
  handleModalClose: undefined,
  onPutInstitution: undefined
};

const makeMapStateToProps = () => {
  const getInstitutionalSystem = makeGetInstitutionsInstitutionalSystemSelector();
  const getInstitution = makeEditInstitutionSelector();
  const getSystemStateProvinceSelector = makeGetSystemStateProvinceSelector();
  const getSystemInstitutionSubtypeSelector = makeSystemInstitutionSubtypeSelector();

  const mapStateToProps = (state, props) => ({
    institutionalSystem: getInstitutionalSystem(state, props),
    allInstitutions: allInstitutionsSelector(state, props),
    systemUuid: state.institutionsDashboardState.systemUuid,
    institution: getInstitution(state, props),
    locations: getSystemStateProvinceSelector(state, props),
    types: getSystemInstitutionSubtypeSelector(state, props)
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  onPutInstitution: updates => dispatch(doPutInstitution(updates))
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(EditInstitutionContainer);
export { EditInstitutionContainer };
