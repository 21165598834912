/* eslint react/no-unused-prop-types:off */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { doGetInstitutionTypes } from '../../../redux/actions/institutionTypeActions';
import { makeInstitutionSubtypesPublishedSelector } from '../../../redux/selectors/institutionTypesSelectors';
import InstitutionSubtypeSelect from './InstitutionSubtypeSelect';

class InstitutionSubtypeSelectContainer extends Component {
  componentDidMount() {
    const { institutionSubtypes } = this.props;
    if (!institutionSubtypes || institutionSubtypes.length < 1) {
      this.props.onFetchTypes();
    }
  }

  render() {
    const {
      disabled,
      institutionSubtypes,
      selectedInstitutionSubtype,
      showValidationErrors,
      handleChange,
      className
    } = this.props;
    return (
      <InstitutionSubtypeSelect
        className={className}
        disabled={disabled}
        institutionSubtypes={institutionSubtypes}
        selectedInstitutionSubtype={selectedInstitutionSubtype}
        showValidationErrors={showValidationErrors}
        handleChange={handleChange}
      />
    );
  }
}

InstitutionSubtypeSelectContainer.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  institutionType: PropTypes.object.isRequired,
  institutionSubtypes: PropTypes.arrayOf(PropTypes.object),
  showValidationErrors: PropTypes.bool,
  selectedInstitutionSubtype: PropTypes.string,
  onFetchTypes: PropTypes.func,
  handleChange: PropTypes.func
};

InstitutionSubtypeSelectContainer.defaultProps = {
  className: '',
  disabled: false,
  institutionSubtypes: [],
  showValidationErrors: false,
  selectedInstitutionSubtype: '',
  onFetchTypes: undefined,
  handleChange: undefined
};

const makeMapStateToProps = () => {
  const getInstitutionSubtypes = makeInstitutionSubtypesPublishedSelector();
  const mapStateToProps = (state, props) => ({
    institutionSubtypes: getInstitutionSubtypes(state, props)
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  onFetchTypes: () => dispatch(doGetInstitutionTypes())
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(InstitutionSubtypeSelectContainer);
export { InstitutionSubtypeSelectContainer };
