import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AccordionRowContainer from '../../../../AccordionRow/AccordionRowContainer';
import TopicContainer from '../Topic/TopicContainer';
import { sortItemsByLevelCodes } from '../../../../../helpers/arrayHelpers';
import { types } from '../../../../../redux/reducers/moveCodeReducer';

const DisciplineRowSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const Discipline = ({
  cip,
  discipline,
  topics,
  getDisciplineTopics,
  isCodeSelected,
  codeType,
  onCodeSelect
}) => (
  <DisciplineRowSC>
    {Number(discipline.notopics) < 1 && topics.length < 1 ? (
      <AccordionRowContainer
        label="Discipline"
        columnOne={`${cip.code}.${discipline.code}`}
        columnTwo={discipline.title}
        expandWidth="42px"
        height="38px"
        columnGutters="1.5rem"
        rowOffset="30px"
        leaf
        isSelected={isCodeSelected}
        onSelect={
          codeType === types.topic
            ? () => onCodeSelect(discipline.uuid)
            : undefined
        }
      />
    ) : (
      <AccordionRowContainer
        label="Discipline"
        columnOne={`${cip.code}.${discipline.code}`}
        columnTwo={discipline.title}
        expandWidth="42px"
        height="38px"
        columnGutters="1.5rem"
        rowOffset="30px"
        onRowOpen={() => getDisciplineTopics(discipline.uuid)}
      >
        {topics.sort(sortItemsByLevelCodes).map(topic => (
          <TopicContainer
            cip={cip}
            discipline={discipline}
            topic={topic}
            key={topic.uuid}
          />
        ))}
      </AccordionRowContainer>
    )}
  </DisciplineRowSC>
);

Discipline.propTypes = {
  discipline: PropTypes.object,
  cip: PropTypes.object,
  isCodeSelected: PropTypes.bool,
  codeType: PropTypes.oneOf([types.topic, types.subtopic, types.micro, '']),
  getDisciplineTopics: PropTypes.func,
  onCodeSelect: PropTypes.func,
  topics: PropTypes.arrayOf(PropTypes.object)
};

Discipline.defaultProps = {
  discipline: {},
  cip: {},
  isCodeSelected: false,
  codeType: '',
  getDisciplineTopics: undefined,
  onCodeSelect: undefined,
  topics: []
};

export default Discipline;
