import React from 'react';
import PropTypes from 'prop-types';

import { HeaderText } from '../../../SharedSC/HeaderSC';
import {
  SimpleModalSC,
  ModalHeaderText,
  ModalBody,
  FormLayout
} from '../../../SharedSC/ModalForm';
import { ModalHeader } from '../../../../Forms/FormComponents';

import InstitutionForm from '../InstitutionForm/InstitutionFormContainer';
import { validateEntityName } from '../../../../../helpers/validateGeneric';
import { validateInstitutionCode } from '../../../../../helpers/validateInstitutions';

const NewInstitution = ({
  newInstitution,
  isModalOpen,
  allInstitutions,
  hasSystemParent,
  types,
  locations,
  showValidationErrors,
  handleModalClose,
  handleChange,
  handleUserFieldChange,
  onPostCreateInstitution,
  clearNewInstitution
}) => {
  const onModalSubmit = () => {
    onPostCreateInstitution();
  };
  return (
    <SimpleModalSC
      open={isModalOpen}
      onClose={handleModalClose}
      maxWidth="lg"
      fullWidth
    >
      <ModalBody>
        <ModalHeader>
          <HeaderText>
            <ModalHeaderText>Create Institution</ModalHeaderText>
          </HeaderText>
        </ModalHeader>
        <FormLayout>
          <InstitutionForm
            types={types}
            locations={locations}
            institution={newInstitution}
            hasSystemParent={hasSystemParent}
            system={
              hasSystemParent && hasSystemParent.institutionalSystem
                ? hasSystemParent.institutionalSystem
                : {}
            }
            buttonText="Create"
            showValidationErrors={showValidationErrors}
            validateName={() =>
              validateEntityName(newInstitution.name, allInstitutions)
            }
            validateCity={() => validateEntityName(newInstitution.city, [])}
            validateInstitutionCode={() =>
              validateInstitutionCode(newInstitution.institutionCode)
            }
            handleChange={event => handleChange(event)}
            handleUserFieldChange={handleUserFieldChange}
            onSubmit={onModalSubmit}
            closeForm={clearNewInstitution}
          />
        </FormLayout>
      </ModalBody>
    </SimpleModalSC>
  );
};

NewInstitution.propTypes = {
  newInstitution: PropTypes.object,
  isModalOpen: PropTypes.bool,
  allInstitutions: PropTypes.arrayOf(PropTypes.object),
  showValidationErrors: PropTypes.bool,
  locations: PropTypes.object,
  types: PropTypes.object,
  hasSystemParent: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  handleChange: PropTypes.func,
  handleUserFieldChange: PropTypes.func,
  handleModalClose: PropTypes.func,
  onPostCreateInstitution: PropTypes.func,
  clearNewInstitution: PropTypes.func
};

NewInstitution.defaultProps = {
  newInstitution: {},
  isModalOpen: false,
  allInstitutions: [],
  showValidationErrors: false,
  locations: {
    country: {},
    stateProvince: {}
  },
  types: {
    institutionType: {},
    institutionSubtype: {}
  },
  hasSystemParent: false,
  handleModalClose: undefined,
  handleChange: undefined,
  handleUserFieldChange: undefined,
  onPostCreateInstitution: undefined,
  clearNewInstitution: undefined
};

export default NewInstitution;
