import {
  CLEAR_SUCCESFULLY_UPDATED_MICROCOMPETENCY,
  CLEAR_SUCCESFULLY_UPDATED_SUBTOPIC,
  CLEAR_SUCCESFULLY_UPDATED_TOPIC,
  CLEAR_SUCCESFULLY_UPDATED_DISCIPLINE,
  CLEAR_SUCCESFULLY_UPDATED_CIP_CODE
} from './actionTypes';

const doClearSuccessfullyUpdatedMicrocompetency = () => ({
  type: CLEAR_SUCCESFULLY_UPDATED_MICROCOMPETENCY
});

const doClearSuccessfullyUpdatedSubtopic = () => ({
  type: CLEAR_SUCCESFULLY_UPDATED_SUBTOPIC
});

const doClearSuccessfullyUpdatedTopic = () => ({
  type: CLEAR_SUCCESFULLY_UPDATED_TOPIC
});

const doClearSuccessfullyUpdatedDiscipline = () => ({
  type: CLEAR_SUCCESFULLY_UPDATED_DISCIPLINE
});

const doClearSuccessfullyUpdatedCipcode = () => ({
  type: CLEAR_SUCCESFULLY_UPDATED_CIP_CODE
});

export {
  doClearSuccessfullyUpdatedMicrocompetency,
  doClearSuccessfullyUpdatedSubtopic,
  doClearSuccessfullyUpdatedTopic,
  doClearSuccessfullyUpdatedDiscipline,
  doClearSuccessfullyUpdatedCipcode
};
