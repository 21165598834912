import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PublishModal from '../../../DialogModal/PublishModal/PublishModal';
import DeleteModal from '../../../DialogModal/DeleteModal/DeleteModal';
import AccordionRowContainer from '../../../AccordionRow/AccordionRowContainer';
import EditInstitutionSubtypeContainer from './EditInstitutionSubtype/EditInstitutionSubtypeContainer';

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const InstitutionSubtype = ({
  institutionSubtype,
  parentInstitutionType,
  view,
  className,
  isPublishModalOpen,
  isDeleteModalOpen,
  handlePublishModalOpen,
  handlePublishModalClose,
  handleDeleteClick,
  handleDeleteModalClose,
  handleConfirmDelete,
  onPublish,
  toggleEditView
}) => {
  const institutionSubtypeActions = [
    {
      label: 'Edit',
      onClick: toggleEditView,
      shouldOpenOnClick: false,
      icon: 'edit'
    },
    {
      label: 'Publish',
      onClick: handlePublishModalOpen,
      shouldOpenOnClick: false,
      icon: 'edit'
    },
    {
      label: 'Delete',
      onClick: handleDeleteClick,
      shouldOpenOnClick: false,
      icon: 'delete'
    }
  ];

  return (
    <Layout className={className}>
      <PublishModal
        isModalOpen={isPublishModalOpen}
        handleModalClose={handlePublishModalClose}
        handlePublish={onPublish}
        entityLabel="Subtype"
        publishConfirmMessage="This will make the Subtype available to institutions and lock the Subtype from being deleted."
      />
      <DeleteModal
        modalOpen={isDeleteModalOpen}
        handleModalClose={() => handleDeleteModalClose()}
        handleDelete={() => handleConfirmDelete()}
        type="Subtype"
      />
      {view === 'EDIT' ? (
        <EditInstitutionSubtypeContainer
          institutionType={parentInstitutionType}
          institutionSubtype={institutionSubtype}
          key={institutionSubtype.uuid}
          toggleEditView={toggleEditView}
        />
      ) : (
        <AccordionRowContainer
          columnTwo={institutionSubtype.name}
          columnGutters="1.5rem"
          actions={institutionSubtypeActions}
          expandWidth="42px"
          height="38px"
          rowOffset="70px"
          isPublished={institutionSubtype.published}
          leaf
        />
      )}
    </Layout>
  );
};

InstitutionSubtype.propTypes = {
  institutionSubtype: PropTypes.object.isRequired,
  parentInstitutionType: PropTypes.object.isRequired,
  view: PropTypes.oneOf(['EDIT', '']),
  className: PropTypes.string,
  isPublishModalOpen: PropTypes.bool,
  isDeleteModalOpen: PropTypes.bool,
  handlePublishModalOpen: PropTypes.func,
  handlePublishModalClose: PropTypes.func,
  handleDeleteClick: PropTypes.func,
  handleDeleteModalClose: PropTypes.func,
  handleConfirmDelete: PropTypes.func,
  onPublish: PropTypes.func,
  toggleEditView: PropTypes.func
};

InstitutionSubtype.defaultProps = {
  view: '',
  className: '',
  isPublishModalOpen: PropTypes.bool,
  isDeleteModalOpen: false,
  handlePublishModalOpen: undefined,
  handlePublishModalClose: undefined,
  handleDeleteClick: undefined,
  handleDeleteModalClose: undefined,
  handleConfirmDelete: undefined,
  onPublish: PropTypes.func,
  toggleEditView: undefined
};

export default InstitutionSubtype;
