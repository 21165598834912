import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { sortByProperty } from '../../helpers/utilities';
import { orm } from '../models';

export const ormSelector = state => state.orm;

// export const institutionTypesSelector = createSelector(
//   ormSelector,
//   ormCreateSelector(orm, session => {
//     return session.InstitutionType.all()
//       .toModelArray()
//       .map(institutionType => Object.assign({}, institutionType.ref))
//       .sort((a, b) => sortByProperty(a, b, 'order'));
//   })
// );

export const institutionTypesSelector = createSelector(
  state => state,
  redux => {
    const selectorORM = ormCreateSelector([orm], session => {
      return session.InstitutionType.all()
        .toModelArray()
        .map(institutionType => Object.assign({}, institutionType.ref))
        .sort((a, b) => sortByProperty(a, b, 'order'));
    });
    return selectorORM(redux);
  }
);

// export const publishedInstitutionTypesSelector = createSelector(
//   ormSelector,
//   ormCreateSelector(orm, session => {
//     return session.InstitutionType.all()
//       .toModelArray()
//       .filter(institutionType => institutionType.published)
//       .map(institutionType => Object.assign({}, institutionType.ref))
//       .sort((a, b) => sortByProperty(a, b, 'order'));
//   })
// );

export const publishedInstitutionTypesSelector = createSelector(
  state => state,
  redux => {
    const selectorORM = ormCreateSelector([orm], session => {
      return session.InstitutionType.all()
        .toModelArray()
        .filter(institutionType => institutionType.published)
        .map(institutionType => Object.assign({}, institutionType.ref))
        .sort((a, b) => sortByProperty(a, b, 'order'));
    });
    return selectorORM(redux);
  }
);

export const getInstitutionTypeUuid = (state, props) =>
  props.institutionType.uuid;

// export const makeInstitutionSubtypesSelector = () =>
//   createSelector(
//     ormSelector,
//     getInstitutionTypeUuid,
//     ormCreateSelector(orm, (session, institutionTypeUuid) =>
//       session.InstitutionSubtype.all()
//         .filter(subtype => subtype.institutionTypeUuid === institutionTypeUuid)
//         .toModelArray()
//         .map(subtype => Object.assign({}, subtype.ref))
//         .sort((a, b) => sortByProperty(a, b, 'name'))
//     )
//   );

export const makeInstitutionSubtypesSelector = () =>
  createSelector(
    state => state,
    getInstitutionTypeUuid,
    (redux, institutionTypeUuid) => {
      const selectorORM = ormCreateSelector([orm], session =>
        session.InstitutionSubtype.all()
          .filter(
            subtype => subtype.institutionTypeUuid === institutionTypeUuid
          )
          .toModelArray()
          .map(subtype => Object.assign({}, subtype.ref))
          .sort((a, b) => sortByProperty(a, b, 'name'))
      );
      return selectorORM(redux);
    }
  );

// export const makeInstitutionSubtypesPublishedSelector = () =>
//   createSelector(
//     ormSelector,
//     getInstitutionTypeUuid,
//     ormCreateSelector(orm, (session, institutionTypeUuid) =>
//       session.InstitutionSubtype.all()
//         .filter(subtype => subtype.institutionTypeUuid === institutionTypeUuid)
//         .filter(subtype => subtype.published)
//         .toModelArray()
//         .map(subtype => Object.assign({}, subtype.ref))
//         .sort((a, b) => sortByProperty(a, b, 'name'))
//     )
//   );

export const makeInstitutionSubtypesPublishedSelector = () =>
  createSelector(
    state => state,
    getInstitutionTypeUuid,
    (redux, institutionTypeUuid) => {
      const selectorORM = ormCreateSelector([orm], session =>
        session.InstitutionSubtype.all()
          .filter(
            subtype => subtype.institutionTypeUuid === institutionTypeUuid
          )
          .filter(subtype => subtype.published)
          .toModelArray()
          .map(subtype => Object.assign({}, subtype.ref))
          .sort((a, b) => sortByProperty(a, b, 'name'))
      );
      return selectorORM(redux);
    }
  );

export const getSystemInstitutionTypeUuid = state =>
  state.institutionsDashboardState.institutionSubtypeUuid;

// export const makeSystemInstitutionSubtypeSelector = () => {
//   return createSelector(
//     ormSelector,
//     getSystemInstitutionTypeUuid,
//     ormCreateSelector(orm, (session, institutionSubtypeUuid) => {
//       const subtype = session.InstitutionSubtype.all()
//         .filter(subtype => subtype.uuid === institutionSubtypeUuid)
//         .toRefArray();

//       const type = subtype[0]
//         ? session.InstitutionType.all()
//             .filter(type => type.uuid === subtype[0].institutionTypeUuid)
//             .toRefArray()
//         : [{}];

//       return { institutionSubtype: subtype[0], institutionType: type[0] };
//     })
//   );
// };

export const makeSystemInstitutionSubtypeSelector = () => {
  return createSelector(
    state => state,
    getSystemInstitutionTypeUuid,
    (redux, institutionSubtypeUuid) => {
      const selectorORM = ormCreateSelector([orm], session => {
        const subtype = session.InstitutionSubtype.all()
          .filter(subtype => subtype.uuid === institutionSubtypeUuid)
          .toRefArray();

        const type = subtype[0]
          ? session.InstitutionType.all()
              .filter(type => type.uuid === subtype[0].institutionTypeUuid)
              .toRefArray()
          : [{}];

        return { institutionSubtype: subtype[0], institutionType: type[0] };
      });
      return selectorORM(redux);
    }
  );
};
