import { Model, attr } from 'redux-orm';
import {
  GET_INSTITUTIONS_SUCCESS,
  POST_INSTITUTION_SUCCESS,
  PUT_INSTITUTION_SUCCESS,
  DELETE_INSTITUTION_SUCCESS
} from '../actions/actionTypes';

class Institution extends Model {
  static reducer(action, Institution, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_INSTITUTIONS_SUCCESS: {
        Institution.all().delete();
        const institutions = [...payload.institutions];
        return institutions.map(({ institution_admins, ...rest }) => {
          const institutionAdmins =
            institution_admins && institution_admins.length > 0
              ? institution_admins.map(admin => admin.uuid)
              : [];

          const newInstitution = {
            institutionAdmins,
            ...rest
          };

          const institutionUpsert = Institution.upsert(newInstitution);
          return institutionUpsert;
        });
      }
      case POST_INSTITUTION_SUCCESS: {
        const { createdInstitution } = payload;

        const { institution_admins, ...rest } = createdInstitution;

        const institutionAdmins =
          institution_admins && institution_admins.length > 0
            ? institution_admins.map(admin => admin.userUuid)
            : [];

        const newInstitution = {
          institutionAdmins,
          ...rest
        };

        const institutionUpsert = Institution.upsert(newInstitution);
        return institutionUpsert;
      }
      case PUT_INSTITUTION_SUCCESS: {
        const { updatedInstitution } = payload;
        const { institution_admins, ...rest } = updatedInstitution;
        const institutionAdmins =
          institution_admins && institution_admins.length > 0
            ? institution_admins.map(admin => admin.userUuid)
            : [];

        const systemUpdates = {
          institutionAdmins,
          ...rest
        };

        const institutionUpsert = Institution.upsert(systemUpdates);
        return institutionUpsert;
      }
      case DELETE_INSTITUTION_SUCCESS: {
        const { deletedInstitution } = payload;

        return Institution.withId(deletedInstitution.uuid).delete();
      }
      default:
        return session;
    }
  }
}

Institution.fields = {
  uuid: attr(),
  name: attr(),
  city: attr(),
  institutionCode: attr(),
  institutionSubtypeUuid: attr(),
  stateProvinceUuid: attr(),
  institutionalSystemUuid: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

Institution.modelName = 'Institution';
Institution.options = {
  idAttribute: 'uuid'
};

export default Institution;
