import { call, put } from 'redux-saga/effects';
import {
  getInstitutionTypes,
  postInstitutionType,
  postInstitutionSubtype,
  putInstitutionType,
  putInstitutionSubtype,
  patchMoveInstitutionType,
  deleteInstitutionType,
  deleteInstitutionSubtype
} from '../../api/institutionTypesApi';
import {
  doGetInstitutionTypesSuccess,
  doGetInstitutionTypesError,
  doPostInstitutionTypeSuccess,
  doPostInstitutionTypeError,
  doPostInstitutionSubtypeSuccess,
  doPostInstitutionSubtypeError,
  doPutInstitutionSubtypeSuccess,
  doPutInstitutionSubtypeError,
  doPutInstitutionTypeSuccess,
  doPutInstitutionTypeError,
  doPatchMoveInstitutionTypeSuccess,
  doPatchMoveInstitutionTypeError,
  doDeleteInstitutionTypeSuccess,
  doDeleteInstitutionTypeError,
  doDeleteInstitutionSubtypeSuccess,
  doDeleteInstitutionSubtypeError
} from '../actions/institutionTypeActions';

function* handleFetchInstitutionTypes() {
  try {
    const result = yield call(getInstitutionTypes);
    yield put(doGetInstitutionTypesSuccess(result));
  } catch (error) {
    yield put(doGetInstitutionTypesError(error));
  }
}

function* handlePostCreateInstitutionType(action) {
  const { newInstitutionType } = action.payload;

  try {
    const result = yield call(postInstitutionType, newInstitutionType);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostInstitutionTypeSuccess(payload));
    } else {
      const payload = yield result.json();
      yield put(doPostInstitutionTypeError(payload));
    }
  } catch (error) {
    yield put(doPostInstitutionTypeError());
  }
}

function* handlePostCreateInstitutionSubtype(action) {
  const { newInstitutionSubtype } = action.payload;

  try {
    const result = yield call(postInstitutionSubtype, newInstitutionSubtype);

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doPostInstitutionSubtypeSuccess(payload));
    } else {
      const payload = yield result.json();
      yield put(doPostInstitutionSubtypeError(payload));
    }
  } catch (error) {
    yield put(doPostInstitutionSubtypeError());
  }
}

function* handlePutUpdateInstitutionType(action) {
  const { institutionType } = action.payload;

  try {
    const result = yield call(putInstitutionType, institutionType);

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doPutInstitutionTypeSuccess(payload));
    } else {
      const payload = yield result.json();
      yield put(doPutInstitutionTypeError(payload));
    }
  } catch (error) {
    yield put(doPutInstitutionTypeError());
  }
}

function* handlePutUpdateInstitutionSubtype(action) {
  const { institutionSubtype } = action.payload;

  try {
    const result = yield call(putInstitutionSubtype, institutionSubtype);

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doPutInstitutionSubtypeSuccess(payload));
    } else {
      const payload = yield result.json();
      yield put(doPutInstitutionSubtypeError(payload));
    }
  } catch (error) {
    yield put(doPutInstitutionSubtypeError());
  }
}

function* handlePatchMoveInstitutionType(action) {
  const { uuid, destinationOrder } = action.payload;

  try {
    const result = yield call(patchMoveInstitutionType, uuid, destinationOrder);

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doPatchMoveInstitutionTypeSuccess(payload));
    } else {
      const payload = yield result.json();
      yield put(doPatchMoveInstitutionTypeError(payload));
    }
  } catch (error) {
    yield put(doPatchMoveInstitutionTypeError());
  }
}

function* handleDeleteInstitutionType(action) {
  const { uuid } = action.payload;

  try {
    const result = yield call(deleteInstitutionType, uuid);

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doDeleteInstitutionTypeSuccess(payload));
    } else {
      const errorText = yield result.text();
      yield put(
        doDeleteInstitutionTypeError({
          text: errorText
        })
      );
    }
  } catch (error) {
    yield put(doDeleteInstitutionTypeError());
  }
}

function* handleDeleteInstitutionSubtype(action) {
  const { uuid } = action.payload;

  try {
    const result = yield call(deleteInstitutionSubtype, uuid);

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doDeleteInstitutionSubtypeSuccess(payload));
    } else {
      const errorText = yield result.text();

      yield put(
        doDeleteInstitutionSubtypeError({
          text: errorText
        })
      );
    }
  } catch (error) {
    yield put(doDeleteInstitutionSubtypeError());
  }
}

export {
  handleFetchInstitutionTypes,
  handlePostCreateInstitutionType,
  handlePostCreateInstitutionSubtype,
  handlePutUpdateInstitutionType,
  handlePutUpdateInstitutionSubtype,
  handlePatchMoveInstitutionType,
  handleDeleteInstitutionType,
  handleDeleteInstitutionSubtype
};
