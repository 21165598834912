import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { Heading1 } from '@xcomp/xcomp-design-library/dist/components/Typography';
import { Droppable } from 'react-beautiful-dnd';

import {
  HeaderWrapper,
  HeaderLayout,
  HeaderText,
  ButtonHeader,
  ButtonPrimarySC,
  PageActions
} from '../SharedSC/HeaderSC';
import {
  Panel,
  PanelHeader,
  PanelLabel,
  Page,
  Wrapper
} from '../SharedSC/DashboardSC';
import PageHeader from '../../Header/PageHeader';
import InstitutionType from './InstitutionType/InstitutionTypeContainer';
import NoItemsMessage from '../../NoItemsMessage/NoItemsMessage';
import NewInstitutionTypeContainer from './InstitutionType/NewInstitutionType/NewInstitutionTypeContainer';

const InstitutionTypeDashboard = ({
  institutionTypes,
  newInstitutionType,
  addInstitutionType,
  clearNewInstitutionType
}) => {
  const noItemsMessage = 'There are currently no Institution Types created.';
  const someUuid = uuidv4();
  const allInstitutionTypes = newInstitutionType
    ? institutionTypes.concat(newInstitutionType)
    : institutionTypes;
  return (
    <Page>
      <PageHeader>
        <HeaderWrapper>
          <HeaderLayout>
            <HeaderText>
              <Heading1>Institution Type/Subtype</Heading1>
            </HeaderText>
          </HeaderLayout>
        </HeaderWrapper>
      </PageHeader>
      <PageActions>
        <HeaderWrapper>
          <ButtonHeader>
            <ButtonPrimarySC onClick={addInstitutionType}>
              Add Type
            </ButtonPrimarySC>
          </ButtonHeader>
        </HeaderWrapper>
      </PageActions>
      <Wrapper>
        {allInstitutionTypes && allInstitutionTypes.length > 0 ? (
          <Droppable droppableId={someUuid}>
            {(provided, snapshot) => (
              <Panel ref={provided.innerRef} {...provided.droppableProps}>
                <PanelHeader>
                  <PanelLabel marginLeft="20px">Type</PanelLabel>
                </PanelHeader>
                {newInstitutionType && (
                  <NewInstitutionTypeContainer
                    key={someUuid}
                    institutionType={newInstitutionType}
                    allInstitutionTypes={institutionTypes}
                    clearNewForm={clearNewInstitutionType}
                  />
                )}
                {institutionTypes.map((type, index) => (
                  <InstitutionType
                    key={type.uuid}
                    dragIndex={index}
                    institutionType={type}
                    allInstitutionTypes={institutionTypes}
                    isDraggingOver={snapshot.isDraggingOver}
                  />
                ))}
                {provided.placeholder}
              </Panel>
            )}
          </Droppable>
        ) : (
          <Panel>
            <NoItemsMessage message={noItemsMessage} />
          </Panel>
        )}
      </Wrapper>
    </Page>
  );
};

InstitutionTypeDashboard.propTypes = {
  institutionTypes: PropTypes.arrayOf(PropTypes.object),
  newInstitutionType: PropTypes.object,
  addInstitutionType: PropTypes.func,
  clearNewInstitutionType: PropTypes.func
};

InstitutionTypeDashboard.defaultProps = {
  institutionTypes: [],
  newInstitutionType: {},
  addInstitutionType: undefined,
  clearNewInstitutionType: undefined
};

export default InstitutionTypeDashboard;
