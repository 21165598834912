import { Model, attr } from 'redux-orm';
import {
  GET_CIP_CODES_SUCCESS,
  POST_CIP_CODE_SUCCESS,
  PUT_CIP_CODE_SUCCESS,
  DELETE_CIP_CODE_SUCCESS,
  CLEAR_CIP_CODES
} from '../actions/actionTypes';

class CipCode extends Model {
  static reducer(action, CipCode, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_CIP_CODES_SUCCESS: {
        CipCode.all().delete();

        const cips = [...payload.cips];
        return cips.map(cip => {
          const cipUpsert = CipCode.upsert(cip);
          return cipUpsert;
        });
      }
      case POST_CIP_CODE_SUCCESS: {
        const newCipcode = { ...payload.createdCipcode };
        return CipCode.upsert(newCipcode);
      }
      case PUT_CIP_CODE_SUCCESS: {
        const updatedCipcode = { ...payload.updatedCipcode };
        return CipCode.upsert(updatedCipcode);
      }
      case CLEAR_CIP_CODES: {
        CipCode.all().delete();
        break;
      }
      case DELETE_CIP_CODE_SUCCESS: {
        const { deletedCipcode } = action.payload;
        return CipCode.withId(deletedCipcode.uuid).delete();
      }
      default:
        return session;
    }
  }
}

CipCode.fields = {
  uuid: attr(),
  title: attr(),
  code: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

CipCode.modelName = 'CipCode';
CipCode.options = {
  idAttribute: 'uuid'
};

export default CipCode;
