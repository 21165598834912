import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { doGeneralErrorNotification } from '../../../../redux/actions/notificationActions';
import {
  doPutCountry,
  doDeleteCountry
} from '../../../../redux/actions/locationActions';
import { makeCountryStateProvincesSelector } from '../../../../redux/selectors/locationsSelectors';
import Country from './Country';

class CountryContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newStateProvince: null,
      view: '',
      isPublishModalOpen: false,
      isDeleteModalOpen: false
    };

    this.clearNewStateProvince = this.clearNewStateProvince.bind(this);
    this.addStateProvince = this.addStateProvince.bind(this);
    this.onPublish = this.onPublish.bind(this);
    this.handlePublishModalOpen = this.handlePublishModalOpen.bind(this);
    this.handlePublishModalClose = this.handlePublishModalClose.bind(this);
  }

  clearNewStateProvince = () => {
    this.setState({ newStateProvince: null });
  };

  addStateProvince = countryUuid => {
    const { newStateProvince } = this.state;

    if (newStateProvince) {
      this.props.onGeneralErrorNotification(
        'Cannot create more than one State/Province simultaneously'
      );
      return;
    }

    const emptyStateProvince = {
      code: '',
      name: '',
      countryUuid,
      isNew: true
    };

    this.setState({
      newStateProvince: emptyStateProvince
    });
  };

  toggleEditView = () => {
    this.setState(prevState => ({
      view: prevState.view !== 'EDIT' ? 'EDIT' : ''
    }));
  };

  handlePublishModalOpen = () => {
    this.setState({
      isPublishModalOpen: true
    });
  };

  handlePublishModalClose = () => {
    this.setState({
      isPublishModalOpen: false
    });
  };

  onPublish = () => {
    const { country } = this.props;
    const payload = {
      ...country,
      published: true
    };
    this.props.onPostUpdateCountry(payload);
    this.handlePublishModalClose();
  };

  handleDeleteClick = () => {
    this.setState({
      isDeleteModalOpen: true
    });
  };

  handleDeleteModalClose = () => {
    this.setState({
      isDeleteModalOpen: false
    });
  };

  handleDelete = () => {
    const { country } = this.props;

    this.props.onDeleteCountry(country.uuid);
    this.handleDeleteModalClose();
  };

  render() {
    const { country, stateProvinces, className } = this.props;
    const {
      newStateProvince,
      view,
      isPublishModalOpen,
      isDeleteModalOpen
    } = this.state;

    return (
      <Country
        view={view}
        country={country}
        newStateProvince={newStateProvince}
        stateProvinces={stateProvinces}
        className={className}
        isPublishModalOpen={isPublishModalOpen}
        isDeleteModalOpen={isDeleteModalOpen}
        onPublish={this.onPublish}
        handlePublishModalOpen={this.handlePublishModalOpen}
        handlePublishModalClose={this.handlePublishModalClose}
        handleDeleteClick={this.handleDeleteClick}
        handleDeleteModalClose={this.handleDeleteModalClose}
        handleDelete={this.handleDelete}
        clearNewStateProvince={this.clearNewStateProvince}
        addStateProvince={this.addStateProvince}
        toggleEditView={this.toggleEditView}
      />
    );
  }
}

CountryContainer.propTypes = {
  country: PropTypes.object,
  stateProvinces: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  onPostUpdateCountry: PropTypes.func,
  onDeleteCountry: PropTypes.func,
  onGeneralErrorNotification: PropTypes.func
};

CountryContainer.defaultProps = {
  country: {},
  stateProvinces: [],
  className: '',
  onPostUpdateCountry: undefined,
  onDeleteCountry: undefined,
  onGeneralErrorNotification: undefined
};

const makeMapStateToProps = () => {
  const getCountryStateProvinces = makeCountryStateProvincesSelector();
  const mapStateToProps = (state, props) => ({
    stateProvinces: getCountryStateProvinces(state, props)
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  onGeneralErrorNotification: errorMessage =>
    dispatch(doGeneralErrorNotification(errorMessage)),
  onPostUpdateCountry: countryUpdates => dispatch(doPutCountry(countryUpdates)),
  onDeleteCountry: countryUuid => dispatch(doDeleteCountry(countryUuid))
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(CountryContainer);
