import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';
import { sortByProperty } from '../../helpers/utilities';

// const ormSelector = state => state.orm;

// export const programCipcodesSelector = createSelector(
//   ormSelector,
//   ormCreateSelector(orm, session =>
//     session.ProgramCipcode.all()
//       .toModelArray()
//       .map(cip => Object.assign({}, cip.ref))
//       .sort((cipA, cipB) => sortByProperty(cipA, cipB, 'code'))
//   )
// );

export const programCipcodesSelector = createSelector(
  state => state,
  redux => {
    const selectorORM = ormCreateSelector([orm], session =>
      session.ProgramCipcode.all()
        .toModelArray()
        .map(cip => Object.assign({}, cip.ref))
        .sort((cipA, cipB) => sortByProperty(cipA, cipB, 'code'))
    );
    return selectorORM(redux);
  }
);
