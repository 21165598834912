import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RowSummary } from '../../../SharedSC/DashboardSC';
import AccreditationAgencyForm from '../../AccreditationAgencyForm';
import { validateEntityName } from '../../../../../helpers/validateGeneric';

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const NewAccreditationAgency = ({
  className,
  newAccreditationAgency,
  allAccreditationAgencies,
  showValidationErrors,
  handleChange,
  onPostCreate,
  clearNewForm,
  refProp
}) => (
  <Layout ref={refProp} className={className}>
    <RowSummary>
      <AccreditationAgencyForm
        agency={newAccreditationAgency}
        entityLabel="Agency"
        buttonText="Create"
        showValidationErrors={showValidationErrors}
        validateName={() =>
          validateEntityName(
            newAccreditationAgency.name,
            allAccreditationAgencies
          )
        }
        handleChange={event => handleChange(event)}
        onSubmit={onPostCreate}
        onFormClose={clearNewForm}
      />
    </RowSummary>
  </Layout>
);

NewAccreditationAgency.propTypes = {
  newAccreditationAgency: PropTypes.object,
  allAccreditationAgencies: PropTypes.arrayOf(PropTypes.object),
  showValidationErrors: PropTypes.bool,
  className: PropTypes.string,
  refProp: PropTypes.func,
  handleChange: PropTypes.func,
  onPostCreate: PropTypes.func,
  clearNewForm: PropTypes.func
};
NewAccreditationAgency.defaultProps = {
  newAccreditationAgency: {},
  allAccreditationAgencies: [],
  showValidationErrors: false,
  className: '',
  refProp: undefined,
  handleChange: undefined,
  onPostCreate: undefined,
  clearNewForm: undefined
};

export default NewAccreditationAgency;
