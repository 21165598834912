import {
  GET_MICROCOMPETENCIES,
  GET_MICROCOMPETENCIES_SUCCESS,
  GET_MICROCOMPETENCIES_ERROR,
  POST_MICROCOMPETENCY,
  POST_MICROCOMPETENCY_SUCCESS,
  POST_MICROCOMPETENCY_ERROR,
  PUT_MICROCOMPETENCY,
  PUT_MICROCOMPETENCY_SUCCESS,
  PUT_MICROCOMPETENCY_ERROR,
  DELETE_MICROCOMPETENCY,
  DELETE_MICROCOMPETENCY_SUCCESS,
  DELETE_MICROCOMPETENCY_ERROR,
  PATCH_MOVE_MICROCOMPETENCY,
  PATCH_MOVE_MICROCOMPETENCY_SUCCESS,
  PATCH_MOVE_MICROCOMPETENCY_ERROR,
  PATCH_PUBLISH_MICROCOMPETENCY,
  PATCH_PUBLISH_MICROCOMPETENCY_SUCCESS,
  PATCH_PUBLISH_MICROCOMPETENCY_ERROR
} from './actionTypes';

const doGetMicrocompetencies = subtopicUuid => ({
  type: GET_MICROCOMPETENCIES,
  payload: {
    subtopicUuid
  }
});

const doGetMicrocompetenciesSuccess = data => ({
  type: GET_MICROCOMPETENCIES_SUCCESS,
  payload: {
    microcompetencies: data.microcompetencies
  }
});

const doGetMicrocompetenciesError = error => ({
  type: GET_MICROCOMPETENCIES_ERROR,
  payload: {
    error
  }
});

const doPostMicrocompetencies = newMicrocompetency => ({
  type: POST_MICROCOMPETENCY,
  payload: {
    newMicrocompetency
  }
});

const doPostMicrocompetenciesSuccess = data => ({
  type: POST_MICROCOMPETENCY_SUCCESS,
  payload: {
    createdMicrocompetency: data.createdMicrocompetency
  }
});

const doPostMicrocompetenciesError = error => ({
  type: POST_MICROCOMPETENCY_ERROR,
  payload: {
    error
  }
});

const doPutMicrocompetency = microUpdates => ({
  type: PUT_MICROCOMPETENCY,
  payload: {
    microUpdates
  }
});

const doPutMicrocompetencySuccess = data => ({
  type: PUT_MICROCOMPETENCY_SUCCESS,
  payload: {
    updatedMicrocompetency: data.updatedMicrocompetency
  }
});

const doPutMicrocompetencyError = error => ({
  type: PUT_MICROCOMPETENCY_ERROR,
  payload: {
    error
  }
});

const doPatchMoveMicrocompetency = (uuid, destinationCodeUuid, moveAbove) => ({
  type: PATCH_MOVE_MICROCOMPETENCY,
  payload: {
    uuid,
    destinationCodeUuid,
    moveAbove
  }
});

const doPatchMoveMicrocompetencySuccess = data => ({
  type: PATCH_MOVE_MICROCOMPETENCY_SUCCESS,
  payload: {
    movedMicro: data.movedMicro,
    updatedMicrocompetencies: data.updatedMicrocompetencies
  }
});

const doPatchMoveMicrocompetencyError = error => ({
  type: PATCH_MOVE_MICROCOMPETENCY_ERROR,
  payload: {
    error
  }
});

const doPatchPublishMicrocompetency = uuid => ({
  type: PATCH_PUBLISH_MICROCOMPETENCY,
  payload: {
    uuid
  }
});

const doPatchPublishMicrocompetencySuccess = data => ({
  type: PATCH_PUBLISH_MICROCOMPETENCY_SUCCESS,
  payload: {
    publishedMicro: data.updatedMicrocompetency
  }
});

const doPatchPublishMicrocompetencyError = error => ({
  type: PATCH_PUBLISH_MICROCOMPETENCY_ERROR,
  payload: {
    error
  }
});

const doDeleteMicrocompetency = microUuid => ({
  type: DELETE_MICROCOMPETENCY,
  payload: {
    microUuid
  }
});

const doDeleteMicrocompetencySuccess = data => ({
  type: DELETE_MICROCOMPETENCY_SUCCESS,
  payload: {
    deletedMicrocompetencyUuid: data.deletedMicrocompetency,
    updatedMicrocompetencies: data.updatedMicrocompetencies
  }
});

const doDeleteMicrocompetencyError = error => ({
  type: DELETE_MICROCOMPETENCY_ERROR,
  payload: {
    error
  }
});

export {
  doGetMicrocompetencies,
  doGetMicrocompetenciesSuccess,
  doGetMicrocompetenciesError,
  doPostMicrocompetencies,
  doPostMicrocompetenciesSuccess,
  doPostMicrocompetenciesError,
  doPutMicrocompetency,
  doPutMicrocompetencySuccess,
  doPutMicrocompetencyError,
  doPatchMoveMicrocompetency,
  doPatchMoveMicrocompetencySuccess,
  doPatchMoveMicrocompetencyError,
  doPatchPublishMicrocompetency,
  doPatchPublishMicrocompetencySuccess,
  doPatchPublishMicrocompetencyError,
  doDeleteMicrocompetency,
  doDeleteMicrocompetencySuccess,
  doDeleteMicrocompetencyError
};
