import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { types } from '../../../../../redux/reducers/moveCodeReducer';

import Topic from './Topic';

import { doGetSubtopics } from '../../../../../redux/actions/subtopicActions';
import { makeSubtopicsSelector } from '../../../../../redux/selectors/subtopicSelectors';
import { doSetMoveCodeDestination } from '../../../../../redux/actions/moveCodeActions';

class TopicContainer extends Component {
  constructor(props) {
    super(props);

    this.getSubtopics = this.getSubtopics.bind(this);
  }

  getSubtopics = topicUuid => {
    const { subtopics } = this.props;
    if (!subtopics || subtopics.length < 1) {
      this.props.onFetchSubtopics(topicUuid);
    }
  };

  render() {
    const {
      subtopics,
      topic,
      discipline,
      cip,
      codeType,
      destinationCode,
      codeToMove
    } = this.props;
    const selectableSubtopics =
      codeType === types.subtopic
        ? subtopics.filter(
            code => code.level_two_code === '00' || code.level_two_code === '99'
          )
        : subtopics;

    return (
      <Topic
        subtopics={selectableSubtopics}
        topic={topic}
        discipline={discipline}
        cip={cip}
        codeType={codeType}
        codeToMove={codeToMove}
        destinationCode={destinationCode}
        getSubtopics={this.getSubtopics}
        onCodeSelect={this.props.onSetMoveCodeDestination}
      />
    );
  }
}

TopicContainer.propTypes = {
  subtopics: PropTypes.arrayOf(PropTypes.object),
  topic: PropTypes.object,
  discipline: PropTypes.object,
  cip: PropTypes.object,
  codeType: PropTypes.oneOf([types.topic, types.subtopic, types.micro, '']),
  destinationCode: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  codeToMove: PropTypes.string,
  onFetchSubtopics: PropTypes.func,
  onSetMoveCodeDestination: PropTypes.func
};

TopicContainer.defaultProps = {
  subtopics: [],
  topic: {},
  discipline: {},
  cip: {},
  codeType: '',
  destinationCode: false,
  codeToMove: '',
  onFetchSubtopics: undefined,
  onSetMoveCodeDestination: undefined
};

const makeMapStateToProps = () => {
  const getSubtopics = makeSubtopicsSelector();
  const mapStateToProps = (state, props) => ({
    subtopics: getSubtopics(state, props),
    codeType: state.moveCodeState.codeType,
    destinationCode: state.moveCodeState.destination,
    codeToMove: state.moveCodeState.codeToMove
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  onFetchSubtopics: topicUuid => dispatch(doGetSubtopics(topicUuid)),
  onSetMoveCodeDestination: (topicUuid, fullCode, isGeneralCode, isOtherCode) =>
    dispatch(
      doSetMoveCodeDestination(topicUuid, fullCode, isGeneralCode, isOtherCode)
    )
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(TopicContainer);
export { TopicContainer, mapDispatchToProps };
