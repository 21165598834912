import _ from 'lodash';

const validateRequired = textField => {
  const trimTextField = textField ? textField.trim() : textField;
  if (!trimTextField || trimTextField === '' || trimTextField === 0) {
    return {
      isFormatError: false,
      message: 'Required'
    };
  }
  return false;
};

const validateCodeTitle = textField => {
  const requiredError = validateRequired(textField);
  if (requiredError) {
    return requiredError;
  }

  if (_.size(textField) > 100) {
    return {
      isFormatError: true,
      message: 'Course title must be less than 100 characters'
    };
  }
  return false;
};

const validateProgramCode = (code, allPrograms) => {
  let programIsUnique = true;

  if (!code || code === '' || code === 0) {
    return {
      isFormatError: false,
      message: `Please enter a 2 digit program code`
    };
  }

  if (_.size(code) !== 2) {
    return {
      isFormatError: true,
      message: `Program codes must be exactly 2 digits`
    };
  }

  const regex = /^[0-9]{2}$/g;
  const regexMatch = code.match(regex);
  const failsRegex = !regexMatch || regexMatch.length < 1;

  if (failsRegex) {
    return {
      isFormatError: true,
      message: 'Program codes can only contain numbers'
    };
  }

  allPrograms.forEach(program => {
    if (program.code === code) {
      programIsUnique = false;
    }
  });

  if (!programIsUnique) {
    return {
      isFormatError: false,
      message: `${code} has already been used`
    };
  }

  return false;
};

const validateDisciplineCode = (code, allDisciplines, codeLength = 4) => {
  let disciplineIsUnique = true;

  if (!code || code === '' || code === 0) {
    return {
      isFormatError: false,
      message: `Please enter a ${codeLength} digit discipline code`
    };
  }

  if (_.size(code) !== codeLength) {
    return {
      isFormatError: true,
      message: `Discipline codes must be exactly ${codeLength} digits`
    };
  }

  const regex = new RegExp(`^[0-9]{${codeLength}}$`, 'g');
  const regexMatch = code.match(regex);
  const failsRegex = !regexMatch || regexMatch.length < 1;

  if (failsRegex) {
    return {
      isFormatError: true,
      message: 'Discipline codes can only contain numbers'
    };
  }

  allDisciplines.forEach(discipline => {
    if (discipline.code === code) {
      disciplineIsUnique = false;
    }
  });

  if (!disciplineIsUnique) {
    return {
      isFormatError: false,
      message: `${code} has already been used`
    };
  }

  return false;
};

const validateCipcode = (code, allCipcodes) => {
  let cipcodeIsUnique = true;

  if (!code || code === '' || code === 0) {
    // empty code
    return {
      isFormatError: false,
      message: 'Please enter a 2 digit CIP code'
    };
  }

  if (_.size(code) !== 2) {
    // code not 2 digits
    return {
      isFormatError: true,
      message: 'CIP codes must be exactly 2 digits'
    };
  }

  // code has non numeric characters
  const regex = /^[0-9]{2}$/g;
  const regexMatch = code.match(regex);
  const failsRegex = !regexMatch || regexMatch.length < 1;

  if (failsRegex) {
    return {
      isFormatError: true,
      message: 'CIP codes can only contain numbers'
    };
  }
  // code must be unique

  allCipcodes.forEach(cip => {
    if (cip.code === code) {
      cipcodeIsUnique = false;
    }
  });

  if (!cipcodeIsUnique) {
    return {
      isFormatError: false,
      message: `${code} has already been used`
    };
  }

  return false;
};

const validateCodeDescription = textField => {
  const sizeOfDesc = _.size(textField);
  if (sizeOfDesc > 0 && sizeOfDesc < 10) {
    return {
      isFormatError: true,
      message: 'Must be at least 10 characters long'
    };
  }
  if (sizeOfDesc > 3000) {
    return {
      isFormatError: true,
      message: 'Must not exceed 3000 characters'
    };
  }
  return false;
};

export {
  validateRequired,
  validateCodeTitle,
  validateCodeDescription,
  validateProgramCode,
  validateDisciplineCode,
  validateCipcode
};
