import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { ButtonMenu, Heading1, Heading2 } from '@xcomp/xcomp-design-library';

import {
  HeaderWrapper,
  HeaderLayout,
  HeaderText,
  ButtonHeader,
  PageActions
} from '../SharedSC/HeaderSC';
import { Panel, Page, Wrapper } from '../SharedSC/DashboardSC';
import PageHeader from '../../Header/PageHeader';
import AccreditationAgency from './AccreditationAgency/AccreditationAgencyContainer';
import NoItemsMessage from '../../NoItemsMessage/NoItemsMessage';
import NewAccreditationAgencyContainer from './AccreditationAgency/NewAccreditationAgency/NewAccreditationAgencyContainer';

const FullWidthDiv = styled.div`
  width: 100%;
`;

const AddAgencyMenuSC = styled(ButtonMenu)`
  && {
    display: flex;
    margin-left: auto;
  }
`;

const TopPanel = styled(Panel)`
  && {
    margin: 0 auto 2rem auto;
  }
`;

const PanelHeader = styled.div`
  height: 64px;
  width: 100%;
  display: flex;
  flex-wrap: no-wrap;
  align-items: center;
  justify-content: flex-start;
  padding-left: 24px;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const AccreditationAgencyDashboard = ({
  institutionAgencies,
  programAgencies,
  newAccreditationAgency,
  clearNewAccreditationAgency,
  addInstitutionAgency,
  addProgramAgency
}) => {
  const noItemsMessage = 'There are currently no Agencies created.';
  const someUuid = uuidv4();
  const allInstitutionAgencies =
    newAccreditationAgency &&
    newAccreditationAgency.agencyType === 'INSTITUTION'
      ? institutionAgencies.concat(newAccreditationAgency)
      : institutionAgencies;

  const allProgramAgencies =
    newAccreditationAgency && newAccreditationAgency.agencyType === 'PROGRAM'
      ? programAgencies.concat(newAccreditationAgency)
      : programAgencies;

  const isInstitutionAgenciesEmpty =
    !allInstitutionAgencies || allInstitutionAgencies.length < 1;
  const isProgramAgenciesEmpty =
    !allProgramAgencies || allProgramAgencies.length < 1;

  return (
    <Page>
      <PageHeader>
        <HeaderWrapper>
          <HeaderLayout>
            <HeaderText>
              <Heading1>Accreditation Agencies</Heading1>
            </HeaderText>
          </HeaderLayout>
        </HeaderWrapper>
      </PageHeader>
      <PageActions>
        <HeaderWrapper>
          <ButtonHeader>
            <AddAgencyMenuSC
              buttonText="Add Agency"
              options={[
                {
                  label: 'Institutional',
                  onClick: addInstitutionAgency
                },
                {
                  label: 'Program',
                  onClick: addProgramAgency
                }
              ]}
            />
          </ButtonHeader>
        </HeaderWrapper>
      </PageActions>
      <Wrapper>
        <TopPanel>
          <PanelHeader>
            <Heading2>Institutional Accreditation Agencies</Heading2>
          </PanelHeader>
          {!isInstitutionAgenciesEmpty ? (
            <FullWidthDiv>
              {newAccreditationAgency &&
                newAccreditationAgency.agencyType === 'INSTITUTION' && (
                  <NewAccreditationAgencyContainer
                    key={someUuid}
                    accreditationAgency={newAccreditationAgency}
                    allAccreditationAgencies={allInstitutionAgencies}
                    clearNewForm={clearNewAccreditationAgency}
                  />
                )}
              {institutionAgencies.map(agency => (
                <AccreditationAgency
                  key={agency.uuid}
                  accreditationAgency={agency}
                />
              ))}
            </FullWidthDiv>
          ) : (
            <NoItemsMessage message={noItemsMessage} />
          )}
        </TopPanel>
        <Panel>
          <PanelHeader>
            <Heading2>Program Accreditation Agencies</Heading2>
          </PanelHeader>
          {!isProgramAgenciesEmpty ? (
            <FullWidthDiv>
              {newAccreditationAgency &&
                newAccreditationAgency.agencyType === 'PROGRAM' && (
                  <NewAccreditationAgencyContainer
                    key={someUuid}
                    accreditationAgency={newAccreditationAgency}
                    allAccreditationAgencies={allProgramAgencies}
                    clearNewForm={clearNewAccreditationAgency}
                  />
                )}
              {programAgencies.map(agency => (
                <AccreditationAgency
                  key={agency.uuid}
                  accreditationAgency={agency}
                />
              ))}
            </FullWidthDiv>
          ) : (
            <NoItemsMessage message={noItemsMessage} />
          )}
        </Panel>
      </Wrapper>
    </Page>
  );
};

AccreditationAgencyDashboard.propTypes = {
  institutionAgencies: PropTypes.arrayOf(PropTypes.object),
  programAgencies: PropTypes.arrayOf(PropTypes.object),
  newAccreditationAgency: PropTypes.object,
  clearNewAccreditationAgency: PropTypes.func,
  addInstitutionAgency: PropTypes.func,
  addProgramAgency: PropTypes.func
};

AccreditationAgencyDashboard.defaultProps = {
  institutionAgencies: [],
  programAgencies: [],
  newAccreditationAgency: {},
  clearNewAccreditationAgency: undefined,
  addInstitutionAgency: undefined,
  addProgramAgency: undefined
};

export default AccreditationAgencyDashboard;
