import {
  SEARCH_PROGRAM_CODES,
  SEARCH_PROGRAM_CODES_SUCCESS,
  SEARCH_PROGRAM_CODES_ERROR
} from '../actions/actionTypes';

const INITIAL_STATE = {
  programCodeSearchResults: null,
  isLoading: false
};

const searchReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SEARCH_PROGRAM_CODES: {
      return {
        ...state,
        isLoading: true
      };
    }
    case SEARCH_PROGRAM_CODES_SUCCESS: {
      return {
        ...state,
        programCodeSearchResults: action.payload.programCodes.map(program => ({
          uuid: program.uuid,
          label: `${program.program_discipline.program_cipcode.code}.${
            program.program_discipline.code
          }${program.code} - ${program.title}`
        })),
        isLoading: false
      };
    }
    case SEARCH_PROGRAM_CODES_ERROR: {
      return {
        ...state,
        isLoading: false
      };
    }

    default:
      return state;
  }
};

export default searchReducer;

export { INITIAL_STATE };
