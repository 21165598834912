import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';

export const ormSelector = state => state.orm;

// export const cipsSelector = createSelector(
//   ormSelector,
//   ormCreateSelector(orm, session =>
//     session.CipCode.all()
//       .toModelArray()
//       .map(cip => Object.assign({}, cip.ref))
//   )
// );

export const cipsSelector = createSelector(
  state => state,
  redux => {
    const cipsSelectorORM = ormCreateSelector(orm.CipCode);
    const cipcodes = cipsSelectorORM(redux);
    return cipcodes;
  }
);
