import React from 'react';
import PropTypes from 'prop-types';

import { convertNullToString } from '../../../helpers/utilities';
import SelectField from '../../SelectField/SelectFieldWithError';

const StateProvinceSelect = ({
  className,
  disabled,
  stateProvinces,
  selectedStateProvince,
  showValidationErrors,
  handleChange
}) => {
  const stateProvinceOptions = stateProvinces.map(state => ({
    value: state.uuid,
    text: state.name
  }));

  const error =
    showValidationErrors && !selectedStateProvince
      ? { message: 'Please select a State Province' }
      : false;

  return (
    <SelectField
      className={className}
      select
      error={error}
      disabled={disabled || !stateProvinces || stateProvinces.length < 1}
      name="stateProvinceUuid"
      labelText="State/Province*"
      inputLabelWidth={110}
      value={convertNullToString(selectedStateProvince)}
      onChange={handleChange}
      options={stateProvinceOptions}
    />
  );
};

StateProvinceSelect.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  stateProvinces: PropTypes.arrayOf(PropTypes.object),
  selectedStateProvince: PropTypes.string,
  showValidationErrors: PropTypes.bool,
  handleChange: PropTypes.func
};

StateProvinceSelect.defaultProps = {
  className: '',
  disabled: false,
  stateProvinces: [],
  selectedStateProvince: '',
  showValidationErrors: false,
  handleChange: undefined
};

export default StateProvinceSelect;
