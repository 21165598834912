import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';
import { doGeneralErrorNotification } from '../../../redux/actions/notificationActions';

import {
  doGetDegreeLevels,
  doPatchMoveDegreeLevel
} from '../../../redux/actions/degreeLevelActions';
import { degreeLevelsSelector } from '../../../redux/selectors/degreeLevelSelectors';
import DegreeLevelDashboard from './DegreeLevelDashboard';
import { sortByProperty } from '../../../helpers/utilities';
import { doClearRevertDragDrop } from '../../../redux/actions/dragDropActions';

class DegreeLevelDashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newDegreeLevel: null,
      levels: []
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.onFetchDispatch();
  }

  componentDidUpdate = prevProps => {
    if (prevProps.degreeLevels !== this.props.degreeLevels) {
      this.setLevels(this.props.degreeLevels);
    }
    if (this.props.revertLastDrag) {
      this.setLevels(this.props.degreeLevels);
      this.props.onClearRevertDragDrop();
    }
  };

  setLevels = levels => {
    this.setState({
      levels
    });
  };

  clearNewDegreeLevel = () => {
    this.setState({ newDegreeLevel: null });
  };

  addDegreeLevel = () => {
    const { newDegreeLevel } = this.state;

    if (newDegreeLevel) {
      this.props.onGeneralErrorNotification(
        'Cannot create more than one degree level simultaneously'
      );
      return;
    }

    const emptyDegreeLevel = {
      degreeLevel: '',
      isNew: true
    };

    this.setState({
      newDegreeLevel: emptyDegreeLevel
    });
  };

  onDragEnd = result => {
    const { destination, source, draggableId } = result;
    const { levels } = this.state;
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.draggableId &&
      destination.index === source.index
    ) {
      return;
    }

    const isMovingUp = source.index > destination.index;
    const destinationOrder = destination.index + 1;
    const sourceOrder = source.index + 1;

    this.props.onPatchMoveDegreeLevel(draggableId, destinationOrder);

    let newLevelsState;

    if (isMovingUp) {
      newLevelsState = levels.map(type => {
        const newType = { ...type };
        if (type.order >= destinationOrder && type.order < sourceOrder) {
          newType.order += 1;
        } else if (type.order === sourceOrder) {
          newType.order = destinationOrder;
        }
        return newType;
      });
    } else {
      newLevelsState = levels.map(type => {
        const newType = { ...type };
        if (type.order === sourceOrder) {
          newType.order = destinationOrder;
        } else if (type.order > sourceOrder && type.order <= destinationOrder) {
          newType.order -= 1;
        }
        return newType;
      });
    }

    this.setState({
      levels: newLevelsState.sort((a, b) => sortByProperty(a, b, 'order'))
    });
  };

  render() {
    const { newDegreeLevel, levels } = this.state;

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <DegreeLevelDashboard
          degreeLevels={levels}
          newDegreeLevel={newDegreeLevel}
          addDegreeLevel={this.addDegreeLevel}
          clearNewDegreeLevel={this.clearNewDegreeLevel}
        />
      </DragDropContext>
    );
  }
}

DegreeLevelDashboardContainer.propTypes = {
  degreeLevels: PropTypes.arrayOf(PropTypes.object),
  revertLastDrag: PropTypes.bool,
  onFetchDispatch: PropTypes.func,
  onPatchMoveDegreeLevel: PropTypes.func,
  onClearRevertDragDrop: PropTypes.func,
  onGeneralErrorNotification: PropTypes.func
};

DegreeLevelDashboardContainer.defaultProps = {
  degreeLevels: [],
  revertLastDrag: false,
  onFetchDispatch: undefined,
  onPatchMoveDegreeLevel: undefined,
  onClearRevertDragDrop: undefined,
  onGeneralErrorNotification: undefined
};

const mapStateToProps = state => ({
  degreeLevels: degreeLevelsSelector(state),
  revertLastDrag: state.dragDropState.revertLastDrag
});

const mapDispatchToProps = dispatch => ({
  onFetchDispatch: () => dispatch(doGetDegreeLevels()),
  onGeneralErrorNotification: errorMessage =>
    dispatch(doGeneralErrorNotification(errorMessage)),
  onPatchMoveDegreeLevel: (uuid, destinationOrder) =>
    dispatch(doPatchMoveDegreeLevel(uuid, destinationOrder)),
  onClearRevertDragDrop: () => dispatch(doClearRevertDragDrop())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DegreeLevelDashboardContainer);
export { DegreeLevelDashboardContainer };
