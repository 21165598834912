import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Body1 } from '@xcomp/xcomp-design-library';
import AccordionRowContainer from '../../../AccordionRow/AccordionRowContainer';
import StateProvinceContainer from '../StateProvince/StateProvinceContainer';

const CountryAccordion = styled(AccordionRowContainer)`
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const NoStatesMessage = styled(({ rowOffset, ...rest }) => <div {...rest} />)`
  margin-left: ${props => props.rowOffset};
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  font-weight: 300;
`;

const Body1SC = styled(Body1)`
  margin-top: 0;
`;

const Country = ({ country, stateProvinces }) => {
  return (
    <CountryAccordion
      columnOne={country.code}
      columnTwo={country.name}
      columnGutters="1.75rem"
      isTopLevel
    >
      {stateProvinces && stateProvinces.length > 0 ? (
        stateProvinces.map(state => (
          <StateProvinceContainer
            country={country}
            key={state.uuid}
            stateProvince={state}
          />
        ))
      ) : (
        <NoStatesMessage rowOffset="97px">
          <Body1SC>No State/Provinces exist for this Country</Body1SC>
        </NoStatesMessage>
      )}
    </CountryAccordion>
  );
};

Country.propTypes = {
  country: PropTypes.object.isRequired,
  stateProvinces: PropTypes.arrayOf(PropTypes.object)
};

Country.defaultProps = {
  stateProvinces: []
};

export default Country;
