import {
  GET_PROGRAM_CODES,
  GET_PROGRAM_CODES_SUCCESS,
  GET_PROGRAM_CODES_ERROR,
  SEARCH_PROGRAM_CODES,
  SEARCH_PROGRAM_CODES_SUCCESS,
  SEARCH_PROGRAM_CODES_ERROR,
  GET_PROGRAM_DISCIPLINE_CODES,
  GET_PROGRAM_DISCIPLINE_CODES_SUCCESS,
  GET_PROGRAM_DISCIPLINE_CODES_ERROR,
  POST_PROGRAM_CODE,
  POST_PROGRAM_CODE_SUCCESS,
  POST_PROGRAM_CODE_ERROR,
  PUT_PROGRAM_CODE,
  PUT_PROGRAM_CODE_SUCCESS,
  PUT_PROGRAM_CODE_ERROR,
  DELETE_PROGRAM_CODE,
  DELETE_PROGRAM_CODE_SUCCESS,
  DELETE_PROGRAM_CODE_ERROR,
  PATCH_MOVE_PROGRAM_CODE,
  PATCH_MOVE_PROGRAM_CODE_SUCCESS,
  PATCH_MOVE_PROGRAM_CODE_ERROR,
  PATCH_PUBLISH_PROGRAM_CODE,
  PATCH_PUBLISH_PROGRAM_CODE_SUCCESS,
  PATCH_PUBLISH_PROGRAM_CODE_ERROR
} from './actionTypes';

const doGetProgramCodes = () => ({
  type: GET_PROGRAM_CODES
});

const doGetProgramCodesSuccess = data => ({
  type: GET_PROGRAM_CODES_SUCCESS,
  payload: {
    programCodes: data.programCodes
  }
});

const doGetProgramCodesError = error => ({
  type: GET_PROGRAM_CODES_ERROR,
  payload: {
    error
  }
});

const doSearchProgramCode = searchTerm => ({
  type: SEARCH_PROGRAM_CODES,
  payload: {
    searchTerm
  }
});

const doSearchProgramCodeSuccess = data => ({
  type: SEARCH_PROGRAM_CODES_SUCCESS,
  payload: {
    programCodes: data.programCodes
  }
});

const doSearchProgramCodeError = error => ({
  type: SEARCH_PROGRAM_CODES_ERROR,
  payload: {
    error
  }
});

const doGetProgramDisciplineCodes = disciplineUuid => ({
  type: GET_PROGRAM_DISCIPLINE_CODES,
  payload: {
    disciplineUuid
  }
});

const doGetProgramDisciplineCodesSuccess = data => ({
  type: GET_PROGRAM_DISCIPLINE_CODES_SUCCESS,
  payload: {
    programCodes: data.programCodes
  }
});

const doGetProgramDisciplineCodesError = error => ({
  type: GET_PROGRAM_DISCIPLINE_CODES_ERROR,
  payload: {
    error
  }
});

const doPostProgramCode = newProgramCode => ({
  type: POST_PROGRAM_CODE,
  payload: {
    newProgramCode
  }
});

const doPostProgramCodeSuccess = data => ({
  type: POST_PROGRAM_CODE_SUCCESS,
  payload: {
    createdProgramCode: data.createdProgramCode
  }
});

const doPostProgramCodeError = error => ({
  type: POST_PROGRAM_CODE_ERROR,
  payload: {
    error
  }
});

const doPutProgramCode = programCodeUpdates => ({
  type: PUT_PROGRAM_CODE,
  payload: {
    programCodeUpdates
  }
});

const doPutProgramCodeSuccess = data => ({
  type: PUT_PROGRAM_CODE_SUCCESS,
  payload: {
    updatedProgramCode: data.updatedProgramCode
  }
});

const doPutProgramCodeError = error => ({
  type: PUT_PROGRAM_CODE_ERROR,
  payload: {
    error
  }
});

const doPatchMoveProgramCode = (uuid, destinationCodeUuid, moveAbove) => ({
  type: PATCH_MOVE_PROGRAM_CODE,
  payload: {
    uuid,
    destinationCodeUuid,
    moveAbove
  }
});

const doPatchMoveProgramCodeSuccess = data => ({
  type: PATCH_MOVE_PROGRAM_CODE_SUCCESS,
  payload: {
    movedProgramCode: data.movedProgramCode,
    updatedProgramCodes: data.updatedProgramCodes
  }
});

const doPatchMoveProgramCodeError = error => ({
  type: PATCH_MOVE_PROGRAM_CODE_ERROR,
  payload: {
    error
  }
});

const doPatchPublishProgramCode = uuid => ({
  type: PATCH_PUBLISH_PROGRAM_CODE,
  payload: {
    uuid
  }
});

const doPatchPublishProgramCodeSuccess = data => ({
  type: PATCH_PUBLISH_PROGRAM_CODE_SUCCESS,
  payload: {
    publishedProgramCode: data.updatedProgramCode
  }
});

const doPatchPublishProgramCodeError = error => ({
  type: PATCH_PUBLISH_PROGRAM_CODE_ERROR,
  payload: {
    error
  }
});

const doDeleteProgramCode = uuid => ({
  type: DELETE_PROGRAM_CODE,
  payload: {
    uuid
  }
});

const doDeleteProgramCodeSuccess = data => ({
  type: DELETE_PROGRAM_CODE_SUCCESS,
  payload: {
    deletedProgramCode: data.deletedProgramCode
  }
});

const doDeleteProgramCodeError = error => ({
  type: DELETE_PROGRAM_CODE_ERROR,
  payload: {
    error
  }
});

export {
  doGetProgramCodes,
  doGetProgramCodesSuccess,
  doGetProgramCodesError,
  doSearchProgramCode,
  doSearchProgramCodeSuccess,
  doSearchProgramCodeError,
  doGetProgramDisciplineCodes,
  doGetProgramDisciplineCodesSuccess,
  doGetProgramDisciplineCodesError,
  doPostProgramCode,
  doPostProgramCodeSuccess,
  doPostProgramCodeError,
  doPutProgramCode,
  doPutProgramCodeSuccess,
  doPutProgramCodeError,
  doPatchMoveProgramCode,
  doPatchMoveProgramCodeSuccess,
  doPatchMoveProgramCodeError,
  doPatchPublishProgramCode,
  doPatchPublishProgramCodeSuccess,
  doPatchPublishProgramCodeError,
  doDeleteProgramCode,
  doDeleteProgramCodeSuccess,
  doDeleteProgramCodeError
};
