import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import InstitutionTypeForm from '../../InstitutionTypeForm';
import { validateEntityName } from '../../../../../helpers/validateGeneric';
import { RowSummary } from '../../../SharedSC/DashboardSC';

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const NewStateProvince = ({
  newInstitutionSubtype,
  allInstitutionSubtypes,
  showValidationErrors,
  handleChange,
  onPostCreate,
  clearNewForm
}) => (
  <Layout>
    <RowSummary>
      <InstitutionTypeForm
        type={newInstitutionSubtype}
        entityLabel="Subtype"
        buttonText="Create"
        showValidationErrors={showValidationErrors}
        validateName={() =>
          validateEntityName(newInstitutionSubtype.name, allInstitutionSubtypes)
        }
        handleChange={event => handleChange(event)}
        onSubmit={onPostCreate}
        onFormClose={clearNewForm}
      />
    </RowSummary>
  </Layout>
);

NewStateProvince.propTypes = {
  newInstitutionSubtype: PropTypes.object,
  allInstitutionSubtypes: PropTypes.arrayOf(PropTypes.object),
  showValidationErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  onPostCreate: PropTypes.func,
  clearNewForm: PropTypes.func
};
NewStateProvince.defaultProps = {
  newInstitutionSubtype: {},
  allInstitutionSubtypes: [],
  showValidationErrors: false,
  handleChange: undefined,
  onPostCreate: undefined,
  clearNewForm: undefined
};

export default NewStateProvince;
