import { Model, attr } from 'redux-orm';
import {
  GET_INSTITUTION_ID_TYPES_SUCCESS,
  POST_INSTITUTION_ID_TYPE_SUCCESS,
  PUT_INSTITUTION_ID_TYPE_SUCCESS,
  DELETE_INSTITUTION_ID_TYPE_SUCCESS
} from '../actions/actionTypes';

class InstitutionIdType extends Model {
  static reducer(action, InstitutionIdType, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_INSTITUTION_ID_TYPES_SUCCESS: {
        InstitutionIdType.all().delete();
        const idTypes = [...payload.institutionIdTypes];
        return idTypes.map(idType => {
          const idTypeUpsert = InstitutionIdType.upsert(idType);
          return idTypeUpsert;
        });
      }
      case POST_INSTITUTION_ID_TYPE_SUCCESS: {
        const { createdInstitutionIdType } = payload;
        return InstitutionIdType.upsert(createdInstitutionIdType);
      }
      case PUT_INSTITUTION_ID_TYPE_SUCCESS: {
        const { updatedInstitutionIdType } = payload;
        return InstitutionIdType.upsert(updatedInstitutionIdType);
      }
      case DELETE_INSTITUTION_ID_TYPE_SUCCESS: {
        const { deletedInstitutionIdType } = payload;
        return InstitutionIdType.withId(deletedInstitutionIdType.uuid).delete();
      }
      default:
        return session;
    }
  }
}

InstitutionIdType.fields = {
  uuid: attr(),
  name: attr(),
  published: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

InstitutionIdType.modelName = 'InstitutionIdType';
InstitutionIdType.options = {
  idAttribute: 'uuid'
};

export default InstitutionIdType;
