import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MMDashboard from './MMDashboard';
import { doGetCips } from '../../../redux/actions/cipCodeActions';
import { cipsSelector } from '../../../redux/selectors/cipsSelectors';
import { doGeneralErrorNotification } from '../../../redux/actions/notificationActions';
import { doClearSuccessfullyCreatedCipcode } from '../../../redux/actions/successfulCreationActions';
import { doClearSuccessfullyUpdatedCipcode } from '../../../redux/actions/successfulUpdateActions';
import { sortByProperty } from '../../../helpers/utilities';

class MMDashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newCip: null
    };

    this.addCip = this.addCip.bind(this);
    this.clearNewCip = this.clearNewCip.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.onFetchCips();
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.successfullyUpdatedCip) {
      this.props.onClearUpdatedCip();

      return true;
    }

    if (
      nextProps.successfullyCreatedTopic !== this.props.successfullyCreatedTopic
    ) {
      return true;
    }
    
    return true;
  }

  componentDidUpdate() {
    const { successfullyCreatedCip } = this.props;

    if (successfullyCreatedCip && this.state.newCip) {
      this.clearNewCip();
      this.props.onClearCreatedCip();
    }
  }

  clearNewCip = () => {
    this.setState({ newCip: null });
  };

  addCip = () => {
    const { newCip } = this.state;

    if (newCip) {
      this.props.onGeneralErrorNotification(
        'Cannot create more than one CIP code simultaneously'
      );
      return;
    }

    const emptyCip = {
      code: '',
      title: '',
      isNew: true
    };

    this.setState({
      newCip: emptyCip
    });
  };

  render() {
    const { cips } = this.props;
    const { newCip } = this.state;

    const allCips = newCip ? cips.concat(newCip) : cips;
    const sortedCips = allCips.sort((cipA, cipB) =>
      sortByProperty(cipA, cipB, 'code')
    );
    return (
      <MMDashboard
        cips={sortedCips}
        addCip={this.addCip}
        clearNewCip={this.clearNewCip}
      />
    );
  }
}

MMDashboardContainer.propTypes = {
  cips: PropTypes.arrayOf(PropTypes.object),
  successfullyCreatedCip: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
  successfullyUpdatedCip: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
  successfullyCreatedTopic: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
  onFetchCips: PropTypes.func,
  onClearCreatedCip: PropTypes.func,
  onClearUpdatedCip: PropTypes.func,
  onGeneralErrorNotification: PropTypes.func
};

MMDashboardContainer.defaultProps = {
  cips: [],
  successfullyCreatedCip: false,
  successfullyUpdatedCip: false,
  successfullyCreatedTopic: false,
  onFetchCips: undefined,
  onClearCreatedCip: undefined,
  onClearUpdatedCip: undefined,
  onGeneralErrorNotification: undefined
};

const mapStateToProps = state => ({
  cips: cipsSelector(state),
  successfullyCreatedCip:
    state.successfulCreationState.successfullyCreatedCipcode,
  successfullyUpdatedCip:
    state.successfulUpdateState.successfullyUpdatedCipcode,
  successfullyCreatedTopic:
    state.successfulCreationState.successfullyCreatedTopic
});

const mapDispatchToProps = dispatch => ({
  onFetchCips: () => dispatch(doGetCips()),
  onClearCreatedCip: () => dispatch(doClearSuccessfullyCreatedCipcode()),
  onClearUpdatedCip: () => dispatch(doClearSuccessfullyUpdatedCipcode()),
  onGeneralErrorNotification: errorMessage =>
    dispatch(doGeneralErrorNotification(errorMessage))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MMDashboardContainer);
export { MMDashboardContainer, mapStateToProps, mapDispatchToProps };
