import React from 'react';
import PropTypes from 'prop-types';

import { convertNullToString } from '../../../helpers/utilities';
import SelectField from '../../SelectField/SelectFieldWithError';

const InstitutionSubtypeSelect = ({
  className,
  disabled,
  institutionSubtypes,
  selectedInstitutionSubtype,
  showValidationErrors,
  handleChange
}) => {
  const selectOptions = institutionSubtypes.map(subtype => ({
    value: subtype.uuid,
    text: subtype.name
  }));

  const error =
    showValidationErrors && !selectedInstitutionSubtype
      ? { message: 'Please select a Subtype' }
      : false;

  return (
    <SelectField
      className={className}
      select
      error={error}
      disabled={
        disabled || !institutionSubtypes || institutionSubtypes.length < 1
      }
      name="institutionSubtypeUuid"
      labelText="Subtype*"
      inputLabelWidth={62}
      value={convertNullToString(selectedInstitutionSubtype)}
      onChange={handleChange}
      options={selectOptions}
    />
  );
};

InstitutionSubtypeSelect.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  institutionSubtypes: PropTypes.arrayOf(PropTypes.object),
  selectedInstitutionSubtype: PropTypes.string,
  showValidationErrors: PropTypes.bool,
  handleChange: PropTypes.func
};

InstitutionSubtypeSelect.defaultProps = {
  className: '',
  disabled: false,
  institutionSubtypes: [],
  selectedInstitutionSubtype: '',
  showValidationErrors: false,
  handleChange: undefined
};

export default InstitutionSubtypeSelect;
