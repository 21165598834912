import { Model, attr } from 'redux-orm';
import { GET_USERS_SUCCESS } from '../actions/actionTypes';

class User extends Model {
  static reducer(action, User, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_USERS_SUCCESS: {
        const allUsers = [...payload.users];
        return allUsers.map(user => User.upsert(user));
      }
      default:
        return session;
    }
  }
}

User.fields = {
  uuid: attr(),
  firstName: attr(),
  middleName: attr(),
  lastName: attr(),
  suffix: attr(),
  email: attr(),
  phone: attr(),
  text: attr(),
  notes: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

User.modelName = 'User';
User.options = {
  idAttribute: 'uuid'
};

export default User;
