import {
  OPEN_MOVE_CODE_MODAL,
  CLOSE_MOVE_CODE_MODAL,
  SET_MOVE_CODE_DESTINATION,
  SET_MOVE_TOPIC_EMPTY_DISCIPLINE
} from './actionTypes';

const doOpenMoveCodeModal = (
  codeToMoveUuid,
  codeType,
  fullCodeToMove,
  parentCodeString
) => ({
  type: OPEN_MOVE_CODE_MODAL,
  payload: {
    codeToMoveUuid,
    codeType,
    fullCodeToMove,
    parentCodeString
  }
});

const doCloseMoveCodeModal = () => ({
  type: CLOSE_MOVE_CODE_MODAL
});

const doSetMoveCodeDestination = (
  destinationUuid,
  destinationFullCode,
  isGeneralCode,
  isOtherCode
) => ({
  type: SET_MOVE_CODE_DESTINATION,
  payload: {
    destinationUuid,
    destinationFullCode,
    isGeneralCode,
    isOtherCode
  }
});

const doSetMoveTopicToEmptyDiscipline = newDisciplineUuid => ({
  type: SET_MOVE_TOPIC_EMPTY_DISCIPLINE,
  payload: {
    newDisciplineUuid
  }
});

export {
  doOpenMoveCodeModal,
  doCloseMoveCodeModal,
  doSetMoveCodeDestination,
  doSetMoveTopicToEmptyDiscipline
};
