import { call, put } from 'redux-saga/effects';
import {
  getProgramCodes,
  getProgramCodesSearch,
  getProgramDisciplineCodes,
  postProgramCode,
  putProgramCode,
  patchPublishProgramCode,
  deleteProgramCode
} from '../../api/programCodesApi';
import {
  doGetProgramCodesSuccess,
  doGetProgramCodesError,
  doSearchProgramCodeSuccess,
  doSearchProgramCodeError,
  doGetProgramDisciplineCodesSuccess,
  doGetProgramDisciplineCodesError,
  doPostProgramCodeSuccess,
  doPostProgramCodeError,
  doPutProgramCodeSuccess,
  doPutProgramCodeError,
  doPatchPublishProgramCodeSuccess,
  doPatchPublishProgramCodeError,
  doDeleteProgramCodeSuccess,
  doDeleteProgramCodeError
} from '../actions/programCodeActions';

function* handleFetchProgramCodes() {
  try {
    const result = yield call(getProgramCodes);
    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doGetProgramCodesSuccess(payload));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doGetProgramCodesError(error));
  }
}

function* handleSearchProgramCodes(action) {
  const { searchTerm } = action.payload;
  try {
    const result = yield call(getProgramCodesSearch, searchTerm);
    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doSearchProgramCodeSuccess(payload));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doSearchProgramCodeError(error));
  }
}

function* handleFetchDisciplineProgramCodes(action) {
  const { disciplineUuid } = action.payload;
  try {
    const result = yield call(getProgramDisciplineCodes, disciplineUuid);
    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doGetProgramDisciplineCodesSuccess(payload));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doGetProgramDisciplineCodesError(error));
  }
}

function* handlePostCreateProgramCode(action) {
  const { newProgramCode } = action.payload;
  try {
    const result = yield call(postProgramCode, newProgramCode);
    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doPostProgramCodeSuccess(payload));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doPostProgramCodeError(error));
  }
}

function* handlePutUpdateProgramCode(action) {
  const { programCodeUpdates } = action.payload;
  try {
    const result = yield call(putProgramCode, programCodeUpdates);
    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doPutProgramCodeSuccess(payload));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doPutProgramCodeError(error));
  }
}

function* handlePatchPublishProgramCode(action) {
  const { uuid } = action.payload.uuid;
  try {
    const result = yield call(patchPublishProgramCode, uuid);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPatchPublishProgramCodeSuccess(payload));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doPatchPublishProgramCodeError(error));
  }
}

function* handleDeleteProgramCode(action) {
  const { uuid } = action.payload;
  try {
    const result = yield call(deleteProgramCode, uuid);
    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doDeleteProgramCodeSuccess(payload));
    } else {
      const errorText = yield result.text();

      yield put(
        doDeleteProgramCodeError({
          text: errorText
        })
      );
    }
  } catch (error) {
    yield put(doDeleteProgramCodeError(error));
  }
}

export {
  handleFetchProgramCodes,
  handleSearchProgramCodes,
  handleFetchDisciplineProgramCodes,
  handlePostCreateProgramCode,
  handlePutUpdateProgramCode,
  handlePatchPublishProgramCode,
  handleDeleteProgramCode
};
