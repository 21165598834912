import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NewMicrocompetency from './NewMicrocompetency';
import { doPostMicrocompetencies } from '../../../../../redux/actions/microcompetencyActions';
import {
  validateCodeTitle,
  validateCodeDescription
} from '../../../../../helpers/validateCodes';
import { allFieldsAreValid } from '../../../../../helpers/validateGeneric';

class NewMicrocompetencyContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      level_one_code: '',
      level_two_code: '',
      title: '',
      description: '',
      subtopicUuid: '',
      showValidationErrors: false
    };

    this.setNewMicro = this.setNewMicro.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleMicrocompetencySubmission = this.handleMicrocompetencySubmission.bind(
      this
    );
  }

  componentDidMount() {
    const { level_one_code, level_two_code } = this.state;
    const { microcompetency } = this.props;
    this._isMounted = true;
    if (!level_one_code || !level_two_code) {
      this.setNewMicro(microcompetency);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  setNewMicro = microcompetency => {
    const { isNew, ...newMicro } = microcompetency;
    if (this._isMounted) {
      this.setState(() => ({
        ...newMicro
      }));
    }
  };

  setValidationErrors = newMicro => {
    const titleError = validateCodeTitle(newMicro.title);
    const descriptionError = validateCodeDescription(newMicro.description);
    const newValidationErrors = {
      title: titleError,
      description: descriptionError
    };

    return newValidationErrors;
  };

  handleMicrocompetencySubmission = () => {
    const { showValidationErrors, ...newMicro } = this.state;
    const newValidationErrors = this.setValidationErrors(newMicro);

    if (allFieldsAreValid(newValidationErrors)) {
      this.props.onPostCreateMicrocompetency(newMicro);
      this.setState({ showValidationErrors: false });
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const {
      level_one_code,
      level_two_code,
      title,
      description,
      subtopicUuid,
      showValidationErrors
    } = this.state;
    const { cip, discipline, topic, subtopic, clearNewMicro } = this.props;

    const newMicro = {
      level_one_code,
      level_two_code,
      title,
      description,
      subtopicUuid
    };

    return (
      <NewMicrocompetency
        newMicro={newMicro}
        cip={cip}
        discipline={discipline}
        topic={topic}
        subtopic={subtopic}
        handleChange={this.handleChange}
        clearNewMicro={clearNewMicro}
        onPostCreateMicrocompetency={this.handleMicrocompetencySubmission}
        showValidationErrors={showValidationErrors}
      />
    );
  }
}

NewMicrocompetencyContainer.propTypes = {
  microcompetency: PropTypes.object,
  cip: PropTypes.object,
  discipline: PropTypes.object,
  topic: PropTypes.object,
  subtopic: PropTypes.object,
  onPostCreateMicrocompetency: PropTypes.func,
  clearNewMicro: PropTypes.func
};
NewMicrocompetencyContainer.defaultProps = {
  microcompetency: {},
  cip: {},
  discipline: {},
  topic: {},
  subtopic: {},
  onPostCreateMicrocompetency: undefined,
  clearNewMicro: undefined
};

const mapDispatchToProps = dispatch => ({
  onPostCreateMicrocompetency: newMicro =>
    dispatch(doPostMicrocompetencies(newMicro))
});

export default connect(
  undefined,
  mapDispatchToProps
)(NewMicrocompetencyContainer);
export { NewMicrocompetencyContainer };
export { NewMicrocompetency };
