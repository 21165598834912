import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';
import { doGeneralErrorNotification } from '../../../redux/actions/notificationActions';

import {
  doGetInstitutionTypes,
  doPatchMoveInstitutionType
} from '../../../redux/actions/institutionTypeActions';
import { institutionTypesSelector } from '../../../redux/selectors/institutionTypesSelectors';
import InstitutionTypeDashboard from './InstitutionTypeDashboard';
import { sortByProperty } from '../../../helpers/utilities';
import { doClearRevertDragDrop } from '../../../redux/actions/dragDropActions';

class InstitutionTypeDashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newInstitutionType: null,
      types: []
    };

    this.clearNewInstitutionType = this.clearNewInstitutionType.bind(this);
    this.addInstitutionType = this.addInstitutionType.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.onFetchInstitutionTypes();
  }

  componentDidUpdate = prevProps => {
    if (prevProps.institutionTypes !== this.props.institutionTypes) {
      this.setTypes(this.props.institutionTypes);
    }
    if (this.props.revertLastDrag) {
      this.setTypes(this.props.institutionTypes);
      this.props.onClearRevertDragDrop();
    }
  };

  setTypes = types => {
    this.setState({
      types
    });
  };

  clearNewInstitutionType = () => {
    this.setState({ newInstitutionType: null });
  };

  addInstitutionType = () => {
    const { newInstitutionType } = this.state;

    if (newInstitutionType) {
      this.props.onGeneralErrorNotification(
        'Cannot create more than one institution type simultaneously'
      );
      return;
    }

    const emptyType = {
      name: '',
      isNew: true
    };

    this.setState({
      newInstitutionType: emptyType
    });
  };

  onDragEnd = result => {
    const { destination, source, draggableId } = result;
    const { types } = this.state;
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.draggableId &&
      destination.index === source.index
    ) {
      return;
    }

    const isMovingUp = source.index > destination.index;
    const destinationOrder = destination.index + 1;
    const sourceOrder = source.index + 1;

    this.props.onPatchMoveInstitutionType(draggableId, destinationOrder);

    let newTypesState;

    if (isMovingUp) {
      newTypesState = types.map(type => {
        const newType = { ...type };
        if (type.order >= destinationOrder && type.order < sourceOrder) {
          newType.order += 1;
        } else if (type.order === sourceOrder) {
          newType.order = destinationOrder;
        }
        return newType;
      });
    } else {
      newTypesState = types.map(type => {
        const newType = { ...type };
        if (type.order === sourceOrder) {
          newType.order = destinationOrder;
        } else if (type.order > sourceOrder && type.order <= destinationOrder) {
          newType.order -= 1;
        }
        return newType;
      });
    }

    this.setState({
      types: newTypesState.sort((a, b) => sortByProperty(a, b, 'order'))
    });
  };

  render() {
    const { newInstitutionType, types } = this.state;

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <InstitutionTypeDashboard
          institutionTypes={types}
          newInstitutionType={newInstitutionType}
          addInstitutionType={this.addInstitutionType}
          clearNewInstitutionType={this.clearNewInstitutionType}
        />
      </DragDropContext>
    );
  }
}

InstitutionTypeDashboardContainer.propTypes = {
  institutionTypes: PropTypes.arrayOf(PropTypes.object),
  revertLastDrag: PropTypes.bool,
  onFetchInstitutionTypes: PropTypes.func,
  onPatchMoveInstitutionType: PropTypes.func,
  onClearRevertDragDrop: PropTypes.func,
  onGeneralErrorNotification: PropTypes.func
};

InstitutionTypeDashboardContainer.defaultProps = {
  institutionTypes: [],
  revertLastDrag: false,
  onFetchInstitutionTypes: undefined,
  onPatchMoveInstitutionType: undefined,
  onClearRevertDragDrop: undefined,
  onGeneralErrorNotification: undefined
};

const mapStateToProps = state => ({
  institutionTypes: institutionTypesSelector(state),
  revertLastDrag: state.dragDropState.revertLastDrag
});

const mapDispatchToProps = dispatch => ({
  onFetchInstitutionTypes: () => dispatch(doGetInstitutionTypes()),
  onGeneralErrorNotification: errorMessage =>
    dispatch(doGeneralErrorNotification(errorMessage)),
  onPatchMoveInstitutionType: (uuid, destinationOrder) =>
    dispatch(doPatchMoveInstitutionType(uuid, destinationOrder)),
  onClearRevertDragDrop: () => dispatch(doClearRevertDragDrop())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstitutionTypeDashboardContainer);
export { InstitutionTypeDashboardContainer };
