import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Body1, Subtitle2 } from '@xcomp/xcomp-design-library';
import PublishModal from '../../../DialogModal/PublishModal/PublishModal';
import DeleteModal from '../../../DialogModal/DeleteModal/DeleteModal';
import AccordionRowContainer from '../../../AccordionRow/AccordionRowContainer';
import EditProgramContainer from './EditProgram/EditProgramContainer';
import { sortByProperty } from '../../../../helpers/utilities';

const Layout = styled.div`
  width: 100%;
`;

const RelatedPrograms = styled(({ rowOffset, ...rest }) => <div {...rest} />)`
  width: 100%;
  margin-left: ${props => props.rowOffset};
`;

const RelatedProgramsWrapper = styled.ul`
  && {
    list-style: none;
    padding: 0 1rem;
    margin-bottom: 1rem;
  }
`;

const Subtitle2SC = styled(Subtitle2)`
  && {
    display: block;
    margin-bottom: 0.5rem;
  }
`;

const RelatedProgram = styled.li`
  && {
    position: relative;
    width: 100%;
    margin-bottom: 0.5rem;
    font-weight: 300;
    font-size: 1rem;
  }
`;

const Description = styled(({ rowOffset, removeMarginBottom, ...rest }) => (
  <div {...rest} />
))`
  margin-left: ${props => props.rowOffset};
  padding-left: 1rem;
  margin-bottom: ${props => (props.removeMarginBottom ? '0' : '0.5rem')};
  font-weight: 300;
`;

const Body1SC = styled(({ removeMarginBottom, ...rest }) => (
  <Body1 {...rest} />
))`
  margin-top: 0;
  margin-bottom: ${props => (props.removeMarginBottom ? '0' : 'initial')};
`;

const Program = ({
  view,
  program,
  discipline,
  cipcode,
  toggleEditView,
  isPublishModalOpen,
  isDeleteModalOpen,
  handlePublishModalOpen,
  handlePublishModalClose,
  handleDeleteClick,
  handleDeleteModalClose,
  handleDelete,
  onPublish,
  clearNew
}) => {
  const codeString = `${cipcode.code}.${discipline.code}${program.code}`;
  const programOptions = [
    {
      label: 'Edit',
      onClick: toggleEditView,
      shouldOpenOnClick: false,
      icon: 'edit'
    },
    {
      label: 'Delete',
      onClick: handleDeleteClick,
      shouldOpenOnClick: false,
      icon: 'delete'
    }
  ];

  const hasRelatedPrograms =
    program.relatedPrograms && program.relatedPrograms.length > 0;

  const relatedPrograms = program.relatedPrograms.map(program => ({
    uuid: program.uuid,
    label: `${program.program_cipcode.code}.${program.program_discipline.code}${
      program.code
    } - ${program.title}`
  }));

  const sortedRelatedPrograms = hasRelatedPrograms
    ? relatedPrograms.sort((a, b) => sortByProperty(a, b, 'label'))
    : false;

  return (
    <Layout>
      <PublishModal
        isModalOpen={isPublishModalOpen}
        handleModalClose={handlePublishModalClose}
        handlePublish={onPublish}
        entityLabel="Program Code"
        publishConfirmMessage="This will make the Program Code available to institutions and lock the Program Code from being deleted."
      />
      <DeleteModal
        modalOpen={isDeleteModalOpen}
        handleModalClose={() => handleDeleteModalClose()}
        handleDelete={handleDelete}
        type="Program Code"
      />
      {view === 'EDIT' ? (
        <EditProgramContainer
          cipcode={cipcode}
          discipline={discipline}
          program={program}
          clearNew={clearNew}
          key={program.uuid}
          toggleEditView={toggleEditView}
        />
      ) : (
        <AccordionRowContainer
          label="Program"
          columnOne={codeString}
          columnTwo={program.title}
          actions={programOptions}
          expandWidth="42px"
          height="38px"
          rowOffset="90px"
          isPublished={program.published}
          onPublish={handlePublishModalOpen}
        >
          <>
            <Description
              rowOffset="172px"
              removeMarginBottom={hasRelatedPrograms}
            >
              <Body1SC removeMarginBottom={hasRelatedPrograms}>
                {program.description ? program.description : 'No description'}
              </Body1SC>
            </Description>
            <RelatedPrograms rowOffset="172px">
              {sortedRelatedPrograms && (
                <RelatedProgramsWrapper>
                  <Subtitle2SC>Related Programs</Subtitle2SC>
                  {sortedRelatedPrograms.map(program => (
                    <RelatedProgram key={program.uuid}>
                      <Body1SC>{program.label}</Body1SC>
                    </RelatedProgram>
                  ))}
                </RelatedProgramsWrapper>
              )}
            </RelatedPrograms>
          </>
        </AccordionRowContainer>
      )}
    </Layout>
  );
};

Program.propTypes = {
  program: PropTypes.object.isRequired,
  discipline: PropTypes.object.isRequired,
  cipcode: PropTypes.object.isRequired,
  view: PropTypes.oneOf(['EDIT', '']),
  toggleEditView: PropTypes.func,
  isPublishModalOpen: PropTypes.bool,
  isDeleteModalOpen: PropTypes.bool,
  onPublish: PropTypes.func,
  handlePublishModalOpen: PropTypes.func,
  handlePublishModalClose: PropTypes.func,
  handleDeleteClick: PropTypes.func,
  handleDeleteModalClose: PropTypes.func,
  handleDelete: PropTypes.func,
  clearNew: PropTypes.func
};
Program.defaultProps = {
  view: '',
  toggleEditView: undefined,
  isPublishModalOpen: false,
  isDeleteModalOpen: false,
  onPublish: undefined,
  handlePublishModalOpen: undefined,
  handlePublishModalClose: undefined,
  handleDeleteClick: undefined,
  handleDeleteModalClose: undefined,
  handleDelete: undefined,
  clearNew: undefined
};

export default Program;
