import {
  OPEN_NEW_SYSTEM_MODAL,
  OPEN_EDIT_SYSTEM_MODAL,
  CLOSE_SYSTEM_MODAL,
  OPEN_NEW_INSTITUTION_MODAL,
  OPEN_EDIT_INSTITUTION_MODAL,
  CLOSE_INSTITUTION_MODAL
} from './actionTypes';

const doOpenNewSystemModal = () => ({
  type: OPEN_NEW_SYSTEM_MODAL
});

const doOpenNewInstitutionModal = (
  systemUuid,
  stateProvinceUuid,
  institutionSubtypeUuid
) => ({
  type: OPEN_NEW_INSTITUTION_MODAL,
  payload: {
    systemUuid,
    stateProvinceUuid,
    institutionSubtypeUuid
  }
});

const doOpenEditSystemModal = (
  systemUuid,
  stateProvinceUuid,
  institutionSubtypeUuid
) => ({
  type: OPEN_EDIT_SYSTEM_MODAL,
  payload: {
    systemUuid,
    stateProvinceUuid,
    institutionSubtypeUuid
  }
});

const doOpenEditInstitutionModal = (
  institutionUuid,
  systemUuid,
  stateProvinceUuid,
  institutionSubtypeUuid
) => ({
  type: OPEN_EDIT_INSTITUTION_MODAL,
  payload: {
    institutionUuid,
    systemUuid,
    stateProvinceUuid,
    institutionSubtypeUuid
  }
});

const doCloseSystemModal = () => ({
  type: CLOSE_SYSTEM_MODAL
});

const doCloseInstitutionModal = () => ({
  type: CLOSE_INSTITUTION_MODAL
});

export {
  doOpenNewSystemModal,
  doCloseSystemModal,
  doOpenEditSystemModal,
  doOpenEditInstitutionModal,
  doOpenNewInstitutionModal,
  doCloseInstitutionModal
};
