import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Program from './Program';
import {
  doPatchPublishProgramCode,
  doDeleteProgramCode
} from '../../../../redux/actions/programCodeActions';

class ProgramContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: '',
      isPublishModalOpen: false,
      isDeleteModalOpen: false
    };

    this.onPublish = this.onPublish.bind(this);
    this.handlePublishModalOpen = this.handlePublishModalOpen.bind(this);
    this.handlePublishModalClose = this.handlePublishModalClose.bind(this);
  }

  toggleEditView = () => {
    this.setState(prevState => ({
      view: prevState.view !== 'EDIT' ? 'EDIT' : ''
    }));
  };

  handlePublishModalOpen = () => {
    this.setState({
      isPublishModalOpen: true
    });
  };

  handlePublishModalClose = () => {
    this.setState({
      isPublishModalOpen: false
    });
  };

  onPublish = () => {
    const { program } = this.props;
    const payload = {
      ...program,
      published: true
    };
    this.props.onPutUpdate(payload);
    this.handlePublishModalClose();
  };

  handleDeleteClick = () => {
    this.setState({
      isDeleteModalOpen: true
    });
  };

  handleDeleteModalClose = () => {
    this.setState({
      isDeleteModalOpen: false
    });
  };

  handleDelete = () => {
    const { program } = this.props;

    this.props.onDelete(program.uuid);
    this.handleDeleteModalClose();
  };

  render() {
    const { view, isPublishModalOpen, isDeleteModalOpen } = this.state;
    const { program, discipline, cipcode } = this.props;
    return (
      <Program
        view={view}
        program={program}
        discipline={discipline}
        cipcode={cipcode}
        isPublishModalOpen={isPublishModalOpen}
        isDeleteModalOpen={isDeleteModalOpen}
        onPublish={this.onPublish}
        handlePublishModalOpen={this.handlePublishModalOpen}
        handlePublishModalClose={this.handlePublishModalClose}
        handleDeleteClick={this.handleDeleteClick}
        handleDeleteModalClose={this.handleDeleteModalClose}
        handleDelete={this.handleDelete}
        toggleEditView={this.toggleEditView}
        clearNew={this.clearNew}
      />
    );
  }
}

ProgramContainer.propTypes = {
  program: PropTypes.object.isRequired,
  discipline: PropTypes.object.isRequired,
  cipcode: PropTypes.object.isRequired,
  onPutUpdate: PropTypes.func,
  onDelete: PropTypes.func
};

ProgramContainer.defaultProps = {
  onPutUpdate: undefined,
  onDelete: undefined
};

const mapDispatchToProps = dispatch => ({
  onPutUpdate: programUpdates =>
    dispatch(doPatchPublishProgramCode(programUpdates)),
  onDelete: uuid => dispatch(doDeleteProgramCode(uuid))
});

export default connect(
  null,
  mapDispatchToProps
)(ProgramContainer);
