import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import DeleteModal from '../../../DialogModal/DeleteModal/DeleteModal';
import AccordionRowContainer from '../../../AccordionRow/AccordionRowContainer';
import DisciplineContainer from '../Discipline/DisciplineContainer';
import NewDisciplineContainer from '../Discipline/NewDiscipline/NewDisciplineContainer';
import EditCipcodeContainer from './EditCipcode/EditCipcodeContainer';

const CipcodeAccordion = styled(AccordionRowContainer)`
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const Cipcode = ({
  view,
  cipcode,
  disciplines,
  newDiscipline,
  isDeleteModalOpen,
  addProgramCipcode,
  addProgramDiscipline,
  getChildDisciplines,
  handleDeleteClick,
  handleDeleteModalClose,
  handleDelete,
  toggleEditView,
  clearNew
}) => {
  const cipcodeActions = [
    {
      label: 'Add CIP',
      onClick: () => addProgramCipcode(),
      shouldOpenOnClick: false,
      icon: 'add'
    },
    {
      label: 'Add Discipline',
      onClick: () => addProgramDiscipline(cipcode.uuid),
      shouldOpenOnClick: true,
      icon: 'add'
    },
    {
      label: 'Edit',
      onClick: toggleEditView,
      shouldOpenOnClick: false,
      icon: 'edit'
    },
    {
      label: 'Delete',
      onClick: handleDeleteClick,
      shouldOpenOnClick: false,
      icon: 'delete'
    }
  ];

  const someUuid = uuidv4();

  return (
    <div>
      <DeleteModal
        modalOpen={isDeleteModalOpen}
        handleModalClose={() => handleDeleteModalClose()}
        handleDelete={() => handleDelete()}
        type="CIP"
      />
      {view === 'EDIT' ? (
        <EditCipcodeContainer
          cipcode={cipcode}
          key={cipcode.uuid}
          toggleEditView={toggleEditView}
        />
      ) : (
        <CipcodeAccordion
          columnOne={cipcode.code}
          columnTwo={cipcode.title}
          actions={cipcodeActions}
          columnGutters="1.75rem"
          onRowOpen={() => getChildDisciplines()}
          toggleEditView={toggleEditView}
          isTopLevel
        >
          {newDiscipline && (
            <NewDisciplineContainer
              key={someUuid}
              discipline={newDiscipline}
              cipcode={cipcode}
              allDisciplines={disciplines}
              clearNew={clearNew}
            />
          )}
          {disciplines.map(disc => (
            <DisciplineContainer
              key={disc.uuid}
              discipline={disc}
              cipcode={cipcode}
              addProgramDiscipline={addProgramDiscipline}
            />
          ))}
        </CipcodeAccordion>
      )}
    </div>
  );
};

Cipcode.propTypes = {
  cipcode: PropTypes.object.isRequired,
  view: PropTypes.oneOf(['EDIT', '']),
  disciplines: PropTypes.arrayOf(PropTypes.object),
  newDiscipline: PropTypes.object,
  isDeleteModalOpen: PropTypes.bool,
  handleDeleteClick: PropTypes.func,
  handleDeleteModalClose: PropTypes.func,
  handleDelete: PropTypes.func,
  addProgramCipcode: PropTypes.func,
  addProgramDiscipline: PropTypes.func,
  getChildDisciplines: PropTypes.func,
  toggleEditView: PropTypes.func,
  clearNew: PropTypes.func
};

Cipcode.defaultProps = {
  view: '',
  disciplines: [],
  newDiscipline: {},
  isDeleteModalOpen: false,
  handleDeleteClick: undefined,
  handleDeleteModalClose: undefined,
  handleDelete: undefined,
  addProgramCipcode: undefined,
  addProgramDiscipline: undefined,
  getChildDisciplines: undefined,
  toggleEditView: undefined,
  clearNew: undefined
};

export default Cipcode;
