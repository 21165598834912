import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ButtonInline } from '@xcomp/xcomp-design-library';
import SimpleModal from '../SimpleModal';

const ModalBody = styled.div`
  padding: 0 1.5rem 1rem 1.5rem;

  button {
    display: block;
    margin-left: auto;
    margin-right: 0;
  }
`;

const ModalButtons = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;

  button {
    margin-left: 1rem;
  }
`;

const PublishModal = ({
  isModalOpen,
  handleModalClose,
  handlePublish,
  entityLabel,
  publishConfirmMessage,
  className
}) => (
  <SimpleModal
    className={className}
    headerText={`Publish ${entityLabel}?`}
    open={isModalOpen}
    onClose={handleModalClose}
  >
    <ModalBody>
      <p>{publishConfirmMessage}</p>
      <ModalButtons>
        <ButtonInline onClick={handleModalClose}>Cancel</ButtonInline>
        <ButtonInline onClick={handlePublish}>Publish</ButtonInline>
      </ModalButtons>
    </ModalBody>
  </SimpleModal>
);

PublishModal.propTypes = {
  isModalOpen: PropTypes.bool,
  handleModalClose: PropTypes.func,
  handlePublish: PropTypes.func,
  entityLabel: PropTypes.string,
  publishConfirmMessage: PropTypes.string,
  className: PropTypes.string
};

PublishModal.defaultProps = {
  isModalOpen: false,
  handleModalClose: undefined,
  handlePublish: undefined,
  entityLabel: 'Type',
  publishConfirmMessage:
    'This will make it available to institutions and lock it from being deleted.',
  className: ''
};

export default PublishModal;
