import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Body1, Caption } from '@xcomp/xcomp-design-library';
import PublishModal from '../../../DialogModal/PublishModal/PublishModal';
import DeleteModal from '../../../DialogModal/DeleteModal/DeleteModal';
import AccordionWithBoldCode from '../../../AccordionRow/AccordionRowSC';
import EditMicrocompetencyContainer from './EditMicrocompetency/EditMicrocompetencyContainer';

const Layout = styled.div`
  width: 100%;
`;

const Description = styled(({ rowOffset, ...rest }) => <div {...rest} />)`
  margin-left: ${props => props.rowOffset};
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  font-weight: 300;
`;

const Body1SC = styled(Body1)`
  margin-top: 0;
`;

const Microcompetency = ({
  microcompetency,
  topic,
  subtopic,
  discipline,
  cip,
  view,
  addMicrocompetency,
  isPublishModalOpen,
  isDeleteModalOpen,
  handlePublishModalOpen,
  handlePublishModalClose,
  handleDeleteClick,
  handleDeleteModalClose,
  handleDelete,
  onPublish,
  toggleEditView,
  openMoveModal
}) => {
  const cipCode = cip.code;
  const disciplineCode = discipline.code;
  const topicCode = `${topic.level_one_code}${topic.level_two_code}`;
  const subtopicCode = `${subtopic.level_one_code}${subtopic.level_two_code}`;
  const microCode = `${microcompetency.level_one_code}${
    microcompetency.level_two_code
  }`;

  const isCodeBold =
    microcompetency.level_two_code === '00' &&
    microcompetency.level_one_code !== '00';

  const isBookendCode =
    microcompetency.level_one_code === '00' ||
    microcompetency.level_one_code === '99';

  const fullCode = `${cipCode}.${disciplineCode}.${topicCode}.${subtopicCode}.${microCode}`;
  const parentCodeString = `${cipCode}.${disciplineCode}.${topicCode}.${subtopicCode}`;

  const microActions = [
    {
      label: 'Add Microcompetency L1',
      onClick: () => addMicrocompetency(subtopic.uuid, false, false),
      shouldOpenOnClick: false
    },
    {
      label: 'Add Microcompetency L2',
      onClick: () =>
        addMicrocompetency(subtopic.uuid, true, microcompetency.level_one_code),
      shouldOpenOnClick: false
    },
    {
      label: 'Edit',
      onClick: toggleEditView,
      shouldOpenOnClick: false,
      icon: 'edit'
    },
    {
      label: 'Publish',
      onClick: handlePublishModalOpen,
      shouldOpenOnClick: false,
      icon: 'publish'
    },
    {
      label: 'Move',
      onClick: () =>
        openMoveModal(microcompetency.uuid, fullCode, parentCodeString),
      shouldOpenOnClick: false,
      icon: 'move'
    },
    {
      label: 'Delete',
      onClick: handleDeleteClick,
      shouldOpenOnClick: false,
      icon: 'delete'
    }
  ];

  const bookendActions = [
    {
      label: 'Edit',
      onClick: toggleEditView,
      shouldOpenOnClick: false,
      icon: 'edit'
    },
    {
      label: 'Publish',
      onClick: handlePublishModalOpen,
      shouldOpenOnClick: false,
      icon: 'publish'
    }
  ];

  return (
    <Layout>
      <PublishModal
        isModalOpen={isPublishModalOpen}
        handleModalClose={handlePublishModalClose}
        handlePublish={() => onPublish(microcompetency.uuid)}
        entityLabel="Microcompetency"
        publishConfirmMessage="This will lock the Microcompetency and its parent codes from being deleted."
      />
      <DeleteModal
        modalOpen={isDeleteModalOpen}
        handleModalClose={() => handleDeleteModalClose()}
        handleDelete={handleDelete}
        type="Microcompetency"
      />
      {view === 'EDIT' ? (
        <EditMicrocompetencyContainer
          id="editContainer"
          cip={cip}
          discipline={discipline}
          topic={topic}
          subtopic={subtopic}
          microcompetency={microcompetency}
          key={microcompetency.uuid}
          toggleEditView={() => toggleEditView()}
        />
      ) : (
        <AccordionWithBoldCode
          label="Microcompetency"
          columnOne={fullCode}
          columnTwo={microcompetency.title}
          actions={isBookendCode ? bookendActions : microActions}
          expandWidth="42px"
          height="38px"
          rowOffset="150px"
          isCodeBold={isCodeBold}
          isPublished={microcompetency.published}
        >
          <Description rowOffset="286px">
            <Body1SC>
              {microcompetency.description
                ? microcompetency.description
                : 'No description'}
            </Body1SC>
            <Caption>UUID: {microcompetency.uuid}</Caption>
          </Description>
        </AccordionWithBoldCode>
      )}
    </Layout>
  );
};

Microcompetency.propTypes = {
  microcompetency: PropTypes.object,
  subtopic: PropTypes.object,
  topic: PropTypes.object,
  discipline: PropTypes.object,
  cip: PropTypes.object,
  isPublishModalOpen: PropTypes.bool,
  isDeleteModalOpen: PropTypes.bool,
  view: PropTypes.oneOf(['', 'EDIT']),
  handlePublishModalOpen: PropTypes.func,
  handlePublishModalClose: PropTypes.func,
  handleDeleteClick: PropTypes.func,
  handleDeleteModalClose: PropTypes.func,
  handleDelete: PropTypes.func,
  toggleEditView: PropTypes.func,
  openMoveModal: PropTypes.func,
  onPublish: PropTypes.func,
  addMicrocompetency: PropTypes.func
};

Microcompetency.defaultProps = {
  microcompetency: {},
  subtopic: {},
  topic: {},
  discipline: {},
  cip: {},
  isPublishModalOpen: false,
  isDeleteModalOpen: false,
  view: '',
  handlePublishModalOpen: undefined,
  handlePublishModalClose: undefined,
  handleDeleteClick: undefined,
  handleDeleteModalClose: undefined,
  handleDelete: undefined,
  toggleEditView: undefined,
  openMoveModal: undefined,
  onPublish: undefined,
  addMicrocompetency: undefined
};

export default Microcompetency;
export { Body1SC };
