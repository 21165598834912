import { call, put } from 'redux-saga/effects';
import {
  getMicrocompetencies,
  postMicrocompetency,
  patchMoveMicrocompetency,
  putMicrocompetency,
  deleteMicrocompetency,
  patchPublishMicrocomptency
} from '../../api/microcompetenciesApi';
import {
  doGetMicrocompetenciesSuccess,
  doGetMicrocompetenciesError,
  doPostMicrocompetenciesSuccess,
  doPostMicrocompetenciesError,
  doPutMicrocompetencySuccess,
  doPutMicrocompetencyError,
  doPatchMoveMicrocompetencySuccess,
  doPatchMoveMicrocompetencyError,
  doDeleteMicrocompetencySuccess,
  doDeleteMicrocompetencyError,
  doPatchPublishMicrocompetencySuccess,
  doPatchPublishMicrocompetencyError
} from '../actions/microcompetencyActions';

function* handleFetchMicrocompetencies(action) {
  const { subtopicUuid } = action.payload;
  try {
    const result = yield call(getMicrocompetencies, subtopicUuid);
    yield put(doGetMicrocompetenciesSuccess(result));
  } catch (error) {
    yield put(doGetMicrocompetenciesError(error));
  }
}

function* handlePostCreateMicrocompetency(action) {
  const { newMicrocompetency } = action.payload;
  try {
    const result = yield call(postMicrocompetency, newMicrocompetency);
    yield put(doPostMicrocompetenciesSuccess(result));
  } catch (error) {
    yield put(doPostMicrocompetenciesError(error));
  }
}

function* handlePostUpdateMicrocompetency(action) {
  const { microUpdates } = action.payload;
  try {
    const result = yield call(putMicrocompetency, microUpdates);
    yield put(doPutMicrocompetencySuccess(result));
  } catch (error) {
    yield put(doPutMicrocompetencyError(error));
  }
}

function* handlePostMoveMicrocompetency(action) {
  const { uuid, destinationCodeUuid, moveAbove } = action.payload;
  try {
    const result = yield call(patchMoveMicrocompetency, {
      uuid,
      destinationCodeUuid,
      moveAbove
    });
    yield put(doPatchMoveMicrocompetencySuccess(result));
  } catch (error) {
    yield put(doPatchMoveMicrocompetencyError(error));
  }
}

function* handlePostPublishMicrocompetency(action) {
  const { uuid } = action.payload;
  try {
    const result = yield call(patchPublishMicrocomptency, uuid);
    yield put(doPatchPublishMicrocompetencySuccess(result));
  } catch (error) {
    yield put(doPatchPublishMicrocompetencyError(error));
  }
}

function* handleDeleteMicrocompetency(action) {
  const { microUuid } = action.payload;
  try {
    const result = yield call(deleteMicrocompetency, microUuid);
    yield put(doDeleteMicrocompetencySuccess(result));
  } catch (error) {
    const errorMessage = yield error.text();
    yield put(doDeleteMicrocompetencyError(errorMessage));
  }
}

export {
  handleFetchMicrocompetencies,
  handlePostCreateMicrocompetency,
  handlePostMoveMicrocompetency,
  handlePostUpdateMicrocompetency,
  handlePostPublishMicrocompetency,
  handleDeleteMicrocompetency
};
