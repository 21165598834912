import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { makeInstitutionSubtypesPublishedSelector } from '../../../../redux/selectors/institutionTypesSelectors';
import InstitutionType from './InstitutionType';

class InstitutionTypeContainer extends PureComponent {
  render() {
    const {
      institutionType,
      institutionSubtypes,
      stateProvince,
      className
    } = this.props;

    return (
      <InstitutionType
        institutionType={institutionType}
        institutionSubtypes={institutionSubtypes}
        stateProvince={stateProvince}
        className={className}
      />
    );
  }
}

InstitutionTypeContainer.propTypes = {
  institutionType: PropTypes.object,
  stateProvince: PropTypes.object,
  institutionSubtypes: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string
};

InstitutionTypeContainer.defaultProps = {
  institutionType: {},
  stateProvince: {},
  institutionSubtypes: [],
  className: ''
};

const makeMapStateToProps = () => {
  const getInstitutionSubtypes = makeInstitutionSubtypesPublishedSelector();
  const mapStateToProps = (state, props) => ({
    institutionSubtypes: getInstitutionSubtypes(state, props)
  });
  return mapStateToProps;
};

export default connect(
  makeMapStateToProps,
  undefined
)(InstitutionTypeContainer);
