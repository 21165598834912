import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { sortByProperty } from '../../helpers/utilities';
import { orm } from '../models';

export const ormSelector = state => state.orm;

// export const institutionalAccreditationAgenciesSelector = createSelector(
//   ormSelector,
//   ormCreateSelector(orm, session => {
//     return session.AccreditationAgency.all()
//       .toModelArray()
//       .map(agency => Object.assign({}, agency.ref))
//       .filter(agency => agency.agencyType === 'INSTITUTION')
//       .sort((a, b) => sortByProperty(a, b, 'name'));
//   })
// );

export const institutionalAccreditationAgenciesSelector = createSelector(
  state => state,
  redux => {
    const selectorORM = ormCreateSelector([orm], session => {
      return session.AccreditationAgency.all()
        .toModelArray()
        .map(agency => Object.assign({}, agency.ref))
        .filter(agency => agency.agencyType === 'INSTITUTION')
        .sort((a, b) => sortByProperty(a, b, 'name'));
    });
    return selectorORM(redux);
  }
);

// export const allAccreditationAgenciesSelector = createSelector(
//   ormSelector,
//   ormCreateSelector(orm, session => {
//     return session.AccreditationAgency.all()
//       .toModelArray()
//       .map(agency => Object.assign({}, agency.ref))
//       .sort((a, b) => sortByProperty(a, b, 'name'));
//   })
// );

export const allAccreditationAgenciesSelector = createSelector(
  state => state,
  redux => {
    const selectorORM = ormCreateSelector([orm], session => {
      return session.AccreditationAgency.all()
        .toModelArray()
        .map(agency => Object.assign({}, agency.ref))
        .sort((a, b) => sortByProperty(a, b, 'name'));
    });
    return selectorORM(redux);
  }
);

// export const programAccreditationAgenciesSelector = createSelector(
//   ormSelector,
//   ormCreateSelector(orm, session => {
//     return session.AccreditationAgency.all()
//       .toModelArray()
//       .map(agency => Object.assign({}, agency.ref))
//       .filter(agency => agency.agencyType === 'PROGRAM')
//       .sort((a, b) => sortByProperty(a, b, 'name'));
//   })
// );

export const programAccreditationAgenciesSelector = createSelector(
  state => state,
  redux => {
    const selectorORM = ormCreateSelector([orm], session => {
      return session.AccreditationAgency.all()
        .toModelArray()
        .map(agency => Object.assign({}, agency.ref))
        .filter(agency => agency.agencyType === 'PROGRAM')
        .sort((a, b) => sortByProperty(a, b, 'name'));
    });
    return selectorORM(redux);
  }
);
