import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { doGeneralErrorNotification } from '../../../redux/actions/notificationActions';

import { doGetLocations } from '../../../redux/actions/locationActions';
import { countriesSelector } from '../../../redux/selectors/locationsSelectors';
import LocationDashboard from './LocationDashboard';

class LocationDashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newCountry: null
    };

    this.clearNewCountry = this.clearNewCountry.bind(this);
    this.addCountry = this.addCountry.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.onFetchLocations();
  }

  clearNewCountry = () => {
    this.setState({ newCountry: null });
  };

  addCountry = () => {
    const { newCountry } = this.state;

    if (newCountry) {
      this.props.onGeneralErrorNotification(
        'Cannot create more than one Country simultaneously'
      );
      return;
    }

    const emptyCountry = {
      code: '',
      name: '',
      isNew: true
    };

    this.setState({
      newCountry: emptyCountry
    });
  };

  render() {
    const { countries } = this.props;
    const { newCountry } = this.state;

    return (
      <LocationDashboard
        countries={countries}
        newCountry={newCountry}
        addCountry={this.addCountry}
        clearNewCountry={this.clearNewCountry}
      />
    );
  }
}

LocationDashboardContainer.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.object),
  onFetchLocations: PropTypes.func,
  onGeneralErrorNotification: PropTypes.func
};

LocationDashboardContainer.defaultProps = {
  countries: [],
  onFetchLocations: undefined,
  onGeneralErrorNotification: undefined
};

const mapStateToProps = state => ({
  countries: countriesSelector(state)
});

const mapDispatchToProps = dispatch => ({
  onFetchLocations: () => dispatch(doGetLocations()),
  onGeneralErrorNotification: errorMessage =>
    dispatch(doGeneralErrorNotification(errorMessage))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationDashboardContainer);
export { LocationDashboardContainer };
