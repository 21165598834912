/* eslint no-unused-vars: off */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NewDiscipline from './NewDiscipline';
import { doPostProgramDiscipline } from '../../../../../redux/actions/programDisciplineActions';
import { makeProgramCipDisciplinesSelector } from '../../../../../redux/selectors/programDisciplineSelectors';
import {
  validateStringProperty,
  allFieldsAreValid
} from '../../../../../helpers/validateGeneric';
import { validateDisciplineCode } from '../../../../../helpers/validateCodes';

class NewDisciplineContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      code: '',
      title: '',
      showValidationErrors: false
    };

    this.setNew = this.setNew.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmission = this.handleSubmission.bind(this);
  }

  componentDidMount() {
    const { code } = this.state;
    const { discipline } = this.props;
    this._isMounted = true;
    if (!code) {
      this.setNew(discipline);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  setNew = discipline => {
    const { isNew, ...newDiscipline } = discipline;
    if (this._isMounted) {
      this.setState(state => ({
        ...newDiscipline
      }));
    }
  };

  setValidationErrors = newDiscipline => {
    const { allProgramDisciplines } = this.props;
    const titleError = validateStringProperty(
      newDiscipline.title,
      allProgramDisciplines,
      'title'
    );
    const codeError = validateDisciplineCode(
      newDiscipline.code,
      allProgramDisciplines,
      2
    );
    const newValidationErrors = {
      title: titleError,
      code: codeError
    };

    return newValidationErrors;
  };

  handleSubmission = () => {
    const { showValidationErrors, ...newDiscipline } = this.state;
    const { cipcode } = this.props;

    const newValidationErrors = this.setValidationErrors(newDiscipline);

    if (allFieldsAreValid(newValidationErrors)) {
      this.props.onPostCreate({ cipcodeUuid: cipcode.uuid, ...newDiscipline });
      this.setState({ showValidationErrors: false });
      this.props.clearNew();
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const { code, title, showValidationErrors } = this.state;
    const { cipcode, allProgramDisciplines, clearNew } = this.props;

    const newDiscipline = {
      code,
      title
    };

    return (
      <NewDiscipline
        cipcode={cipcode}
        newDiscipline={newDiscipline}
        handleChange={this.handleChange}
        onPostCreate={this.handleSubmission}
        clearNew={clearNew}
        allProgramDisciplines={allProgramDisciplines}
        showValidationErrors={showValidationErrors}
      />
    );
  }
}

NewDisciplineContainer.propTypes = {
  cipcode: PropTypes.object.isRequired,
  discipline: PropTypes.object,
  allProgramDisciplines: PropTypes.arrayOf(PropTypes.object),
  onPostCreate: PropTypes.func,
  clearNew: PropTypes.func
};
NewDisciplineContainer.defaultProps = {
  discipline: {},
  allProgramDisciplines: [],
  onPostCreate: undefined,
  clearNew: undefined
};

const makeMapStateToProps = () => {
  const getCipcodeDisciplines = makeProgramCipDisciplinesSelector();
  const mapStateToProps = (state, props) => ({
    allProgramDisciplines: getCipcodeDisciplines(state, props)
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  onPostCreate: newDiscipline =>
    dispatch(doPostProgramDiscipline(newDiscipline))
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(NewDisciplineContainer);
export { NewDisciplineContainer };
