export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';

/* NOTIFICATION Actions */
export const OPEN_NOTIFICATION = 'OPEN_NOTIFICATION';
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION';
export const NEW_GENERAL_ERROR = 'NEW_GENERAL_ERROR';

// CIP ACTIONS
export const GET_CIP_CODES = 'GET_CIP_CODES';
export const GET_CIP_CODES_SUCCESS = 'GET_CIP_CODES_SUCCESS';
export const GET_CIP_CODES_ERROR = 'GET_CIP_CODES_ERROR';
export const CLEAR_CIP_CODES = 'CLEAR_CIP_CODES';
export const POST_CIP_CODE = 'POST_CIP_CODE';
export const POST_CIP_CODE_SUCCESS = 'POST_CIP_CODE_SUCCESS';
export const POST_CIP_CODE_ERROR = 'POST_CIP_CODE_ERROR';
export const PUT_CIP_CODE = 'PUT_CIP_CODE';
export const PUT_CIP_CODE_SUCCESS = 'PUT_CIP_CODE_SUCCESS';
export const PUT_CIP_CODE_ERROR = 'PUT_CIP_CODE_ERROR';
export const DELETE_CIP_CODE = 'DELETE_CIP_CODE';
export const DELETE_CIP_CODE_SUCCESS = 'DELETE_CIP_CODE_SUCCESS';
export const DELETE_CIP_CODE_ERROR = 'DELETE_CIP_CODE_ERROR';

export const CLEAR_SUCCESFULLY_UPDATED_CIP_CODE =
  'CLEAR_SUCCESFULLY_UPDATED_CIP_CODE';
export const CLEAR_SUCCESFULLY_CREATED_CIP_CODE =
  'CLEAR_SUCCESFULLY_CREATED_CIP_CODE';

// DISCIPLINE ACTIONS
export const POST_DISCIPLINE = 'POST_DISCIPLINE';
export const POST_DISCIPLINE_SUCCESS = 'POST_DISCIPLINE_SUCCESS';
export const POST_DISCIPLINE_ERROR = 'POST_DISCIPLINE_ERROR';
export const PUT_DISCIPLINE = 'PUT_DISCIPLINE';
export const PUT_DISCIPLINE_SUCCESS = 'PUT_DISCIPLINE_SUCCESS';
export const PUT_DISCIPLINE_ERROR = 'PUT_DISCIPLINE_ERROR';
export const DELETE_DISCIPLINE = 'DELETE_DISCIPLINE';
export const DELETE_DISCIPLINE_SUCCESS = 'DELETE_DISCIPLINE_SUCCESS';
export const DELETE_DISCIPLINE_ERROR = 'DELETE_DISCIPLINE_ERROR';

export const CLEAR_SUCCESFULLY_UPDATED_DISCIPLINE =
  'CLEAR_SUCCESFULLY_UPDATED_DISCIPLINE';
export const CLEAR_SUCCESFULLY_CREATED_DISCIPLINE =
  'CLEAR_SUCCESFULLY_CREATED_DISCIPLINE';

// TOPIC ACTIONS
export const GET_DISCIPLINE_TOPICS = 'GET_DISCIPLINE_TOPICS';
export const GET_DISCIPLINE_TOPICS_SUCCESS = 'GET_DISCIPLINE_TOPICS_SUCCESS';
export const GET_DISCIPLINE_TOPICS_ERROR = 'GET_DISCIPLINE_TOPICS_ERROR';
export const POST_TOPIC = 'POST_TOPIC';
export const POST_TOPIC_SUCCESS = 'POST_TOPIC_SUCCESS';
export const POST_TOPIC_ERROR = 'POST_TOPIC_ERROR';
export const PUT_TOPIC = 'PUT_TOPIC';
export const PUT_TOPIC_SUCCESS = 'PUT_TOPIC_SUCCESS';
export const PUT_TOPIC_ERROR = 'PUT_TOPIC_ERROR';
export const PATCH_MOVE_TOPIC = 'PATCH_MOVE_TOPIC';
export const PATCH_MOVE_TOPIC_EMPTY_DISCIPLINE =
  'PATCH_MOVE_TOPIC_EMPTY_DISCIPLINE';
export const PATCH_MOVE_TOPIC_EMPTY_DISCIPLINE_SUCCESS =
  'PATCH_MOVE_TOPIC_EMPTY_DISCIPLINE_SUCCESS';
export const PATCH_MOVE_TOPIC_SUCCESS = 'PATCH_MOVE_TOPIC_SUCCESS';
export const PATCH_MOVE_TOPIC_ERROR = 'PATCH_MOVE_TOPIC_ERROR';
export const DELETE_TOPIC = 'DELETE_TOPIC';
export const DELETE_TOPIC_SUCCESS = 'DELETE_TOPIC_SUCCESS';
export const DELETE_TOPIC_ERROR = 'DELETE_TOPIC_ERROR';

export const CLEAR_SUCCESFULLY_CREATED_TOPIC =
  'CLEAR_SUCCESFULLY_CREATED_TOPIC';
export const CLEAR_SUCCESFULLY_UPDATED_TOPIC =
  'CLEAR_SUCCESFULLY_UPDATED_TOPIC';

// SUBTOPIC ACTIONS
export const GET_SUBTOPICS = 'GET_SUBTOPICS';
export const GET_SUBTOPICS_SUCCESS = 'GET_SUBTOPICS_SUCCESS';
export const GET_SUBTOPICS_ERROR = 'GET_SUBTOPICS_ERROR';
export const POST_SUBTOPIC = 'POST_SUBTOPIC';
export const POST_SUBTOPIC_SUCCESS = 'POST_SUBTOPIC_SUCCESS';
export const POST_SUBTOPIC_ERROR = 'POST_SUBTOPIC_ERROR';
export const PUT_SUBTOPIC = 'PUT_SUBTOPIC';
export const PUT_SUBTOPIC_SUCCESS = 'PUT_SUBTOPIC_SUCCESS';
export const PUT_SUBTOPIC_ERROR = 'PUT_SUBTOPIC_ERROR';

export const PATCH_MOVE_SUBTOPIC = 'PATCH_MOVE_SUBTOPIC';
export const PATCH_MOVE_SUBTOPIC_SUCCESS = 'PATCH_MOVE_SUBTOPIC_SUCCESS';
export const PATCH_MOVE_SUBTOPIC_ERROR = 'PATCH_MOVE_SUBTOPIC_ERROR';

export const DELETE_SUBTOPIC = 'DELETE_SUBTOPIC';
export const DELETE_SUBTOPIC_SUCCESS = 'DELETE_SUBTOPIC_SUCCESS';
export const DELETE_SUBTOPIC_ERROR = 'DELETE_SUBTOPIC_ERROR';
export const CLEAR_SUCCESFULLY_CREATED_SUBTOPIC =
  'CLEAR_SUCCESFULLY_CREATED_SUBTOPIC';
export const CLEAR_SUCCESFULLY_UPDATED_SUBTOPIC =
  'CLEAR_SUCCESFULLY_UPDATED_SUBTOPIC';

// MICROCOMPETENCY ACTIONS
export const GET_MICROCOMPETENCIES = 'GET_MICROCOMPETENCIES';
export const GET_MICROCOMPETENCIES_SUCCESS = 'GET_MICROCOMPETENCIES_SUCCESS';
export const GET_MICROCOMPETENCIES_ERROR = 'GET_MICROCOMPETENCIES_ERROR';

export const POST_MICROCOMPETENCY = 'POST_MICROCOMPETENCY';
export const POST_MICROCOMPETENCY_SUCCESS = 'POST_MICROCOMPETENCY_SUCCESS';
export const POST_MICROCOMPETENCY_ERROR = 'POST_MICROCOMPETENCY_ERROR';

export const PUT_MICROCOMPETENCY = 'PUT_MICROCOMPETENCY';
export const PUT_MICROCOMPETENCY_SUCCESS = 'PUT_MICROCOMPETENCY_SUCCESS';
export const PUT_MICROCOMPETENCY_ERROR = 'PUT_MICROCOMPETENCY_ERROR';

export const PATCH_MOVE_MICROCOMPETENCY = 'PATCH_MOVE_MICROCOMPETENCY';
export const PATCH_MOVE_MICROCOMPETENCY_SUCCESS =
  'PATCH_MOVE_MICROCOMPETENCY_SUCCESS';
export const PATCH_MOVE_MICROCOMPETENCY_ERROR =
  'PATCH_MOVE_MICROCOMPETENCY_ERROR';

export const PATCH_PUBLISH_MICROCOMPETENCY = 'PATCH_PUBLISH_MICROCOMPETENCY';
export const PATCH_PUBLISH_MICROCOMPETENCY_SUCCESS =
  'PATCH_PUBLISH_MICROCOMPETENCY_SUCCESS';
export const PATCH_PUBLISH_MICROCOMPETENCY_ERROR =
  'PATCH_PUBLISH_MICROCOMPETENCY_ERROR';

export const DELETE_MICROCOMPETENCY = 'DELETE_MICROCOMPETENCY';
export const DELETE_MICROCOMPETENCY_SUCCESS = 'DELETE_MICROCOMPETENCY_SUCCESS';
export const DELETE_MICROCOMPETENCY_ERROR = 'DELETE_MICROCOMPETENCY_ERROR';

export const CLEAR_SUCCESFULLY_CREATED_MICROCOMPETENCY =
  'CLEAR_SUCCESFULLY_CREATED_MICROCOMPETENCY';
export const CLEAR_SUCCESFULLY_UPDATED_MICROCOMPETENCY =
  'CLEAR_SUCCESFULLY_UPDATED_MICROCOMPETENCY';

// LOCATIONS
export const GET_LOCATIONS = 'GET_LOCATIONS';
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_ERROR = 'GET_LOCATIONS_ERROR';

export const POST_COUNTRY = 'POST_COUNTRY';
export const POST_COUNTRY_SUCCESS = 'POST_COUNTRY_SUCCESS';
export const POST_COUNTRY_ERROR = 'POST_COUNTRY_ERROR';

export const PUT_COUNTRY = 'PUT_COUNTRY';
export const PUT_COUNTRY_SUCCESS = 'PUT_COUNTRY_SUCCESS';
export const PUT_COUNTRY_ERROR = 'PUT_COUNTRY_ERROR';

export const DELETE_COUNTRY = 'DELETE_COUNTRY';
export const DELETE_COUNTRY_SUCCESS = 'DELETE_COUNTRY_SUCCESS';
export const DELETE_COUNTRY_ERROR = 'DELETE_COUNTRY_ERROR';

export const POST_STATE_PROVINCE = 'POST_STATE_PROVINCE';
export const POST_STATE_PROVINCE_SUCCESS = 'POST_STATE_PROVINCE_SUCCESS';
export const POST_STATE_PROVINCE_ERROR = 'POST_STATE_PROVINCE_ERROR';

export const PUT_STATE_PROVINCE = 'PUT_STATE_PROVINCE';
export const PUT_STATE_PROVINCE_SUCCESS = 'PUT_STATE_PROVINCE_SUCCESS';
export const PUT_STATE_PROVINCE_ERROR = 'PUT_STATE_PROVINCE_ERROR';

export const DELETE_STATE_PROVINCE = 'DELETE_STATE_PROVINCE';
export const DELETE_STATE_PROVINCE_SUCCESS = 'DELETE_STATE_PROVINCE_SUCCESS';
export const DELETE_STATE_PROVINCE_ERROR = 'DELETE_STATE_PROVINCE_ERROR';

export const CLEAR_COUNTRIES = 'CLEAR_COUNTRIES';

// INSTITUTION TYPES & SUBTYPES
export const GET_INSTITUTION_TYPES = 'GET_INSTITUTION_TYPES';
export const GET_INSTITUTION_TYPES_SUCCESS = 'GET_INSTITUTION_TYPES_SUCCESS';
export const GET_INSTITUTION_TYPES_ERROR = 'GET_INSTITUTION_TYPES_ERROR';

export const POST_INSTITUTION_TYPE = 'POST_INSTITUTION_TYPE';
export const POST_INSTITUTION_TYPE_SUCCESS = 'POST_INSTITUTION_TYPE_SUCCESS';
export const POST_INSTITUTION_TYPE_ERROR = 'POST_INSTITUTION_TYPE_ERROR';

export const POST_INSTITUTION_SUBTYPE = 'POST_INSTITUTION_SUBTYPE';
export const POST_INSTITUTION_SUBTYPE_SUCCESS =
  'POST_INSTITUTION_SUBTYPE_SUCCESS';
export const POST_INSTITUTION_SUBTYPE_ERROR = 'POST_INSTITUTION_SUBTYPE_ERROR';

export const PUT_INSTITUTION_TYPE = 'PUT_INSTITUTION_TYPE';
export const PUT_INSTITUTION_TYPE_SUCCESS = 'PUT_INSTITUTION_TYPE_SUCCESS';
export const PUT_INSTITUTION_TYPE_ERROR = 'PUT_INSTITUTION_TYPE_ERROR';

export const PUT_INSTITUTION_SUBTYPE = 'PUT_INSTITUTION_SUBTYPE';
export const PUT_INSTITUTION_SUBTYPE_SUCCESS =
  'PUT_INSTITUTION_SUBTYPE_SUCCESS';
export const PUT_INSTITUTION_SUBTYPE_ERROR = 'PUT_INSTITUTION_SUBTYPE_ERROR';

export const PATCH_MOVE_INSTITUTION_TYPE = 'PATCH_MOVE_INSTITUTION_TYPE';
export const PATCH_MOVE_INSTITUTION_TYPE_SUCCESS =
  'PATCH_MOVE_INSTITUTION_TYPE_SUCCESS';
export const PATCH_MOVE_INSTITUTION_TYPE_ERROR = 'PUT_INSTITUTION_TYPE_ERROR';

export const DELETE_INSTITUTION_TYPE = 'DELETE_INSTITUTION_TYPE';
export const DELETE_INSTITUTION_TYPE_SUCCESS =
  'DELETE_INSTITUTION_TYPE_SUCCESS';
export const DELETE_INSTITUTION_TYPE_ERROR = 'DELETE_INSTITUTION_TYPE_ERROR';

export const DELETE_INSTITUTION_SUBTYPE = 'DELETE_INSTITUTION_SUBTYPE';
export const DELETE_INSTITUTION_SUBTYPE_SUCCESS =
  'DELETE_INSTITUTION_SUBTYPE_SUCCESS';
export const DELETE_INSTITUTION_SUBTYPE_ERROR =
  'DELETE_INSTITUTION_SUBTYPE_ERROR';

// ACCREDITATION AGENCIES
export const GET_ACCREDITATION_AGENCIES = 'GET_ACCREDITATION_AGENCIES';
export const GET_ACCREDITATION_AGENCIES_SUCCESS =
  'GET_ACCREDITATION_AGENCIES_SUCCESS';
export const GET_ACCREDITATION_AGENCIES_ERROR =
  'GET_ACCREDITATION_AGENCIES_ERROR';

export const POST_ACCREDITATION_AGENCY = 'POST_ACCREDITATION_AGENCY';
export const POST_ACCREDITATION_AGENCY_SUCCESS =
  'POST_ACCREDITATION_AGENCY_SUCCESS';
export const POST_ACCREDITATION_AGENCY_ERROR =
  'POST_ACCREDITATION_AGENCY_ERROR';

export const PUT_ACCREDITATION_AGENCY = 'PUT_ACCREDITATION_AGENCY';
export const PUT_ACCREDITATION_AGENCY_SUCCESS =
  'PUT_ACCREDITATION_AGENCY_SUCCESS';
export const PUT_ACCREDITATION_AGENCY_ERROR = 'PUT_ACCREDITATION_AGENCY_ERROR';

export const DELETE_ACCREDITATION_AGENCY = 'DELETE_ACCREDITATION_AGENCY';
export const DELETE_ACCREDITATION_AGENCY_SUCCESS =
  'DELETE_ACCREDITATION_AGENCY_SUCCESS';
export const DELETE_ACCREDITATION_AGENCY_ERROR =
  'DELETE_ACCREDITATION_AGENCY_ERROR';

// INSTITUTIONAL SYSTEMS
export const GET_INSTITUTIONAL_SYSTEMS = 'GET_INSTITUTIONAL_SYSTEMS';
export const GET_INSTITUTIONAL_SYSTEMS_SUCCESS =
  'GET_INSTITUTIONAL_SYSTEMS_SUCCESS';
export const GET_INSTITUTIONAL_SYSTEMS_ERROR =
  'GET_INSTITUTIONAL_SYSTEMS_ERROR';

export const POST_INSTITUTIONAL_SYSTEM = 'POST_INSTITUTIONAL_SYSTEM';
export const POST_INSTITUTIONAL_SYSTEM_SUCCESS =
  'POST_INSTITUTIONAL_SYSTEM_SUCCESS';
export const POST_INSTITUTIONAL_SYSTEM_ERROR =
  'POST_INSTITUTIONAL_SYSTEM_ERROR';

export const PUT_INSTITUTIONAL_SYSTEM = 'PUT_INSTITUTIONAL_SYSTEM';
export const PUT_INSTITUTIONAL_SYSTEM_SUCCESS =
  'PUT_INSTITUTIONAL_SYSTEM_SUCCESS';
export const PUT_INSTITUTIONAL_SYSTEM_ERROR = 'PUT_INSTITUTIONAL_SYSTEM_ERROR';

export const DELETE_INSTITUTIONAL_SYSTEM = 'DELETE_INSTITUTIONAL_SYSTEM';
export const DELETE_INSTITUTIONAL_SYSTEM_SUCCESS =
  'DELETE_INSTITUTIONAL_SYSTEM_SUCCESS';
export const DELETE_INSTITUTIONAL_SYSTEM_ERROR =
  'DELETE_INSTITUTIONAL_SYSTEM_ERROR';

// INSTITUTIONS
export const GET_INSTITUTIONS = 'GET_INSTITUTIONS';
export const GET_INSTITUTIONS_SUCCESS = 'GET_INSTITUTIONS_SUCCESS';
export const GET_INSTITUTIONS_ERROR = 'GET_INSTITUTIONS_ERROR';

export const POST_INSTITUTION = 'POST_INSTITUTION';
export const POST_INSTITUTION_SUCCESS = 'POST_INSTITUTION_SUCCESS';
export const POST_INSTITUTION_ERROR = 'POST_INSTITUTION_ERROR';

export const PUT_INSTITUTION = 'PUT_INSTITUTION';
export const PUT_INSTITUTION_SUCCESS = 'PUT_INSTITUTION_SUCCESS';
export const PUT_INSTITUTION_ERROR = 'PUT_INSTITUTION_ERROR';

export const DELETE_INSTITUTION = 'DELETE_INSTITUTION';
export const DELETE_INSTITUTION_SUCCESS = 'DELETE_INSTITUTION_SUCCESS';
export const DELETE_INSTITUTION_ERROR = 'DELETE_INSTITUTIONAL_SYSTEM_ERROR';

// OTHER
export const CLEAR_SUCCESSFUL_DELETION = 'CLEAR_SUCCESSFUL_DELETION';
export const CLEAR_REVERT_DRAG_DROP = 'CLEAR_REVERT_DRAG_DROP';

// INSTITUTIONS DASHBOARD ACTIONS
export const OPEN_NEW_SYSTEM_MODAL = 'OPEN_NEW_SYSTEM_MODAL';
export const OPEN_EDIT_SYSTEM_MODAL = 'OPEN_EDIT_SYSTEM_MODAL';
export const CLOSE_SYSTEM_MODAL = 'CLOSE_SYSTEM_MODAL';

export const OPEN_NEW_INSTITUTION_MODAL = 'OPEN_NEW_INSTITUTION_MODAL';
export const OPEN_EDIT_INSTITUTION_MODAL = 'OPEN_EDIT_INSTITUTION_MODAL';
export const CLOSE_INSTITUTION_MODAL = 'CLOSE_INSTITUTION_MODAL';

// MOVE CODE ACTIONS
export const OPEN_MOVE_CODE_MODAL = 'OPEN_MOVE_CODE_MODAL';
export const CLOSE_MOVE_CODE_MODAL = 'CLOSE_MOVE_CODE_MODAL';
export const SET_MOVE_CODE_DESTINATION = 'SET_MOVE_CODE_DESTINATION';
export const SET_MOVE_TOPIC_EMPTY_DISCIPLINE =
  'SET_MOVE_TOPIC_EMPTY_DISCIPLINE';

// PROGRAM CIP ACTIONS
export const GET_PROGRAM_CIPCODES = 'GET_PROGRAM_CIPCODES';
export const GET_PROGRAM_CIPCODES_SUCCESS = 'GET_PROGRAM_CIPCODES_SUCCESS';
export const GET_PROGRAM_CIPCODES_ERROR = 'GET_PROGRAM_CIPCODES_ERROR';
export const CLEAR_PROGRAM_CIPCODES = 'CLEAR_PROGRAM_CIPCODES';
export const POST_PROGRAM_CIPCODE = 'POST_PROGRAM_CIPCODE';
export const POST_PROGRAM_CIPCODE_SUCCESS = 'POST_PROGRAM_CIPCODE_SUCCESS';
export const POST_PROGRAM_CIPCODE_ERROR = 'POST_PROGRAM_CIPCODE_ERROR';
export const PUT_PROGRAM_CIPCODE = 'PUT_PROGRAM_CIPCODE';
export const PUT_PROGRAM_CIPCODE_SUCCESS = 'PUT_PROGRAM_CIPCODE_SUCCESS';
export const PUT_PROGRAM_CIPCODE_ERROR = 'PUT_PROGRAM_CIPCODE_ERROR';
export const DELETE_PROGRAM_CIPCODE = 'DELETE_PROGRAM_CIPCODE';
export const DELETE_PROGRAM_CIPCODE_SUCCESS = 'DELETE_PROGRAM_CIPCODE_SUCCESS';
export const DELETE_PROGRAM_CIPCODE_ERROR = 'DELETE_PROGRAM_CIPCODE_ERROR';

export const CLEAR_SUCCESFULLY_CREATED_PROGRAM_CIPCODE =
  'CLEAR_SUCCESFULLY_CREATED_PROGRAM_CIPCODE';

// PROGRAM DISCIPLINE ACTIONS
export const GET_PROGRAM_DISCIPLINES = 'GET_PROGRAM_DISCIPLINES';
export const GET_PROGRAM_DISCIPLINES_SUCCESS =
  'GET_PROGRAM_DISCIPLINES_SUCCESS';
export const GET_PROGRAM_DISCIPLINES_ERROR = 'GET_PROGRAM_DISCIPLINES_ERROR';
export const POST_PROGRAM_DISCIPLINE = 'POST_PROGRAM_DISCIPLINE';
export const POST_PROGRAM_DISCIPLINE_SUCCESS =
  'POST_PROGRAM_DISCIPLINE_SUCCESS';
export const POST_PROGRAM_DISCIPLINE_ERROR = 'POST_PROGRAM_DISCIPLINE_ERROR';
export const PUT_PROGRAM_DISCIPLINE = 'PUT_PROGRAM_DISCIPLINE';
export const PUT_PROGRAM_DISCIPLINE_SUCCESS = 'PUT_PROGRAM_DISCIPLINE_SUCCESS';
export const PUT_PROGRAM_DISCIPLINE_ERROR = 'PUT_PROGRAM_DISCIPLINE_ERROR';
export const DELETE_PROGRAM_DISCIPLINE = 'DELETE_PROGRAM_DISCIPLINE';
export const DELETE_PROGRAM_DISCIPLINE_SUCCESS =
  'DELETE_PROGRAM_DISCIPLINE_SUCCESS';
export const DELETE_PROGRAM_DISCIPLINE_ERROR =
  'DELETE_PROGRAM_DISCIPLINE_ERROR';

export const CLEAR_SUCCESFULLY_CREATED_PROGRAM_DISCIPLINE =
  'CLEAR_SUCCESFULLY_CREATED_PROGRAM_DISCIPLINE';

// PROGRAM CODE ACTIONS
export const GET_PROGRAM_CODES = 'GET_PROGRAM_CODES';
export const GET_PROGRAM_CODES_SUCCESS = 'GET_PROGRAM_CODES_SUCCESS';
export const GET_PROGRAM_CODES_ERROR = 'GET_PROGRAM_CODES_ERROR';

export const GET_PROGRAM_DISCIPLINE_CODES = 'GET_PROGRAM_DISCIPLINE_CODES';
export const GET_PROGRAM_DISCIPLINE_CODES_SUCCESS =
  'GET_PROGRAM_DISCIPLINE_CODES_SUCCESS';
export const GET_PROGRAM_DISCIPLINE_CODES_ERROR =
  'GET_PROGRAM_DISCIPLINE_CODES_ERROR';

export const POST_PROGRAM_CODE = 'POST_PROGRAM_CODE';
export const POST_PROGRAM_CODE_SUCCESS = 'POST_PROGRAM_CODE_SUCCESS';
export const POST_PROGRAM_CODE_ERROR = 'POST_PROGRAM_CODE_ERROR';

export const PUT_PROGRAM_CODE = 'PUT_PROGRAM_CODE';
export const PUT_PROGRAM_CODE_SUCCESS = 'PUT_PROGRAM_CODE_SUCCESS';
export const PUT_PROGRAM_CODE_ERROR = 'PUT_PROGRAM_CODE_ERROR';

export const PATCH_MOVE_PROGRAM_CODE = 'PATCH_MOVE_PROGRAM_CODE';
export const PATCH_MOVE_PROGRAM_CODE_SUCCESS =
  'PATCH_MOVE_PROGRAM_CODE_SUCCESS';
export const PATCH_MOVE_PROGRAM_CODE_ERROR = 'PATCH_MOVE_PROGRAM_CODE_ERROR';

export const PATCH_PUBLISH_PROGRAM_CODE = 'PATCH_PUBLISH_PROGRAM_CODE';
export const PATCH_PUBLISH_PROGRAM_CODE_SUCCESS =
  'PATCH_PUBLISH_PROGRAM_CODE_SUCCESS';
export const PATCH_PUBLISH_PROGRAM_CODE_ERROR =
  'PATCH_PUBLISH_PROGRAM_CODE_ERROR';

export const DELETE_PROGRAM_CODE = 'DELETE_PROGRAM_CODE';
export const DELETE_PROGRAM_CODE_SUCCESS = 'DELETE_PROGRAM_CODE_SUCCESS';
export const DELETE_PROGRAM_CODE_ERROR = 'DELETE_PROGRAM_CODE_ERROR';

export const CLEAR_SUCCESFULLY_CREATED_PROGRAM_CODE =
  'CLEAR_SUCCESFULLY_CREATED_PROGRAM_CODE';

// DEGREE LEVEL ACTIONS
export const GET_DEGREE_LEVELS = 'GET_DEGREE_LEVELS';
export const GET_DEGREE_LEVELS_SUCCESS = 'GET_DEGREE_LEVELS_SUCCESS';
export const GET_DEGREE_LEVELS_ERROR = 'GET_DEGREE_LEVELS_ERROR';

export const POST_DEGREE_LEVEL = 'POST_DEGREE_LEVEL';
export const POST_DEGREE_LEVEL_SUCCESS = 'POST_DEGREE_LEVEL_SUCCESS';
export const POST_DEGREE_LEVEL_ERROR = 'POST_DEGREE_LEVEL_ERROR';

export const PUT_DEGREE_LEVEL = 'PUT_DEGREE_LEVEL';
export const PUT_DEGREE_LEVEL_SUCCESS = 'PUT_DEGREE_LEVEL_SUCCESS';
export const PUT_DEGREE_LEVEL_ERROR = 'PUT_DEGREE_LEVEL_ERROR';

export const PATCH_MOVE_DEGREE_LEVEL = 'PATCH_MOVE_DEGREE_LEVEL';
export const PATCH_MOVE_DEGREE_LEVEL_SUCCESS =
  'PATCH_MOVE_DEGREE_LEVEL_SUCCESS';
export const PATCH_MOVE_DEGREE_LEVEL_ERROR = 'PUT_DEGREE_LEVEL_ERROR';

export const DELETE_DEGREE_LEVEL = 'DELETE_DEGREE_LEVEL';
export const DELETE_DEGREE_LEVEL_SUCCESS = 'DELETE_DEGREE_LEVEL_SUCCESS';
export const DELETE_DEGREE_LEVEL_ERROR = 'DELETE_DEGREE_LEVEL_ERROR';

// INSTITUTION ID TYPE ACTIONS
export const GET_INSTITUTION_ID_TYPES = 'GET_INSTITUTION_ID_TYPES';
export const GET_INSTITUTION_ID_TYPES_SUCCESS =
  'GET_INSTITUTION_ID_TYPES_SUCCESS';
export const GET_INSTITUTION_ID_TYPES_ERROR = 'GET_INSTITUTION_ID_TYPES_ERROR';

export const POST_INSTITUTION_ID_TYPE = 'POST_INSTITUTION_ID_TYPE';
export const POST_INSTITUTION_ID_TYPE_SUCCESS =
  'POST_INSTITUTION_ID_TYPE_SUCCESS';
export const POST_INSTITUTION_ID_TYPE_ERROR = 'POST_INSTITUTION_ID_TYPE_ERROR';

export const PUT_INSTITUTION_ID_TYPE = 'PUT_INSTITUTION_ID_TYPE';
export const PUT_INSTITUTION_ID_TYPE_SUCCESS =
  'PUT_INSTITUTION_ID_TYPE_SUCCESS';
export const PUT_INSTITUTION_ID_TYPE_ERROR = 'PUT_INSTITUTION_ID_TYPE_ERROR';

export const PATCH_MOVE_INSTITUTION_ID_TYPE = 'PATCH_MOVE_INSTITUTION_ID_TYPE';
export const PATCH_MOVE_INSTITUTION_ID_TYPE_SUCCESS =
  'PATCH_MOVE_INSTITUTION_ID_TYPE_SUCCESS';
export const PATCH_MOVE_INSTITUTION_ID_TYPE_ERROR =
  'PUT_INSTITUTION_ID_TYPE_ERROR';

export const DELETE_INSTITUTION_ID_TYPE = 'DELETE_INSTITUTION_ID_TYPE';
export const DELETE_INSTITUTION_ID_TYPE_SUCCESS =
  'DELETE_INSTITUTION_ID_TYPE_SUCCESS';
export const DELETE_INSTITUTION_ID_TYPE_ERROR =
  'DELETE_INSTITUTION_ID_TYPE_ERROR';

// SEARCH ACTIONS
export const SEARCH_PROGRAM_CODES = 'SEARCH_PROGRAM_CODES';
export const SEARCH_PROGRAM_CODES_SUCCESS = 'SEARCH_PROGRAM_CODES_SUCCESS';
export const SEARCH_PROGRAM_CODES_ERROR = 'SEARCH_PROGRAM_CODES_ERROR';
