import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  doPutInstitutionSubtype,
  doDeleteInstitutionSubtype
} from '../../../../redux/actions/institutionTypeActions';
import InstitutionSubtype from './InstitutionSubtype';

class InstitutionSubtypeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: '',
      isPublishModalOpen: false,
      isDeleteModalOpen: false
    };

    this.onPublish = this.onPublish.bind(this);
    this.handlePublishModalOpen = this.handlePublishModalOpen.bind(this);
    this.handlePublishModalClose = this.handlePublishModalClose.bind(this);
  }

  toggleEditView = () => {
    this.setState(prevState => ({
      view: prevState.view !== 'EDIT' ? 'EDIT' : ''
    }));
  };

  handlePublishModalOpen = () => {
    this.setState({
      isPublishModalOpen: true
    });
  };

  handlePublishModalClose = () => {
    this.setState({
      isPublishModalOpen: false
    });
  };

  onPublish = () => {
    const { institutionSubtype } = this.props;
    const payload = {
      ...institutionSubtype,
      published: true
    };
    this.props.onPutPublish(payload);
    this.handlePublishModalClose();
  };

  handleDeleteClick = () => {
    this.setState({
      isDeleteModalOpen: true
    });
  };

  handleDeleteModalClose = () => {
    this.setState({
      isDeleteModalOpen: false
    });
  };

  handleConfirmDelete = () => {
    const { institutionSubtype } = this.props;

    this.props.onDelete(institutionSubtype.uuid);
    this.handleDeleteModalClose();
  };

  render() {
    const { institutionSubtype, institutionType, className } = this.props;
    const { view, isPublishModalOpen, isDeleteModalOpen } = this.state;

    return (
      <InstitutionSubtype
        view={view}
        institutionSubtype={institutionSubtype}
        parentInstitutionType={institutionType}
        className={className}
        isPublishModalOpen={isPublishModalOpen}
        isDeleteModalOpen={isDeleteModalOpen}
        onPublish={this.onPublish}
        handlePublishModalOpen={this.handlePublishModalOpen}
        handlePublishModalClose={this.handlePublishModalClose}
        handleDeleteClick={this.handleDeleteClick}
        handleDeleteModalClose={this.handleDeleteModalClose}
        handleConfirmDelete={this.handleConfirmDelete}
        toggleEditView={this.toggleEditView}
      />
    );
  }
}

InstitutionSubtypeContainer.propTypes = {
  institutionSubtype: PropTypes.object,
  institutionType: PropTypes.object,
  className: PropTypes.string,
  onPutPublish: PropTypes.func,
  onDelete: PropTypes.func
};

InstitutionSubtypeContainer.defaultProps = {
  institutionSubtype: {},
  institutionType: {},
  className: '',
  onPutPublish: undefined,
  onDelete: undefined
};

const mapDispatchToProps = dispatch => ({
  onPutPublish: updates => dispatch(doPutInstitutionSubtype(updates)),
  onDelete: uuid => dispatch(doDeleteInstitutionSubtype(uuid))
});

export default connect(
  undefined,
  mapDispatchToProps
)(InstitutionSubtypeContainer);
