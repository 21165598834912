/* eslint no-unused-vars: off */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EditDiscipline from './EditDiscipline';
import { doPutProgramDiscipline } from '../../../../../redux/actions/programDisciplineActions';
import { makeProgramCipDisciplinesSelector } from '../../../../../redux/selectors/programDisciplineSelectors';
import { validateDisciplineCode } from '../../../../../helpers/validateCodes';
import {
  validateStringProperty,
  allFieldsAreValid
} from '../../../../../helpers/validateGeneric';

class EditDisciplineContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      uuid: '',
      code: '',
      title: '',
      showValidationErrors: false
    };

    this.setExisting = this.setExisting.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  componentDidMount() {
    const { code } = this.state;
    const { discipline } = this.props;
    this._isMounted = true;
    if (!code) {
      this.setExisting(discipline);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  setExisting = discipline => {
    const { ...existingDiscipline } = discipline;
    if (this._isMounted) {
      this.setState(state => ({
        ...existingDiscipline
      }));
    }
  };

  setValidationErrors = existingDiscipline => {
    const { allProgramDisciplines } = this.props;
    const { uuid } = this.state;
    const siblingDisciplines = allProgramDisciplines.filter(
      discipline => discipline.uuid !== uuid
    );
    const titleError = validateStringProperty(
      existingDiscipline.title,
      siblingDisciplines,
      'title'
    );
    const codeError = validateDisciplineCode(
      existingDiscipline.code,
      siblingDisciplines,
      2
    );
    const newValidationErrors = {
      title: titleError,
      code: codeError
    };

    return newValidationErrors;
  };

  handleUpdate = () => {
    const { showValidationErrors, ...existingDiscipline } = this.state;
    const { cipcode } = this.props;
    const newValidationErrors = this.setValidationErrors(existingDiscipline);

    if (allFieldsAreValid(newValidationErrors)) {
      const payload = {
        cipcodeUuid: cipcode.uuid,
        uuid: existingDiscipline.uuid,
        title: existingDiscipline.title,
        code: existingDiscipline.code
      };
      this.props.onPutUpdate(payload);
      this.setState({ showValidationErrors: false });
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const { code, title, uuid, showValidationErrors } = this.state;
    const {
      discipline,
      cipcode,
      allProgramDisciplines,
      toggleEditView
    } = this.props;

    const thisDiscipline = {
      uuid,
      code,
      title
    };

    const siblingDisciplines = allProgramDisciplines.filter(
      discipline => discipline.uuid !== thisDiscipline.uuid
    );

    return (
      <EditDiscipline
        cipcode={cipcode}
        existingDiscipline={thisDiscipline}
        discipline={discipline}
        allProgramDisciplines={siblingDisciplines}
        handleChange={this.handleChange}
        onPutUpdate={this.handleUpdate}
        showValidationErrors={showValidationErrors}
        toggleEditView={toggleEditView}
      />
    );
  }
}

EditDisciplineContainer.propTypes = {
  discipline: PropTypes.object.isRequired,
  cipcode: PropTypes.object.isRequired,
  allProgramDisciplines: PropTypes.arrayOf(PropTypes.object),
  toggleEditView: PropTypes.func,
  onPutUpdate: PropTypes.func
};
EditDisciplineContainer.defaultProps = {
  allProgramDisciplines: [],
  toggleEditView: undefined,
  onPutUpdate: undefined
};

const makeMapStateToProps = () => {
  const getCipcodeDisciplines = makeProgramCipDisciplinesSelector();
  const mapStateToProps = (state, props) => ({
    allProgramDisciplines: getCipcodeDisciplines(state, props)
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  onPutUpdate: updates => dispatch(doPutProgramDiscipline(updates))
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(EditDisciplineContainer);
export { EditDisciplineContainer };
