import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

import { validateCodeTitle } from '../../../../helpers/validateCodes';
import {
  Column,
  ElevatedForm,
  SubmitButton,
  CancelButton,
  TextFieldWithError
} from '../../../Forms/FormComponents';
import { FormTop, FormBottom } from '../../SharedSC/FormSC';

const SubtopicForm = ({
  code,
  subtopic,
  handleChange,
  onSubmit,
  buttonText,
  showValidationErrors,
  closeForm
}) => (
  <ElevatedForm>
    <FormTop>
      <Column size="22%">
        <Tooltip title="Cannot edit generated subtopic code">
          <TextFieldWithError
            id="subtopicCode"
            name="code"
            value={code}
            onChange={event => handleChange(event)}
            margin="normal"
            InputLabelProps={{ shrink: true }}
            label="Subtopic Code*"
            placeholder=""
            variant="outlined"
            disabled
            validate={undefined}
            showValidationErrors={false}
            paddingRight="1rem"
          />
        </Tooltip>
      </Column>
      <Column size="78%">
        <TextFieldWithError
          id="subtopicTitle"
          name="title"
          value={subtopic.title}
          onChange={event => handleChange(event)}
          margin="normal"
          InputLabelProps={{ shrink: true }}
          label="Subtopic Title*"
          placeholder=""
          variant="outlined"
          validate={validateCodeTitle}
          showValidationErrors={showValidationErrors}
        />
      </Column>
    </FormTop>
    <FormBottom>
      <CancelButton onClick={closeForm} noHoverBg>
        Cancel
      </CancelButton>
      <SubmitButton onClick={onSubmit}>{buttonText}</SubmitButton>
    </FormBottom>
  </ElevatedForm>
);

SubtopicForm.propTypes = {
  code: PropTypes.string,
  buttonText: PropTypes.string,
  subtopic: PropTypes.object,
  showValidationErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  onSubmit: PropTypes.func,
  closeForm: PropTypes.func
};
SubtopicForm.defaultProps = {
  code: '',
  buttonText: 'Create',
  subtopic: {},
  showValidationErrors: false,
  handleChange: undefined,
  onSubmit: undefined,
  closeForm: undefined
};

export default SubtopicForm;
