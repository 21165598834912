import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Heading1 } from '@xcomp/xcomp-design-library/dist/components/Typography';
import { Droppable } from 'react-beautiful-dnd';

import {
  HeaderWrapper,
  HeaderLayout,
  HeaderText,
  ButtonHeader,
  ButtonPrimarySC,
  PageActions
} from '../SharedSC/HeaderSC';
import {
  Panel,
  Page,
  Wrapper,
  PanelHeader,
  PanelLabel
} from '../SharedSC/DashboardSC';
import PageHeader from '../../Header/PageHeader';
import DegreeLevel from './DegreeLevel/DegreeLevelContainer';
import NoItemsMessage from '../../NoItemsMessage/NoItemsMessage';
import NewDegreeLevelContainer from './DegreeLevel/NewDegreeLevel/NewDegreeLevelContainer';

const PanelHeaderSC = styled(PanelHeader)`
  padding-left: 1.5rem;
`;

const DegreeLevelDashboard = ({
  degreeLevels,
  newDegreeLevel,
  clearNewDegreeLevel,
  addDegreeLevel
}) => {
  const noItemsMessage = 'There are currently no degree levels created.';
  const someUuid = uuidv4();

  const allDegreeLevels = newDegreeLevel
    ? degreeLevels.concat(newDegreeLevel)
    : degreeLevels;

  return (
    <Page>
      <PageHeader>
        <HeaderWrapper>
          <HeaderLayout>
            <HeaderText>
              <Heading1>Degree Levels</Heading1>
            </HeaderText>
          </HeaderLayout>
        </HeaderWrapper>
      </PageHeader>
      <PageActions>
        <HeaderWrapper>
          <ButtonHeader>
            <ButtonPrimarySC onClick={addDegreeLevel}>
              Add Level
            </ButtonPrimarySC>
          </ButtonHeader>
        </HeaderWrapper>
      </PageActions>
      <Wrapper>
        {allDegreeLevels && allDegreeLevels.length > 0 ? (
          <Droppable droppableId={someUuid}>
            {(provided, snapshot) => (
              <Panel ref={provided.innerRef} {...provided.droppableProps}>
                <PanelHeaderSC>
                  <PanelLabel>Degree Level</PanelLabel>
                </PanelHeaderSC>
                {newDegreeLevel && (
                  <NewDegreeLevelContainer
                    key={someUuid}
                    degreeLevel={newDegreeLevel}
                    allDegreeLevels={allDegreeLevels}
                    clearNewForm={clearNewDegreeLevel}
                  />
                )}
                {degreeLevels.map((degreeLevel, index) => (
                  <DegreeLevel
                    key={degreeLevel.uuid}
                    dragIndex={index}
                    degreeLevel={degreeLevel}
                    isDraggingOver={snapshot.isDraggingOver}
                  />
                ))}
                {provided.placeholder}
              </Panel>
            )}
          </Droppable>
        ) : (
          <Panel>
            <NoItemsMessage message={noItemsMessage} />
          </Panel>
        )}
      </Wrapper>
    </Page>
  );
};

DegreeLevelDashboard.propTypes = {
  degreeLevels: PropTypes.arrayOf(PropTypes.object),
  newDegreeLevel: PropTypes.object,
  clearNewDegreeLevel: PropTypes.func,
  addDegreeLevel: PropTypes.func
};

DegreeLevelDashboard.defaultProps = {
  degreeLevels: [],
  newDegreeLevel: {},
  clearNewDegreeLevel: undefined,
  addDegreeLevel: undefined
};

export default DegreeLevelDashboard;
