import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cipsSelector } from '../../../../redux/selectors/cipsSelectors';
import { codeToMoveSelector } from '../../../../redux/selectors/moveCodeSelectors';
import { doCloseMoveCodeModal } from '../../../../redux/actions/moveCodeActions';
import { types } from '../../../../redux/reducers/moveCodeReducer';
import SelectionModal from './SelectionModal';
import { doPatchMoveMicrocompetency } from '../../../../redux/actions/microcompetencyActions';
import { doPatchMoveSubtopic } from '../../../../redux/actions/subtopicActions';
import {
  doPatchMoveTopic,
  doPatchMoveTopicToEmptyDiscipline
} from '../../../../redux/actions/topicActions';

class SelectionModalContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleMoveSubmit = this.handleMoveSubmit.bind(this);
  }

  handleClose = () => {
    this.props.onCloseMoveCodeModal();
  };

  handleMoveSubmit = moveAbove => {
    const { codeType, codeToMove, destinationCode } = this.props;
    if (destinationCode && codeType === types.micro) {
      this.props.onPostMoveMicrocompetency(
        codeToMove.uuid,
        destinationCode.uuid,
        moveAbove
      );
    } else if (destinationCode && codeType === types.subtopic) {
      this.props.onPostMoveSubtopic(
        codeToMove.uuid,
        destinationCode.uuid,
        moveAbove
      );
    } else if (destinationCode && codeType === types.topic) {
      this.props.onPostMoveTopic(
        codeToMove.uuid,
        destinationCode.uuid,
        moveAbove
      );
    }
    this.handleClose();
  };

  handleMoveTopicToEmptyDiscipline = () => {
    const { codeToMove, newDisciplineUuid } = this.props;
    if (newDisciplineUuid) {
      this.props.onPostMoveTopicToEmptyDiscipline(
        codeToMove.uuid,
        newDisciplineUuid
      );
      this.handleClose();
    }
  };

  render() {
    const {
      open,
      codeToMove,
      codeType,
      fullCodeToMove,
      parentCodeString,
      destinationCode,
      newDisciplineUuid
    } = this.props;
    return (
      <SelectionModal
        fullCodeToMove={fullCodeToMove}
        parentCodeString={parentCodeString}
        modalOpen={open}
        handleModalOpen={this.handleClickOpen}
        handleModalClose={this.handleClose}
        handleMoveSubmit={this.handleMoveSubmit}
        handleMoveTopicToEmptyDiscipline={this.handleMoveTopicToEmptyDiscipline}
        codeToMove={codeToMove}
        codeType={codeType}
        destinationCode={destinationCode}
        newDisciplineUuid={newDisciplineUuid}
      />
    );
  }
}

SelectionModalContainer.propTypes = {
  fullCodeToMove: PropTypes.string,
  parentCodeString: PropTypes.string,
  newDisciplineUuid: PropTypes.string,
  codeType: PropTypes.oneOf([types.topic, types.subtopic, types.micro, '']),
  codeToMove: PropTypes.object,
  open: PropTypes.bool,
  destinationCode: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  onCloseMoveCodeModal: PropTypes.func,
  onPostMoveMicrocompetency: PropTypes.func,
  onPostMoveSubtopic: PropTypes.func,
  onPostMoveTopicToEmptyDiscipline: PropTypes.func,
  onPostMoveTopic: PropTypes.func
};

SelectionModalContainer.defaultProps = {
  fullCodeToMove: '',
  parentCodeString: '',
  newDisciplineUuid: '',
  codeType: '',
  codeToMove: {
    title: '',
    code: ''
  },
  open: false,
  destinationCode: false,
  onCloseMoveCodeModal: undefined,
  onPostMoveMicrocompetency: undefined,
  onPostMoveSubtopic: undefined,
  onPostMoveTopicToEmptyDiscipline: undefined,
  onPostMoveTopic: undefined
};

const mapStateToProps = state => ({
  cips: cipsSelector(state),
  open: state.moveCodeState.open,
  codeToMove: codeToMoveSelector(state),
  fullCodeToMove: state.moveCodeState.fullCodeToMove,
  parentCodeString: state.moveCodeState.parentCodeString,
  codeType: state.moveCodeState.codeType,
  destinationCode: state.moveCodeState.destination,
  newDisciplineUuid: state.moveCodeState.emptyDisciplineUuid
});

const mapDispatchToProps = dispatch => ({
  onCloseMoveCodeModal: () => dispatch(doCloseMoveCodeModal()),
  onPostMoveMicrocompetency: (uuid, destinationCodeUuid, moveAbove) =>
    dispatch(doPatchMoveMicrocompetency(uuid, destinationCodeUuid, moveAbove)),
  onPostMoveSubtopic: (uuid, destinationCodeUuid, moveAbove) =>
    dispatch(doPatchMoveSubtopic(uuid, destinationCodeUuid, moveAbove)),
  onPostMoveTopic: (uuid, destinationCodeUuid, moveAbove) =>
    dispatch(doPatchMoveTopic(uuid, destinationCodeUuid, moveAbove)),
  onPostMoveTopicToEmptyDiscipline: (uuid, newDisciplineUuid) =>
    dispatch(doPatchMoveTopicToEmptyDiscipline(uuid, newDisciplineUuid))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectionModalContainer);
export { SelectionModalContainer };
