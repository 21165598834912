import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NewDiscipline from './NewDiscipline';
import { doPostDiscipline } from '../../../../../redux/actions/disciplineActions';
import { makeCipDisciplinesSelector } from '../../../../../redux/selectors/disciplineSelectors';

import {
  validateCodeTitle,
  validateDisciplineCode
} from '../../../../../helpers/validateCodes';
import { allFieldsAreValid } from '../../../../../helpers/validateGeneric';

class NewDisciplineContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      code: '',
      title: '',
      cipcodeUuid: '',
      showValidationErrors: false
    };

    this.setNewDiscipline = this.setNewDiscipline.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDisciplineSubmission = this.handleDisciplineSubmission.bind(
      this
    );
  }

  componentDidMount() {
    const { code } = this.state;
    const { discipline } = this.props;
    this._isMounted = true;
    if (!code) {
      this.setNewDiscipline(discipline);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  setNewDiscipline = discipline => {
    const { isNew, ...newDiscipline } = discipline;
    if (this._isMounted) {
      this.setState(() => ({
        ...newDiscipline
      }));
    }
  };

  setValidationErrors = newDiscipline => {
    const { allDisciplines } = this.props;
    const titleError = validateCodeTitle(newDiscipline.title);
    const codeError = validateDisciplineCode(
      newDiscipline.code,
      allDisciplines
    );
    const newValidationErrors = {
      title: titleError,
      code: codeError
    };

    return newValidationErrors;
  };

  handleDisciplineSubmission = () => {
    const { showValidationErrors, ...newDiscipline } = this.state;

    const newValidationErrors = this.setValidationErrors(newDiscipline);

    if (allFieldsAreValid(newValidationErrors)) {
      this.props.onPostCreateDiscipline(newDiscipline);
      this.setState({ showValidationErrors: false });
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const { code, title, cipcodeUuid, showValidationErrors } = this.state;
    const { cip, allDisciplines, clearNewDiscipline } = this.props;

    const newDiscipline = {
      code,
      title,
      cipcodeUuid
    };

    return (
      <NewDiscipline
        newDiscipline={newDiscipline}
        cip={cip}
        handleChange={this.handleChange}
        onPostCreateDiscipline={this.handleDisciplineSubmission}
        clearNewDiscipline={clearNewDiscipline}
        allDisciplines={allDisciplines}
        showValidationErrors={showValidationErrors}
      />
    );
  }
}

NewDisciplineContainer.propTypes = {
  cip: PropTypes.object,
  discipline: PropTypes.object,
  allDisciplines: PropTypes.arrayOf(PropTypes.object),
  onPostCreateDiscipline: PropTypes.func,
  clearNewDiscipline: PropTypes.func
};
NewDisciplineContainer.defaultProps = {
  cip: {},
  discipline: {},
  allDisciplines: [],
  onPostCreateDiscipline: undefined,
  clearNewDiscipline: undefined
};

const makeMapStateToProps = () => {
  const getCipDisciplines = makeCipDisciplinesSelector();
  const mapStateToProps = (state, props) => ({
    allDisciplines: getCipDisciplines(state, props)
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  onPostCreateDiscipline: newDiscipline =>
    dispatch(doPostDiscipline(newDiscipline))
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(NewDisciplineContainer);
export { NewDisciplineContainer };
