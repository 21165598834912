import React from 'react';
import styled from 'styled-components';

import { Heading1 } from '@xcomp/xcomp-design-library/dist/components/Typography';
import WelcomeBackIllustration from '@xcomp/xcomp-design-library/dist/illustrations/WelcomeBackIllustration';

const Page = styled.div`
  width: 100%;
  min-height: calc(100vh - 128px);
`;

const Wrapper = styled.div`
  padding: 0 1.5rem;
  height: calc(100vh - 128px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FlexItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const WelcomeBackDashboard = () => {
  return (
    <Page>
      <Wrapper>
        <FlexItem>
          <WelcomeBackIllustration />
        </FlexItem>
        <FlexItem>
          <Heading1>Welcome Back, Todd!</Heading1>
        </FlexItem>
      </Wrapper>
    </Page>
  );
};

export default WelcomeBackDashboard;
