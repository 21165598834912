import {
  GET_INSTITUTIONS,
  GET_INSTITUTIONS_SUCCESS,
  GET_INSTITUTIONS_ERROR,
  POST_INSTITUTION,
  POST_INSTITUTION_SUCCESS,
  POST_INSTITUTION_ERROR,
  PUT_INSTITUTION,
  PUT_INSTITUTION_SUCCESS,
  PUT_INSTITUTION_ERROR,
  DELETE_INSTITUTION,
  DELETE_INSTITUTION_SUCCESS,
  DELETE_INSTITUTION_ERROR
} from './actionTypes';

const doGetInstitutions = () => ({
  type: GET_INSTITUTIONS
});

const doGetInstitutionsSuccess = data => ({
  type: GET_INSTITUTIONS_SUCCESS,
  payload: {
    institutions: data.institutions
  }
});

const doGetInstitutionsError = error => ({
  type: GET_INSTITUTIONS_ERROR,
  payload: {
    error
  }
});

const doPostInstitution = newInstitution => ({
  type: POST_INSTITUTION,
  payload: {
    newInstitution
  }
});

const doPostInstitutionSuccess = data => ({
  type: POST_INSTITUTION_SUCCESS,
  payload: {
    createdInstitution: data.createdInstitution
  }
});

const doPostInstitutionError = error => ({
  type: POST_INSTITUTION_ERROR,
  payload: {
    error
  }
});

const doPutInstitution = institution => ({
  type: PUT_INSTITUTION,
  payload: {
    institution
  }
});

const doPutInstitutionSuccess = data => ({
  type: PUT_INSTITUTION_SUCCESS,
  payload: {
    updatedInstitution: data.updatedInstitution
  }
});

const doPutInstitutionError = error => ({
  type: PUT_INSTITUTION_ERROR,
  payload: {
    error
  }
});

const doDeleteInstitution = uuid => ({
  type: DELETE_INSTITUTION,
  payload: {
    uuid
  }
});

const doDeleteInstitutionSuccess = data => ({
  type: DELETE_INSTITUTION_SUCCESS,
  payload: {
    deletedInstitution: data.deletedInstitution
  }
});

const doDeleteInstitutionError = error => ({
  type: DELETE_INSTITUTION_ERROR,
  payload: {
    error
  }
});

export {
  doGetInstitutions,
  doGetInstitutionsSuccess,
  doGetInstitutionsError,
  doPostInstitution,
  doPostInstitutionSuccess,
  doPostInstitutionError,
  doPutInstitution,
  doPutInstitutionSuccess,
  doPutInstitutionError,
  doDeleteInstitution,
  doDeleteInstitutionSuccess,
  doDeleteInstitutionError
};
