/* eslint no-unused-vars: off */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NewInstitutionType from './NewInstitutionType';
import { doPostInstitutionType } from '../../../../../redux/actions/institutionTypeActions';
import { institutionTypesSelector } from '../../../../../redux/selectors/institutionTypesSelectors';
import {
  validateEntityName,
  allFieldsAreValid
} from '../../../../../helpers/validateGeneric';

class NewInstitutionTypeContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      showValidationErrors: false
    };

    this.setNewInstitutionType = this.setNewInstitutionType.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCreateSubmission = this.handleCreateSubmission.bind(this);
  }

  componentDidMount() {
    const { name } = this.state;
    const { institutionType } = this.props;
    this._isMounted = true;
    if (!name) {
      this.setNewInstitutionType(institutionType);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  setNewInstitutionType = institutionType => {
    const { isNew, ...newInstitutionType } = institutionType;
    if (this._isMounted) {
      this.setState(state => ({
        ...newInstitutionType
      }));
    }
  };

  setValidationErrors = newInstitutionType => {
    const { allInstitutionTypes } = this.props;
    const nameError = validateEntityName(
      newInstitutionType.name,
      allInstitutionTypes
    );
    const newValidationErrors = {
      name: nameError
    };

    return newValidationErrors;
  };

  handleCreateSubmission = () => {
    const { showValidationErrors, ...newInstitutionType } = this.state;

    const newValidationErrors = this.setValidationErrors(newInstitutionType);

    if (allFieldsAreValid(newValidationErrors)) {
      this.props.onPostCreate(newInstitutionType);
      this.setState({ showValidationErrors: false });
      this.props.clearNewForm();
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const { name, showValidationErrors } = this.state;
    const { institutionType, allInstitutionTypes, clearNewForm } = this.props;

    const newInstitutionType = {
      name
    };

    return (
      <NewInstitutionType
        newInstitutionType={newInstitutionType}
        handleChange={this.handleChange}
        onPostCreate={this.handleCreateSubmission}
        clearNewForm={clearNewForm}
        allInstitutionTypes={allInstitutionTypes}
        showValidationErrors={showValidationErrors}
      />
    );
  }
}

NewInstitutionTypeContainer.propTypes = {
  institutionType: PropTypes.object,
  allInstitutionTypes: PropTypes.arrayOf(PropTypes.object),
  onPostCreate: PropTypes.func,
  clearNewForm: PropTypes.func
};
NewInstitutionTypeContainer.defaultProps = {
  institutionType: {},
  allInstitutionTypes: [],
  onPostCreate: undefined,
  clearNewForm: undefined
};

const mapStateToProps = (state, props) => ({
  allInstitutionTypes: institutionTypesSelector(state, props)
});

const mapDispatchToProps = dispatch => ({
  onPostCreate: newInstitutionType =>
    dispatch(doPostInstitutionType(newInstitutionType))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewInstitutionTypeContainer);
export { NewInstitutionTypeContainer };
