import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { doGeneralErrorNotification } from '../../../../redux/actions/notificationActions';
import {
  doPutInstitutionType,
  doDeleteInstitutionType
} from '../../../../redux/actions/institutionTypeActions';
import { makeInstitutionSubtypesSelector } from '../../../../redux/selectors/institutionTypesSelectors';
import InstitutionType from './InstitutionType';

class InstitutionTypeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newInstitutionSubtype: null,
      view: '',
      isPublishModalOpen: false,
      isDeleteModalOpen: false
    };

    this.clearNewSubtype = this.clearNewSubtype.bind(this);
    this.addSubtype = this.addSubtype.bind(this);
    this.onPublish = this.onPublish.bind(this);
    this.handlePublishModalOpen = this.handlePublishModalOpen.bind(this);
    this.handlePublishModalClose = this.handlePublishModalClose.bind(this);
  }

  clearNewSubtype = () => {
    this.setState({ newInstitutionSubtype: null });
  };

  addSubtype = institutionTypeUuid => {
    const { newInstitutionSubtype } = this.state;

    if (newInstitutionSubtype) {
      this.props.onGeneralErrorNotification(
        'Cannot create more than one subtype simultaneously'
      );
      return;
    }

    const emptySubtype = {
      code: '',
      name: '',
      institutionTypeUuid,
      isNew: true
    };

    this.setState({
      newInstitutionSubtype: emptySubtype
    });
  };

  toggleEditView = () => {
    this.setState(prevState => ({
      view: prevState.view !== 'EDIT' ? 'EDIT' : ''
    }));
  };

  handlePublishModalOpen = () => {
    this.setState({
      isPublishModalOpen: true
    });
  };

  handlePublishModalClose = () => {
    this.setState({
      isPublishModalOpen: false
    });
  };

  onPublish = () => {
    const { institutionType } = this.props;
    const payload = {
      ...institutionType,
      published: true
    };
    this.props.onPutPublish(payload);
    this.handlePublishModalClose();
  };

  handleDeleteClick = () => {
    this.setState({
      isDeleteModalOpen: true
    });
  };

  handleDeleteModalClose = () => {
    this.setState({
      isDeleteModalOpen: false
    });
  };

  handleConfirmDelete = () => {
    const { institutionType } = this.props;

    this.props.onDelete(institutionType.uuid);
    this.handleDeleteModalClose();
  };

  render() {
    const {
      institutionType,
      institutionSubtypes,
      className,
      dragIndex,
      isDraggingOver
    } = this.props;
    const {
      newInstitutionSubtype,
      view,
      isPublishModalOpen,
      isDeleteModalOpen
    } = this.state;

    return (
      <InstitutionType
        view={view}
        dragIndex={dragIndex}
        isDraggingOver={isDraggingOver}
        institutionType={institutionType}
        newInstitutionSubtype={newInstitutionSubtype}
        institutionSubtypes={institutionSubtypes}
        className={className}
        isPublishModalOpen={isPublishModalOpen}
        isDeleteModalOpen={isDeleteModalOpen}
        onPublish={this.onPublish}
        handlePublishModalOpen={this.handlePublishModalOpen}
        handlePublishModalClose={this.handlePublishModalClose}
        handleDeleteClick={this.handleDeleteClick}
        handleDeleteModalClose={this.handleDeleteModalClose}
        handleConfirmDelete={this.handleConfirmDelete}
        clearNewSubtype={this.clearNewSubtype}
        addSubtype={this.addSubtype}
        toggleEditView={this.toggleEditView}
      />
    );
  }
}

InstitutionTypeContainer.propTypes = {
  institutionType: PropTypes.object,
  institutionSubtypes: PropTypes.arrayOf(PropTypes.object),
  dragIndex: PropTypes.number.isRequired,
  isDraggingOver: PropTypes.bool,
  className: PropTypes.string,
  onPutPublish: PropTypes.func,
  onDelete: PropTypes.func,
  onGeneralErrorNotification: PropTypes.func
};

InstitutionTypeContainer.defaultProps = {
  institutionType: {},
  institutionSubtypes: [],
  isDraggingOver: false,
  className: '',
  onPutPublish: undefined,
  onDelete: undefined,
  onGeneralErrorNotification: undefined
};

const makeMapStateToProps = () => {
  const getInstitutionSubtypes = makeInstitutionSubtypesSelector();
  const mapStateToProps = (state, props) => ({
    institutionSubtypes: getInstitutionSubtypes(state, props)
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  onGeneralErrorNotification: errorMessage =>
    dispatch(doGeneralErrorNotification(errorMessage)),
  onPutPublish: updates => dispatch(doPutInstitutionType(updates)),
  onDelete: uuid => dispatch(doDeleteInstitutionType(uuid))
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(InstitutionTypeContainer);
