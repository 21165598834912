import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PublishModal from '../../../DialogModal/PublishModal/PublishModal';
import DeleteModal from '../../../DialogModal/DeleteModal/DeleteModal';
import DraggableAccordionRow from '../../../AccordionRow/DraggableAccordionRow';
import EditDegreeLevelContainer from './EditDegreeLevel/EditDegreeLevelContainer';

const DegreeLevelAccordion = styled(DraggableAccordionRow)`
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const Layout = styled.div`
  width: 100%;
`;

const DegreeLevel = ({
  degreeLevel,
  dragIndex,
  isDraggingOver,
  toggleEditView,
  view,
  isPublishModalOpen,
  isDeleteModalOpen,
  handlePublishModalOpen,
  handlePublishModalClose,
  handleDeleteClick,
  handleDeleteModalClose,
  handleConfirmDelete,
  onPublish
}) => {
  const degreeLevelActions = [
    {
      label: 'Edit',
      onClick: toggleEditView,
      shouldOpenOnClick: false,
      icon: 'edit'
    },
    {
      label: 'Publish',
      onClick: handlePublishModalOpen,
      shouldOpenOnClick: false,
      icon: 'edit'
    },
    {
      label: 'Delete',
      onClick: handleDeleteClick,
      shouldOpenOnClick: false,
      icon: 'delete'
    }
  ];

  return (
    <Layout>
      <PublishModal
        isModalOpen={isPublishModalOpen}
        handleModalClose={handlePublishModalClose}
        handlePublish={onPublish}
        entityLabel="Degree Level"
        publishConfirmMessage="This will make the agency available to institutions and lock the agency from being deleted."
      />
      <DeleteModal
        modalOpen={isDeleteModalOpen}
        handleModalClose={() => handleDeleteModalClose()}
        handleDelete={() => handleConfirmDelete()}
        type="Degree Level"
      />
      {view === 'EDIT' ? (
        <EditDegreeLevelContainer
          degreeLevel={degreeLevel}
          key={degreeLevel.uuid}
          toggleEditView={toggleEditView}
        />
      ) : (
        <DegreeLevelAccordion
          columnOne={degreeLevel.degreeLevel}
          columnTwo=""
          actions={degreeLevelActions}
          columnGutters="1.5rem"
          draggableId={degreeLevel.uuid}
          dragIndex={dragIndex}
          isDraggingOver={isDraggingOver}
          isPublished={degreeLevel.published}
          isTopLevel
          leaf
        />
      )}
    </Layout>
  );
};

DegreeLevel.propTypes = {
  view: PropTypes.oneOf(['EDIT', '']),
  degreeLevel: PropTypes.object.isRequired,
  isPublishModalOpen: PropTypes.bool,
  isDeleteModalOpen: PropTypes.bool,
  dragIndex: PropTypes.number.isRequired,
  isDraggingOver: PropTypes.bool,
  onPublish: PropTypes.func,
  handlePublishModalOpen: PropTypes.func,
  handlePublishModalClose: PropTypes.func,
  handleDeleteClick: PropTypes.func,
  handleDeleteModalClose: PropTypes.func,
  handleConfirmDelete: PropTypes.func,
  toggleEditView: PropTypes.func
};

DegreeLevel.defaultProps = {
  view: '',
  isPublishModalOpen: false,
  isDeleteModalOpen: false,
  isDraggingOver: false,
  onPublish: undefined,
  handlePublishModalOpen: undefined,
  handlePublishModalClose: undefined,
  handleDeleteClick: undefined,
  handleDeleteModalClose: undefined,
  handleConfirmDelete: undefined,
  toggleEditView: undefined
};

export default DegreeLevel;
