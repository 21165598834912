import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EditInstitutionSubtype from './EditInstitutionSubtype';
import { makeInstitutionSubtypesSelector } from '../../../../../redux/selectors/institutionTypesSelectors';
import { doPutInstitutionSubtype } from '../../../../../redux/actions/institutionTypeActions';
import {
  allFieldsAreValid,
  validateEntityName
} from '../../../../../helpers/validateGeneric';

class EditInstitutionSubtypeContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      uuid: '',
      code: '',
      name: '',
      institutionTypeUuid: '',
      showValidationErrors: false
    };

    this.setExisting = this.setExisting.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    const { code } = this.state;
    const { institutionSubtype } = this.props;
    this._isMounted = true;
    if (!code) {
      this.setExisting(institutionSubtype);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  setExisting = institutionSubtype => {
    const { ...existingInstitutionSubtype } = institutionSubtype;
    if (this._isMounted) {
      this.setState(() => ({
        ...existingInstitutionSubtype
      }));
    }
  };

  setValidationErrors = existingInstitutionSubtype => {
    const { allSiblings } = this.props;
    const { uuid } = this.state;
    const siblingStateProvinces = allSiblings.filter(
      institutionSubtype => institutionSubtype.uuid !== uuid
    );
    const nameError = validateEntityName(
      existingInstitutionSubtype.name,
      siblingStateProvinces
    );
    const newValidationErrors = {
      name: nameError
    };

    return newValidationErrors;
  };

  handleSave = () => {
    const { showValidationErrors, ...existingInstitutionSubtype } = this.state;
    const newValidationErrors = this.setValidationErrors(
      existingInstitutionSubtype
    );

    if (allFieldsAreValid(newValidationErrors)) {
      const payload = {
        uuid: existingInstitutionSubtype.uuid,
        name: existingInstitutionSubtype.name,
        institutionTypeUuid: existingInstitutionSubtype.institutionTypeUuid
      };
      this.props.onPutUpdate(payload);
      this.setState({ showValidationErrors: false });
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const {
      code,
      name,
      uuid,
      institutionTypeUuid,
      showValidationErrors
    } = this.state;
    const { allSiblings, toggleEditView } = this.props;

    const thisSubtype = {
      uuid,
      code,
      name,
      institutionTypeUuid
    };

    const siblingStateProvinces = allSiblings.filter(
      institutionSubtype => institutionSubtype.uuid !== thisSubtype.uuid
    );

    return (
      <EditInstitutionSubtype
        existingInstitutionSubtype={thisSubtype}
        allSiblings={siblingStateProvinces}
        handleChange={this.handleChange}
        onPutUpdate={this.handleSave}
        showValidationErrors={showValidationErrors}
        toggleEditView={toggleEditView}
      />
    );
  }
}

EditInstitutionSubtypeContainer.propTypes = {
  institutionSubtype: PropTypes.object,
  allSiblings: PropTypes.arrayOf(PropTypes.object),
  toggleEditView: PropTypes.func,
  onPutUpdate: PropTypes.func
};

EditInstitutionSubtypeContainer.defaultProps = {
  institutionSubtype: {},
  allSiblings: [],
  toggleEditView: undefined,
  onPutUpdate: undefined
};

const makeMapStateToProps = () => {
  const getInstitutionSubtypes = makeInstitutionSubtypesSelector();
  const mapStateToProps = (state, props) => ({
    allSiblings: getInstitutionSubtypes(state, props)
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  onPutUpdate: updates => dispatch(doPutInstitutionSubtype(updates))
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(EditInstitutionSubtypeContainer);
export { EditInstitutionSubtypeContainer };
