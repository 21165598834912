import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SubtopicForm from '../SubtopicForm';
import { RowSummary } from '../../../SharedSC/DashboardSC';

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const NewSubtopic = ({
  newSubtopic,
  cip,
  discipline,
  topic,
  showValidationErrors,
  handleChange,
  onPostCreateSubtopic,
  clearNewSubtopic
}) => {
  const cipCode = cip.code;
  const disciplineCode = discipline.code;
  const topicCode = `${topic.level_one_code}${topic.level_two_code}`;
  const newSubtopicCode = `${newSubtopic.level_one_code}${
    newSubtopic.level_two_code
  }`;

  return (
    <Layout>
      <RowSummary>
        <SubtopicForm
          code={`${cipCode}.${disciplineCode}.${topicCode}.${newSubtopicCode}`}
          newSubtopic={newSubtopic}
          buttonText="Create"
          showValidationErrors={showValidationErrors}
          handleChange={event => handleChange(event)}
          onSubmit={onPostCreateSubtopic}
          closeForm={clearNewSubtopic}
        />
      </RowSummary>
    </Layout>
  );
};

NewSubtopic.propTypes = {
  newSubtopic: PropTypes.object,
  cip: PropTypes.object,
  discipline: PropTypes.object,
  topic: PropTypes.object,
  showValidationErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  onPostCreateSubtopic: PropTypes.func,
  clearNewSubtopic: PropTypes.func
};
NewSubtopic.defaultProps = {
  newSubtopic: {},
  cip: {},
  discipline: {},
  topic: {},
  showValidationErrors: false,
  handleChange: undefined,
  onPostCreateSubtopic: undefined,
  clearNewSubtopic: undefined
};

export default NewSubtopic;
export { SubtopicForm };
