import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { sortByProperty } from '../../helpers/utilities';
import { orm } from '../models';

export const ormSelector = state => state.orm;

// export const allUsersSelector = createSelector(
//   ormSelector,
//   ormCreateSelector(orm, session => {
//     return session.User.all()
//       .toModelArray()
//       .map(user => Object.assign({}, user.ref))
//       .sort((a, b) => sortByProperty(a, b, 'lastName'));
//   })
// );

export const allUsersSelector = createSelector(
  state => state,
  redux => {
    const selectorORM = ormCreateSelector([orm], session => {
      return session.User.all()
        .toModelArray()
        .map(user => Object.assign({}, user.ref))
        .sort((a, b) => sortByProperty(a, b, 'lastName'));
    });
    return selectorORM(redux);
  }
);

export const getSystemAdminUuid = (state, props) => props.uuid;

// export const makeGetSystemAdmin = () => {
//   return createSelector(
//     ormSelector,
//     getSystemAdminUuid,
//     ormCreateSelector(orm, (session, systemAdminUuid) => {
//       return session.User.all()
//         .filter(user => {
//           return user.uuid === systemAdminUuid;
//         })
//         .toRefArray()[0];
//     })
//   );
// };

export const makeGetSystemAdmin = () => {
  return createSelector(
    state => state,
    getSystemAdminUuid,
    (redux, systemAdminUuid) => {
      const selectorORM = ormCreateSelector([orm], session => {
        return session.User.all()
          .filter(user => {
            return user.uuid === systemAdminUuid;
          })
          .toRefArray()[0];
      });
      return selectorORM(redux);
    }
  );
};
