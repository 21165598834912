import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Discipline from './Discipline';

import { doGetProgramDisciplineCodes } from '../../../../redux/actions/programCodeActions';
import { doDeleteProgramDiscipline } from '../../../../redux/actions/programDisciplineActions';
import { makeDisciplineProgramCodesSelector } from '../../../../redux/selectors/programCodeSelectors';
import { doGeneralErrorNotification } from '../../../../redux/actions/notificationActions';

class DisciplineContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: '',
      newProgram: null,
      isDeleteModalOpen: false
    };

    this.getChildPrograms = this.getChildPrograms.bind(this);
  }

  getChildPrograms = () => {
    const { programs } = this.props;
    const { discipline } = this.props;

    if (!programs || programs.length < 1) {
      this.props.onFetchProgramCodes(discipline.uuid);
    }
  };

  addProgram = () => {
    const { programs } = this.props;
    const { newProgram } = this.state;

    if (newProgram) {
      this.props.onGeneralErrorNotification(
        'Cannot create more than one program discipline simultaneously'
      );
      return;
    }

    if (programs.length >= 100) {
      this.props.onGeneralErrorNotification(
        'The maximum number of programs has already been reached for this Discipline'
      );
      return;
    }
    const empty = {
      title: '',
      code: '',
      description: '',
      isNew: true
    };

    this.setState({
      newProgram: empty
    });
  };

  clearNew = () => {
    this.setState({ newProgram: null });
  };

  toggleEditView = () => {
    this.setState(prevState => ({
      view: prevState.view !== 'EDIT' ? 'EDIT' : ''
    }));
  };

  handleDeleteClick = () => {
    this.setState({
      isDeleteModalOpen: true
    });
  };

  handleDeleteModalClose = () => {
    this.setState({
      isDeleteModalOpen: false
    });
  };

  handleDelete = () => {
    const { discipline } = this.props;

    this.props.onDelete(discipline.uuid);
    this.handleDeleteModalClose();
  };

  render() {
    const { newProgram, view, isDeleteModalOpen } = this.state;
    const { discipline, programs, cipcode, addProgramDiscipline } = this.props;
    return (
      <Discipline
        view={view}
        discipline={discipline}
        cipcode={cipcode}
        programs={programs}
        newProgram={newProgram}
        isDeleteModalOpen={isDeleteModalOpen}
        addProgramDiscipline={addProgramDiscipline}
        addProgram={this.addProgram}
        getChildPrograms={this.getChildPrograms}
        toggleEditView={this.toggleEditView}
        handleDeleteClick={this.handleDeleteClick}
        handleDeleteModalClose={this.handleDeleteModalClose}
        handleDelete={this.handleDelete}
        clearNew={this.clearNew}
      />
    );
  }
}

DisciplineContainer.propTypes = {
  discipline: PropTypes.object.isRequired,
  cipcode: PropTypes.object.isRequired,
  addProgramDiscipline: PropTypes.func,
  programs: PropTypes.arrayOf(PropTypes.object),
  onDelete: PropTypes.func,
  onFetchProgramCodes: PropTypes.func,
  onGeneralErrorNotification: PropTypes.func
};
DisciplineContainer.defaultProps = {
  programs: [],
  addProgramDiscipline: undefined,
  onDelete: undefined,
  onFetchProgramCodes: undefined,
  onGeneralErrorNotification: undefined
};

const makeMapStateToProps = () => {
  const getDisciplinePrograms = makeDisciplineProgramCodesSelector();
  const mapStateToProps = (state, props) => ({
    programs: getDisciplinePrograms(state, props)
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  onFetchProgramCodes: disciplineUuid =>
    dispatch(doGetProgramDisciplineCodes(disciplineUuid)),
  onDelete: uuid => dispatch(doDeleteProgramDiscipline(uuid)),
  onGeneralErrorNotification: errorMessage =>
    dispatch(doGeneralErrorNotification(errorMessage))
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(DisciplineContainer);
