import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InstitutionIdTypeForm from '../../InstitutionIdTypeForm';
import { validateStringProperty } from '../../../../../helpers/validateGeneric';
import { RowSummary } from '../../../SharedSC/DashboardSC';

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const EditInstitutionIdType = ({
  existingInstitutionIdType,
  allInstitutionIdTypes,
  handleChange,
  onPutUpdate,
  showValidationErrors,
  toggleEditView
}) => (
  <Layout>
    <RowSummary>
      <InstitutionIdTypeForm
        isEditForm
        institutionIdType={existingInstitutionIdType}
        entityLabel="Institution ID Type"
        buttonText="Update"
        handleChange={event => handleChange(event)}
        onSubmit={onPutUpdate}
        showValidationErrors={showValidationErrors}
        validateName={() =>
          validateStringProperty(
            existingInstitutionIdType.name,
            allInstitutionIdTypes,
            'name'
          )
        }
        onFormClose={() => toggleEditView()}
      />
    </RowSummary>
  </Layout>
);

EditInstitutionIdType.propTypes = {
  existingInstitutionIdType: PropTypes.object,
  showValidationErrors: PropTypes.bool,
  allInstitutionIdTypes: PropTypes.arrayOf(PropTypes.object),
  handleChange: PropTypes.func,
  onPutUpdate: PropTypes.func,
  toggleEditView: PropTypes.func
};
EditInstitutionIdType.defaultProps = {
  existingInstitutionIdType: {},
  showValidationErrors: false,
  allInstitutionIdTypes: [],
  handleChange: undefined,
  onPutUpdate: undefined,
  toggleEditView: undefined
};

export default EditInstitutionIdType;
