import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Body1, Caption } from '@xcomp/xcomp-design-library';
import ShowSystemAdminContainer from './ShowSystemAdminContainer';

const Layout = styled(({ rowOffset, ...rest }) => <div {...rest} />)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: ${props => props.rowOffset};
  width: 100%;
`;

const Field = styled.div`
  width: 50%;
  padding-left: 1rem;
  margin-bottom: 1rem;
`;

const CaptionSC = styled(Caption)`
  && {
    color: ${props => props.theme.fontColors.dark};
    margin: 0;

    p {
      margin-top: 0;
    }
  }
`;

const SystemAttributeLink = styled.a`
  && {
    margin: 0;

    &:hover {
      color: ${props => props.theme.colors.primary};
    }
  }
`;

const SystemAttributeText = styled(Body1)`
  && {
    margin: 0;
  }
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const InstitutionalSystemDetails = ({ institutionalSystem, rowOffset }) => {
  const showAdmins =
    institutionalSystem.institutionalSystemAdmins &&
    institutionalSystem.institutionalSystemAdmins.length > 0;
  return (
    <Layout rowOffset={rowOffset}>
      <Field>
        <CaptionSC>Website Address</CaptionSC>

        <SystemAttributeText>
          {institutionalSystem.websiteAddress ? (
            <SystemAttributeLink
              href={institutionalSystem.websiteAddress}
              target="blank"
            >
              {institutionalSystem.websiteAddress}
            </SystemAttributeLink>
          ) : (
            'None'
          )}
        </SystemAttributeText>
      </Field>

      <Field>
        <CaptionSC>Admins</CaptionSC>
        {showAdmins ? (
          <List>
            {institutionalSystem.institutionalSystemAdmins.map(
              (uuid, index) => (
                <ShowSystemAdminContainer
                  uuid={uuid}
                  key={uuid}
                  isLast={
                    index ===
                    institutionalSystem.institutionalSystemAdmins.length - 1
                  }
                />
              )
            )}
          </List>
        ) : (
          <SystemAttributeText>No Admins selected</SystemAttributeText>
        )}
      </Field>
    </Layout>
  );
};

InstitutionalSystemDetails.propTypes = {
  institutionalSystem: PropTypes.object,
  rowOffset: PropTypes.string
};

InstitutionalSystemDetails.defaultProps = {
  institutionalSystem: {},
  rowOffset: '0px'
};

export default InstitutionalSystemDetails;
