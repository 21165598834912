import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AppBar } from '@material-ui/core';
import Menu from '@material-ui/icons/Menu';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';

import { ProfileIcon } from '@xcomp/xcomp-design-library/dist/icons';

import {
  Heading3,
  Subtitle2,
  Body1
} from '@xcomp/xcomp-design-library/dist/components/Typography';

import NavigationItem from './NavigationItem';

const MenuSC = styled(Menu)`
  cursor: pointer;
`;

const GroupLabel = styled(Subtitle2)`
  && {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    padding-left: 15px;
  }
`;

const User = styled(Body1)`
  padding-right: 5px;
  color: ${props => props.theme.colors.white};
`;

const Account = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-rigth: 0;
  margin-left: auto;
`;

const AppBarSC = styled(AppBar)`
  && {
    height: 64px;
    z-index: 1201;
    box-shadow: none;
    border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  }
`;

const ToolbarSC = styled(Toolbar)`
  && {
    display: flex;
    justify-content: flex-start;
    padding-left: 16px;
    padding-right: 16px;
    svg {
      fill: ${props => props.theme.colors.white};
    }
  }
`;

const Logo = styled.div`
  color: ${props => props.theme.colors.white};
  font-weight: 500;
  padding-left: 1.5rem;
`;

const AppTitle = styled(Heading3)`
  color: ${props => props.theme.colors.white};
`;

// const Image = styled.img`
//   margin-top: 5px;
//   height: 30px;
//   src: url(${props => props.src});
// `;

const DrawerBody = styled.div`
  margin-top: 64px;
  width: ${props => (props.open ? '300px' : '60px')};
  transition-duration: 0.3s;
  &:focus {
    outline: none;
  }
`;

const DrawerGroup = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const definitionNav = [
  { route: '/locations', label: 'Locations', icon: 'location' },
  {
    route: '/institutiontype',
    label: 'Institution Type',
    icon: 'school'
  },
  { route: '/programs', label: 'Program Codes', icon: 'additionalReading' },
  {
    route: '/degreelevels',
    label: 'Degree Levels',
    icon: 'degree'
  },
  { route: '/microcompetency', label: 'Microcompetency Codes', icon: 'list' },
  { route: '/agencies', label: 'Agencies', icon: 'category' },
  { route: '/idtypes', label: 'Institution ID Types', icon: 'instituteId' },
  { route: '/globalUsers', label: 'Global Users', icon: 'users' }
];

const managementNav = [
  // { route: '/user', label: 'Users', icon: 'users' },
  {
    route: '/institution',
    label: 'Institutions',
    icon: 'school'
  }
];

const Navigation = ({ toggleDrawer, drawerOpen, location }) => {
  return (
    <div>
      <AppBarSC position="fixed">
        <ToolbarSC>
          <MenuSC
            onClick={() => {
              toggleDrawer();
            }}
          />
          <Logo>
            <Link to="/">
              <AppTitle color="inherit" noWrap>
                XComP Source
              </AppTitle>
              {/* <Image src={logo} /> */}
            </Link>
          </Logo>

          <Account>
            <User>Super Admin</User>
            <ProfileIcon className="profile-icon" />
          </Account>
        </ToolbarSC>
      </AppBarSC>
      <Drawer variant="permanent" open={drawerOpen}>
        <DrawerBody tabIndex={0} role="button" open={drawerOpen}>
          <DrawerGroup open={drawerOpen}>
            <GroupLabel>{drawerOpen ? 'Definitions' : 'Def'}</GroupLabel>
            {definitionNav.map(navItem => {
              return (
                <NavigationItem
                  key={navItem.label}
                  navItem={navItem}
                  drawerOpen={drawerOpen}
                  location={location}
                />
              );
            })}
          </DrawerGroup>
          <Divider />
          <DrawerGroup open={drawerOpen}>
            <GroupLabel>{drawerOpen ? 'Management' : 'Man'}</GroupLabel>
            {managementNav.map(navItem => {
              return (
                <NavigationItem
                  key={navItem.label}
                  navItem={navItem}
                  drawerOpen={drawerOpen}
                  location={location}
                />
              );
            })}
          </DrawerGroup>
          <Divider />
        </DrawerBody>
      </Drawer>
    </div>
  );
};

Navigation.propTypes = {
  toggleDrawer: PropTypes.func,
  drawerOpen: PropTypes.bool,
  location: PropTypes.object
};

Navigation.defaultProps = {
  toggleDrawer: undefined,
  drawerOpen: false,
  location: {}
};

export default withRouter(Navigation);
