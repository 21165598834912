import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@xcomp/xcomp-design-library/dist/icons/content/CheckIcon';
import RemoveIcon from '@xcomp/xcomp-design-library/dist/icons/actions/RemoveIcon';
import InfoIcon from '@xcomp/xcomp-design-library/dist/icons/actions/InfoIcon';
import { ButtonIcon, Body1 } from '@xcomp/xcomp-design-library';
import { types } from '../../redux/reducers/notificationReducer';

const styles = () => ({
  snackbarContent: {
    paddingLeft: '1rem'
  }
});

const RelativeContainer = styled.div``;

const NotificationBody = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
`;

const Icon = styled(({ type, ...rest }) => <span {...rest} />)`
  display: flex;
  justify-content: center;
  align-items: center;

  ${props =>
    props.type === types.error &&
    css`
      svg {
        fill: ${props => props.theme.colors.status.error};
      }
    `}

  ${props =>
    props.type === types.info &&
    css`
      svg {
        fill: ${props => props.theme.colors.status.info};
      }
    `}
`;

const Message = styled(({ type, ...rest }) => <Body1 {...rest} />)`
  display: flex;
  align-items: center;
  margin: 0 1.5rem 0 0.75rem;
  color: ${props => props.theme.colors.white};

  ${props =>
    props.type === types.error &&
    css`
      color: ${props => props.theme.colors.status.error};
    `}

  ${props =>
    props.type === types.info &&
    css`
      color: ${props => props.theme.fontColors.darker};
    `}
`;

const SnackbarSC = styled(({ type, ...rest }) => <Snackbar {...rest} />)`
  && {
    margin-top: 74px;

    > div {
      background-color: ${props => props.bgcolor};
    }

    ${props =>
      props.type === types.info &&
      css`
        > div {
          background-color: ${props => props.theme.colors.white};
          border: 1px solid ${props => props.theme.colors.status.info};
        }
      `}
  }
`;

const ButtonIconSC = styled(({ type, ...rest }) => <ButtonIcon {...rest} />)`
  && {
    position: absolute;
    top: 4px;
    right: 4px;
    svg {
      font-size: 18px;
      fill: ${props => props.theme.colors.white};
    }

    &:hover {
      svg {
        fill: ${props => props.theme.colors.white};
      }
    }

    ${props =>
      props.type === types.info &&
      css`
        svg {
          fill: ${props => props.theme.colors.status.info};
        }
        &:hover {
          svg {
            fill: ${props => props.theme.colors.status.info};
          }
        }
      `}

    ${props =>
      props.type === types.error &&
      css`
        && {
          svg {
            fill: ${props => props.theme.colors.status.error};
          }
          &:hover {
            svg {
              fill: ${props => props.theme.colors.status.error};
            }
          }
        }
      `}
  }
`;

const getIcon = type => {
  if (type === types.success) {
    return <CheckIcon />;
  } else if (type === types.error) {
    return <RemoveIcon />;
  } else if (type === types.info) {
    return <InfoIcon />;
  }
  return <CheckIcon />;
};

const Notification = props => {
  const {
    open,
    handleClose,
    notifications,
    type,
    notificationBg,
    classes
  } = props;
  const snackbarMessage = `${notifications.message}`;

  return (
    <RelativeContainer>
      <SnackbarSC
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        type={type}
        bgcolor={notificationBg}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        message={
          <NotificationBody>
            <Icon type={type}>{getIcon(type)}</Icon>
            <Message type={type}>{snackbarMessage}</Message>
          </NotificationBody>
        }
        transitionDuration={{
          exit: 0
        }}
        ContentProps={{
          'aria-describedby': 'message-id',
          className: classes.snackbarContent
        }}
        action={[
          <ButtonIconSC key="close" type={type} onClick={handleClose} noHoverBg>
            <CloseIcon />
          </ButtonIconSC>
        ]}
      />
    </RelativeContainer>
  );
};

Notification.propTypes = {
  type: PropTypes.oneOf(['Success', 'Error', 'Info', '']),
  open: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  notificationBg: PropTypes.string,
  handleClose: PropTypes.func,
  notifications: PropTypes.object
};

Notification.defaultProps = {
  type: '',
  open: false,
  handleClose: undefined,
  notifications: {},
  notificationBg: 'gray'
};

export default withStyles(styles)(Notification);
