import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Subtitle1 } from '@xcomp/xcomp-design-library';
import CIPCodeContainer from './CIPCode/CIPCodeContainer';
import { types } from '../../../../redux/reducers/moveCodeReducer';
import L2Selection from './L2Selection';

const NoSiblingsMessage = styled(Subtitle1)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MMSelectionHierarchy = ({
  cips,
  className,
  codeType,
  codeToMove,
  destinationCode,
  isL2Code,
  L2Siblings,
  parentCodeString,
  onCodeSelect
}) =>
  !isL2Code ? (
    <div className={className}>
      {cips &&
        cips
          .sort((cipA, cipB) => cipA.code - cipB.code)
          .map(cip => <CIPCodeContainer key={cip.uuid} cip={cip} />)}
    </div>
  ) : (
    <div className={className}>
      {L2Siblings && L2Siblings.length > 1 ? (
        <L2Selection
          L2Siblings={L2Siblings}
          codeType={codeType}
          codeToMove={codeToMove}
          destinationCode={destinationCode}
          parentCodeString={parentCodeString}
          onCodeSelect={onCodeSelect}
        />
      ) : (
        <NoSiblingsMessage>
          {`This L2 ${codeType} has no siblings to move between`}
        </NoSiblingsMessage>
      )}
    </div>
  );

MMSelectionHierarchy.propTypes = {
  cips: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  codeToMove: PropTypes.object,
  codeType: PropTypes.oneOf([types.topic, types.subtopic, types.micro, '']),
  parentCodeString: PropTypes.string,
  isL2Code: PropTypes.bool,
  destinationCode: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  L2Siblings: PropTypes.arrayOf(PropTypes.object),
  onCodeSelect: PropTypes.func
};

MMSelectionHierarchy.defaultProps = {
  cips: [],
  className: '',
  codeToMove: {
    title: '',
    code: ''
  },
  codeType: '',
  parentCodeString: '',
  isL2Code: false,
  destinationCode: false,
  L2Siblings: null,
  onCodeSelect: undefined
};

export default MMSelectionHierarchy;
