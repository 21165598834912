import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { sortByProperty } from '../../helpers/utilities';
import { orm } from '../models';

export const ormSelector = state => state.orm;

// export const allInstitutionsSelector = createSelector(
//   ormSelector,
//   ormCreateSelector(orm, session => {
//     return session.Institution.all()
//       .toModelArray()
//       .map(system => Object.assign({}, system.ref))
//       .sort((a, b) => sortByProperty(a, b, 'name'));
//   })
// );

export const allInstitutionsSelector = createSelector(
  state => state,
  redux => {
    const selectorORM = ormCreateSelector([orm], session => {
      return session.Institution.all()
        .toModelArray()
        .map(system => Object.assign({}, system.ref))
        .sort((a, b) => sortByProperty(a, b, 'name'));
    });
    return selectorORM(redux);
  }
);

export const getInstitutionSubtypeUuid = (state, props) =>
  props.institutionSubtypeUuid;

export const getStateProvinceUuid = (state, props) => props.stateProvinceUuid;

// // get unaffiliated systems for a specific state & subtype
// export const makeStateSubtypeUnaffiliatedInstitutionsSelector = () => {
//   return createSelector(
//     ormSelector,
//     getInstitutionSubtypeUuid,
//     getStateProvinceUuid,
//     ormCreateSelector(
//       orm,
//       (session, institutionSubtypeUuid, stateProvinceUuid) => {
//         return session.Institution.all()
//           .filter(
//             institution =>
//               !institution.institutionalSystemUuid &&
//               institution.institutionSubtypeUuid === institutionSubtypeUuid &&
//               institution.stateProvinceUuid === stateProvinceUuid
//           )
//           .toModelArray()
//           .map(institution => Object.assign({}, institution.ref))
//           .sort((a, b) => sortByProperty(a, b, 'name'));
//       }
//     )
//   );
// };
// get unaffiliated systems for a specific state & subtype
export const makeStateSubtypeUnaffiliatedInstitutionsSelector = () => {
  return createSelector(
    state => state,
    getInstitutionSubtypeUuid,
    getStateProvinceUuid,
    (redux, institutionSubtypeUuid, stateProvinceUuid) => {
      const selectorORM = ormCreateSelector([orm], session => {
        return session.Institution.all()
          .filter(
            institution =>
              !institution.institutionalSystemUuid &&
              institution.institutionSubtypeUuid === institutionSubtypeUuid &&
              institution.stateProvinceUuid === stateProvinceUuid
          )
          .toModelArray()
          .map(institution => Object.assign({}, institution.ref))
          .sort((a, b) => sortByProperty(a, b, 'name'));
      });
      return selectorORM(redux);
    }
  );
};

// get institutions for a specific system
export const getSystemUuid = (state, props) => props.institutionalSystem.uuid;

// export const makeGetSystemInstitutionsSelector = () => {
//   return createSelector(
//     ormSelector,
//     getSystemUuid,
//     ormCreateSelector(orm, (session, institutionalSystemUuid) => {
//       return session.Institution.all()
//         .filter(
//           institution =>
//             institution.institutionalSystemUuid === institutionalSystemUuid
//         )
//         .toRefArray()
//         .sort((a, b) => sortByProperty(a, b, 'name'));
//     })
//   );
// };

export const makeGetSystemInstitutionsSelector = () => {
  return createSelector(
    state => state,
    getSystemUuid,
    (redux, institutionalSystemUuid) => {
      const selectorORM = ormCreateSelector([orm], session => {
        return session.Institution.all()
          .filter(
            institution =>
              institution.institutionalSystemUuid === institutionalSystemUuid
          )
          .toRefArray()
          .sort((a, b) => sortByProperty(a, b, 'name'));
      });
      return selectorORM(redux);
    }
  );
};

// get a specific institutions by uuid
export const getInstitutionUuid = (state, props) => props.institution.uuid;

// export const makeGetInstitutionSelector = () => {
//   return createSelector(
//     ormSelector,
//     getInstitutionUuid,
//     ormCreateSelector(orm, (session, institutionUuid) => {
//       return session.Institution.all()
//         .filter(institution => institution.uuid === institutionUuid)
//         .toRefArray()[0];
//     })
//   );
// };

export const makeGetInstitutionSelector = () => {
  return createSelector(
    state => state,
    getInstitutionUuid,
    (redux, institutionUuid) => {
      const selectorORM = ormCreateSelector([orm], session => {
        return session.Institution.all()
          .filter(institution => institution.uuid === institutionUuid)
          .toRefArray()[0];
      });
      return selectorORM(redux);
    }
  );
};

// get a specific institution by uuid
const getEditInstitutionUuid = state =>
  state.institutionsDashboardState.institutionUuid;

// export const makeEditInstitutionSelector = () => {
//   return createSelector(
//     ormSelector,
//     getEditInstitutionUuid,
//     ormCreateSelector(orm, (session, institutionUuid) => {
//       return session.Institution.all()
//         .filter(institution => institution.uuid === institutionUuid)
//         .toRefArray()[0];
//     })
//   );
// };

export const makeEditInstitutionSelector = () => {
  return createSelector(
    state => state,
    getEditInstitutionUuid,
    (redux, institutionUuid) => {
      const selectorORM = ormCreateSelector([orm], session => {
        return session.Institution.all()
          .filter(institution => institution.uuid === institutionUuid)
          .toRefArray()[0];
      });
      return selectorORM(redux);
    }
  );
};

// get an institution's admins
export const getInstitutionAdminsArray = (state, props) =>
  props.institution.institutionAdmins;

// export const makeGetInstitutionAdminsSelector = () => {
//   return createSelector(
//     ormSelector,
//     getInstitutionAdminsArray,
//     ormCreateSelector(orm, (session, institutionAdmins) => {
//       return session.User.all()
//         .filter(user => institutionAdmins.includes(user.uuid))
//         .toRefArray();
//     })
//   );
// };

export const makeGetInstitutionAdminsSelector = () => {
  return createSelector(
    state => state,
    getInstitutionAdminsArray,
    (redux, institutionAdmins) => {
      const selectorORM = ormCreateSelector([orm], session => {
        return session.User.all()
          .filter(user => institutionAdmins.includes(user.uuid))
          .toRefArray();
      });
      return selectorORM(redux);
    }
  );
};

// export const getEditInstitutionSubtypeUuid = state =>
//   state.institutionsDashboardState.institutionSubtypeUuid;

// export const getEditStateProvinceUuid = state =>
//   state.institutionsDashboardState.stateProvinceUuid;

// export const makeEditStateSubtypeSystemsSelector = () => {
//   return createSelector(
//     ormSelector,
//     getEditInstitutionSubtypeUuid,
//     getEditStateProvinceUuid,
//     ormCreateSelector(
//       orm,
//       (session, institutionSubtypeUuid, stateProvinceUuid) => {
//         return session.Institution.all()
//           .filter(
//             system =>
//               system.institutionSubtypeUuid === institutionSubtypeUuid &&
//               system.stateProvinceUuid === stateProvinceUuid
//           )
//           .toModelArray()
//           .map(system => Object.assign({}, system.ref))
//           .sort((a, b) => sortByProperty(a, b, 'name'));
//       }
//     )
//   );
// };
