import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Caption } from '@xcomp/xcomp-design-library/dist/components/Typography';

import PublishModal from '../../../DialogModal/PublishModal/PublishModal';
import DeleteModal from '../../../DialogModal/DeleteModal/DeleteModal';
import AccordionRowContainer from '../../../AccordionRow/AccordionRowContainer';
import NewStateProvinceContainer from '../StateProvince/NewStateProvince/NewStateProvinceContainer';
import EditCountryContainer from './EditCountry/EditCountryContainer';
import StateProvinceContainer from '../StateProvince/StateProvinceContainer';

const CountryAccordion = styled(AccordionRowContainer)`
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const StyledCaption = styled(({ rowOffset, ...rest }) => <Caption {...rest} />)`
  margin-left: ${props => props.rowOffset};
  margin-bottom: 0;
`;

const FullWidthDiv = styled.div`
  width: 100%;
`;

const NoDisciplinesMessasge = styled(({ rowOffset, ...rest }) => (
  <div {...rest} />
))`
  margin-left: ${props => props.rowOffset};
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  font-weight: 300;
`;

const Country = ({
  country,
  toggleEditView,
  stateProvinces,
  view,
  newStateProvince,
  isPublishModalOpen,
  isDeleteModalOpen,
  clearNewStateProvince,
  handlePublishModalOpen,
  handlePublishModalClose,
  handleDeleteClick,
  handleDeleteModalClose,
  handleDelete,
  onPublish,
  addStateProvince
}) => {
  const countryActions = [
    {
      label: 'Add State/Province',
      onClick: () => addStateProvince(country.uuid),
      shouldOpenOnClick: true,
      icon: 'add'
    },
    {
      label: 'Edit',
      onClick: toggleEditView,
      shouldOpenOnClick: false,
      icon: 'edit'
    },
    {
      label: 'Publish',
      onClick: handlePublishModalOpen,
      shouldOpenOnClick: false,
      icon: 'edit'
    },
    {
      label: 'Delete',
      onClick: handleDeleteClick,
      shouldOpenOnClick: false,
      icon: 'delete'
    }
  ];

  const someUuid = uuidv4();
  const allStateProvinces = newStateProvince
    ? stateProvinces.concat(newStateProvince)
    : stateProvinces;

  return (
    <div>
      <PublishModal
        isModalOpen={isPublishModalOpen}
        handleModalClose={handlePublishModalClose}
        handlePublish={onPublish}
        entityLabel="Country"
        publishConfirmMessage="This will make the Country available to institutions and lock the Country from being deleted."
      />
      <DeleteModal
        modalOpen={isDeleteModalOpen}
        handleModalClose={() => handleDeleteModalClose()}
        handleDelete={() => handleDelete()}
        type="Country"
      />
      {view === 'EDIT' ? (
        <EditCountryContainer
          country={country}
          key={country.uuid}
          toggleEditView={toggleEditView}
        />
      ) : (
        <CountryAccordion
          columnOne={country.code}
          columnTwo={country.name}
          actions={countryActions}
          columnGutters="1.75rem"
          toggleEditView={toggleEditView}
          isPublished={country.published}
          isTopLevel
        >
          {newStateProvince && (
            <NewStateProvinceContainer
              country={country}
              stateProvince={newStateProvince}
              allStateProvinces={allStateProvinces}
              key={someUuid}
              clearNewStateProvince={clearNewStateProvince}
            />
          )}
          {stateProvinces && stateProvinces.length > 0 ? (
            <FullWidthDiv>
              <StyledCaption rowOffset="65px">State/Provinces</StyledCaption>
              {stateProvinces.map(state => (
                <StateProvinceContainer
                  country={country}
                  key={state.uuid}
                  stateProvince={state}
                />
              ))}
            </FullWidthDiv>
          ) : (
            <NoDisciplinesMessasge rowOffset="65px">
              No State/Provinces added
            </NoDisciplinesMessasge>
          )}
        </CountryAccordion>
      )}
    </div>
  );
};

Country.propTypes = {
  view: PropTypes.oneOf(['EDIT', '']),
  country: PropTypes.object.isRequired,
  stateProvinces: PropTypes.arrayOf(PropTypes.object),
  newStateProvince: PropTypes.object,
  isPublishModalOpen: PropTypes.bool,
  isDeleteModalOpen: PropTypes.bool,
  handleDeleteClick: PropTypes.func,
  handleDeleteModalClose: PropTypes.func,
  handleDelete: PropTypes.func,
  onPublish: PropTypes.func,
  clearNewStateProvince: PropTypes.func,
  handlePublishModalOpen: PropTypes.func,
  handlePublishModalClose: PropTypes.func,
  addStateProvince: PropTypes.func,
  toggleEditView: PropTypes.func
};

Country.defaultProps = {
  view: '',
  stateProvinces: [],
  newStateProvince: {},
  isPublishModalOpen: false,
  isDeleteModalOpen: false,
  handleDeleteClick: undefined,
  handleDeleteModalClose: undefined,
  handleDelete: undefined,
  onPublish: undefined,
  clearNewStateProvince: undefined,
  handlePublishModalOpen: undefined,
  handlePublishModalClose: undefined,
  addStateProvince: undefined,
  toggleEditView: undefined
};

export default Country;
