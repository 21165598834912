import {
  GET_INSTITUTION_TYPES,
  GET_INSTITUTION_TYPES_SUCCESS,
  GET_INSTITUTION_TYPES_ERROR,
  POST_INSTITUTION_TYPE,
  POST_INSTITUTION_TYPE_SUCCESS,
  POST_INSTITUTION_TYPE_ERROR,
  POST_INSTITUTION_SUBTYPE,
  POST_INSTITUTION_SUBTYPE_SUCCESS,
  POST_INSTITUTION_SUBTYPE_ERROR,
  PUT_INSTITUTION_SUBTYPE,
  PUT_INSTITUTION_SUBTYPE_SUCCESS,
  PUT_INSTITUTION_SUBTYPE_ERROR,
  PUT_INSTITUTION_TYPE,
  PUT_INSTITUTION_TYPE_SUCCESS,
  PUT_INSTITUTION_TYPE_ERROR,
  PATCH_MOVE_INSTITUTION_TYPE,
  PATCH_MOVE_INSTITUTION_TYPE_SUCCESS,
  PATCH_MOVE_INSTITUTION_TYPE_ERROR,
  DELETE_INSTITUTION_TYPE,
  DELETE_INSTITUTION_TYPE_SUCCESS,
  DELETE_INSTITUTION_TYPE_ERROR,
  DELETE_INSTITUTION_SUBTYPE,
  DELETE_INSTITUTION_SUBTYPE_SUCCESS,
  DELETE_INSTITUTION_SUBTYPE_ERROR
} from './actionTypes';

const doGetInstitutionTypes = () => ({
  type: GET_INSTITUTION_TYPES
});

const doGetInstitutionTypesSuccess = data => ({
  type: GET_INSTITUTION_TYPES_SUCCESS,
  payload: {
    institutionTypes: data.institutionTypes,
    institutionSubtypes: data.institutionSubtypes
  }
});

const doGetInstitutionTypesError = error => ({
  type: GET_INSTITUTION_TYPES_ERROR,
  payload: {
    error
  }
});

const doPostInstitutionType = newInstitutionType => ({
  type: POST_INSTITUTION_TYPE,
  payload: {
    newInstitutionType
  }
});

const doPostInstitutionTypeSuccess = data => ({
  type: POST_INSTITUTION_TYPE_SUCCESS,
  payload: {
    createdInstitutionType: data.createdType
  }
});

const doPostInstitutionTypeError = error => ({
  type: POST_INSTITUTION_TYPE_ERROR,
  payload: {
    error
  }
});

const doPostInstitutionSubtype = newInstitutionSubtype => ({
  type: POST_INSTITUTION_SUBTYPE,
  payload: {
    newInstitutionSubtype
  }
});

const doPostInstitutionSubtypeSuccess = data => ({
  type: POST_INSTITUTION_SUBTYPE_SUCCESS,
  payload: {
    createdInstitutionSubtype: data.createdSubtype
  }
});

const doPostInstitutionSubtypeError = error => ({
  type: POST_INSTITUTION_SUBTYPE_ERROR,
  payload: {
    error
  }
});

const doPutInstitutionType = institutionType => ({
  type: PUT_INSTITUTION_TYPE,
  payload: {
    institutionType
  }
});

const doPutInstitutionTypeSuccess = data => ({
  type: PUT_INSTITUTION_TYPE_SUCCESS,
  payload: {
    updatedInstitutionType: data.updatedType
  }
});

const doPutInstitutionTypeError = error => ({
  type: PUT_INSTITUTION_TYPE_ERROR,
  payload: {
    error
  }
});

const doPutInstitutionSubtype = institutionSubtype => ({
  type: PUT_INSTITUTION_SUBTYPE,
  payload: {
    institutionSubtype
  }
});

const doPutInstitutionSubtypeSuccess = data => ({
  type: PUT_INSTITUTION_SUBTYPE_SUCCESS,
  payload: {
    updatedInstitutionSubtype: data.updatedSubtype
  }
});

const doPutInstitutionSubtypeError = error => ({
  type: PUT_INSTITUTION_SUBTYPE_ERROR,
  payload: {
    error
  }
});

const doPatchMoveInstitutionType = (uuid, destinationOrder) => ({
  type: PATCH_MOVE_INSTITUTION_TYPE,
  payload: {
    uuid,
    destinationOrder
  }
});

const doPatchMoveInstitutionTypeSuccess = data => ({
  type: PATCH_MOVE_INSTITUTION_TYPE_SUCCESS,
  payload: {
    updatedTypeUuid: data.updatedTypeUuid,
    updatedTypes: data.updatedTypes
  }
});

const doPatchMoveInstitutionTypeError = error => ({
  type: PATCH_MOVE_INSTITUTION_TYPE_ERROR,
  payload: {
    error
  }
});

const doDeleteInstitutionType = uuid => ({
  type: DELETE_INSTITUTION_TYPE,
  payload: {
    uuid
  }
});

const doDeleteInstitutionTypeSuccess = data => ({
  type: DELETE_INSTITUTION_TYPE_SUCCESS,
  payload: {
    deletedInstitutionType: data.deletedInstitutionType,
    updatedInstitutionTypes: data.updatedInstitutionTypes
  }
});

const doDeleteInstitutionTypeError = error => ({
  type: DELETE_INSTITUTION_TYPE_ERROR,
  payload: {
    error
  }
});

const doDeleteInstitutionSubtype = uuid => ({
  type: DELETE_INSTITUTION_SUBTYPE,
  payload: {
    uuid
  }
});

const doDeleteInstitutionSubtypeSuccess = data => ({
  type: DELETE_INSTITUTION_SUBTYPE_SUCCESS,
  payload: {
    deletedInstitutionSubtype: data.deletedInstitutionSubtype
  }
});

const doDeleteInstitutionSubtypeError = error => ({
  type: DELETE_INSTITUTION_SUBTYPE_ERROR,
  payload: {
    error
  }
});

export {
  doGetInstitutionTypes,
  doGetInstitutionTypesSuccess,
  doGetInstitutionTypesError,
  doPostInstitutionType,
  doPostInstitutionTypeSuccess,
  doPostInstitutionTypeError,
  doPostInstitutionSubtype,
  doPostInstitutionSubtypeSuccess,
  doPostInstitutionSubtypeError,
  doPutInstitutionType,
  doPutInstitutionTypeSuccess,
  doPutInstitutionTypeError,
  doPutInstitutionSubtype,
  doPutInstitutionSubtypeSuccess,
  doPutInstitutionSubtypeError,
  doPatchMoveInstitutionType,
  doPatchMoveInstitutionTypeSuccess,
  doPatchMoveInstitutionTypeError,
  doDeleteInstitutionType,
  doDeleteInstitutionTypeSuccess,
  doDeleteInstitutionTypeError,
  doDeleteInstitutionSubtype,
  doDeleteInstitutionSubtypeSuccess,
  doDeleteInstitutionSubtypeError
};
