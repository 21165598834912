import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Heading1 } from '@xcomp/xcomp-design-library/dist/components/Typography';

import {
  HeaderWrapper,
  HeaderLayout,
  HeaderText,
  ButtonHeader,
  ButtonPrimarySC,
  PageActions
} from '../SharedSC/HeaderSC';
import {
  Panel,
  Page,
  Wrapper,
  PanelHeader,
  PanelLabel
} from '../SharedSC/DashboardSC';
import PageHeader from '../../Header/PageHeader';
import InstitutionIdType from './InstitutionIdType/InstitutionIdTypeContainer';
import NoItemsMessage from '../../NoItemsMessage/NoItemsMessage';
import NewInstitutionIdTypeContainer from './InstitutionIdType/NewInstitutionIdType/NewInstitutionIdTypeContainer';

const PanelHeaderSC = styled(PanelHeader)`
  padding-left: 1.5rem;
`;

const InstitutionIdTypeDashboard = ({
  institutionIdTypes,
  newInstitutionIdType,
  clearNewIdType,
  addIdType
}) => {
  const noItemsMessage = 'There are currently no institution ID types created.';
  const someUuid = uuidv4();

  const allInstitutionIdTypes = newInstitutionIdType
    ? institutionIdTypes.concat(newInstitutionIdType)
    : institutionIdTypes;

  return (
    <Page>
      <PageHeader>
        <HeaderWrapper>
          <HeaderLayout>
            <HeaderText>
              <Heading1>Institution ID Types</Heading1>
            </HeaderText>
          </HeaderLayout>
        </HeaderWrapper>
      </PageHeader>
      <PageActions>
        <HeaderWrapper>
          <ButtonHeader>
            <ButtonPrimarySC onClick={addIdType}>Add Type</ButtonPrimarySC>
          </ButtonHeader>
        </HeaderWrapper>
      </PageActions>
      <Wrapper>
        {allInstitutionIdTypes && allInstitutionIdTypes.length > 0 ? (
          <Panel>
            <PanelHeaderSC>
              <PanelLabel>Institution ID Type</PanelLabel>
            </PanelHeaderSC>
            {newInstitutionIdType && (
              <NewInstitutionIdTypeContainer
                key={someUuid}
                institutionIdType={newInstitutionIdType}
                allInstitutionIdTypes={allInstitutionIdTypes}
                clearNewForm={clearNewIdType}
              />
            )}
            {institutionIdTypes.map(institutionIdType => (
              <InstitutionIdType
                key={institutionIdType.uuid}
                institutionIdType={institutionIdType}
              />
            ))}
          </Panel>
        ) : (
          <Panel>
            <NoItemsMessage message={noItemsMessage} />
          </Panel>
        )}
      </Wrapper>
    </Page>
  );
};

InstitutionIdTypeDashboard.propTypes = {
  institutionIdTypes: PropTypes.arrayOf(PropTypes.object),
  newInstitutionIdType: PropTypes.object,
  clearNewIdType: PropTypes.func,
  addIdType: PropTypes.func
};

InstitutionIdTypeDashboard.defaultProps = {
  institutionIdTypes: [],
  newInstitutionIdType: {},
  clearNewIdType: undefined,
  addIdType: undefined
};

export default InstitutionIdTypeDashboard;
