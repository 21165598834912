import fetch from "../helpers/fetch";

const getTopics = disciplineUuid =>
  fetch(`/api/discipline/${disciplineUuid}/topics`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      }
      throw new Error(`(status code: ${res.status})`);
    })
    .catch(error => {
      throw error;
    });

const postTopic = newTopic =>
  fetch(`/api/topic/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(newTopic)
  })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      }
      throw new Error(`(status code: ${res.status})`);
    })
    .catch(error => {
      throw error;
    });

const putTopic = ({ uuid, ...topicUpdates }) =>
  fetch(`/api/topic/${uuid}/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(topicUpdates)
  })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      }
      throw new Error(`(status code: ${res.status})`);
    })
    .catch(error => {
      throw error;
    });

const patchMoveTopic = ({ uuid, destinationCodeUuid, moveAbove }) =>
  fetch(`/api/topic/${uuid}/move`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      destinationCodeUuid,
      moveAbove
    })
  })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      }
      throw new Error(`(status code: ${res.status})`);
    })
    .catch(error => {
      throw error;
    });

const patchMoveTopicToEmptyDiscipline = ({ uuid, newDisciplineUuid }) =>
  fetch(`/api/topic/${uuid}/move/empty-discipline`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      newDisciplineUuid
    })
  })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      }
      throw new Error(`(status code: ${res.status})`);
    })
    .catch(error => {
      throw error;
    });

const deleteTopic = topicUuid =>
  fetch(`/api/topic/${topicUuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({})
  }).then(res => {
    if (res.status === 200) {
      return res.json();
    } else {
      throw res;
    }
  });

export {
  getTopics,
  postTopic,
  putTopic,
  patchMoveTopic,
  patchMoveTopicToEmptyDiscipline,
  deleteTopic
};
