import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const styles = () => ({
  bootstrapPlacementLeft: {
    margin: '0'
  },
  bootstrapPlacementRight: {
    margin: '0'
  },
  bootstrapPlacementTop: {
    margin: '0'
  },
  bootstrapPlacementBottom: {
    margin: '0'
  }
});

class CustomTooltip extends React.PureComponent {
  render() {
    const { classes, children, ...rest } = this.props;

    return (
      <Tooltip
        title="Add"
        classes={{
          tooltipPlacementLeft: classes.bootstrapPlacementLeft,
          tooltipPlacementRight: classes.bootstrapPlacementRight,
          tooltipPlacementTop: classes.bootstrapPlacementTop,
          tooltipPlacementBottom: classes.bootstrapPlacementBottom
        }}
        {...rest}
      >
        {children}
      </Tooltip>
    );
  }
}

CustomTooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};

export default withStyles(styles)(CustomTooltip);
