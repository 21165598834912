import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TopicForm from '../TopicForm';
import { RowSummary } from '../../../SharedSC/DashboardSC';

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const EditTopic = ({
  existingTopic,
  cip,
  discipline,
  showValidationErrors,
  handleChange,
  onPostUpdateTopic,
  toggleEditView
}) => {
  const cipCode = cip.code;
  const disciplineCode = discipline.code;
  const existingTopicCode = `${existingTopic.level_one_code}${
    existingTopic.level_two_code
  }`;

  return (
    <Layout>
      <RowSummary>
        <TopicForm
          code={`${cipCode}.${disciplineCode}.${existingTopicCode}`}
          topic={existingTopic}
          buttonText="Update"
          handleChange={event => handleChange(event)}
          onSubmit={onPostUpdateTopic}
          showValidationErrors={showValidationErrors}
          closeForm={toggleEditView}
        />
      </RowSummary>
    </Layout>
  );
};

EditTopic.propTypes = {
  existingTopic: PropTypes.object,
  cip: PropTypes.object,
  discipline: PropTypes.object,
  showValidationErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  onPostUpdateTopic: PropTypes.func,
  toggleEditView: PropTypes.func
};
EditTopic.defaultProps = {
  existingTopic: {},
  cip: {},
  discipline: {},
  showValidationErrors: false,
  handleChange: undefined,
  onPostUpdateTopic: undefined,
  toggleEditView: undefined
};

export default EditTopic;
