import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LocationForm from '../../LocationForm';
import { RowSummary } from '../../../SharedSC/DashboardSC';
import {
  validateLocationName,
  validateStateProvinceCode
} from '../../../../../helpers/validateLocations';

const StateProvinceRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const EditStateProvince = ({
  existingStateProvince,
  allStateProvinces,
  handleChange,
  onPostUpdateStateProvince,
  showValidationErrors,
  toggleEditView
}) => (
  <StateProvinceRow>
    <RowSummary>
      <LocationForm
        location={existingStateProvince}
        entityLabel="State/Province"
        buttonText="Update"
        handleChange={event => handleChange(event)}
        onSubmit={onPostUpdateStateProvince}
        showValidationErrors={showValidationErrors}
        validateCode={() =>
          validateStateProvinceCode(
            existingStateProvince.code,
            allStateProvinces
          )
        }
        validateName={() =>
          validateLocationName(existingStateProvince.name, allStateProvinces)
        }
        closeForm={() => toggleEditView()}
      />
    </RowSummary>
  </StateProvinceRow>
);

EditStateProvince.propTypes = {
  existingStateProvince: PropTypes.object,
  showValidationErrors: PropTypes.bool,
  allStateProvinces: PropTypes.arrayOf(PropTypes.object),
  handleChange: PropTypes.func,
  onPostUpdateStateProvince: PropTypes.func,
  toggleEditView: PropTypes.func
};
EditStateProvince.defaultProps = {
  existingStateProvince: {},
  showValidationErrors: false,
  allStateProvinces: [],
  handleChange: undefined,
  onPostUpdateStateProvince: undefined,
  toggleEditView: undefined
};

export default EditStateProvince;
