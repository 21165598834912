import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EditAccreditationAgency from './EditAccreditationAgency';
import { allAccreditationAgenciesSelector } from '../../../../../redux/selectors/accreditationAgenciesSelectors';
import { doPutAccreditationAgency } from '../../../../../redux/actions/accreditationAgencyActions';
import {
  allFieldsAreValid,
  validateEntityName
} from '../../../../../helpers/validateGeneric';
import { validateAgencyType } from '../../../../../helpers/validateAgencies';

class EditAccreditationAgencyContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      uuid: '',
      name: '',
      showValidationErrors: false
    };

    this.setExistingState = this.setExistingState.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.hanldeSave = this.hanldeSave.bind(this);
  }

  componentDidMount() {
    const { name } = this.state;
    const { accreditationAgency } = this.props;
    this._isMounted = true;
    if (!name) {
      this.setExistingState(accreditationAgency);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  setExistingState = accreditationAgency => {
    const { ...existingAccreditationAgency } = accreditationAgency;
    if (this._isMounted) {
      this.setState(() => ({
        ...existingAccreditationAgency
      }));
    }
  };

  setValidationErrors = existingAccreditationAgency => {
    const { allAgencies } = this.props;
    const { uuid } = this.state;
    const siblings = allAgencies.filter(
      accreditationAgency => accreditationAgency.uuid !== uuid
    );
    const nameError = validateEntityName(
      existingAccreditationAgency.name,
      siblings
    );
    const typeError = validateAgencyType(
      existingAccreditationAgency.agencyType
    );
    const newValidationErrors = {
      name: nameError,
      agencyType: typeError
    };

    return newValidationErrors;
  };

  hanldeSave = () => {
    const { showValidationErrors, ...existingAccreditationAgency } = this.state;
    const newValidationErrors = this.setValidationErrors(
      existingAccreditationAgency
    );

    if (allFieldsAreValid(newValidationErrors)) {
      const payload = {
        uuid: existingAccreditationAgency.uuid,
        name: existingAccreditationAgency.name
      };
      this.props.onPostUpdate(payload);
      this.setState({ showValidationErrors: false });
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const { name, order, uuid, showValidationErrors } = this.state;
    const { allAgencies, toggleEditView } = this.props;

    const thisAgency = {
      uuid,
      name,
      order
    };

    const siblings = allAgencies.filter(
      accreditationAgency => accreditationAgency.uuid !== thisAgency.uuid
    );

    return (
      <EditAccreditationAgency
        existingAccreditationAgency={thisAgency}
        allAgencies={siblings}
        handleChange={this.handleChange}
        onPostUpdate={this.hanldeSave}
        showValidationErrors={showValidationErrors}
        toggleEditView={toggleEditView}
      />
    );
  }
}

EditAccreditationAgencyContainer.propTypes = {
  accreditationAgency: PropTypes.object,
  allAgencies: PropTypes.arrayOf(PropTypes.object),
  toggleEditView: PropTypes.func,
  onPostUpdate: PropTypes.func
};
EditAccreditationAgencyContainer.defaultProps = {
  accreditationAgency: {},
  allAgencies: [],
  toggleEditView: undefined,
  onPostUpdate: undefined
};

const mapStateToProps = (state, props) => ({
  allAgencies: allAccreditationAgenciesSelector(state, props)
});

const mapDispatchToProps = dispatch => ({
  onPostUpdate: updates => dispatch(doPutAccreditationAgency(updates))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditAccreditationAgencyContainer);
export { EditAccreditationAgencyContainer };
