import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NewTopic from './NewTopic';
import { doPostTopic } from '../../../../../redux/actions/topicActions';
import { validateCodeTitle } from '../../../../../helpers/validateCodes';
import { allFieldsAreValid } from '../../../../../helpers/validateGeneric';

class NewTopicContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      level_one_code: '',
      level_two_code: '',
      title: '',
      disciplineUuid: '',
      showValidationErrors: false
    };

    this.setNewTopic = this.setNewTopic.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTopicSubmission = this.handleTopicSubmission.bind(this);
  }

  componentDidMount() {
    const { level_one_code, level_two_code } = this.state;
    const { topic } = this.props;
    this._isMounted = true;
    if (!level_one_code || !level_two_code) {
      this.setNewTopic(topic);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  setNewTopic = topic => {
    const { isNew, ...newTopic } = topic;
    if (this._isMounted) {
      this.setState(() => ({
        ...newTopic
      }));
    }
  };

  setValidationErrors = newTopic => {
    const titleError = validateCodeTitle(newTopic.title);
    const newValidationErrors = {
      title: titleError
    };

    return newValidationErrors;
  };

  handleTopicSubmission = () => {
    const { showValidationErrors, ...newTopic } = this.state;

    const newValidationErrors = this.setValidationErrors(newTopic);

    if (allFieldsAreValid(newValidationErrors)) {
      this.props.onPostCreateTopic(newTopic);
      this.setState({ showValidationErrors: false });
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const {
      level_one_code,
      level_two_code,
      title,
      disciplineUuid,
      showValidationErrors
    } = this.state;
    const { cip, discipline, clearNewTopic } = this.props;

    const newTopic = {
      level_one_code,
      level_two_code,
      title,
      disciplineUuid
    };

    return (
      <NewTopic
        newTopic={newTopic}
        cip={cip}
        discipline={discipline}
        handleChange={this.handleChange}
        clearNewTopic={clearNewTopic}
        onPostCreateTopic={this.handleTopicSubmission}
        showValidationErrors={showValidationErrors}
      />
    );
  }
}

NewTopicContainer.propTypes = {
  cip: PropTypes.object,
  discipline: PropTypes.object,
  topic: PropTypes.object,
  onPostCreateTopic: PropTypes.func,
  clearNewTopic: PropTypes.func
};
NewTopicContainer.defaultProps = {
  cip: {},
  discipline: {},
  topic: {},
  onPostCreateTopic: undefined,
  clearNewTopic: undefined
};

const mapDispatchToProps = dispatch => ({
  onPostCreateTopic: newTopic => dispatch(doPostTopic(newTopic))
});

export default connect(
  undefined,
  mapDispatchToProps
)(NewTopicContainer);
export { NewTopicContainer };
