import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CipcodeForm from '../CipcodeForm';
import { validateCipcode } from '../../../../../helpers/validateCodes';
import { validateStringProperty } from '../../../../../helpers/validateGeneric';
import { RowSummary } from '../../../SharedSC/DashboardSC';

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const EditCipcode = ({
  existingCipcode,
  allCipcodes,
  handleChange,
  onPutUpdate,
  showValidationErrors,
  toggleEditView
}) => (
  <Layout>
    <RowSummary>
      <CipcodeForm
        cipcode={existingCipcode}
        buttonText="Update"
        showValidationErrors={showValidationErrors}
        handleChange={handleChange}
        validateCode={() => validateCipcode(existingCipcode.code, allCipcodes)}
        validateTitle={title =>
          validateStringProperty(title, allCipcodes, 'title')
        }
        onSubmit={onPutUpdate}
        closeForm={toggleEditView}
      />
    </RowSummary>
  </Layout>
);

EditCipcode.propTypes = {
  existingCipcode: PropTypes.object,
  showValidationErrors: PropTypes.bool,
  allCipcodes: PropTypes.arrayOf(PropTypes.object),
  handleChange: PropTypes.func,
  onPutUpdate: PropTypes.func,
  toggleEditView: PropTypes.func
};
EditCipcode.defaultProps = {
  existingCipcode: {},
  showValidationErrors: false,
  allCipcodes: [],
  handleChange: undefined,
  onPutUpdate: undefined,
  toggleEditView: undefined
};

export default EditCipcode;
