import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ProgramForm from '../ProgramForm';
import { RowSummary } from '../../../SharedSC/DashboardSC';
import {
  validateProgramCode,
  validateCodeDescription
} from '../../../../../helpers/validateCodes';
import { validateStringProperty } from '../../../../../helpers/validateGeneric';

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const EditProgram = ({
  cipcode,
  discipline,
  existingProgram,
  allProgramCodes,
  handleChange,
  handleRelatedProgramFieldChange,
  handleRelatedProgramAdd,
  handleRelatedProgramRemove,
  onPutUpdate,
  showValidationErrors,
  toggleEditView
}) => (
  <Layout>
    <RowSummary>
      <ProgramForm
        cipcode={cipcode}
        discipline={discipline}
        program={existingProgram}
        buttonText="Update"
        showValidationErrors={showValidationErrors}
        validateCode={() =>
          validateProgramCode(existingProgram.code, allProgramCodes, 2)
        }
        validateTitle={title =>
          validateStringProperty(title, allProgramCodes, 'title')
        }
        validateDescription={validateCodeDescription}
        handleChange={handleChange}
        handleRelatedProgramFieldChange={handleRelatedProgramFieldChange}
        handleRelatedProgramAdd={handleRelatedProgramAdd}
        handleRelatedProgramRemove={handleRelatedProgramRemove}
        onSubmit={onPutUpdate}
        closeForm={toggleEditView}
      />
    </RowSummary>
  </Layout>
);

EditProgram.propTypes = {
  cipcode: PropTypes.object.isRequired,
  discipline: PropTypes.object.isRequired,
  existingProgram: PropTypes.object,
  showValidationErrors: PropTypes.bool,
  allProgramCodes: PropTypes.arrayOf(PropTypes.object),
  handleChange: PropTypes.func,
  handleRelatedProgramFieldChange: PropTypes.func,
  handleRelatedProgramAdd: PropTypes.func,
  handleRelatedProgramRemove: PropTypes.func,
  onPutUpdate: PropTypes.func,
  toggleEditView: PropTypes.func
};
EditProgram.defaultProps = {
  existingProgram: {},
  showValidationErrors: false,
  allProgramCodes: [],
  handleChange: undefined,
  handleRelatedProgramFieldChange: undefined,
  handleRelatedProgramAdd: undefined,
  handleRelatedProgramRemove: undefined,
  onPutUpdate: undefined,
  toggleEditView: undefined
};

export default EditProgram;
