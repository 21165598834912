/* eslint no-unused-vars: off */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import NewInstitutionalSystem from './NewInstitutionalSystem';
import { doPostInstitutionalSystem } from '../../../../../redux/actions/institutionalSystemActions';
import { allInstitutionalSystemsSelector } from '../../../../../redux/selectors/institutionalSystemsSelectors';
import { validateStateProvinceCode } from '../../../../../helpers/validateLocations';
import {
  allFieldsAreValid,
  validateEntityName,
  validateRequired,
  validateSelectUserFieldMultiple
} from '../../../../../helpers/validateGeneric';

class NewInstitutionalSystemContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      websiteAddress: '',
      selectedCountryUuid: '',
      selectedInstitutionTypeUuid: '',
      institutionSubtypeUuid: '',
      stateProvinceUuid: '',
      institutionalSystemAdmins: [],
      showValidationErrors: false
    };

    this.setNewInstitutionalSystem = this.setNewInstitutionalSystem.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUserFieldChange = this.handleUserFieldChange.bind(this);
    this.handleSubmission = this.handleSubmission.bind(this);
  }

  componentDidMount() {
    const { name } = this.state;
    const { institutionalSystem } = this.props;
    this._isMounted = true;
    if (!name && institutionalSystem) {
      this.setNewInstitutionalSystem(institutionalSystem);
    }
  }

  componentDidUpdate(prevProps) {
    const { institutionalSystem } = this.props;
    if (!institutionalSystem && prevProps.institutionalSystem !== null) {
      this.clearSystemState(institutionalSystem);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  handleUserFieldChange = (values, fieldName) => {
    this.setState(() => ({
      [fieldName]: values
    }));
  };

  setNewInstitutionalSystem = institutionalSystem => {
    if (this._isMounted) {
      this.setState(state => ({
        ...institutionalSystem
      }));
    }
  };

  clearSystemState = () => {
    if (this._isMounted) {
      this.setState(state => ({
        name: '',
        websiteAddress: '',
        selectedCountryUuid: '',
        selectedInstitutionTypeUuid: '',
        institutionSubtypeUuid: '',
        stateProvinceUuid: '',
        institutionalSystemAdmins: [],
        showValidationErrors: false
      }));
    }
  };

  setValidationErrors = newInstitutionalSystem => {
    const { allInstitutionalSystems } = this.props;
    const nameError = validateEntityName(
      newInstitutionalSystem.name,
      allInstitutionalSystems
    );

    const subtypeError = validateRequired(
      newInstitutionalSystem.institutionSubtypeUuid
    );
    const stateProvinceError = validateRequired(
      newInstitutionalSystem.stateProvinceUuid
    );
    const newValidationErrors = {
      name: nameError,
      institutionSubtypeUuid: subtypeError,
      stateProvinceUuid: stateProvinceError
    };

    return newValidationErrors;
  };

  handleSubmission = () => {
    const { showValidationErrors, ...newInstitutionalSystem } = this.state;

    const newValidationErrors = this.setValidationErrors(
      newInstitutionalSystem
    );

    if (allFieldsAreValid(newValidationErrors)) {
      this.props.onPostCreateInstitutionalSystem(newInstitutionalSystem);
      this.setState({ showValidationErrors: false });
      this.props.clearNewInstitutionalSystem();
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const {
      name,
      websiteAddress,
      selectedCountryUuid,
      selectedInstitutionTypeUuid,
      institutionSubtypeUuid,
      stateProvinceUuid,
      institutionalSystemAdmins,
      showValidationErrors
    } = this.state;
    const {
      institutionalSystem,
      isNewSystemModalOpen,
      handleModalClose,
      onSystemModalSubmit,
      allInstitutionalSystems,
      clearNewInstitutionalSystem
    } = this.props;

    const newInstitutionalSystem = {
      name,
      websiteAddress,
      selectedCountryUuid,
      selectedInstitutionTypeUuid,
      institutionSubtypeUuid,
      stateProvinceUuid,
      institutionalSystemAdmins
    };

    return (
      <NewInstitutionalSystem
        newInstitutionalSystem={newInstitutionalSystem}
        isNewSystemModalOpen={isNewSystemModalOpen}
        handleModalClose={handleModalClose}
        onSystemModalSubmit={onSystemModalSubmit}
        handleChange={this.handleChange}
        handleUserFieldChange={this.handleUserFieldChange}
        onPostCreateInstitutionalSystem={this.handleSubmission}
        clearNewInstitutionalSystem={clearNewInstitutionalSystem}
        allInstitutionalSystems={allInstitutionalSystems}
        showValidationErrors={showValidationErrors}
      />
    );
  }
}

NewInstitutionalSystemContainer.propTypes = {
  institutionalSystem: PropTypes.object,
  allInstitutionalSystems: PropTypes.arrayOf(PropTypes.object),
  isNewSystemModalOpen: PropTypes.bool,
  handleModalClose: PropTypes.func,
  onSystemModalSubmit: PropTypes.func,
  onPostCreateInstitutionalSystem: PropTypes.func,
  clearNewInstitutionalSystem: PropTypes.func
};
NewInstitutionalSystemContainer.defaultProps = {
  institutionalSystem: {},
  allInstitutionalSystems: [],
  isNewSystemModalOpen: false,
  handleModalClose: undefined,
  onSystemModalSubmit: undefined,
  onPostCreateInstitutionalSystem: undefined,
  clearNewInstitutionalSystem: undefined
};

const mapStateToProps = (state, props) => ({
  allInstitutionalSystems: allInstitutionalSystemsSelector(state, props)
});

const mapDispatchToProps = dispatch => ({
  onPostCreateInstitutionalSystem: newInstitutionalSystem =>
    dispatch(doPostInstitutionalSystem(newInstitutionalSystem))
});

export default connect(
  undefined,
  mapDispatchToProps
)(NewInstitutionalSystemContainer);
export { NewInstitutionalSystemContainer };
