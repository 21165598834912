import { takeEvery, all } from 'redux-saga/effects';
import { handleFetchUsers } from './userSaga';
import {
  handleFetchCips,
  handlePostCreateCipcode,
  handlePostUpdateCipcode,
  handleDeleteCipcode
} from './cipsSaga';
import {
  handleFetchTopics,
  handlePostCreateTopic,
  handlePostUpdateTopic,
  handlePostMoveTopic,
  handlePostMoveTopicToEmptyDiscipline,
  handleDeleteTopic
} from './topicsSaga';
import {
  handleFetchSubtopics,
  handlePostCreateSubtopic,
  handlePostUpdateSubtopic,
  handlePostMoveSubtopic,
  handleDeleteSubtopic
} from './subtopicsSaga';
import {
  handleFetchMicrocompetencies,
  handlePostCreateMicrocompetency,
  handlePostMoveMicrocompetency,
  handlePostUpdateMicrocompetency,
  handlePostPublishMicrocompetency,
  handleDeleteMicrocompetency
} from './microcompetenciesSaga';
import {
  handleFetchLocations,
  handlePostCreateCountry,
  handlePostCreateStateProvince,
  handlePostUpdateCountry,
  handlePostUpdateStateProvince,
  handleDeleteCountry,
  handleDeleteStateProvince
} from './locationsSaga';
import {
  handleFetchInstitutionTypes,
  handlePostCreateInstitutionType,
  handlePostCreateInstitutionSubtype,
  handlePutUpdateInstitutionType,
  handlePutUpdateInstitutionSubtype,
  handlePatchMoveInstitutionType,
  handleDeleteInstitutionType,
  handleDeleteInstitutionSubtype
} from './institutionTypesSaga';
import {
  handlePostCreateDiscipline,
  handlePostUpdateDiscipline,
  handleDeleteDiscipline
} from './disciplinesSaga';
import {
  handleFetchAccreditationAgencies,
  handlePostCreateAccreditationAgency,
  handlePutUpdateAccreditationAgency,
  handleDeleteAccreditationAgency
} from './accreditationAgenciesSaga';
import {
  handleFetchInstitutionalSystems,
  handlePostCreateInstitutionalSystem,
  handlePutUpdateInstitutionalSystem,
  handleDeleteInstitutionalSystem
} from './institutionalSystemsSaga';
import {
  handleFetchInstitutions,
  handlePostCreateInstitution,
  handlePutUpdateInstitution,
  handleDeleteInstitution
} from './institutionsSaga';
import {
  handleFetchProgramCipcodes,
  handlePostCreateProgramCipcode,
  handlePutUpdateProgramCipcode,
  handleDeleteProgramCipcode
} from './programCipcodeSaga';
import {
  handleFetchProgramDisciplines,
  handlePostProgramDisciplines,
  handlePutUpdateProgramDiscipline,
  handleDeleteProgramDiscipline
} from './programDisciplineSaga';
import {
  handleFetchProgramCodes,
  handleSearchProgramCodes,
  handleFetchDisciplineProgramCodes,
  handlePostCreateProgramCode,
  handlePutUpdateProgramCode,
  handlePatchPublishProgramCode,
  handleDeleteProgramCode
} from './programCodeSaga';
import {
  handleFetchDegreeLevels,
  handlePostCreateDegreeLevel,
  handlePutUpdateDegreeLevel,
  handlePatchMoveDegreeLevel,
  handleDeleteDegreeLevel
} from './degreeLevelsSaga';
import {
  GET_USERS,
  GET_CIP_CODES,
  GET_DISCIPLINE_TOPICS,
  GET_SUBTOPICS,
  GET_MICROCOMPETENCIES,
  GET_LOCATIONS,
  POST_MICROCOMPETENCY,
  POST_SUBTOPIC,
  POST_TOPIC,
  POST_DISCIPLINE,
  POST_CIP_CODE,
  PUT_MICROCOMPETENCY,
  PUT_SUBTOPIC,
  PUT_TOPIC,
  PUT_DISCIPLINE,
  PUT_CIP_CODE,
  DELETE_MICROCOMPETENCY,
  DELETE_TOPIC,
  DELETE_SUBTOPIC,
  DELETE_DISCIPLINE,
  DELETE_CIP_CODE,
  PATCH_MOVE_MICROCOMPETENCY,
  PATCH_MOVE_SUBTOPIC,
  PATCH_MOVE_TOPIC,
  PATCH_MOVE_TOPIC_EMPTY_DISCIPLINE,
  PATCH_PUBLISH_MICROCOMPETENCY,
  POST_COUNTRY,
  POST_STATE_PROVINCE,
  PUT_COUNTRY,
  PUT_STATE_PROVINCE,
  DELETE_COUNTRY,
  DELETE_STATE_PROVINCE,
  GET_INSTITUTION_TYPES,
  POST_INSTITUTION_TYPE,
  POST_INSTITUTION_SUBTYPE,
  PUT_INSTITUTION_SUBTYPE,
  PUT_INSTITUTION_TYPE,
  PATCH_MOVE_INSTITUTION_TYPE,
  DELETE_INSTITUTION_TYPE,
  DELETE_INSTITUTION_SUBTYPE,
  GET_ACCREDITATION_AGENCIES,
  POST_ACCREDITATION_AGENCY,
  PUT_ACCREDITATION_AGENCY,
  DELETE_ACCREDITATION_AGENCY,
  GET_INSTITUTIONAL_SYSTEMS,
  POST_INSTITUTIONAL_SYSTEM,
  PUT_INSTITUTIONAL_SYSTEM,
  DELETE_INSTITUTIONAL_SYSTEM,
  GET_INSTITUTIONS,
  POST_INSTITUTION,
  PUT_INSTITUTION,
  DELETE_INSTITUTION,
  GET_PROGRAM_CIPCODES,
  POST_PROGRAM_CIPCODE,
  PUT_PROGRAM_CIPCODE,
  DELETE_PROGRAM_CIPCODE,
  GET_PROGRAM_DISCIPLINES,
  POST_PROGRAM_DISCIPLINE,
  PUT_PROGRAM_DISCIPLINE,
  DELETE_PROGRAM_DISCIPLINE,
  GET_PROGRAM_CODES,
  GET_PROGRAM_DISCIPLINE_CODES,
  POST_PROGRAM_CODE,
  PUT_PROGRAM_CODE,
  PATCH_PUBLISH_PROGRAM_CODE,
  DELETE_PROGRAM_CODE,
  SEARCH_PROGRAM_CODES,
  GET_DEGREE_LEVELS,
  POST_DEGREE_LEVEL,
  PUT_DEGREE_LEVEL,
  PATCH_MOVE_DEGREE_LEVEL,
  DELETE_DEGREE_LEVEL,
  GET_INSTITUTION_ID_TYPES,
  POST_INSTITUTION_ID_TYPE,
  PUT_INSTITUTION_ID_TYPE,
  DELETE_INSTITUTION_ID_TYPE
} from '../actions/actionTypes';
import {
  handleFetchInstitutionIdTypes,
  handlePostCreateInstitutionIdTypes,
  handlePutUpdateInstitutionIdTypes,
  handleDeleteInstitutionIdTypes
} from './institutionIdTypesSaga';

function* watchAll() {
  yield all([takeEvery(GET_CIP_CODES, handleFetchCips)]);
  yield all([takeEvery(GET_USERS, handleFetchUsers)]);
  yield all([takeEvery(GET_DISCIPLINE_TOPICS, handleFetchTopics)]);
  yield all([takeEvery(GET_SUBTOPICS, handleFetchSubtopics)]);
  yield all([takeEvery(GET_MICROCOMPETENCIES, handleFetchMicrocompetencies)]);
  yield all([takeEvery(POST_MICROCOMPETENCY, handlePostCreateMicrocompetency)]);
  yield all([takeEvery(PUT_MICROCOMPETENCY, handlePostUpdateMicrocompetency)]);
  yield all([
    takeEvery(PATCH_MOVE_MICROCOMPETENCY, handlePostMoveMicrocompetency)
  ]);
  yield all([
    takeEvery(PATCH_PUBLISH_MICROCOMPETENCY, handlePostPublishMicrocompetency)
  ]);
  yield all([takeEvery(DELETE_MICROCOMPETENCY, handleDeleteMicrocompetency)]);
  yield all([takeEvery(POST_SUBTOPIC, handlePostCreateSubtopic)]);
  yield all([takeEvery(PUT_SUBTOPIC, handlePostUpdateSubtopic)]);
  yield all([takeEvery(PATCH_MOVE_SUBTOPIC, handlePostMoveSubtopic)]);
  yield all([takeEvery(DELETE_SUBTOPIC, handleDeleteSubtopic)]);
  yield all([takeEvery(POST_TOPIC, handlePostCreateTopic)]);
  yield all([takeEvery(PUT_TOPIC, handlePostUpdateTopic)]);
  yield all([takeEvery(PATCH_MOVE_TOPIC, handlePostMoveTopic)]);
  yield all([
    takeEvery(
      PATCH_MOVE_TOPIC_EMPTY_DISCIPLINE,
      handlePostMoveTopicToEmptyDiscipline
    )
  ]);
  yield all([takeEvery(DELETE_TOPIC, handleDeleteTopic)]);
  yield all([takeEvery(POST_DISCIPLINE, handlePostCreateDiscipline)]);
  yield all([takeEvery(PUT_DISCIPLINE, handlePostUpdateDiscipline)]);
  yield all([takeEvery(DELETE_DISCIPLINE, handleDeleteDiscipline)]);
  yield all([takeEvery(POST_CIP_CODE, handlePostCreateCipcode)]);
  yield all([takeEvery(PUT_CIP_CODE, handlePostUpdateCipcode)]);
  yield all([takeEvery(DELETE_CIP_CODE, handleDeleteCipcode)]);
  yield all([takeEvery(GET_LOCATIONS, handleFetchLocations)]);
  yield all([takeEvery(POST_COUNTRY, handlePostCreateCountry)]);
  yield all([takeEvery(POST_STATE_PROVINCE, handlePostCreateStateProvince)]);
  yield all([takeEvery(PUT_COUNTRY, handlePostUpdateCountry)]);
  yield all([takeEvery(PUT_STATE_PROVINCE, handlePostUpdateStateProvince)]);
  yield all([takeEvery(DELETE_COUNTRY, handleDeleteCountry)]);
  yield all([takeEvery(DELETE_STATE_PROVINCE, handleDeleteStateProvince)]);
  yield all([takeEvery(GET_INSTITUTION_TYPES, handleFetchInstitutionTypes)]);
  yield all([
    takeEvery(POST_INSTITUTION_TYPE, handlePostCreateInstitutionType)
  ]);
  yield all([
    takeEvery(POST_INSTITUTION_SUBTYPE, handlePostCreateInstitutionSubtype)
  ]);
  yield all([takeEvery(PUT_INSTITUTION_TYPE, handlePutUpdateInstitutionType)]);
  yield all([
    takeEvery(PUT_INSTITUTION_SUBTYPE, handlePutUpdateInstitutionSubtype)
  ]);
  yield all([
    takeEvery(PATCH_MOVE_INSTITUTION_TYPE, handlePatchMoveInstitutionType)
  ]);
  yield all([takeEvery(DELETE_INSTITUTION_TYPE, handleDeleteInstitutionType)]);
  yield all([
    takeEvery(DELETE_INSTITUTION_SUBTYPE, handleDeleteInstitutionSubtype)
  ]);
  yield all([
    takeEvery(GET_ACCREDITATION_AGENCIES, handleFetchAccreditationAgencies)
  ]);
  yield all([
    takeEvery(POST_ACCREDITATION_AGENCY, handlePostCreateAccreditationAgency)
  ]);
  yield all([
    takeEvery(PUT_ACCREDITATION_AGENCY, handlePutUpdateAccreditationAgency)
  ]);
  yield all([
    takeEvery(DELETE_ACCREDITATION_AGENCY, handleDeleteAccreditationAgency)
  ]);
  yield all([
    takeEvery(GET_INSTITUTIONAL_SYSTEMS, handleFetchInstitutionalSystems)
  ]);
  yield all([
    takeEvery(POST_INSTITUTIONAL_SYSTEM, handlePostCreateInstitutionalSystem)
  ]);
  yield all([
    takeEvery(PUT_INSTITUTIONAL_SYSTEM, handlePutUpdateInstitutionalSystem)
  ]);
  yield all([
    takeEvery(DELETE_INSTITUTIONAL_SYSTEM, handleDeleteInstitutionalSystem)
  ]);
  yield all([takeEvery(GET_INSTITUTIONS, handleFetchInstitutions)]);
  yield all([takeEvery(POST_INSTITUTION, handlePostCreateInstitution)]);
  yield all([takeEvery(PUT_INSTITUTION, handlePutUpdateInstitution)]);
  yield all([takeEvery(DELETE_INSTITUTION, handleDeleteInstitution)]);
  yield all([takeEvery(GET_PROGRAM_CIPCODES, handleFetchProgramCipcodes)]);
  yield all([takeEvery(POST_PROGRAM_CIPCODE, handlePostCreateProgramCipcode)]);
  yield all([
    takeEvery(GET_PROGRAM_DISCIPLINES, handleFetchProgramDisciplines)
  ]);
  yield all([takeEvery(POST_PROGRAM_DISCIPLINE, handlePostProgramDisciplines)]);
  yield all([
    takeEvery(PUT_PROGRAM_DISCIPLINE, handlePutUpdateProgramDiscipline)
  ]);
  yield all([
    takeEvery(DELETE_PROGRAM_DISCIPLINE, handleDeleteProgramDiscipline)
  ]);
  yield all([takeEvery(PUT_PROGRAM_CIPCODE, handlePutUpdateProgramCipcode)]);
  yield all([takeEvery(DELETE_PROGRAM_CIPCODE, handleDeleteProgramCipcode)]);
  yield all([
    takeEvery(GET_PROGRAM_DISCIPLINE_CODES, handleFetchDisciplineProgramCodes)
  ]);
  yield all([takeEvery(GET_PROGRAM_CODES, handleFetchProgramCodes)]);
  yield all([takeEvery(POST_PROGRAM_CODE, handlePostCreateProgramCode)]);
  yield all([takeEvery(PUT_PROGRAM_CODE, handlePutUpdateProgramCode)]);
  yield all([
    takeEvery(PATCH_PUBLISH_PROGRAM_CODE, handlePatchPublishProgramCode)
  ]);
  yield all([takeEvery(DELETE_PROGRAM_CODE, handleDeleteProgramCode)]);
  yield all([takeEvery(SEARCH_PROGRAM_CODES, handleSearchProgramCodes)]);
  yield all([takeEvery(GET_DEGREE_LEVELS, handleFetchDegreeLevels)]);
  yield all([takeEvery(POST_DEGREE_LEVEL, handlePostCreateDegreeLevel)]);
  yield all([takeEvery(PUT_DEGREE_LEVEL, handlePutUpdateDegreeLevel)]);
  yield all([takeEvery(PATCH_MOVE_DEGREE_LEVEL, handlePatchMoveDegreeLevel)]);
  yield all([takeEvery(DELETE_DEGREE_LEVEL, handleDeleteDegreeLevel)]);
  yield all([
    takeEvery(GET_INSTITUTION_ID_TYPES, handleFetchInstitutionIdTypes)
  ]);
  yield all([
    takeEvery(POST_INSTITUTION_ID_TYPE, handlePostCreateInstitutionIdTypes)
  ]);
  yield all([
    takeEvery(PUT_INSTITUTION_ID_TYPE, handlePutUpdateInstitutionIdTypes)
  ]);
  yield all([
    takeEvery(DELETE_INSTITUTION_ID_TYPE, handleDeleteInstitutionIdTypes)
  ]);
}

export default watchAll;
