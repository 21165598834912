import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {
  MoreIcon,
  AddIcon,
  EditIcon,
  DeleteIcon,
  MoveIcon,
  PublishIcon
} from '@xcomp/xcomp-design-library/dist/icons';
import { ButtonIcon, Body1 } from '@xcomp/xcomp-design-library';

const MenuSC = styled(Menu)`
  && {
    padding: 6px 0;
  }
`;

const ButtonIconSC = styled(ButtonIcon)`
  && {
    &:hover,
    &:focus,
    &:active {
      background: 0;
    }
  }
`;

const MenuItemSC = styled(MenuItem)`
  && {
    font-weight: 500;
    font-size: 0.875rem;
    opacity: 0.8;
    display: flex;
    padding: 8px 20px 8px 16px;
    background: none;

    p {
      margin: 0;
    }
    &:hover {
      background: ${props => props.theme.colors.primaryShades[50]};
    }
  }
`;

const Body1SC = styled(Body1)`
  && {
    color: ${props => props.theme.fontColors.darker};
  }
`;

const OptionIcon = styled.div`
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const getIcon = name => {
  if (name === 'add') {
    return <AddIcon />;
  } else if (name === 'edit') {
    return <EditIcon />;
  } else if (name === 'delete') {
    return <DeleteIcon />;
  } else if (name === 'publish') {
    return <PublishIcon />;
  } else if (name === 'move') {
    return <MoveIcon />;
  }
  return <AddIcon />;
};

class ActionButton extends Component {
  constructor(props) {
    super(props);
    this.state = { anchorEl: null };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClick = event => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = event => {
    event.stopPropagation();
    this.setState({ anchorEl: null });
  };

  handleOptionOnClick = (event, onClick, shouldOpenOnClick) => {
    const { isRowOpen, toggleOpenRow } = this.props;
    event.stopPropagation();
    if (!isRowOpen && shouldOpenOnClick) {
      toggleOpenRow();
    }
    onClick();
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { actions, className } = this.props;
    return (
      <div className={className}>
        <ButtonIconSC
          noHoverBg
          aria-owns={anchorEl ? 'simple-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <MoreIcon />
        </ButtonIconSC>
        <MenuSC
          id="cipcode-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={event => this.handleClose(event)}
        >
          {actions.map(option => (
            <MenuItemSC
              onClick={event =>
                this.handleOptionOnClick(
                  event,
                  option.onClick,
                  option.shouldOpenOnClick
                )
              }
              key={uuidv4()}
            >
              <OptionIcon>{getIcon(option.icon)}</OptionIcon>
              <Body1SC>{option.label}</Body1SC>
            </MenuItemSC>
          ))}
        </MenuSC>
      </div>
    );
  }
}

ActionButton.propTypes = {
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.bool
  ]),
  className: PropTypes.string,
  isRowOpen: PropTypes.bool.isRequired,
  toggleOpenRow: PropTypes.func
};
ActionButton.defaultProps = {
  actions: false,
  className: '',
  toggleOpenRow: undefined
};

export default ActionButton;
export { MenuItemSC };
