import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SubtopicForm from '../SubtopicForm';
import { RowSummary } from '../../../SharedSC/DashboardSC';

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const EditSubtopic = ({
  existingSubtopic,
  cip,
  discipline,
  topic,
  handleChange,
  onPostUpdateSubtopic,
  showValidationErrors,
  toggleEditView
}) => {
  const cipCode = cip.code;
  const disciplineCode = discipline.code;
  const topicCode = `${topic.level_one_code}${topic.level_two_code}`;
  const existingSubtopicCode = `${existingSubtopic.level_one_code}${
    existingSubtopic.level_two_code
  }`;

  return (
    <Layout>
      <RowSummary>
        <SubtopicForm
          code={`${cipCode}.${disciplineCode}.${topicCode}.${existingSubtopicCode}`}
          subtopic={existingSubtopic}
          buttonText="Update"
          handleChange={event => handleChange(event)}
          onSubmit={onPostUpdateSubtopic}
          showValidationErrors={showValidationErrors}
          closeForm={toggleEditView}
        />
      </RowSummary>
    </Layout>
  );
};

EditSubtopic.propTypes = {
  existingSubtopic: PropTypes.object,
  cip: PropTypes.object,
  discipline: PropTypes.object,
  topic: PropTypes.object,
  showValidationErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  onPostUpdateSubtopic: PropTypes.func,
  toggleEditView: PropTypes.func
};
EditSubtopic.defaultProps = {
  existingSubtopic: {},
  cip: {},
  discipline: {},
  topic: {},
  showValidationErrors: false,
  handleChange: undefined,
  onPostUpdateSubtopic: undefined,
  toggleEditView: undefined
};

export default EditSubtopic;
