import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import scrollToComponent from 'react-scroll-to-component';
import NewDegreeLevel from './NewDegreeLevel';
import { doPostDegreeLevel } from '../../../../../redux/actions/degreeLevelActions';
import { degreeLevelsSelector } from '../../../../../redux/selectors/degreeLevelSelectors';
import {
  validateStringProperty,
  allFieldsAreValid
} from '../../../../../helpers/validateGeneric';

class NewDegreeLevelContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      degreeLevel: '',
      showValidationErrors: false
    };
    this.setNewDegreeLevel = this.setNewDegreeLevel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCreateSubmission = this.handleCreateSubmission.bind(this);
  }

  componentDidMount() {
    const { degreeLevel } = this.props;
    this._isMounted = true;
    if (!this.state.degreeLevel) {
      this.setNewDegreeLevel(degreeLevel);
    }

    scrollToComponent(this.Compref, {
      offset: -100,
      align: 'top',
      duration: 500,
      ease: 'inCirc'
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  setNewDegreeLevel = degreeLevel => {
    const { isNew, ...newDegreeLevel } = degreeLevel;
    if (this._isMounted) {
      this.setState(() => ({
        ...newDegreeLevel
      }));
    }
  };

  setValidationErrors = newDegreeLevel => {
    const { allDegreeLevels } = this.props;
    const degreeLevelError = validateStringProperty(
      newDegreeLevel.degreeLevel,
      allDegreeLevels,
      'degreeLevel'
    );
    const newValidationErrors = {
      degreeLevel: degreeLevelError
    };

    return newValidationErrors;
  };

  handleCreateSubmission = () => {
    const { showValidationErrors, ...newDegreeLevel } = this.state;

    const newValidationErrors = this.setValidationErrors(newDegreeLevel);

    if (allFieldsAreValid(newValidationErrors)) {
      this.props.onPostCreate(newDegreeLevel);
      this.setState({ showValidationErrors: false });
      this.props.clearNewForm();
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const { degreeLevel, showValidationErrors } = this.state;
    const { allDegreeLevels, clearNewForm } = this.props;

    const newDegreeLevel = {
      degreeLevel
    };

    return (
      <NewDegreeLevel
        className="compref"
        refProp={section => {
          this.Compref = section;
        }}
        newDegreeLevel={newDegreeLevel}
        handleChange={this.handleChange}
        onPostCreate={this.handleCreateSubmission}
        clearNewForm={clearNewForm}
        allDegreeLevels={allDegreeLevels}
        showValidationErrors={showValidationErrors}
      />
    );
  }
}

NewDegreeLevelContainer.propTypes = {
  degreeLevel: PropTypes.object,
  allDegreeLevels: PropTypes.arrayOf(PropTypes.object),
  onPostCreate: PropTypes.func,
  clearNewForm: PropTypes.func
};
NewDegreeLevelContainer.defaultProps = {
  degreeLevel: {},
  allDegreeLevels: [],
  onPostCreate: undefined,
  clearNewForm: undefined
};

const mapStateToProps = (state, props) => ({
  allDegreeLevels: degreeLevelsSelector(state, props)
});

const mapDispatchToProps = dispatch => ({
  onPostCreate: newAgency => dispatch(doPostDegreeLevel(newAgency))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewDegreeLevelContainer);
export { NewDegreeLevelContainer };
