import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Discipline from './Discipline';
import { doGetTopics } from '../../../../../redux/actions/topicActions';
import { doSetMoveTopicToEmptyDiscipline } from '../../../../../redux/actions/moveCodeActions';
import { makeDisciplineTopicsSelector } from '../../../../../redux/selectors/topicSelectors';
import { types } from '../../../../../redux/reducers/moveCodeReducer';

class DisciplineContainer extends Component {
  constructor(props) {
    super(props);

    this.getDisciplineTopics = this.getDisciplineTopics.bind(this);
  }

  getDisciplineTopics = disciplineUuid => {
    const { topics } = this.props;
    if (!topics || topics.length < 1) {
      this.props.onFetchTopics(disciplineUuid);
    }
  };

  render() {
    const { discipline, cip, topics, codeType, newDisciplineUuid } = this.props;
    const selectableTopics =
      codeType === types.topic
        ? topics.filter(code => code.level_two_code === '00')
        : topics;
    const isLeaf = Number(discipline.notopics) < 1 && topics.length < 1;
    const isCodeSelected =
      newDisciplineUuid === discipline.uuid && codeType === types.topic;
    return (
      <Discipline
        discipline={discipline}
        cip={cip}
        topics={selectableTopics}
        getDisciplineTopics={this.getDisciplineTopics}
        isCodeSelected={isCodeSelected}
        onCodeSelect={
          isLeaf && codeType === types.topic
            ? this.props.onSetMoveTopicToEmptyDiscipline
            : undefined
        }
        codeType={codeType}
      />
    );
  }
}

DisciplineContainer.propTypes = {
  discipline: PropTypes.object,
  topics: PropTypes.arrayOf(PropTypes.object),
  cip: PropTypes.object,
  newDisciplineUuid: PropTypes.string,
  codeType: PropTypes.oneOf([types.topic, types.subtopic, types.micro, '']),
  onFetchTopics: PropTypes.func,
  onSetMoveTopicToEmptyDiscipline: PropTypes.func
};

DisciplineContainer.defaultProps = {
  discipline: {},
  topics: [],
  cip: {},
  newDisciplineUuid: '',
  codeType: '',
  onFetchTopics: undefined,
  onSetMoveTopicToEmptyDiscipline: undefined
};

const makeMapStateToProps = () => {
  const getDisciplineTopics = makeDisciplineTopicsSelector();
  const mapStateToProps = (state, props) => ({
    topics: getDisciplineTopics(state, props),
    codeType: state.moveCodeState.codeType,
    newDisciplineUuid: state.moveCodeState.emptyDisciplineUuid
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  onFetchTopics: disciplineUuid => dispatch(doGetTopics(disciplineUuid)),
  onSetMoveTopicToEmptyDiscipline: newDisciplineUuid =>
    dispatch(doSetMoveTopicToEmptyDiscipline(newDisciplineUuid))
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(DisciplineContainer);
export { DisciplineContainer, mapDispatchToProps };
