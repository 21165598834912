import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PublishModal from '../../../DialogModal/PublishModal/PublishModal';
import DeleteModal from '../../../DialogModal/DeleteModal/DeleteModal';
import AccordionRowContainer from '../../../AccordionRow/AccordionRowContainer';
import EditStateProvinceContainer from './EditStateProvince/EditStateProvinceContainer';

const StateProvinceSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const StateProvince = ({
  stateProvince,
  parentCountry,
  view,
  className,
  isPublishModalOpen,
  isDeleteModalOpen,
  handlePublishModalOpen,
  handlePublishModalClose,
  handleDeleteClick,
  handleDeleteModalClose,
  handleDelete,
  onPublish,
  toggleEditView
}) => {
  const stateProvinceActions = [
    {
      label: 'Edit',
      onClick: toggleEditView,
      shouldOpenOnClick: false,
      icon: 'edit'
    },
    {
      label: 'Publish',
      onClick: handlePublishModalOpen,
      shouldOpenOnClick: false,
      icon: 'publish'
    },
    {
      label: 'Delete',
      onClick: handleDeleteClick,
      shouldOpenOnClick: false,
      icon: 'delete'
    }
  ];

  return (
    <StateProvinceSC className={className}>
      <PublishModal
        isModalOpen={isPublishModalOpen}
        handleModalClose={handlePublishModalClose}
        handlePublish={onPublish}
        entityLabel="State/Province"
        publishConfirmMessage="This will make the State/Province available to institutions and lock the State/Province from being deleted."
      />
      <DeleteModal
        modalOpen={isDeleteModalOpen}
        handleModalClose={() => handleDeleteModalClose()}
        handleDelete={() => handleDelete()}
        type="State/Province"
      />
      {view === 'EDIT' ? (
        <EditStateProvinceContainer
          country={parentCountry}
          stateProvince={stateProvince}
          key={stateProvince.uuid}
          toggleEditView={toggleEditView}
        />
      ) : (
        <AccordionRowContainer
          columnTwo={`${stateProvince.code} - ${stateProvince.name}`}
          columnGutters="1.5rem"
          actions={stateProvinceActions}
          expandWidth="42px"
          height="38px"
          rowOffset="60px"
          isPublished={stateProvince.published}
          leaf
        />
      )}
    </StateProvinceSC>
  );
};

StateProvince.propTypes = {
  stateProvince: PropTypes.object.isRequired,
  parentCountry: PropTypes.object.isRequired,
  view: PropTypes.oneOf(['EDIT', '']),
  className: PropTypes.string,
  isPublishModalOpen: PropTypes.bool,
  isDeleteModalOpen: PropTypes.bool,
  handleDeleteClick: PropTypes.func,
  handleDeleteModalClose: PropTypes.func,
  handleDelete: PropTypes.func,
  handlePublishModalOpen: PropTypes.func,
  handlePublishModalClose: PropTypes.func,
  onPublish: PropTypes.func,
  toggleEditView: PropTypes.func
};

StateProvince.defaultProps = {
  view: '',
  className: '',
  isPublishModalOpen: PropTypes.bool,
  isDeleteModalOpen: false,
  handleDeleteClick: undefined,
  handleDeleteModalClose: undefined,
  handleDelete: undefined,
  handlePublishModalOpen: undefined,
  handlePublishModalClose: undefined,
  onPublish: PropTypes.func,
  toggleEditView: undefined
};

export default StateProvince;
