import { Model, attr, fk } from 'redux-orm';
import {
  GET_SUBTOPICS_SUCCESS,
  POST_SUBTOPIC_SUCCESS,
  PUT_SUBTOPIC_SUCCESS,
  PUT_TOPIC_SUCCESS,
  DELETE_TOPIC_SUCCESS,
  DELETE_SUBTOPIC_SUCCESS,
  DELETE_DISCIPLINE_SUCCESS,
  DELETE_CIP_CODE_SUCCESS,
  PATCH_MOVE_SUBTOPIC_SUCCESS
} from '../actions/actionTypes';

class Subtopic extends Model {
  static reducer(action, Subtopic, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_SUBTOPICS_SUCCESS: {
        const subtopics = [...payload.subtopics];
        return subtopics.map(subtopic => {
          const subtopicUpsert = Subtopic.upsert(subtopic);
          return subtopicUpsert;
        });
      }
      case POST_SUBTOPIC_SUCCESS: {
        const newSubtopic = { ...payload.createdSubtopic };
        return Subtopic.upsert(newSubtopic);
      }
      case PUT_SUBTOPIC_SUCCESS: {
        const updatedSubtopic = { ...payload.updatedSubtopic };
        return Subtopic.upsert(updatedSubtopic);
      }
      case PUT_TOPIC_SUCCESS: {
        const updatedSubtopics = [...payload.updatedSubtopics];
        return updatedSubtopics.map(subtopic => {
          const subtopicUpsert = Subtopic.upsert(subtopic);
          return subtopicUpsert;
        });
      }
      case PATCH_MOVE_SUBTOPIC_SUCCESS: {
        const { movedCode, updatedCodes } = payload.updatedSubtopics;
        Subtopic.upsert(movedCode);
        return updatedCodes && updatedCodes.length > 0
          ? updatedCodes.map(subtopic => {
              const subtopicUpsert = Subtopic.upsert(subtopic);
              return subtopicUpsert;
            })
          : false;
      }
      case DELETE_TOPIC_SUCCESS: {
        const { deletedTopic } = payload;
        return Subtopic.all()
          .filter(subtopic => {
            if (
              !subtopic.topicUuid ||
              subtopic.topicUuid === deletedTopic.uuid
            ) {
              return subtopic;
            }
            return false;
          })
          .delete();
      }
      case DELETE_SUBTOPIC_SUCCESS: {
        const { deletedSubtopic, updatedSubtopics } = payload;
        Subtopic.withId(deletedSubtopic.uuid).delete();

        return updatedSubtopics.map(subtopic => {
          const subtopicUpsert = Subtopic.upsert(subtopic);
          return subtopicUpsert;
        });
      }
      case DELETE_DISCIPLINE_SUCCESS: {
        const { deletedDiscipline } = payload;
        return (
          deletedDiscipline.topics &&
          deletedDiscipline.topics.length > 0 &&
          deletedDiscipline.topics.map(topic =>
            Subtopic.all()
              .filter(subtopic => {
                if (!subtopic.topicUuid || subtopic.topicUuid === topic.uuid) {
                  return subtopic;
                }
                return false;
              })
              .delete()
          )
        );
      }
      case DELETE_CIP_CODE_SUCCESS: {
        const { deletedCipcode } = payload;
        return (
          deletedCipcode.disciplines &&
          deletedCipcode.disciplines.length > 0 &&
          deletedCipcode.disciplines.map(discipline => {
            if (discipline.topics && discipline.topics.length > 0) {
              discipline.topics.map(topic =>
                Subtopic.all()
                  .filter(subtopic => {
                    if (
                      !subtopic.topicUuid ||
                      subtopic.topicUuid === topic.uuid
                    ) {
                      return subtopic;
                    }
                    return false;
                  })
                  .delete()
              );
            }
            return false;
          })
        );
      }
      default:
        return session;
    }
  }
}

Subtopic.fields = {
  uuid: attr(),
  title: attr(),
  code: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  topicUuid: fk('Topic', 'subtopics')
};

Subtopic.modelName = 'Subtopic';
Subtopic.options = {
  idAttribute: 'uuid'
};

export default Subtopic;
