import { call, put } from 'redux-saga/effects';
import { getUsers } from '../../api/userApi';
import { doGetUsersSuccess, doGetUsersError } from '../actions/userActions';

function* handleFetchUsers() {
  try {
    const result = yield call(getUsers);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doGetUsersSuccess(payload.users));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doGetUsersError(error));
  }
}

export { handleFetchUsers };
