import { Model, attr, fk } from 'redux-orm';
import {
  GET_PROGRAM_CODES_SUCCESS,
  GET_PROGRAM_DISCIPLINE_CODES_SUCCESS,
  POST_PROGRAM_CODE_SUCCESS,
  PUT_PROGRAM_CODE_SUCCESS,
  DELETE_PROGRAM_CODE_SUCCESS,
  // DELETE_PROGRAM_CIPCODE_SUCCESS,
  PATCH_PUBLISH_PROGRAM_CODE_SUCCESS,
  DELETE_PROGRAM_DISCIPLINE_SUCCESS,
  DELETE_PROGRAM_CIPCODE_SUCCESS
} from '../actions/actionTypes';

class ProgramCode extends Model {
  static reducer(action, ProgramCode, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_PROGRAM_CODES_SUCCESS: {
        const programCodes = [...payload.programCodes];
        return programCodes.map(programCode => {
          const relatedPrograms = programCode.relatedPrograms.map(program => ({
            uuid: program.uuid,
            title: program.title,
            code: program.code,
            description: program.description,
            program_discipline: program.program_discipline,
            program_cipcode: program.program_discipline.program_cipcode
          }));
          const programCodeUpsert = ProgramCode.upsert({
            ...programCode,
            relatedPrograms
          });
          return programCodeUpsert;
        });
      }
      case GET_PROGRAM_DISCIPLINE_CODES_SUCCESS: {
        const programCodes = [...payload.programCodes];
        return programCodes.map(programCode => {
          const relatedPrograms = programCode.relatedPrograms.map(program => ({
            uuid: program.uuid,
            title: program.title,
            code: program.code,
            description: program.description,
            program_discipline: program.program_discipline,
            program_cipcode: program.program_discipline.program_cipcode
          }));
          const programCodeUpsert = ProgramCode.upsert({
            ...programCode,
            relatedPrograms
          });
          return programCodeUpsert;
        });
      }
      case POST_PROGRAM_CODE_SUCCESS: {
        const newProgramCode = { ...payload.createdProgramCode };

        const relatedPrograms = newProgramCode.relatedPrograms.map(program => ({
          uuid: program.uuid,
          title: program.title,
          code: program.code,
          description: program.description,
          program_discipline: program.program_discipline,
          program_cipcode: program.program_discipline.program_cipcode
        }));

        return ProgramCode.upsert({
          ...newProgramCode,
          relatedPrograms
        });
      }
      case PUT_PROGRAM_CODE_SUCCESS: {
        const updatedProgramCode = { ...payload.updatedProgramCode };
        const relatedPrograms = updatedProgramCode.relatedPrograms.map(
          program => ({
            uuid: program.uuid,
            title: program.title,
            code: program.code,
            description: program.description,
            program_discipline: program.program_discipline,
            program_cipcode: program.program_discipline.program_cipcode
          })
        );
        return ProgramCode.upsert({
          ...updatedProgramCode,
          relatedPrograms
        });
      }
      case PATCH_PUBLISH_PROGRAM_CODE_SUCCESS: {
        const { publishedProgramCode } = payload;
        return ProgramCode.withId(publishedProgramCode.uuid).update({
          published: true
        });
      }
      case DELETE_PROGRAM_CODE_SUCCESS: {
        const { deletedProgramCode } = payload;
        return ProgramCode.withId(deletedProgramCode.uuid).delete();
      }
      case DELETE_PROGRAM_DISCIPLINE_SUCCESS: {
        const { deletedProgramDiscipline } = payload;
        return (
          deletedProgramDiscipline.programCodes &&
          deletedProgramDiscipline.programCodes.length > 0 &&
          ProgramCode.all()
            .filter(program => {
              if (
                !program.disciplineUuid ||
                program.disciplineUuid === deletedProgramDiscipline.uuid
              ) {
                return program;
              }
              return false;
            })
            .delete()
        );
      }
      case DELETE_PROGRAM_CIPCODE_SUCCESS: {
        const { deletedProgramCipcode } = payload;
        return (
          deletedProgramCipcode.program_disciplines &&
          deletedProgramCipcode.program_disciplines.length > 0 &&
          deletedProgramCipcode.program_disciplines.map(discipline => {
            if (
              discipline.program_codes &&
              discipline.program_codes.length > 0
            ) {
              const deletedPrograms = ProgramCode.all()
                .filter(program => {
                  if (
                    !program.disciplineUuid ||
                    program.disciplineUuid === discipline.uuid
                  ) {
                    return program;
                  }
                  return false;
                })
                .delete();

              return deletedPrograms;
            }
            return false;
          })
        );
      }
      default:
        return session;
    }
  }
}

ProgramCode.fields = {
  uuid: attr(),
  title: attr(),
  code: attr(),
  description: attr(),
  published: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  disciplineUuid: fk('ProgramDiscipline', 'programCodes')
};

ProgramCode.modelName = 'ProgramCode';
ProgramCode.options = {
  idAttribute: 'uuid'
};

export default ProgramCode;
