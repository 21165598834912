import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InstitutionTypeForm from '../../InstitutionTypeForm';
import { validateEntityName } from '../../../../../helpers/validateGeneric';
import { RowSummary } from '../../../SharedSC/DashboardSC';

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const EditInstitutionType = ({
  existingInstitutionType,
  allInstitutionTypes,
  handleChange,
  onPostUpdate,
  showValidationErrors,
  toggleEditView
}) => (
  <Layout>
    <RowSummary>
      <InstitutionTypeForm
        type={existingInstitutionType}
        entityLabel="Type"
        buttonText="Update"
        handleChange={event => handleChange(event)}
        onSubmit={onPostUpdate}
        showValidationErrors={showValidationErrors}
        validateName={() =>
          validateEntityName(existingInstitutionType.name, allInstitutionTypes)
        }
        onFormClose={() => toggleEditView()}
      />
    </RowSummary>
  </Layout>
);

EditInstitutionType.propTypes = {
  existingInstitutionType: PropTypes.object,
  showValidationErrors: PropTypes.bool,
  allInstitutionTypes: PropTypes.arrayOf(PropTypes.object),
  handleChange: PropTypes.func,
  onPostUpdate: PropTypes.func,
  toggleEditView: PropTypes.func
};
EditInstitutionType.defaultProps = {
  existingInstitutionType: {},
  showValidationErrors: false,
  allInstitutionTypes: [],
  handleChange: undefined,
  onPostUpdate: undefined,
  toggleEditView: undefined
};

export default EditInstitutionType;
