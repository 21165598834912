import fetch from "../helpers/fetch";

const getLocations = () =>
  fetch('/api/countries/all', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      }
      throw new Error(`(status code: ${res.status})`);
    })
    .catch(error => {
      throw error;
    });

const postCountry = newCountry =>
  fetch(`/api/country/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(newCountry)
  })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      }
      throw new Error(`(status code: ${res.status})`);
    })
    .catch(error => {
      throw error;
    });

const postStateProvince = newStateProvince =>
  fetch(`/api/state-province/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(newStateProvince)
  })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      }
      throw new Error(`(status code: ${res.status})`);
    })
    .catch(error => {
      throw error;
    });

const putCountry = ({ uuid, ...countryUpdates }) =>
  fetch(`/api/country/${uuid}/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(countryUpdates)
  })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      }
      throw new Error(`(status code: ${res.status})`);
    })
    .catch(error => {
      throw error;
    });

const putStateProvince = ({ uuid, ...stateProvinceUpdates }) =>
  fetch(`/api/state-province/${uuid}/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(stateProvinceUpdates)
  })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      }
      throw new Error(`(status code: ${res.status})`);
    })
    .catch(error => {
      throw error;
    });

const deleteCountry = uuid =>
  fetch(`/api/country/${uuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      }
      throw new Error(`(status code: ${res.status})`);
    })
    .catch(error => {
      throw error;
    });

const deleteStateProvince = uuid =>
  fetch(`/api/state-province/${uuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      }
      throw new Error(`(status code: ${res.status})`);
    })
    .catch(error => {
      throw error;
    });

export {
  getLocations,
  postCountry,
  postStateProvince,
  putCountry,
  putStateProvince,
  deleteCountry,
  deleteStateProvince
};
