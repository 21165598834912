import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DeleteIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { ButtonIcon, CharacterCount } from '@xcomp/xcomp-design-library';
import {
  Column,
  ElevatedForm,
  SubmitButton,
  CancelButton,
  TextFieldWithError
} from '../../../Forms/FormComponents';
import {
  convertNullToString,
  sortByProperty
} from '../../../../helpers/utilities';
import RelatedProgramSearchContainer from './RelatedProgramSearch/RelatedProgramSearchContainer';
import { FormTop, FormBottom } from '../../SharedSC/FormSC';

const Field = styled.div`
  width: 100%;
  margin-bottom: 1.5rem;
`;

const RelatedProgramsContainer = styled.div`
  width: 100%;
`;

const ButtonsContainer = styled.div`
  margin-top: 1rem;
`;

const ButtonIconSC = styled(ButtonIcon)`
  && {
    margin-left: 0.375rem;
    font-size: 1.25rem;
    line-height: 0.7;
    &:hover,
    &:focus,
    &:active {
      background: 0;
    }

    &.delete {
      svg {
        font-size: 1.125rem;
      }
    }
  }
`;

const StyledCharacterCount = styled(CharacterCount)`
  && {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 1rem;
  }
`;

const ParentCipDisciplineDigits = styled.div`
  font-size: 16px;
  padding-right: 2px;
  width: 100%;
  text-align: right;
`;

const RelatedProgramsWrapper = styled.ul`
  && {
    list-style: none;
    padding: 0 0.5rem;
    margin-bottom: 1.5rem;
  }
`;

const RelatedProgram = styled.li`
  && {
    position: relative;
    width: 100%;
    margin-bottom: 0.5rem;

    &.delete_program {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
    }
  }
`;

const ProgramForm = ({
  cipcode,
  discipline,
  program,
  buttonText,
  handleChange,
  handleRelatedProgramAdd,
  handleRelatedProgramRemove,
  onSubmit,
  showValidationErrors,
  validateCode,
  validateTitle,
  validateDescription,
  closeForm
}) => {
  const sortedRelatedPrograms =
    program.relatedPrograms && program.relatedPrograms.length > 0
      ? program.relatedPrograms.sort((a, b) => sortByProperty(a, b, 'label'))
      : false;
  return (
    <ElevatedForm>
      <FormTop>
        <Column size="5%">
          <Field>
            <ParentCipDisciplineDigits>
              {cipcode.code}.{discipline.code}
            </ParentCipDisciplineDigits>
          </Field>
        </Column>
        <Column size="22%">
          <Field>
            <TextFieldWithError
              id="programCode"
              name="code"
              value={program.code}
              onChange={event => handleChange(event)}
              margin="normal"
              InputLabelProps={{ shrink: true }}
              label="Program Code*"
              placeholder=""
              variant="outlined"
              validate={validateCode}
              showValidationErrors={showValidationErrors}
              paddingRight="1rem"
            />
          </Field>
        </Column>
        <Column size="73%">
          <Field>
            <TextFieldWithError
              id="programTitle"
              name="title"
              value={program.title}
              onChange={event => handleChange(event)}
              margin="normal"
              InputLabelProps={{ shrink: true }}
              label="Code Title*"
              placeholder=""
              variant="outlined"
              validate={validateTitle}
              showValidationErrors={showValidationErrors}
            />
          </Field>
        </Column>
      </FormTop>
      <FormBottom>
        <TextFieldWithError
          id="programDescription"
          name="description"
          value={convertNullToString(program.description)}
          onChange={event => handleChange(event)}
          multiline
          rows="2"
          rowsMax="10"
          margin="normal"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          label="Description"
          placeholder="Enter a description (3000 characters max)"
          validate={validateDescription}
          showValidationErrors={showValidationErrors}
        />
        <StyledCharacterCount
          count={program.description ? program.description.length : 0}
          label="Count"
          maxCount={3000}
        />
        <RelatedProgramsContainer>
          {sortedRelatedPrograms && (
            <RelatedProgramsWrapper>
              <h3>Related Programs</h3>
              {program.relatedPrograms.map(program => (
                <RelatedProgram key={program.uuid}>
                  {program.label}
                  <ButtonIconSC
                    className="delete_program"
                    noHoverBg
                    onClick={() => handleRelatedProgramRemove(program.uuid)}
                  >
                    <DeleteIcon />
                  </ButtonIconSC>
                </RelatedProgram>
              ))}
            </RelatedProgramsWrapper>
          )}
          <RelatedProgramSearchContainer
            label="Add Related Program"
            cipcode={cipcode}
            discipline={discipline}
            thisProgram={program}
            placeholder="Search"
            handleRelatedProgramAdd={handleRelatedProgramAdd}
          />
        </RelatedProgramsContainer>
        <ButtonsContainer>
          <CancelButton onClick={closeForm} noHoverBg>
            Cancel
          </CancelButton>
          <SubmitButton onClick={onSubmit}>{buttonText}</SubmitButton>
        </ButtonsContainer>
      </FormBottom>
    </ElevatedForm>
  );
};

ProgramForm.propTypes = {
  cipcode: PropTypes.object.isRequired,
  discipline: PropTypes.object.isRequired,
  program: PropTypes.object.isRequired,
  buttonText: PropTypes.string,
  showValidationErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  handleRelatedProgramAdd: PropTypes.func,
  handleRelatedProgramRemove: PropTypes.func,
  onSubmit: PropTypes.func,
  validateCode: PropTypes.func.isRequired,
  validateTitle: PropTypes.func.isRequired,
  validateDescription: PropTypes.func.isRequired,
  closeForm: PropTypes.func
};
ProgramForm.defaultProps = {
  buttonText: 'Create',
  showValidationErrors: false,
  handleChange: undefined,
  handleRelatedProgramAdd: undefined,
  handleRelatedProgramRemove: undefined,
  onSubmit: undefined,
  closeForm: undefined
};

export default ProgramForm;
