/* eslint no-unused-vars: off */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EditCountry from './EditCountry';
import { countriesSelector } from '../../../../../redux/selectors/locationsSelectors';
import {
  doPutCountry,
  doDeleteCountry
} from '../../../../../redux/actions/locationActions';
import {
  validateLocationName,
  validateCountryCode
} from '../../../../../helpers/validateLocations';
import { allFieldsAreValid } from '../../../../../helpers/validateGeneric';

class EditCountryContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      uuid: '',
      code: '',
      name: '',
      showValidationErrors: false
    };

    this.setExistingCountry = this.setExistingCountry.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCountryUpdate = this.handleCountryUpdate.bind(this);
  }

  componentDidMount() {
    const { code } = this.state;
    const { country } = this.props;
    this._isMounted = true;
    if (!code) {
      this.setExistingCountry(country);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  setExistingCountry = country => {
    const { ...existingCountry } = country;
    if (this._isMounted) {
      this.setState(state => ({
        ...existingCountry
      }));
    }
  };

  setValidationErrors = existingCountry => {
    const { allCountries } = this.props;
    const { uuid } = this.state;
    const siblingCountries = allCountries.filter(
      country => country.uuid !== uuid
    );
    const nameError = validateLocationName(
      existingCountry.name,
      siblingCountries
    );
    const codeError = validateCountryCode(
      existingCountry.code,
      siblingCountries
    );
    const newValidationErrors = {
      name: nameError,
      code: codeError
    };

    return newValidationErrors;
  };

  handleCountryUpdate = () => {
    const { showValidationErrors, ...existingCountry } = this.state;
    const newValidationErrors = this.setValidationErrors(existingCountry);

    if (allFieldsAreValid(newValidationErrors)) {
      const payload = {
        uuid: existingCountry.uuid,
        name: existingCountry.name,
        code: existingCountry.code
      };
      this.props.onPostUpdateCountry(payload);
      this.setState({ showValidationErrors: false });
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const {
      code,
      name,
      uuid,
      showValidationErrors,
      isDeleteModalOpen
    } = this.state;
    const { country, allCountries, toggleEditView } = this.props;

    const thisCountry = {
      uuid,
      code,
      name
    };

    const siblingCountries = allCountries.filter(
      country => country.uuid !== thisCountry.uuid
    );

    return (
      <EditCountry
        existingCountry={thisCountry}
        country={country}
        allCountries={siblingCountries}
        handleChange={this.handleChange}
        onPostUpdateCountry={this.handleCountryUpdate}
        showValidationErrors={showValidationErrors}
        toggleEditView={toggleEditView}
      />
    );
  }
}

EditCountryContainer.propTypes = {
  country: PropTypes.object,
  allCountries: PropTypes.arrayOf(PropTypes.object),
  toggleEditView: PropTypes.func,

  onPostUpdateCountry: PropTypes.func
};
EditCountryContainer.defaultProps = {
  country: {},
  allCountries: [],
  toggleEditView: undefined,

  onPostUpdateCountry: undefined
};

const mapStateToProps = (state, props) => ({
  allCountries: countriesSelector(state, props)
});

const mapDispatchToProps = dispatch => ({
  onPostUpdateCountry: countryUpdates => dispatch(doPutCountry(countryUpdates))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCountryContainer);
export { EditCountryContainer };
