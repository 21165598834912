import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InstitutionTypeForm from '../../InstitutionTypeForm';
import { validateEntityName } from '../../../../../helpers/validateGeneric';
import { RowSummary } from '../../../SharedSC/DashboardSC';

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const NewInstitutionType = ({
  newInstitutionType,
  allInstitutionTypes,
  showValidationErrors,
  handleChange,
  onPostCreate,
  clearNewForm
}) => (
  <Layout>
    <RowSummary>
      <InstitutionTypeForm
        type={newInstitutionType}
        entityLabel="Type"
        buttonText="Create"
        showValidationErrors={showValidationErrors}
        validateName={() =>
          validateEntityName(newInstitutionType.name, allInstitutionTypes)
        }
        handleChange={event => handleChange(event)}
        onSubmit={onPostCreate}
        onFormClose={clearNewForm}
      />
    </RowSummary>
  </Layout>
);

NewInstitutionType.propTypes = {
  newInstitutionType: PropTypes.object,
  allInstitutionTypes: PropTypes.arrayOf(PropTypes.object),
  showValidationErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  onPostCreate: PropTypes.func,
  clearNewForm: PropTypes.func
};
NewInstitutionType.defaultProps = {
  newInstitutionType: {},
  allInstitutionTypes: [],
  showValidationErrors: false,
  handleChange: undefined,
  onPostCreate: undefined,
  clearNewForm: undefined
};

export default NewInstitutionType;
