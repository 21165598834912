import {
  POST_DISCIPLINE,
  POST_DISCIPLINE_SUCCESS,
  POST_DISCIPLINE_ERROR,
  PUT_DISCIPLINE,
  PUT_DISCIPLINE_SUCCESS,
  PUT_DISCIPLINE_ERROR,
  DELETE_DISCIPLINE,
  DELETE_DISCIPLINE_SUCCESS,
  DELETE_DISCIPLINE_ERROR
} from './actionTypes';

const doPostDiscipline = newDiscipline => ({
  type: POST_DISCIPLINE,
  payload: {
    newDiscipline
  }
});

const doPostDisciplineSuccess = data => ({
  type: POST_DISCIPLINE_SUCCESS,
  payload: {
    createdDiscipline: data.createdDiscipline
  }
});

const doPostDisciplineError = error => ({
  type: POST_DISCIPLINE_ERROR,
  payload: {
    error
  }
});

const doPutDiscipline = disciplineUpdates => ({
  type: PUT_DISCIPLINE,
  payload: {
    disciplineUpdates
  }
});

const doPutDisciplineSuccess = data => ({
  type: PUT_DISCIPLINE_SUCCESS,
  payload: {
    updatedDiscipline: data.updatedDiscipline
  }
});

const doPutDisciplineError = error => ({
  type: PUT_DISCIPLINE_ERROR,
  payload: {
    error
  }
});

const doDeleteDiscipline = disciplineUuid => ({
  type: DELETE_DISCIPLINE,
  payload: {
    disciplineUuid
  }
});

const doDeleteDisciplineSuccess = data => ({
  type: DELETE_DISCIPLINE_SUCCESS,
  payload: {
    deletedDiscipline: data.deletedDiscipline
  }
});

const doDeleteDisciplineError = error => ({
  type: DELETE_DISCIPLINE_ERROR,
  payload: {
    error
  }
});

export {
  doPostDiscipline,
  doPostDisciplineError,
  doPostDisciplineSuccess,
  doPutDiscipline,
  doPutDisciplineSuccess,
  doPutDisciplineError,
  doDeleteDiscipline,
  doDeleteDisciplineSuccess,
  doDeleteDisciplineError
};
