import fetch from "../helpers/fetch";

const getInstitutionalSystems = () =>
  fetch('/api/institutional-systems', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postInstitutionalSystem = institutionalSystem =>
  fetch('/api/institutional-system/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(institutionalSystem),
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putInstitutionalSystem = ({ uuid, ...updates }) =>
  fetch(`/api/institutional-system/${uuid}/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(updates)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteInstitutionalSystem = uuid =>
  fetch(`/api/institutional-system/${uuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => error);

export {
  getInstitutionalSystems,
  postInstitutionalSystem,
  putInstitutionalSystem,
  deleteInstitutionalSystem
};
