import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import AccordionRowContainer from './AccordionRowContainer';

const AccordionRowContainerSC = styled(AccordionRowContainer)`
  && {
    .level_one_code {
      span {
        color: ${props => props.theme.colors.primary};
      }
    }
  }
`;

const AccordionWithBoldCode = ({ isCodeBold, columnOneClassName, ...rest }) => {
  return (
    <AccordionRowContainerSC
      columnOneClassName={`${columnOneClassName} ${isCodeBold &&
        'level_one_code'}`}
      {...rest}
    />
  );
};

AccordionWithBoldCode.propTypes = {
  isCodeBold: PropTypes.bool,
  columnOneClassName: PropTypes.string
};

AccordionWithBoldCode.defaultProps = {
  isCodeBold: false,
  columnOneClassName: ''
};

export default AccordionWithBoldCode;
