import { call, put } from 'redux-saga/effects';
import {
  getCipcodes,
  postCipcode,
  putCipcode,
  deleteCipcode
} from '../../api/cipCodesApi';
import {
  doGetCipsSuccess,
  doGetCipsError,
  doPostCipcodeSuccess,
  doPostCipcodeError,
  doPutCipcodeSuccess,
  doPutCipcodeError,
  doDeleteCipcodeSuccess,
  doDeleteCipcodeError
} from '../actions/cipCodeActions';

function* handleFetchCips() {
  try {
    const result = yield call(getCipcodes);
    yield put(doGetCipsSuccess(result));
  } catch (error) {
    yield put(doGetCipsError(error));
  }
}

function* handlePostCreateCipcode(action) {
  const { newCipcode } = action.payload;
  try {
    const result = yield call(postCipcode, newCipcode);
    yield put(doPostCipcodeSuccess(result));
  } catch (error) {
    yield put(doPostCipcodeError(error));
  }
}

function* handlePostUpdateCipcode(action) {
  const { cipcodeUpdates } = action.payload;
  try {
    const result = yield call(putCipcode, cipcodeUpdates);
    yield put(doPutCipcodeSuccess(result));
  } catch (error) {
    yield put(doPutCipcodeError(error));
  }
}

function* handleDeleteCipcode(action) {
  const { cipcodeUuid } = action.payload;
  try {
    const result = yield call(deleteCipcode, cipcodeUuid);

    yield put(doDeleteCipcodeSuccess(result));
  } catch (error) {
    const errorMessage = yield error.text();
    yield put(doDeleteCipcodeError(errorMessage));
  }
}

export {
  handleFetchCips,
  handlePostCreateCipcode,
  handlePostUpdateCipcode,
  handleDeleteCipcode
};
