import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { makeStateSubtypeSystemsSelector } from '../../../../redux/selectors/institutionalSystemsSelectors';
import InstitutionSubtype from './InstitutionSubtype';

class InstitutionSubtypeContainer extends PureComponent {
  render() {
    const {
      institutionSubtype,
      institutionType,
      institutionalSystems,
      stateProvince,
      className
    } = this.props;

    return (
      <InstitutionSubtype
        institutionSubtype={institutionSubtype}
        parentInstitutionType={institutionType}
        institutionalSystems={institutionalSystems}
        className={className}
        stateProvince={stateProvince}
      />
    );
  }
}

InstitutionSubtypeContainer.propTypes = {
  institutionSubtype: PropTypes.object,
  institutionType: PropTypes.object,
  stateProvince: PropTypes.object,
  institutionalSystems: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string
};

InstitutionSubtypeContainer.defaultProps = {
  institutionSubtype: {},
  institutionType: {},
  stateProvince: {},
  institutionalSystems: [],
  className: ''
};

const makeMapStateToProps = () => {
  const getStateSubtypeSystems = makeStateSubtypeSystemsSelector(); 
  const mapStateToProps = (state, props) => ({
    institutionalSystems: getStateSubtypeSystems(state, props)
  });
  return mapStateToProps;
};

export default connect(
  makeMapStateToProps,
  undefined
)(InstitutionSubtypeContainer);
