import _ from 'lodash';
import { validateRequired } from './validateGeneric';

const validateLocationName = (locationName, allLocations) => {
  const requiredError = validateRequired(locationName);
  let locationNameIsUnique = true;
  if (requiredError) {
    return requiredError;
  }

  if (_.size(locationName) > 100) {
    return {
      isFormatError: true,
      message: 'Country name must be less than 100 characters'
    };
  }

  allLocations.forEach(location => {
    if (location.name.toLowerCase() === locationName.toLowerCase()) {
      locationNameIsUnique = false;
    }
  });

  if (!locationNameIsUnique) {
    return {
      isFormatError: false,
      message: `${locationName} has already been used`
    };
  }
  return false;
};

const validateCountryCode = (code, allCountries) => {
  let countryCodeIsUnique = true;

  if (!code || code === '' || code === 0) {
    // empty code
    return {
      isFormatError: false,
      message: 'Please enter a 3 character country code'
    };
  }

  if (_.size(code) !== 3) {
    // code not 2 digits
    return {
      isFormatError: true,
      message: 'Country codes must be exactly 3 characters'
    };
  }

  // code has non alpha characters
  const regex = /^[A-Za-z]{3}$/;
  const regexMatch = code.match(regex);
  const failsRegex = !regexMatch || regexMatch.length < 1;

  if (failsRegex) {
    return {
      isFormatError: true,
      message: 'Country codes can only contain letters'
    };
  }
  // code must be unique

  allCountries.forEach(country => {
    if (country.code.toLowerCase() === code.toLowerCase()) {
      countryCodeIsUnique = false;
    }
  });

  if (!countryCodeIsUnique) {
    return {
      isFormatError: false,
      message: `${code} has already been used`
    };
  }

  return false;
};

const validateStateProvinceCode = (code, allStateProvinces) => {
  let stateProvinceCodeIsUnique = true;

  if (!code || code === '' || code === 0) {
    // empty code
    return {
      isFormatError: false,
      message: 'Please enter a 2 character state/province code'
    };
  }

  if (_.size(code) !== 2) {
    // code not 2 digits
    return {
      isFormatError: true,
      message: 'State/Province codes must be exactly 2 characters'
    };
  }

  // code has non numeric characters
  const regex = /^[A-Za-z]{2}$/;
  const regexMatch = code.match(regex);
  const failsRegex = !regexMatch || regexMatch.length < 1;

  if (failsRegex) {
    return {
      isFormatError: true,
      message: 'State/Province codes can only contain letters'
    };
  }

  // code must be unique
  allStateProvinces.forEach(state => {
    if (state.code.toLowerCase() === code.toLowerCase()) {
      stateProvinceCodeIsUnique = false;
    }
  });

  if (!stateProvinceCodeIsUnique) {
    return {
      isFormatError: false,
      message: `${code} has already been used`
    };
  }

  return false;
};

export { validateLocationName, validateCountryCode, validateStateProvinceCode };
