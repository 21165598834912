/* eslint no-unused-vars: off */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import EditInstitutionalSystem from './EditInstitutionalSystem';
import {
  doPutInstitutionalSystem,
  doDeleteInstitutionalSystem
} from '../../../../../redux/actions/institutionalSystemActions';
import {
  makeGetInstitutionalSystemSelector,
  makeEditStateSubtypeSystemsSelector
} from '../../../../../redux/selectors/institutionalSystemsSelectors';
import { makeSystemInstitutionSubtypeSelector } from '../../../../../redux/selectors/institutionTypesSelectors';
import {
  makeGetSystemCountrySelector,
  makeGetSystemStateProvinceSelector
} from '../../../../../redux/selectors/locationsSelectors';
import {
  allFieldsAreValid,
  validateEntityName,
  validateRequired,
  validateUrl
} from '../../../../../helpers/validateGeneric';

class EditInstitutionalSystemContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      uuid: '',
      name: '',
      websiteAddress: '',
      selectedCountryUuid: '',
      selectedInstitutionTypeUuid: '',
      institutionSubtypeUuid: '',
      stateProvinceUuid: '',
      institutionalSystemAdmins: [],
      showValidationErrors: false
    };
  }

  componentDidMount() {
    const { name } = this.state;
    const { institutionalSystem } = this.props;
    this._isMounted = true;
    if (!name && institutionalSystem) {
      this.setExistingInstitutionalSystem(institutionalSystem);
    }
  }

  componentDidUpdate(prevProps) {
    const { institutionalSystem } = this.props;
    if (institutionalSystem && !prevProps.institutionalSystem) {
      this.setExistingInstitutionalSystem(institutionalSystem);
    } else if (
      institutionalSystem &&
      prevProps.institutionalSystem &&
      !_.isEqual(institutionalSystem, prevProps.institutionalSystem)
    ) {
      this.setExistingInstitutionalSystem(institutionalSystem);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  handleUserFieldChange = (values, fieldName) => {
    this.setState(() => ({
      [fieldName]: values
    }));
  };

  setExistingInstitutionalSystem = institutionalSystem => {
    const { types, locations } = this.props;
    if (this._isMounted) {
      this.setState(state => ({
        selectedCountryUuid: locations.country ? locations.country.uuid : '',
        selectedInstitutionTypeUuid: types.institutionType
          ? types.institutionType.uuid
          : '',
        ...institutionalSystem
      }));
    }
  };

  setValidationErrors = existingInstitutionalSystem => {
    const { allInstitutionalSystems } = this.props;
    const allSiblingSystems =
      allInstitutionalSystems && allInstitutionalSystems.length > 0
        ? allInstitutionalSystems.filter(
            system => system.uuid !== existingInstitutionalSystem.uuid
          )
        : [];
    const nameError = validateEntityName(
      existingInstitutionalSystem.name,
      allSiblingSystems
    );
    const websiteAddressError = validateUrl(
      existingInstitutionalSystem.websiteAddress,
      false
    );
    const subtypeError = validateRequired(
      existingInstitutionalSystem.institutionSubtypeUuid
    );
    const stateProvinceError = validateRequired(
      existingInstitutionalSystem.stateProvinceUuid
    );
    const newValidationErrors = {
      name: nameError,
      websiteAddress: websiteAddressError,
      institutionSubtypeUuid: subtypeError,
      stateProvinceUuid: stateProvinceError
    };

    return newValidationErrors;
  };

  handleInstitutionalSystemUpdate = () => {
    const {
      showValidationErrors,
      isDeleteModalOpen,
      ...existingInstitutionalSystem
    } = this.state;

    const newValidationErrors = this.setValidationErrors(
      existingInstitutionalSystem
    );

    if (allFieldsAreValid(newValidationErrors)) {
      this.props.onPutInstitutionalSystem(existingInstitutionalSystem);
      this.setState({ showValidationErrors: false });
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const {
      uuid,
      name,
      websiteAddress,
      selectedCountryUuid,
      selectedInstitutionTypeUuid,
      institutionSubtypeUuid,
      stateProvinceUuid,
      institutionalSystemAdmins,
      showValidationErrors
    } = this.state;
    const {
      institutionalSystem,
      locations,
      types,
      isEditSystemModalOpen,
      handleModalClose,
      allInstitutionalSystems,
      clearNewInstitutionalSystem
    } = this.props;

    const existingInstitutionalSystem = {
      uuid,
      name,
      websiteAddress,
      selectedCountryUuid,
      selectedInstitutionTypeUuid,
      institutionSubtypeUuid,
      stateProvinceUuid,
      institutionalSystemAdmins
    };

    return (
      <EditInstitutionalSystem
        existingInstitutionalSystem={existingInstitutionalSystem}
        isEditSystemModalOpen={isEditSystemModalOpen}
        locations={locations}
        types={types}
        handleModalClose={handleModalClose}
        handleChange={this.handleChange}
        handleUserFieldChange={this.handleUserFieldChange}
        onPutUpdate={this.handleInstitutionalSystemUpdate}
        clearNewInstitutionalSystem={clearNewInstitutionalSystem}
        allInstitutionalSystems={allInstitutionalSystems}
        showValidationErrors={showValidationErrors}
        handleInstitutionalSystemUpdate={this.handleInstitutionalSystemUpdate}
      />
    );
  }
}

EditInstitutionalSystemContainer.propTypes = {
  institutionalSystem: PropTypes.object,
  locations: PropTypes.object,
  types: PropTypes.object,
  allInstitutionalSystems: PropTypes.arrayOf(PropTypes.object),
  isEditSystemModalOpen: PropTypes.bool,
  handleModalClose: PropTypes.func,
  onPutInstitutionalSystem: PropTypes.func,
  clearNewInstitutionalSystem: PropTypes.func
};
EditInstitutionalSystemContainer.defaultProps = {
  institutionalSystem: {},
  locations: {
    country: {},
    stateProvince: {}
  },
  types: {
    institutionType: {},
    institutionSubtype: {}
  },
  allInstitutionalSystems: [],
  isEditSystemModalOpen: false,
  handleModalClose: undefined,
  onPutInstitutionalSystem: undefined,
  clearNewInstitutionalSystem: undefined
};

const makeMapStateToProps = () => {
  const getEditStateSubtypeSystemsSelector = makeEditStateSubtypeSystemsSelector();
  const getInstitutionalSystem = makeGetInstitutionalSystemSelector();
  const getSystemStateProvinceSelector = makeGetSystemStateProvinceSelector();
  const getSystemInstitutionSubtypeSelector = makeSystemInstitutionSubtypeSelector();

  const mapStateToProps = (state, props) => ({
    allInstitutionalSystems: getEditStateSubtypeSystemsSelector(state, props),
    institutionalSystem: getInstitutionalSystem(state, props),
    locations: getSystemStateProvinceSelector(state, props),
    types: getSystemInstitutionSubtypeSelector(state, props)
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  onPutInstitutionalSystem: updates =>
    dispatch(doPutInstitutionalSystem(updates))
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(EditInstitutionalSystemContainer);
export { EditInstitutionalSystemContainer };
