import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';
import { sortByProperty } from '../../helpers/utilities';

// const ormSelector = state => state.orm;

export const getDisciplineUuid = (state, props) => props.discipline.uuid;

// export const allProgramCodesSelector = createSelector(
//   ormSelector,
//   ormCreateSelector(orm, session =>
//     session.ProgramCode.all()
//       .toModelArray()
//       .map(program => ({
//         ...program.ref
//       }))
//       .sort((programA, programB) => sortByProperty(programA, programB, 'code'))
//   )
// );

export const allProgramCodesSelector = createSelector(
  state => state,
  redux => {
    const selectorORM = ormCreateSelector([orm], session =>
      session.ProgramCode.all()
        .toModelArray()
        .map(program => ({
          ...program.ref
        }))
        .sort((programA, programB) =>
          sortByProperty(programA, programB, 'code')
        )
    );
    return selectorORM(redux);
  }
);

// export const makeDisciplineProgramCodesSelector = () =>
//   createSelector(
//     ormSelector,
//     getDisciplineUuid,
//     ormCreateSelector(orm, (session, disciplineUuid) =>
//       session.ProgramCode.all()
//         .filter(program => program.disciplineUuid === disciplineUuid)
//         .toModelArray()
//         .map(program => Object.assign({}, program.ref))
//         .sort((programA, programB) =>
//           sortByProperty(programA, programB, 'code')
//         )
//     )
//   );

export const makeDisciplineProgramCodesSelector = () =>
  createSelector(
    state => state,
    getDisciplineUuid,
    (redux, disciplineUuid) => {
      const selectorORM = ormCreateSelector([orm], session =>
        session.ProgramCode.all()
          .filter(program => program.disciplineUuid === disciplineUuid)
          .toModelArray()
          .map(program => Object.assign({}, program.ref))
          .sort((programA, programB) =>
            sortByProperty(programA, programB, 'code')
          )
      );
      return selectorORM(redux);
    }
  );
