import {
  GET_INSTITUTION_ID_TYPES,
  GET_INSTITUTION_ID_TYPES_SUCCESS,
  GET_INSTITUTION_ID_TYPES_ERROR,
  POST_INSTITUTION_ID_TYPE,
  POST_INSTITUTION_ID_TYPE_SUCCESS,
  POST_INSTITUTION_ID_TYPE_ERROR,
  PUT_INSTITUTION_ID_TYPE,
  PUT_INSTITUTION_ID_TYPE_SUCCESS,
  PUT_INSTITUTION_ID_TYPE_ERROR,
  DELETE_INSTITUTION_ID_TYPE,
  DELETE_INSTITUTION_ID_TYPE_SUCCESS,
  DELETE_INSTITUTION_ID_TYPE_ERROR
} from './actionTypes';

const doGetInstitutionIdTypes = () => ({
  type: GET_INSTITUTION_ID_TYPES
});

const doGetInstitutionIdTypesSuccess = data => ({
  type: GET_INSTITUTION_ID_TYPES_SUCCESS,
  payload: {
    institutionIdTypes: data.institutionIdTypes
  }
});

const doGetInstitutionIdTypesError = error => ({
  type: GET_INSTITUTION_ID_TYPES_ERROR,
  payload: {
    error
  }
});

const doPostInstitutionIdType = newInstitutionIdType => ({
  type: POST_INSTITUTION_ID_TYPE,
  payload: {
    newInstitutionIdType
  }
});

const doPostInstitutionIdTypeSuccess = data => ({
  type: POST_INSTITUTION_ID_TYPE_SUCCESS,
  payload: {
    createdInstitutionIdType: data.createdInstitutionIdType
  }
});

const doPostInstitutionIdTypeError = error => ({
  type: POST_INSTITUTION_ID_TYPE_ERROR,
  payload: {
    error
  }
});

const doPutInstitutionIdType = institutionIdType => ({
  type: PUT_INSTITUTION_ID_TYPE,
  payload: {
    institutionIdType
  }
});

const doPutInstitutionIdTypeSuccess = data => ({
  type: PUT_INSTITUTION_ID_TYPE_SUCCESS,
  payload: {
    updatedInstitutionIdType: data.updatedInstitutionIdType
  }
});

const doPutInstitutionIdTypeError = error => ({
  type: PUT_INSTITUTION_ID_TYPE_ERROR,
  payload: {
    error
  }
});

const doDeleteInstitutionIdType = uuid => ({
  type: DELETE_INSTITUTION_ID_TYPE,
  payload: {
    uuid
  }
});

const doDeleteInstitutionIdTypeSuccess = data => ({
  type: DELETE_INSTITUTION_ID_TYPE_SUCCESS,
  payload: {
    deletedInstitutionIdType: data.deletedInstitutionIdType
  }
});

const doDeleteInstitutionIdTypeError = error => ({
  type: DELETE_INSTITUTION_ID_TYPE_ERROR,
  payload: {
    error
  }
});

export {
  doGetInstitutionIdTypes,
  doGetInstitutionIdTypesSuccess,
  doGetInstitutionIdTypesError,
  doPostInstitutionIdType,
  doPostInstitutionIdTypeSuccess,
  doPostInstitutionIdTypeError,
  doPutInstitutionIdType,
  doPutInstitutionIdTypeSuccess,
  doPutInstitutionIdTypeError,
  doDeleteInstitutionIdType,
  doDeleteInstitutionIdTypeSuccess,
  doDeleteInstitutionIdTypeError
};
