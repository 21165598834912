import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Topic from './Topic';
import { doGetSubtopics } from '../../../../redux/actions/subtopicActions';
import { doDeleteTopic } from '../../../../redux/actions/topicActions';
import { makeSubtopicsSelector } from '../../../../redux/selectors/subtopicSelectors';
import { makeL2TopicsSelector } from '../../../../redux/selectors/topicSelectors';
import { doGeneralErrorNotification } from '../../../../redux/actions/notificationActions';
import { generateNewCode } from '../../../../helpers/utilities';
import { doClearSuccessfullyCreatedSubtopic } from '../../../../redux/actions/successfulCreationActions';
import { doClearSuccessfullyUpdatedSubtopic } from '../../../../redux/actions/successfulUpdateActions';
import { doOpenMoveCodeModal } from '../../../../redux/actions/moveCodeActions';
import { types } from '../../../../redux/reducers/moveCodeReducer';

class TopicContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newSubtopic: null,
      addSubtopicAfterFetch: false,
      view: '',
      isDeleteModalOpen: false
    };
    this.addSubtopic = this.addSubtopic.bind(this);
    this.getSubtopics = this.getSubtopics.bind(this);
    this.clearAddSubtopicAfterFetch = this.clearAddSubtopicAfterFetch.bind(
      this
    );
    this.toggleEditView = this.toggleEditView.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { topic } = this.props;
    if (
      nextProps.successfullyUpdatedSubtopic &&
      nextProps.successfullyUpdatedSubtopic.topicUuid === topic.uuid
    ) {
      this.props.onClearUpdatedSubtopic();

      return true;
    }

    if (nextProps.topic !== this.props.topic) {
      return true;
    }

    if (nextState.view !== this.state.view) {
      return true;
    }

    return true;
  }

  componentDidUpdate() {
    const { successfullyCreatedSubtopic, topic, subtopics } = this.props;
    const { addSubtopicAfterFetch } = this.state;
    if (addSubtopicAfterFetch && subtopics && subtopics.length >= 1) {
      this.addSubtopic(
        addSubtopicAfterFetch.topicUuid,
        addSubtopicAfterFetch.isLevelTwo,
        addSubtopicAfterFetch.levelOneParentCode
      );
      this.clearAddSubtopicAfterFetch();
    }

    if (
      successfullyCreatedSubtopic &&
      successfullyCreatedSubtopic.topicUuid === topic.uuid
    ) {
      this.clearNewSubtopic();
      this.props.onClearCreatedSubtopic();
    }
  }

  clearAddSubtopicAfterFetch = () => {
    this.setState({
      addSubtopicAfterFetch: false
    });
  };

  clearNewSubtopic = () => {
    this.setState({ newSubtopic: null });
  };

  addSubtopic = (topicUuid, isLevelTwo, levelOneParentCode) => {
    const { subtopics, topic } = this.props;
    const { newSubtopic } = this.state;

    if (!subtopics || subtopics.length < 1) {
      this.getSubtopics(topicUuid);
      this.setState({
        addSubtopicAfterFetch: {
          topicUuid,
          isLevelTwo,
          levelOneParentCode
        }
      });
      return;
    }

    if (newSubtopic) {
      this.props.onGeneralErrorNotification(
        'Cannot create more than one Subtopic simultaneously'
      );
      return;
    }

    const newCodes = generateNewCode(
      topic.level_one_code,
      subtopics,
      isLevelTwo,
      levelOneParentCode
    );

    if (newCodes.error) {
      this.props.onGeneralErrorNotification(newCodes.message);
      return;
    }

    const emptySubtopic = {
      level_one_code: newCodes.level_one_code,
      level_two_code: newCodes.level_two_code,
      title: '',
      topicUuid,
      isNew: true
    };

    this.setState({
      newSubtopic: emptySubtopic
    });
  };

  getSubtopics = topicUuid => {
    const { subtopics } = this.props;
    if (!subtopics || subtopics.length < 1) {
      this.props.onFetchSubtopics(topicUuid);
    }
  };

  toggleEditView = () => {
    this.setState(prevState => ({
      view: prevState.view !== 'EDIT' ? 'EDIT' : ''
    }));
  };

  handleDeleteClick = () => {
    const { topicL2Siblings, topic } = this.props;
    if (
      topic.level_two_code === '00' &&
      topicL2Siblings &&
      topicL2Siblings.length > 0
    ) {
      this.props.onGeneralErrorNotification(
        'Error: cannot delete an L1 code that has relative L2 codes, please delete or move them first'
      );
      return;
    }

    this.setState({
      isDeleteModalOpen: true
    });
  };

  handleDeleteModalClose = () => {
    this.setState({
      isDeleteModalOpen: false
    });
  };

  handleDelete = () => {
    const { topicL2Siblings, topic } = this.props;

    if (
      topic.level_two_code === '00' &&
      topicL2Siblings &&
      topicL2Siblings.length > 0
    ) {
      this.props.onGeneralErrorNotification(
        'Error: cannot delete an L1 code that has relative L2 codes, please delete or move them first'
      );
      return;
    }

    this.props.onDelete(topic.uuid);
    this.handleDeleteModalClose();
  };

  render() {
    const {
      subtopics,
      topic,
      discipline,
      cip,
      addTopic,
      onOpenMoveCodeModal
    } = this.props;
    const { newSubtopic, view, isDeleteModalOpen } = this.state;
    const allSubtopics =
      newSubtopic && subtopics ? subtopics.concat(newSubtopic) : subtopics;

    return (
      <Topic
        subtopics={allSubtopics || []}
        topic={topic}
        discipline={discipline}
        cip={cip}
        view={view}
        isDeleteModalOpen={isDeleteModalOpen}
        getSubtopics={this.getSubtopics}
        addSubtopic={this.addSubtopic}
        addTopic={addTopic}
        handleDeleteClick={this.handleDeleteClick}
        handleDeleteModalClose={this.handleDeleteModalClose}
        handleDelete={this.handleDelete}
        clearNewSubtopic={this.clearNewSubtopic}
        toggleEditView={this.toggleEditView}
        openMoveModal={(uuid, fullCode, parentCodeString) =>
          onOpenMoveCodeModal(uuid, fullCode, parentCodeString)
        }
      />
    );
  }
}

TopicContainer.propTypes = {
  subtopics: PropTypes.arrayOf(PropTypes.object),
  topic: PropTypes.object,
  discipline: PropTypes.object,
  cip: PropTypes.object,
  topicL2Siblings: PropTypes.arrayOf(PropTypes.object),
  successfullyCreatedSubtopic: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
  successfullyUpdatedSubtopic: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
  addTopic: PropTypes.func,
  onOpenMoveCodeModal: PropTypes.func,
  onClearCreatedSubtopic: PropTypes.func,
  onClearUpdatedSubtopic: PropTypes.func,
  onGeneralErrorNotification: PropTypes.func,
  onFetchSubtopics: PropTypes.func,
  onDelete: PropTypes.func
};

TopicContainer.defaultProps = {
  subtopics: [],
  topic: {},
  discipline: {},
  cip: {},
  topicL2Siblings: [],
  successfullyCreatedSubtopic: false,
  successfullyUpdatedSubtopic: false,
  addTopic: undefined,
  onOpenMoveCodeModal: undefined,
  onClearCreatedSubtopic: undefined,
  onClearUpdatedSubtopic: undefined,
  onGeneralErrorNotification: undefined,
  onFetchSubtopics: undefined,
  onDelete: undefined
};

const makeMapStateToProps = () => {
  const getSubtopics = makeSubtopicsSelector();
  const getL2SiblingTopics = makeL2TopicsSelector();
  const mapStateToProps = (state, props) => ({
    subtopics: getSubtopics(state, props),
    moveModalOpen: state.moveCodeState.open,
    topicL2Siblings: getL2SiblingTopics(state, props),
    successfullyCreatedSubtopic:
      state.successfulCreationState.successfullyCreatedSubtopic,
    successfullyUpdatedSubtopic:
      state.successfulUpdateState.successfullyUpdatedSubtopic
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  onFetchSubtopics: topicUuid => dispatch(doGetSubtopics(topicUuid)),
  onClearCreatedSubtopic: () => dispatch(doClearSuccessfullyCreatedSubtopic()),
  onClearUpdatedSubtopic: () => dispatch(doClearSuccessfullyUpdatedSubtopic()),
  onDelete: uuid => dispatch(doDeleteTopic(uuid)),
  onGeneralErrorNotification: errorMessage =>
    dispatch(doGeneralErrorNotification(errorMessage)),
  onOpenMoveCodeModal: (microToMoveUuid, fullCodeToMove, parentCodeString) =>
    dispatch(
      doOpenMoveCodeModal(
        microToMoveUuid,
        types.topic,
        fullCodeToMove,
        parentCodeString
      )
    )
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(TopicContainer);
export { TopicContainer, mapDispatchToProps };
