import {
  OPEN_NEW_SYSTEM_MODAL,
  OPEN_EDIT_SYSTEM_MODAL,
  CLOSE_SYSTEM_MODAL,
  OPEN_NEW_INSTITUTION_MODAL,
  OPEN_EDIT_INSTITUTION_MODAL,
  CLOSE_INSTITUTION_MODAL
} from '../actions/actionTypes';

const views = {
  new: 'NEW',
  edit: 'EDIT'
};

const INITIAL_STATE = {
  systemModalView: '',
  institutionModalView: '',
  institutionUuid: '',
  systemUuid: null,
  stateProvinceUuid: null,
  institutionSubtypeUuid: null
};

const institutionsDashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPEN_NEW_SYSTEM_MODAL: {
      return {
        systemModalView: views.new,
        institutionModalView: '',
        systemUuid: null
      };
    }
    case OPEN_EDIT_SYSTEM_MODAL: {
      return {
        systemModalView: views.edit,
        institutionModalView: '',
        systemUuid: action.payload.systemUuid,
        stateProvinceUuid: action.payload.stateProvinceUuid,
        institutionSubtypeUuid: action.payload.institutionSubtypeUuid
      };
    }
    case OPEN_NEW_INSTITUTION_MODAL: {
      return {
        systemModalView: '',
        institutionModalView: views.new,
        systemUuid: action.payload.systemUuid
          ? action.payload.systemUuid
          : null,
        stateProvinceUuid: action.payload.stateProvinceUuid
          ? action.payload.stateProvinceUuid
          : null,
        institutionSubtypeUuid: action.payload.institutionSubtypeUuid
          ? action.payload.institutionSubtypeUuid
          : null
      };
    }
    case OPEN_EDIT_INSTITUTION_MODAL: {
      return {
        systemModalView: '',
        institutionModalView: views.edit,
        institutionUuid: action.payload.institutionUuid,
        systemUuid: action.payload.systemUuid
          ? action.payload.systemUuid
          : null,
        stateProvinceUuid: action.payload.stateProvinceUuid,
        institutionSubtypeUuid: action.payload.institutionSubtypeUuid
      };
    }
    case CLOSE_SYSTEM_MODAL: {
      return {
        ...INITIAL_STATE
      };
    }
    case CLOSE_INSTITUTION_MODAL: {
      return {
        ...INITIAL_STATE
      };
    }

    default:
      return state;
  }
};

export default institutionsDashboardReducer;
export { views, INITIAL_STATE };
