import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NotificationContainer from '../Notification/NotificationContainer';

const PageHeaderSC = styled.div`
  margin-top: 4rem;
  padding-top: 2rem;
  padding-bottom: 0.5rem;
  width: 100%;

  &.children {
    border-bottom: 2px solid ${props => props.theme.colors.grey[300]};
  }
`;

const Header = ({ children, ...props }) => (
  <div {...props}>
    <PageHeaderSC>
      <NotificationContainer />
      {children}
    </PageHeaderSC>
  </div>
);

Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};
Header.defaultProps = {
  children: null
};
export default Header;
