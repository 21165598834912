import {
  GET_DEGREE_LEVELS,
  GET_DEGREE_LEVELS_SUCCESS,
  GET_DEGREE_LEVELS_ERROR,
  POST_DEGREE_LEVEL,
  POST_DEGREE_LEVEL_SUCCESS,
  POST_DEGREE_LEVEL_ERROR,
  PUT_DEGREE_LEVEL,
  PUT_DEGREE_LEVEL_SUCCESS,
  PUT_DEGREE_LEVEL_ERROR,
  DELETE_DEGREE_LEVEL,
  DELETE_DEGREE_LEVEL_SUCCESS,
  DELETE_DEGREE_LEVEL_ERROR,
  PATCH_MOVE_DEGREE_LEVEL,
  PATCH_MOVE_DEGREE_LEVEL_SUCCESS,
  PATCH_MOVE_DEGREE_LEVEL_ERROR
} from './actionTypes';

const doGetDegreeLevels = () => ({
  type: GET_DEGREE_LEVELS
});

const doGetDegreeLevelsSuccess = data => ({
  type: GET_DEGREE_LEVELS_SUCCESS,
  payload: {
    degreeLevels: data.degreeLevels
  }
});

const doGetDegreeLevelsError = error => ({
  type: GET_DEGREE_LEVELS_ERROR,
  payload: {
    error
  }
});

const doPostDegreeLevel = newDegreeLevel => ({
  type: POST_DEGREE_LEVEL,
  payload: {
    newDegreeLevel
  }
});

const doPostDegreeLevelSuccess = data => ({
  type: POST_DEGREE_LEVEL_SUCCESS,
  payload: {
    createdDegreeLevel: data.createdDegreeLevel
  }
});

const doPostDegreeLevelError = error => ({
  type: POST_DEGREE_LEVEL_ERROR,
  payload: {
    error
  }
});

const doPutDegreeLevel = degreeLevel => ({
  type: PUT_DEGREE_LEVEL,
  payload: {
    degreeLevel
  }
});

const doPutDegreeLevelSuccess = data => ({
  type: PUT_DEGREE_LEVEL_SUCCESS,
  payload: {
    updatedDegreeLevel: data.updatedDegreeLevel
  }
});

const doPutDegreeLevelError = error => ({
  type: PUT_DEGREE_LEVEL_ERROR,
  payload: {
    error
  }
});

const doPatchMoveDegreeLevel = (uuid, destinationOrder) => ({
  type: PATCH_MOVE_DEGREE_LEVEL,
  payload: {
    uuid,
    destinationOrder
  }
});

const doPatchMoveDegreeLevelSuccess = data => ({
  type: PATCH_MOVE_DEGREE_LEVEL_SUCCESS,
  payload: {
    updatedDegreeLevelUuid: data.updatedDegreeLevelUuid,
    updatedDegreeLevels: data.updatedDegreeLevels
  }
});

const doPatchMoveDegreeLevelError = error => ({
  type: PATCH_MOVE_DEGREE_LEVEL_ERROR,
  payload: {
    error
  }
});

const doDeleteDegreeLevel = uuid => ({
  type: DELETE_DEGREE_LEVEL,
  payload: {
    uuid
  }
});

const doDeleteDegreeLevelSuccess = data => ({
  type: DELETE_DEGREE_LEVEL_SUCCESS,
  payload: {
    deletedDegreeLevel: data.deletedDegreeLevel
  }
});

const doDeleteDegreeLevelError = error => ({
  type: DELETE_DEGREE_LEVEL_ERROR,
  payload: {
    error
  }
});

export {
  doGetDegreeLevels,
  doGetDegreeLevelsSuccess,
  doGetDegreeLevelsError,
  doPostDegreeLevel,
  doPostDegreeLevelSuccess,
  doPostDegreeLevelError,
  doPutDegreeLevel,
  doPutDegreeLevelSuccess,
  doPutDegreeLevelError,
  doPatchMoveDegreeLevel,
  doPatchMoveDegreeLevelSuccess,
  doPatchMoveDegreeLevelError,
  doDeleteDegreeLevel,
  doDeleteDegreeLevelSuccess,
  doDeleteDegreeLevelError
};
