import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MicrocompetencyForm from '../MicrocompetencyForm';
import { RowSummary } from '../../../SharedSC/DashboardSC';

const MicrocompetencyRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const NewMicrocompetency = ({
  newMicro,
  cip,
  discipline,
  topic,
  subtopic,
  handleChange,
  onPostCreateMicrocompetency,
  showValidationErrors,
  clearNewMicro
}) => {
  const cipCode = cip.code;
  const disciplineCode = discipline.code;
  const topicCode = `${topic.level_one_code}${topic.level_two_code}`;
  const subtopicCode = `${subtopic.level_one_code}${subtopic.level_two_code}`;
  const newMicroCode = `${newMicro.level_one_code}${newMicro.level_two_code}`;

  return (
    <MicrocompetencyRow>
      <RowSummary>
        <MicrocompetencyForm
          code={`${cipCode}.${disciplineCode}.${topicCode}.${subtopicCode}.${newMicroCode}`}
          micro={newMicro}
          buttonText="Create"
          handleChange={event => handleChange(event)}
          onSubmit={onPostCreateMicrocompetency}
          showValidationErrors={showValidationErrors}
          closeForm={clearNewMicro}
        />
      </RowSummary>
    </MicrocompetencyRow>
  );
};

NewMicrocompetency.propTypes = {
  newMicro: PropTypes.object,
  cip: PropTypes.object,
  discipline: PropTypes.object,
  topic: PropTypes.object,
  subtopic: PropTypes.object,
  showValidationErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  onPostCreateMicrocompetency: PropTypes.func,
  clearNewMicro: PropTypes.func
};
NewMicrocompetency.defaultProps = {
  newMicro: {},
  cip: {},
  discipline: {},
  topic: {},
  subtopic: {},
  showValidationErrors: false,
  handleChange: undefined,
  onPostCreateMicrocompetency: undefined,
  clearNewMicro: undefined
};

export default NewMicrocompetency;
export { MicrocompetencyForm };
