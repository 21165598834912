import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { doGetInstitutionTypes } from '../../../redux/actions/institutionTypeActions';
import { publishedInstitutionTypesSelector } from '../../../redux/selectors/institutionTypesSelectors';
import InstitutionTypeSelect from './InstitutionTypeSelect';

class InstitutionTypeSelectContainer extends Component {
  componentDidMount() {
    const { institutionTypes } = this.props;
    if (!institutionTypes || institutionTypes.length < 1) {
      this.props.onFetchInstitutionTypes();
    }
  }

  render() {
    const {
      className,
      disabled,
      institutionTypes,
      selectedInstitutionType,
      showValidationErrors,
      handleChange
    } = this.props;
    return (
      <InstitutionTypeSelect
        className={className}
        disabled={disabled}
        institutionTypes={institutionTypes}
        selectedInstitutionType={selectedInstitutionType}
        showValidationErrors={showValidationErrors}
        handleChange={handleChange}
      />
    );
  }
}

InstitutionTypeSelectContainer.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  institutionTypes: PropTypes.arrayOf(PropTypes.object),
  showValidationErrors: PropTypes.bool,
  selectedInstitutionType: PropTypes.string,
  onFetchInstitutionTypes: PropTypes.func,
  handleChange: PropTypes.func
};

InstitutionTypeSelectContainer.defaultProps = {
  className: '',
  disabled: false,
  institutionTypes: [],
  showValidationErrors: false,
  selectedInstitutionType: '',
  onFetchInstitutionTypes: undefined,
  handleChange: undefined
};

const mapStateToProps = state => ({
  institutionTypes: publishedInstitutionTypesSelector(state)
});

const mapDispatchToProps = dispatch => ({
  onFetchInstitutionTypes: () => dispatch(doGetInstitutionTypes())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstitutionTypeSelectContainer);
export { InstitutionTypeSelectContainer };
