import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { Body1 } from '@xcomp/xcomp-design-library';
import { sortItemsByLevelCodes } from '../../../../helpers/arrayHelpers';
import AccordionWithBoldCode from '../../../AccordionRow/AccordionRowSC';
import DeleteModal from '../../../DialogModal/DeleteModal/DeleteModal';

import EditSubtopicContainer from './EditSubtopic/EditSubtopicContainer';
import MicrocompetencyContainer from '../Microcompetency/MicrocompetencyContainer';
import NewMicrocompetencyContainer from '../Microcompetency/NewMicrocompetency/NewMicrocompetencyContainer';

const Layout = styled.div`
  width: 100%;
`;

const NoMicrosMessage = styled(({ rowOffset, ...rest }) => <div {...rest} />)`
  margin-left: ${props => props.rowOffset};
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  font-weight: 300;
`;

const Body1SC = styled(Body1)`
  margin-top: 0;
`;

const Subtopic = ({
  view,
  subtopic,
  microcompetencies,
  topic,
  discipline,
  cip,
  isDeleteModalOpen,
  addMicrocompetency,
  addSubtopic,
  handleDeleteClick,
  handleDeleteModalClose,
  handleDelete,
  getSubtopicMicros,
  clearNewMicro,
  toggleEditView,
  openMoveModal
}) => {
  const someUuid = uuidv4();
  const cipCode = cip.code;
  const disciplineCode = discipline.code;
  const topicCode = `${topic.level_one_code}${topic.level_two_code}`;
  const subtopicCode = `${subtopic.level_one_code}${subtopic.level_two_code}`;
  const fullCode = `${cipCode}.${disciplineCode}.${topicCode}.${subtopicCode}`;
  const parentCodeString = `${cipCode}.${disciplineCode}.${topicCode}`;

  const isCodeBold =
    subtopic.level_two_code === '00' && subtopic.level_one_code !== '00';
  const isBookendCode =
    subtopic.level_one_code === '00' || subtopic.level_one_code === '99';
  const isOtherCode = subtopic.level_one_code === '99';

  const subtopicActions = [
    {
      label: 'Add Subtopic L1',
      onClick: () => addSubtopic(topic.uuid, false, false),
      shouldOpenOnClick: false,
      icon: 'add'
    },
    {
      label: 'Add Subtopic L2',
      onClick: () => addSubtopic(topic.uuid, true, subtopic.level_one_code),
      shouldOpenOnClick: false,
      icon: 'add'
    },
    {
      label: 'Add Microcompetency',
      onClick: () => addMicrocompetency(subtopic.uuid, false, false),
      shouldOpenOnClick: true,
      icon: 'add'
    },
    {
      label: 'Edit',
      onClick: toggleEditView,
      shouldOpenOnClick: false,
      icon: 'edit'
    },
    {
      label: 'Move',
      onClick: () => openMoveModal(subtopic.uuid, fullCode, parentCodeString),
      shouldOpenOnClick: false,
      icon: 'move'
    },
    {
      label: 'Delete',
      onClick: handleDeleteClick,
      shouldOpenOnClick: false,
      icon: 'delete'
    }
  ];

  const generalSubtopicActions = [
    {
      label: 'Add Subtopic L1',
      onClick: () => addSubtopic(topic.uuid, false, false),
      shouldOpenOnClick: true,
      icon: 'add'
    },
    {
      label: 'Add Microcompetency',
      onClick: () => addMicrocompetency(subtopic.uuid, false, false),
      shouldOpenOnClick: true,
      icon: 'add'
    }
  ];

  const thisAddOptions = isBookendCode
    ? generalSubtopicActions
    : subtopicActions;

  return (
    <Layout>
      <DeleteModal
        modalOpen={isDeleteModalOpen}
        handleModalClose={() => handleDeleteModalClose()}
        handleDelete={() => handleDelete()}
        type="Subtopic"
      />
      {view === 'EDIT' ? (
        <EditSubtopicContainer
          cip={cip}
          discipline={discipline}
          topic={topic}
          subtopic={subtopic}
          key={subtopic.uuid}
          toggleEditView={toggleEditView}
        />
      ) : (
        <AccordionWithBoldCode
          label="Subtopic"
          columnOne={fullCode}
          columnTwo={subtopic.title}
          actions={isOtherCode ? false : thisAddOptions}
          expandWidth="42px"
          height="38px"
          rowOffset="120px"
          isCodeBold={isCodeBold}
          onRowOpen={() => getSubtopicMicros(subtopic.uuid)}
        >
          {microcompetencies && microcompetencies.length > 0 ? (
            microcompetencies
              .sort(sortItemsByLevelCodes)
              .map(micro =>
                !micro.isNew ? (
                  <MicrocompetencyContainer
                    cip={cip}
                    discipline={discipline}
                    topic={topic}
                    subtopic={subtopic}
                    microcompetency={micro}
                    key={micro.uuid}
                    addMicrocompetency={addMicrocompetency}
                  />
                ) : (
                  <NewMicrocompetencyContainer
                    cip={cip}
                    discipline={discipline}
                    topic={topic}
                    subtopic={subtopic}
                    microcompetency={micro}
                    key={someUuid}
                    clearNewMicro={clearNewMicro}
                  />
                )
              )
          ) : (
            <NoMicrosMessage rowOffset="205px">
              <Body1SC>No microcompetencies exist for this subtopic</Body1SC>
            </NoMicrosMessage>
          )}
        </AccordionWithBoldCode>
      )}
    </Layout>
  );
};

Subtopic.propTypes = {
  microcompetencies: PropTypes.arrayOf(PropTypes.object),
  subtopic: PropTypes.object,
  topic: PropTypes.object,
  discipline: PropTypes.object,
  cip: PropTypes.object,
  view: PropTypes.oneOf(['', 'EDIT']),
  isDeleteModalOpen: PropTypes.bool,
  handleDeleteClick: PropTypes.func,
  handleDeleteModalClose: PropTypes.func,
  handleDelete: PropTypes.func,
  getSubtopicMicros: PropTypes.func,
  addSubtopic: PropTypes.func,
  addMicrocompetency: PropTypes.func,
  clearNewMicro: PropTypes.func,
  toggleEditView: PropTypes.func,
  openMoveModal: PropTypes.func
};

Subtopic.defaultProps = {
  microcompetencies: [],
  subtopic: {},
  topic: {},
  discipline: {},
  cip: {},
  view: '',
  isDeleteModalOpen: false,
  handleDeleteClick: undefined,
  handleDeleteModalClose: undefined,
  handleDelete: undefined,
  getSubtopicMicros: undefined,
  addSubtopic: undefined,
  addMicrocompetency: undefined,
  clearNewMicro: undefined,
  toggleEditView: undefined,
  openMoveModal: undefined
};

export default Subtopic;
