import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { Body1 } from '@xcomp/xcomp-design-library';
import AccordionRowContainer from '../../../AccordionRow/AccordionRowContainer';
import DeleteModal from '../../../DialogModal/DeleteModal/DeleteModal';
import EditDisciplineContainer from './EditDiscipline/EditDisciplineContainer';
import TopicContainer from '../Topic/TopicContainer';
import NewTopicContainer from '../Topic/NewTopic/NewTopicContainer';
import { sortItemsByLevelCodes } from '../../../../helpers/arrayHelpers';

const Layout = styled.div`
  width: 100%;
`;

const NoTopicsMessage = styled(({ rowOffset, ...rest }) => <div {...rest} />)`
  margin-left: ${props => props.rowOffset};
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  font-weight: 300;
`;

const Body1SC = styled(Body1)`
  margin-top: 0;
`;

const Discipline = ({
  view,
  discipline,
  cip,
  topics,
  isDeleteModalOpen,
  getDisciplineTopics,
  addTopic,
  addDiscipline,
  handleDeleteClick,
  handleDeleteModalClose,
  handleDelete,
  toggleEditView,
  clearNewTopic
}) => {
  const disciplineActions = [
    {
      label: 'Add Discipline',
      onClick: () => addDiscipline(cip.uuid),
      shouldOpenOnClick: true,
      icon: 'add'
    },
    {
      label: 'Add Topic',
      onClick: () => addTopic(discipline.uuid, false, false),
      shouldOpenOnClick: true,
      icon: 'add'
    },
    {
      label: 'Edit',
      onClick: toggleEditView,
      shouldOpenOnClick: false,
      icon: 'edit'
    },
    {
      label: 'Delete',
      onClick: handleDeleteClick,
      shouldOpenOnClick: false,
      icon: 'delete'
    }
  ];

  const someUuid = uuidv4();

  return (
    <Layout>
      <DeleteModal
        modalOpen={isDeleteModalOpen}
        handleModalClose={() => handleDeleteModalClose()}
        handleDelete={() => handleDelete()}
        type="Discipline"
      />
      {view === 'EDIT' ? (
        <EditDisciplineContainer
          cip={cip}
          discipline={discipline}
          key={discipline.uuid}
          toggleEditView={() => toggleEditView()}
        />
      ) : (
        <AccordionRowContainer
          label="Discipline"
          columnOne={`${cip.code}.${discipline.code}`}
          columnTwo={discipline.title}
          actions={disciplineActions}
          expandWidth="42px"
          height="38px"
          rowOffset="60px"
          onRowOpen={() => getDisciplineTopics(discipline.uuid)}
        >
          {topics && topics.length > 0 ? (
            topics
              .sort(sortItemsByLevelCodes)
              .map(topic =>
                !topic.isNew ? (
                  <TopicContainer
                    cip={cip}
                    discipline={discipline}
                    topic={topic}
                    key={topic.uuid}
                    addTopic={addTopic}
                  />
                ) : (
                  <NewTopicContainer
                    cip={cip}
                    discipline={discipline}
                    topic={topic}
                    key={someUuid}
                    clearNewTopic={clearNewTopic}
                  />
                )
              )
          ) : (
            <NoTopicsMessage rowOffset="150px">
              <Body1SC>No topics exist for this discipline</Body1SC>
            </NoTopicsMessage>
          )}
        </AccordionRowContainer>
      )}
    </Layout>
  );
};

Discipline.propTypes = {
  discipline: PropTypes.object,
  cip: PropTypes.object,
  topics: PropTypes.arrayOf(PropTypes.object),
  view: PropTypes.oneOf(['', 'EDIT']),
  isDeleteModalOpen: PropTypes.bool,
  handleDeleteClick: PropTypes.func,
  handleDeleteModalClose: PropTypes.func,
  handleDelete: PropTypes.func,
  getDisciplineTopics: PropTypes.func,
  addTopic: PropTypes.func,
  addDiscipline: PropTypes.func,
  clearNewTopic: PropTypes.func,
  toggleEditView: PropTypes.func
};

Discipline.defaultProps = {
  discipline: {},
  cip: {},
  topics: [],
  view: '',
  isDeleteModalOpen: false,
  handleDeleteClick: undefined,
  handleDeleteModalClose: undefined,
  handleDelete: undefined,
  getDisciplineTopics: undefined,
  addTopic: undefined,
  addDiscipline: undefined,
  clearNewTopic: undefined,
  toggleEditView: undefined
};

export default Discipline;
