import fetch from "../helpers/fetch";

const getProgramDisciplines = cipcodeUuid =>
  fetch(`/api/program-cipcode/${cipcodeUuid}/program-disciplines`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postProgramDiscipline = newDiscipline =>
  fetch(`/api/program-discipline/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(newDiscipline)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putProgramDiscipline = ({ uuid, ...updates }) =>
  fetch(`/api/program-discipline/${uuid}/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(updates)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteProgramDiscipline = uuid =>
  fetch(`/api/program-discipline/${uuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => error);

export {
  getProgramDisciplines,
  postProgramDiscipline,
  putProgramDiscipline,
  deleteProgramDiscipline
};
