import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { doOpenEditInstitutionModal } from '../../../../redux/actions/institutionsDashboardActions';
import { doDeleteInstitution } from '../../../../redux/actions/institutionActions';
import { makeGetInstitutionAdminsSelector } from '../../../../redux/selectors/institutionsSelectors';
import InstitutionalSystem from './Institution';

class InstitutionContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleteModalOpen: false
    };
  }

  handleDeleteClick = () => {
    this.setState({
      isDeleteModalOpen: true
    });
  };

  handleDeleteModalClose = () => {
    this.setState({
      isDeleteModalOpen: false
    });
  };

  handleDelete = event => {
    const { institution } = this.props;
    if (event) event.stopPropagation();
    this.props.onDeleteInstitution(institution.uuid);
    this.handleDeleteModalClose();
  };

  render() {
    const { isDeleteModalOpen } = this.state;
    const {
      institution,
      institutionAdmins,
      isUnaffiliated,
      className
    } = this.props;

    const systemUuid = isUnaffiliated
      ? null
      : institution.institutionalSystemUuid;

    return (
      <InstitutionalSystem
        className={className}
        institution={institution}
        institutionAdmins={institutionAdmins}
        isUnaffiliated={isUnaffiliated}
        onOpenEditInstitutionModal={() =>
          this.props.onOpenEditInstitutionModal(
            institution.uuid,
            systemUuid,
            institution.stateProvinceUuid,
            institution.institutionSubtypeUuid
          )
        }
        isDeleteModalOpen={isDeleteModalOpen}
        handleDeleteClick={this.handleDeleteClick}
        handleDeleteModalClose={this.handleDeleteModalClose}
        handleDelete={this.handleDelete}
      />
    );
  }
}

InstitutionContainer.propTypes = {
  institution: PropTypes.object.isRequired,
  institutionAdmins: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  isUnaffiliated: PropTypes.bool,
  onOpenEditInstitutionModal: PropTypes.func,
  onDeleteInstitution: PropTypes.func
};

InstitutionContainer.defaultProps = {
  institutionAdmins: [],
  className: '',
  isUnaffiliated: false,
  onOpenEditInstitutionModal: undefined,
  onDeleteInstitution: undefined
};

const makeMapStateToProps = () => {
  const getAdmins = makeGetInstitutionAdminsSelector();
  const mapStateToProps = (state, props) => ({
    institutionAdmins: getAdmins(state, props)
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  onOpenEditInstitutionModal: (
    uuid,
    systemUuid,
    stateProvinceUuid,
    institutionSubtypeUuid
  ) =>
    dispatch(
      doOpenEditInstitutionModal(
        uuid,
        systemUuid,
        stateProvinceUuid,
        institutionSubtypeUuid
      )
    ),
  onDeleteInstitution: systemUuid => dispatch(doDeleteInstitution(systemUuid))
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(InstitutionContainer);
export { InstitutionContainer };
