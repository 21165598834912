import {
  GET_PROGRAM_CIPCODES,
  GET_PROGRAM_CIPCODES_SUCCESS,
  GET_PROGRAM_CIPCODES_ERROR,
  POST_PROGRAM_CIPCODE,
  POST_PROGRAM_CIPCODE_SUCCESS,
  POST_PROGRAM_CIPCODE_ERROR,
  PUT_PROGRAM_CIPCODE,
  PUT_PROGRAM_CIPCODE_SUCCESS,
  PUT_PROGRAM_CIPCODE_ERROR,
  DELETE_PROGRAM_CIPCODE,
  DELETE_PROGRAM_CIPCODE_SUCCESS,
  DELETE_PROGRAM_CIPCODE_ERROR,
  CLEAR_PROGRAM_CIPCODES
} from './actionTypes';

const doGetProgramCipcodes = () => ({
  type: GET_PROGRAM_CIPCODES
});

const doGetProgramCipcodesSuccess = data => ({
  type: GET_PROGRAM_CIPCODES_SUCCESS,
  payload: {
    programCipcodes: data.programCips
  }
});

const doGetProgramCipcodesError = error => ({
  type: GET_PROGRAM_CIPCODES_ERROR,
  payload: {
    error
  }
});

const doPostProgramCipcode = newProgramCipcode => ({
  type: POST_PROGRAM_CIPCODE,
  payload: {
    newProgramCipcode
  }
});

const doPostProgramCipcodeSuccess = data => ({
  type: POST_PROGRAM_CIPCODE_SUCCESS,
  payload: {
    createdProgramCipcode: data.createdProgramCipcode
  }
});

const doPostProgramCipcodeError = error => ({
  type: POST_PROGRAM_CIPCODE_ERROR,
  payload: {
    error
  }
});

const doPutProgramCipcode = programCipcodeUpdates => ({
  type: PUT_PROGRAM_CIPCODE,
  payload: {
    programCipcodeUpdates
  }
});

const doPutProgramCipcodeSuccess = data => ({
  type: PUT_PROGRAM_CIPCODE_SUCCESS,
  payload: {
    updatedProgramCipcode: data.updatedProgramCipcode
  }
});

const doPutProgramCipcodeError = error => ({
  type: PUT_PROGRAM_CIPCODE_ERROR,
  payload: {
    error
  }
});

const doDeleteProgramCipcode = uuid => ({
  type: DELETE_PROGRAM_CIPCODE,
  payload: {
    uuid
  }
});

const doDeleteProgramCipcodeSuccess = data => ({
  type: DELETE_PROGRAM_CIPCODE_SUCCESS,
  payload: {
    deletedProgramCipcode: data.deletedProgramCipcode
  }
});

const doDeleteProgramCipcodeError = error => ({
  type: DELETE_PROGRAM_CIPCODE_ERROR,
  payload: {
    error
  }
});

const doClearProgramCipcodes = () => ({
  type: CLEAR_PROGRAM_CIPCODES
});

export {
  doGetProgramCipcodes,
  doGetProgramCipcodesSuccess,
  doGetProgramCipcodesError,
  doPostProgramCipcode,
  doPostProgramCipcodeSuccess,
  doPostProgramCipcodeError,
  doPutProgramCipcode,
  doPutProgramCipcodeSuccess,
  doPutProgramCipcodeError,
  doDeleteProgramCipcode,
  doDeleteProgramCipcodeSuccess,
  doDeleteProgramCipcodeError,
  doClearProgramCipcodes
};
