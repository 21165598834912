import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { Heading1 } from '@xcomp/xcomp-design-library/dist/components/Typography';

import {
  HeaderWrapper,
  HeaderLayout,
  HeaderText,
  ButtonHeader,
  ButtonPrimarySC,
  PageActions
} from '../SharedSC/HeaderSC';
import {
  Panel,
  PanelHeader,
  PanelLabel,
  Page,
  Wrapper
} from '../SharedSC/DashboardSC';
import PageHeader from '../../Header/PageHeader';
import Country from './Country/CountryContainer';
import NoItemsMessage from '../../NoItemsMessage/NoItemsMessage';
import NewCountryContainer from './Country/NewCountry/NewCountryContainer';

const LocationDashboard = ({
  countries,
  addCountry,
  clearNewCountry,
  newCountry
}) => {
  const noCountriesMessage = 'There are currently no Countries created.';
  const someUuid = uuidv4();
  const allCountries = newCountry ? countries.concat(newCountry) : countries;
  return (
    <Page>
      <PageHeader>
        <HeaderWrapper>
          <HeaderLayout>
            <HeaderText>
              <Heading1>Locations</Heading1>
            </HeaderText>
          </HeaderLayout>
        </HeaderWrapper>
      </PageHeader>
      <PageActions>
        <HeaderWrapper>
          <ButtonHeader>
            <ButtonPrimarySC onClick={addCountry}>Add Country</ButtonPrimarySC>
          </ButtonHeader>
        </HeaderWrapper>
      </PageActions>
      <Wrapper>
        {allCountries && allCountries.length > 0 ? (
          <Panel>
            <PanelHeader>
              <PanelLabel>Code</PanelLabel>
              <PanelLabel marginLeft="22px">Country</PanelLabel>
            </PanelHeader>
            {newCountry && (
              <NewCountryContainer
                country={newCountry}
                allCountries={countries}
                key={someUuid}
                clearNewCountry={clearNewCountry}
              />
            )}
            {countries.map(country => (
              <Country
                key={country.uuid}
                country={country}
                allCountries={countries}
              />
            ))}
          </Panel>
        ) : (
          <Panel>
            <NoItemsMessage message={noCountriesMessage} />
          </Panel>
        )}
      </Wrapper>
    </Page>
  );
};

LocationDashboard.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.object),
  newCountry: PropTypes.object,
  addCountry: PropTypes.func,
  clearNewCountry: PropTypes.func
};

LocationDashboard.defaultProps = {
  countries: [],
  newCountry: {},
  addCountry: undefined,
  clearNewCountry: undefined
};

export default LocationDashboard;
