import { ORM } from 'redux-orm';
import CipCode from './cipCode';
import Discipline from './discipline';
import Topic from './topic';
import Subtopic from './subtopic';
import Microcompetency from './microcompetency';
import Country from './country';
import User from './user';
import StateProvince from './stateProvince';
import InstitutionType from './institutionType';
import InstitutionSubtype from './institutionSubtype';
import AccreditationAgency from './accreditationAgency';
import InstitutionalSystem from './institutionalSystem';
import Institution from './institution';
import InstitutionIdType from './institutionIdType';
import ProgramCipcode from './programCipcode';
import ProgramDiscipline from './programDiscipline';
import ProgramCode from './programCode';
import DegreeLevel from './degreeLevel';

export const orm = new ORM({ stateSelector: state => state.orm });

orm.register(
  AccreditationAgency,
  CipCode,
  Discipline,
  Topic,
  Subtopic,
  User,
  Microcompetency,
  Country,
  StateProvince,
  InstitutionType,
  InstitutionSubtype,
  Institution,
  InstitutionalSystem,
  InstitutionIdType,
  ProgramCipcode,
  ProgramDiscipline,
  ProgramCode,
  DegreeLevel
);

export default orm;
