import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CustomTooltip from '../../../../Library/CustomTooltip';
import AccordionWithBoldCode from '../../../../AccordionRow/AccordionRowSC';
import SubtopicContainer from '../Subtopic/SubtopicContainer';
import { sortItemsByLevelCodes } from '../../../../../helpers/arrayHelpers';
import { types } from '../../../../../redux/reducers/moveCodeReducer';

const TopicRowSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const TooltipSC = styled(CustomTooltip)`
  width: 100%;
`;

const TooltipChild = styled.div`
  width: 100%;
`;

const Topic = ({
  topic,
  subtopics,
  discipline,
  cip,
  codeType,
  codeToMove,
  destinationCode,
  getSubtopics,
  onCodeSelect
}) => {
  const cipCode = cip.code;
  const disciplineCode = discipline.code;
  const topicCode = `${topic.level_one_code}${topic.level_two_code}`;
  const isCodeBold =
    topic.level_two_code === '00' && topic.level_one_code !== '00';

  const fullCode = `${cipCode}.${disciplineCode}.${topicCode}`;
  const isGeneralCode = topic.level_one_code === '00';
  const isOtherCode = topic.level_one_code === '99';
  const isCodeSelected = destinationCode && destinationCode.uuid === topic.uuid;
  const isCodeToMove = codeToMove && codeToMove === topic.uuid;

  return (
    <TopicRowSC>
      {codeType === types.topic ? (
        <TooltipSC title={isCodeToMove ? 'Current topic selected' : ''}>
          <TooltipChild>
            <AccordionWithBoldCode
              label="Topic"
              columnOne={fullCode}
              columnTwo={topic.title}
              expandWidth="42px"
              height="38px"
              columnGutters="1.5rem"
              rowOffset="60px"
              isSelected={isCodeSelected}
              isCodeBold={isCodeBold && !isCodeToMove}
              disabled={isCodeToMove}
              onRowOpen={() => getSubtopics(topic.uuid)}
              onSelect={() =>
                onCodeSelect(topic.uuid, fullCode, isGeneralCode, isOtherCode)
              }
              leaf
            />
          </TooltipChild>
        </TooltipSC>
      ) : (
        <AccordionWithBoldCode
          label="Topic"
          columnOne={fullCode}
          columnTwo={topic.title}
          expandWidth="42px"
          height="38px"
          columnGutters="1.5rem"
          rowOffset="60px"
          onRowOpen={() => getSubtopics(topic.uuid)}
          isCodeBold={isCodeBold}
        >
          {subtopics.sort(sortItemsByLevelCodes).map(subtopic => (
            <SubtopicContainer
              cip={cip}
              discipline={discipline}
              topic={topic}
              subtopic={subtopic}
              key={subtopic.uuid}
            />
          ))}
        </AccordionWithBoldCode>
      )}
    </TopicRowSC>
  );
};

Topic.propTypes = {
  subtopics: PropTypes.arrayOf(PropTypes.object),
  topic: PropTypes.object,
  discipline: PropTypes.object,
  cip: PropTypes.object,
  codeToMove: PropTypes.string,
  codeType: PropTypes.oneOf([types.topic, types.subtopic, types.micro, '']),
  destinationCode: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  getSubtopics: PropTypes.func,
  onCodeSelect: PropTypes.func
};

Topic.defaultProps = {
  subtopics: [],
  topic: {},
  discipline: {},
  cip: {},
  codeToMove: '',
  codeType: '',
  destinationCode: false,
  getSubtopics: undefined,
  onCodeSelect: undefined
};

export default Topic;
