import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { DialogTitle, Dialog } from '@material-ui/core';

const DialogSC = styled(Dialog)`
  && {
    font-size: 1rem;
    color: ${props => props.theme.fontColors.dark};
    line-height: 1.75rem;
  }
`;

class SimpleModal extends Component {
  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const {
      className,
      onClose,
      children,
      headerText,
      maxWidth,
      fullWidth,
      ...other
    } = this.props;

    return (
      <DialogSC
        onClose={this.handleClose}
        aria-labelledby="button-modal"
        className={className}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        {...other}
      >
        {headerText && (
          <DialogTitle id="button-modal">{headerText}</DialogTitle>
        )}
        <div>{children}</div>
      </DialogSC>
    );
  }
}

SimpleModal.propTypes = {
  className: PropTypes.string,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  onClose: PropTypes.func,
  children: PropTypes.element,
  headerText: PropTypes.string,
  fullWidth: PropTypes.bool
};

SimpleModal.defaultProps = {
  className: '',
  maxWidth: 'sm',
  onClose: undefined,
  children: <div />,
  headerText: '',
  fullWidth: false
};

export default SimpleModal;

// export { StyledDialogSC };
/* USAGE EXAMPLE */
// class SimpleDialogDemo extends React.Component {
//   state = {
//     open: false,
//   };

//   handleClickOpen = () => {
//     this.setState({
//       open: true
//     });
//   };

//   handleClose = value => {
//     this.setState({ open: false });
//   };

//   render() {
//     return (
//       <div>
//         <ButtonPrimary onClick={this.handleClickOpen}>Open Dialogue</ButtonPrimary>
//         <SimpleModal
//           headerText={'Publish Type'}
//           open={this.state.open}
//           onClose={this.handleClose}
//         />
//       </div>
//     );
//   }
// }
