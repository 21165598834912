import React from 'react';
import PropTypes from 'prop-types';

import { convertNullToString } from '../../../helpers/utilities';
import SelectField from '../../SelectField/SelectFieldWithError';

const InstitutionalSystemSelect = ({
  className,
  disabled,
  institutionalSystems,
  selectedInstitutionalSystem,
  showValidationErrors,
  handleChange
}) => {
  const selectOptions = institutionalSystems.map(system => ({
    value: system.uuid,
    text: system.name
  }));

  const error =
    showValidationErrors && !selectedInstitutionalSystem
      ? { message: 'Please select a System' }
      : false;

  return (
    <SelectField
      className={className}
      select
      error={error}
      disabled={
        disabled || !institutionalSystems || institutionalSystems.length < 1
      }
      name="institutionalSystemUuid"
      labelText="Institutional System"
      inputLabelWidth={62}
      value={convertNullToString(selectedInstitutionalSystem)}
      onChange={handleChange}
      options={selectOptions}
      emptyOptionText="Unaffiliated"
    />
  );
};

InstitutionalSystemSelect.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  institutionalSystems: PropTypes.arrayOf(PropTypes.object),
  selectedInstitutionalSystem: PropTypes.string,
  showValidationErrors: PropTypes.bool,
  handleChange: PropTypes.func
};

InstitutionalSystemSelect.defaultProps = {
  className: '',
  disabled: false,
  institutionalSystems: [],
  selectedInstitutionalSystem: '',
  showValidationErrors: false,
  handleChange: undefined
};

export default InstitutionalSystemSelect;
