import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { doGetInstitutionTypes } from '../../../redux/actions/institutionTypeActions';
import { makeStateSubtypeSystemsSelector } from '../../../redux/selectors/institutionalSystemsSelectors';
import InstitutionalSystemSelect from './InstitutionalSystemSelect';

class InstitutionalSystemSelectContainer extends Component {
  componentDidMount() {
    const { institutionalSystems } = this.props;
    if (!institutionalSystems || institutionalSystems.length < 1) {
      this.props.onFetchTypes();
    }
  }

  render() {
    const {
      disabled,
      institutionalSystems,
      institutionSubtype,
      stateProvince,
      selectedInstitutionalSystem,
      showValidationErrors,
      handleChange,
      className
    } = this.props;
    return (
      <InstitutionalSystemSelect
        disabled={disabled}
        className={className}
        institutionalSystems={institutionalSystems}
        institutionSubtype={institutionSubtype}
        stateProvince={stateProvince}
        selectedInstitutionalSystem={selectedInstitutionalSystem}
        showValidationErrors={showValidationErrors}
        handleChange={handleChange}
      />
    );
  }
}

InstitutionalSystemSelectContainer.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  institutionalSystems: PropTypes.arrayOf(PropTypes.object),
  showValidationErrors: PropTypes.bool,
  institutionSubtype: PropTypes.object,
  stateProvince: PropTypes.object,
  selectedInstitutionalSystem: PropTypes.string,
  onFetchTypes: PropTypes.func,
  handleChange: PropTypes.func
};

InstitutionalSystemSelectContainer.defaultProps = {
  className: '',
  disabled: false,
  institutionalSystems: [],
  showValidationErrors: false,
  institutionSubtype: { uuid: null },
  stateProvince: { uuid: null },
  selectedInstitutionalSystem: '',
  onFetchTypes: undefined,
  handleChange: undefined
};

const makeMapStateToProps = () => {
  const getInstitutionalSystems = makeStateSubtypeSystemsSelector();
  const mapStateToProps = (state, props) => ({
    institutionalSystems: getInstitutionalSystems(state, props)
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  onFetchTypes: () => dispatch(doGetInstitutionTypes())
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(InstitutionalSystemSelectContainer);
export { InstitutionalSystemSelectContainer };
