import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CustomTooltip from '../../../../Library/CustomTooltip';
import AccordionWithBoldCode from '../../../../AccordionRow/AccordionRowSC';

const MicrocompetencyRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const TooltipSC = styled(CustomTooltip)`
  width: 100%;
`;

const TooltipChild = styled.div`
  width: 100%;
`;

const Microcompetency = ({
  microcompetency,
  topic,
  subtopic,
  discipline,
  cip,
  codeToMove,
  destinationCode,
  onCodeSelect
}) => {
  const cipCode = cip.code;
  const disciplineCode = discipline.code;
  const topicCode = `${topic.level_one_code}${topic.level_two_code}`;
  const subtopicCode = `${subtopic.level_one_code}${subtopic.level_two_code}`;
  const microCode = `${microcompetency.level_one_code}${
    microcompetency.level_two_code
  }`;
  const isCodeBold =
    microcompetency.level_two_code === '00' &&
    microcompetency.level_one_code !== '00';

  const fullCode = `${cipCode}.${disciplineCode}.${topicCode}.${subtopicCode}.${microCode}`;
  const isGeneralCode = microcompetency.level_one_code === '00';
  const isOtherCode = microcompetency.level_one_code === '99';
  const isCodeSelected =
    destinationCode && destinationCode.uuid === microcompetency.uuid;
  const isCodeToMove =
    codeToMove && microcompetency && codeToMove.uuid === microcompetency.uuid;
  return (
    <MicrocompetencyRow>
      <TooltipSC title={isCodeToMove ? 'Current microcompetency selected' : ''}>
        <TooltipChild>
          <AccordionWithBoldCode
            label="Microcompetency"
            columnOne={fullCode}
            columnTwo={microcompetency.title}
            expandWidth="42px"
            height="38px"
            columnGutters="1.5rem"
            rowOffset="120px"
            isSelected={isCodeSelected}
            isCodeBold={isCodeBold && !isCodeToMove}
            disabled={isCodeToMove}
            onSelect={() =>
              onCodeSelect(
                microcompetency.uuid,
                fullCode,
                isGeneralCode,
                isOtherCode
              )
            }
            leaf
          />
        </TooltipChild>
      </TooltipSC>
    </MicrocompetencyRow>
  );
};

Microcompetency.propTypes = {
  microcompetency: PropTypes.object,
  subtopic: PropTypes.object,
  topic: PropTypes.object,
  discipline: PropTypes.object,
  cip: PropTypes.object,
  codeToMove: PropTypes.object,
  destinationCode: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  onCodeSelect: PropTypes.func
};

Microcompetency.defaultProps = {
  microcompetency: {},
  subtopic: {},
  topic: {},
  discipline: {},
  cip: {},
  codeToMove: {
    title: '',
    code: ''
  },
  destinationCode: false,
  onCodeSelect: undefined
};

export default Microcompetency;
