import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LocationForm from '../../LocationForm';
import { RowSummary } from '../../../SharedSC/DashboardSC';
import {
  validateLocationName,
  validateStateProvinceCode
} from '../../../../../helpers/validateLocations';

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const NewStateProvince = ({
  newStateProvince,
  allStateProvinces,
  showValidationErrors,
  handleChange,
  onPostCreateStateProvince,
  clearNewStateProvince
}) => (
  <Layout>
    <RowSummary>
      <LocationForm
        location={newStateProvince}
        entityLabel="State/Province"
        buttonText="Create"
        showValidationErrors={showValidationErrors}
        validateCode={() =>
          validateStateProvinceCode(newStateProvince.code, allStateProvinces)
        }
        validateName={() =>
          validateLocationName(newStateProvince.name, allStateProvinces)
        }
        handleChange={event => handleChange(event)}
        onSubmit={onPostCreateStateProvince}
        closeForm={clearNewStateProvince}
      />
    </RowSummary>
  </Layout>
);

NewStateProvince.propTypes = {
  newStateProvince: PropTypes.object,
  allStateProvinces: PropTypes.arrayOf(PropTypes.object),
  showValidationErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  onPostCreateStateProvince: PropTypes.func,
  clearNewStateProvince: PropTypes.func
};
NewStateProvince.defaultProps = {
  newStateProvince: {},
  allStateProvinces: [],
  showValidationErrors: false,
  handleChange: undefined,
  onPostCreateStateProvince: undefined,
  clearNewStateProvince: undefined
};

export default NewStateProvince;
