import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText
} from '@material-ui/core';

const SelectFieldSC = styled(FormControl)`
  width: 100%;
`;

const SelectField = ({
  className,
  name,
  value,
  labelText,
  options,
  error,
  inputLabelWidth,
  disabled,
  emptyOptionText,
  onChange
}) => (
  <SelectFieldSC
    variant="outlined"
    error={Boolean(error)}
    disabled={disabled}
    className={className}
  >
    {!emptyOptionText && labelText && (
      <InputLabel htmlFor={name}>{labelText}</InputLabel>
    )}
    <Select
      name={name}
      value={value}
      onChange={onChange}
      input={
        emptyOptionText ? (
          <OutlinedInput
            name={name}
            labelWidth={inputLabelWidth}
            notched={false}
          />
        ) : (
          <OutlinedInput name={name} labelWidth={inputLabelWidth} />
        )
      }
      displayEmpty
    >
      {emptyOptionText && <MenuItem value="">{emptyOptionText}</MenuItem>}
      {options &&
        options.map(option => (
          <MenuItem key={uuidv4()} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
    </Select>
    {error && <FormHelperText>{error.message}</FormHelperText>}
  </SelectFieldSC>
);

SelectField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  labelText: PropTypes.string,
  inputLabelWidth: PropTypes.number,
  disabled: PropTypes.bool,
  error: oneOfType([PropTypes.bool, PropTypes.object]),
  emptyOptionText: oneOfType([PropTypes.bool, PropTypes.string]),
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object)
};

SelectField.defaultProps = {
  className: '',
  name: '',
  value: '',
  labelText: '',
  inputLabelWidth: 100,
  disabled: false,
  error: false,
  emptyOptionText: false,
  onChange: undefined,
  options: []
};

export default SelectField;
