import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DisciplineForm from '../DisciplineForm';
import { RowSummary } from '../../../SharedSC/DashboardSC';
import { validateDisciplineCode } from '../../../../../helpers/validateCodes';
import { validateStringProperty } from '../../../../../helpers/validateGeneric';

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const EditDiscipline = ({
  cipcode,
  existingDiscipline,
  allProgramDisciplines,
  handleChange,
  onPutUpdate,
  showValidationErrors,
  toggleEditView
}) => (
  <Layout>
    <RowSummary>
      <DisciplineForm
        cipcode={cipcode}
        discipline={existingDiscipline}
        buttonText="Update"
        showValidationErrors={showValidationErrors}
        validateCode={() =>
          validateDisciplineCode(
            existingDiscipline.code,
            allProgramDisciplines,
            2
          )
        }
        validateTitle={title =>
          validateStringProperty(title, allProgramDisciplines, 'title')
        }
        handleChange={handleChange}
        onSubmit={onPutUpdate}
        closeForm={toggleEditView}
      />
    </RowSummary>
  </Layout>
);

EditDiscipline.propTypes = {
  cipcode: PropTypes.object.isRequired,
  existingDiscipline: PropTypes.object,
  showValidationErrors: PropTypes.bool,
  allProgramDisciplines: PropTypes.arrayOf(PropTypes.object),
  handleChange: PropTypes.func,
  onPutUpdate: PropTypes.func,
  toggleEditView: PropTypes.func
};
EditDiscipline.defaultProps = {
  existingDiscipline: {},
  showValidationErrors: false,
  allProgramDisciplines: [],
  handleChange: undefined,
  onPutUpdate: undefined,
  toggleEditView: undefined
};

export default EditDiscipline;
