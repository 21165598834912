import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';
import { types } from '../reducers/moveCodeReducer';

export const ormSelector = state => state.orm;
export const uuidSelector = state => state.moveCodeState.codeToMove;
export const codeTypeSelector = state => state.moveCodeState.codeType;

// export const codeToMoveSelector = createSelector(
//   ormSelector,
//   uuidSelector,
//   codeTypeSelector,
//   ormCreateSelector(orm, (session, uuid, codeType) => {
//     let returnCode;

//     if (codeType === types.micro) {
//       returnCode = session.Microcompetency.withId(uuid);
//     } else if (codeType === types.subtopic) {
//       returnCode = session.Subtopic.withId(uuid);
//     } else {
//       returnCode = session.Topic.withId(uuid);
//     }

//     return returnCode;
//   })
// );
export const codeToMoveSelector = createSelector(
  state => state,
  uuidSelector,
  codeTypeSelector,
  (redux, uuid, codeType) => {
    const selectorORM = ormCreateSelector(orm, session => {
      let returnCode;

      if (codeType === types.micro) {
        returnCode = session.Microcompetency.withId(uuid);
      } else if (codeType === types.subtopic) {
        returnCode = session.Subtopic.withId(uuid);
      } else {
        returnCode = session.Topic.withId(uuid);
      }

      return returnCode;
    });
    return selectorORM(redux);
  }
);

// export const codeToMoveSelector = createSelector(
//   state => state,
//   uuidSelector,
//   codeTypeSelector,
//   (redux, uuid, codeType) => {
//     let returnCode;

//     if (codeType === types.micro) {
//       const microsSelectorORM = ormCreateSelector(orm.Microcompetency);
//       returnCode = microsSelectorORM(redux).withId(uuid);
//     } else if (codeType === types.subtopic) {
//       const subtopicsSelectorORM = ormCreateSelector(orm.Subtopic);
//       returnCode = subtopicsSelectorORM(redux).withId(uuid);
//     } else {
//       const topicsSelectorORM = ormCreateSelector(orm.Topic);
//       returnCode = topicsSelectorORM(redux).withId(uuid);
//     }

//     return returnCode;
//   }
// );

// export const L2siblingsSelector = createSelector(
//   ormSelector,
//   uuidSelector,
//   codeTypeSelector,
//   ormCreateSelector(orm, (session, uuid, codeType) => {
//     let L2siblings;

//     if (codeType === types.micro) {
//       const codeToMove = session.Microcompetency.withId(uuid);
//       L2siblings =
//         codeToMove && codeToMove.level_two_code !== '00'
//           ? session.Microcompetency.all().filter(
//               micro =>
//                 micro.level_one_code === codeToMove.level_one_code &&
//                 micro.level_two_code !== '00' &&
//                 micro.subtopicUuid === codeToMove.subtopicUuid.uuid
//             )
//           : null;
//     } else if (codeType === types.subtopic) {
//       const codeToMove = session.Subtopic.withId(uuid);
//       L2siblings =
//         codeToMove && codeToMove.level_two_code !== '00'
//           ? session.Subtopic.all().filter(
//               subtopic =>
//                 subtopic.level_one_code === codeToMove.level_one_code &&
//                 subtopic.level_two_code !== '00' &&
//                 subtopic.topicUuid === codeToMove.topicUuid.uuid
//             )
//           : null;
//     } else {
//       const codeToMove = session.Topic.withId(uuid);
//       L2siblings =
//         codeToMove && codeToMove.level_two_code !== '00'
//           ? session.Topic.all().filter(
//               topic =>
//                 topic.level_one_code === codeToMove.level_one_code &&
//                 topic.level_two_code !== '00' &&
//                 topic.disciplineUuid === codeToMove.disciplineUuid.uuid
//             )
//           : null;
//     }

//     return L2siblings
//       ? L2siblings.toModelArray().map(code => Object.assign({}, code.ref))
//       : null;
//   })
// );

export const L2siblingsSelector = createSelector(
  state => state,
  uuidSelector,
  codeTypeSelector,
  (redux, uuid, codeType) => {
    const selectorORM = ormCreateSelector([orm], session => {
      let L2siblings;

      if (codeType === types.micro) {
        const codeToMove = session.Microcompetency.withId(uuid);
        L2siblings =
          codeToMove && codeToMove.level_two_code !== '00'
            ? session.Microcompetency.all().filter(
                micro =>
                  micro.level_one_code === codeToMove.level_one_code &&
                  micro.level_two_code !== '00' &&
                  micro.subtopicUuid === codeToMove.subtopicUuid.uuid
              )
            : null;
      } else if (codeType === types.subtopic) {
        const codeToMove = session.Subtopic.withId(uuid);
        L2siblings =
          codeToMove && codeToMove.level_two_code !== '00'
            ? session.Subtopic.all().filter(
                subtopic =>
                  subtopic.level_one_code === codeToMove.level_one_code &&
                  subtopic.level_two_code !== '00' &&
                  subtopic.topicUuid === codeToMove.topicUuid.uuid
              )
            : null;
      } else {
        const codeToMove = session.Topic.withId(uuid);
        L2siblings =
          codeToMove && codeToMove.level_two_code !== '00'
            ? session.Topic.all().filter(
                topic =>
                  topic.level_one_code === codeToMove.level_one_code &&
                  topic.level_two_code !== '00' &&
                  topic.disciplineUuid === codeToMove.disciplineUuid.uuid
              )
            : null;
      }

      return L2siblings
        ? L2siblings.toModelArray().map(code => Object.assign({}, code.ref))
        : null;
    });
    return selectorORM(redux);
  }
);
