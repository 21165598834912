import { call, put } from 'redux-saga/effects';
import {
  getTopics,
  postTopic,
  putTopic,
  deleteTopic,
  patchMoveTopic,
  patchMoveTopicToEmptyDiscipline
} from '../../api/topicsApi';
import {
  doGetTopicsSuccess,
  doGetTopicsError,
  doPostTopicSuccess,
  doPostTopicError,
  doPutTopicSuccess,
  doPutTopicError,
  doDeleteTopicSuccess,
  doDeleteTopicError,
  doPatchMoveTopicSuccess,
  doPatchMoveTopicError,
  doPatchMoveTopicToEmptyDisciplineSuccess
} from '../actions/topicActions';

function* handleFetchTopics(action) {
  const { disciplineUuid } = action.payload;
  try {
    const result = yield call(getTopics, disciplineUuid);
    yield put(doGetTopicsSuccess(result));
  } catch (error) {
    yield put(doGetTopicsError(error));
  }
}

function* handlePostCreateTopic(action) {
  const { newTopic } = action.payload;
  try {
    const result = yield call(postTopic, newTopic);
    yield put(doPostTopicSuccess(result));
  } catch (error) {
    yield put(doPostTopicError(error));
  }
}

function* handlePostUpdateTopic(action) {
  const { topicUpdates } = action.payload;
  try {
    const result = yield call(putTopic, topicUpdates);
    yield put(doPutTopicSuccess(result));
  } catch (error) {
    yield put(doPutTopicError(error));
  }
}

function* handlePostMoveTopic(action) {
  const { uuid, destinationCodeUuid, moveAbove } = action.payload;
  try {
    const result = yield call(patchMoveTopic, {
      uuid,
      destinationCodeUuid,
      moveAbove
    });
    yield put(doPatchMoveTopicSuccess(result));
  } catch (error) {
    yield put(doPatchMoveTopicError(error));
  }
}

function* handlePostMoveTopicToEmptyDiscipline(action) {
  const { uuid, newDisciplineUuid } = action.payload;
  try {
    const result = yield call(patchMoveTopicToEmptyDiscipline, {
      uuid,
      newDisciplineUuid
    });
    yield put(doPatchMoveTopicSuccess(result));
    yield put(
      doPatchMoveTopicToEmptyDisciplineSuccess(newDisciplineUuid, result)
    );
  } catch (error) {
    yield put(doPatchMoveTopicError(error));
  }
}

function* handleDeleteTopic(action) {
  const { topicUuid } = action.payload;
  try {
    const result = yield call(deleteTopic, topicUuid);
    yield put(doDeleteTopicSuccess(result));
  } catch (error) {
    const errorMessage = yield error.text();
    yield put(doDeleteTopicError(errorMessage));
  }
}

export {
  handleFetchTopics,
  handlePostCreateTopic,
  handlePostUpdateTopic,
  handlePostMoveTopic,
  handlePostMoveTopicToEmptyDiscipline,
  handleDeleteTopic
};
