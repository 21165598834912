import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CipcodeForm from '../CipcodeForm';
import { validateStringProperty } from '../../../../../helpers/validateGeneric';
import { validateCipcode } from '../../../../../helpers/validateCodes';
import { RowSummary } from '../../../SharedSC/DashboardSC';

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const NewCipcode = ({
  newCipcode,
  allProgramCipcodes,
  showValidationErrors,
  handleChange,
  onPostCreate,
  clearNew
}) => (
  <Layout>
    <RowSummary>
      <CipcodeForm
        cipcode={newCipcode}
        buttonText="Create"
        showValidationErrors={showValidationErrors}
        validateCode={() =>
          validateCipcode(newCipcode.code, allProgramCipcodes)
        }
        validateTitle={title =>
          validateStringProperty(title, allProgramCipcodes, 'title')
        }
        handleChange={event => handleChange(event)}
        onSubmit={onPostCreate}
        closeForm={clearNew}
      />
    </RowSummary>
  </Layout>
);

NewCipcode.propTypes = {
  newCipcode: PropTypes.object,
  allProgramCipcodes: PropTypes.arrayOf(PropTypes.object),
  showValidationErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  onPostCreate: PropTypes.func,
  clearNew: PropTypes.func
};
NewCipcode.defaultProps = {
  newCipcode: {},
  allProgramCipcodes: [],
  showValidationErrors: false,
  handleChange: undefined,
  onPostCreate: undefined,
  clearNew: undefined
};

export default NewCipcode;
