import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Caption } from '@xcomp/xcomp-design-library/dist/components/Typography';

import PublishModal from '../../../DialogModal/PublishModal/PublishModal';
import DeleteModal from '../../../DialogModal/DeleteModal/DeleteModal';
import DraggableAccordionRow from '../../../AccordionRow/DraggableAccordionRow';
import NewInstitutionSubtypeContainer from '../InstitutionSubtype/NewInstitutionSubtype/NewInstitutionSubtypeContainer';
import EditInstitutionTypeContainer from './EditInstitutionType/EditInstitutionTypeContainer';
import InstitutionSubtypeContainer from '../InstitutionSubtype/InstitutionSubtypeContainer';

const DraggableTypeAccordion = styled(DraggableAccordionRow)`
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const StyledCaption = styled(({ rowOffset, ...rest }) => <Caption {...rest} />)`
  margin-left: ${props => props.rowOffset};
  margin-bottom: 0;
`;

const FullWidthDiv = styled.div`
  width: 100%;
`;

const NoSubtypesError = styled(({ rowOffset, ...rest }) => <div {...rest} />)`
  margin-left: ${props => props.rowOffset};
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  font-weight: 300;
`;

const InstitutionType = ({
  institutionType,
  dragIndex,
  isDraggingOver,
  toggleEditView,
  institutionSubtypes,
  view,
  newInstitutionSubtype,
  isPublishModalOpen,
  isDeleteModalOpen,
  clearNewSubtype,
  handlePublishModalOpen,
  handlePublishModalClose,
  handleDeleteClick,
  handleDeleteModalClose,
  handleConfirmDelete,
  onPublish,
  addSubtype
}) => {
  const addOptions = [
    {
      label: 'Add Subtype',
      onClick: () => addSubtype(institutionType.uuid),
      icon: 'add',
      shouldOpenOnClick: true
    },
    {
      label: 'Edit',
      onClick: () => toggleEditView(),
      icon: 'edit',
      shouldOpenOnClick: false
    },
    {
      label: 'Publish',
      onClick: () => handlePublishModalOpen(),
      icon: 'publish',
      shouldOpenOnClick: false
    },
    {
      label: 'Delete',
      onClick: () => handleDeleteClick(),
      icon: 'delete',
      shouldOpenOnClick: false
    }
  ];

  const someUuid = uuidv4();

  const allInstitutionSubtypes = newInstitutionSubtype
    ? institutionSubtypes.concat(newInstitutionSubtype)
    : institutionSubtypes;

  return (
    <div>
      <PublishModal
        isModalOpen={isPublishModalOpen}
        handleModalClose={handlePublishModalClose}
        handlePublish={onPublish}
        entityLabel="Type"
        publishConfirmMessage="This will make the Type available to institutions and lock the Type from being deleted."
      />
      <DeleteModal
        modalOpen={isDeleteModalOpen}
        handleModalClose={() => handleDeleteModalClose()}
        handleDelete={() => handleConfirmDelete()}
        type="Type"
      />
      {view === 'EDIT' ? (
        <EditInstitutionTypeContainer
          institutionType={institutionType}
          key={institutionType.uuid}
          toggleEditView={toggleEditView}
        />
      ) : (
        <DraggableTypeAccordion
          columnOne={institutionType.name}
          columnTwo=""
          actions={addOptions}
          columnGutters="1.5rem"
          expandWidth="55px"
          draggableId={institutionType.uuid}
          dragIndex={dragIndex}
          isDraggingOver={isDraggingOver}
          isPublished={institutionType.published}
          isTopLevel
        >
          {allInstitutionSubtypes && allInstitutionSubtypes.length > 0 ? (
            <FullWidthDiv>
              {institutionSubtypes && institutionSubtypes.length > 0 && (
                <StyledCaption rowOffset="86px">Subtype</StyledCaption>
              )}
              {newInstitutionSubtype && (
                <NewInstitutionSubtypeContainer
                  institutionType={institutionType}
                  institutionSubtype={newInstitutionSubtype}
                  allInstitutionSubtypes={institutionSubtypes}
                  key={someUuid}
                  clearNewForm={clearNewSubtype}
                />
              )}
              {institutionSubtypes.map(subtype => (
                <InstitutionSubtypeContainer
                  institutionType={institutionType}
                  key={subtype.uuid}
                  institutionSubtype={subtype}
                />
              ))}
            </FullWidthDiv>
          ) : (
            <NoSubtypesError rowOffset="86px">
              No State/Provinces added
            </NoSubtypesError>
          )}
        </DraggableTypeAccordion>
      )}
    </div>
  );
};

InstitutionType.propTypes = {
  view: PropTypes.oneOf(['EDIT', '']),
  institutionType: PropTypes.object.isRequired,
  institutionSubtypes: PropTypes.arrayOf(PropTypes.object),
  newInstitutionSubtype: PropTypes.object,
  dragIndex: PropTypes.number.isRequired,
  isDraggingOver: PropTypes.bool,
  isPublishModalOpen: PropTypes.bool,
  isDeleteModalOpen: PropTypes.bool,
  onPublish: PropTypes.func,
  clearNewSubtype: PropTypes.func,
  handlePublishModalOpen: PropTypes.func,
  handlePublishModalClose: PropTypes.func,
  handleDeleteClick: PropTypes.func,
  handleDeleteModalClose: PropTypes.func,
  handleConfirmDelete: PropTypes.func,
  addSubtype: PropTypes.func,
  toggleEditView: PropTypes.func
};

InstitutionType.defaultProps = {
  view: '',
  institutionSubtypes: [],
  newInstitutionSubtype: {},
  isPublishModalOpen: false,
  isDeleteModalOpen: false,
  isDraggingOver: false,
  onPublish: undefined,
  clearNewSubtype: undefined,
  handlePublishModalOpen: undefined,
  handlePublishModalClose: undefined,
  handleDeleteClick: undefined,
  handleDeleteModalClose: undefined,
  handleConfirmDelete: undefined,
  addSubtype: undefined,
  toggleEditView: undefined
};

export default InstitutionType;
