import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Body1 } from '@xcomp/xcomp-design-library';
import AccordionRowContainer from '../../../AccordionRow/AccordionRowContainer';
import InstitutionSubtypeContainer from '../InstitutionSubtype/InstitutionSubtypeContainer';

const NoSubtypesMessage = styled(({ rowOffset, ...rest }) => <div {...rest} />)`
  margin-left: ${props => props.rowOffset};
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  font-weight: 300;
`;

const Body1SC = styled(Body1)`
  margin-top: 0;
`;

const InstitutionType = ({
  institutionType,
  institutionSubtypes,
  stateProvince
}) => {
  return (
    <AccordionRowContainer
      label="Type"
      columnTwo={institutionType.name}
      expandWidth="42px"
      height="38px"
      rowOffset="90px"
      columnGutters="0.75rem"
    >
      {institutionSubtypes && institutionSubtypes.length > 0 ? (
        institutionSubtypes.map(subtype => (
          <InstitutionSubtypeContainer
            institutionType={institutionType}
            key={subtype.uuid}
            institutionSubtype={subtype}
            stateProvince={stateProvince}
          />
        ))
      ) : (
        <NoSubtypesMessage rowOffset="127px">
          <Body1SC>No institution types exist</Body1SC>
        </NoSubtypesMessage>
      )}
    </AccordionRowContainer>
  );
};

InstitutionType.propTypes = {
  institutionType: PropTypes.object.isRequired,
  stateProvince: PropTypes.object,
  institutionSubtypes: PropTypes.arrayOf(PropTypes.object)
};

InstitutionType.defaultProps = {
  stateProvince: {},
  institutionSubtypes: []
};

export default InstitutionType;
