/* eslint no-unused-vars: off */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EditStateProvince from './EditStateProvince';
import { makeCountryStateProvincesSelector } from '../../../../../redux/selectors/locationsSelectors';
import {
  doPutStateProvince,
  doDeleteStateProvince
} from '../../../../../redux/actions/locationActions';
import {
  validateLocationName,
  validateStateProvinceCode
} from '../../../../../helpers/validateLocations';
import { allFieldsAreValid } from '../../../../../helpers/validateGeneric';

class EditStateProvinceContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      uuid: '',
      code: '',
      name: '',
      countryUuid: '',
      showValidationErrors: false
    };

    this.setExistingStateProvince = this.setExistingStateProvince.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleStateProvinceUpdate = this.handleStateProvinceUpdate.bind(this);
  }

  componentDidMount() {
    const { code } = this.state;
    const { stateProvince } = this.props;
    this._isMounted = true;
    if (!code) {
      this.setExistingStateProvince(stateProvince);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  setExistingStateProvince = stateProvince => {
    const { ...existingStateProvince } = stateProvince;
    if (this._isMounted) {
      this.setState(state => ({
        ...existingStateProvince
      }));
    }
  };

  setValidationErrors = existingStateProvince => {
    const { allStateProvinces } = this.props;
    const { uuid } = this.state;
    const siblingStateProvinces = allStateProvinces.filter(
      stateProvince => stateProvince.uuid !== uuid
    );
    const nameError = validateLocationName(
      existingStateProvince.name,
      siblingStateProvinces
    );
    const codeError = validateStateProvinceCode(
      existingStateProvince.code,
      siblingStateProvinces
    );
    const newValidationErrors = {
      name: nameError,
      code: codeError
    };

    return newValidationErrors;
  };

  handleStateProvinceUpdate = () => {
    const { showValidationErrors, ...existingStateProvince } = this.state;
    const newValidationErrors = this.setValidationErrors(existingStateProvince);

    if (allFieldsAreValid(newValidationErrors)) {
      const payload = {
        uuid: existingStateProvince.uuid,
        name: existingStateProvince.name,
        code: existingStateProvince.code,
        countryUuid: existingStateProvince.countryUuid
      };
      this.props.onPostUpdateStateProvince(payload);
      this.setState({ showValidationErrors: false });
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const {
      code,
      country,
      name,
      uuid,
      countryUuid,
      showValidationErrors
    } = this.state;
    const { stateProvince, allStateProvinces, toggleEditView } = this.props;

    const thisStateProvince = {
      uuid,
      code,
      name,
      countryUuid
    };

    const siblingStateProvinces = allStateProvinces.filter(
      stateProvince => stateProvince.uuid !== thisStateProvince.uuid
    );

    return (
      <EditStateProvince
        existingStateProvince={thisStateProvince}
        stateProvince={stateProvince}
        allStateProvinces={siblingStateProvinces}
        handleChange={this.handleChange}
        onPostUpdateStateProvince={this.handleStateProvinceUpdate}
        showValidationErrors={showValidationErrors}
        toggleEditView={toggleEditView}
      />
    );
  }
}

EditStateProvinceContainer.propTypes = {
  stateProvince: PropTypes.object,
  allStateProvinces: PropTypes.arrayOf(PropTypes.object),
  toggleEditView: PropTypes.func,
  onPostUpdateStateProvince: PropTypes.func
};
EditStateProvinceContainer.defaultProps = {
  stateProvince: {},
  allStateProvinces: [],
  toggleEditView: undefined,
  onPostUpdateStateProvince: undefined
};

const makeMapStateToProps = () => {
  const getCountryStateProvinces = makeCountryStateProvincesSelector();
  const mapStateToProps = (state, props) => ({
    allStateProvinces: getCountryStateProvinces(state, props)
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  onPostUpdateStateProvince: countryUpdates =>
    dispatch(doPutStateProvince(countryUpdates))
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(EditStateProvinceContainer);
export { EditStateProvinceContainer };
