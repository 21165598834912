/* eslint no-unused-vars: off */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NewStateProvince from './NewStateProvince';
import { doPostStateProvince } from '../../../../../redux/actions/locationActions';
import { makeCountryStateProvincesSelector } from '../../../../../redux/selectors/locationsSelectors';
import {
  validateLocationName,
  validateStateProvinceCode
} from '../../../../../helpers/validateLocations';
import { allFieldsAreValid } from '../../../../../helpers/validateGeneric';

class NewStateProvinceContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      code: '',
      name: '',
      showValidationErrors: false
    };

    this.setNewStateProvince = this.setNewStateProvince.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleStateProvinceSubmission = this.handleStateProvinceSubmission.bind(
      this
    );
  }

  componentDidMount() {
    const { code } = this.state;
    const { stateProvince } = this.props;
    this._isMounted = true;
    if (!code) {
      this.setNewStateProvince(stateProvince);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  setNewStateProvince = stateProvince => {
    const { isNew, ...newStateProvince } = stateProvince;
    if (this._isMounted) {
      this.setState(state => ({
        ...newStateProvince
      }));
    }
  };

  setValidationErrors = newStateProvince => {
    const { allStateProvinces } = this.props;
    const nameError = validateLocationName(
      newStateProvince.name,
      allStateProvinces
    );
    const codeError = validateStateProvinceCode(
      newStateProvince.code,
      allStateProvinces
    );
    const newValidationErrors = {
      name: nameError,
      code: codeError
    };

    return newValidationErrors;
  };

  handleStateProvinceSubmission = () => {
    const { showValidationErrors, ...newStateProvince } = this.state;

    const newValidationErrors = this.setValidationErrors(newStateProvince);

    if (allFieldsAreValid(newValidationErrors)) {
      this.props.onPostCreateStateProvince(newStateProvince);
      this.setState({ showValidationErrors: false });
      this.props.clearNewStateProvince();
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const { code, name, showValidationErrors } = this.state;
    const {
      stateProvince,
      allStateProvinces,
      clearNewStateProvince
    } = this.props;

    const newStateProvince = {
      code,
      name
    };

    return (
      <NewStateProvince
        newStateProvince={newStateProvince}
        handleChange={this.handleChange}
        onPostCreateStateProvince={this.handleStateProvinceSubmission}
        clearNewStateProvince={clearNewStateProvince}
        allStateProvinces={allStateProvinces}
        showValidationErrors={showValidationErrors}
      />
    );
  }
}

NewStateProvinceContainer.propTypes = {
  stateProvince: PropTypes.object,
  allStateProvinces: PropTypes.arrayOf(PropTypes.object),
  onPostCreateStateProvince: PropTypes.func,
  clearNewStateProvince: PropTypes.func
};
NewStateProvinceContainer.defaultProps = {
  stateProvince: {},
  allStateProvinces: [],
  onPostCreateStateProvince: undefined,
  clearNewStateProvince: undefined
};

const makeMapStateToProps = () => {
  const getCountryStateProvinces = makeCountryStateProvincesSelector();
  const mapStateToProps = (state, props) => ({
    allStateProvinces: getCountryStateProvinces(state, props)
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  onPostCreateStateProvince: newStateProvince =>
    dispatch(doPostStateProvince(newStateProvince))
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(NewStateProvinceContainer);
export { NewStateProvinceContainer };
