import React from 'react';
import PropTypes from 'prop-types';

import {
  Column,
  ElevatedForm,
  CancelButton,
  SubmitButton,
  TextFieldWithError
} from '../../../Forms/FormComponents';
import { FormTop, FormBottom } from '../../SharedSC/FormSC';

const CipcodeForm = ({
  cipcode,
  buttonText,
  handleChange,
  onSubmit,
  showValidationErrors,
  validateCode,
  validateTitle,
  closeForm
}) => (
  <ElevatedForm>
    <FormTop>
      <Column size="21%">
        <TextFieldWithError
          id="cipcodeCode"
          name="code"
          value={cipcode.code}
          onChange={event => handleChange(event)}
          margin="normal"
          InputLabelProps={{ shrink: true }}
          label="CIP Code*"
          placeholder=""
          variant="outlined"
          validate={validateCode}
          showValidationErrors={showValidationErrors}
          paddingRight="1rem"
        />
      </Column>
      <Column size="79%">
        <TextFieldWithError
          id="cipcodeName"
          name="title"
          value={cipcode.title}
          onChange={event => handleChange(event)}
          margin="normal"
          InputLabelProps={{ shrink: true }}
          label="Code Title*"
          placeholder=""
          variant="outlined"
          validate={validateTitle}
          showValidationErrors={showValidationErrors}
        />
      </Column>
    </FormTop>
    <FormBottom>
      <CancelButton onClick={closeForm} noHoverBg>
        Cancel
      </CancelButton>
      <SubmitButton onClick={onSubmit}>{buttonText}</SubmitButton>
    </FormBottom>
  </ElevatedForm>
);

CipcodeForm.propTypes = {
  cipcode: PropTypes.object,
  buttonText: PropTypes.string,
  showValidationErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  onSubmit: PropTypes.func,
  validateCode: PropTypes.func.isRequired,
  validateTitle: PropTypes.func.isRequired,
  closeForm: PropTypes.func
};
CipcodeForm.defaultProps = {
  cipcode: {},
  buttonText: 'Create',
  showValidationErrors: false,
  handleChange: undefined,
  onSubmit: undefined,
  closeForm: undefined
};

export default CipcodeForm;
