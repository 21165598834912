import {
  GET_PROGRAM_DISCIPLINES,
  GET_PROGRAM_DISCIPLINES_SUCCESS,
  GET_PROGRAM_DISCIPLINES_ERROR,
  POST_PROGRAM_DISCIPLINE,
  POST_PROGRAM_DISCIPLINE_SUCCESS,
  POST_PROGRAM_DISCIPLINE_ERROR,
  PUT_PROGRAM_DISCIPLINE,
  PUT_PROGRAM_DISCIPLINE_SUCCESS,
  PUT_PROGRAM_DISCIPLINE_ERROR,
  DELETE_PROGRAM_DISCIPLINE,
  DELETE_PROGRAM_DISCIPLINE_SUCCESS,
  DELETE_PROGRAM_DISCIPLINE_ERROR
} from './actionTypes';

const doGetProgramDisciplines = cipcodeUuid => ({
  type: GET_PROGRAM_DISCIPLINES,
  payload: {
    cipcodeUuid
  }
});

const doGetProgramDisciplinesSuccess = data => ({
  type: GET_PROGRAM_DISCIPLINES_SUCCESS,
  payload: {
    programDisciplines: data.programDisciplines
  }
});

const doGetProgramDisciplinesError = error => ({
  type: GET_PROGRAM_DISCIPLINES_ERROR,
  payload: {
    error
  }
});

const doPostProgramDiscipline = newProgramDiscipline => ({
  type: POST_PROGRAM_DISCIPLINE,
  payload: {
    newProgramDiscipline
  }
});

const doPostProgramDisciplineSuccess = data => ({
  type: POST_PROGRAM_DISCIPLINE_SUCCESS,
  payload: {
    createdProgramDiscipline: data.createdDiscipline
  }
});

const doPostProgramDisciplineError = error => ({
  type: POST_PROGRAM_DISCIPLINE_ERROR,
  payload: {
    error
  }
});

const doPutProgramDiscipline = programDisciplineUpdates => ({
  type: PUT_PROGRAM_DISCIPLINE,
  payload: {
    programDisciplineUpdates
  }
});

const doPutProgramDisciplineSuccess = data => ({
  type: PUT_PROGRAM_DISCIPLINE_SUCCESS,
  payload: {
    updatedProgramDiscipline: data.updatedDiscipline
  }
});

const doPutProgramDisciplineError = error => ({
  type: PUT_PROGRAM_DISCIPLINE_ERROR,
  payload: {
    error
  }
});

const doDeleteProgramDiscipline = uuid => ({
  type: DELETE_PROGRAM_DISCIPLINE,
  payload: {
    uuid
  }
});

const doDeleteProgramDisciplineSuccess = data => ({
  type: DELETE_PROGRAM_DISCIPLINE_SUCCESS,
  payload: {
    deletedProgramDiscipline: data.deletedDiscipline
  }
});

const doDeleteProgramDisciplineError = error => ({
  type: DELETE_PROGRAM_DISCIPLINE_ERROR,
  payload: {
    error
  }
});

export {
  doGetProgramDisciplines,
  doGetProgramDisciplinesSuccess,
  doGetProgramDisciplinesError,
  doPostProgramDiscipline,
  doPostProgramDisciplineSuccess,
  doPostProgramDisciplineError,
  doPutProgramDiscipline,
  doPutProgramDisciplineSuccess,
  doPutProgramDisciplineError,
  doDeleteProgramDiscipline,
  doDeleteProgramDisciplineSuccess,
  doDeleteProgramDisciplineError
};
