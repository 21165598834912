import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DisciplineForm from '../DisciplineForm';
import { RowSummary } from '../../../SharedSC/DashboardSC';

const DisciplineRowSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const EditDiscipline = ({
  existingDiscipline,
  cip,
  allDisciplines,
  showValidationErrors,
  handleChange,
  onPostUpdateDiscipline,
  toggleEditView
}) => (
  <DisciplineRowSC>
    <RowSummary>
      <DisciplineForm
        cipcode={cip.code}
        discipline={existingDiscipline}
        buttonText="Update"
        handleChange={event => handleChange(event)}
        onSubmit={onPostUpdateDiscipline}
        showValidationErrors={showValidationErrors}
        closeForm={toggleEditView}
        allDisciplines={allDisciplines}
      />
    </RowSummary>
  </DisciplineRowSC>
);

EditDiscipline.propTypes = {
  existingDiscipline: PropTypes.object,
  cip: PropTypes.object,
  showValidationErrors: PropTypes.bool,
  allDisciplines: PropTypes.arrayOf(PropTypes.object),
  handleChange: PropTypes.func,
  onPostUpdateDiscipline: PropTypes.func,
  toggleEditView: PropTypes.func
};
EditDiscipline.defaultProps = {
  existingDiscipline: {},
  cip: {},
  showValidationErrors: false,
  allDisciplines: [],
  handleChange: undefined,
  onPostUpdateDiscipline: undefined,
  toggleEditView: undefined
};

export default EditDiscipline;
