/* eslint react/no-array-index-key: off */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Body1, Caption } from '@xcomp/xcomp-design-library';
import ShowAdmin from '../../ShowAdmin';

const Layout = styled(({ rowOffset, ...rest }) => <div {...rest} />)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: ${props => props.rowOffset};
  width: 100%;
`;

const Field = styled.div`
  flex-grow: 1;
  padding-left: 1rem;
  margin-bottom: 1rem;
`;

const CaptionSC = styled(Caption)`
  && {
    color: ${props => props.theme.fontColors.dark};
    margin: 0;

    p {
      margin-top: 0;
    }
  }
`;

const SystemAttributeText = styled(Body1)`
  && {
    margin: 0;
  }
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const InstitutionalSystemDetails = ({
  institution,
  institutionAdmins,
  rowOffset
}) => {
  const showAdmins = institutionAdmins && institutionAdmins.length > 0;
  return (
    <Layout rowOffset={rowOffset}>
      <Field>
        <CaptionSC>Institution Code</CaptionSC>
        <SystemAttributeText>
          {institution.institutionCode ? institution.institutionCode : 'None'}
        </SystemAttributeText>
      </Field>
      <Field>
        <CaptionSC>City</CaptionSC>
        <SystemAttributeText>
          {institution.city ? institution.city : 'None'}
        </SystemAttributeText>
      </Field>

      <Field>
        <CaptionSC>Admins</CaptionSC>
        {showAdmins ? (
          <List>
            {institutionAdmins.map((admin, index) => (
              <ShowAdmin admin={admin} key={index} />
            ))}
          </List>
        ) : (
          <SystemAttributeText>No Admins selected</SystemAttributeText>
        )}
      </Field>
    </Layout>
  );
};

InstitutionalSystemDetails.propTypes = {
  institution: PropTypes.object,
  institutionAdmins: PropTypes.arrayOf(PropTypes.object),
  rowOffset: PropTypes.string
};

InstitutionalSystemDetails.defaultProps = {
  institution: {},
  institutionAdmins: [],
  rowOffset: '0px'
};

export default InstitutionalSystemDetails;
