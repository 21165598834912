import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EditTopic from './EditTopic';
import { doPutTopic } from '../../../../../redux/actions/topicActions';

import { validateCodeTitle } from '../../../../../helpers/validateCodes';
import { allFieldsAreValid } from '../../../../../helpers/validateGeneric';

class EditTopicContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      level_one_code: '',
      level_two_code: '',
      title: '',
      disciplineUuid: '',
      showValidationErrors: false
    };

    this.setExistingTopic = this.setExistingTopic.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTopicUpdate = this.handleTopicUpdate.bind(this);
  }

  componentDidMount() {
    const { level_one_code, level_two_code } = this.state;
    const { topic } = this.props;
    this._isMounted = true;
    if (!level_one_code || !level_two_code) {
      this.setExistingTopic(topic);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  setExistingTopic = topic => {
    const { ...existingTopic } = topic;
    if (this._isMounted) {
      this.setState(() => ({
        ...existingTopic
      }));
    }
  };

  setValidationErrors = existingTopic => {
    const titleError = validateCodeTitle(existingTopic.title);

    const newValidationErrors = {
      title: titleError
    };

    return newValidationErrors;
  };

  handleTopicUpdate = () => {
    const { showValidationErrors, ...existingTopic } = this.state;
    const newValidationErrors = this.setValidationErrors(existingTopic);

    if (allFieldsAreValid(newValidationErrors)) {
      const payload = {
        uuid: existingTopic.uuid,
        title: existingTopic.title
      };
      this.props.onPostUpdateTopic(payload);
      this.setState({ showValidationErrors: false });
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const {
      level_one_code,
      level_two_code,
      title,
      disciplineUuid,
      showValidationErrors
    } = this.state;
    const { cip, discipline, toggleEditView } = this.props;

    const thisTopic = {
      level_one_code,
      level_two_code,
      title,
      disciplineUuid
    };

    return (
      <EditTopic
        existingTopic={thisTopic}
        cip={cip}
        discipline={discipline}
        handleChange={this.handleChange}
        onPostUpdateTopic={this.handleTopicUpdate}
        showValidationErrors={showValidationErrors}
        toggleEditView={toggleEditView}
      />
    );
  }
}

EditTopicContainer.propTypes = {
  cip: PropTypes.object,
  discipline: PropTypes.object,
  topic: PropTypes.object,
  toggleEditView: PropTypes.func,
  onPostUpdateTopic: PropTypes.func
};
EditTopicContainer.defaultProps = {
  cip: {},
  discipline: {},
  topic: {},
  toggleEditView: undefined,
  onPostUpdateTopic: undefined
};

const mapDispatchToProps = dispatch => ({
  onPostUpdateTopic: topicUpdates => dispatch(doPutTopic(topicUpdates))
});

export default connect(
  undefined,
  mapDispatchToProps
)(EditTopicContainer);
export { EditTopicContainer };
