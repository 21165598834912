import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  doPutInstitutionIdType,
  doDeleteInstitutionIdType
} from '../../../../redux/actions/institutionIdTypeActions';
import InstitutionIdType from './InstitutionIdType';

class InstitutionIdTypeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: '',
      isPublishModalOpen: false,
      isDeleteModalOpen: false
    };
  }

  toggleEditView = () => {
    this.setState(prevState => ({
      view: prevState.view !== 'EDIT' ? 'EDIT' : ''
    }));
  };

  handlePublishModalOpen = () => {
    this.setState({
      isPublishModalOpen: true
    });
  };

  handlePublishModalClose = () => {
    this.setState({
      isPublishModalOpen: false
    });
  };

  onPublish = () => {
    const { institutionIdType } = this.props;
    const payload = {
      ...institutionIdType,
      published: true
    };
    this.props.onPutPublish(payload);
    this.handlePublishModalClose();
  };

  handleDeleteClick = () => {
    this.setState({
      isDeleteModalOpen: true
    });
  };

  handleDeleteModalClose = () => {
    this.setState({
      isDeleteModalOpen: false
    });
  };

  handleConfirmDelete = () => {
    const { institutionIdType } = this.props;

    this.props.onDelete(institutionIdType.uuid);
    this.handleDeleteModalClose();
  };

  render() {
    const { institutionIdType, className } = this.props;
    const { view, isPublishModalOpen, isDeleteModalOpen } = this.state;

    return (
      <InstitutionIdType
        view={view}
        institutionIdType={institutionIdType}
        className={className}
        isPublishModalOpen={isPublishModalOpen}
        isDeleteModalOpen={isDeleteModalOpen}
        onPublish={this.onPublish}
        handlePublishModalOpen={this.handlePublishModalOpen}
        handlePublishModalClose={this.handlePublishModalClose}
        handleDeleteClick={this.handleDeleteClick}
        handleDeleteModalClose={this.handleDeleteModalClose}
        handleConfirmDelete={this.handleConfirmDelete}
        toggleEditView={this.toggleEditView}
      />
    );
  }
}

InstitutionIdTypeContainer.propTypes = {
  institutionIdType: PropTypes.object,
  className: PropTypes.string,
  onPutPublish: PropTypes.func,
  onDelete: PropTypes.func
};

InstitutionIdTypeContainer.defaultProps = {
  institutionIdType: {},
  className: '',
  onPutPublish: undefined,
  onDelete: undefined
};

const mapDispatchToProps = dispatch => ({
  onPutPublish: updates => dispatch(doPutInstitutionIdType(updates)),
  onDelete: uuid => dispatch(doDeleteInstitutionIdType(uuid))
});

export default connect(
  undefined,
  mapDispatchToProps
)(InstitutionIdTypeContainer);
