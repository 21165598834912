import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Body1 } from '@xcomp/xcomp-design-library';
import AccordionRowContainer from '../../../AccordionRow/AccordionRowContainer';
import InstitutionTypeContainer from '../InstitutionType/InstitutionTypeContainer';

const NoTypesMessage = styled(({ rowOffset, ...rest }) => <div {...rest} />)`
  margin-left: ${props => props.rowOffset};
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  font-weight: 300;
`;

const Body1SC = styled(Body1)`
  margin-top: 0;
`;

const StateProvince = ({ stateProvince, institutionTypes, className }) => {
  return (
    <AccordionRowContainer
      className={className}
      label="State/Province"
      columnOne={stateProvince.code}
      columnTwo={stateProvince.name}
      expandWidth="42px"
      height="38px"
      rowOffset="60px"
      columnGutters="0.75rem"
    >
      {institutionTypes && institutionTypes.length > 0 ? (
        institutionTypes.map(type => (
          <InstitutionTypeContainer
            key={type.uuid}
            institutionType={type}
            stateProvince={stateProvince}
          />
        ))
      ) : (
        <NoTypesMessage rowOffset="97px">
          <Body1SC>No institution types exist</Body1SC>
        </NoTypesMessage>
      )}
    </AccordionRowContainer>
  );
};

StateProvince.propTypes = {
  stateProvince: PropTypes.object.isRequired,
  institutionTypes: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string
};

StateProvince.defaultProps = {
  institutionTypes: [],
  className: ''
};

export default StateProvince;
