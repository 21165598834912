import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { publishedInstitutionTypesSelector } from '../../../../redux/selectors/institutionTypesSelectors';
import StateProvince from './StateProvince';

class StateProvinceContainer extends PureComponent {
  render() {
    const { institutionTypes, stateProvince, className } = this.props;

    return (
      <StateProvince
        institutionTypes={institutionTypes}
        className={className}
        stateProvince={stateProvince}
      />
    );
  }
}

StateProvinceContainer.propTypes = {
  institutionTypes: PropTypes.arrayOf(PropTypes.object),
  stateProvince: PropTypes.object.isRequired,
  className: PropTypes.string
};

StateProvinceContainer.defaultProps = {
  institutionTypes: [],
  className: ''
};

const mapStateToProps = state => ({
  institutionTypes: publishedInstitutionTypesSelector(state)
});

export default connect(
  mapStateToProps,
  undefined
)(StateProvinceContainer);
export { StateProvinceContainer };
