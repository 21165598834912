import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EditDiscipline from './EditDiscipline';
import { doPutDiscipline } from '../../../../../redux/actions/disciplineActions';
import { makeCipDisciplinesSelector } from '../../../../../redux/selectors/disciplineSelectors';
import {
  validateCodeTitle,
  validateDisciplineCode
} from '../../../../../helpers/validateCodes';
import { allFieldsAreValid } from '../../../../../helpers/validateGeneric';

class EditDisciplineContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      code: '',
      title: '',
      cipcodeUuid: '',
      showValidationErrors: false
    };

    this.setExistingDiscipline = this.setExistingDiscipline.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDisciplineUpdate = this.handleDisciplineUpdate.bind(this);
  }

  componentDidMount() {
    const { code } = this.state;
    const { discipline } = this.props;
    this._isMounted = true;
    if (!code) {
      this.setExistingDiscipline(discipline);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  setExistingDiscipline = discipline => {
    const { ...existingDiscipline } = discipline;
    if (this._isMounted) {
      this.setState(() => ({
        ...existingDiscipline
      }));
    }
  };

  setValidationErrors = existingDiscipline => {
    const { allDisciplines } = this.props;
    const siblingDisciplines = allDisciplines.filter(
      disc => disc.uuid !== existingDiscipline.uuid
    );
    const titleError = validateCodeTitle(existingDiscipline.title);
    const codeError = validateDisciplineCode(
      existingDiscipline.code,
      siblingDisciplines
    );
    const newValidationErrors = {
      title: titleError,
      code: codeError
    };

    return newValidationErrors;
  };

  handleDisciplineUpdate = () => {
    const { showValidationErrors, ...existingDiscipline } = this.state;
    const newValidationErrors = this.setValidationErrors(existingDiscipline);

    if (allFieldsAreValid(newValidationErrors)) {
      const payload = {
        uuid: existingDiscipline.uuid,
        title: existingDiscipline.title,
        code: existingDiscipline.code,
        cipcodeUuid: existingDiscipline.cipcodeUuid
      };
      this.props.onPostUpdateDiscipline(payload);
      this.setState({ showValidationErrors: false });
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const { code, title, cipcodeUuid, uuid, showValidationErrors } = this.state;
    const { cip, allDisciplines, toggleEditView } = this.props;

    const thisDiscipline = {
      uuid,
      code,
      title,
      cipcodeUuid
    };

    const siblingDisciplines = allDisciplines.filter(
      disc => disc.uuid !== thisDiscipline.uuid
    );

    return (
      <EditDiscipline
        existingDiscipline={thisDiscipline}
        cip={cip}
        allDisciplines={siblingDisciplines}
        handleChange={this.handleChange}
        onPostUpdateDiscipline={this.handleDisciplineUpdate}
        showValidationErrors={showValidationErrors}
        toggleEditView={toggleEditView}
      />
    );
  }
}

EditDisciplineContainer.propTypes = {
  cip: PropTypes.object,
  discipline: PropTypes.object,
  allDisciplines: PropTypes.arrayOf(PropTypes.object),
  toggleEditView: PropTypes.func,
  onPostUpdateDiscipline: PropTypes.func
};
EditDisciplineContainer.defaultProps = {
  cip: {},
  discipline: {},
  allDisciplines: [],
  toggleEditView: undefined,
  onPostUpdateDiscipline: undefined
};

const makeMapStateToProps = () => {
  const getCipDisciplines = makeCipDisciplinesSelector();
  const mapStateToProps = (state, props) => ({
    allDisciplines: getCipDisciplines(state, props)
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  onPostUpdateDiscipline: disciplineUpdates =>
    dispatch(doPutDiscipline(disciplineUpdates))
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(EditDisciplineContainer);
export { EditDisciplineContainer };
