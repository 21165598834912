import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CIPCodeForm from '../CIPCodeForm';
import { RowSummary } from '../../../SharedSC/DashboardSC';

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const EditCIPCode = ({
  existingCipcode,
  allCipcodes,
  handleChange,
  onPostUpdateCipcode,
  showValidationErrors,
  toggleEditView
}) => (
  <Layout>
    <RowSummary>
      <CIPCodeForm
        cip={existingCipcode}
        buttonText="Update"
        showValidationErrors={showValidationErrors}
        handleChange={handleChange}
        onSubmit={onPostUpdateCipcode}
        closeForm={toggleEditView}
        allCipcodes={allCipcodes}
      />
    </RowSummary>
  </Layout>
);

EditCIPCode.propTypes = {
  existingCipcode: PropTypes.object,
  showValidationErrors: PropTypes.bool,
  allCipcodes: PropTypes.arrayOf(PropTypes.object),
  handleChange: PropTypes.func,
  onPostUpdateCipcode: PropTypes.func,
  toggleEditView: PropTypes.func
};
EditCIPCode.defaultProps = {
  existingCipcode: {},
  showValidationErrors: false,
  allCipcodes: [],
  handleChange: undefined,
  onPostUpdateCipcode: undefined,
  toggleEditView: undefined
};

export default EditCIPCode;
