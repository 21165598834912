import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MMSelectionHierarchy from './MMSelectionHierarchy';
import { doGetCips } from '../../../../redux/actions/cipCodeActions';
import { doSetMoveCodeDestination } from '../../../../redux/actions/moveCodeActions';
import { cipsSelector } from '../../../../redux/selectors/cipsSelectors';
import { types } from '../../../../redux/reducers/moveCodeReducer';
import {
  L2siblingsSelector,
  codeToMoveSelector
} from '../../../../redux/selectors/moveCodeSelectors';

class MMSelectionHierarchyContainer extends Component {
  componentDidMount() {
    this.props.onFetchCips();
  }

  render() {
    const {
      cips,
      className,
      codeType,
      destinationCode,
      codeToMove,
      parentCodeString,
      isL2Code,
      L2Siblings
    } = this.props;

    return (
      <MMSelectionHierarchy
        cips={cips}
        className={className}
        codeType={codeType}
        codeToMove={codeToMove}
        destinationCode={destinationCode}
        isL2Code={isL2Code}
        L2Siblings={L2Siblings}
        parentCodeString={parentCodeString}
        onCodeSelect={this.props.onSetMoveCodeDestination}
      />
    );
  }
}

MMSelectionHierarchyContainer.propTypes = {
  cips: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  codeType: PropTypes.oneOf([types.topic, types.subtopic, types.micro, '']),
  isL2Code: PropTypes.bool,
  L2Siblings: PropTypes.arrayOf(PropTypes.object),
  destinationCode: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  parentCodeString: PropTypes.string,
  codeToMove: PropTypes.object,
  onFetchCips: PropTypes.func,
  onSetMoveCodeDestination: PropTypes.func
};

MMSelectionHierarchyContainer.defaultProps = {
  cips: [],
  className: '',
  codeType: '',
  isL2Code: false,
  L2Siblings: null,
  destinationCode: false,
  parentCodeString: '',
  codeToMove: {
    title: '',
    code: ''
  },
  onFetchCips: undefined,
  onSetMoveCodeDestination: undefined
};

const mapStateToProps = (state, props) => ({
  cips: cipsSelector(state),
  L2Siblings: L2siblingsSelector(state, props),
  codeToMove: codeToMoveSelector(state),
  destinationCode: state.moveCodeState.destination
});

const mapDispatchToProps = dispatch => ({
  onFetchCips: () => dispatch(doGetCips()),
  onSetMoveCodeDestination: (
    subtopicUuid,
    fullCode,
    isGeneralCode,
    isOtherCode
  ) =>
    dispatch(
      doSetMoveCodeDestination(
        subtopicUuid,
        fullCode,
        isGeneralCode,
        isOtherCode
      )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MMSelectionHierarchyContainer);
export { MMSelectionHierarchyContainer, mapStateToProps, mapDispatchToProps };
