import React from 'react';
import PropTypes from 'prop-types';

import { HeaderText } from '../../../SharedSC/HeaderSC';
import {
  SimpleModalSC,
  ModalHeaderText,
  ModalBody,
  FormLayout
} from '../../../SharedSC/ModalForm';
import { ModalHeader } from '../../../../Forms/FormComponents';

import InstitutionForm from '../InstitutionForm/InstitutionFormContainer';
import { validateEntityName } from '../../../../../helpers/validateGeneric';
import { validateInstitutionCode } from '../../../../../helpers/validateInstitutions';

const EditInstitution = ({
  existingInstitution,
  hasSystemParent,
  isEditInstitutionModalOpen,
  locations,
  types,
  handleModalClose,
  handleChange,
  handleUserFieldChange,
  onPutUpdate,
  allInstitutions,
  showValidationErrors
}) => {
  const onModalSubmit = () => {
    onPutUpdate();
  };
  const allSiblingSystems =
    allInstitutions && allInstitutions.length > 0
      ? allInstitutions.filter(
          system => system.uuid !== existingInstitution.uuid
        )
      : [];

  return (
    <SimpleModalSC
      open={isEditInstitutionModalOpen}
      onClose={handleModalClose}
      maxWidth="lg"
      fullWidth
    >
      <ModalBody>
        <ModalHeader>
          <HeaderText>
            <ModalHeaderText>Edit Institution</ModalHeaderText>
          </HeaderText>
        </ModalHeader>
        <FormLayout>
          <InstitutionForm
            types={types}
            locations={locations}
            institution={existingInstitution}
            hasSystemParent={hasSystemParent}
            buttonText="Update"
            isEditForm
            showValidationErrors={showValidationErrors}
            validateName={() =>
              validateEntityName(existingInstitution.name, allSiblingSystems)
            }
            validateCity={() =>
              validateEntityName(existingInstitution.city, [])
            }
            validateInstitutionCode={() =>
              validateInstitutionCode(existingInstitution.institutionCode)
            }
            handleChange={event => handleChange(event)}
            handleUserFieldChange={handleUserFieldChange}
            onSubmit={onModalSubmit}
            closeForm={() => handleModalClose()}
          />
        </FormLayout>
      </ModalBody>
    </SimpleModalSC>
  );
};

EditInstitution.propTypes = {
  existingInstitution: PropTypes.object,
  locations: PropTypes.object,
  types: PropTypes.object,
  isEditInstitutionModalOpen: PropTypes.bool,
  hasSystemParent: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  allInstitutions: PropTypes.arrayOf(PropTypes.object),
  showValidationErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  handleUserFieldChange: PropTypes.func,
  handleModalClose: PropTypes.func,
  onPutUpdate: PropTypes.func
};
EditInstitution.defaultProps = {
  existingInstitution: {},
  locations: {
    country: {},
    stateProvince: {}
  },
  types: {
    institutionType: {},
    institutionSubtype: {}
  },
  isEditInstitutionModalOpen: false,
  hasSystemParent: false,
  allInstitutions: [],
  showValidationErrors: false,
  handleModalClose: undefined,
  handleChange: undefined,
  handleUserFieldChange: undefined,
  onPutUpdate: undefined
};

export default EditInstitution;
