import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Body1 } from '@xcomp/xcomp-design-library';

const ListItem = styled.li``;

const Body1SC = styled(Body1)`
  margin: 0.25rem 0;
`;

const ShowAdmin = ({ admin, className }) => (
  <ListItem className={className}>
    <Body1SC>{`${admin.firstName} ${admin.lastName}`}</Body1SC>
  </ListItem>
);

ShowAdmin.propTypes = {
  className: PropTypes.string,
  admin: PropTypes.object
};

ShowAdmin.defaultProps = {
  className: '',
  admin: {}
};

export default ShowAdmin;
