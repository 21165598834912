import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';

export const ormSelector = state => state.orm;

export const getTopicUuid = (state, props) => props.topic.uuid;
export const getSubtopic = (state, props) => props.subtopic;

export const makeSubtopicsSelector = () =>
  createSelector(
    state => state,
    getTopicUuid,
    (redux, topicUuid) => {
      const selectorORM = ormCreateSelector([orm], session =>
        session.Subtopic.all()
          .filter(subtopic => subtopic.topicUuid === topicUuid)
          .toModelArray()
          .map(subtopic => Object.assign({}, subtopic.ref))
      );
      return selectorORM(redux);
    }
  );

export const makeL2SubtopicsSelector = () =>
  createSelector(
    state => state,
    getSubtopic,
    (redux, subtop) => {
      const selectorORM = ormCreateSelector([orm], session =>
        session.Subtopic.all()
          .filter(
            subtopic =>
              subtopic.topicUuid === subtop.topicUuid &&
              subtopic.level_one_code === subtop.level_one_code &&
              subtopic.uuid !== subtop.uuid
          )
          .toModelArray()
          .map(subtopic => Object.assign({}, subtopic.ref))
      );
      return selectorORM(redux);
    }
  );
