/* eslint no-unused-vars: off */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NewProgram from './NewProgram';
import { doPostProgramCode } from '../../../../../redux/actions/programCodeActions';
import { makeDisciplineProgramCodesSelector } from '../../../../../redux/selectors/programCodeSelectors';
import {
  validateStringProperty,
  allFieldsAreValid
} from '../../../../../helpers/validateGeneric';
import {
  validateProgramCode,
  validateCodeDescription
} from '../../../../../helpers/validateCodes';

class NewProgramContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      code: '',
      title: '',
      description: '',
      relatedPrograms: [],
      showValidationErrors: false
    };

    this.setNew = this.setNew.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmission = this.handleSubmission.bind(this);
  }

  componentDidMount() {
    const { code } = this.state;
    const { program } = this.props;
    this._isMounted = true;
    if (!code) {
      this.setNew(program);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  handleRelatedProgramFieldChange = (values, fieldName) => {
    this.setState(() => ({
      [fieldName]: values
    }));
  };

  handleRelatedProgramAdd = program => {
    const { relatedPrograms } = this.state;
    const newPrograms = [
      ...relatedPrograms,
      { uuid: program.value, label: program.label }
    ];
    this.setState({
      relatedPrograms: newPrograms
    });
  };

  handleRelatedProgramRemove = uuid => {
    const { relatedPrograms } = this.state;
    const newPrograms = relatedPrograms.filter(
      program => program.uuid !== uuid
    );
    this.setState({
      relatedPrograms: newPrograms
    });
  };

  setNew = program => {
    const { isNew, ...newProgram } = program;
    if (this._isMounted) {
      this.setState(state => ({
        ...newProgram
      }));
    }
  };

  setValidationErrors = newProgram => {
    const { allProgramCodes } = this.props;
    const titleError = validateStringProperty(
      newProgram.title,
      allProgramCodes,
      'title'
    );
    const codeError = validateProgramCode(newProgram.code, allProgramCodes, 2);
    const descriptionError = validateCodeDescription(newProgram.description);

    const newValidationErrors = {
      title: titleError,
      code: codeError,
      description: descriptionError
    };

    return newValidationErrors;
  };

  handleSubmission = () => {
    const { showValidationErrors, relatedPrograms, ...newProgram } = this.state;
    const { discipline } = this.props;

    const newValidationErrors = this.setValidationErrors(newProgram);

    if (allFieldsAreValid(newValidationErrors)) {
      const payload = {
        disciplineUuid: discipline.uuid,
        title: newProgram.title,
        description: newProgram.description,
        code: newProgram.code,
        relatedPrograms: relatedPrograms.map(program => program.uuid)
      };

      this.props.onPostCreate(payload);
      this.setState({ showValidationErrors: false });
      this.props.clearNew();
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const {
      code,
      title,
      description,
      relatedPrograms,
      showValidationErrors
    } = this.state;
    const { cipcode, discipline, allProgramCodes, clearNew } = this.props;

    const newProgram = {
      code,
      title,
      description,
      relatedPrograms
    };

    return (
      <NewProgram
        cipcode={cipcode}
        discipline={discipline}
        newProgram={newProgram}
        handleChange={this.handleChange}
        handleRelatedProgramFieldChange={this.handleRelatedProgramFieldChange}
        handleRelatedProgramAdd={this.handleRelatedProgramAdd}
        handleRelatedProgramRemove={this.handleRelatedProgramRemove}
        onPostCreate={this.handleSubmission}
        clearNew={clearNew}
        allPrograms={allProgramCodes}
        showValidationErrors={showValidationErrors}
      />
    );
  }
}

NewProgramContainer.propTypes = {
  cipcode: PropTypes.object.isRequired,
  discipline: PropTypes.object.isRequired,
  program: PropTypes.object,
  allProgramCodes: PropTypes.arrayOf(PropTypes.object),
  onPostCreate: PropTypes.func,
  clearNew: PropTypes.func
};

NewProgramContainer.defaultProps = {
  program: {},
  allProgramCodes: [],
  onPostCreate: undefined,
  clearNew: undefined
};

const makeMapStateToProps = () => {
  const getDisciplineProgramCodes = makeDisciplineProgramCodesSelector();
  const mapStateToProps = (state, props) => ({
    allProgramCodes: getDisciplineProgramCodes(state, props)
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  onPostCreate: newProgram => dispatch(doPostProgramCode(newProgram))
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(NewProgramContainer);
export { NewProgramContainer };
