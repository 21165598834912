import styled from 'styled-components';
import { ButtonPrimary, ButtonIcon } from '@xcomp/xcomp-design-library';

export const FormTop = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding-top: 6px;

  margin-bottom: 1.5rem;
  &.has_code_field {
    margin-bottom: 0;
  }
  &.edit {
    padding-right: 2rem;
  }
`;

export const FormBottom = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

export const SubmitButtonSC = styled(ButtonPrimary)`
  && {
    margin-top: 1rem;
  }
`;

export const ButtonIconSC = styled(ButtonIcon)`
  && {
    margin-left: 0.375rem;
    font-size: 1.25rem;
    line-height: 0.7;
    &.delete {
      svg {
        font-size: 1.125rem;
      }
    }
  }
`;

export const IconsContainer = styled.div`
  && {
    position: absolute;
    right: 0.5rem;
    top: 0rem;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0.5rem 0;
  }
`;
