import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import SelectUserField from './SelectUserField';

class SelectUserFieldContainer extends Component {
  constructor(props) {
    super(props);
    this.customHandleChange = this.customHandleChange.bind(this);
  }

  customHandleChange = (
    incomingValues,
    handleChange,
    fieldName,
    singleValue
  ) => {
    if (singleValue) {
      handleChange(incomingValues.value, fieldName);
    } else {
      const newUserValues = incomingValues.map(user =>
        typeof user === 'string' ? user : user.value
      );

      handleChange(newUserValues, fieldName);
    }
  };

  render() {
    const {
      label,
      placeholder,
      usersArray,
      values,
      singleValue,
      fieldName,
      children,
      handleChange,
      ...rest
    } = this.props;

    let valsWithLabels;

    if (singleValue) {
      const index = _.findIndex(usersArray, obj => obj.value === values);
      valsWithLabels = usersArray[index];

      if (!valsWithLabels || valsWithLabels.length < 1) {
        valsWithLabels = [
          {
            value: '',
            label: ''
          }
        ];
      }
    } else {
      valsWithLabels = values
        .map(value => {
          const index = _.findIndex(usersArray, obj => obj.value === value);
          return index !== -1 ? usersArray[index] : false;
        })
        .filter(item => item !== false);
    }

    return (
      <SelectUserField
        handleChange={users =>
          this.customHandleChange(users, handleChange, fieldName, singleValue)
        }
        fieldName={fieldName}
        label={label}
        placeholder={placeholder}
        usersArray={usersArray}
        value={valsWithLabels}
        singleValue={singleValue}
        {...rest}
      >
        {children && children}
      </SelectUserField>
    );
  }
}

SelectUserFieldContainer.propTypes = {
  fieldName: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  singleValue: PropTypes.bool,
  usersArray: PropTypes.arrayOf(PropTypes.object),
  handleChange: PropTypes.func,
  values: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string
  ]),
  children: PropTypes.node
};

SelectUserFieldContainer.defaultProps = {
  fieldName: '',
  label: '',
  placeholder: '',
  singleValue: false,
  usersArray: [],
  handleChange: undefined,
  values: [],
  children: <div />
};

export default SelectUserFieldContainer;
