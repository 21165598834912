import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import LockOutlined from '@material-ui/icons/LockOutlined';
import { ExpandIcon } from '@xcomp/xcomp-design-library/dist/icons';
import { Subtitle1, Caption } from '@xcomp/xcomp-design-library';
import DragIcon from '@xcomp/xcomp-design-library/dist/icons/actions/DragIcon';

import ActionButton from './ActionButton';

const DragIconSC = styled(DragIcon)`
  && {
    transform: rotate(90deg);
  }
`;

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
  position: relative;
`;

const RowSummary = styled(
  ({
    height,
    rowOffset,
    published,
    disabled,
    isSelected,
    isSelectable,
    isTopLevel,
    isDragging,
    isDraggingOver,
    ...rest
  }) => <div {...rest} />
)`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: ${props => props.height};
  font-size: 0.9375rem;
  font-weight: 300;
  color: ${props => props.theme.fontColors.dark};
  margin-left: ${props => !props.isTopLevel && props.rowOffset};
  .show_on_hover {
    display: none;
  }

  .hover-effect {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    background: ${props =>
      props.published ? props.theme.colors.primaryShades[50] : 'transparent'};
  }

  ${props =>
    props.isSelectable &&
    css`
      .hover-effect:hover {
        cursor: ${props => (props.disabled ? 'initial' : 'pointer')};
      }
      border-left: 3px solid transparent;
    `}

  ${props =>
    props.isSelected &&
    css`
      .hover-effect {
        background: ${props => props.theme.colors.primaryShades[50]};
      }
      border-left: 3px solid ${props => props.theme.colors.primary};
    `}

  &:hover {
    .hover-effect {
      background: ${props =>
        props.disabled ? 'none' : props.theme.colors.primaryShades[50]};
    }

    .show_on_hover {
      display: flex;
    }
  }

  h2 {
    font-size: inherit;
    font-weight: 400;
    color: ${props => props.theme.fontColors.darker};
    margin: 0;
    padding: 0;
  }

  svg {
    vertical-align: middle;
  }
`;

const RowContent = styled(({ leaf, gutter, ...rest }) => <div {...rest} />)`
  flex-grow: 2;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.9375rem;
  font-weight: 300;
  color: ${props => props.theme.fontColors.dark};
  padding-left: ${props => (props.leaf ? props.gutter : 'initial')};

  h2 {
    font-size: inherit;
    font-weight: 400;
    color: ${props => props.theme.fontColors.darker};
    margin: 0;
    padding: 0;
  }
`;

const ActionsColumn = styled(({ isPublished, ...rest }) => <div {...rest} />)`
  && {
    margin-left: auto;
    width: ${props => (props.isPublished ? '88px' : '66px')};
    height: 100%;
    font-size: inherit;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
  }
`;

const StyledPublishedIcon = styled(LockOutlined)`
  && {
    position: absolute;
    right: 8px;
    top: 0;
    bottom: 0;
    height: 100%;
    odisplay: flex;
    align-item: center;
    font-size: 1.25rem;
  }
`;

const RowChild = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
`;

const ContentColumn = styled(({ gutter, disabled, ...rest }) => (
  <div {...rest} />
))`
  font-size: inherit;
  display: block;
  margin-right: ${props => props.gutter || '1rem'};

  ${props =>
    props.disabled &&
    css`
      font-style: italic;
    `}
`;

const CaptionSC = styled(Caption)`
  color: ${props => props.theme.colors.grey[600]};
`;

const SubtitleSC = styled(({ light, ...rest }) => <Subtitle1 {...rest} />)`
  && {
    color: ${props =>
      props.light
        ? props.theme.colors.grey[600]
        : props.theme.colors.grey[900]};
  }
`;

const ExpandColumn = styled(({ width, ...rest }) => <div {...rest} />)`
  && {
    width: ${props => props.width};
    height: 100%;
    font-size: inherit;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

const DragHandleColumn = styled(({ width, ...rest }) => <div {...rest} />)`
  && {
    width: ${props => props.width};
    height: 100%;
    font-size: inherit;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

const RowButtons = styled.div`
  display: flex;
  wrap: nowrap;
  justify-content: flex-end;
  padding-right: 0.5rem;
`;

const ClosedIcon = styled(ExpandIcon)`
  transform: rotate(180deg);
`;

const OpenedIcon = styled(ExpandIcon)`
  transform: rotate(270deg);
`;

const DragHandleLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  svg {
    font-size: 1.25rem;
    transform: rotate(90deg);
  }
  small {
    width: 100%;
    font-size: 0.625rem;
    margin-top: 2px;
    color: ${props => props.theme.fontColors.dark};
    text-align: center;
  }
`;

const DragHandle = props => (
  <DragHandleLayout {...props}>
    <DragIconSC />
  </DragHandleLayout>
);

const getIcon = isRowOpen => {
  if (!isRowOpen) {
    return <ClosedIcon />;
  }
  return <OpenedIcon />;
};

const AccordionRow = ({
  actions,
  children,
  label,
  columnOne,
  columnTwo,
  expandWidth,
  rowOffset,
  className,
  columnOneClassName,
  columnTwoClassName,
  leaf,
  disabled,
  columnGutters,
  height,
  isRowOpen,
  isSelected,
  isPublished,
  isTopLevel,
  isDragEnabled,
  isDragging,
  isDraggingOver,
  dragHandleProps,
  toggleOpenRow,
  onSelect
}) => {
  const getOnClick = (disabled, onSelect, toggleOpenRow) => {
    if (disabled) {
      return undefined;
    } else if (onSelect) {
      return onSelect;
    }
    return toggleOpenRow;
  };

  return (
    <Layout className={className}>
      <RowSummary
        onClick={getOnClick(disabled, onSelect, toggleOpenRow)}
        published={isPublished}
        height={height}
        rowOffset={rowOffset}
        disabled={disabled}
        isSelected={isSelected}
        isSelectable={Boolean(onSelect)}
        isTopLevel={isTopLevel}
        isDragging={isDragging}
        isDraggingOver={isDraggingOver}
      >
        <div className="hover-effect">
          {isDragEnabled && (
            <DragHandleColumn width="30px">
              <DragHandle {...dragHandleProps} />
            </DragHandleColumn>
          )}
          {!leaf && (
            <ExpandColumn width={expandWidth || '66px'}>
              {getIcon(isRowOpen)}
            </ExpandColumn>
          )}
          <RowContent leaf={leaf} gutter={columnGutters}>
            {label && (
              <ContentColumn gutter={columnGutters}>
                <CaptionSC>{label}</CaptionSC>
              </ContentColumn>
            )}
            {columnOne && (
              <ContentColumn
                disabled={disabled}
                gutter={columnGutters}
                className={columnOneClassName}
              >
                <SubtitleSC light={!isTopLevel || disabled}>
                  {columnOne}
                </SubtitleSC>
              </ContentColumn>
            )}
            {columnTwo && (
              <ContentColumn
                disabled={disabled}
                gutter={columnGutters}
                className={columnTwoClassName}
              >
                <SubtitleSC light={disabled}>{columnTwo}</SubtitleSC>
              </ContentColumn>
            )}
          </RowContent>
          {(actions || isPublished) && (
            <ActionsColumn isPublished>
              <RowButtons>
                {actions && (
                  <ActionButton
                    className="show_on_hover"
                    actions={actions}
                    isRowOpen={isRowOpen}
                    toggleOpenRow={toggleOpenRow}
                  />
                )}
                {isPublished && <StyledPublishedIcon />}
              </RowButtons>
            </ActionsColumn>
          )}
        </div>
      </RowSummary>
      {isRowOpen && !leaf && <RowChild>{children}</RowChild>}
    </Layout>
  );
};

AccordionRow.propTypes = {
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.bool
  ]),
  children: PropTypes.node,
  label: PropTypes.string,
  columnOne: PropTypes.string,
  columnTwo: PropTypes.string,
  columnOneClassName: PropTypes.string,
  columnTwoClassName: PropTypes.string,
  disabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  className: PropTypes.string,
  expandWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  columnGutters: PropTypes.string,
  height: PropTypes.string,
  rowOffset: PropTypes.string,
  leaf: PropTypes.bool,
  isRowOpen: PropTypes.bool,
  isPublished: PropTypes.bool,
  isTopLevel: PropTypes.bool,
  isDragEnabled: PropTypes.bool,
  isDragging: PropTypes.bool,
  isDraggingOver: PropTypes.bool,
  dragHandleProps: PropTypes.object,
  toggleOpenRow: PropTypes.func
};

AccordionRow.defaultProps = {
  actions: false,
  children: <div />,
  label: '',
  columnOne: '',
  columnTwo: '',
  columnOneClassName: '',
  columnTwoClassName: '',
  disabled: false,
  isSelected: false,
  onSelect: undefined,
  className: '',
  expandWidth: false,
  columnGutters: '0.5rem',
  height: '43px',
  rowOffset: '0',
  leaf: false,
  isRowOpen: false,
  isPublished: false,
  isTopLevel: false,
  isDragEnabled: false,
  isDragging: false,
  isDraggingOver: false,
  dragHandleProps: {},
  toggleOpenRow: undefined
};

export default AccordionRow;
export { getIcon, RowChild, RowSummary, StyledPublishedIcon };
