/* eslint no-unused-vars: off */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { doGetUsers } from '../../../../../redux/actions/userActions';
import { allUsersSelector } from '../../../../../redux/selectors/userSelectors';
import InstitutionForm from './InstitutionForm';

class InstitutionFormContainer extends Component {
  componentDidMount() {
    const { users } = this.props;
    if (!users || users.length < 1) {
      this.props.onGetUsers();
    }
  }

  render() {
    const { users, ...rest } = this.props;

    return <InstitutionForm users={users} {...rest} />;
  }
}

InstitutionFormContainer.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func
};
InstitutionFormContainer.defaultProps = {
  users: [],
  onGetUsers: undefined
};

const mapStateToProps = (state, props) => ({
  users: allUsersSelector(state, props)
});

const mapDispatchToProps = dispatch => ({
  onGetUsers: () => dispatch(doGetUsers())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstitutionFormContainer);
export { InstitutionFormContainer };
