import { call, put } from 'redux-saga/effects';
import {
  postDiscipline,
  putDiscipline,
  deleteDiscipline
} from '../../api/disciplinesApi';
import {
  doPostDisciplineError,
  doPostDisciplineSuccess,
  doPutDisciplineError,
  doPutDisciplineSuccess,
  doDeleteDisciplineSuccess,
  doDeleteDisciplineError
} from '../actions/disciplineActions';

function* handlePostCreateDiscipline(action) {
  const { newDiscipline } = action.payload;
  try {
    const result = yield call(postDiscipline, newDiscipline);
    yield put(doPostDisciplineSuccess(result));
  } catch (error) {
    yield put(doPostDisciplineError(error));
  }
}

function* handlePostUpdateDiscipline(action) {
  const { disciplineUpdates } = action.payload;
  try {
    const result = yield call(putDiscipline, disciplineUpdates);
    yield put(doPutDisciplineSuccess(result));
  } catch (error) {
    yield put(doPutDisciplineError(error));
  }
}

function* handleDeleteDiscipline(action) {
  const { disciplineUuid } = action.payload;
  try {
    const result = yield call(deleteDiscipline, disciplineUuid);
    yield put(doDeleteDisciplineSuccess(result));
  } catch (error) {
    const errorMessage = yield error.text();
    yield put(doDeleteDisciplineError(errorMessage));
  }
}

export {
  handlePostCreateDiscipline,
  handlePostUpdateDiscipline,
  handleDeleteDiscipline
};
