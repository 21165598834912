import fetch from "../helpers/fetch";

const getProgramCipcodes = () =>
  fetch('/api/program-cipcodes', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postProgramCipcode = cipcode =>
  fetch('/api/program-cipcode/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(cipcode),
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putProgramCipcode = ({ uuid, ...updates }) =>
  fetch(`/api/program-cipcode/${uuid}/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(updates)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteProgramCipcode = uuid =>
  fetch(`/api/program-cipcode/${uuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => error);

export {
  getProgramCipcodes,
  postProgramCipcode,
  putProgramCipcode,
  deleteProgramCipcode
};
