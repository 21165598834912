import { call, put } from 'redux-saga/effects';
import {
  getInstitutions,
  postInstitution,
  putInstitution,
  deleteInstitution
} from '../../api/institutionsApi';
import {
  doGetInstitutionsSuccess,
  doGetInstitutionsError,
  doPostInstitutionSuccess,
  doPostInstitutionError,
  doPutInstitutionSuccess,
  doPutInstitutionError,
  doDeleteInstitutionSuccess,
  doDeleteInstitutionError
} from '../actions/institutionActions';

function* handleFetchInstitutions() {
  try {
    const result = yield call(getInstitutions);
    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doGetInstitutionsSuccess(payload));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doGetInstitutionsError(error));
  }
}

function* handlePostCreateInstitution(action) {
  const { newInstitution } = action.payload;

  try {
    const result = yield call(postInstitution, newInstitution);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostInstitutionSuccess(payload));
    } else {
      const errorText = yield result.text();
      yield put(
        doPostInstitutionError({
          text: errorText
        })
      );
    }
  } catch (error) {
    yield put(doPostInstitutionError());
  }
}

function* handlePutUpdateInstitution(action) {
  const { institution } = action.payload;

  try {
    const result = yield call(putInstitution, institution);

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doPutInstitutionSuccess(payload));
    } else {
      const errorText = yield result.text();
      yield put(
        doPutInstitutionError({
          text: errorText
        })
      );
    }
  } catch (error) {
    yield put(doPutInstitutionError());
  }
}

function* handleDeleteInstitution(action) {
  const { uuid } = action.payload;

  try {
    const result = yield call(deleteInstitution, uuid);

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doDeleteInstitutionSuccess(payload));
    } else {
      const errorText = yield result.text();
      yield put(
        doDeleteInstitutionError({
          text: errorText
        })
      );
    }
  } catch (error) {
    yield put(doDeleteInstitutionError());
  }
}

export {
  handleFetchInstitutions,
  handlePostCreateInstitution,
  handlePutUpdateInstitution,
  handleDeleteInstitution
};
