import React from 'react';
import PropTypes from 'prop-types';

import { FormTop, FormBottom } from '../SharedSC/FormSC';

import {
  Column,
  ElevatedForm,
  CancelButton,
  SubmitButton,
  TextFieldWithError
} from '../../Forms/FormComponents';

const DegreeLevelForm = ({
  degreeLevel,
  entityLabel,
  buttonText,
  handleChange,
  onSubmit,
  showValidationErrors,
  validateDegreeLevel,
  onFormClose
}) => (
  <ElevatedForm>
    <FormTop>
      <Column size="100%">
        <TextFieldWithError
          id="degreeLevel"
          name="degreeLevel"
          value={degreeLevel.degreeLevel}
          onChange={event => handleChange(event)}
          margin="normal"
          InputLabelProps={{ shrink: true }}
          label={`${entityLabel}*`}
          placeholder=""
          variant="outlined"
          validate={validateDegreeLevel}
          showValidationErrors={showValidationErrors}
        />
      </Column>
    </FormTop>
    <FormBottom>
      <CancelButton onClick={onFormClose} noHoverBg>
        Cancel
      </CancelButton>
      <SubmitButton onClick={onSubmit}>{buttonText}</SubmitButton>
    </FormBottom>
  </ElevatedForm>
);

DegreeLevelForm.propTypes = {
  degreeLevel: PropTypes.object,
  buttonText: PropTypes.string,
  entityLabel: PropTypes.string,
  showValidationErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  onSubmit: PropTypes.func,
  validateDegreeLevel: PropTypes.func.isRequired,
  onFormClose: PropTypes.func
};
DegreeLevelForm.defaultProps = {
  degreeLevel: {},
  buttonText: 'Create',
  entityLabel: 'Type',
  showValidationErrors: false,
  handleChange: undefined,
  onSubmit: undefined,
  onFormClose: undefined
};

export default DegreeLevelForm;
