import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';

import {
  doOpenNotification,
  doCloseNotification
} from '../../redux/actions/notificationActions';
import { types } from '../../redux/reducers/notificationReducer';
import Notification from './Notification';

class NotificationContainer extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  notificationColor = type => {
    let color;
    if (type === types.success) {
      color = this.props.theme.colors.primary;
    } else if (type === types.error) {
      color = this.props.theme.colors.red[100];
    } else if (type === types.info) {
      color = this.props.theme.colors.status.info;
    } else {
      color = this.props.theme.colors.primary;
    }
    return color;
  };

  handleClick = () => {
    this.props.onOpenNotification();
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.props.onCloseNotification();
  };

  render() {
    const { notifications } = this.props;
    return (
      <Notification
        notifications={notifications}
        open={notifications.open}
        handleClick={this.handleClick}
        handleClose={this.handleClose}
        type={notifications.type}
        notificationBg={this.notificationColor(notifications.type)}
      />
    );
  }
}

NotificationContainer.propTypes = {
  notifications: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  onOpenNotification: PropTypes.func,
  onCloseNotification: PropTypes.func
};

NotificationContainer.defaultProps = {
  onOpenNotification: undefined,
  onCloseNotification: undefined
};

const mapStateToProps = state => {
  const { notificationState } = state;
  return {
    notifications: notificationState
  };
};

const mapDispatchToProps = dispatch => ({
  onOpenNotification: () => dispatch(doOpenNotification()),
  onCloseNotification: () => dispatch(doCloseNotification())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(NotificationContainer));
export { NotificationContainer, mapStateToProps, mapDispatchToProps };
