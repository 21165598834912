import React from 'react';
import styled from 'styled-components';
import AddIcon from '@material-ui/icons/Add';
import {
  ButtonPrimary,
  ButtonOutline,
  ButtonInline,
  ButtonIcon,
  ButtonMenu,
  Heading1,
  Heading2,
  Heading3,
  Body1,
  Body2,
  Subtitle1,
  Subtitle2,
  ButtonText,
  Caption,
  CaptionSmall
} from '@xcomp/xcomp-design-library';

import Header from '../Header/Header';

import { Panel } from '../DefinitionDashboards/SharedSC/DashboardSC';

const Layout = styled.div`
  padding: 0 1.5rem;
`;
const PanelSC = styled(Panel)`
  padding: 0 1.5rem;
`;

const HeaderSC = styled(Header)`
  width: 100%;
  max-width: 1200px;
  display: block;
  margin: 0 auto;
`;

const HeaderLayout = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const HeaderText = styled.div`
  disply: flex;
  align-items: baseline;
`;

const Heading2SC = styled(Heading2)`
  display: inline-block;
  margin: 0;
  margin-right: 1.125rem;
`;

const Subtitle1SC = styled(Subtitle1)`
  display: inline-block;
  margin: 0;
`;

const StyledHR = styled.hr`
  margin: 2rem 0;
  border-top: 0.5px solid rgba(220, 220, 220);
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  button {
    margin-right: 1rem;
  }
`;

const IconButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  button {
    margin-right: 1rem;
  }
`;

const IconButtonContainer = styled.div`
  display: block;
  width: 100%;
  padding: 0.5rem 0;
`;

const AccreditationAgencyDashboard = () => (
  <Layout>
    <HeaderSC>
      <HeaderLayout>
        <HeaderText>
          <Heading2SC>Style Guide</Heading2SC>
          <Subtitle1SC>Some Subtitle</Subtitle1SC>
        </HeaderText>
      </HeaderLayout>
    </HeaderSC>
    <PanelSC>
      <Heading1>Heading 1</Heading1>
      <StyledHR />
      <Heading2>Heading 2</Heading2>
      <StyledHR />
      <Heading3>Heading 3</Heading3>
      <StyledHR />
      <Subtitle1>Subtitle 1</Subtitle1>
      <StyledHR />
      <Subtitle2>Subtitle 2</Subtitle2>
      <StyledHR />
      <Body1>Body 1</Body1>
      <StyledHR />
      <Body2>Body 2</Body2>
      <StyledHR />
      <ButtonText>Button Text</ButtonText>
      <StyledHR />
      <Caption>Caption</Caption>
      <StyledHR />
      <CaptionSmall>Caption Small</CaptionSmall>
      <StyledHR />
      <Heading1>Buttons</Heading1>
      <Heading3>Button Primary</Heading3>
      <ButtonsContainer>
        <ButtonPrimary>Button</ButtonPrimary>
        <ButtonMenu
          buttonText="Menu"
          type="primary"
          options={[
            {
              label: 'Option 1',
              onClick: () => console.log('clicked')
            },
            {
              label: 'Option 2',
              onClick: () => console.log('clicked')
            }
          ]}
        />
        <ButtonPrimary icon>
          <AddIcon />
          Add
        </ButtonPrimary>
      </ButtonsContainer>
      <StyledHR />
      <Heading3>Button Outline</Heading3>
      <ButtonsContainer>
        <ButtonOutline>Button</ButtonOutline>
        <ButtonMenu
          buttonText="Menu"
          type="outline"
          options={[
            {
              label: 'Option 1',
              onClick: () => console.log('clicked')
            },
            {
              label: 'Option 2',
              onClick: () => console.log('clicked')
            }
          ]}
        />

        <ButtonOutline icon>
          <AddIcon />
          Add
        </ButtonOutline>
      </ButtonsContainer>
      <StyledHR />
      <Heading3>Button Inline</Heading3>
      <ButtonsContainer>
        <ButtonInline>Button</ButtonInline>
        <ButtonInline noHoverBg>Button No Hover</ButtonInline>
      </ButtonsContainer>
      <StyledHR />
      <Heading3>Button Icon</Heading3>
      <IconButtonsContainer>
        <Subtitle1>Default</Subtitle1>
        <IconButtonContainer>
          <ButtonIcon>
            <AddIcon />
          </ButtonIcon>
        </IconButtonContainer>
        <Subtitle1>noHoverBg</Subtitle1>
        <IconButtonContainer>
          <ButtonIcon noHoverBg>
            <AddIcon />
          </ButtonIcon>
        </IconButtonContainer>
      </IconButtonsContainer>
    </PanelSC>
  </Layout>
);

export default AccreditationAgencyDashboard;
