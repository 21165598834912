import styled from "styled-components";
import {
  ButtonPrimary,
  Heading2,
  Subtitle1,
} from "@xcomp/xcomp-design-library";
import Header from "../../Header/Header";

export const HeaderSC = styled(Header)`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey[300]};
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  display: block;
  margin: 0 auto;
`;

export const HeaderLayout = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const HeaderText = styled.div`
  disply: flex;
  align-items: baseline;
`;

export const Heading2SC = styled(Heading2)`
  display: inline-block;
  margin: 0;
  margin-right: 1.125rem;
`;

export const Subtitle1SC = styled(Subtitle1)`
  display: inline-block;
  margin: 0;
`;

export const ButtonHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5rem 0 1rem 0;
`;

export const ButtonPrimarySC = styled(ButtonPrimary)`
  && {
    display: inline-block;
    margin-left: auto;
  }
`;

export const PageActions = styled.div`
  width: 100%;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
`;
