import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tooltip } from '@material-ui/core';

import { CharacterCount } from '@xcomp/xcomp-design-library';
import {
  validateCodeTitle,
  validateCodeDescription
} from '../../../../helpers/validateCodes';
import { convertNullToString } from '../../../../helpers/utilities';
import {
  Column,
  ElevatedForm,
  SubmitButton,
  CancelButton,
  TextFieldWithError
} from '../../../Forms/FormComponents';
import { FormTop } from '../../SharedSC/FormSC';

const FormBottom = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  #microDescription {
    line-height: 1.5;
    font-size: 0.9875rem;
  }
`;

const MicrocompetencyForm = ({
  code,
  micro,
  buttonText,
  showValidationErrors,
  handleChange,
  onSubmit,
  closeForm
}) => {
  const isBookendCode =
    micro.level_one_code === '00' || micro.level_one_code === '99';

  return (
    <ElevatedForm>
      <FormTop>
        <Column size="25%">
          <Tooltip title="Cannot edit generated microcompetency code">
            <TextFieldWithError
              id="microCode"
              name="code"
              value={code}
              onChange={event => handleChange(event)}
              margin="normal"
              InputLabelProps={{ shrink: true }}
              label="Microcompetency Code*"
              placeholder=""
              variant="outlined"
              disabled
              validate={undefined}
              showValidationErrors={false}
              paddingRight="1rem"
            />
          </Tooltip>
        </Column>
        <Column size="75%">
          {isBookendCode ? (
            <Tooltip title="Cannot edit title for general/other codes">
              <TextFieldWithError
                id="microTitle"
                name="title"
                value={micro.title}
                onChange={event => handleChange(event)}
                margin="normal"
                InputLabelProps={{ shrink: true }}
                label="Microcompetency Title*"
                placeholder=""
                variant="outlined"
                validate={validateCodeTitle}
                showValidationErrors={showValidationErrors}
                disabled
              />
            </Tooltip>
          ) : (
            <TextFieldWithError
              id="microTitle"
              name="title"
              value={micro.title}
              onChange={event => handleChange(event)}
              margin="normal"
              InputLabelProps={{ shrink: true }}
              label="Microcompetency Title*"
              placeholder=""
              variant="outlined"
              validate={validateCodeTitle}
              showValidationErrors={showValidationErrors}
            />
          )}
        </Column>
      </FormTop>
      <FormBottom>
        <TextFieldWithError
          id="microDescription"
          name="description"
          value={convertNullToString(micro.description)}
          onChange={event => handleChange(event)}
          multiline
          rows="2"
          rowsMax="10"
          margin="normal"
          InputLabelProps={{ shrink: true }}
          label="Description*"
          placeholder="Enter a description (3000 characters max)"
          variant="outlined"
          validate={validateCodeDescription}
          showValidationErrors={showValidationErrors}
        />
        <CharacterCount
          count={micro.description ? micro.description.length : 0}
          label="Count"
          maxCount={3000}
        />
        <CancelButton onClick={closeForm} noHoverBg>
          Cancel
        </CancelButton>
        <SubmitButton onClick={onSubmit}>{buttonText}</SubmitButton>
      </FormBottom>
    </ElevatedForm>
  );
};

MicrocompetencyForm.propTypes = {
  code: PropTypes.string,
  buttonText: PropTypes.string,
  micro: PropTypes.object,
  showValidationErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  onSubmit: PropTypes.func,
  closeForm: PropTypes.func
};
MicrocompetencyForm.defaultProps = {
  code: '',
  buttonText: 'Create',
  micro: {},
  showValidationErrors: false,
  handleChange: undefined,
  onSubmit: undefined,
  closeForm: undefined
};

export default MicrocompetencyForm;
