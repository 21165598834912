import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';

export const ormSelector = state => state.orm;

export const getDisciplineUuid = (state, props) => props.discipline.uuid;
export const getTopic = (state, props) => props.topic;

export const makeDisciplineTopicsSelector = () =>
  createSelector(
    state => state,
    getDisciplineUuid,
    (redux, discUuid) => {
      const selectorORM = ormCreateSelector([orm], session =>
        session.Topic.all()
          .filter(topic => topic.disciplineUuid === discUuid)
          .toModelArray()
          .map(topic => Object.assign({}, topic.ref))
      );
      return selectorORM(redux);
    }
  );

export const makeL2TopicsSelector = () =>
  createSelector(
    state => state,
    getTopic,
    (redux, thisTopic) => {
      const selectorORM = ormCreateSelector([orm], session =>
        session.Topic.all()
          .filter(
            topic =>
              topic.disciplineUuid === thisTopic.disciplineUuid &&
              topic.level_one_code === thisTopic.level_one_code &&
              topic.level_two_code !== thisTopic.level_two_code
          )
          .toModelArray()
          .map(topic => Object.assign({}, topic.ref))
      );
      return selectorORM(redux);
    }
  );
