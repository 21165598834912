import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeCipDisciplinesSelector } from '../../../../../redux/selectors/disciplineSelectors';

import CIPCode from './CIPCode';

const CIPCodeContainer = ({ cip, disciplines }) => (
  <CIPCode cip={cip} disciplines={disciplines} />
);

CIPCodeContainer.propTypes = {
  cip: PropTypes.object,
  disciplines: PropTypes.arrayOf(PropTypes.object)
};

CIPCodeContainer.defaultProps = {
  cip: {},
  disciplines: []
};

const makeMapStateToProps = () => {
  const getCipDisciplines = makeCipDisciplinesSelector();
  const mapStateToProps = (state, props) => ({
    disciplines: getCipDisciplines(state, props)
  });
  return mapStateToProps;
};

export default connect(
  makeMapStateToProps,
  null
)(CIPCodeContainer);
export { CIPCodeContainer, makeMapStateToProps };
