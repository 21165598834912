/* eslint no-unused-vars: off */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NewInstitutionSubtype from './NewInstitutionSubtype';
import { doPostStateProvince } from '../../../../../redux/actions/locationActions';
import { makeInstitutionSubtypesSelector } from '../../../../../redux/selectors/institutionTypesSelectors';
import {
  validateLocationName,
  validateStateProvinceCode
} from '../../../../../helpers/validateLocations';
import {
  allFieldsAreValid,
  validateEntityName
} from '../../../../../helpers/validateGeneric';
import { doPostInstitutionSubtype } from '../../../../../redux/actions/institutionTypeActions';

class NewInstitutionSubtypeContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      showValidationErrors: false
    };

    this.setNewInstitutionSubtype = this.setNewInstitutionSubtype.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCreateSubmission = this.handleCreateSubmission.bind(this);
  }

  componentDidMount() {
    const { code } = this.state;
    const { institutionSubtype } = this.props;
    this._isMounted = true;
    if (!code) {
      this.setNewInstitutionSubtype(institutionSubtype);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  setNewInstitutionSubtype = institutionSubtype => {
    const { isNew, ...newInstitutionSubtype } = institutionSubtype;
    if (this._isMounted) {
      this.setState(state => ({
        ...newInstitutionSubtype
      }));
    }
  };

  setValidationErrors = newInstitutionSubtype => {
    const { allInstitutionSubtypes } = this.props;
    const nameError = validateEntityName(
      newInstitutionSubtype.name,
      allInstitutionSubtypes
    );
    const newValidationErrors = {
      name: nameError
    };

    return newValidationErrors;
  };

  handleCreateSubmission = () => {
    const { showValidationErrors, ...newInstitutionSubtype } = this.state;

    const newValidationErrors = this.setValidationErrors(newInstitutionSubtype);

    if (allFieldsAreValid(newValidationErrors)) {
      this.props.onPostCreate(newInstitutionSubtype);
      this.setState({ showValidationErrors: false });
      this.props.clearNewForm();
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const { name, showValidationErrors } = this.state;
    const {
      institutionSubtype,
      allInstitutionSubtypes,
      clearNewForm
    } = this.props;

    const newInstitutionSubtype = {
      name
    };

    return (
      <NewInstitutionSubtype
        newInstitutionSubtype={newInstitutionSubtype}
        handleChange={this.handleChange}
        onPostCreate={this.handleCreateSubmission}
        clearNewForm={clearNewForm}
        allInstitutionSubtypes={allInstitutionSubtypes}
        showValidationErrors={showValidationErrors}
      />
    );
  }
}

NewInstitutionSubtypeContainer.propTypes = {
  institutionSubtype: PropTypes.object,
  allInstitutionSubtypes: PropTypes.arrayOf(PropTypes.object),
  onPostCreate: PropTypes.func,
  clearNewForm: PropTypes.func
};
NewInstitutionSubtypeContainer.defaultProps = {
  institutionSubtype: {},
  allInstitutionSubtypes: [],
  onPostCreate: undefined,
  clearNewForm: undefined
};

const makeMapStateToProps = () => {
  const getInstitutionSubtypes = makeInstitutionSubtypesSelector();
  const mapStateToProps = (state, props) => ({
    allInstitutionSubtypes: getInstitutionSubtypes(state, props)
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  onPostCreate: newInstitutionSubtype =>
    dispatch(doPostInstitutionSubtype(newInstitutionSubtype))
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(NewInstitutionSubtypeContainer);
export { NewInstitutionSubtypeContainer };
