import { Model, attr } from 'redux-orm';
import {
  GET_INSTITUTION_TYPES_SUCCESS,
  POST_INSTITUTION_TYPE_SUCCESS,
  PUT_INSTITUTION_TYPE_SUCCESS,
  PATCH_MOVE_INSTITUTION_TYPE_SUCCESS,
  DELETE_INSTITUTION_TYPE_SUCCESS
} from '../actions/actionTypes';

class InstitutionType extends Model {
  static reducer(action, InstitutionType, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_INSTITUTION_TYPES_SUCCESS: {
        InstitutionType.all().delete();
        const types = [...payload.institutionTypes];
        return types.map(type => {
          const typeUpsert = InstitutionType.upsert(type);
          return typeUpsert;
        });
      }
      case POST_INSTITUTION_TYPE_SUCCESS: {
        const { createdInstitutionType } = payload;
        return InstitutionType.upsert(createdInstitutionType);
      }
      case PUT_INSTITUTION_TYPE_SUCCESS: {
        const { updatedInstitutionType } = payload;
        return InstitutionType.upsert(updatedInstitutionType);
      }
      case PATCH_MOVE_INSTITUTION_TYPE_SUCCESS: {
        const types = [...payload.updatedTypes];
        return types.map(type => {
          const typeUpsert = InstitutionType.upsert(type);
          return typeUpsert;
        });
      }
      case DELETE_INSTITUTION_TYPE_SUCCESS: {
        const { deletedInstitutionType, updatedInstitutionTypes } = payload;
        // update order numbers
        updatedInstitutionTypes.map(type => {
          const thisType = InstitutionType.withId(type.uuid);
          const newOrder = thisType.order - 1;
          return thisType.set('order', newOrder);
        });
        return InstitutionType.withId(deletedInstitutionType.uuid).delete();
      }
      default:
        return session;
    }
  }
}

InstitutionType.fields = {
  uuid: attr(),
  name: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

InstitutionType.modelName = 'InstitutionType';
InstitutionType.options = {
  idAttribute: 'uuid'
};

export default InstitutionType;
