import { validateRequired } from './validateGeneric';

const validateAgencyType = agencyType => {
  const requiredError = validateRequired(agencyType);

  if (requiredError) {
    return requiredError;
  }

  if (agencyType !== 'INSTITUTION' && agencyType !== 'PROGRAM') {
    return {
      isFormatError: true,
      message: 'Agency must be of type INSTITUTION or PROGRAM'
    };
  }

  return false;
};

export { validateAgencyType };
