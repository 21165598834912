/* eslint-disable */
import React from 'react';
import { FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';

const withValidation = FieldComponent => ({
  value,
  values,
  name,
  select,
  userSelect,
  validate, // function to validate field with
  showValidationErrors, // bool for wether or not to show validation errors
  ...rest
}) => {
  if (!showValidationErrors && !userSelect) {
    return <FieldComponent value={value} name={name} {...rest} />;
  } else if (!showValidationErrors && userSelect) {
    return <FieldComponent values={values} name={name} {...rest} />;
  }

  const fieldValue = userSelect ? values : value;
  const fieldIsInvalid = validate(fieldValue);

  if (fieldIsInvalid && !select && !userSelect) {
    return (
      <FieldComponent
        error
        value={value}
        name={name}
        helperText={fieldIsInvalid.message}
        {...rest}
      />
    );
  } else if (fieldIsInvalid && select) {
    return (
      <FieldComponent
        error={fieldIsInvalid}
        value={value}
        name={name}
        helperText={fieldIsInvalid.message}
        {...rest}
      />
    );
  } else if (userSelect && fieldIsInvalid) {
    return (
      <FieldComponent error={true} values={values} name={name} {...rest}>
        <FormHelperText error>{fieldIsInvalid.message}</FormHelperText>
      </FieldComponent>
    );
  } else if (userSelect && !fieldIsInvalid) {
    return <FieldComponent values={values} name={name} {...rest} />;
  }
  return <FieldComponent value={value} name={name} {...rest} />;
};

withValidation.propTypes = {
  value: PropTypes.string,
  values: PropTypes.array,
  name: PropTypes.string,
  selected: PropTypes.bool,
  userSelect: PropTypes.string,
  showValidationErrors: PropTypes.bool,
  validate: PropTypes.func
};

withValidation.defaultProps = {
  value: '',
  values: [],
  name: '',
  selected: false,
  userSelect: '',
  showValidationErrors: false,
  validate: undefined
};

export default withValidation;
