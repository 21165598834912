import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AccreditationAgencyForm from '../../AccreditationAgencyForm';
import { validateEntityName } from '../../../../../helpers/validateGeneric';
import { RowSummary } from '../../../SharedSC/DashboardSC';

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const EditAccreditationAgency = ({
  existingAccreditationAgency,
  allAgencies,
  handleChange,
  onPostUpdate,
  showValidationErrors,
  toggleEditView
}) => (
  <Layout>
    <RowSummary>
      <AccreditationAgencyForm
        isEditForm
        agency={existingAccreditationAgency}
        entityLabel="Agency"
        buttonText="Update"
        handleChange={event => handleChange(event)}
        onSubmit={onPostUpdate}
        showValidationErrors={showValidationErrors}
        validateName={() =>
          validateEntityName(existingAccreditationAgency.name, allAgencies)
        }
        onFormClose={() => toggleEditView()}
      />
    </RowSummary>
  </Layout>
);

EditAccreditationAgency.propTypes = {
  existingAccreditationAgency: PropTypes.object,
  showValidationErrors: PropTypes.bool,
  allAgencies: PropTypes.arrayOf(PropTypes.object),
  handleChange: PropTypes.func,
  onPostUpdate: PropTypes.func,
  toggleEditView: PropTypes.func
};
EditAccreditationAgency.defaultProps = {
  existingAccreditationAgency: {},
  showValidationErrors: false,
  allAgencies: [],
  handleChange: undefined,
  onPostUpdate: undefined,
  toggleEditView: undefined
};

export default EditAccreditationAgency;
