import _ from 'lodash';
import { isURL } from 'validator';

const allFieldsAreValid = validationErrors => {
  const isValid = !Object.keys(validationErrors).some(
    key => validationErrors[key]
  );

  return isValid;
};

const validateRequired = textField => {
  const trimTextField =
    textField && typeof textField === 'string' ? textField.trim() : textField;
  if (!trimTextField || trimTextField === '' || trimTextField === 0) {
    return {
      isFormatError: false,
      message: 'Required'
    };
  }
  return false;
};

const validateUniqueEntity = (value, allSiblings, propertyName) => {
  const requiredError = validateRequired(value);
  let locationNameIsUnique = true;
  if (requiredError) {
    return requiredError;
  }

  allSiblings.forEach(sibling => {
    if (sibling[propertyName].toLowerCase() === value.toLowerCase()) {
      locationNameIsUnique = false;
    }
  });

  if (!locationNameIsUnique) {
    return {
      isFormatError: false,
      message: `${value} has already been used`
    };
  }
  return false;
};

const validateEntityName = (entityName, allSiblings) => {
  const requiredError = validateRequired(entityName);
  let entityNameIsUnique = true;
  if (requiredError) {
    return requiredError;
  }

  if (_.size(entityName) > 100) {
    return {
      isFormatError: true,
      message: 'Must be less than 100 characters'
    };
  }

  allSiblings.forEach(sibling => {
    if (sibling.name.toLowerCase() === entityName.toLowerCase()) {
      entityNameIsUnique = false;
    }
  });

  if (!entityNameIsUnique) {
    return {
      isFormatError: false,
      message: `${entityName} has already been used`
    };
  }
  return false;
};

const validateStringProperty = (entityName, allSiblings, propertyName) => {
  const requiredError = validateRequired(entityName);
  let entityNameIsUnique = true;
  if (requiredError) {
    return requiredError;
  }

  if (_.size(entityName) > 100) {
    return {
      isFormatError: true,
      message: 'Must be less than 100 characters'
    };
  }

  allSiblings.forEach(sibling => {
    if (sibling[propertyName].toLowerCase() === entityName.toLowerCase()) {
      entityNameIsUnique = false;
    }
  });

  if (!entityNameIsUnique) {
    return {
      isFormatError: false,
      message: `${entityName} has already been used`
    };
  }
  return false;
};

const validateSelectUserFieldMultiple = inputValue => {
  if (!inputValue || inputValue.length < 1) {
    return {
      isFormatError: false,
      message: 'Please select one'
    };
  }

  return false;
};

const validateStringLength = (string, length = 100, required = true) => {
  const requiredError = required ? validateRequired(string) : false;

  if (requiredError) {
    return requiredError;
  }

  if (!required && !string) {
    return false;
  }

  if (_.size(string) > length) {
    return {
      isFormatError: true,
      message: `Must be less than ${length} characters`
    };
  }

  return false;
};

const validateUrl = (url, required = true) => {
  const requiredError = required ? validateRequired(url) : false;

  if (requiredError) {
    return requiredError;
  }

  if (!required && !url) {
    return false;
  }

  const invalidUrlError = !isURL(url, {
    protocols: ['http', 'https'],
    require_protocol: true
  });

  if (invalidUrlError) {
    return {
      isFormatError: true,
      message: `Invalid URL, make sure to prepend http:// or https://`
    };
  }

  return false;
};

export {
  allFieldsAreValid,
  validateRequired,
  validateUniqueEntity,
  validateEntityName,
  validateStringProperty,
  validateSelectUserFieldMultiple,
  validateStringLength,
  validateUrl
};
