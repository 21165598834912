import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RelatedProgramSearch from './RelatedProgramSearch';
import { doSearchProgramCode } from '../../../../../redux/actions/programCodeActions';

class RelatedProgramSearchContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      showResults: false,
      showSearchField: false
    };
  }

  handleKeyDown = e => {
    if (e.key === 'Enter') {
      this.onSearchClick();
    }
  };

  handleChange = event => {
    this.setState({
      searchTerm: event.target.value
    });
  };

  onSearchClick = () => {
    const { searchTerm } = this.state;
    if (searchTerm && searchTerm.length > 0) {
      this.props.onSearch(searchTerm);
    }
    this.setState({
      showResults: searchTerm && searchTerm.length > 0
    });
  };

  onResultSelect = program => {
    const { handleRelatedProgramAdd } = this.props;
    handleRelatedProgramAdd(program);
    this.setState({
      showSearchField: false,
      searchTerm: '',
      showResults: false
    });
  };

  onShowSearchField = () => {
    this.setState({
      showSearchField: true
    });
  };

  render() {
    const {
      searchResults,
      isLoading,
      label,
      thisProgram,
      placeholder,
      fieldName,
      children
    } = this.props;
    return (
      <RelatedProgramSearch
        thisProgram={thisProgram}
        onSearchClick={this.onSearchClick}
        searchResults={searchResults}
        isLoading={isLoading}
        searchTerm={this.state.searchTerm}
        showResults={this.state.showResults}
        showSearchField={this.state.showSearchField}
        handleChange={this.handleChange}
        onResultSelect={this.onResultSelect}
        onShowSearchField={this.onShowSearchField}
        handleKeyDown={this.handleKeyDown}
        fieldName={fieldName}
        label={label}
        placeholder={placeholder}
      >
        {children && children}
      </RelatedProgramSearch>
    );
  }
}

RelatedProgramSearchContainer.propTypes = {
  thisProgram: PropTypes.object.isRequired,
  searchResults: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  fieldName: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  children: PropTypes.node,
  onSearch: PropTypes.func,
  handleRelatedProgramAdd: PropTypes.func
};

RelatedProgramSearchContainer.defaultProps = {
  searchResults: null,
  isLoading: false,
  fieldName: 'searchTerm',
  label: '',
  placeholder: '',
  children: null,
  onSearch: undefined,
  handleRelatedProgramAdd: undefined
};

const mapStateToProps = state => ({
  searchResults: state.searchState.programCodeSearchResults,
  isLoading: state.searchState.isLoading
});

const mapDispatchToProps = dispatch => ({
  onSearch: searchTerm => dispatch(doSearchProgramCode(searchTerm))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RelatedProgramSearchContainer);
export { RelatedProgramSearchContainer };
