import { Model, attr, fk } from 'redux-orm';
import {
  GET_MICROCOMPETENCIES_SUCCESS,
  POST_MICROCOMPETENCY_SUCCESS,
  PUT_MICROCOMPETENCY_SUCCESS,
  DELETE_MICROCOMPETENCY_SUCCESS,
  DELETE_TOPIC_SUCCESS,
  DELETE_SUBTOPIC_SUCCESS,
  DELETE_DISCIPLINE_SUCCESS,
  DELETE_CIP_CODE_SUCCESS,
  PUT_SUBTOPIC_SUCCESS,
  PUT_TOPIC_SUCCESS,
  PATCH_MOVE_MICROCOMPETENCY_SUCCESS,
  PATCH_PUBLISH_MICROCOMPETENCY_SUCCESS
} from '../actions/actionTypes';

class Microcompetency extends Model {
  static reducer(action, Microcompetency, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_MICROCOMPETENCIES_SUCCESS: {
        const microcompetencies = [...payload.microcompetencies];
        return microcompetencies.map(microcompetency => {
          const microcompetencyUpsert = Microcompetency.upsert(microcompetency);
          return microcompetencyUpsert;
        });
      }
      case POST_MICROCOMPETENCY_SUCCESS: {
        const newMicro = { ...payload.createdMicrocompetency };
        return Microcompetency.upsert(newMicro);
      }
      case PUT_MICROCOMPETENCY_SUCCESS: {
        const updatedMicro = { ...payload.updatedMicrocompetency };
        return Microcompetency.upsert(updatedMicro);
      }
      case PUT_SUBTOPIC_SUCCESS: {
        const updatedMicros = [...payload.updatedMicros];
        return updatedMicros.map(microcompetency => {
          const microcompetencyUpsert = Microcompetency.upsert(microcompetency);
          return microcompetencyUpsert;
        });
      }
      case PUT_TOPIC_SUCCESS: {
        const updatedMicros = [...payload.updatedMicros];
        return updatedMicros && updatedMicros.length > 0
          ? updatedMicros.map(microcompetency => {
              const microcompetencyUpsert = Microcompetency.upsert(
                microcompetency
              );
              return microcompetencyUpsert;
            })
          : false;
      }
      case PATCH_MOVE_MICROCOMPETENCY_SUCCESS: {
        const { movedCode, updatedCodes } = payload.updatedMicrocompetencies;
        Microcompetency.upsert(movedCode);
        return updatedCodes && updatedCodes.length > 0
          ? updatedCodes.map(microcompetency => {
              const microcompetencyUpsert = Microcompetency.upsert(
                microcompetency
              );
              return microcompetencyUpsert;
            })
          : false;
      }
      case PATCH_PUBLISH_MICROCOMPETENCY_SUCCESS: {
        const { publishedMicro } = payload;
        return Microcompetency.upsert(publishedMicro);
      }
      case DELETE_MICROCOMPETENCY_SUCCESS: {
        const {
          deletedMicrocompetencyUuid,
          updatedMicrocompetencies
        } = payload;
        Microcompetency.withId(deletedMicrocompetencyUuid).delete();
        return updatedMicrocompetencies.map(micro => {
          const microcompetencyUpsert = Microcompetency.upsert(micro);
          return microcompetencyUpsert;
        });
      }
      case DELETE_TOPIC_SUCCESS: {
        const { deletedTopic } = payload;
        return (
          deletedTopic.subtopics &&
          deletedTopic.subtopics.length > 0 &&
          deletedTopic.subtopics.map(subtopic =>
            Microcompetency.all()
              .filter(micro => {
                if (
                  !micro.subtopicUuid ||
                  micro.subtopicUuid === subtopic.uuid
                ) {
                  return micro;
                }
                return false;
              })
              .delete()
          )
        );
      }
      case DELETE_SUBTOPIC_SUCCESS: {
        const { deletedSubtopic } = payload;
        return Microcompetency.all()
          .filter(micro => {
            if (
              !micro.subtopicUuid ||
              micro.subtopicUuid === deletedSubtopic.uuid
            ) {
              return micro;
            }
            return false;
          })
          .delete();
      }
      case DELETE_DISCIPLINE_SUCCESS: {
        const { deletedDiscipline } = payload;
        return (
          deletedDiscipline.topics &&
          deletedDiscipline.topics.length > 0 &&
          deletedDiscipline.topics.map(topic => {
            if (topic.subtopics && topic.subtopics.length > 0) {
              topic.subtopics.map(subtopic => {
                const deletedMicros = Microcompetency.all()
                  .filter(micro => {
                    if (
                      !micro.subtopicUuid ||
                      micro.subtopicUuid === subtopic.uuid
                    ) {
                      return micro;
                    }
                    return false;
                  })
                  .delete();

                return deletedMicros;
              });
            }
            return false;
          })
        );
      }
      case DELETE_CIP_CODE_SUCCESS: {
        const { deletedCipcode } = payload;
        return (
          deletedCipcode.disciplines &&
          deletedCipcode.disciplines.length > 0 &&
          deletedCipcode.disciplines.map(discipline => {
            if (discipline.topics && discipline.topics.length > 0) {
              discipline.topics.map(topic => {
                if (topic.subtopics && topic.subtopics.length > 0) {
                  topic.subtopics.map(subtopic => {
                    const deletedMicros = Microcompetency.all()
                      .filter(micro => {
                        if (
                          !micro.subtopicUuid ||
                          micro.subtopicUuid === subtopic.uuid
                        ) {
                          return micro;
                        }
                        return false;
                      })
                      .delete();

                    return deletedMicros;
                  });
                  return false;
                }
                return false;
              });
              return false;
            }
            return false;
          })
        );
      }
      default:
        return session;
    }
  }
}

Microcompetency.fields = {
  uuid: attr(),
  title: attr(),
  code: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  subtopicUuid: fk('Subtopic', 'microcompetencies')
};

Microcompetency.modelName = 'Microcompetency';
Microcompetency.options = {
  idAttribute: 'uuid'
};

export default Microcompetency;
