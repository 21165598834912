import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { orm } from '../models';

export const ormSelector = state => state.orm;

// export const disciplinesSelector = createSelector(
//   ormSelector,
//   ormCreateSelector(orm, session =>
//     session.Discipline.all()
//       .toModelArray()
//       .map(disc => Object.assign({}, disc.ref))
//   )
// );

export const disciplinesSelector = createSelector(
  state => state,
  redux => {
    const selectorORM = ormCreateSelector([orm], session =>
      session.Discipline.all()
        .toModelArray()
        .map(disc => Object.assign({}, disc.ref))
    );
    return selectorORM(redux);
  }
);

export const getCipUuid = (state, props) => props.cip.uuid;

export const makeCipDisciplinesSelector = () =>
  createSelector(
    state => state,
    getCipUuid,
    (redux, cipUuid) => {
      const selectorORM = ormCreateSelector([orm], session =>
        session.Discipline.all()
          .filter(disc => disc.cipcodeUuid === cipUuid)
          .toModelArray()
          .map(disc => Object.assign({}, disc.ref))
      );
      return selectorORM(redux);
    }
  );
