import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Column,
  ElevatedForm,
  CancelButton,
  SubmitButton,
  TextFieldWithError
} from '../../Forms/FormComponents';
import { FormTop, FormBottom } from '../SharedSC/FormSC';

const FormTopSC = styled(FormTop)`
  margin-bottom: 1.5rem;
`;

const LocationForm = ({
  location,
  entityLabel,
  buttonText,
  handleChange,
  onSubmit,
  showValidationErrors,
  validateCode,
  validateName,
  closeForm
}) => (
  <ElevatedForm>
    <FormTopSC>
      <Column size="20%">
        <TextFieldWithError
          id="locationCode"
          name="code"
          value={location.code}
          onChange={event => handleChange(event)}
          margin="normal"
          InputLabelProps={{ shrink: true }}
          label={`${entityLabel} Code*`}
          placeholder=""
          variant="outlined"
          validate={validateCode}
          showValidationErrors={showValidationErrors}
          paddingRight="1rem"
        />
      </Column>
      <Column size="80%">
        <TextFieldWithError
          id="locationName"
          name="name"
          value={location.name}
          onChange={event => handleChange(event)}
          margin="normal"
          InputLabelProps={{ shrink: true }}
          label={`${entityLabel} Code*`}
          placeholder=""
          variant="outlined"
          validate={validateName}
          showValidationErrors={showValidationErrors}
        />
      </Column>
    </FormTopSC>
    <FormBottom>
      <CancelButton onClick={closeForm} noHoverBg>
        Cancel
      </CancelButton>
      <SubmitButton onClick={onSubmit}>{buttonText}</SubmitButton>
    </FormBottom>
  </ElevatedForm>
);

LocationForm.propTypes = {
  location: PropTypes.object,
  buttonText: PropTypes.string,
  entityLabel: PropTypes.string,
  showValidationErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  onSubmit: PropTypes.func,
  validateCode: PropTypes.func.isRequired,
  validateName: PropTypes.func.isRequired,
  closeForm: PropTypes.func
};
LocationForm.defaultProps = {
  location: {},
  buttonText: 'Create',
  entityLabel: 'Country',
  showValidationErrors: false,
  handleChange: undefined,
  onSubmit: undefined,
  closeForm: undefined
};

export default LocationForm;
