import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ButtonInline } from '@xcomp/xcomp-design-library';
import SimpleModal from '../SimpleModal';

const ModalBodySC = styled.div`
  width: 100%;
  padding: 1rem 1.25rem;

  h3 {
    margin-bottom: 0.5rem;
  }
`;

const ModalButtonsSC = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const DeleteModal = ({
  modalOpen,
  handleModalClose,
  handleDelete,
  type,
  className
}) => (
  <div className={className}>
    <SimpleModal open={modalOpen} onClose={handleModalClose}>
      <ModalBodySC>
        <div>
          <h3>Are you sure you want to delete this {type.toLowerCase()}?</h3>
          <em>This cannot be reversed.</em>
        </div>
        <ModalButtonsSC>
          <ButtonInline onClick={() => handleModalClose()}>Cancel</ButtonInline>
          <ButtonInline onClick={() => handleDelete()}>
            Delete {type}
          </ButtonInline>
        </ModalButtonsSC>
      </ModalBodySC>
    </SimpleModal>
  </div>
);

DeleteModal.propTypes = {
  modalOpen: PropTypes.bool,
  handleModalClose: PropTypes.func,
  handleDelete: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string
};

DeleteModal.defaultProps = {
  modalOpen: false,
  handleModalClose: undefined,
  handleDelete: undefined,
  type: '',
  className: ''
};

export default DeleteModal;
export { ModalButtonsSC };
