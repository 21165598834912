import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AccordionRow from './AccordionRow';

class AccordionRowContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRowOpen: false
    };
    this.toggleOpenRow = this.toggleOpenRow.bind(this);
  }

  componentDidUpdate = prevProps => {
    if (
      this.props.shouldStartOpen &&
      prevProps.shouldStartOpen !== this.props.shouldStartOpen &&
      !this.state.isRowOpen
    ) {
      this.toggleOpenRow();
    }
  };

  toggleOpenRow = () => {
    const { onRowOpen } = this.props;
    if (onRowOpen) {
      onRowOpen();
    }
    this.setState(prevState => ({
      isRowOpen: !prevState.isRowOpen
    }));
  };

  render() {
    const {
      actions,
      children,
      className,
      label,
      columnOne,
      columnTwo,
      columnTwoClassName,
      columnOneClassName,
      expandWidth,
      height,
      rowOffset,
      columnGutters,
      dragHandleProps,
      leaf,
      disabled,
      isSelected,
      isPublished,
      isTopLevel,
      isDragEnabled,
      isDragging,
      isDraggingOver,
      onSelect,
      onShow,
      onPublish
    } = this.props;
    const { isRowOpen } = this.state;
    return (
      <AccordionRow
        actions={actions}
        className={className}
        columnOneClassName={columnOneClassName}
        columnTwoClassName={columnTwoClassName}
        isRowOpen={isRowOpen}
        isSelected={isSelected}
        isPublished={isPublished}
        isTopLevel={isTopLevel}
        isDragEnabled={isDragEnabled}
        isDragging={isDragging}
        isDraggingOver={isDraggingOver}
        dragHandleProps={dragHandleProps}
        label={label}
        columnOne={columnOne}
        columnTwo={columnTwo}
        expandWidth={expandWidth}
        height={height}
        rowOffset={rowOffset}
        columnGutters={columnGutters}
        leaf={leaf}
        disabled={disabled}
        onPublish={onPublish}
        onShow={onShow}
        onSelect={onSelect}
        toggleOpenRow={() => this.toggleOpenRow()}
      >
        {children}
      </AccordionRow>
    );
  }
}

AccordionRowContainer.propTypes = {
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.bool
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  label: PropTypes.string,
  columnOne: PropTypes.string,
  columnTwo: PropTypes.string,
  columnOneClassName: PropTypes.string,
  columnTwoClassName: PropTypes.string,
  expandWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  columnGutters: PropTypes.string,
  height: PropTypes.string,
  rowOffset: PropTypes.string,
  dragHandleProps: PropTypes.object,
  leaf: PropTypes.bool,
  disabled: PropTypes.bool,
  shouldStartOpen: PropTypes.bool,
  isDragging: PropTypes.bool,
  isDragEnabled: PropTypes.bool,
  isDraggingOver: PropTypes.bool,
  isSelected: PropTypes.bool,
  isPublished: PropTypes.bool,
  isTopLevel: PropTypes.bool,
  onPublish: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onRowOpen: PropTypes.func,
  onSelect: PropTypes.func,
  onShow: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
};

AccordionRowContainer.defaultProps = {
  actions: false,
  children: <div />,
  className: '',
  label: '',
  columnOne: '',
  columnTwo: '',
  columnOneClassName: '',
  columnTwoClassName: '',
  columnGutters: '0.5rem',
  height: '43px',
  rowOffset: '0',
  expandWidth: false,
  dragHandleProps: {},
  leaf: false,
  disabled: false,
  shouldStartOpen: false,
  isDragEnabled: false,
  isDragging: false,
  isDraggingOver: false,
  isPublished: false,
  isSelected: false,
  isTopLevel: false,
  onPublish: false,
  onRowOpen: undefined,
  onSelect: undefined,
  onShow: false
};

export default AccordionRowContainer;
