import { sortItemsByLevelCodes } from './arrayHelpers';

export const convertNullToString = value => (!value ? '' : value);
export const convertNullToArray = value => (!value ? [] : value);
export const convertNullToZero = value => (!value ? 0 : value);

export const generateNewCode = (
  parentLevelOneCode,
  codeArray,
  isLevelTwo,
  levelOneCode
) => {
  // parentLevelOneCode - string(2)
  // codeArray - array
  // isLevelTwo - boolean
  // levelOneCode - string, L1 code of parent (optional)

  if (parentLevelOneCode === '99') {
    return {
      // return error if parent code is 99
      error: true,
      message: 'children codes cannnot be created under 9999 (other) codes'
    };
  }

  let lastLevelOneCode = '00';
  let lastLevelTwoCode = '00';
  let newLevelOne = '01';
  let newLevelTwo = '00';
  const sortedArray = codeArray.sort(sortItemsByLevelCodes);

  if (codeArray.length === 1) {
    return {
      // only 1 (0000 or 9999) -> 0100
      level_one_code: '01',
      level_two_code: '00'
    };
  }

  if (codeArray.length === 2) {
    if (
      codeArray[0].level_one_code === '00' &&
      codeArray[1].level_one_code === '99'
    ) {
      return {
        // only 2 (0000 & 9999) -> 0100
        level_one_code: '01',
        level_two_code: '00'
      };
    } else {
      // only 2 (0000 || 9999, 0100) && level2 -> 0101
      // only 2 (0000 || 9999, 0100) -> 0200
      // IF you are here and one of the codes is not 0100, there is a problem with the codes
      if (
        codeArray[0].level_one_code !== '01' &&
        codeArray[1].level_one_code !== '01'
      ) {
        return {
          error: true,
          message:
            'current codes are in an illegal state, please contact support to reconcile data'
        };
      }
      return isLevelTwo
        ? {
            // only 2 (0000 || 9999, #) && level2 -> 0101
            level_one_code: '01',
            level_two_code: '01'
          }
        : {
            // only 2 (0000 || 9999, #) -> 0200
            level_one_code: '02',
            level_two_code: '00'
          };
    }
  }
  // 3 or more items
  if (!isLevelTwo) {
    // find unique level_one_code from the codeArray
    const uniqueL1 = [
      ...new Set(codeArray.map(codeOne => codeOne.level_one_code))
    ];
    if (uniqueL1.length >= 99) {
      return {
        error: true,
        message: 'number of level one codes already reached'
      };
    }
    // last item is 9999 -> lastLevelOneCode = array[length-2].level_one_code;
    // last item != 9999 -> lastLevelOneCode = array[length-1].level_one_code;
    const lastLevelOneCodeInArray =
      sortedArray[sortedArray.length - 1].level_one_code;

    lastLevelOneCode =
      lastLevelOneCodeInArray === '99'
        ? sortedArray[sortedArray.length - 2].level_one_code
        : lastLevelOneCodeInArray;

    // if lastLevelOneCode === 98 return error, no more level one codes can be created for this parent
    if (lastLevelOneCode === '98') {
      return {
        error: true,
        message: 'maximum level one codes created'
      };
    }
    newLevelOne = (Number(lastLevelOneCode) + 1).toString().padStart(2, '0');
  } else {
    // levelTwo
    // if levelOneCode === 00 || 99 return error, cannot create level two codes in general or other buckets
    if (levelOneCode === '00' || levelOneCode === '99') {
      return {
        error: true,
        message: 'cannot create level two codes for 0000 or 9999 codes'
      };
    }
    const filteredArray = sortedArray.filter(
      // only have items in array that are same level 1 code
      code => code.level_one_code === levelOneCode
    );
    // make sure no duplicate level_two_code within the same level_one_code
    const uniqueL2 = [
      ...new Set(filteredArray.map(codeTwo => codeTwo.level_two_code))
    ];

    if (filteredArray.length !== uniqueL2.length) {
      return {
        error: true,
        message: 'level two codes contains duplicate'
      };
    }

    if (uniqueL2.length > 99) {
      return {
        error: true,
        message: 'number of level two codes already reached'
      };
    }
    // if last level 2 code is 99, return error, no more level 2 codes can be created for this level one code
    lastLevelTwoCode = filteredArray[filteredArray.length - 1].level_two_code;
    if (lastLevelTwoCode === '99') {
      return {
        error: true,
        message: 'maximuml number of level 2 codes created'
      };
    }
    newLevelOne = levelOneCode;
    newLevelTwo = (Number(lastLevelTwoCode) + 1).toString().padStart(2, '0');
  }

  return {
    level_one_code: newLevelOne,
    level_two_code: newLevelTwo
  };
};

export const generateNewTopicCode = (codeArray, isLevelTwo, levelOneCode) => {
  // codeArray - array
  // isLevelTwo - boolean
  // levelOneCode - string, L1 code of parent (optional)

  let lastLevelOneCode = '00';
  let lastLevelTwoCode = '00';
  let newLevelOne = '01';
  let newLevelTwo = '00';

  if (!codeArray || codeArray.length < 1) {
    return {
      level_one_code: newLevelOne,
      level_two_code: newLevelTwo
    };
  }
  const sortedArray = codeArray.sort(sortItemsByLevelCodes);

  // 1 or more items
  if (!isLevelTwo) {
    // find unique level_obe_code from the codeArray
    const uniqueL1 = [
      ...new Set(codeArray.map(codeOne => codeOne.level_one_code))
    ];
    if (uniqueL1.length >= 99) {
      return {
        error: true,
        message: 'number of level one codes already reached'
      };
    }
    // get last L1 code and increment
    const lastLevelOneCodeInArray =
      sortedArray[sortedArray.length - 1].level_one_code;

    lastLevelOneCode =
      lastLevelOneCodeInArray === '99'
        ? sortedArray[sortedArray.length - 2].level_one_code
        : lastLevelOneCodeInArray; // TODO: might not need this code

    // if lastLevelOneCode === 98 return error, no more level one codes can be created for this parent
    if (lastLevelOneCode === '99') {
      return {
        error: true,
        message: 'maximum level one codes created'
      };
    }
    newLevelOne = (Number(lastLevelOneCode) + 1).toString().padStart(2, '0');
  } else {
    // levelTwo
    // if levelOneCode === 00 || 99 return error, cannot create level two codes in general or other buckets
    if (levelOneCode === '00' || levelOneCode === '99') {
      return {
        // TODO: might not need this code
        error: true,
        message: 'cannot create level two codes for 0000 or 9999 codes'
      };
    }

    const filteredArray = sortedArray.filter(
      // only have items in array that are same level 1 code
      code => code.level_one_code === levelOneCode
    );
    // make sure no duplicate level_two_code within the same level_one_code
    const uniqueL2 = [
      ...new Set(filteredArray.map(codeTwo => codeTwo.level_two_code))
    ];

    if (filteredArray.length !== uniqueL2.length) {
      return {
        error: true,
        message: 'level two codes contains duplicate'
      };
    }
    if (uniqueL2.length > 99) {
      return {
        error: true,
        message: 'number of level two codes already reached'
      };
    }
    // if last level 2 code is 99, return error, no more level 2 codes can be created for this level one code
    lastLevelTwoCode = filteredArray[filteredArray.length - 1].level_two_code;
    if (lastLevelTwoCode === '99') {
      return {
        error: true,
        message: 'maximuml number of level 2 codes created'
      };
    }
    newLevelOne = levelOneCode;
    newLevelTwo = (Number(lastLevelTwoCode) + 1).toString().padStart(2, '0');
  }
  return {
    level_one_code: newLevelOne,
    level_two_code: newLevelTwo
  };
};

// sort by name
export const sortByProperty = (a, b, propertyName) => {
  const propertyA =
    typeof a[propertyName] === 'string'
      ? a[propertyName].toUpperCase()
      : a[propertyName]; // ignore upper and lowercase
  const propertyB =
    typeof b[propertyName] === 'string'
      ? b[propertyName].toUpperCase()
      : b[propertyName]; // ignore upper and lowercase
  if (propertyA < propertyB) {
    return -1;
  }
  if (propertyA > propertyB) {
    return 1;
  }

  // names must be equal
  return 0;
};
