import {
  GET_CIP_CODES,
  GET_CIP_CODES_SUCCESS,
  GET_CIP_CODES_ERROR,
  POST_CIP_CODE,
  POST_CIP_CODE_SUCCESS,
  POST_CIP_CODE_ERROR,
  PUT_CIP_CODE,
  PUT_CIP_CODE_SUCCESS,
  PUT_CIP_CODE_ERROR,
  DELETE_CIP_CODE,
  DELETE_CIP_CODE_SUCCESS,
  DELETE_CIP_CODE_ERROR,
  CLEAR_CIP_CODES
} from './actionTypes';

const doGetCips = () => ({
  type: GET_CIP_CODES
});

const doGetCipsSuccess = data => ({
  type: GET_CIP_CODES_SUCCESS,
  payload: {
    cips: data.cips,
    disciplines: data.disciplines
  }
});

const doGetCipsError = error => ({
  type: GET_CIP_CODES_ERROR,
  payload: {
    error
  }
});

const doPostCipcode = newCipcode => ({
  type: POST_CIP_CODE,
  payload: {
    newCipcode
  }
});

const doPostCipcodeSuccess = data => ({
  type: POST_CIP_CODE_SUCCESS,
  payload: {
    createdCipcode: data.createdCipcode
  }
});

const doPostCipcodeError = error => ({
  type: POST_CIP_CODE_ERROR,
  payload: {
    error
  }
});

const doPutCipcode = cipcodeUpdates => ({
  type: PUT_CIP_CODE,
  payload: {
    cipcodeUpdates
  }
});

const doPutCipcodeSuccess = data => ({
  type: PUT_CIP_CODE_SUCCESS,
  payload: {
    updatedCipcode: data.updatedCipcode
  }
});

const doPutCipcodeError = error => ({
  type: PUT_CIP_CODE_ERROR,
  payload: {
    error
  }
});

const doDeleteCipcode = cipcodeUuid => ({
  type: DELETE_CIP_CODE,
  payload: {
    cipcodeUuid
  }
});

const doDeleteCipcodeSuccess = data => ({
  type: DELETE_CIP_CODE_SUCCESS,
  payload: {
    deletedCipcode: data.deletedCipcode
  }
});

const doDeleteCipcodeError = error => ({
  type: DELETE_CIP_CODE_ERROR,
  payload: {
    error
  }
});

const doClearCips = () => ({
  type: CLEAR_CIP_CODES
});

export {
  doGetCips,
  doGetCipsSuccess,
  doGetCipsError,
  doPostCipcode,
  doPostCipcodeSuccess,
  doPostCipcodeError,
  doPutCipcode,
  doPutCipcodeSuccess,
  doPutCipcodeError,
  doDeleteCipcode,
  doDeleteCipcodeSuccess,
  doDeleteCipcodeError,
  doClearCips
};
