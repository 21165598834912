import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NewCIPCode from './NewCIPCode';
import { doPostCipcode } from '../../../../../redux/actions/cipCodeActions';
import { cipsSelector } from '../../../../../redux/selectors/cipsSelectors';
import {
  validateCodeTitle,
  validateCipcode
} from '../../../../../helpers/validateCodes';
import { allFieldsAreValid } from '../../../../../helpers/validateGeneric';

class NewCIPCodeContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      code: '',
      title: '',
      showValidationErrors: false
    };

    this.setNewCipcode = this.setNewCipcode.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCipSubmission = this.handleCipSubmission.bind(this);
  }

  componentDidMount() {
    const { code } = this.state;
    const { cip } = this.props;
    this._isMounted = true;
    if (!code) {
      this.setNewCipcode(cip);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  setNewCipcode = cip => {
    const { isNew, ...newCip } = cip;
    if (this._isMounted) {
      this.setState(() => ({
        ...newCip
      }));
    }
  };

  setValidationErrors = newCipcode => {
    const { allCipcodes } = this.props;
    const titleError = validateCodeTitle(newCipcode.title);
    const codeError = validateCipcode(newCipcode.code, allCipcodes);
    const newValidationErrors = {
      title: titleError,
      code: codeError
    };

    return newValidationErrors;
  };

  handleCipSubmission = () => {
    const { showValidationErrors, ...newCip } = this.state;

    const newValidationErrors = this.setValidationErrors(newCip);

    if (allFieldsAreValid(newValidationErrors)) {
      this.props.onPostCreateCip(newCip);
      this.setState({ showValidationErrors: false });
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const { code, title, showValidationErrors } = this.state;
    const { allCipcodes, clearNewCip } = this.props;

    const newCip = {
      code,
      title
    };

    return (
      <NewCIPCode
        newCip={newCip}
        handleChange={this.handleChange}
        onPostCreateCip={this.handleCipSubmission}
        clearNewCip={clearNewCip}
        allCipcodes={allCipcodes}
        showValidationErrors={showValidationErrors}
      />
    );
  }
}

NewCIPCodeContainer.propTypes = {
  cip: PropTypes.object,
  allCipcodes: PropTypes.arrayOf(PropTypes.object),
  onPostCreateCip: PropTypes.func,
  clearNewCip: PropTypes.func
};
NewCIPCodeContainer.defaultProps = {
  cip: {},
  allCipcodes: [],
  onPostCreateCip: undefined,
  clearNewCip: undefined
};

const mapStateToProps = (state, props) => ({
  allCipcodes: cipsSelector(state, props)
});

const mapDispatchToProps = dispatch => ({
  onPostCreateCip: newCip => dispatch(doPostCipcode(newCip))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewCIPCodeContainer);
export { NewCIPCodeContainer };
