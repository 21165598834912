import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Body1 } from '@xcomp/xcomp-design-library';
import InstitutionalSystemContainer from '../InstitutionalSystem/InstitutionalSystemContainer';
import AccordionRowContainer from '../../../AccordionRow/AccordionRowContainer';

const NoSystemsMessage = styled(({ rowOffset, ...rest }) => <div {...rest} />)`
  margin-left: ${props => props.rowOffset};
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  font-weight: 300;
`;

const Body1SC = styled(Body1)`
  margin-top: 0;
`;

const InstitutionSubtype = ({
  institutionSubtype,
  stateProvince,
  institutionalSystems,
  className
}) => {
  const unaffiliatedSystemFind = institutionalSystems.find(
    system => system.name.toLowerCase() === 'unaffiliated'
  );
  const unaffiliatedSystem = unaffiliatedSystemFind || {
    name: 'Unaffiliated',
    stateProvinceUuid: stateProvince.uuid,
    institutionSubtypeUuid: institutionSubtype.uuid,
    isUncreatedSystem: true
  };
  const otherSystems = institutionalSystems.filter(
    system => system.name.toLowerCase() !== 'unaffiliated'
  );
  return (
    <AccordionRowContainer
      className={className}
      label="Subtype"
      columnTwo={institutionSubtype.name}
      expandWidth="42px"
      height="38px"
      rowOffset="120px"
      columnGutters="0.75rem"
    >
      <InstitutionalSystemContainer
        key={unaffiliatedSystem.uuid}
        institutionalSystem={unaffiliatedSystem}
        stateProvinceUuid={stateProvince.uuid}
        institutionSubtypeUuid={institutionSubtype.uuid}
        isUnaffiliated
      />
      {otherSystems && otherSystems.length > 0 ? (
        otherSystems.map(system => (
          <InstitutionalSystemContainer
            key={system.uuid}
            institutionalSystem={system}
            stateProvinceUuid={stateProvince.uuid}
            institutionSubtypeUuid={institutionSubtype.uuid}
          />
        ))
      ) : (
        <NoSystemsMessage rowOffset="157px">
          <Body1SC>
            No systems exist for this State/Province and institution subtype
          </Body1SC>
        </NoSystemsMessage>
      )}
    </AccordionRowContainer>
  );
};

InstitutionSubtype.propTypes = {
  institutionSubtype: PropTypes.object.isRequired,
  stateProvince: PropTypes.object.isRequired,
  institutionalSystems: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string
};

InstitutionSubtype.defaultProps = {
  className: '',
  institutionalSystems: []
};

export default InstitutionSubtype;
