import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CIPCodeForm from '../CIPCodeForm';
import { RowSummary } from '../../../SharedSC/DashboardSC';

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const NewCIPCode = ({
  newCip,
  allCipcodes,
  showValidationErrors,
  handleChange,
  onPostCreateCip,
  clearNewCip
}) => (
  <Layout>
    <RowSummary>
      <CIPCodeForm
        cip={newCip}
        buttonText="Create"
        showValidationErrors={showValidationErrors}
        handleChange={event => handleChange(event)}
        onSubmit={onPostCreateCip}
        closeForm={clearNewCip}
        allCipcodes={allCipcodes}
      />
    </RowSummary>
  </Layout>
);

NewCIPCode.propTypes = {
  newCip: PropTypes.object,
  allCipcodes: PropTypes.arrayOf(PropTypes.object),
  showValidationErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  onPostCreateCip: PropTypes.func,
  clearNewCip: PropTypes.func
};
NewCIPCode.defaultProps = {
  newCip: {},
  allCipcodes: [],
  showValidationErrors: false,
  handleChange: undefined,
  onPostCreateCip: undefined,
  clearNewCip: undefined
};

export default NewCIPCode;
export { CIPCodeForm };
