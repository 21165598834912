import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DisciplineForm from '../DisciplineForm';
import { RowSummary } from '../../../SharedSC/DashboardSC';
import { validateStringProperty } from '../../../../../helpers/validateGeneric';
import { validateDisciplineCode } from '../../../../../helpers/validateCodes';

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const NewDiscipline = ({
  cipcode,
  newDiscipline,
  allProgramDisciplines,
  showValidationErrors,
  handleChange,
  onPostCreate,
  clearNew
}) => (
  <Layout>
    <RowSummary>
      <DisciplineForm
        cipcode={cipcode}
        discipline={newDiscipline}
        buttonText="Create"
        showValidationErrors={showValidationErrors}
        validateCode={() =>
          validateDisciplineCode(newDiscipline.code, allProgramDisciplines, 2)
        }
        validateTitle={title =>
          validateStringProperty(title, allProgramDisciplines, 'title')
        }
        handleChange={event => handleChange(event)}
        onSubmit={onPostCreate}
        closeForm={clearNew}
      />
    </RowSummary>
  </Layout>
);

NewDiscipline.propTypes = {
  cipcode: PropTypes.object.isRequired,
  newDiscipline: PropTypes.object,
  allProgramDisciplines: PropTypes.arrayOf(PropTypes.object),
  showValidationErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  onPostCreate: PropTypes.func,
  clearNew: PropTypes.func
};
NewDiscipline.defaultProps = {
  newDiscipline: {},
  allProgramDisciplines: [],
  showValidationErrors: false,
  handleChange: undefined,
  onPostCreate: undefined,
  clearNew: undefined
};

export default NewDiscipline;
