/* eslint no-unused-vars: off */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EditCipcode from './EditCipcode';
import { programCipcodesSelector } from '../../../../../redux/selectors/programCipcodeSelectors';
import { doPutProgramCipcode } from '../../../../../redux/actions/programCipcodeActions';
import { validateCipcode } from '../../../../../helpers/validateCodes';
import {
  validateStringProperty,
  allFieldsAreValid
} from '../../../../../helpers/validateGeneric';

class EditCipcodeContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      uuid: '',
      code: '',
      title: '',
      showValidationErrors: false
    };

    this.setExisting = this.setExisting.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  componentDidMount() {
    const { code } = this.state;
    const { cipcode } = this.props;
    this._isMounted = true;
    if (!code) {
      this.setExisting(cipcode);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  setExisting = cipcode => {
    const { ...existingCipcode } = cipcode;
    if (this._isMounted) {
      this.setState(state => ({
        ...existingCipcode
      }));
    }
  };

  setValidationErrors = existingCipcode => {
    const { allCipcodes } = this.props;
    const { uuid } = this.state;
    const siblingCipcodes = allCipcodes.filter(
      cipcode => cipcode.uuid !== uuid
    );
    const titleError = validateStringProperty(
      existingCipcode.title,
      siblingCipcodes,
      'title'
    );
    const codeError = validateCipcode(existingCipcode.code, siblingCipcodes);
    const newValidationErrors = {
      title: titleError,
      code: codeError
    };

    return newValidationErrors;
  };

  handleUpdate = () => {
    const { showValidationErrors, ...existingCipcode } = this.state;
    const newValidationErrors = this.setValidationErrors(existingCipcode);

    if (allFieldsAreValid(newValidationErrors)) {
      const payload = {
        uuid: existingCipcode.uuid,
        title: existingCipcode.title,
        code: existingCipcode.code
      };
      this.props.onPutUpdate(payload);
      this.setState({ showValidationErrors: false });
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const { code, title, uuid, showValidationErrors } = this.state;
    const { cipcode, allCipcodes, toggleEditView } = this.props;

    const thisCipcode = {
      uuid,
      code,
      title
    };

    const siblingCipcodes = allCipcodes.filter(
      cipcode => cipcode.uuid !== thisCipcode.uuid
    );

    return (
      <EditCipcode
        existingCipcode={thisCipcode}
        cipcode={cipcode}
        allCipcodes={siblingCipcodes}
        handleChange={this.handleChange}
        onPutUpdate={this.handleUpdate}
        showValidationErrors={showValidationErrors}
        toggleEditView={toggleEditView}
      />
    );
  }
}

EditCipcodeContainer.propTypes = {
  cipcode: PropTypes.object,
  allCipcodes: PropTypes.arrayOf(PropTypes.object),
  toggleEditView: PropTypes.func,
  onPutUpdate: PropTypes.func
};
EditCipcodeContainer.defaultProps = {
  cipcode: {},
  allCipcodes: [],
  toggleEditView: undefined,
  onPutUpdate: undefined
};

const mapStateToProps = (state, props) => ({
  allCipcodes: programCipcodesSelector(state, props)
});

const mapDispatchToProps = dispatch => ({
  onPutUpdate: updates => dispatch(doPutProgramCipcode(updates))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCipcodeContainer);
export { EditCipcodeContainer };
