import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { sortByProperty } from '../../helpers/utilities';
import { orm } from '../models';

export const ormSelector = state => state.orm;

// export const institutionIdTypesSelector = createSelector(
//   ormSelector,
//   ormCreateSelector(orm, session =>
//     session.InstitutionIdType.all()
//       .toModelArray()
//       .map(idType => Object.assign({}, idType.ref))
//       .sort((a, b) => sortByProperty(a, b, 'name'))
//   )
// );

export const institutionIdTypesSelector = createSelector(
  state => state,
  redux => {
    const idTypesSelcotorORM = ormCreateSelector(orm.InstitutionIdType);

    const idTypes = idTypesSelcotorORM(redux);
    const sortedIdTypes = idTypes.sort((a, b) => sortByProperty(a, b, 'name'));
    return sortedIdTypes;
  }
);
