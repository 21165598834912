import React from 'react';
import styled from 'styled-components';
import { Heading2 } from '@xcomp/xcomp-design-library/dist/components/Typography';

import SimpleModal from '../../DialogModal/SimpleModal';

export const SimpleModalSC = styled(SimpleModal)`
  width: 100%;
`;

export const ModalHeaderText = styled(Heading2)`
  margin: 0;
`;

export const ModalBody = styled.div`
  width: 100%;
  button {
    display: block;
    margin-left: auto;
    margin-right: 0;
  }
`;

export const FormLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
  padding: 1.5rem 2rem;
`;

export const Divider = styled.hr`
  border: 0;
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
  width: 100%;
  padding: 0;
  margin: 0 0 2rem 0;
`;

export const FormRow = styled(({ marginBottom, ...rest }) => <div {...rest} />)`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '2rem')};
`;
