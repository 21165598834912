import React from 'react';
import styled, { css } from 'styled-components';
import { TextField } from '@material-ui/core';
import { ButtonInline } from '@xcomp/xcomp-design-library';
import withValidation from '../FieldWithError/FieldWithError';

const ModalHeader = styled.div`
  width: 100%;
  height: 66px;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const Column = styled(({ size, paddingRight, paddingLeft, ...rest }) => (
  <div {...rest} />
))`
  width: ${props => props.size};
  font-size: inherit;

  ${props =>
    props.paddingRight &&
    css`
      padding-right: ${props => props.paddingRight};
    `}

  ${props =>
    props.paddingLeft &&
    css`
      padding-left: ${props => props.paddingLeft};
    `}

  svg {
    color: ${props => props.theme.colors.grey[700]};
  }
  &.italic {
    font-style: italic;
  }
`;

const ElevatedForm = styled.div`
  padding: 1.5rem 1rem 1rem 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  box-shadow: ${props => props.theme.boxShadows.high};
  border-left: 6px solid ${props => props.theme.colors.primary};
  border-radius: 3px;
  z-index: 1000;
`;

const SubmitButton = styled(ButtonInline)`
  && {
    margin-left: 1rem;
  }
`;

const CancelButton = styled(ButtonInline)`
  && {
    color: ${props => props.theme.colors.grey[600]};
  }
`;

const TextFieldSC = styled(({ paddingRight, ...rest }) => (
  <TextField {...rest} />
))`
  && {
    width: 100%;
    margin: auto;
    padding-right: ${props =>
      props.paddingRight ? props.paddingRight : 'initial'};

    input {
      font-weight: 400;
    }
    & > div:before {
      border-bottom: 0;
    }
  }
`;

const TextFieldWithError = withValidation(TextFieldSC);

export {
  Column,
  ElevatedForm,
  SubmitButton,
  CancelButton,
  TextFieldSC,
  TextFieldWithError,
  ModalHeader
};
