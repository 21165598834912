import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { Body1 } from '@xcomp/xcomp-design-library';
import DeleteModal from '../../../DialogModal/DeleteModal/DeleteModal';
import AccordionWithBoldCode from '../../../AccordionRow/AccordionRowSC';
import SubtopicContainer from '../Subtopic/SubtopicContainer';
import { sortItemsByLevelCodes } from '../../../../helpers/arrayHelpers';
import NewSubtopicContainer from '../Subtopic/NewSubtopic/NewSubtopicContainer';
import EditTopicContainer from './EditTopic/EditTopicContainer';

const Layout = styled.div`
  width: 100%;
`;

const NoSubtopicsMessage = styled(({ rowOffset, ...rest }) => (
  <div {...rest} />
))`
  margin-left: ${props => props.rowOffset};
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  font-weight: 300;
`;

const Body1SC = styled(Body1)`
  margin-top: 0;
`;

const Topic = ({
  view,
  topic,
  subtopics,
  discipline,
  cip,
  isDeleteModalOpen,
  addSubtopic,
  addTopic,
  handleDeleteClick,
  handleDeleteModalClose,
  handleDelete,
  getSubtopics,
  toggleEditView,
  openMoveModal,
  clearNewSubtopic
}) => {
  const cipCode = cip.code;
  const disciplineCode = discipline.code;
  const topicCode = `${topic.level_one_code}${topic.level_two_code}`;
  const isCodeBold =
    topic.level_two_code === '00' && topic.level_one_code !== '00';

  const fullCode = `${cipCode}.${disciplineCode}.${topicCode}`;
  const parentCodeString = `${cipCode}.${disciplineCode}`;
  const someUuid = uuidv4();

  const topicActions = [
    {
      label: 'Add Topic L1',
      onClick: () => addTopic(discipline.uuid, false, false),
      shouldOpenOnClick: false,
      icon: 'add'
    },
    {
      label: 'Add Topic L2',
      onClick: () => addTopic(discipline.uuid, true, topic.level_one_code),
      shouldOpenOnClick: false,
      icon: 'add'
    },
    {
      label: 'Add Subtopic',
      onClick: () => addSubtopic(topic.uuid, false, false),
      shouldOpenOnClick: true,
      icon: 'add'
    },
    {
      label: 'Edit',
      onClick: toggleEditView,
      shouldOpenOnClick: false,
      icon: 'edit'
    },
    {
      label: 'Move',
      onClick: () => openMoveModal(topic.uuid, fullCode, parentCodeString),
      shouldOpenOnClick: false,
      icon: 'move'
    },
    {
      label: 'Delete',
      onClick: handleDeleteClick,
      shouldOpenOnClick: false,
      icon: 'delete'
    }
  ];
  return (
    <Layout>
      <DeleteModal
        modalOpen={isDeleteModalOpen}
        handleModalClose={() => handleDeleteModalClose()}
        handleDelete={() => handleDelete()}
        type="Topic"
      />
      {view === 'EDIT' ? (
        <EditTopicContainer
          cip={cip}
          discipline={discipline}
          topic={topic}
          key={topic.uuid}
          toggleEditView={toggleEditView}
        />
      ) : (
        <AccordionWithBoldCode
          label="Topic"
          columnOne={fullCode}
          columnTwo={topic.title}
          actions={topicActions}
          expandWidth="42px"
          height="38px"
          rowOffset="90px"
          onRowOpen={() => getSubtopics(topic.uuid)}
          isCodeBold={isCodeBold}
        >
          {subtopics && subtopics.length > 0 ? (
            subtopics
              .sort(sortItemsByLevelCodes)
              .map(subtopic =>
                !subtopic.isNew ? (
                  <SubtopicContainer
                    cip={cip}
                    discipline={discipline}
                    topic={topic}
                    subtopic={subtopic}
                    key={subtopic.uuid}
                    addSubtopic={addSubtopic}
                  />
                ) : (
                  <NewSubtopicContainer
                    cip={cip}
                    discipline={discipline}
                    topic={topic}
                    subtopic={subtopic}
                    key={someUuid}
                    clearNewSubtopic={clearNewSubtopic}
                  />
                )
              )
          ) : (
            <NoSubtopicsMessage rowOffset="172px">
              <Body1SC>No subtopics exist for this topic</Body1SC>
            </NoSubtopicsMessage>
          )}
        </AccordionWithBoldCode>
      )}
    </Layout>
  );
};

Topic.propTypes = {
  subtopics: PropTypes.arrayOf(PropTypes.object),
  topic: PropTypes.object,
  discipline: PropTypes.object,
  cip: PropTypes.object,
  addTopic: PropTypes.func,
  view: PropTypes.oneOf(['', 'EDIT']),
  isDeleteModalOpen: PropTypes.bool,
  handleDeleteClick: PropTypes.func,
  handleDeleteModalClose: PropTypes.func,
  handleDelete: PropTypes.func,
  getSubtopics: PropTypes.func,
  addSubtopic: PropTypes.func,
  clearNewSubtopic: PropTypes.func,
  openMoveModal: PropTypes.func,
  toggleEditView: PropTypes.func
};

Topic.defaultProps = {
  subtopics: [],
  topic: {},
  discipline: {},
  cip: {},
  view: '',
  isDeleteModalOpen: false,
  handleDeleteClick: undefined,
  handleDeleteModalClose: undefined,
  handleDelete: undefined,
  addTopic: undefined,
  getSubtopics: undefined,
  addSubtopic: undefined,
  clearNewSubtopic: undefined,
  openMoveModal: undefined,
  toggleEditView: undefined
};

export default Topic;
