import { isAlphanumeric } from 'validator';
import { validateRequired } from './validateGeneric';

const validateInstitutionCode = (code, required = true) => {
  const requiredError = required ? validateRequired(code) : false;

  if (requiredError) {
    return requiredError;
  }

  if (!required && !code) {
    return false;
  }

  const invalideCode = !isAlphanumeric(code) || code.length > 13;

  if (invalideCode) {
    return {
      isFormatError: true,
      message: `Letters and numbers only, 13 maximum`
    };
  }

  return false;
};

export { validateInstitutionCode };
