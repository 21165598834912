import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import SearchIcon from '@material-ui/icons/Search';
import { ButtonIcon, ButtonInline } from '@xcomp/xcomp-design-library';
import { sortByProperty } from '../../../../../helpers/utilities';

import { TextFieldSC } from '../../../../Forms/FormComponents';

const Layout = styled.div`
  position: relative;
  z-index: 100;
`;

const SearchButton = styled(ButtonIcon)`
  && {
    position: absolute;
    right: 24px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    z-index: 300;

    &:hover,
    &:focus,
    &:active {
      background: 0;
    }
  }
`;

const Wrapper = styled.div`
  position: absolute;
  top: 58px;
  left: 0;
  right: 0;
  width: 100%;
  padding-right: 1rem;
`;

const SearchResults = styled.div`
  && {
    width: 100%;
    max-height: 200px;
    overflow: scroll;
    background: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.grey[300]};
  }
`;

const SearchResultsItem = styled.div`
  && {
    width: 100%;
    padding: 0.5rem 1.25rem;

    &:hover {
      background-color: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.white};
    }
  }
`;

const NoResultsMessage = styled.div`
  && {
    width: 100%;
    padding: 0.5rem 1.25rem;
  }
`;

const RelatedProgramSearch = ({
  thisProgram,
  onSearchClick,
  searchResults,
  isLoading,
  searchTerm,
  showResults,
  showSearchField,
  handleChange,
  onResultSelect,
  onShowSearchField,
  handleKeyDown,
  fieldName,
  label,
  placeholder,
  children
}) => {
  const relatedProgramUuids = thisProgram.relatedPrograms.map(
    program => program.uuid
  );

  const filteredSearchResults =
    searchResults && searchResults.length > 0
      ? searchResults.filter(program => {
          if (program.uuid === thisProgram.uuid) {
            return false;
          }
          if (_.includes(relatedProgramUuids, program.uuid)) {
            return false;
          }
          return true;
        })
      : [];

  const options =
    filteredSearchResults && filteredSearchResults.length > 0
      ? filteredSearchResults
          .map(program => {
            return {
              value: program.uuid,
              label: program.label
            };
          })
          .sort((a, b) => sortByProperty(a, b, 'label'))
      : [];

  const noRelatedPrograms =
    !thisProgram.relatedPrograms || thisProgram.relatedPrograms.length < 0;

  return showSearchField || noRelatedPrograms ? (
    <Layout>
      <SearchButton noHoverBg onClick={onSearchClick}>
        <SearchIcon />
      </SearchButton>
      <TextFieldSC
        id={fieldName}
        name={fieldName}
        label={label}
        placeholder={placeholder}
        value={searchTerm}
        onChange={event => handleChange(event)}
        onKeyDown={event => handleKeyDown(event)}
        margin="normal"
        variant="outlined"
      />
      {showResults && (
        <Wrapper>
          {isLoading ? (
            <SearchResults>
              <NoResultsMessage>Loading...</NoResultsMessage>
            </SearchResults>
          ) : (
            <SearchResults>
              {options && options.length > 0 ? (
                options.map(option => {
                  return (
                    <SearchResultsItem
                      key={option.value}
                      onClick={() => onResultSelect(option)}
                    >
                      {option.label}
                    </SearchResultsItem>
                  );
                })
              ) : (
                <NoResultsMessage>No results</NoResultsMessage>
              )}
            </SearchResults>
          )}
        </Wrapper>
      )}
      {children}
    </Layout>
  ) : (
    <ButtonInline onClick={onShowSearchField}>Add Related Program</ButtonInline>
  );
};

RelatedProgramSearch.propTypes = {
  thisProgram: PropTypes.object.isRequired,
  searchResults: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  showResults: PropTypes.bool,
  showSearchField: PropTypes.bool,
  searchTerm: PropTypes.string,
  fieldName: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  children: PropTypes.node,
  handleChange: PropTypes.func,
  handleKeyDown: PropTypes.func,
  onShowSearchField: PropTypes.func,
  onResultSelect: PropTypes.func,
  onSearchClick: PropTypes.func
};

RelatedProgramSearch.defaultProps = {
  searchResults: null,
  isLoading: false,
  showResults: false,
  showSearchField: false,
  searchTerm: '',
  fieldName: 'searchTerm',
  label: 'Search Related Programs',
  placeholder: 'Search',
  children: null,
  handleChange: undefined,
  handleKeyDown: undefined,
  onShowSearchField: undefined,
  onResultSelect: undefined,
  onSearchClick: undefined
};

export default RelatedProgramSearch;
