/* eslint arrow-body-style: off, array-callback-return: off, no-unused-expressions: off */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Subtopic from './Subtopic';
import { makeMicrocompetenciesSelector } from '../../../../../redux/selectors/microcompetencySelectors';
import { doGetMicrocompetencies } from '../../../../../redux/actions/microcompetencyActions';
import { types } from '../../../../../redux/reducers/moveCodeReducer';
import { doSetMoveCodeDestination } from '../../../../../redux/actions/moveCodeActions';

class SubtopicContainer extends Component {
  constructor(props) {
    super(props);

    this.getSubtopicMicros = this.getSubtopicMicros.bind(this);
  }

  getSubtopicMicros = subtopicUuid => {
    const { microcompetencies } = this.props;
    if (!microcompetencies || microcompetencies.length < 1) {
      this.props.onFetchMicros(subtopicUuid);
    }
  };

  render() {
    const {
      microcompetencies,
      discipline,
      cip,
      topic,
      subtopic,
      codeType,
      codeToMove,
      destinationCode
    } = this.props;
    const selectableMicros = microcompetencies.filter(
      code => code.level_two_code === '00'
    );
    return (
      <Subtopic
        microcompetencies={selectableMicros}
        subtopic={subtopic}
        topic={topic}
        discipline={discipline}
        cip={cip}
        codeType={codeType}
        codeToMove={codeToMove}
        getSubtopicMicros={this.getSubtopicMicros}
        onCodeSelect={this.props.onSetMoveCodeDestination}
        destinationCode={destinationCode}
      />
    );
  }
}

SubtopicContainer.propTypes = {
  subtopic: PropTypes.object,
  topic: PropTypes.object,
  discipline: PropTypes.object,
  cip: PropTypes.object,
  codeToMove: PropTypes.string,
  codeType: PropTypes.oneOf([types.topic, types.subtopic, types.micro, '']),
  destinationCode: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  onFetchMicros: PropTypes.func,
  microcompetencies: PropTypes.arrayOf(PropTypes.object),
  onSetMoveCodeDestination: PropTypes.func
};

SubtopicContainer.defaultProps = {
  subtopic: {},
  topic: {},
  discipline: {},
  cip: {},
  codeToMove: '',
  codeType: '',
  destinationCode: false,
  onFetchMicros: undefined,
  microcompetencies: [],
  onSetMoveCodeDestination: undefined
};

const makeMapStateToProps = () => {
  const getSubtopicMicros = makeMicrocompetenciesSelector();
  const mapStateToProps = (state, props) => ({
    microcompetencies: getSubtopicMicros(state, props),
    codeType: state.moveCodeState.codeType,
    destinationCode: state.moveCodeState.destination,
    codeToMove: state.moveCodeState.codeToMove
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  onFetchMicros: subtopicUuid => dispatch(doGetMicrocompetencies(subtopicUuid)),
  onSetMoveCodeDestination: (
    subtopicUuid,
    fullCode,
    isGeneralCode,
    isOtherCode
  ) =>
    dispatch(
      doSetMoveCodeDestination(
        subtopicUuid,
        fullCode,
        isGeneralCode,
        isOtherCode
      )
    )
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(SubtopicContainer);
export { SubtopicContainer, mapDispatchToProps };
