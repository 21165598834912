import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import {
  AdditionalReadingIcon,
  CategoryIcon,
  DegreeIcon,
  InstituteIdIcon,
  ListIcon,
  LocationIcon,
  SchoolIcon,
  UsersIcon
} from '@xcomp/xcomp-design-library/dist/icons';

import { Body1 } from '@xcomp/xcomp-design-library/dist/components/Typography';

const Item = styled(({ drawerOpen, isCurrentPage, ...rest }) => (
  <div {...rest} />
))`
  && {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    padding-left: 15px;
    svg {
      fill: ${props => props.theme.colors.grey[600]}
    }

    ${props =>
      props.isCurrentPage &&
      css`
        border-left: 3px solid ${props => props.theme.colors.primary}
          background-color: ${props => props.theme.colors.primaryShades[50]};

          svg {
            fill: ${props => props.theme.colors.primary}
          }
          p {
            font-weight: bold;
            color: ${props => props.theme.colors.primary}
          }
      `}

    &:hover,
    &:focus,
    &:active {
      border-left: 3px solid ${props => props.theme.colors.primary}
      background-color: ${props => props.theme.colors.primaryShades[50]};

      svg {
        fill: ${props => props.theme.colors.primary}
      }
      p {
        color: ${props => props.theme.colors.primary}
      }
    }
  }
`;

const ItemText = styled(({ drawerOpen, ...rest }) => <Body1 {...rest} />)`
  margin-left: 12px;
  opacity: ${props => (props.drawerOpen ? '1' : '0')};
  transition: opacity 0.4s ease-in;
  transition-delay: 0.2s;
`;

const Icon = styled.div``;

const getIcon = icon => {
  if (icon === 'additionalReading') {
    return <AdditionalReadingIcon />;
  } else if (icon === 'category') {
    return <CategoryIcon />;
  } else if (icon === 'degree') {
    return <DegreeIcon />;
  } else if (icon === 'instituteId') {
    return <InstituteIdIcon />;
  } else if (icon === 'list') {
    return <ListIcon />;
  } else if (icon === 'location') {
    return <LocationIcon />;
  } else if (icon === 'school') {
    return <SchoolIcon />;
  } else if (icon === 'users') {
    return <UsersIcon />;
  }
};

const NavigationItem = ({ navItem, drawerOpen, location }) => {
  const isCurrentPage = navItem.route === location.pathname;
  return (
    <Link to={navItem.route}>
      <Item drawerOpen={drawerOpen} isCurrentPage={isCurrentPage}>
        <Icon>{getIcon(navItem.icon)}</Icon>
        <ItemText drawerOpen={drawerOpen}>
          {drawerOpen && navItem.label}
        </ItemText>
      </Item>
    </Link>
  );
};

NavigationItem.propTypes = {
  drawerOpen: PropTypes.bool,
  navItem: PropTypes.object,
  location: PropTypes.object
};

NavigationItem.defaultProps = {
  drawerOpen: false,
  navItem: {},
  location: {}
};
export default NavigationItem;
