import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DegreeLevelForm from '../../DegreeLevelForm';
import { validateStringProperty } from '../../../../../helpers/validateGeneric';
import { RowSummary } from '../../../SharedSC/DashboardSC';

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const EditDegreeLevel = ({
  existingDegreeLevel,
  allDegreeLevels,
  handleChange,
  onPutUpdate,
  showValidationErrors,
  toggleEditView
}) => (
  <Layout>
    <RowSummary>
      <DegreeLevelForm
        isEditForm
        degreeLevel={existingDegreeLevel}
        entityLabel="Degree Level"
        buttonText="Update"
        handleChange={event => handleChange(event)}
        onSubmit={onPutUpdate}
        showValidationErrors={showValidationErrors}
        validateDegreeLevel={() =>
          validateStringProperty(
            existingDegreeLevel.degreeLevel,
            allDegreeLevels,
            'degreeLevel'
          )
        }
        onFormClose={() => toggleEditView()}
      />
    </RowSummary>
  </Layout>
);

EditDegreeLevel.propTypes = {
  existingDegreeLevel: PropTypes.object,
  showValidationErrors: PropTypes.bool,
  allDegreeLevels: PropTypes.arrayOf(PropTypes.object),
  handleChange: PropTypes.func,
  onPutUpdate: PropTypes.func,
  toggleEditView: PropTypes.func
};
EditDegreeLevel.defaultProps = {
  existingDegreeLevel: {},
  showValidationErrors: false,
  allDegreeLevels: [],
  handleChange: undefined,
  onPutUpdate: undefined,
  toggleEditView: undefined
};

export default EditDegreeLevel;
