import fetch from "../helpers/fetch";

const getCipcodes = () =>
  fetch('/api/cipcodes/disciplines', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      }
      throw new Error(`(status code: ${res.status})`);
    })
    .catch(error => {
      throw error;
    });

const postCipcode = newCipcode =>
  fetch(`/api/cipcode/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(newCipcode)
  })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      }
      throw new Error(`(status code: ${res.status})`);
    })
    .catch(error => {
      throw error;
    });

const putCipcode = ({ uuid, ...cipcodeUpdates }) =>
  fetch(`/api/cipcode/${uuid}/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(cipcodeUpdates)
  })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      }
      throw new Error(`(status code: ${res.status})`);
    })
    .catch(error => {
      throw error;
    });

const deleteCipcode = cipcodeUuid =>
  fetch(`/api/cipcode/${cipcodeUuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({})
  }).then(res => {
    if (res.status === 200) {
      return res.json();
    } else {
      throw res;
    }
  });

export { getCipcodes, postCipcode, putCipcode, deleteCipcode };
