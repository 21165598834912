import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import scrollToComponent from 'react-scroll-to-component';
import NewAccreditationAgency from './NewAccreditationAgency';
import { doPostAccreditationAgency } from '../../../../../redux/actions/accreditationAgencyActions';
import { allAccreditationAgenciesSelector } from '../../../../../redux/selectors/accreditationAgenciesSelectors';
import {
  validateEntityName,
  allFieldsAreValid
} from '../../../../../helpers/validateGeneric';
import { validateAgencyType } from '../../../../../helpers/validateAgencies';

class NewAccreditationAgencyContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      agencyType: '',
      showValidationErrors: false
    };
    this.setNewAccreditationAgency = this.setNewAccreditationAgency.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCreateSubmission = this.handleCreateSubmission.bind(this);
  }

  componentDidMount() {
    const { name } = this.state;
    const { accreditationAgency } = this.props;
    this._isMounted = true;
    if (!name) {
      this.setNewAccreditationAgency(accreditationAgency);
    }

    scrollToComponent(this.Compref, {
      offset: -100,
      align: 'top',
      duration: 500,
      ease: 'inCirc'
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  setNewAccreditationAgency = accreditationAgency => {
    const { isNew, ...newAccreditationAgency } = accreditationAgency;
    if (this._isMounted) {
      this.setState(() => ({
        ...newAccreditationAgency
      }));
    }
  };

  setValidationErrors = newAccreditationAgency => {
    const { allAccreditationAgencies } = this.props;
    const nameError = validateEntityName(
      newAccreditationAgency.name,
      allAccreditationAgencies
    );
    const typeError = validateAgencyType(newAccreditationAgency.agencyType);
    const newValidationErrors = {
      name: nameError,
      agencyType: typeError
    };

    return newValidationErrors;
  };

  handleCreateSubmission = () => {
    const { showValidationErrors, ...newAccreditationAgency } = this.state;

    const newValidationErrors = this.setValidationErrors(
      newAccreditationAgency
    );

    if (allFieldsAreValid(newValidationErrors)) {
      this.props.onPostCreate(newAccreditationAgency);
      this.setState({ showValidationErrors: false });
      this.props.clearNewForm();
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const { name, agencyType, showValidationErrors } = this.state;
    const { allAccreditationAgencies, clearNewForm } = this.props;

    const newAccreditationAgency = {
      name,
      agencyType
    };

    return (
      <NewAccreditationAgency
        className="compref"
        refProp={section => {
          this.Compref = section;
        }}
        newAccreditationAgency={newAccreditationAgency}
        handleChange={this.handleChange}
        onPostCreate={this.handleCreateSubmission}
        clearNewForm={clearNewForm}
        allAccreditationAgencies={allAccreditationAgencies}
        showValidationErrors={showValidationErrors}
      />
    );
  }
}

NewAccreditationAgencyContainer.propTypes = {
  accreditationAgency: PropTypes.object,
  allAccreditationAgencies: PropTypes.arrayOf(PropTypes.object),
  onPostCreate: PropTypes.func,
  clearNewForm: PropTypes.func
};
NewAccreditationAgencyContainer.defaultProps = {
  accreditationAgency: {},
  allAccreditationAgencies: [],
  onPostCreate: undefined,
  clearNewForm: undefined
};

const mapStateToProps = (state, props) => ({
  allAccreditationAgencies: allAccreditationAgenciesSelector(state, props)
});

const mapDispatchToProps = dispatch => ({
  onPostCreate: newAgency => dispatch(doPostAccreditationAgency(newAgency))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewAccreditationAgencyContainer);
export { NewAccreditationAgencyContainer };
