import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EditInstitutionIdType from './EditInstitutionIdType';
import { institutionIdTypesSelector } from '../../../../../redux/selectors/institutionIdTypeSelectors';
import { doPutInstitutionIdType } from '../../../../../redux/actions/institutionIdTypeActions';
import {
  allFieldsAreValid,
  validateStringProperty
} from '../../../../../helpers/validateGeneric';

class EditInstitutionIdTypeContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      uuid: '',
      name: '',
      showValidationErrors: false
    };
  }

  componentDidMount() {
    const { institutionIdType } = this.props;
    this._isMounted = true;
    if (!this.state.name) {
      this.setExistingState(institutionIdType);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  setExistingState = institutionIdType => {
    if (this._isMounted) {
      this.setState(() => ({
        ...institutionIdType
      }));
    }
  };

  setValidationErrors = existingInstitutionIdType => {
    const { allInstitutionIdTypes } = this.props;
    const { uuid } = this.state;
    const siblings = allInstitutionIdTypes.filter(
      idType => idType.uuid !== uuid
    );
    const nameError = validateStringProperty(
      existingInstitutionIdType.name,
      siblings,
      'name'
    );
    const newValidationErrors = {
      name: nameError
    };

    return newValidationErrors;
  };

  hanldeSave = () => {
    const { showValidationErrors, ...existingInstitutionIdType } = this.state;
    const newValidationErrors = this.setValidationErrors(
      existingInstitutionIdType
    );

    if (allFieldsAreValid(newValidationErrors)) {
      const payload = {
        uuid: existingInstitutionIdType.uuid,
        name: existingInstitutionIdType.name
      };
      this.props.onPutUpdate(payload);
      this.setState({ showValidationErrors: false });
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const { name, uuid, showValidationErrors } = this.state;
    const { allInstitutionIdTypes, toggleEditView } = this.props;

    const thisInstitutionIdType = {
      uuid,
      name
    };

    const siblings = allInstitutionIdTypes.filter(
      idType => idType.uuid !== thisInstitutionIdType.uuid
    );

    return (
      <EditInstitutionIdType
        existingInstitutionIdType={thisInstitutionIdType}
        allInstitutionIdTypes={siblings}
        handleChange={this.handleChange}
        onPutUpdate={this.hanldeSave}
        showValidationErrors={showValidationErrors}
        toggleEditView={toggleEditView}
      />
    );
  }
}

EditInstitutionIdTypeContainer.propTypes = {
  institutionIdType: PropTypes.object,
  allInstitutionIdTypes: PropTypes.arrayOf(PropTypes.object),
  toggleEditView: PropTypes.func,
  onPutUpdate: PropTypes.func
};
EditInstitutionIdTypeContainer.defaultProps = {
  institutionIdType: {},
  allInstitutionIdTypes: [],
  toggleEditView: undefined,
  onPutUpdate: undefined
};

const mapStateToProps = (state, props) => ({
  allInstitutionIdTypes: institutionIdTypesSelector(state, props)
});

const mapDispatchToProps = dispatch => ({
  onPutUpdate: updates => dispatch(doPutInstitutionIdType(updates))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditInstitutionIdTypeContainer);
export { EditInstitutionIdTypeContainer };
