import React from 'react';
import PropTypes from 'prop-types';

import DeleteModal from '../../../DialogModal/DeleteModal/DeleteModal';
import AccordionRowContainer from '../../../AccordionRow/AccordionRowContainer';
import InstitutionDetails from './InstitutionDetails/InstitutionDetails';

const Institution = ({
  className,
  institution,
  institutionAdmins,
  onOpenEditInstitutionModal,
  isDeleteModalOpen,
  handleDeleteClick,
  handleDeleteModalClose,
  handleDelete
}) => {
  const nameString = `${institution.name}`;

  const institutionActions = [
    {
      label: 'Edit',
      onClick: onOpenEditInstitutionModal,
      shouldOpenOnClick: false,
      icon: 'edit'
    },
    {
      label: 'Delete',
      onClick: handleDeleteClick,
      shouldOpenOnClick: false,
      icon: 'delete'
    }
  ];

  return (
    <>
      <DeleteModal
        modalOpen={isDeleteModalOpen}
        handleModalClose={() => handleDeleteModalClose()}
        handleDelete={() => handleDelete()}
        type="Institution"
      />
      <AccordionRowContainer
        className={className}
        label="Institution"
        columnTwo={nameString}
        actions={institutionActions}
        expandWidth="42px"
        height="38px"
        rowOffset="180px"
        columnGutters="0.75rem"
      >
        <InstitutionDetails
          institution={institution}
          institutionAdmins={institutionAdmins}
          rowOffset="279px"
        />
      </AccordionRowContainer>
    </>
  );
};

Institution.propTypes = {
  institution: PropTypes.object.isRequired,
  institutionAdmins: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  isDeleteModalOpen: PropTypes.bool,
  handleDeleteClick: PropTypes.func,
  handleDeleteModalClose: PropTypes.func,
  handleDelete: PropTypes.func,
  onOpenEditInstitutionModal: PropTypes.func
};

Institution.defaultProps = {
  institutionAdmins: [],
  className: '',
  isDeleteModalOpen: false,
  handleDeleteClick: undefined,
  handleDeleteModalClose: undefined,
  handleDelete: undefined,
  onOpenEditInstitutionModal: undefined
};

export default Institution;
