import { Model, attr } from 'redux-orm';
import {
  GET_LOCATIONS_SUCCESS,
  POST_COUNTRY_SUCCESS,
  PUT_COUNTRY_SUCCESS,
  CLEAR_COUNTRIES,
  DELETE_COUNTRY_SUCCESS
} from '../actions/actionTypes';

class Country extends Model {
  static reducer(action, Country, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_LOCATIONS_SUCCESS: {
        Country.all().delete();
        const countries = [...payload.countries];
        return countries.map(country => {
          const countryUpsert = Country.upsert(country);
          return countryUpsert;
        });
      }
      case POST_COUNTRY_SUCCESS: {
        const { createdCountry } = payload;
        return Country.upsert(createdCountry);
      }
      case PUT_COUNTRY_SUCCESS: {
        const { updatedCountry } = payload;
        return Country.upsert(updatedCountry);
      }
      case DELETE_COUNTRY_SUCCESS: {
        const { deletedCountry } = payload;
        return Country.withId(deletedCountry.uuid).delete();
      }
      case CLEAR_COUNTRIES: {
        Country.all().delete();
        break;
      }
      default:
        return session;
    }
  }
}

Country.fields = {
  uuid: attr(),
  name: attr(),
  code: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

Country.modelName = 'Country';
Country.options = {
  idAttribute: 'uuid'
};

export default Country;
