import { call, put } from 'redux-saga/effects';
import {
  getInstitutionIdTypes,
  postInstitutionIdType,
  putInstitutionIdType,
  deleteInstitutionIdType
} from '../../api/institutionIdTypesApi';
import {
  doGetInstitutionIdTypesSuccess,
  doGetInstitutionIdTypesError,
  doPostInstitutionIdTypeSuccess,
  doPostInstitutionIdTypeError,
  doPutInstitutionIdTypeSuccess,
  doPutInstitutionIdTypeError,
  doDeleteInstitutionIdTypeSuccess,
  doDeleteInstitutionIdTypeError
} from '../actions/institutionIdTypeActions';

function* handleFetchInstitutionIdTypes() {
  try {
    const result = yield call(getInstitutionIdTypes);
    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doGetInstitutionIdTypesSuccess(payload));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doGetInstitutionIdTypesError(error));
  }
}

function* handlePostCreateInstitutionIdTypes(action) {
  const { newInstitutionIdType } = action.payload;

  try {
    const result = yield call(postInstitutionIdType, newInstitutionIdType);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostInstitutionIdTypeSuccess(payload));
    } else {
      const errorText = yield result.text();
      yield put(
        doPutInstitutionIdTypeError({
          text: errorText
        })
      );
    }
  } catch (error) {
    yield put(doPostInstitutionIdTypeError());
  }
}

function* handlePutUpdateInstitutionIdTypes(action) {
  const { institutionIdType } = action.payload;

  try {
    const result = yield call(putInstitutionIdType, institutionIdType);

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doPutInstitutionIdTypeSuccess(payload));
    } else {
      const errorText = yield result.text();
      yield put(
        doPutInstitutionIdTypeError({
          text: errorText
        })
      );
    }
  } catch (error) {
    yield put(doPutInstitutionIdTypeError());
  }
}

function* handleDeleteInstitutionIdTypes(action) {
  const { uuid } = action.payload;

  try {
    const result = yield call(deleteInstitutionIdType, uuid);

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doDeleteInstitutionIdTypeSuccess(payload));
    } else {
      const errorText = yield result.text();
      yield put(
        doDeleteInstitutionIdTypeError({
          text: errorText
        })
      );
    }
  } catch (error) {
    yield put(doDeleteInstitutionIdTypeError());
  }
}

export {
  handleFetchInstitutionIdTypes,
  handlePostCreateInstitutionIdTypes,
  handlePutUpdateInstitutionIdTypes,
  handleDeleteInstitutionIdTypes
};
