import {
  OPEN_MOVE_CODE_MODAL,
  CLOSE_MOVE_CODE_MODAL,
  SET_MOVE_CODE_DESTINATION,
  SET_MOVE_TOPIC_EMPTY_DISCIPLINE
} from '../actions/actionTypes';

const types = {
  topic: 'Topic',
  subtopic: 'Subtopic',
  micro: 'Microcompetency'
};

const INITIAL_STATE = {
  open: false,
  codeToMove: '',
  fullCodeToMove: '',
  parentCodeString: '',
  codeType: '',
  destination: null,
  emptyDisciplineUuid: ''
};

const moveCodeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPEN_MOVE_CODE_MODAL: {
      return {
        ...state,
        open: true,
        codeToMove: action.payload.codeToMoveUuid,
        fullCodeToMove: action.payload.fullCodeToMove,
        parentCodeString: action.payload.parentCodeString,
        codeType: action.payload.codeType
      };
    }
    case CLOSE_MOVE_CODE_MODAL: {
      return {
        ...INITIAL_STATE
      };
    }
    case SET_MOVE_CODE_DESTINATION: {
      return {
        ...state,
        destination: {
          uuid: action.payload.destinationUuid,
          fullCode: action.payload.destinationFullCode,
          isGeneralCode: action.payload.isGeneralCode,
          isOtherCode: action.payload.isOtherCode
        }
      };
    }
    case SET_MOVE_TOPIC_EMPTY_DISCIPLINE: {
      return {
        ...state,
        emptyDisciplineUuid: action.payload.newDisciplineUuid
      };
    }
    default:
      return state;
  }
};

export default moveCodeReducer;
export { types, INITIAL_STATE };
