import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';

import AccordionRowContainer from './AccordionRowContainer';

const DraggableChild = styled.div``;

class DraggableAccordionRow extends PureComponent {
  render() {
    const { draggableId, dragIndex, isDraggingOver, ...rest } = this.props;

    return (
      <Draggable draggableId={draggableId} index={dragIndex}>
        {(provided, snapshot) => (
          <DraggableChild {...provided.draggableProps} ref={provided.innerRef}>
            <AccordionRowContainer
              isDragging={snapshot.isDragging}
              isDraggingOver={isDraggingOver}
              dragHandleProps={provided.dragHandleProps}
              isDragEnabled
              {...rest}
            />
          </DraggableChild>
        )}
      </Draggable>
    );
  }
}

DraggableAccordionRow.propTypes = {
  draggableId: PropTypes.string,
  dragIndex: PropTypes.number.isRequired,
  isDragging: PropTypes.bool,
  isDraggingOver: PropTypes.bool
};

DraggableAccordionRow.defaultProps = {
  draggableId: '',
  isDragging: false,
  isDraggingOver: false
};

export default DraggableAccordionRow;
