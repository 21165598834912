import React from 'react';
import PropTypes from 'prop-types';

import { FormTop, FormBottom } from '../SharedSC/FormSC';

import {
  Column,
  ElevatedForm,
  CancelButton,
  SubmitButton,
  TextFieldWithError
} from '../../Forms/FormComponents';

const InstitutionTypeForm = ({
  agency,
  entityLabel,
  buttonText,
  handleChange,
  onSubmit,
  showValidationErrors,
  validateName,
  onFormClose
}) => (
  <ElevatedForm>
    <FormTop>
      <Column size="100%">
        <TextFieldWithError
          id="agencyName"
          name="name"
          value={agency.name}
          onChange={event => handleChange(event)}
          margin="normal"
          InputLabelProps={{ shrink: true }}
          label={`${entityLabel}*`}
          placeholder=""
          variant="outlined"
          validate={validateName}
          showValidationErrors={showValidationErrors}
          paddingRight="1rem"
        />
      </Column>
    </FormTop>
    <FormBottom>
      <CancelButton onClick={onFormClose} noHoverBg>
        Cancel
      </CancelButton>
      <SubmitButton onClick={onSubmit}>{buttonText}</SubmitButton>
    </FormBottom>
  </ElevatedForm>
);

InstitutionTypeForm.propTypes = {
  agency: PropTypes.object,
  buttonText: PropTypes.string,
  entityLabel: PropTypes.string,
  showValidationErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  onSubmit: PropTypes.func,
  validateName: PropTypes.func.isRequired,
  onFormClose: PropTypes.func
};
InstitutionTypeForm.defaultProps = {
  agency: {},
  buttonText: 'Create',
  entityLabel: 'Type',
  showValidationErrors: false,
  handleChange: undefined,
  onSubmit: undefined,
  onFormClose: undefined
};

export default InstitutionTypeForm;
