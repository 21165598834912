import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { doGetLocations } from '../../../redux/actions/locationActions';
import { publishedCountriesSelector } from '../../../redux/selectors/locationsSelectors';
import CountrySelect from './CountrySelect';

class CountrySelectContainer extends Component {
  componentDidMount() {
    const { countries } = this.props;
    if (!countries || countries.length < 1) {
      this.props.onFetchLocations();
    }
  }

  render() {
    const {
      className,
      countries,
      disabled,
      selectedCountry,
      showValidationErrors,
      handleCountryChange
    } = this.props;
    return (
      <CountrySelect
        className={className}
        disabled={disabled}
        countries={countries}
        selectedCountry={selectedCountry}
        showValidationErrors={showValidationErrors}
        handleCountryChange={handleCountryChange}
      />
    );
  }
}

CountrySelectContainer.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  countries: PropTypes.arrayOf(PropTypes.object),
  showValidationErrors: PropTypes.bool,
  selectedCountry: PropTypes.string,
  onFetchLocations: PropTypes.func,
  handleCountryChange: PropTypes.func
};

CountrySelectContainer.defaultProps = {
  className: '',
  disabled: false,
  countries: [],
  showValidationErrors: false,
  selectedCountry: '',
  onFetchLocations: undefined,
  handleCountryChange: undefined
};

const mapStateToProps = state => ({
  countries: publishedCountriesSelector(state)
});

const mapDispatchToProps = dispatch => ({
  onFetchLocations: () => dispatch(doGetLocations())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CountrySelectContainer);
export { CountrySelectContainer };
