import fetch from "../helpers/fetch";

const getDegreeLevels = () =>
  fetch(`/api/degree-levels/all`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postDegreeLevel = newDegreeLevel =>
  fetch(`/api/degree-level/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(newDegreeLevel)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putDegreeLevel = ({ uuid, ...updates }) =>
  fetch(`/api/degree-level/${uuid}/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(updates)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const patchMoveDegreeLevel = (uuid, destinationOrder) =>
  fetch(`/api/degree-level/${uuid}/move`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ destinationOrder })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteDegreeLevel = uuid =>
  fetch(`/api/degree-level/${uuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export {
  getDegreeLevels,
  postDegreeLevel,
  putDegreeLevel,
  patchMoveDegreeLevel,
  deleteDegreeLevel
};
