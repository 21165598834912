import { createSelector } from 'reselect';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { sortByProperty } from '../../helpers/utilities';
import { orm } from '../models';

export const ormSelector = state => state.orm;

// export const allInstitutionalSystemsSelector = createSelector(
//   ormSelector,
//   ormCreateSelector(orm, session => {
//     return session.InstitutionalSystem.all()
//       .toModelArray()
//       .map(system => Object.assign({}, system.ref))
//       .sort((a, b) => sortByProperty(a, b, 'name'));
//   })
// );

export const allInstitutionalSystemsSelector = createSelector(
  state => state,
  redux => {
    const selectorORM = ormCreateSelector([orm], session => {
      return session.InstitutionalSystem.all()
        .toModelArray()
        .map(system => Object.assign({}, system.ref))
        .sort((a, b) => sortByProperty(a, b, 'name'));
    });
    return selectorORM(redux);
  }
);

export const getInstitutionSubtypeUuid = (state, props) =>
  props.institutionSubtype.uuid;

export const getStateProvinceUuid = (state, props) => props.stateProvince.uuid;

// export const makeStateSubtypeSystemsSelector = () => {
//   return createSelector(
//     ormSelector,
//     getInstitutionSubtypeUuid,
//     getStateProvinceUuid,
//     ormCreateSelector(
//       orm,
//       (session, institutionSubtypeUuid, stateProvinceUuid) => {
//         return session.InstitutionalSystem.all()
//           .filter(
//             system =>
//               system.institutionSubtypeUuid === institutionSubtypeUuid &&
//               system.stateProvinceUuid === stateProvinceUuid
//           )
//           .toModelArray()
//           .map(system => Object.assign({}, system.ref))
//           .sort((a, b) => sortByProperty(a, b, 'name'));
//       }
//     )
//   );
// };

export const makeStateSubtypeSystemsSelector = () => {
  return createSelector(
    state => state,
    getInstitutionSubtypeUuid,
    getStateProvinceUuid,
    (redux, institutionSubtypeUuid, stateProvinceUuid) => {
      const selectorORM = ormCreateSelector([orm], session => {
        return session.InstitutionalSystem.all()
          .filter(
            system =>
              system.institutionSubtypeUuid === institutionSubtypeUuid &&
              system.stateProvinceUuid === stateProvinceUuid
          )
          .toModelArray()
          .map(system => Object.assign({}, system.ref))
          .sort((a, b) => sortByProperty(a, b, 'name'));
      });
      return selectorORM(redux);
    }
  );
};

export const getSystemUuid = (state, props) => props.systemUuid;

// export const makeGetInstitutionalSystemSelector = () => {
//   return createSelector(
//     ormSelector,
//     getSystemUuid,
//     ormCreateSelector(orm, (session, institutionalSystemUuid) => {
//       const returnValue = session.InstitutionalSystem.all()
//         .filter(system => system.uuid === institutionalSystemUuid)
//         .toRefArray()[0];

//       return returnValue;
//     })
//   );
// };

export const makeGetInstitutionalSystemSelector = () => {
  return createSelector(
    state => state,
    getSystemUuid,
    (redux, institutionalSystemUuid) => {
      const selectorORM = ormCreateSelector([orm], session => {
        const returnValue = session.InstitutionalSystem.all()
          .filter(system => system.uuid === institutionalSystemUuid)
          .toRefArray()[0];

        return returnValue;
      });
      return selectorORM(redux);
    }
  );
};

const getInstitutionDashboardSystemUuid = state =>
  state.institutionsDashboardState.systemUuid;

// export const makeGetInstitutionsInstitutionalSystemSelector = () => {
//   return createSelector(
//     ormSelector,
//     getInstitutionDashboardSystemUuid,
//     ormCreateSelector(orm, (session, institutionalSystemUuid) => {
//       const returnValue = session.InstitutionalSystem.all()
//         .filter(system => system.uuid === institutionalSystemUuid)
//         .toRefArray()[0];

//       return returnValue;
//     })
//   );
// };

export const makeGetInstitutionsInstitutionalSystemSelector = () => {
  return createSelector(
    state => state,
    getInstitutionDashboardSystemUuid,
    (redux, institutionalSystemUuid) => {
      const selectorORM = ormCreateSelector([orm], session => {
        const returnValue = session.InstitutionalSystem.all()
          .filter(system => system.uuid === institutionalSystemUuid)
          .toRefArray()[0];

        return returnValue;
      });
      return selectorORM(redux);
    }
  );
};

export const getEditInstitutionSubtypeUuid = state =>
  state.institutionsDashboardState.institutionSubtypeUuid;

export const getEditStateProvinceUuid = state =>
  state.institutionsDashboardState.stateProvinceUuid;

// export const makeEditStateSubtypeSystemsSelector = () => {
//   return createSelector(
//     ormSelector,
//     getEditInstitutionSubtypeUuid,
//     getEditStateProvinceUuid,
//     ormCreateSelector(
//       orm,
//       (session, institutionSubtypeUuid, stateProvinceUuid) => {
//         return session.InstitutionalSystem.all()
//           .filter(
//             system =>
//               system.institutionSubtypeUuid === institutionSubtypeUuid &&
//               system.stateProvinceUuid === stateProvinceUuid
//           )
//           .toModelArray()
//           .map(system => Object.assign({}, system.ref))
//           .sort((a, b) => sortByProperty(a, b, 'name'));
//       }
//     )
//   );
// };

export const makeEditStateSubtypeSystemsSelector = () => {
  return createSelector(
    state => state,
    getEditInstitutionSubtypeUuid,
    getEditStateProvinceUuid,
    (redux, institutionSubtypeUuid, stateProvinceUuid) => {
      const selectorORM = ormCreateSelector([orm], session => {
        return session.InstitutionalSystem.all()
          .filter(
            system =>
              system.institutionSubtypeUuid === institutionSubtypeUuid &&
              system.stateProvinceUuid === stateProvinceUuid
          )
          .toModelArray()
          .map(system => Object.assign({}, system.ref))
          .sort((a, b) => sortByProperty(a, b, 'name'));
      });
      return selectorORM(redux);
    }
  );
};
