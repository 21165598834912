import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EditInstitutionType from './EditInstitutionType';
import { institutionTypesSelector } from '../../../../../redux/selectors/institutionTypesSelectors';
import { doPutInstitutionType } from '../../../../../redux/actions/institutionTypeActions';
import {
  allFieldsAreValid,
  validateEntityName
} from '../../../../../helpers/validateGeneric';

class EditInstitutionTypeContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      uuid: '',
      name: '',
      order: 0,
      showValidationErrors: false
    };

    this.setExistingState = this.setExistingState.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.hanldeSave = this.hanldeSave.bind(this);
  }

  componentDidMount() {
    const { name } = this.state;
    const { institutionType } = this.props;
    this._isMounted = true;
    if (!name) {
      this.setExistingState(institutionType);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  setExistingState = institutionType => {
    const { ...existingInstitutionType } = institutionType;
    if (this._isMounted) {
      this.setState(() => ({
        ...existingInstitutionType
      }));
    }
  };

  setValidationErrors = existingInstitutionType => {
    const { allInstitutionTypes } = this.props;
    const { uuid } = this.state;
    const siblingTypes = allInstitutionTypes.filter(
      institutionType => institutionType.uuid !== uuid
    );
    const nameError = validateEntityName(
      existingInstitutionType.name,
      siblingTypes
    );
    const newValidationErrors = {
      name: nameError
    };

    return newValidationErrors;
  };

  hanldeSave = () => {
    const { showValidationErrors, ...existingInstitutionType } = this.state;
    const newValidationErrors = this.setValidationErrors(
      existingInstitutionType
    );

    if (allFieldsAreValid(newValidationErrors)) {
      const payload = {
        uuid: existingInstitutionType.uuid,
        name: existingInstitutionType.name
      };
      this.props.onPostUpdate(payload);
      this.setState({ showValidationErrors: false });
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const { name, order, uuid, showValidationErrors } = this.state;
    const { institutionType, allInstitutionTypes, toggleEditView } = this.props;

    const thisType = {
      uuid,
      name,
      order
    };

    const siblingTypes = allInstitutionTypes.filter(
      institutionType => institutionType.uuid !== thisType.uuid
    );

    return (
      <EditInstitutionType
        existingInstitutionType={thisType}
        institutionType={institutionType}
        allInstitutionTypes={siblingTypes}
        handleChange={this.handleChange}
        onPostUpdate={this.hanldeSave}
        showValidationErrors={showValidationErrors}
        toggleEditView={toggleEditView}
      />
    );
  }
}

EditInstitutionTypeContainer.propTypes = {
  institutionType: PropTypes.object,
  allInstitutionTypes: PropTypes.arrayOf(PropTypes.object),
  toggleEditView: PropTypes.func,
  onPostUpdate: PropTypes.func
};
EditInstitutionTypeContainer.defaultProps = {
  institutionType: {},
  allInstitutionTypes: [],
  toggleEditView: undefined,
  onPostUpdate: undefined
};

const mapStateToProps = (state, props) => ({
  allInstitutionTypes: institutionTypesSelector(state, props)
});

const mapDispatchToProps = dispatch => ({
  onPostUpdate: updates => dispatch(doPutInstitutionType(updates))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditInstitutionTypeContainer);
export { EditInstitutionTypeContainer };
