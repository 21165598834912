import { call, put } from 'redux-saga/effects';
import {
  getLocations,
  postCountry,
  postStateProvince,
  putCountry,
  putStateProvince,
  deleteCountry,
  deleteStateProvince
} from '../../api/locationsApi';
import {
  doGetLocationsSuccess,
  doGetLocationsError,
  doPostCountrySuccess,
  doPostCountryError,
  doPostStateProvinceSuccess,
  doPostStateProvinceError,
  doPutCountrySuccess,
  doPutCountryError,
  doPutStateProvinceSuccess,
  doPutStateProvinceError,
  doDeleteCountrySuccess,
  doDeleteCountryError,
  doDeleteStateProvinceSuccess,
  doDeleteStateProvinceError
} from '../actions/locationActions';

function* handleFetchLocations() {
  try {
    const result = yield call(getLocations);
    yield put(doGetLocationsSuccess(result));
  } catch (error) {
    yield put(doGetLocationsError(error));
  }
}

function* handlePostCreateCountry(action) {
  const { newCountry } = action.payload;
  try {
    const result = yield call(postCountry, newCountry);
    yield put(doPostCountrySuccess(result));
  } catch (error) {
    yield put(doPostCountryError(error));
  }
}

function* handlePostCreateStateProvince(action) {
  const { newStateProvince } = action.payload;
  try {
    const result = yield call(postStateProvince, newStateProvince);
    yield put(doPostStateProvinceSuccess(result));
  } catch (error) {
    yield put(doPostStateProvinceError(error));
  }
}

function* handlePostUpdateCountry(action) {
  const { countryUpdates } = action.payload;
  try {
    const result = yield call(putCountry, countryUpdates);
    yield put(doPutCountrySuccess(result));
  } catch (error) {
    yield put(doPutCountryError(error));
  }
}

function* handlePostUpdateStateProvince(action) {
  const { stateProvinceUpdates } = action.payload;
  try {
    const result = yield call(putStateProvince, stateProvinceUpdates);
    yield put(doPutStateProvinceSuccess(result));
  } catch (error) {
    yield put(doPutStateProvinceError(error));
  }
}

function* handleDeleteCountry(action) {
  const { countryUuid } = action.payload;
  try {
    const result = yield call(deleteCountry, countryUuid);
    yield put(doDeleteCountrySuccess(result));
  } catch (error) {
    yield put(doDeleteCountryError(error));
  }
}

function* handleDeleteStateProvince(action) {
  const { stateProvinceUuid } = action.payload;
  try {
    const result = yield call(deleteStateProvince, stateProvinceUuid);
    yield put(doDeleteStateProvinceSuccess(result));
  } catch (error) {
    yield put(doDeleteStateProvinceError(error));
  }
}

export {
  handleFetchLocations,
  handlePostCreateCountry,
  handlePostCreateStateProvince,
  handlePostUpdateCountry,
  handlePostUpdateStateProvince,
  handleDeleteCountry,
  handleDeleteStateProvince
};
