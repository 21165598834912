import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tooltip } from '@material-ui/core';

import { FormTop, FormBottom } from '../../../SharedSC/FormSC';
import { Divider, FormRow } from '../../../SharedSC/ModalForm';
import {
  Column,
  CancelButton,
  SubmitButton,
  TextFieldWithError
} from '../../../../Forms/FormComponents';
import { convertNullToString } from '../../../../../helpers/utilities';
import withValidation from '../../../../FieldWithError/FieldWithError';
import CountrySelect from '../../../../EntitySelectFields/CountrySelect/CountrySelectContainer';
import StateProvinceSelect from '../../../../EntitySelectFields/StateProvinceSelect/StateProvinceSelectContainer';
import InstitutionTypeSelect from '../../../../EntitySelectFields/InstitutionTypeSelect/InstitutionTypeSelectContainer';
import InstitutionSubtypeSelect from '../../../../EntitySelectFields/InstitutionSubtypeSelect/InstitutionSubtypeSelectContainer';
import SelectUserFieldContainer from '../../../../SelectUserField/SelectUserFieldContainer';

const Layout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`;

const SelectUserFieldWithError = withValidation(SelectUserFieldContainer);

const InstitutionalSystemForm = ({
  users,
  institutionalSystem,
  locations,
  types,
  buttonText,
  isEditForm,
  handleChange,
  handleUserFieldChange,
  onSubmit,
  closeForm,
  showValidationErrors,
  validateName,
  validateWebsiteAddress
}) => {
  const {
    name,
    websiteAddress,
    selectedCountryUuid,
    stateProvinceUuid,
    selectedInstitutionTypeUuid,
    institutionSubtypeUuid
  } = institutionalSystem;

  const userOptions = users
    ? users.map(user => ({
        value: user.uuid,
        label: `${user.firstName} ${user.lastName}`
      }))
    : [];
  return (
    <Layout>
      <FormTop>
        <FormRow>
          <Column size="50%" paddingRight="1rem">
            {!isEditForm ? (
              <CountrySelect
                selectedCountry={selectedCountryUuid}
                showValidationErrors={showValidationErrors}
                handleCountryChange={handleChange}
              />
            ) : (
              <>
                {locations.country ? (
                  <CountrySelect
                    selectedCountry={locations.country.uuid}
                    showValidationErrors={showValidationErrors}
                    handleCountryChange={handleChange}
                    disabled
                  />
                ) : (
                  'No Country Selected'
                )}
              </>
            )}
          </Column>
          <Column size="50%" paddingLeft="1rem">
            {isEditForm ? (
              <>
                {locations.stateProvince ? (
                  <StateProvinceSelect
                    country={{ uuid: locations.country.uuid }}
                    selectedStateProvince={stateProvinceUuid}
                    showValidationErrors={showValidationErrors}
                    handleChange={handleChange}
                    disabled
                  />
                ) : (
                  'No State/Province Selected'
                )}
              </>
            ) : (
              <>
                {!selectedCountryUuid ? (
                  <Tooltip title="Select a country first">
                    <span>
                      <StateProvinceSelect
                        country={{ uuid: selectedCountryUuid }}
                        selectedStateProvince={stateProvinceUuid}
                        showValidationErrors={showValidationErrors}
                        handleChange={handleChange}
                      />
                    </span>
                  </Tooltip>
                ) : (
                  <StateProvinceSelect
                    country={{ uuid: selectedCountryUuid }}
                    selectedStateProvince={stateProvinceUuid}
                    showValidationErrors={showValidationErrors}
                    handleChange={handleChange}
                  />
                )}
              </>
            )}
          </Column>
        </FormRow>
        <FormRow>
          <Column size="50%" paddingRight="1rem">
            {isEditForm ? (
              <>
                {types.institutionType ? (
                  <InstitutionTypeSelect
                    selectedInstitutionType={types.institutionType.uuid}
                    showValidationErrors={showValidationErrors}
                    handleChange={handleChange}
                    disabled
                  />
                ) : (
                  'No Institution Type Selected'
                )}
              </>
            ) : (
              <InstitutionTypeSelect
                selectedInstitutionType={selectedInstitutionTypeUuid}
                showValidationErrors={showValidationErrors}
                handleChange={handleChange}
              />
            )}
          </Column>
          <Column size="50%" paddingLeft="1rem">
            {isEditForm ? (
              <>
                {types.institutionSubtype ? (
                  <InstitutionSubtypeSelect
                    institutionType={{ uuid: types.institutionType.uuid }}
                    selectedInstitutionSubtype={institutionSubtypeUuid}
                    showValidationErrors={showValidationErrors}
                    handleChange={handleChange}
                    disabled
                  />
                ) : (
                  'No Institution Subtype Selected'
                )}
              </>
            ) : (
              <>
                {!isEditForm && !selectedInstitutionTypeUuid ? (
                  <Tooltip title="Select a type first">
                    <span>
                      <InstitutionSubtypeSelect
                        institutionType={{ uuid: selectedInstitutionTypeUuid }}
                        selectedInstitutionSubtype={institutionSubtypeUuid}
                        showValidationErrors={showValidationErrors}
                        handleChange={handleChange}
                      />
                    </span>
                  </Tooltip>
                ) : (
                  <InstitutionSubtypeSelect
                    institutionType={{ uuid: selectedInstitutionTypeUuid }}
                    selectedInstitutionSubtype={institutionSubtypeUuid}
                    showValidationErrors={showValidationErrors}
                    handleChange={handleChange}
                  />
                )}
              </>
            )}
          </Column>
        </FormRow>
        <Divider />
        <FormRow>
          <Column size="50%" paddingRight="1rem">
            <TextFieldWithError
              id="systemName"
              name="name"
              value={name}
              label="System Name*"
              onChange={event => handleChange(event)}
              margin="normal"
              variant="outlined"
              validate={validateName}
              showValidationErrors={showValidationErrors}
            />
          </Column>
          <Column size="50%" paddingLeft="1rem">
            <TextFieldWithError
              id="systemWebsiteAddress"
              name="websiteAddress"
              value={convertNullToString(websiteAddress)}
              label="Website Address"
              onChange={event => handleChange(event)}
              margin="normal"
              variant="outlined"
              validate={validateWebsiteAddress}
              showValidationErrors={showValidationErrors}
            />
          </Column>
        </FormRow>
        <FormRow marginBottom="0">
          <Column size="50%" paddingRight="1rem">
            <SelectUserFieldWithError
              name="institutionalSystemAdmins"
              userSelect
              fieldName="institutionalSystemAdmins"
              label="System Admins"
              handleChange={handleUserFieldChange}
              usersArray={userOptions}
              placeholder="Select"
              values={institutionalSystem.institutionalSystemAdmins}
            />
          </Column>
        </FormRow>
      </FormTop>
      <FormBottom>
        <CancelButton onClick={closeForm} noHoverBg>
          Cancel
        </CancelButton>
        <SubmitButton onClick={onSubmit}>{buttonText}</SubmitButton>
      </FormBottom>
    </Layout>
  );
};

InstitutionalSystemForm.propTypes = {
  institutionalSystem: PropTypes.object,
  locations: PropTypes.object,
  types: PropTypes.object,
  buttonText: PropTypes.string,
  isEditForm: PropTypes.bool,
  showValidationErrors: PropTypes.bool,
  users: PropTypes.arrayOf(PropTypes.object),
  closeForm: PropTypes.func,
  handleChange: PropTypes.func,
  handleUserFieldChange: PropTypes.func,
  onSubmit: PropTypes.func,
  validateName: PropTypes.func.isRequired,
  validateWebsiteAddress: PropTypes.func.isRequired
};
InstitutionalSystemForm.defaultProps = {
  institutionalSystem: {},
  locations: {
    country: {},
    stateProvince: {}
  },
  types: {
    institutionType: {},
    institutionSubtype: {}
  },
  buttonText: 'Create',
  showValidationErrors: false,
  isEditForm: false,
  users: [],
  closeForm: undefined,
  handleChange: undefined,
  handleUserFieldChange: undefined,
  onSubmit: undefined
};

export default InstitutionalSystemForm;
