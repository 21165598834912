import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Column,
  ElevatedForm,
  SubmitButton,
  CancelButton,
  TextFieldWithError
} from '../../../Forms/FormComponents';

import { FormTop, FormBottom } from '../../SharedSC/FormSC';

const FormBottomSC = styled(FormBottom)`
  margin-top: 1rem;
`;

const ParentCipDigits = styled.div`
  width: 100%;
  text-align: right;
  padding-right: 0.25rem;
`;

const DisciplineForm = ({
  cipcode,
  discipline,
  buttonText,
  handleChange,
  onSubmit,
  showValidationErrors,
  validateCode,
  validateTitle,
  closeForm
}) => (
  <ElevatedForm>
    <FormTop>
      <Column size="3%">
        <ParentCipDigits>{cipcode.code}.</ParentCipDigits>
      </Column>
      <Column size="22%">
        <TextFieldWithError
          id="disciplineCode"
          name="code"
          value={discipline.code}
          onChange={event => handleChange(event)}
          margin="normal"
          InputLabelProps={{ shrink: true }}
          label="Discipline Code*"
          placeholder=""
          variant="outlined"
          validate={validateCode}
          showValidationErrors={showValidationErrors}
          paddingRight="1rem"
        />
      </Column>
      <Column size="75%">
        <TextFieldWithError
          id="disciplineName"
          name="title"
          value={discipline.title}
          onChange={event => handleChange(event)}
          margin="normal"
          InputLabelProps={{ shrink: true }}
          label="Code Title*"
          placeholder=""
          variant="outlined"
          validate={validateTitle}
          showValidationErrors={showValidationErrors}
        />
      </Column>
    </FormTop>
    <FormBottomSC>
      <CancelButton onClick={closeForm} noHoverBg>
        Cancel
      </CancelButton>
      <SubmitButton onClick={onSubmit}>{buttonText}</SubmitButton>
    </FormBottomSC>
  </ElevatedForm>
);

DisciplineForm.propTypes = {
  cipcode: PropTypes.object.isRequired,
  discipline: PropTypes.object.isRequired,
  buttonText: PropTypes.string,
  showValidationErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  onSubmit: PropTypes.func,
  validateCode: PropTypes.func.isRequired,
  validateTitle: PropTypes.func.isRequired,
  closeForm: PropTypes.func
};
DisciplineForm.defaultProps = {
  buttonText: 'Create',
  showValidationErrors: false,
  handleChange: undefined,
  onSubmit: undefined,
  closeForm: undefined
};

export default DisciplineForm;
