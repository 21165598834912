import {
  GET_LOCATIONS,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATIONS_ERROR,
  POST_COUNTRY,
  POST_COUNTRY_SUCCESS,
  POST_COUNTRY_ERROR,
  POST_STATE_PROVINCE,
  POST_STATE_PROVINCE_SUCCESS,
  POST_STATE_PROVINCE_ERROR,
  PUT_COUNTRY,
  PUT_COUNTRY_SUCCESS,
  PUT_COUNTRY_ERROR,
  PUT_STATE_PROVINCE,
  PUT_STATE_PROVINCE_SUCCESS,
  PUT_STATE_PROVINCE_ERROR,
  DELETE_COUNTRY,
  DELETE_COUNTRY_SUCCESS,
  DELETE_COUNTRY_ERROR,
  DELETE_STATE_PROVINCE,
  DELETE_STATE_PROVINCE_SUCCESS,
  DELETE_STATE_PROVINCE_ERROR,
  CLEAR_COUNTRIES
} from './actionTypes';

const doGetLocations = () => ({
  type: GET_LOCATIONS
});

const doGetLocationsSuccess = data => ({
  type: GET_LOCATIONS_SUCCESS,
  payload: {
    countries: data.countries,
    stateProvinces: data.stateProvinces
  }
});

const doGetLocationsError = error => ({
  type: GET_LOCATIONS_ERROR,
  payload: {
    error
  }
});

const doPostCountry = newCountry => ({
  type: POST_COUNTRY,
  payload: {
    newCountry
  }
});

const doPostCountrySuccess = data => ({
  type: POST_COUNTRY_SUCCESS,
  payload: {
    createdCountry: data.newCountry
  }
});

const doPostCountryError = error => ({
  type: POST_COUNTRY_ERROR,
  payload: {
    error
  }
});

const doPutCountry = countryUpdates => ({
  type: PUT_COUNTRY,
  payload: {
    countryUpdates
  }
});

const doPutCountrySuccess = data => ({
  type: PUT_COUNTRY_SUCCESS,
  payload: {
    updatedCountry: data.updatedCountry
  }
});

const doPutCountryError = error => ({
  type: PUT_COUNTRY_ERROR,
  payload: {
    error
  }
});

const doDeleteCountry = countryUuid => ({
  type: DELETE_COUNTRY,
  payload: {
    countryUuid
  }
});

const doDeleteCountrySuccess = data => ({
  type: DELETE_COUNTRY_SUCCESS,
  payload: {
    deletedCountry: data.deletedCountry
  }
});

const doDeleteCountryError = error => ({
  type: DELETE_COUNTRY_ERROR,
  payload: {
    error
  }
});

const doPostStateProvince = newStateProvince => ({
  type: POST_STATE_PROVINCE,
  payload: {
    newStateProvince
  }
});

const doPostStateProvinceSuccess = data => ({
  type: POST_STATE_PROVINCE_SUCCESS,
  payload: {
    createdStateProvince: data.newStateProvince
  }
});

const doPostStateProvinceError = error => ({
  type: POST_STATE_PROVINCE_ERROR,
  payload: {
    error
  }
});

const doPutStateProvince = stateProvinceUpdates => ({
  type: PUT_STATE_PROVINCE,
  payload: {
    stateProvinceUpdates
  }
});

const doPutStateProvinceSuccess = data => ({
  type: PUT_STATE_PROVINCE_SUCCESS,
  payload: {
    updatedStateProvince: data.updatedStateProvince
  }
});

const doPutStateProvinceError = error => ({
  type: PUT_STATE_PROVINCE_ERROR,
  payload: {
    error
  }
});

const doDeleteStateProvince = stateProvinceUuid => ({
  type: DELETE_STATE_PROVINCE,
  payload: {
    stateProvinceUuid
  }
});

const doDeleteStateProvinceSuccess = data => ({
  type: DELETE_STATE_PROVINCE_SUCCESS,
  payload: {
    deletedStateProvince: data.deletedStateProvince
  }
});

const doDeleteStateProvinceError = error => ({
  type: DELETE_STATE_PROVINCE_ERROR,
  payload: {
    error
  }
});

const doClearCountries = () => ({
  type: CLEAR_COUNTRIES
});

export {
  doGetLocations,
  doGetLocationsSuccess,
  doGetLocationsError,
  doPostCountry,
  doPostCountrySuccess,
  doPostCountryError,
  doPutCountry,
  doPutCountrySuccess,
  doPutCountryError,
  doDeleteCountry,
  doDeleteCountrySuccess,
  doDeleteCountryError,
  doPostStateProvince,
  doPostStateProvinceSuccess,
  doPostStateProvinceError,
  doPutStateProvince,
  doPutStateProvinceSuccess,
  doPutStateProvinceError,
  doDeleteStateProvince,
  doDeleteStateProvinceSuccess,
  doDeleteStateProvinceError,
  doClearCountries
};
