import {
  PUT_MICROCOMPETENCY_SUCCESS,
  PUT_SUBTOPIC_SUCCESS,
  PUT_TOPIC_SUCCESS,
  PUT_DISCIPLINE_SUCCESS,
  PUT_CIP_CODE_SUCCESS,
  CLEAR_SUCCESFULLY_UPDATED_MICROCOMPETENCY,
  CLEAR_SUCCESFULLY_UPDATED_SUBTOPIC,
  CLEAR_SUCCESFULLY_UPDATED_TOPIC,
  CLEAR_SUCCESFULLY_UPDATED_DISCIPLINE,
  CLEAR_SUCCESFULLY_UPDATED_CIP_CODE,
  DELETE_TOPIC_SUCCESS
} from '../actions/actionTypes';

const INITIAL_STATE = {
  successfullyUpdatedMicrocompetency: false,
  successfullyUpdatedSubtopic: false,
  successfullyUpdatedTopic: false,
  successfullyUpdatedDiscipline: false,
  successfullyUpdatedCipcode: false
};

const successfulUpdateReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PUT_MICROCOMPETENCY_SUCCESS: {
      return {
        ...state,
        successfullyUpdatedMicrocompetency:
          action.payload.updatedMicrocompetency
      };
    }
    case PUT_SUBTOPIC_SUCCESS: {
      return {
        ...state,
        successfullyUpdatedSubtopic: action.payload.updatedSubtopic
      };
    }
    case PUT_TOPIC_SUCCESS: {
      return {
        ...state,
        successfullyUpdatedTopic: action.payload.updatedTopic
      };
    }
    case DELETE_TOPIC_SUCCESS: {
      return {
        ...state,
        successfullyUpdatedTopic: action.payload.deletedTopic
      };
    }
    case PUT_DISCIPLINE_SUCCESS: {
      return {
        ...state,
        successfullyUpdatedDiscipline: action.payload.updatedDiscipline
      };
    }
    case PUT_CIP_CODE_SUCCESS: {
      return {
        ...state,
        successfullyUpdatedCipcode: action.payload.updatedCipcode
      };
    }
    case CLEAR_SUCCESFULLY_UPDATED_MICROCOMPETENCY: {
      return {
        ...state,
        successfullyUpdatedMicrocompetency: false
      };
    }
    case CLEAR_SUCCESFULLY_UPDATED_SUBTOPIC: {
      return {
        ...state,
        successfullyUpdatedSubtopic: false
      };
    }
    case CLEAR_SUCCESFULLY_UPDATED_TOPIC: {
      return {
        ...state,
        successfullyUpdatedTopic: false
      };
    }
    case CLEAR_SUCCESFULLY_UPDATED_DISCIPLINE: {
      return {
        ...state,
        successfullyUpdatedDiscipline: false
      };
    }
    case CLEAR_SUCCESFULLY_UPDATED_CIP_CODE: {
      return {
        ...state,
        successfullyUpdatedCipcode: false
      };
    }
    default:
      return state;
  }
};

export default successfulUpdateReducer;

export { INITIAL_STATE };
