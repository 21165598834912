import {
  GET_CIP_CODES_ERROR,
  GET_DISCIPLINE_TOPICS_ERROR,
  GET_SUBTOPICS_ERROR,
  GET_MICROCOMPETENCIES_ERROR,
  GET_LOCATIONS_ERROR,
  GET_INSTITUTION_TYPES_ERROR,
  GET_ACCREDITATION_AGENCIES_ERROR,
  GET_INSTITUTIONAL_SYSTEMS_ERROR,
  POST_MICROCOMPETENCY_ERROR,
  POST_MICROCOMPETENCY_SUCCESS,
  POST_SUBTOPIC_SUCCESS,
  POST_SUBTOPIC_ERROR,
  POST_TOPIC_SUCCESS,
  POST_TOPIC_ERROR,
  POST_DISCIPLINE_SUCCESS,
  POST_DISCIPLINE_ERROR,
  POST_CIP_CODE_ERROR,
  POST_CIP_CODE_SUCCESS,
  PUT_MICROCOMPETENCY_ERROR,
  PUT_MICROCOMPETENCY_SUCCESS,
  PUT_SUBTOPIC_ERROR,
  PUT_SUBTOPIC_SUCCESS,
  PUT_TOPIC_ERROR,
  PUT_TOPIC_SUCCESS,
  PUT_DISCIPLINE_ERROR,
  PUT_DISCIPLINE_SUCCESS,
  PUT_CIP_CODE_ERROR,
  PUT_CIP_CODE_SUCCESS,
  OPEN_NOTIFICATION,
  CLOSE_NOTIFICATION,
  NEW_GENERAL_ERROR,
  DELETE_MICROCOMPETENCY_ERROR,
  DELETE_MICROCOMPETENCY_SUCCESS,
  DELETE_TOPIC_ERROR,
  DELETE_TOPIC_SUCCESS,
  DELETE_SUBTOPIC_ERROR,
  DELETE_SUBTOPIC_SUCCESS,
  DELETE_DISCIPLINE_ERROR,
  DELETE_DISCIPLINE_SUCCESS,
  DELETE_CIP_CODE_ERROR,
  DELETE_CIP_CODE_SUCCESS,
  PATCH_MOVE_MICROCOMPETENCY_ERROR,
  PATCH_MOVE_MICROCOMPETENCY_SUCCESS,
  PATCH_MOVE_SUBTOPIC_ERROR,
  PATCH_MOVE_SUBTOPIC_SUCCESS,
  PATCH_MOVE_TOPIC_ERROR,
  PATCH_MOVE_TOPIC_SUCCESS,
  PATCH_PUBLISH_MICROCOMPETENCY_ERROR,
  PATCH_PUBLISH_MICROCOMPETENCY_SUCCESS,
  POST_COUNTRY_ERROR,
  POST_COUNTRY_SUCCESS,
  POST_STATE_PROVINCE_ERROR,
  POST_STATE_PROVINCE_SUCCESS,
  PUT_COUNTRY_ERROR,
  PUT_COUNTRY_SUCCESS,
  PUT_STATE_PROVINCE_ERROR,
  PUT_STATE_PROVINCE_SUCCESS,
  DELETE_COUNTRY_ERROR,
  DELETE_COUNTRY_SUCCESS,
  DELETE_STATE_PROVINCE_ERROR,
  DELETE_STATE_PROVINCE_SUCCESS,
  POST_INSTITUTION_TYPE_SUCCESS,
  POST_INSTITUTION_TYPE_ERROR,
  POST_INSTITUTION_SUBTYPE_ERROR,
  POST_INSTITUTION_SUBTYPE_SUCCESS,
  PUT_INSTITUTION_SUBTYPE_SUCCESS,
  PUT_INSTITUTION_SUBTYPE_ERROR,
  PUT_INSTITUTION_TYPE_ERROR,
  PUT_INSTITUTION_TYPE_SUCCESS,
  PATCH_MOVE_INSTITUTION_TYPE_ERROR,
  PATCH_MOVE_INSTITUTION_TYPE_SUCCESS,
  DELETE_INSTITUTION_TYPE_ERROR,
  DELETE_INSTITUTION_TYPE_SUCCESS,
  DELETE_INSTITUTION_SUBTYPE_ERROR,
  DELETE_INSTITUTION_SUBTYPE_SUCCESS,
  POST_ACCREDITATION_AGENCY_ERROR,
  POST_ACCREDITATION_AGENCY_SUCCESS,
  PUT_ACCREDITATION_AGENCY_SUCCESS,
  PUT_ACCREDITATION_AGENCY_ERROR,
  DELETE_ACCREDITATION_AGENCY_ERROR,
  DELETE_ACCREDITATION_AGENCY_SUCCESS,
  POST_INSTITUTIONAL_SYSTEM_SUCCESS,
  POST_INSTITUTIONAL_SYSTEM_ERROR,
  PUT_INSTITUTIONAL_SYSTEM_SUCCESS,
  PUT_INSTITUTIONAL_SYSTEM_ERROR,
  DELETE_INSTITUTIONAL_SYSTEM_ERROR,
  DELETE_INSTITUTIONAL_SYSTEM_SUCCESS,
  GET_INSTITUTIONS_ERROR,
  POST_INSTITUTION_SUCCESS,
  POST_INSTITUTION_ERROR,
  PUT_INSTITUTION_SUCCESS,
  PUT_INSTITUTION_ERROR,
  DELETE_INSTITUTION_ERROR,
  DELETE_INSTITUTION_SUCCESS,
  GET_USERS_ERROR,
  GET_PROGRAM_CIPCODES_ERROR,
  POST_PROGRAM_CIPCODE_ERROR,
  POST_PROGRAM_CIPCODE_SUCCESS,
  PUT_PROGRAM_CIPCODE_ERROR,
  PUT_PROGRAM_CIPCODE_SUCCESS,
  POST_PROGRAM_DISCIPLINE_SUCCESS,
  POST_PROGRAM_DISCIPLINE_ERROR,
  DELETE_PROGRAM_CIPCODE_ERROR,
  DELETE_PROGRAM_CIPCODE_SUCCESS,
  PUT_PROGRAM_DISCIPLINE_SUCCESS,
  PUT_PROGRAM_DISCIPLINE_ERROR,
  DELETE_PROGRAM_DISCIPLINE_SUCCESS,
  DELETE_PROGRAM_DISCIPLINE_ERROR,
  GET_PROGRAM_CODES_ERROR,
  GET_PROGRAM_DISCIPLINE_CODES_ERROR,
  POST_PROGRAM_CODE_ERROR,
  POST_PROGRAM_CODE_SUCCESS,
  PUT_PROGRAM_CODE_ERROR,
  PUT_PROGRAM_CODE_SUCCESS,
  PATCH_PUBLISH_PROGRAM_CODE_ERROR,
  PATCH_PUBLISH_PROGRAM_CODE_SUCCESS,
  DELETE_PROGRAM_CODE_ERROR,
  DELETE_PROGRAM_CODE_SUCCESS,
  SEARCH_PROGRAM_CODES_ERROR,
  GET_DEGREE_LEVELS_ERROR,
  POST_DEGREE_LEVEL_SUCCESS,
  POST_DEGREE_LEVEL_ERROR,
  PUT_DEGREE_LEVEL_SUCCESS,
  PUT_DEGREE_LEVEL_ERROR,
  DELETE_DEGREE_LEVEL_SUCCESS,
  DELETE_DEGREE_LEVEL_ERROR,
  PATCH_MOVE_DEGREE_LEVEL_ERROR,
  PATCH_MOVE_DEGREE_LEVEL_SUCCESS,
  GET_INSTITUTION_ID_TYPES_ERROR,
  POST_INSTITUTION_ID_TYPE_ERROR,
  POST_INSTITUTION_ID_TYPE_SUCCESS,
  PUT_INSTITUTION_ID_TYPE_ERROR,
  PUT_INSTITUTION_ID_TYPE_SUCCESS,
  DELETE_INSTITUTION_ID_TYPE_ERROR,
  DELETE_INSTITUTION_ID_TYPE_SUCCESS
} from '../actions/actionTypes';

const types = {
  success: 'Success',
  error: 'Error',
  info: 'Info'
};

const INITIAL_STATE = {
  message: '',
  type: '',
  open: false
};

export const fetchError = type => `Error: unable to retrieve ${type}`;

export const createSuccessMessage = type => `Successfully created ${type}`;
export const createErrorMessage = type => `Error: unable to create ${type}`;
export const updateSuccessMessage = type => `Successfully updated ${type}`;
export const updateErrorMessage = type => `Error: unable to update ${type}`;

export const deleteSuccessMessage = type => `Successfully deleted ${type}`;
export const deleteErrorMessage = type => `Error: unable to delete ${type}`;

export const publishEntitySuccessMessage = type =>
  `Successfully published ${type}`;
export const publishEntityErrorMessage = type =>
  `Error: unable to publish ${type}`;

export const moveSuccessMessage = type => `Successfully moved ${type}`;
export const moveErrorMessage = type => `Error: unable to move ${type}`;

const notificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CIP_CODES_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('CIP Codes'),
        open: true
      };
    }
    case GET_DISCIPLINE_TOPICS_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('Disciplines'),
        open: true
      };
    }
    case GET_SUBTOPICS_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('Subtopics'),
        open: true
      };
    }
    case GET_MICROCOMPETENCIES_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('Microcompetencies'),
        open: true
      };
    }
    case GET_LOCATIONS_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('Locations'),
        open: true
      };
    }
    case GET_INSTITUTION_TYPES_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('Institution Types'),
        open: true
      };
    }
    case GET_USERS_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('Users'),
        open: true
      };
    }
    case POST_MICROCOMPETENCY_ERROR: {
      return {
        ...state,
        type: types.error,
        message: createErrorMessage('Microcompetency'),
        open: true
      };
    }
    case POST_MICROCOMPETENCY_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: createSuccessMessage('Microcompetency'),
        open: true
      };
    }
    case POST_SUBTOPIC_ERROR: {
      return {
        ...state,
        type: types.error,
        message: createErrorMessage('Subtopic'),
        open: true
      };
    }
    case POST_SUBTOPIC_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: createSuccessMessage('Subtopic'),
        open: true
      };
    }
    case POST_TOPIC_ERROR: {
      return {
        ...state,
        type: types.error,
        message: createErrorMessage('Topic'),
        open: true
      };
    }
    case POST_TOPIC_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: createSuccessMessage('Topic'),
        open: true
      };
    }
    case POST_DISCIPLINE_ERROR: {
      return {
        ...state,
        type: types.error,
        message: createErrorMessage('Discipline'),
        open: true
      };
    }
    case POST_DISCIPLINE_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: createSuccessMessage('Discipline'),
        open: true
      };
    }
    case POST_CIP_CODE_ERROR: {
      return {
        ...state,
        type: types.error,
        message: createErrorMessage('CIP'),
        open: true
      };
    }
    case POST_CIP_CODE_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: createSuccessMessage('CIP'),
        open: true
      };
    }
    case PUT_MICROCOMPETENCY_ERROR: {
      return {
        ...state,
        type: types.error,
        message: updateErrorMessage('Microcompetency'),
        open: true
      };
    }
    case PUT_MICROCOMPETENCY_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('Microcompetency'),
        open: true
      };
    }
    case PUT_SUBTOPIC_ERROR: {
      return {
        ...state,
        type: types.error,
        message: updateErrorMessage('Subtopic'),
        open: true
      };
    }
    case PUT_SUBTOPIC_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('Subtopic'),
        open: true
      };
    }
    case PUT_TOPIC_ERROR: {
      return {
        ...state,
        type: types.error,
        message: updateErrorMessage('Topic'),
        open: true
      };
    }
    case PUT_TOPIC_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('Topic'),
        open: true
      };
    }
    case PUT_DISCIPLINE_ERROR: {
      return {
        ...state,
        type: types.error,
        message: updateErrorMessage('Discipline'),
        open: true
      };
    }
    case PUT_DISCIPLINE_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('Discipline'),
        open: true
      };
    }
    case PUT_CIP_CODE_ERROR: {
      return {
        ...state,
        type: types.error,
        message: updateErrorMessage('CIP'),
        open: true
      };
    }
    case PUT_CIP_CODE_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('CIP'),
        open: true
      };
    }
    case PATCH_MOVE_MICROCOMPETENCY_ERROR: {
      return {
        ...state,
        type: types.error,
        message: moveErrorMessage('microcompetency'),
        open: true
      };
    }
    case PATCH_MOVE_MICROCOMPETENCY_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: moveSuccessMessage('microcompetency'),
        open: true
      };
    }
    case PATCH_PUBLISH_MICROCOMPETENCY_ERROR: {
      return {
        ...state,
        type: types.error,
        message: publishEntityErrorMessage('microcompetency'),
        open: true
      };
    }
    case PATCH_PUBLISH_MICROCOMPETENCY_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: publishEntitySuccessMessage('microcompetency'),
        open: true
      };
    }
    case PATCH_MOVE_SUBTOPIC_ERROR: {
      return {
        ...state,
        type: types.error,
        message: moveErrorMessage('subtopic'),
        open: true
      };
    }
    case PATCH_MOVE_SUBTOPIC_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: moveSuccessMessage('subtopic'),
        open: true
      };
    }
    case PATCH_MOVE_TOPIC_ERROR: {
      return {
        ...state,
        type: types.error,
        message: moveErrorMessage('topic'),
        open: true
      };
    }
    case PATCH_MOVE_TOPIC_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: moveSuccessMessage('topic'),
        open: true
      };
    }
    case DELETE_MICROCOMPETENCY_ERROR: {
      return {
        ...state,
        type: types.error,
        message: `${deleteErrorMessage('Microcompetency')} - ${
          action.payload.error
        }`,
        open: true
      };
    }
    case DELETE_MICROCOMPETENCY_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: deleteSuccessMessage('Microcompetency'),
        open: true
      };
    }
    case DELETE_SUBTOPIC_ERROR: {
      return {
        ...state,
        type: types.error,
        message: `${deleteErrorMessage('Subtopic')} - ${action.payload.error}`,
        open: true
      };
    }
    case DELETE_SUBTOPIC_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: `${deleteSuccessMessage('Subtopic')}`,
        open: true
      };
    }
    case DELETE_TOPIC_ERROR: {
      return {
        ...state,
        type: types.error,
        message: `${deleteErrorMessage('Topic')} - ${action.payload.error}`,
        open: true
      };
    }
    case DELETE_TOPIC_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: deleteSuccessMessage('Topic'),
        open: true
      };
    }
    case DELETE_DISCIPLINE_ERROR: {
      return {
        ...state,
        type: types.error,
        message: `${deleteErrorMessage('Discipline')} - ${
          action.payload.error
        }`,
        open: true
      };
    }
    case DELETE_DISCIPLINE_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: deleteSuccessMessage('Discipline'),
        open: true
      };
    }
    case DELETE_CIP_CODE_ERROR: {
      return {
        ...state,
        type: types.error,
        message: `${deleteErrorMessage('CIP')} - ${action.payload.error}`,
        open: true
      };
    }
    case DELETE_CIP_CODE_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: deleteSuccessMessage('CIP'),
        open: true
      };
    }
    case POST_COUNTRY_ERROR: {
      return {
        ...state,
        type: types.error,
        message: createErrorMessage('Country'),
        open: true
      };
    }
    case POST_COUNTRY_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: createSuccessMessage('Country'),
        open: true
      };
    }
    case POST_STATE_PROVINCE_ERROR: {
      return {
        ...state,
        type: types.error,
        message: createErrorMessage('State/Province'),
        open: true
      };
    }
    case POST_STATE_PROVINCE_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: createSuccessMessage('State/Province'),
        open: true
      };
    }
    case PUT_COUNTRY_ERROR: {
      return {
        ...state,
        type: types.error,
        message: updateErrorMessage('Country'),
        open: true
      };
    }
    case PUT_COUNTRY_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('Country'),
        open: true
      };
    }
    case PUT_STATE_PROVINCE_ERROR: {
      return {
        ...state,
        type: types.error,
        message: updateErrorMessage('State/Province'),
        open: true
      };
    }
    case PUT_STATE_PROVINCE_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('State/Province'),
        open: true
      };
    }
    case DELETE_COUNTRY_ERROR: {
      return {
        ...state,
        type: types.error,
        message: deleteErrorMessage('Country'),
        open: true
      };
    }
    case DELETE_COUNTRY_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: deleteSuccessMessage('Country'),
        open: true
      };
    }
    case DELETE_STATE_PROVINCE_ERROR: {
      return {
        ...state,
        type: types.error,
        message: deleteErrorMessage('State/Province'),
        open: true
      };
    }
    case DELETE_STATE_PROVINCE_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: deleteSuccessMessage('State/Province'),
        open: true
      };
    }
    case POST_INSTITUTION_TYPE_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : createErrorMessage('Institution Type'),
        open: true
      };
    }
    case POST_INSTITUTION_TYPE_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: createSuccessMessage('Institution Type'),
        open: true
      };
    }
    case POST_INSTITUTION_SUBTYPE_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : createErrorMessage('Institution Subtype'),
        open: true
      };
    }
    case POST_INSTITUTION_SUBTYPE_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: createSuccessMessage('Institution Subtype'),
        open: true
      };
    }
    case PUT_INSTITUTION_TYPE_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : updateErrorMessage('Institution Type'),
        open: true
      };
    }
    case PUT_INSTITUTION_TYPE_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('Institution Type'),
        open: true
      };
    }
    case PUT_INSTITUTION_SUBTYPE_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : updateErrorMessage('Institution Subtype'),
        open: true
      };
    }
    case PUT_INSTITUTION_SUBTYPE_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('Institution Subtype'),
        open: true
      };
    }
    case PATCH_MOVE_INSTITUTION_TYPE_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : moveErrorMessage('Institution Type'),
        open: true
      };
    }
    case PATCH_MOVE_INSTITUTION_TYPE_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: moveSuccessMessage('Institution Type'),
        open: true
      };
    }
    case DELETE_INSTITUTION_TYPE_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : deleteErrorMessage('Institution Type'),
        open: true
      };
    }
    case DELETE_INSTITUTION_TYPE_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: deleteSuccessMessage('Institution Type'),
        open: true
      };
    }
    case DELETE_INSTITUTION_SUBTYPE_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : deleteErrorMessage('Institution Subtype'),
        open: true
      };
    }
    case DELETE_INSTITUTION_SUBTYPE_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: deleteSuccessMessage('Institution Subtype'),
        open: true
      };
    }
    case GET_ACCREDITATION_AGENCIES_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('Accreditation Agencies'),
        open: true
      };
    }
    case POST_ACCREDITATION_AGENCY_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: createSuccessMessage('Accreditation Agency'),
        open: true
      };
    }
    case POST_ACCREDITATION_AGENCY_ERROR: {
      return {
        ...state,
        type: types.error,
        message: createErrorMessage('Accreditation Agency'),
        open: true
      };
    }
    case PUT_ACCREDITATION_AGENCY_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('Accreditation Agency'),
        open: true
      };
    }
    case PUT_ACCREDITATION_AGENCY_ERROR: {
      return {
        ...state,
        type: types.error,
        message: updateErrorMessage('Accreditation Agency'),
        open: true
      };
    }
    case DELETE_ACCREDITATION_AGENCY_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : deleteErrorMessage('Accreditation Agency'),
        open: true
      };
    }
    case DELETE_ACCREDITATION_AGENCY_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: deleteSuccessMessage('Accreditation Agency'),
        open: true
      };
    }
    case GET_INSTITUTIONAL_SYSTEMS_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('Institutional Systems'),
        open: true
      };
    }
    case POST_INSTITUTIONAL_SYSTEM_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: createSuccessMessage('Institutional System'),
        open: true
      };
    }
    case POST_INSTITUTIONAL_SYSTEM_ERROR: {
      return {
        ...state,
        type: types.error,
        message: createErrorMessage('Institutional System'),
        open: true
      };
    }
    case PUT_INSTITUTIONAL_SYSTEM_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('Institutional System'),
        open: true
      };
    }
    case PUT_INSTITUTIONAL_SYSTEM_ERROR: {
      return {
        ...state,
        type: types.error,
        message: updateErrorMessage('Institutional System'),
        open: true
      };
    }
    case DELETE_INSTITUTIONAL_SYSTEM_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : deleteErrorMessage('Institutional System'),
        open: true
      };
    }
    case DELETE_INSTITUTIONAL_SYSTEM_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: deleteSuccessMessage('Institutional System'),
        open: true
      };
    }
    case GET_INSTITUTIONS_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('Institutions'),
        open: true
      };
    }
    case POST_INSTITUTION_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: createSuccessMessage('Institution'),
        open: true
      };
    }
    case POST_INSTITUTION_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : deleteErrorMessage('Institution'),
        open: true
      };
    }
    case PUT_INSTITUTION_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('Institution'),
        open: true
      };
    }
    case PUT_INSTITUTION_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : deleteErrorMessage('Institution'),
        open: true
      };
    }
    case DELETE_INSTITUTION_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : deleteErrorMessage('Institution'),
        open: true
      };
    }
    case DELETE_INSTITUTION_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: deleteSuccessMessage('Institution'),
        open: true
      };
    }
    case GET_PROGRAM_CIPCODES_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('Program CIP codes'),
        open: true
      };
    }
    case POST_PROGRAM_CIPCODE_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: createSuccessMessage('Program CIP code'),
        open: true
      };
    }
    case POST_PROGRAM_CIPCODE_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : deleteErrorMessage('Program CIP code'),
        open: true
      };
    }
    case PUT_PROGRAM_CIPCODE_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('Program CIP code'),
        open: true
      };
    }
    case PUT_PROGRAM_CIPCODE_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : deleteErrorMessage('Program CIP code'),
        open: true
      };
    }
    case DELETE_PROGRAM_CIPCODE_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : deleteErrorMessage('Program CIP code'),
        open: true
      };
    }
    case DELETE_PROGRAM_CIPCODE_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: deleteSuccessMessage('Program CIP code'),
        open: true
      };
    }
    case POST_PROGRAM_DISCIPLINE_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: createSuccessMessage('Program Discipline'),
        open: true
      };
    }
    case POST_PROGRAM_DISCIPLINE_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : deleteErrorMessage('Program Discipline'),
        open: true
      };
    }
    case PUT_PROGRAM_DISCIPLINE_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('Program Discipline'),
        open: true
      };
    }
    case PUT_PROGRAM_DISCIPLINE_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : deleteErrorMessage('Program Discipline'),
        open: true
      };
    }
    case DELETE_PROGRAM_DISCIPLINE_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : deleteErrorMessage('Program Discipline'),
        open: true
      };
    }
    case DELETE_PROGRAM_DISCIPLINE_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: deleteSuccessMessage('Program Discipline'),
        open: true
      };
    }
    case GET_PROGRAM_CODES_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('Program Codes'),
        open: true
      };
    }
    case GET_PROGRAM_DISCIPLINE_CODES_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('Program Codes'),
        open: true
      };
    }
    case POST_PROGRAM_CODE_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : createErrorMessage('Program Code'),
        open: true
      };
    }
    case POST_PROGRAM_CODE_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: createSuccessMessage('Program Code'),
        open: true
      };
    }
    case PUT_PROGRAM_CODE_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : updateErrorMessage('Program Code'),
        open: true
      };
    }
    case PUT_PROGRAM_CODE_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('Program Code'),
        open: true
      };
    }
    case PATCH_PUBLISH_PROGRAM_CODE_ERROR: {
      return {
        ...state,
        type: types.error,
        message: publishEntityErrorMessage('Program Code'),
        open: true
      };
    }
    case PATCH_PUBLISH_PROGRAM_CODE_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: publishEntitySuccessMessage('Program Code'),
        open: true
      };
    }
    case DELETE_PROGRAM_CODE_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : deleteErrorMessage('Program Code'),
        open: true
      };
    }
    case DELETE_PROGRAM_CODE_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: deleteSuccessMessage('Program Code'),
        open: true
      };
    }
    case SEARCH_PROGRAM_CODES_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : 'Error: unable to complete search for Program Codes',
        open: true
      };
    }
    case GET_DEGREE_LEVELS_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('Degree Levels'),
        open: true
      };
    }
    case POST_DEGREE_LEVEL_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : createErrorMessage('Degree Level'),
        open: true
      };
    }
    case POST_DEGREE_LEVEL_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: createSuccessMessage('Degree Level'),
        open: true
      };
    }
    case PUT_DEGREE_LEVEL_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : updateErrorMessage('Degree Level'),
        open: true
      };
    }
    case PUT_DEGREE_LEVEL_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('Degree Level'),
        open: true
      };
    }
    case PATCH_MOVE_DEGREE_LEVEL_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : moveErrorMessage('Degree Level'),
        open: true
      };
    }
    case PATCH_MOVE_DEGREE_LEVEL_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: moveSuccessMessage('Degree Level'),
        open: true
      };
    }
    case DELETE_DEGREE_LEVEL_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : deleteErrorMessage('Degree Level'),
        open: true
      };
    }
    case DELETE_DEGREE_LEVEL_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: deleteSuccessMessage('Degree Level'),
        open: true
      };
    }
    /* */
    case GET_INSTITUTION_ID_TYPES_ERROR: {
      return {
        ...state,
        type: types.error,
        message: fetchError('Institution ID Types'),
        open: true
      };
    }
    case POST_INSTITUTION_ID_TYPE_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : createErrorMessage('Institution ID Type'),
        open: true
      };
    }
    case POST_INSTITUTION_ID_TYPE_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: createSuccessMessage('Institution ID Type'),
        open: true
      };
    }
    case PUT_INSTITUTION_ID_TYPE_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : updateErrorMessage('Institution ID Type'),
        open: true
      };
    }
    case PUT_INSTITUTION_ID_TYPE_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: updateSuccessMessage('Institution ID Type'),
        open: true
      };
    }
    case DELETE_INSTITUTION_ID_TYPE_ERROR: {
      return {
        ...state,
        type: types.error,
        message:
          action.payload.error && action.payload.error.text
            ? action.payload.error.text
            : deleteErrorMessage('Institution ID Type'),
        open: true
      };
    }
    case DELETE_INSTITUTION_ID_TYPE_SUCCESS: {
      return {
        ...state,
        type: types.success,
        message: deleteSuccessMessage('Institution ID Type'),
        open: true
      };
    }
    case NEW_GENERAL_ERROR: {
      return {
        ...state,
        type: types.error,
        message: action.payload.error,
        open: true
      };
    }
    case OPEN_NOTIFICATION: {
      return {
        ...state,
        open: true
      };
    }
    case CLOSE_NOTIFICATION: {
      return {
        ...INITIAL_STATE
      };
    }
    default:
      return state;
  }
};

export default notificationReducer;
export { types, INITIAL_STATE };
