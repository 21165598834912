import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Discipline from './Discipline';
import { doGetTopics } from '../../../../redux/actions/topicActions';
import { doDeleteDiscipline } from '../../../../redux/actions/disciplineActions';
import { makeDisciplineTopicsSelector } from '../../../../redux/selectors/topicSelectors';
import { doGeneralErrorNotification } from '../../../../redux/actions/notificationActions';
import { generateNewTopicCode } from '../../../../helpers/utilities';
import { doClearSuccessfullyCreatedTopic } from '../../../../redux/actions/successfulCreationActions';
import { doClearSuccessfullyUpdatedTopic } from '../../../../redux/actions/successfulUpdateActions';

class DisciplineContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newTopic: null,
      addTopicAfterFetch: false,
      view: '',
      isDeleteModalOpen: false
    };
    this.addTopic = this.addTopic.bind(this);
    this.toggleEditView = this.toggleEditView.bind(this);
    this.getDisciplineTopics = this.getDisciplineTopics.bind(this);
    this.clearAddTopicAfterFetch = this.clearAddTopicAfterFetch.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { discipline } = this.props;
    if (
      nextProps.successfullyUpdatedTopic &&
      nextProps.successfullyUpdatedTopic.disciplineUuid === discipline.uuid
    ) {
      this.props.onClearUpdatedTopic();

      return true;
    }

    if (nextState.view !== this.state.view) {
      return true;
    }

    return true;
  }

  componentDidUpdate() {
    const { successfullyCreatedTopic, discipline, topics } = this.props;
    const { addTopicAfterFetch } = this.state;

    if (addTopicAfterFetch && topics.length === Number(discipline.notopics)) {
      this.addTopic(
        addTopicAfterFetch.disciplineUuid,
        addTopicAfterFetch.isLevelTwo,
        addTopicAfterFetch.levelOneParentCode
      );
      this.clearAddTopicAfterFetch();
    }

    if (
      successfullyCreatedTopic &&
      successfullyCreatedTopic.disciplineUuid === discipline.uuid &&
      this.state.newTopic
    ) {
      this.clearNewTopic();
      this.props.onClearCreatedTopic();
    }
  }

  clearAddTopicAfterFetch = () => {
    this.setState({
      addTopicAfterFetch: false
    });
  };

  clearNewTopic = () => {
    this.setState({ newTopic: null });
  };

  addTopic = (disciplineUuid, isLevelTwo, levelOneParentCode) => {
    const { topics, discipline } = this.props;
    const { newTopic } = this.state;

    if (topics.length < 1 && topics.length !== Number(discipline.notopics)) {
      this.getDisciplineTopics(disciplineUuid);
      this.setState({
        addTopicAfterFetch: {
          disciplineUuid,
          isLevelTwo,
          levelOneParentCode
        }
      });
      return;
    }

    if (newTopic) {
      this.props.onGeneralErrorNotification(
        'Cannot create more than one Microcompetency simultaneously'
      );
      return;
    }

    const newCodes = generateNewTopicCode(
      topics,
      isLevelTwo,
      levelOneParentCode
    );

    if (newCodes.error) {
      this.props.onGeneralErrorNotification(newCodes.message);
      return;
    }

    const emptyTopic = {
      level_one_code: newCodes.level_one_code,
      level_two_code: newCodes.level_two_code,
      title: '',
      disciplineUuid,
      isNew: true
    };

    this.setState({
      newTopic: emptyTopic
    });
  };

  getDisciplineTopics = disciplineUuid => {
    const { topics } = this.props;
    if (!topics || topics.length < 1) {
      this.props.onFetchTopics(disciplineUuid);
    }
  };

  toggleEditView = () => {
    this.setState(prevState => ({
      view: prevState.view !== 'EDIT' ? 'EDIT' : ''
    }));
  };

  handleDeleteClick = () => {
    this.setState({
      isDeleteModalOpen: true
    });
  };

  handleDeleteModalClose = () => {
    this.setState({
      isDeleteModalOpen: false
    });
  };

  handleDelete = () => {
    const { discipline } = this.props;

    this.props.onDelete(discipline.uuid);
    this.handleDeleteModalClose();
  };

  render() {
    const { discipline, cip, addDiscipline, topics } = this.props;
    const { newTopic, view, isDeleteModalOpen } = this.state;

    const allTopics = newTopic && topics ? topics.concat(newTopic) : topics;

    return (
      <Discipline
        discipline={discipline}
        cip={cip}
        view={view}
        topics={allTopics}
        isDeleteModalOpen={isDeleteModalOpen}
        getDisciplineTopics={this.getDisciplineTopics}
        addTopic={this.addTopic}
        addDiscipline={addDiscipline}
        handleDeleteClick={this.handleDeleteClick}
        handleDeleteModalClose={this.handleDeleteModalClose}
        handleDelete={this.handleDelete}
        clearNewTopic={this.clearNewTopic}
        toggleEditView={this.toggleEditView}
      />
    );
  }
}

DisciplineContainer.propTypes = {
  discipline: PropTypes.object,
  topics: PropTypes.arrayOf(PropTypes.object),
  cip: PropTypes.object,
  successfullyCreatedTopic: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
  successfullyUpdatedTopic: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
  onDelete: PropTypes.func,
  addDiscipline: PropTypes.func,
  onClearCreatedTopic: PropTypes.func,
  onClearUpdatedTopic: PropTypes.func,
  onFetchTopics: PropTypes.func,
  onGeneralErrorNotification: PropTypes.func
};

DisciplineContainer.defaultProps = {
  discipline: {},
  topics: [],
  cip: {},
  successfullyCreatedTopic: false,
  successfullyUpdatedTopic: false,
  onDelete: undefined,
  addDiscipline: undefined,
  onClearCreatedTopic: undefined,
  onClearUpdatedTopic: undefined,
  onFetchTopics: undefined,
  onGeneralErrorNotification: undefined
};

const makeMapStateToProps = () => {
  const getDisciplineTopics = makeDisciplineTopicsSelector();
  const mapStateToProps = (state, props) => ({
    topics: getDisciplineTopics(state, props),
    successfullyCreatedTopic:
      state.successfulCreationState.successfullyCreatedTopic,
    successfullyUpdatedTopic:
      state.successfulUpdateState.successfullyUpdatedTopic
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  onFetchTopics: disciplineUuid => dispatch(doGetTopics(disciplineUuid)),
  onClearCreatedTopic: () => dispatch(doClearSuccessfullyCreatedTopic()),
  onClearUpdatedTopic: () => dispatch(doClearSuccessfullyUpdatedTopic()),
  onDelete: uuid => dispatch(doDeleteDiscipline(uuid)),
  onGeneralErrorNotification: errorMessage =>
    dispatch(doGeneralErrorNotification(errorMessage))
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(DisciplineContainer);
export { DisciplineContainer, mapDispatchToProps };
