/* eslint no-param-reassign: off */
import { Model, attr, fk } from 'redux-orm';
import {
  GET_LOCATIONS_SUCCESS,
  POST_STATE_PROVINCE_SUCCESS,
  PUT_STATE_PROVINCE_SUCCESS,
  DELETE_COUNTRY_SUCCESS,
  DELETE_STATE_PROVINCE_SUCCESS
} from '../actions/actionTypes';

class StateProvince extends Model {
  static reducer(action, StateProvince, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_LOCATIONS_SUCCESS: {
        if (payload.stateProvinces.length !== StateProvince.all().count()) {
          StateProvince.all().delete();
        }

        const stateProvinces = [...payload.stateProvinces];
        return stateProvinces.map(state => {
          const stateUpsert = StateProvince.upsert(state);
          return stateUpsert;
        });
      }
      case POST_STATE_PROVINCE_SUCCESS: {
        const { createdStateProvince } = payload;
        return StateProvince.upsert(createdStateProvince);
      }
      case PUT_STATE_PROVINCE_SUCCESS: {
        const { updatedStateProvince } = payload;
        return StateProvince.upsert(updatedStateProvince);
      }
      case DELETE_COUNTRY_SUCCESS: {
        const { deletedCountry } = payload;
        return StateProvince.all()
          .filter(stateProvince => {
            if (
              !stateProvince.countryUuid ||
              stateProvince.countryUuid === deletedCountry.uuid
            ) {
              return stateProvince;
            }
            return false;
          })
          .delete();
      }
      case DELETE_STATE_PROVINCE_SUCCESS: {
        const { deletedStateProvince } = payload;
        return StateProvince.withId(deletedStateProvince.uuid).delete();
      }
      default:
        return session;
    }
  }
}

StateProvince.fields = {
  uuid: attr(),
  name: attr(),
  code: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  countryUuid: fk('Country', 'stateProvinces')
};

StateProvince.modelName = 'StateProvince';
StateProvince.options = {
  idAttribute: 'uuid'
};

export default StateProvince;
