import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TopicForm from '../TopicForm';
import { RowSummary } from '../../../SharedSC/DashboardSC';

const TopicRowSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const NewTopic = ({
  newTopic,
  cip,
  discipline,
  showValidationErrors,
  handleChange,
  onPostCreateTopic,
  clearNewTopic
}) => {
  const cipCode = cip.code;
  const disciplineCode = discipline.code;
  const newTopicCode = `${newTopic.level_one_code}${newTopic.level_two_code}`;

  return (
    <TopicRowSC>
      <RowSummary>
        <TopicForm
          code={`${cipCode}.${disciplineCode}.${newTopicCode}`}
          newTopic={newTopic}
          buttonText="Create"
          showValidationErrors={showValidationErrors}
          handleChange={event => handleChange(event)}
          onSubmit={onPostCreateTopic}
          closeForm={clearNewTopic}
        />
      </RowSummary>
    </TopicRowSC>
  );
};

NewTopic.propTypes = {
  newTopic: PropTypes.object,
  cip: PropTypes.object,
  discipline: PropTypes.object,
  showValidationErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  onPostCreateTopic: PropTypes.func,
  clearNewTopic: PropTypes.func
};
NewTopic.defaultProps = {
  newTopic: {},
  cip: {},
  discipline: {},
  showValidationErrors: false,
  handleChange: undefined,
  onPostCreateTopic: undefined,
  clearNewTopic: undefined
};

export default NewTopic;
