import React from 'react';
import PropTypes from 'prop-types';

import { HeaderText } from '../../../SharedSC/HeaderSC';
import {
  SimpleModalSC,
  ModalHeaderText,
  ModalBody,
  FormLayout
} from '../../../SharedSC/ModalForm';
import { ModalHeader } from '../../../../Forms/FormComponents';

import InstitutionalSystemForm from '../InstitutionalSystemForm/InstitutionalSystemFormContainer';
import {
  validateEntityName,
  validateUrl
} from '../../../../../helpers/validateGeneric';

const EditInstitutionalSystem = ({
  existingInstitutionalSystem,
  isEditSystemModalOpen,
  locations,
  types,
  handleModalClose,
  handleChange,
  handleUserFieldChange,
  onPutUpdate,
  allInstitutionalSystems,
  showValidationErrors
}) => {
  const onSystemModalSubmit = () => {
    onPutUpdate();
  };
  const allSiblingSystems =
    allInstitutionalSystems && allInstitutionalSystems.length > 0
      ? allInstitutionalSystems.filter(
          system => system.uuid !== existingInstitutionalSystem.uuid
        )
      : [];
  return (
    <SimpleModalSC
      open={isEditSystemModalOpen}
      onClose={handleModalClose}
      maxWidth="lg"
      fullWidth
    >
      <ModalBody>
        <ModalHeader>
          <HeaderText>
            <ModalHeaderText>Edit System</ModalHeaderText>
          </HeaderText>
        </ModalHeader>
        <FormLayout>
          <InstitutionalSystemForm
            institutionalSystem={existingInstitutionalSystem}
            buttonText="Update"
            isEditForm
            locations={locations}
            types={types}
            showValidationErrors={showValidationErrors}
            validateName={() =>
              validateEntityName(
                existingInstitutionalSystem.name,
                allSiblingSystems
              )
            }
            validateWebsiteAddress={() =>
              validateUrl(existingInstitutionalSystem.websiteAddress, false)
            }
            handleChange={event => handleChange(event)}
            handleUserFieldChange={handleUserFieldChange}
            onSubmit={onSystemModalSubmit}
            closeForm={() => handleModalClose()}
          />
        </FormLayout>
      </ModalBody>
    </SimpleModalSC>
  );
};

EditInstitutionalSystem.propTypes = {
  existingInstitutionalSystem: PropTypes.object,
  locations: PropTypes.object,
  types: PropTypes.object,
  isEditSystemModalOpen: PropTypes.bool,
  allInstitutionalSystems: PropTypes.arrayOf(PropTypes.object),
  showValidationErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  handleUserFieldChange: PropTypes.func,
  handleModalClose: PropTypes.func,
  onPutUpdate: PropTypes.func
};
EditInstitutionalSystem.defaultProps = {
  existingInstitutionalSystem: {},
  locations: {
    country: {},
    stateProvince: {}
  },
  types: {
    institutionType: {},
    institutionSubtype: {}
  },
  isEditSystemModalOpen: false,
  allInstitutionalSystems: [],
  showValidationErrors: false,
  handleModalClose: undefined,
  handleChange: undefined,
  handleUserFieldChange: undefined,
  onPutUpdate: undefined
};

export default EditInstitutionalSystem;
