/* eslint no-unused-vars: off */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NewCountry from './NewCountry';
import { doPostCountry } from '../../../../../redux/actions/locationActions';
import { countriesSelector } from '../../../../../redux/selectors/locationsSelectors';
import {
  validateLocationName,
  validateCountryCode
} from '../../../../../helpers/validateLocations';
import { allFieldsAreValid } from '../../../../../helpers/validateGeneric';

class NewCountryContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      code: '',
      name: '',
      showValidationErrors: false
    };

    this.setNewCountry = this.setNewCountry.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCountrySubmission = this.handleCountrySubmission.bind(this);
  }

  componentDidMount() {
    const { code } = this.state;
    const { country } = this.props;
    this._isMounted = true;
    if (!code) {
      this.setNewCountry(country);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  setNewCountry = country => {
    const { isNew, ...newCountry } = country;
    if (this._isMounted) {
      this.setState(state => ({
        ...newCountry
      }));
    }
  };

  setValidationErrors = newCountry => {
    const { allCountries } = this.props;
    const nameError = validateLocationName(newCountry.name, allCountries);
    const codeError = validateCountryCode(newCountry.code, allCountries);
    const newValidationErrors = {
      name: nameError,
      code: codeError
    };

    return newValidationErrors;
  };

  handleCountrySubmission = () => {
    const { showValidationErrors, ...newCountry } = this.state;

    const newValidationErrors = this.setValidationErrors(newCountry);

    if (allFieldsAreValid(newValidationErrors)) {
      this.props.onPostCreateCountry(newCountry);
      this.setState({ showValidationErrors: false });
      this.props.clearNewCountry();
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const { code, name, showValidationErrors } = this.state;
    const { country, allCountries, clearNewCountry } = this.props;

    const newCountry = {
      code,
      name
    };

    return (
      <NewCountry
        newCountry={newCountry}
        handleChange={this.handleChange}
        onPostCreateCountry={this.handleCountrySubmission}
        clearNewCountry={clearNewCountry}
        allCountries={allCountries}
        showValidationErrors={showValidationErrors}
      />
    );
  }
}

NewCountryContainer.propTypes = {
  country: PropTypes.object,
  allCountries: PropTypes.arrayOf(PropTypes.object),
  onPostCreateCountry: PropTypes.func,
  clearNewCountry: PropTypes.func
};
NewCountryContainer.defaultProps = {
  country: {},
  allCountries: [],
  onPostCreateCountry: undefined,
  clearNewCountry: undefined
};

const mapStateToProps = (state, props) => ({
  allCountries: countriesSelector(state, props)
});

const mapDispatchToProps = dispatch => ({
  onPostCreateCountry: newCountry => dispatch(doPostCountry(newCountry))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewCountryContainer);
export { NewCountryContainer };
