import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Body1 } from '@xcomp/xcomp-design-library';
import AccordionRowContainer from '../../../AccordionRow/AccordionRowContainer';
import InstitutionContainer from '../Institution/InstitutionContainer';
import InstitutionalSystemDetails from './InstitutionalSystemDetails/InstitutionalSystemDetails';
import DeleteModal from '../../../DialogModal/DeleteModal/DeleteModal';

const NoInstitutionsMessage = styled(({ rowOffset, ...rest }) => (
  <div {...rest} />
))`
  margin-left: ${props => props.rowOffset};
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  font-weight: 300;
`;

const Body1SC = styled(Body1)`
  margin-top: 0;
`;

const InstitutionalSystem = ({
  className,
  institutionalSystem,
  institutions,
  isUnaffiliated,
  onOpenEditSystemModal,
  onOpenNewInstitutionModal,
  isDeleteModalOpen,
  handleDeleteClick,
  handleDeleteModalClose,
  handleSystemDelete
}) => {
  const nameString = `${institutionalSystem.name}`;

  const systemUuid = isUnaffiliated ? null : institutionalSystem.uuid;
  const systemActions = !isUnaffiliated
    ? [
        {
          label: 'Add Institution',
          onClick: () =>
            onOpenNewInstitutionModal(
              systemUuid,
              institutionalSystem.stateProvinceUuid,
              institutionalSystem.institutionSubtypeUuid
            ),
          shouldOpenOnClick: false,
          icon: 'add'
        },
        {
          label: 'Edit',
          onClick: onOpenEditSystemModal,
          shouldOpenOnClick: false,
          icon: 'edit'
        },
        {
          label: 'Delete',
          onClick: handleDeleteClick,
          shouldOpenOnClick: false,
          icon: 'delete'
        }
      ]
    : [
        {
          label: 'Add Institution',
          onClick: () =>
            onOpenNewInstitutionModal(
              systemUuid,
              institutionalSystem.stateProvinceUuid,
              institutionalSystem.institutionSubtypeUuid
            ),
          shouldOpenOnClick: false,
          icon: 'add'
        }
      ];

  return (
    <>
      <DeleteModal
        modalOpen={isDeleteModalOpen}
        handleModalClose={() => handleDeleteModalClose()}
        handleDelete={() => handleSystemDelete()}
        type="Institutional System"
      />
      <AccordionRowContainer
        className={className}
        label="System"
        columnTwo={nameString}
        actions={systemActions}
        expandWidth="42px"
        height="38px"
        rowOffset="150px"
        columnGutters="0.75rem"
      >
        <>
          {!isUnaffiliated && (
            <InstitutionalSystemDetails
              institutionalSystem={institutionalSystem}
              rowOffset="230px"
            />
          )}
          {institutions && institutions.length > 0 ? (
            institutions.map(institution => (
              <InstitutionContainer
                key={institution.uuid}
                institution={institution}
                isUnaffiliated={isUnaffiliated}
                institutionalSystem={institutionalSystem}
              />
            ))
          ) : (
            <NoInstitutionsMessage rowOffset="187px">
              <Body1SC>No institutions exist for this system</Body1SC>
            </NoInstitutionsMessage>
          )}
        </>
      </AccordionRowContainer>
    </>
  );
};

InstitutionalSystem.propTypes = {
  institutionalSystem: PropTypes.object.isRequired,
  institutions: PropTypes.arrayOf(PropTypes.object),
  isUnaffiliated: PropTypes.bool,
  className: PropTypes.string,
  isDeleteModalOpen: PropTypes.bool,
  handleDeleteClick: PropTypes.func,
  handleDeleteModalClose: PropTypes.func,
  handleSystemDelete: PropTypes.func,
  onOpenEditSystemModal: PropTypes.func,
  onOpenNewInstitutionModal: PropTypes.func
};

InstitutionalSystem.defaultProps = {
  institutions: [],
  isUnaffiliated: false,
  className: '',
  isDeleteModalOpen: false,
  handleDeleteClick: undefined,
  handleDeleteModalClose: undefined,
  handleSystemDelete: undefined,
  onOpenEditSystemModal: undefined,
  onOpenNewInstitutionModal: undefined
};

export default InstitutionalSystem;
