import React from 'react';
import PropTypes from 'prop-types';
import {
  validateCodeTitle,
  validateCipcode
} from '../../../../helpers/validateCodes';
import { FormTop, FormBottom } from '../../SharedSC/FormSC';

import {
  Column,
  ElevatedForm,
  CancelButton,
  SubmitButton,
  TextFieldWithError
} from '../../../Forms/FormComponents';

const CIPCodeForm = ({
  cip,
  allCipcodes,
  buttonText,
  handleChange,
  onSubmit,
  showValidationErrors,
  closeForm
}) => (
  <ElevatedForm>
    <FormTop>
      <Column size="21%">
        <TextFieldWithError
          id="cipcodeCode"
          name="code"
          value={cip.code}
          onChange={event => handleChange(event)}
          margin="normal"
          InputLabelProps={{ shrink: true }}
          label="CIP Code*"
          placeholder=""
          variant="outlined"
          validate={() => validateCipcode(cip.code, allCipcodes)}
          showValidationErrors={showValidationErrors}
          paddingRight="1rem"
        />
      </Column>
      <Column size="79%">
        <TextFieldWithError
          id="cipcodeTitle"
          name="title"
          value={cip.title}
          onChange={event => handleChange(event)}
          margin="normal"
          InputLabelProps={{ shrink: true }}
          label="Code Title*"
          placeholder=""
          variant="outlined"
          validate={validateCodeTitle}
          showValidationErrors={showValidationErrors}
        />
      </Column>
    </FormTop>
    <FormBottom>
      <CancelButton onClick={closeForm} noHoverBg>
        Cancel
      </CancelButton>
      <SubmitButton onClick={onSubmit}>{buttonText}</SubmitButton>
    </FormBottom>
  </ElevatedForm>
);

CIPCodeForm.propTypes = {
  cip: PropTypes.object,
  buttonText: PropTypes.string,
  allCipcodes: PropTypes.arrayOf(PropTypes.object),
  showValidationErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  onSubmit: PropTypes.func,
  closeForm: PropTypes.func
};
CIPCodeForm.defaultProps = {
  cip: {},
  buttonText: 'Create',
  allCipcodes: [],
  showValidationErrors: false,
  handleChange: undefined,
  onSubmit: undefined,
  closeForm: undefined
};

export default CIPCodeForm;
