import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MicrocompetencyForm from '../MicrocompetencyForm';
import { RowSummary } from '../../../SharedSC/DashboardSC';

const MicrocompetencyRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const EditMicrocompetency = ({
  existingMicro,
  cip,
  discipline,
  topic,
  subtopic,
  showValidationErrors,
  handleChange,
  onPostUpdateMicrocompetency,
  toggleEditView
}) => {
  const cipCode = cip.code;
  const disciplineCode = discipline.code;
  const topicCode = `${topic.level_one_code}${topic.level_two_code}`;
  const subtopicCode = `${subtopic.level_one_code}${subtopic.level_two_code}`;
  const existingMicroCode = `${existingMicro.level_one_code}${
    existingMicro.level_two_code
  }`;

  return (
    <MicrocompetencyRow>
      <RowSummary>
        <MicrocompetencyForm
          code={`${cipCode}.${disciplineCode}.${topicCode}.${subtopicCode}.${existingMicroCode}`}
          micro={existingMicro}
          buttonText="Update"
          handleChange={event => handleChange(event)}
          onSubmit={onPostUpdateMicrocompetency}
          showValidationErrors={showValidationErrors}
          closeForm={toggleEditView}
        />
      </RowSummary>
    </MicrocompetencyRow>
  );
};

EditMicrocompetency.propTypes = {
  existingMicro: PropTypes.object,
  cip: PropTypes.object,
  discipline: PropTypes.object,
  topic: PropTypes.object,
  subtopic: PropTypes.object,
  showValidationErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  onPostUpdateMicrocompetency: PropTypes.func,
  toggleEditView: PropTypes.func
};
EditMicrocompetency.defaultProps = {
  existingMicro: {},
  cip: {},
  discipline: {},
  topic: {},
  subtopic: {},
  showValidationErrors: false,
  handleChange: undefined,
  onPostUpdateMicrocompetency: undefined,
  toggleEditView: undefined
};

export default EditMicrocompetency;
