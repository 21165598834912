import {
  CLEAR_SUCCESFULLY_CREATED_MICROCOMPETENCY,
  CLEAR_SUCCESFULLY_CREATED_SUBTOPIC,
  CLEAR_SUCCESFULLY_CREATED_TOPIC,
  CLEAR_SUCCESFULLY_CREATED_DISCIPLINE,
  CLEAR_SUCCESFULLY_CREATED_CIP_CODE
} from './actionTypes';

const doClearSuccessfullyCreatedMicrocompetency = () => ({
  type: CLEAR_SUCCESFULLY_CREATED_MICROCOMPETENCY
});

const doClearSuccessfullyCreatedSubtopic = () => ({
  type: CLEAR_SUCCESFULLY_CREATED_SUBTOPIC
});

const doClearSuccessfullyCreatedTopic = () => ({
  type: CLEAR_SUCCESFULLY_CREATED_TOPIC
});

const doClearSuccessfullyCreatedDiscipline = () => ({
  type: CLEAR_SUCCESFULLY_CREATED_DISCIPLINE
});

const doClearSuccessfullyCreatedCipcode = () => ({
  type: CLEAR_SUCCESFULLY_CREATED_CIP_CODE
});

export {
  doClearSuccessfullyCreatedMicrocompetency,
  doClearSuccessfullyCreatedSubtopic,
  doClearSuccessfullyCreatedTopic,
  doClearSuccessfullyCreatedDiscipline,
  doClearSuccessfullyCreatedCipcode
};
