import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { MuiThemeProvider } from '@material-ui/core/styles';

import './App.css';
import { createMuiTheme, createTheme } from '@xcomp/xcomp-design-library';
import Navigation from '../Navigation/Navigation';
import NoMatch404 from './NoMatch404';
import WelcomeBackDashboard from '../DefinitionDashboards/Home/WelcomeBackDashboard';
import MMDashboardContainer from '../DefinitionDashboards/Microcompetencies/MMDashboardContainer';
import LocationDashboardContainer from '../DefinitionDashboards/Locations/LocationDashboardContainer';
import ProgramDashboardContainer from '../DefinitionDashboards/Programs/ProgramDashboardContainer';
import InstitutionTypeDashboardContainer from '../DefinitionDashboards/InstitutionTypes/InstitutionTypeDashboardContainer';
import InstitutionIdTypesDashboardContainer from '../DefinitionDashboards/InstitutionIdTypes/InstitutionIdTypesDashboardContainer';
import AccreditationAgencyDashboardContainer from '../DefinitionDashboards/AccreditationAgencies/AccreditationAgencyDashboardContainer';
import DegreeLevelDashboardContainer from '../DefinitionDashboards/DegreeLevels/DegreeLevelDashboardContainer';
import InstitutionsDashboardContainer from '../DefinitionDashboards/Institutions/InstitutionsDashboardContainer';
import StyleGuide from '../Library/StyleGuide';
import Callback from "../Callback";

const BodySC = styled.div`
  margin: 0;
  background: ${props => props.theme.colors.grey[100]};
  min-height: calc(100vh - 64px);
`;

const RouterChild = styled.div`
  min-height: calc(100vh - 64px);
`;

const Main = styled.div`
  min-height: calc(100vh - 64px);
  margin-top: 64px;  
  margin-left: ${props => (props.open ? '300px' : '60px')}
  transition-duration: 0.3s;
  padding-bottom: 3rem;
`;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false
    };

    this.toggleDrawer = this.toggleDrawer.bind(this);
  }

  toggleDrawer() {
    this.setState(prevState => ({
      drawerOpen: !prevState.drawerOpen
    }));
  }

  render() {
    const { drawerOpen } = this.state;
    const theme = createTheme();

    return (
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={createMuiTheme(theme)}>
          <BodySC>
            <Router basename="app">
              <RouterChild>
                <Navigation
                  drawerOpen={drawerOpen}
                  toggleDrawer={this.toggleDrawer}
                />
                <Main open={drawerOpen} containerSize="auto">
                  <Switch>
                    <Route exact path="/" component={WelcomeBackDashboard} />
                    <Route
                      exact
                      path="/locations"
                      component={LocationDashboardContainer}
                    />
                    <Route
                      exact
                      path="/institutiontype"
                      component={InstitutionTypeDashboardContainer}
                    />
                    <Route
                      exact
                      path="/idtypes"
                      component={InstitutionIdTypesDashboardContainer}
                    />
                    <Route
                      exact
                      path="/agencies"
                      component={AccreditationAgencyDashboardContainer}
                    />
                    <Route
                      exact
                      path="/degreelevels"
                      component={DegreeLevelDashboardContainer}
                    />
                    <Route
                      exact
                      path="/microcompetency"
                      component={MMDashboardContainer}
                    />
                    <Route
                      exact
                      path="/institution"
                      component={InstitutionsDashboardContainer}
                    />
                    <Route
                      exact
                      path="/programs"
                      component={ProgramDashboardContainer}
                    />
                    <Route exact path="/styleguide" component={StyleGuide} />
                    <Route path="/callback" component={Callback} />
                    <Route component={NoMatch404} />
                  </Switch>
                </Main>
              </RouterChild>
            </Router>
          </BodySC>
        </MuiThemeProvider>
      </ThemeProvider>
    );
  }
}

export default App;
