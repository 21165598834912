import { Model, attr } from 'redux-orm';
import {
  GET_ACCREDITATION_AGENCIES_SUCCESS,
  POST_ACCREDITATION_AGENCY_SUCCESS,
  PUT_ACCREDITATION_AGENCY_SUCCESS,
  DELETE_ACCREDITATION_AGENCY_SUCCESS
} from '../actions/actionTypes';

class AccreditationAgency extends Model {
  static reducer(action, AccreditationAgency, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_ACCREDITATION_AGENCIES_SUCCESS: {
        AccreditationAgency.all().delete();
        const agencies = [...payload.accreditationAgencies];
        return agencies.map(agency => {
          const agencyUpsert = AccreditationAgency.upsert(agency);
          return agencyUpsert;
        });
      }
      case POST_ACCREDITATION_AGENCY_SUCCESS: {
        const { createdAccreditationAgency } = payload;
        return AccreditationAgency.upsert(createdAccreditationAgency);
      }
      case PUT_ACCREDITATION_AGENCY_SUCCESS: {
        const { updatedAccreditationAgency } = payload;
        return AccreditationAgency.upsert(updatedAccreditationAgency);
      }
      case DELETE_ACCREDITATION_AGENCY_SUCCESS: {
        const { deletedAccreditationAgency } = payload;
        return AccreditationAgency.withId(
          deletedAccreditationAgency.uuid
        ).delete();
      }
      default:
        return session;
    }
  }
}

AccreditationAgency.fields = {
  uuid: attr(),
  name: attr(),
  agencyType: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

AccreditationAgency.modelName = 'AccreditationAgency';
AccreditationAgency.options = {
  idAttribute: 'uuid'
};

export default AccreditationAgency;
