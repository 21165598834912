/* eslint no-param-reassign: off */
import { Model, attr, fk } from 'redux-orm';
import {
  GET_CIP_CODES_SUCCESS,
  POST_DISCIPLINE_SUCCESS,
  POST_TOPIC_SUCCESS,
  PUT_DISCIPLINE_SUCCESS,
  DELETE_DISCIPLINE_SUCCESS,
  DELETE_TOPIC_SUCCESS,
  DELETE_CIP_CODE_SUCCESS,
  PATCH_MOVE_TOPIC_SUCCESS
} from '../actions/actionTypes';

class Discipline extends Model {
  static reducer(action, Discipline, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_CIP_CODES_SUCCESS: {
        if (payload.disciplines.length !== Discipline.all().count()) {
          Discipline.all().delete();
        }

        const disciplines = [...payload.disciplines];
        return disciplines.map(disc => {
          const discUpsert = Discipline.upsert(disc);
          return discUpsert;
        });
      }
      case POST_DISCIPLINE_SUCCESS: {
        const newDiscipline = { ...payload.createdDiscipline, notopics: 0 };
        return Discipline.upsert(newDiscipline);
      }
      case PUT_DISCIPLINE_SUCCESS: {
        const updatedDiscipline = { ...payload.updatedDiscipline };
        return Discipline.upsert(updatedDiscipline);
      }
      case POST_TOPIC_SUCCESS: {
        const { disciplineUuid } = payload.createdTopic;
        Discipline.withId(disciplineUuid).notopics =
          Number(Discipline.withId(disciplineUuid).notopics) + 1;
        return session;
      }
      case DELETE_DISCIPLINE_SUCCESS: {
        const { deletedDiscipline } = payload;
        return Discipline.withId(deletedDiscipline.uuid).delete();
      }
      case DELETE_TOPIC_SUCCESS: {
        const { deletedTopic } = payload;
        Discipline.withId(deletedTopic.disciplineUuid).notopics =
          Number(Discipline.withId(deletedTopic.disciplineUuid).notopics) - 1;
        return session;
      }
      case PATCH_MOVE_TOPIC_SUCCESS: {
        const { movedCode, numberMovedCodes } = payload.updatedTopics;
        const { previousDisciplineUuid } = payload;

        if (previousDisciplineUuid !== movedCode.disciplineUuid) {
          Discipline.withId(previousDisciplineUuid).notopics =
            Number(Discipline.withId(previousDisciplineUuid).notopics) -
            numberMovedCodes;
          Discipline.withId(movedCode.disciplineUuid).notopics =
            Number(Discipline.withId(movedCode.disciplineUuid).notopics) +
            numberMovedCodes;
        }
        return session;
      }
      case DELETE_CIP_CODE_SUCCESS: {
        const { deletedCipcode } = payload;
        return Discipline.all()
          .filter(discipline => {
            if (
              !discipline.cipcodeUuid ||
              discipline.cipcodeUuid === deletedCipcode.uuid
            ) {
              return discipline;
            }
            return false;
          })
          .delete();
      }
      default:
        return session;
    }
  }
}

Discipline.fields = {
  uuid: attr(),
  title: attr(),
  code: attr(),
  createdAt: attr(),
  updatedAt: attr(),
  cipcodeUuid: fk('CipCode', 'disciplines')
};

Discipline.modelName = 'Discipline';
Discipline.options = {
  idAttribute: 'uuid'
};

export default Discipline;
