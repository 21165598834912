import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CustomTooltip from '../../../../Library/CustomTooltip';
import { sortItemsByLevelCodes } from '../../../../../helpers/arrayHelpers';
import { types } from '../../../../../redux/reducers/moveCodeReducer';
import AccordionWithBoldCode from '../../../../AccordionRow/AccordionRowSC';
import MicrocompetencyContainer from '../Microcompetency/MicrocompetencyContainer';

const SubtopicRowSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const TooltipSC = styled(CustomTooltip)`
  width: 100%;
`;

const TooltipChild = styled.div`
  width: 100%;
`;

const Subtopic = ({
  microcompetencies,
  subtopic,
  topic,
  discipline,
  cip,
  codeToMove,
  codeType,
  destinationCode,
  getSubtopicMicros,
  onCodeSelect
}) => {
  const cipCode = cip.code;
  const disciplineCode = discipline.code;
  const topicCode = `${topic.level_one_code}${topic.level_two_code}`;
  const subtopicCode = `${subtopic.level_one_code}${subtopic.level_two_code}`;
  const isCodeBold =
    subtopic.level_two_code === '00' && subtopic.level_one_code !== '00';

  const fullCode = `${cipCode}.${disciplineCode}.${topicCode}.${subtopicCode}`;
  const isGeneralCode = subtopic.level_one_code === '00';
  const isOtherCode = subtopic.level_one_code === '99';
  const isCodeSelected =
    destinationCode && destinationCode.uuid === subtopic.uuid;
  const isCodeToMove = codeToMove && codeToMove === subtopic.uuid;

  return (
    <SubtopicRowSC>
      {codeType === types.subtopic ? (
        <TooltipSC title={isCodeToMove ? 'Current subtopic selected' : ''}>
          <TooltipChild>
            <AccordionWithBoldCode
              label="Subtopic"
              columnOne={fullCode}
              columnTwo={subtopic.title}
              expandWidth="42px"
              height="38px"
              columnGutters="1.5rem"
              rowOffset="90px"
              isSelected={isCodeSelected}
              isCodeBold={isCodeBold && !isCodeToMove}
              disabled={isCodeToMove}
              onRowOpen={() => getSubtopicMicros(subtopic.uuid)}
              onSelect={() =>
                onCodeSelect(
                  subtopic.uuid,
                  fullCode,
                  isGeneralCode,
                  isOtherCode
                )
              }
              leaf
            />{' '}
          </TooltipChild>
        </TooltipSC>
      ) : (
        <AccordionWithBoldCode
          label="Subtopic"
          columnOne={fullCode}
          columnTwo={subtopic.title}
          expandWidth="42px"
          height="38px"
          columnGutters="1.5rem"
          rowOffset="90px"
          onRowOpen={() => getSubtopicMicros(subtopic.uuid)}
          isCodeBold={isCodeBold}
        >
          {microcompetencies.sort(sortItemsByLevelCodes).map(micro => (
            <MicrocompetencyContainer
              cip={cip}
              discipline={discipline}
              topic={topic}
              subtopic={subtopic}
              microcompetency={micro}
              key={micro.uuid}
            />
          ))}
        </AccordionWithBoldCode>
      )}
    </SubtopicRowSC>
  );
};

Subtopic.propTypes = {
  microcompetencies: PropTypes.arrayOf(PropTypes.object),
  subtopic: PropTypes.object,
  topic: PropTypes.object,
  discipline: PropTypes.object,
  cip: PropTypes.object,
  codeToMove: PropTypes.string,
  destinationCode: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  codeType: PropTypes.oneOf([types.topic, types.subtopic, types.micro, '']),
  getSubtopicMicros: PropTypes.func,
  onCodeSelect: PropTypes.func
};

Subtopic.defaultProps = {
  microcompetencies: [],
  subtopic: {},
  topic: {},
  discipline: {},
  cip: {},
  codeToMove: '',
  destinationCode: false,
  codeType: '',
  getSubtopicMicros: undefined,
  onCodeSelect: undefined
};

export default Subtopic;
