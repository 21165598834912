import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { doGeneralErrorNotification } from '../../../../redux/actions/notificationActions';
import { doDeleteCipcode } from '../../../../redux/actions/cipCodeActions';
import { makeCipDisciplinesSelector } from '../../../../redux/selectors/disciplineSelectors';
import { doClearSuccessfullyCreatedDiscipline } from '../../../../redux/actions/successfulCreationActions';
import { doClearSuccessfullyUpdatedDiscipline } from '../../../../redux/actions/successfulUpdateActions';

import CIPCode from './CIPCode';

class CIPCodeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newDiscipline: null,
      view: '',
      isDeleteModalOpen: false
    };

    this.addDiscipline = this.addDiscipline.bind(this);
    this.clearNewDiscipline = this.clearNewDiscipline.bind(this);
  }

  componentDidUpdate() {
    const { successfullyCreatedDiscipline, cip } = this.props;

    if (
      successfullyCreatedDiscipline &&
      successfullyCreatedDiscipline.cipcodeUuid === cip.uuid &&
      this.state.newDiscipline
    ) {
      this.clearNewDiscipline();
      this.props.onClearCreatedDiscipline();
    }

    if (
      this.props.successfullyUpdatedDiscipline &&
      this.props.successfullyUpdatedDiscipline.cipcodeUuid === cip.uuid
    ) {
      this.props.onClearUpdatedDiscipline();

      return true;
    }
  }

  clearNewDiscipline = () => {
    this.setState({ newDiscipline: null });
  };

  addDiscipline = cipcodeUuid => {
    const { newDiscipline } = this.state;

    if (newDiscipline) {
      this.props.onGeneralErrorNotification(
        'Cannot create more than one Discipline simultaneously'
      );
      return;
    }

    const emptyDiscipline = {
      code: '',
      title: '',
      cipcodeUuid,
      isNew: true
    };

    this.setState({
      newDiscipline: emptyDiscipline
    });
  };

  toggleEditView = () => {
    this.setState(prevState => ({
      view: prevState.view !== 'EDIT' ? 'EDIT' : ''
    }));
  };

  handleDeleteClick = () => {
    this.setState({
      isDeleteModalOpen: true
    });
  };

  handleDeleteModalClose = () => {
    this.setState({
      isDeleteModalOpen: false
    });
  };

  handleDelete = () => {
    const { cip } = this.props;

    this.props.onDelete(cip.uuid);
    this.handleDeleteModalClose();
  };

  render() {
    const { cip, disciplines, addCip } = this.props;
    const { newDiscipline, view, isDeleteModalOpen } = this.state;

    const allDisciplines = newDiscipline
      ? disciplines.concat(newDiscipline)
      : disciplines;

    return (
      <CIPCode
        cip={cip}
        view={view}
        disciplines={allDisciplines}
        isDeleteModalOpen={isDeleteModalOpen}
        addDiscipline={this.addDiscipline}
        addCip={addCip}
        clearNewDiscipline={this.clearNewDiscipline}
        handleDeleteClick={this.handleDeleteClick}
        handleDeleteModalClose={this.handleDeleteModalClose}
        handleDelete={this.handleDelete}
        toggleEditView={this.toggleEditView}
      />
    );
  }
}

CIPCodeContainer.propTypes = {
  cip: PropTypes.object,
  disciplines: PropTypes.arrayOf(PropTypes.object),
  successfullyCreatedDiscipline: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
  successfullyUpdatedDiscipline: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
  addCip: PropTypes.func,
  onDelete: PropTypes.func,
  onClearCreatedDiscipline: PropTypes.func,
  onClearUpdatedDiscipline: PropTypes.func,
  onGeneralErrorNotification: PropTypes.func
};

CIPCodeContainer.defaultProps = {
  cip: {},
  disciplines: [],
  successfullyCreatedDiscipline: false,
  successfullyUpdatedDiscipline: false,
  addCip: undefined,
  onDelete: undefined,
  onClearCreatedDiscipline: undefined,
  onClearUpdatedDiscipline: undefined,
  onGeneralErrorNotification: undefined
};

const makeMapStateToProps = () => {
  const getCipDisciplines = makeCipDisciplinesSelector();
  const mapStateToProps = (state, props) => ({
    disciplines: getCipDisciplines(state, props),
    successfullyCreatedDiscipline:
      state.successfulCreationState.successfullyCreatedDiscipline,
    successfullyUpdatedDiscipline:
      state.successfulUpdateState.successfullyUpdatedDiscipline,
    successfullyCreatedTopic:
      state.successfulCreationState.successfullyCreatedTopic
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  onClearCreatedDiscipline: () =>
    dispatch(doClearSuccessfullyCreatedDiscipline()),
  onClearUpdatedDiscipline: () =>
    dispatch(doClearSuccessfullyUpdatedDiscipline()),
  onGeneralErrorNotification: errorMessage =>
    dispatch(doGeneralErrorNotification(errorMessage)),
  onDelete: uuid => dispatch(doDeleteCipcode(uuid))
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(CIPCodeContainer);
export { CIPCodeContainer, makeMapStateToProps };
