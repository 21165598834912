import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NotificationContainer from '../Notification/NotificationContainer';

const PageHeaderSC = styled.div`
  width: 100%;
  height: 90px;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  border-bottom: 1px solid ${props => props.theme.colors.grey[300]};
`;

const PageHeader = ({ children, ...props }) => (
  <PageHeaderSC {...props}>
    <NotificationContainer />
    {children}
  </PageHeaderSC>
);

PageHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

PageHeader.defaultProps = {
  children: null
};

export default PageHeader;
