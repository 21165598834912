import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NewSubtopic from './NewSubtopic';
import { doPostSubtopic } from '../../../../../redux/actions/subtopicActions';
import { validateCodeTitle } from '../../../../../helpers/validateCodes';
import { allFieldsAreValid } from '../../../../../helpers/validateGeneric';

class NewSubtopicContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      level_one_code: '',
      level_two_code: '',
      title: '',
      topicUuid: '',
      showValidationErrors: false
    };

    this.setNewSubtopic = this.setNewSubtopic.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubtopicSubmission = this.handleSubtopicSubmission.bind(this);
  }

  componentDidMount() {
    const { level_one_code, level_two_code } = this.state;
    const { subtopic } = this.props;
    this._isMounted = true;
    if (!level_one_code || !level_two_code) {
      this.setNewSubtopic(subtopic);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  setNewSubtopic = subtopic => {
    const { isNew, ...newSubtopic } = subtopic;
    if (this._isMounted) {
      this.setState(() => ({
        ...newSubtopic
      }));
    }
  };

  setValidationErrors = newSubtopic => {
    const titleError = validateCodeTitle(newSubtopic.title);

    const newValidationErrors = {
      title: titleError
    };

    return newValidationErrors;
  };

  handleSubtopicSubmission = () => {
    const { showValidationErrors, ...newSubtopic } = this.state;

    const newValidationErrors = this.setValidationErrors(newSubtopic);

    if (allFieldsAreValid(newValidationErrors)) {
      this.props.onPostCreateSubtopic(newSubtopic);
      this.setState({ showValidationErrors: false });
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const {
      level_one_code,
      level_two_code,
      title,
      topicUuid,
      showValidationErrors
    } = this.state;
    const { cip, discipline, topic, clearNewSubtopic } = this.props;

    const newSubtopic = {
      level_one_code,
      level_two_code,
      title,
      topicUuid
    };

    return (
      <NewSubtopic
        newSubtopic={newSubtopic}
        cip={cip}
        discipline={discipline}
        topic={topic}
        handleChange={this.handleChange}
        clearNewSubtopic={clearNewSubtopic}
        onPostCreateSubtopic={this.handleSubtopicSubmission}
        showValidationErrors={showValidationErrors}
      />
    );
  }
}

NewSubtopicContainer.propTypes = {
  subtopic: PropTypes.object,
  cip: PropTypes.object,
  discipline: PropTypes.object,
  topic: PropTypes.object,
  onPostCreateSubtopic: PropTypes.func,
  clearNewSubtopic: PropTypes.func
};
NewSubtopicContainer.defaultProps = {
  subtopic: {},
  cip: {},
  discipline: {},
  topic: {},
  onPostCreateSubtopic: undefined,
  clearNewSubtopic: undefined
};

const mapDispatchToProps = dispatch => ({
  onPostCreateSubtopic: newSubtopic => dispatch(doPostSubtopic(newSubtopic))
});

export default connect(
  undefined,
  mapDispatchToProps
)(NewSubtopicContainer);
export { NewSubtopicContainer };
