import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { doGeneralErrorNotification } from '../../../redux/actions/notificationActions';

import { doGetInstitutionIdTypes } from '../../../redux/actions/institutionIdTypeActions';
import { institutionIdTypesSelector } from '../../../redux/selectors/institutionIdTypeSelectors';
import InstitutionIdTypesDashboard from './InstitutionIdTypesDashboard';

class InstitutionIdTypesDashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newInstitutionIdType: null
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.onFetchDispatch();
  }

  clearNewIdType = () => {
    this.setState({ newInstitutionIdType: null });
  };

  addIdType = () => {
    const { newInstitutionIdType } = this.state;

    if (newInstitutionIdType) {
      this.props.onGeneralErrorNotification(
        'Cannot create more than one institution ID type simultaneously'
      );
      return;
    }

    const emptyIdType = {
      name: '',
      published: false,
      isNew: true
    };

    this.setState({
      newInstitutionIdType: emptyIdType
    });
  };

  render() {
    const { newInstitutionIdType } = this.state;
    const { institutionIdTypes } = this.props;

    return (
      <InstitutionIdTypesDashboard
        institutionIdTypes={institutionIdTypes}
        newInstitutionIdType={newInstitutionIdType}
        addIdType={this.addIdType}
        clearNewIdType={this.clearNewIdType}
      />
    );
  }
}

InstitutionIdTypesDashboardContainer.propTypes = {
  institutionIdTypes: PropTypes.arrayOf(PropTypes.object),
  onFetchDispatch: PropTypes.func,
  onGeneralErrorNotification: PropTypes.func
};

InstitutionIdTypesDashboardContainer.defaultProps = {
  institutionIdTypes: [],
  onFetchDispatch: undefined,
  onGeneralErrorNotification: undefined
};

const mapStateToProps = state => ({
  institutionIdTypes: institutionIdTypesSelector(state)
});

const mapDispatchToProps = dispatch => ({
  onFetchDispatch: () => dispatch(doGetInstitutionIdTypes()),
  onGeneralErrorNotification: errorMessage =>
    dispatch(doGeneralErrorNotification(errorMessage))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstitutionIdTypesDashboardContainer);
export { InstitutionIdTypesDashboardContainer };
