import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DisciplineForm from '../DisciplineForm';
import { RowSummary } from '../../../SharedSC/DashboardSC';

const DisciplineRowSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const NewDiscipline = ({
  cip,
  newDiscipline,
  allDisciplines,
  showValidationErrors,
  handleChange,
  onPostCreateDiscipline,
  clearNewDiscipline
}) => (
  <DisciplineRowSC>
    <RowSummary>
      <DisciplineForm
        cipcode={cip.code}
        buttonText="Create"
        discipline={newDiscipline}
        handleChange={event => handleChange(event)}
        onSubmit={onPostCreateDiscipline}
        allDisciplines={allDisciplines}
        showValidationErrors={showValidationErrors}
        closeForm={clearNewDiscipline}
      />
    </RowSummary>
  </DisciplineRowSC>
);

NewDiscipline.propTypes = {
  cip: PropTypes.object,
  newDiscipline: PropTypes.object,
  showValidationErrors: PropTypes.bool,
  allDisciplines: PropTypes.arrayOf(PropTypes.object),
  handleChange: PropTypes.func,
  onPostCreateDiscipline: PropTypes.func,
  clearNewDiscipline: PropTypes.func
};
NewDiscipline.defaultProps = {
  cip: {},
  newDiscipline: {},
  showValidationErrors: false,
  allDisciplines: [],
  handleChange: undefined,
  onPostCreateDiscipline: undefined,
  clearNewDiscipline: undefined
};

export default NewDiscipline;
export { DisciplineForm };
