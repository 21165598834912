import { Model, attr } from 'redux-orm';
import {
  GET_DEGREE_LEVELS_SUCCESS,
  POST_DEGREE_LEVEL_SUCCESS,
  PUT_DEGREE_LEVEL_SUCCESS,
  DELETE_DEGREE_LEVEL_SUCCESS,
  PATCH_MOVE_DEGREE_LEVEL_SUCCESS
} from '../actions/actionTypes';

class DegreeLevel extends Model {
  static reducer(action, DegreeLevel, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_DEGREE_LEVELS_SUCCESS: {
        DegreeLevel.all().delete();
        const degreeLevels = [...payload.degreeLevels];
        return degreeLevels.map(degreeLevel => {
          const degreeLevelUpsert = DegreeLevel.upsert(degreeLevel);
          return degreeLevelUpsert;
        });
      }
      case POST_DEGREE_LEVEL_SUCCESS: {
        const { createdDegreeLevel } = payload;
        return DegreeLevel.upsert(createdDegreeLevel);
      }
      case PUT_DEGREE_LEVEL_SUCCESS: {
        const { updatedDegreeLevel } = payload;
        return DegreeLevel.upsert(updatedDegreeLevel);
      }
      case PATCH_MOVE_DEGREE_LEVEL_SUCCESS: {
        const levels = [...payload.updatedDegreeLevels];
        return levels.map(level => {
          const levelUpsert = DegreeLevel.upsert(level);
          return levelUpsert;
        });
      }
      case DELETE_DEGREE_LEVEL_SUCCESS: {
        const { deletedDegreeLevel } = payload;
        return DegreeLevel.withId(deletedDegreeLevel.uuid).delete();
      }
      default:
        return session;
    }
  }
}

DegreeLevel.fields = {
  uuid: attr(),
  degreeLevel: attr(),
  published: attr(),
  order: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

DegreeLevel.modelName = 'DegreeLevel';
DegreeLevel.options = {
  idAttribute: 'uuid'
};

export default DegreeLevel;
