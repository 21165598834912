import fetch from "../helpers/fetch";

const getProgramCodes = () =>
  fetch(`/api/program-codes/all`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getProgramDisciplineCodes = disciplineUuid =>
  fetch(`/api/program-discipline/${disciplineUuid}/program-codes/all`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const getProgramCodesSearch = searchTerm =>
  fetch(`/api/program-codes/search/${searchTerm}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postProgramCode = newProgramCode =>
  fetch(`/api/program-code/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(newProgramCode)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putProgramCode = ({ uuid, ...updates }) =>
  fetch(`/api/program-code/${uuid}/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(updates)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const patchPublishProgramCode = uuid =>
  fetch(`/api/program-code/${uuid}/publish`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteProgramCode = uuid =>
  fetch(`/api/program-code/${uuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

export {
  getProgramCodes,
  getProgramCodesSearch,
  getProgramDisciplineCodes,
  postProgramCode,
  putProgramCode,
  patchPublishProgramCode,
  deleteProgramCode
};
