import fetch from "../helpers/fetch";

const getInstitutionTypes = () =>
  fetch('/api/institution-types/all', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      }
      throw new Error(`(status code: ${res.status})`);
    })
    .catch(error => {
      throw error;
    });

const postInstitutionType = institutionType =>
  fetch('/api/institution-type/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(institutionType)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const postInstitutionSubtype = institutionSubtype =>
  fetch('/api/institution-subtype/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(institutionSubtype)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putInstitutionType = ({ uuid, ...updates }) =>
  fetch(`/api/institution-type/${uuid}/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(updates)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const putInstitutionSubtype = ({ uuid, ...updates }) =>
  fetch(`/api/institution-subtype/${uuid}/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(updates)
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const patchMoveInstitutionType = (uuid, destinationOrder) =>
  fetch(`/api/institution-type/${uuid}/move`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ destinationOrder })
  })
    .then(res => res)
    .catch(error => {
      throw error;
    });

const deleteInstitutionType = uuid =>
  fetch(`/api/institution-type/${uuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => error);

const deleteInstitutionSubtype = uuid =>
  fetch(`/api/institution-subtype/${uuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res)
    .catch(error => error);

export {
  getInstitutionTypes,
  postInstitutionType,
  postInstitutionSubtype,
  putInstitutionType,
  putInstitutionSubtype,
  patchMoveInstitutionType,
  deleteInstitutionType,
  deleteInstitutionSubtype
};
