import { call, put } from 'redux-saga/effects';
import {
  getSubtopics,
  postSubtopic,
  putSubtopic,
  deleteSubtopic,
  patchMoveSubtopic
} from '../../api/subtopicsApi';
import {
  doGetSubtopicsSuccess,
  doGetSubtopicsError,
  doPostSubtopicSuccess,
  doPostSubtopicError,
  doPutSubtopicSuccess,
  doPutSubtopicError,
  doDeleteSubtopicSuccess,
  doDeleteSubtopicError,
  doPatchMoveSubtopicSuccess,
  doPatchMoveSubtopicError
} from '../actions/subtopicActions';

function* handleFetchSubtopics(action) {
  const { topicUuid } = action.payload;
  try {
    const result = yield call(getSubtopics, topicUuid);
    yield put(doGetSubtopicsSuccess(result));
  } catch (error) {
    yield put(doGetSubtopicsError(error));
  }
}

function* handlePostCreateSubtopic(action) {
  const { newSubtopic } = action.payload;
  try {
    const result = yield call(postSubtopic, newSubtopic);
    yield put(doPostSubtopicSuccess(result));
  } catch (error) {
    yield put(doPostSubtopicError(error));
  }
}

function* handlePostUpdateSubtopic(action) {
  const { subtopicUpdates } = action.payload;
  try {
    const result = yield call(putSubtopic, subtopicUpdates);
    yield put(doPutSubtopicSuccess(result));
  } catch (error) {
    yield put(doPutSubtopicError(error));
  }
}

function* handlePostMoveSubtopic(action) {
  const { uuid, destinationCodeUuid, moveAbove } = action.payload;
  try {
    const result = yield call(patchMoveSubtopic, {
      uuid,
      destinationCodeUuid,
      moveAbove
    });
    yield put(doPatchMoveSubtopicSuccess(result));
  } catch (error) {
    yield put(doPatchMoveSubtopicError(error));
  }
}

function* handleDeleteSubtopic(action) {
  const { subtopicUuid } = action.payload;
  try {
    const result = yield call(deleteSubtopic, subtopicUuid);
    yield put(doDeleteSubtopicSuccess(result));
  } catch (error) {
    const errorMessage = yield error.text();
    yield put(doDeleteSubtopicError(errorMessage));
  }
}

export {
  handleFetchSubtopics,
  handlePostCreateSubtopic,
  handlePostUpdateSubtopic,
  handlePostMoveSubtopic,
  handleDeleteSubtopic
};
