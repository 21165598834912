/* eslint no-unused-vars: off */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NewCipcode from './NewCipcode';
import { doPostProgramCipcode } from '../../../../../redux/actions/programCipcodeActions';
import { programCipcodesSelector } from '../../../../../redux/selectors/programCipcodeSelectors';
import {
  validateStringProperty,
  allFieldsAreValid
} from '../../../../../helpers/validateGeneric';
import { validateCipcode } from '../../../../../helpers/validateCodes';

class NewCipcodeContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      code: '',
      title: '',
      showValidationErrors: false
    };

    this.setNew = this.setNew.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmission = this.handleSubmission.bind(this);
  }

  componentDidMount() {
    const { code } = this.state;
    const { cipcode } = this.props;
    this._isMounted = true;
    if (!code) {
      this.setNew(cipcode);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  setNew = cipcode => {
    const { isNew, ...newCipcode } = cipcode;
    if (this._isMounted) {
      this.setState(state => ({
        ...newCipcode
      }));
    }
  };

  setValidationErrors = newCipcode => {
    const { allProgramCipcodes } = this.props;
    const titleError = validateStringProperty(
      newCipcode.title,
      allProgramCipcodes,
      'title'
    );
    const codeError = validateCipcode(newCipcode.code, allProgramCipcodes);
    const newValidationErrors = {
      title: titleError,
      code: codeError
    };

    return newValidationErrors;
  };

  handleSubmission = () => {
    const { showValidationErrors, ...newCipcode } = this.state;

    const newValidationErrors = this.setValidationErrors(newCipcode);

    if (allFieldsAreValid(newValidationErrors)) {
      this.props.onPostCreate(newCipcode);
      this.setState({ showValidationErrors: false });
      this.props.clearNew();
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const { code, title, showValidationErrors } = this.state;
    const { cipcode, allProgramCipcodes, clearNew } = this.props;

    const newCipcode = {
      code,
      title
    };

    return (
      <NewCipcode
        newCipcode={newCipcode}
        handleChange={this.handleChange}
        onPostCreate={this.handleSubmission}
        clearNew={clearNew}
        allProgramCipcodes={allProgramCipcodes}
        showValidationErrors={showValidationErrors}
      />
    );
  }
}

NewCipcodeContainer.propTypes = {
  cipcode: PropTypes.object,
  allProgramCipcodes: PropTypes.arrayOf(PropTypes.object),
  onPostCreate: PropTypes.func,
  clearNew: PropTypes.func
};
NewCipcodeContainer.defaultProps = {
  cipcode: {},
  allProgramCipcodes: [],
  onPostCreate: undefined,
  clearNew: undefined
};

const mapStateToProps = (state, props) => ({
  allProgramCipcodes: programCipcodesSelector(state, props)
});

const mapDispatchToProps = dispatch => ({
  onPostCreate: newCipcode => dispatch(doPostProgramCipcode(newCipcode))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewCipcodeContainer);
export { NewCipcodeContainer };
