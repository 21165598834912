import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import LocationForm from '../../LocationForm';
import { RowSummary } from '../../../SharedSC/DashboardSC';
import {
  validateLocationName,
  validateCountryCode
} from '../../../../../helpers/validateLocations';

const CountryRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const EditCountry = ({
  existingCountry,
  allCountries,
  handleChange,
  onPostUpdateCountry,
  showValidationErrors,
  toggleEditView
}) => (
  <CountryRow>
    <RowSummary>
      <LocationForm
        location={existingCountry}
        entityLabel="Country"
        buttonText="Update"
        handleChange={event => handleChange(event)}
        onSubmit={onPostUpdateCountry}
        showValidationErrors={showValidationErrors}
        validateCode={() =>
          validateCountryCode(existingCountry.code, allCountries)
        }
        validateName={() =>
          validateLocationName(existingCountry.name, allCountries)
        }
        closeForm={() => toggleEditView()}
      />
    </RowSummary>
  </CountryRow>
);

EditCountry.propTypes = {
  existingCountry: PropTypes.object,
  showValidationErrors: PropTypes.bool,
  allCountries: PropTypes.arrayOf(PropTypes.object),
  handleChange: PropTypes.func,
  onPostUpdateCountry: PropTypes.func,
  toggleEditView: PropTypes.func
};
EditCountry.defaultProps = {
  existingCountry: {},
  showValidationErrors: false,
  allCountries: [],
  handleChange: undefined,
  onPostUpdateCountry: undefined,
  toggleEditView: undefined
};

export default EditCountry;
