import { call, put } from 'redux-saga/effects';

import {
  getProgramCipcodes,
  postProgramCipcode,
  putProgramCipcode,
  deleteProgramCipcode
} from '../../api/programCipcodesApi';
import {
  doGetProgramCipcodesSuccess,
  doGetProgramCipcodesError,
  doPostProgramCipcodeSuccess,
  doPostProgramCipcodeError,
  doPutProgramCipcodeSuccess,
  doPutProgramCipcodeError,
  doDeleteProgramCipcodeSuccess,
  doDeleteProgramCipcodeError
} from '../actions/programCipcodeActions';

function* handleFetchProgramCipcodes() {
  try {
    const result = yield call(getProgramCipcodes);
    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doGetProgramCipcodesSuccess(payload));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doGetProgramCipcodesError(error));
  }
}

function* handlePostCreateProgramCipcode(action) {
  const { newProgramCipcode } = action.payload;

  try {
    const result = yield call(postProgramCipcode, newProgramCipcode);
    if (result.status === 200) {
      const payload = yield result.json();
      yield put(doPostProgramCipcodeSuccess(payload));
    } else {
      const errorText = yield result.text();
      yield put(
        doPostProgramCipcodeError({
          text: errorText
        })
      );
    }
  } catch (error) {
    yield put(doPostProgramCipcodeError());
  }
}

function* handlePutUpdateProgramCipcode(action) {
  const { programCipcodeUpdates } = action.payload;

  try {
    const result = yield call(putProgramCipcode, programCipcodeUpdates);

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doPutProgramCipcodeSuccess(payload));
    } else {
      const errorText = yield result.text();
      yield put(
        doPutProgramCipcodeError({
          text: errorText
        })
      );
    }
  } catch (error) {
    yield put(doPutProgramCipcodeError());
  }
}

function* handleDeleteProgramCipcode(action) {
  const { uuid } = action.payload;

  try {
    const result = yield call(deleteProgramCipcode, uuid);

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doDeleteProgramCipcodeSuccess(payload));
    } else {
      const errorText = yield result.text();
      yield put(
        doDeleteProgramCipcodeError({
          text: errorText
        })
      );
    }
  } catch (error) {
    yield put(doDeleteProgramCipcodeError());
  }
}

export {
  handleFetchProgramCipcodes,
  handlePostCreateProgramCipcode,
  handlePutUpdateProgramCipcode,
  handleDeleteProgramCipcode
};
