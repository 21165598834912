import fetch from "../helpers/fetch";

const postDiscipline = newDiscipline =>
  fetch(`/api/discipline/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(newDiscipline)
  })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      }
      throw new Error(`(status code: ${res.status})`);
    })
    .catch(error => {
      throw error;
    });

const putDiscipline = ({ uuid, ...disciplineUpdates }) =>
  fetch(`/api/discipline/${uuid}/update`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(disciplineUpdates)
  })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      }
      throw new Error(`(status code: ${res.status})`);
    })
    .catch(error => {
      throw error;
    });

const deleteDiscipline = disciplineUuid =>
  fetch(`/api/discipline/${disciplineUuid}/delete`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({})
  }).then(res => {
    if (res.status === 200) {
      return res.json();
    } else {
      throw res;
    }
  });

export { postDiscipline, putDiscipline, deleteDiscipline };
