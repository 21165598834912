import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LocationForm from '../../LocationForm';
import { RowSummary } from '../../../SharedSC/DashboardSC';
import {
  validateLocationName,
  validateCountryCode
} from '../../../../../helpers/validateLocations';

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const NewCountry = ({
  newCountry,
  allCountries,
  showValidationErrors,
  handleChange,
  onPostCreateCountry,
  clearNewCountry
}) => (
  <Layout>
    <RowSummary>
      <LocationForm
        location={newCountry}
        entityLabel="Country"
        buttonText="Create"
        showValidationErrors={showValidationErrors}
        validateCode={() => validateCountryCode(newCountry.code, allCountries)}
        validateName={() => validateLocationName(newCountry.name, allCountries)}
        handleChange={event => handleChange(event)}
        onSubmit={onPostCreateCountry}
        closeForm={clearNewCountry}
      />
    </RowSummary>
  </Layout>
);

NewCountry.propTypes = {
  newCountry: PropTypes.object,
  allCountries: PropTypes.arrayOf(PropTypes.object),
  showValidationErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  onPostCreateCountry: PropTypes.func,
  clearNewCountry: PropTypes.func
};
NewCountry.defaultProps = {
  newCountry: {},
  allCountries: [],
  showValidationErrors: false,
  handleChange: undefined,
  onPostCreateCountry: undefined,
  clearNewCountry: undefined
};

export default NewCountry;
