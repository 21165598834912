import { createSelector } from 'reselect';
import _ from 'lodash';
import { createSelector as ormCreateSelector } from 'redux-orm';
import { sortByProperty } from '../../helpers/utilities';
import { orm } from '../models';

export const ormSelector = state => state.orm;

// export const countriesSelector = createSelector(
//   ormSelector,
//   ormCreateSelector(orm, session =>
//     session.Country.all()
//       .toModelArray()
//       .map(country => Object.assign({}, country.ref))
//       .sort((a, b) => sortByProperty(a, b, 'code'))
//   )
// );

export const countriesSelector = createSelector(
  state => state,
  redux => {
    const countriesSelectorORM = ormCreateSelector(orm.Country);
    const countries = countriesSelectorORM(redux);
    const countriesSorted = _.sortBy(countries, ['code']);

    return countriesSorted;
  }
);

// export const publishedCountriesSelector = createSelector(
//   ormSelector,
//   ormCreateSelector(orm, session =>
//     session.Country.all()
//       .toModelArray()
//       .filter(country => country.published)
//       .map(country => Object.assign({}, country.ref))
//       .sort((a, b) => sortByProperty(a, b, 'code'))
//   )
// );

export const publishedCountriesSelector = createSelector(
  state => state,
  redux => {
    const selectorORM = ormCreateSelector([orm], session =>
      session.Country.all()
        .toModelArray()
        .filter(country => country.published)
        .map(country => Object.assign({}, country.ref))
        .sort((a, b) => sortByProperty(a, b, 'code'))
    );
    return selectorORM(redux);
  }
);

export const getCountryUuid = (state, props) => props.country.uuid;

// export const makeCountryStateProvincesSelector = () =>
//   createSelector(
//     ormSelector,
//     getCountryUuid,
//     ormCreateSelector(orm, (session, countryUuid) =>
//       session.StateProvince.all()
//         .filter(state => state.countryUuid === countryUuid)
//         .toModelArray()
//         .map(state => Object.assign({}, state.ref))
//         .sort((a, b) => sortByProperty(a, b, 'code'))
//     )
//   );

export const makeCountryStateProvincesSelector = () =>
  createSelector(
    state => state,
    getCountryUuid,
    (redux, countryUuid) => {
      const selectorORM = ormCreateSelector([orm], session =>
        session.StateProvince.all()
          .filter(state => state.countryUuid === countryUuid)
          .toModelArray()
          .map(state => Object.assign({}, state.ref))
          .sort((a, b) => sortByProperty(a, b, 'code'))
      );
      return selectorORM(redux);
    }
  );

// export const makeCountryStateProvincesPublishedSelector = () =>
//   createSelector(
//     ormSelector,
//     getCountryUuid,
//     ormCreateSelector(orm, (session, countryUuid) =>
//       session.StateProvince.all()
//         .filter(state => state.countryUuid === countryUuid)
//         .filter(state => state.published)
//         .toModelArray()
//         .map(state => Object.assign({}, state.ref))
//         .sort((a, b) => sortByProperty(a, b, 'code'))
//     )
//   );

export const makeCountryStateProvincesPublishedSelector = () =>
  createSelector(
    state => state,
    getCountryUuid,
    (redux, countryUuid) => {
      const selectorORM = ormCreateSelector([orm], session =>
        session.StateProvince.all()
          .filter(state => state.countryUuid === countryUuid)
          .filter(state => state.published)
          .toModelArray()
          .map(state => Object.assign({}, state.ref))
          .sort((a, b) => sortByProperty(a, b, 'code'))
      );
      return selectorORM(redux);
    }
  );

export const getSystemCountryUuid = (state, props) =>
  props.stateProvince ? props.stateProvince.countryUuid : '';

// export const makeGetSystemCountrySelector = () => {
//   return createSelector(
//     ormSelector,
//     getSystemCountryUuid,
//     ormCreateSelector(orm, (session, countryUuid) => {
//       return session.Country.all()
//         .filter(country => country.uuid === countryUuid)
//         .at(0);
//     })
//   );
// };

export const makeGetSystemCountrySelector = () => {
  return createSelector(
    state => state,
    getSystemCountryUuid,
    (redux, countryUuid) => {
      const selectorORM = ormCreateSelector([orm], session => {
        return session.Country.all()
          .filter(country => country.uuid === countryUuid)
          .at(0);
      });
      return selectorORM(redux);
    }
  );
};

export const getSystemStateProvinceUuid = state => {
  return state.institutionsDashboardState.stateProvinceUuid;
};

// export const makeGetSystemStateProvinceSelector = () => {
//   return createSelector(
//     ormSelector,
//     getSystemStateProvinceUuid,
//     ormCreateSelector(orm, (session, stateProvinceUuid) => {
//       const stateProvince = session.StateProvince.all()
//         .filter(state => state.uuid === stateProvinceUuid)
//         .toRefArray();
//       const country = stateProvince[0]
//         ? session.Country.all()
//             .filter(country => country.uuid === stateProvince[0].countryUuid)
//             .toRefArray()
//         : [{}];

//       return { stateProvince: stateProvince[0], country: country[0] };
//     })
//   );
// };

export const makeGetSystemStateProvinceSelector = () => {
  return createSelector(
    state => state,
    getSystemStateProvinceUuid,
    (redux, stateProvinceUuid) => {
      const selectorORM = ormCreateSelector([orm], session => {
        const stateProvince = session.StateProvince.all()
          .filter(state => state.uuid === stateProvinceUuid)
          .toRefArray();
        const country = stateProvince[0]
          ? session.Country.all()
              .filter(country => country.uuid === stateProvince[0].countryUuid)
              .toRefArray()
          : [{}];

        return { stateProvince: stateProvince[0], country: country[0] };
      });

      return selectorORM(redux);
    }
  );
};
