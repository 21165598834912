import {
  GET_INSTITUTIONAL_SYSTEMS,
  GET_INSTITUTIONAL_SYSTEMS_SUCCESS,
  GET_INSTITUTIONAL_SYSTEMS_ERROR,
  POST_INSTITUTIONAL_SYSTEM,
  POST_INSTITUTIONAL_SYSTEM_SUCCESS,
  POST_INSTITUTIONAL_SYSTEM_ERROR,
  PUT_INSTITUTIONAL_SYSTEM,
  PUT_INSTITUTIONAL_SYSTEM_SUCCESS,
  PUT_INSTITUTIONAL_SYSTEM_ERROR,
  DELETE_INSTITUTIONAL_SYSTEM,
  DELETE_INSTITUTIONAL_SYSTEM_SUCCESS,
  DELETE_INSTITUTIONAL_SYSTEM_ERROR
} from './actionTypes';

const doGetInstitutionalSystems = () => ({
  type: GET_INSTITUTIONAL_SYSTEMS
});

const doGetInstitutionalSystemsSuccess = data => ({
  type: GET_INSTITUTIONAL_SYSTEMS_SUCCESS,
  payload: {
    institutionalSystems: data.institutionalSystems
  }
});

const doGetInstitutionalSystemsError = error => ({
  type: GET_INSTITUTIONAL_SYSTEMS_ERROR,
  payload: {
    error
  }
});

const doPostInstitutionalSystem = newInstitutionalSystem => ({
  type: POST_INSTITUTIONAL_SYSTEM,
  payload: {
    newInstitutionalSystem
  }
});

const doPostInstitutionalSystemSuccess = data => ({
  type: POST_INSTITUTIONAL_SYSTEM_SUCCESS,
  payload: {
    createdInstitutionalSystem: data.createdSystem
  }
});

const doPostInstitutionalSystemError = error => ({
  type: POST_INSTITUTIONAL_SYSTEM_ERROR,
  payload: {
    error
  }
});

const doPutInstitutionalSystem = institutionalSystem => ({
  type: PUT_INSTITUTIONAL_SYSTEM,
  payload: {
    institutionalSystem
  }
});

const doPutInstitutionalSystemSuccess = data => ({
  type: PUT_INSTITUTIONAL_SYSTEM_SUCCESS,
  payload: {
    updatedInstitutionalSystem: data.updatedSystem
  }
});

const doPutInstitutionalSystemError = error => ({
  type: PUT_INSTITUTIONAL_SYSTEM_ERROR,
  payload: {
    error
  }
});

const doDeleteInstitutionalSystem = uuid => ({
  type: DELETE_INSTITUTIONAL_SYSTEM,
  payload: {
    uuid
  }
});

const doDeleteInstitutionalSystemSuccess = data => ({
  type: DELETE_INSTITUTIONAL_SYSTEM_SUCCESS,
  payload: {
    deletedInstitutionalSystem: data.deletedInstitutionalSystem
  }
});

const doDeleteInstitutionalSystemError = error => ({
  type: DELETE_INSTITUTIONAL_SYSTEM_ERROR,
  payload: {
    error
  }
});

export {
  doGetInstitutionalSystems,
  doGetInstitutionalSystemsSuccess,
  doGetInstitutionalSystemsError,
  doPostInstitutionalSystem,
  doPostInstitutionalSystemSuccess,
  doPostInstitutionalSystemError,
  doPutInstitutionalSystem,
  doPutInstitutionalSystemSuccess,
  doPutInstitutionalSystemError,
  doDeleteInstitutionalSystem,
  doDeleteInstitutionalSystemSuccess,
  doDeleteInstitutionalSystemError
};
