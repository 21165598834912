import React from 'react';
import PropTypes from 'prop-types';

import { HeaderText } from '../../../SharedSC/HeaderSC';
import {
  SimpleModalSC,
  ModalHeaderText,
  ModalBody,
  FormLayout
} from '../../../SharedSC/ModalForm';
import { ModalHeader } from '../../../../Forms/FormComponents';

import InstitutionalSystemForm from '../InstitutionalSystemForm/InstitutionalSystemFormContainer';
import { validateLocationName } from '../../../../../helpers/validateLocations';
import { validateUrl } from '../../../../../helpers/validateGeneric';

const NewInstitutionalSystem = ({
  newInstitutionalSystem,
  isNewSystemModalOpen,
  handleModalClose,
  allInstitutionalSystems,
  showValidationErrors,
  handleChange,
  handleUserFieldChange,
  onPostCreateInstitutionalSystem,
  clearNewInstitutionalSystem
}) => {
  const onSystemModalSubmit = () => {
    onPostCreateInstitutionalSystem();
  };
  return (
    <SimpleModalSC
      open={isNewSystemModalOpen}
      onClose={handleModalClose}
      maxWidth="lg"
      fullWidth
    >
      <ModalBody>
        <ModalHeader>
          <HeaderText>
            <ModalHeaderText>Create System</ModalHeaderText>
          </HeaderText>
        </ModalHeader>
        <FormLayout>
          <InstitutionalSystemForm
            institutionalSystem={newInstitutionalSystem}
            buttonText="Create"
            showValidationErrors={showValidationErrors}
            validateName={() =>
              validateLocationName(
                newInstitutionalSystem.name,
                allInstitutionalSystems
              )
            }
            validateWebsiteAddress={() =>
              validateUrl(newInstitutionalSystem.websiteAddress, false)
            }
            handleChange={event => handleChange(event)}
            handleUserFieldChange={handleUserFieldChange}
            onSubmit={onSystemModalSubmit}
            closeForm={clearNewInstitutionalSystem}
          />
        </FormLayout>
      </ModalBody>
    </SimpleModalSC>
  );
};

NewInstitutionalSystem.propTypes = {
  newInstitutionalSystem: PropTypes.object,
  isNewSystemModalOpen: PropTypes.bool,
  allInstitutionalSystems: PropTypes.arrayOf(PropTypes.object),
  showValidationErrors: PropTypes.bool,
  handleChange: PropTypes.func,
  handleUserFieldChange: PropTypes.func,
  handleModalClose: PropTypes.func,
  onPostCreateInstitutionalSystem: PropTypes.func,
  clearNewInstitutionalSystem: PropTypes.func
};
NewInstitutionalSystem.defaultProps = {
  newInstitutionalSystem: {},
  isNewSystemModalOpen: false,
  allInstitutionalSystems: [],
  showValidationErrors: false,
  handleModalClose: undefined,
  handleChange: undefined,
  handleUserFieldChange: undefined,
  onPostCreateInstitutionalSystem: undefined,
  clearNewInstitutionalSystem: undefined
};

export default NewInstitutionalSystem;
