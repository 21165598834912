import React from 'react';
import PropTypes from 'prop-types';

import { convertNullToString } from '../../../helpers/utilities';
import SelectField from '../../SelectField/SelectFieldWithError';

const CountrySelect = ({
  className,
  disabled,
  countries,
  selectedCountry,
  showValidationErrors,
  handleCountryChange
}) => {
  const countryOptions = countries.map(country => ({
    value: country.uuid,
    text: country.name
  }));

  const error =
    showValidationErrors && !selectedCountry
      ? { message: 'Please select a Country' }
      : false;

  return (
    <SelectField
      className={className}
      select
      error={error}
      disabled={disabled || !countries || countries.length < 1}
      name="selectedCountryUuid"
      labelText="Country*"
      inputLabelWidth={60}
      value={convertNullToString(selectedCountry)}
      onChange={handleCountryChange}
      options={countryOptions}
      variant="outlined"
    />
  );
};

CountrySelect.propTypes = {
  className: PropTypes.string,
  countries: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  selectedCountry: PropTypes.string,
  showValidationErrors: PropTypes.bool,
  handleCountryChange: PropTypes.func
};

CountrySelect.defaultProps = {
  className: '',
  countries: [],
  disabled: false,
  selectedCountry: '',
  showValidationErrors: false,
  handleCountryChange: undefined
};

export default CountrySelect;
