import {
  PATCH_MOVE_INSTITUTION_TYPE_ERROR,
  PATCH_MOVE_DEGREE_LEVEL_ERROR,
  CLEAR_REVERT_DRAG_DROP
} from '../actions/actionTypes';

const INITIAL_STATE = {
  revertLastDrag: false
};

const dragDropReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PATCH_MOVE_INSTITUTION_TYPE_ERROR: {
      return {
        ...state,
        revertLastDrag: true
      };
    }
    case PATCH_MOVE_DEGREE_LEVEL_ERROR: {
      return {
        ...state,
        revertLastDrag: true
      };
    }
    case CLEAR_REVERT_DRAG_DROP: {
      return {
        ...state,
        revertLastDrag: false
      };
    }

    default:
      return state;
  }
};

export default dragDropReducer;

export { INITIAL_STATE };
