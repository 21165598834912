import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EditSubtopic from './EditSubtopic';
import { doPutSubtopic } from '../../../../../redux/actions/subtopicActions';
import { validateCodeTitle } from '../../../../../helpers/validateCodes';
import { allFieldsAreValid } from '../../../../../helpers/validateGeneric';

class EditSubtopicContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      level_one_code: '',
      level_two_code: '',
      title: '',
      topicUuid: '',
      showValidationErrors: false
    };

    this.setExistingSubtopic = this.setExistingSubtopic.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubtopicUpdate = this.handleSubtopicUpdate.bind(this);
  }

  componentDidMount() {
    const { level_one_code, level_two_code } = this.state;
    const { subtopic } = this.props;
    this._isMounted = true;
    if (!level_one_code || !level_two_code) {
      this.setExistingSubtopic(subtopic);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  setExistingSubtopic = subtopic => {
    const { ...existingSubtopic } = subtopic;
    if (this._isMounted) {
      this.setState(() => ({
        ...existingSubtopic
      }));
    }
  };

  setValidationErrors = existingSubtopic => {
    const titleError = validateCodeTitle(existingSubtopic.title);

    const newValidationErrors = {
      title: titleError
    };

    return newValidationErrors;
  };

  handleSubtopicUpdate = () => {
    const { showValidationErrors, ...existingSubtopic } = this.state;
    const newValidationErrors = this.setValidationErrors(existingSubtopic);

    if (allFieldsAreValid(newValidationErrors)) {
      const payload = {
        uuid: existingSubtopic.uuid,
        title: existingSubtopic.title
      };
      this.props.onPostUpdateSubtopic(payload);
      this.setState({ showValidationErrors: false });
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const {
      level_one_code,
      level_two_code,
      title,
      topicUuid,
      showValidationErrors
    } = this.state;
    const { cip, discipline, topic, toggleEditView } = this.props;

    const thisSubtopic = {
      level_one_code,
      level_two_code,
      title,
      topicUuid
    };

    return (
      <EditSubtopic
        existingSubtopic={thisSubtopic}
        cip={cip}
        discipline={discipline}
        topic={topic}
        handleChange={this.handleChange}
        onPostUpdateSubtopic={this.handleSubtopicUpdate}
        showValidationErrors={showValidationErrors}
        toggleEditView={toggleEditView}
      />
    );
  }
}

EditSubtopicContainer.propTypes = {
  cip: PropTypes.object,
  discipline: PropTypes.object,
  topic: PropTypes.object,
  subtopic: PropTypes.object,
  toggleEditView: PropTypes.func,
  onPostUpdateSubtopic: PropTypes.func
};
EditSubtopicContainer.defaultProps = {
  cip: {},
  discipline: {},
  topic: {},
  subtopic: {},
  toggleEditView: undefined,
  onPostUpdateSubtopic: undefined
};

const mapDispatchToProps = dispatch => ({
  onPostUpdateSubtopic: subtopicUpdates =>
    dispatch(doPutSubtopic(subtopicUpdates))
});

export default connect(
  undefined,
  mapDispatchToProps
)(EditSubtopicContainer);
export { EditSubtopicContainer };
