import {
  GET_ACCREDITATION_AGENCIES,
  GET_ACCREDITATION_AGENCIES_SUCCESS,
  GET_ACCREDITATION_AGENCIES_ERROR,
  POST_ACCREDITATION_AGENCY,
  POST_ACCREDITATION_AGENCY_SUCCESS,
  POST_ACCREDITATION_AGENCY_ERROR,
  PUT_ACCREDITATION_AGENCY,
  PUT_ACCREDITATION_AGENCY_SUCCESS,
  PUT_ACCREDITATION_AGENCY_ERROR,
  DELETE_ACCREDITATION_AGENCY,
  DELETE_ACCREDITATION_AGENCY_SUCCESS,
  DELETE_ACCREDITATION_AGENCY_ERROR
} from './actionTypes';

const doGetAccreditationAgencies = () => ({
  type: GET_ACCREDITATION_AGENCIES
});

const doGetAccreditationAgenciesSuccess = data => ({
  type: GET_ACCREDITATION_AGENCIES_SUCCESS,
  payload: {
    accreditationAgencies: data.accreditationAgencies
  }
});

const doGetAccreditationAgenciesError = error => ({
  type: GET_ACCREDITATION_AGENCIES_ERROR,
  payload: {
    error
  }
});

const doPostAccreditationAgency = newAccreditationAgency => ({
  type: POST_ACCREDITATION_AGENCY,
  payload: {
    newAccreditationAgency
  }
});

const doPostAccreditationAgencySuccess = data => ({
  type: POST_ACCREDITATION_AGENCY_SUCCESS,
  payload: {
    createdAccreditationAgency: data.createdAgency
  }
});

const doPostAccreditationAgencyError = error => ({
  type: POST_ACCREDITATION_AGENCY_ERROR,
  payload: {
    error
  }
});

const doPutAccreditationAgency = accreditationAgency => ({
  type: PUT_ACCREDITATION_AGENCY,
  payload: {
    accreditationAgency
  }
});

const doPutAccreditationAgencySuccess = data => ({
  type: PUT_ACCREDITATION_AGENCY_SUCCESS,
  payload: {
    updatedAccreditationAgency: data.updatedAgency
  }
});

const doPutAccreditationAgencyError = error => ({
  type: PUT_ACCREDITATION_AGENCY_ERROR,
  payload: {
    error
  }
});

const doDeleteAccreditationAgency = uuid => ({
  type: DELETE_ACCREDITATION_AGENCY,
  payload: {
    uuid
  }
});

const doDeleteAccreditationAgencySuccess = data => ({
  type: DELETE_ACCREDITATION_AGENCY_SUCCESS,
  payload: {
    deletedAccreditationAgency: data.deletedAccreditationAgency
  }
});

const doDeleteAccreditationAgencyError = error => ({
  type: DELETE_ACCREDITATION_AGENCY_ERROR,
  payload: {
    error
  }
});

export {
  doGetAccreditationAgencies,
  doGetAccreditationAgenciesSuccess,
  doGetAccreditationAgenciesError,
  doPostAccreditationAgency,
  doPostAccreditationAgencySuccess,
  doPostAccreditationAgencyError,
  doPutAccreditationAgency,
  doPutAccreditationAgencySuccess,
  doPutAccreditationAgencyError,
  doDeleteAccreditationAgency,
  doDeleteAccreditationAgencySuccess,
  doDeleteAccreditationAgencyError
};
