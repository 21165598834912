import React from 'react';
import PropTypes from 'prop-types';
import DisciplineContainer from '../Discipline/DisciplineContainer';

import AccordionRowContainer from '../../../../AccordionRow/AccordionRowContainer';

const CipCode = ({ cip, disciplines }) => (
  <div>
    <AccordionRowContainer
      label="CIP"
      columnOne={cip.code}
      columnTwo={cip.title}
      expandWidth="42px"
      height="38px"
      columnGutters="1.5rem"
    >
      {disciplines &&
        disciplines
          .sort((discA, discB) => discA.code - discB.code)
          .map(disc => (
            <DisciplineContainer cip={cip} discipline={disc} key={disc.uuid} />
          ))}
    </AccordionRowContainer>
  </div>
);

CipCode.propTypes = {
  cip: PropTypes.object,
  disciplines: PropTypes.arrayOf(PropTypes.object)
};

CipCode.defaultProps = {
  cip: {},
  disciplines: []
};

export default CipCode;
