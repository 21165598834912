import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import scrollToComponent from 'react-scroll-to-component';
import NewInstitutionIdType from './NewInstitutionIdType';
import { doPostInstitutionIdType } from '../../../../../redux/actions/institutionIdTypeActions';
import { institutionIdTypesSelector } from '../../../../../redux/selectors/institutionIdTypeSelectors';
import {
  validateStringProperty,
  allFieldsAreValid
} from '../../../../../helpers/validateGeneric';

class NewInstitutionIdTypeContainer extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      showValidationErrors: false
    };
    this.setNewInstitutionIdType = this.setNewInstitutionIdType.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCreateSubmission = this.handleCreateSubmission.bind(this);
  }

  componentDidMount() {
    const { institutionIdType } = this.props;
    this._isMounted = true;
    if (!this.state.name) {
      this.setNewInstitutionIdType(institutionIdType);
    }

    scrollToComponent(this.Compref, {
      offset: -100,
      align: 'top',
      duration: 500,
      ease: 'inCirc'
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    if (this._isMounted) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  setNewInstitutionIdType = name => {
    const { isNew, ...newInstitutionIdType } = name;
    if (this._isMounted) {
      this.setState(() => ({
        ...newInstitutionIdType
      }));
    }
  };

  setValidationErrors = newInstitutionIdType => {
    const { allInstitutionIdTypes } = this.props;
    const nameError = validateStringProperty(
      newInstitutionIdType.name,
      allInstitutionIdTypes,
      'name'
    );
    const newValidationErrors = {
      name: nameError
    };

    return newValidationErrors;
  };

  handleCreateSubmission = () => {
    const { showValidationErrors, ...newInstitutionIdType } = this.state;

    const newValidationErrors = this.setValidationErrors(newInstitutionIdType);

    if (allFieldsAreValid(newValidationErrors)) {
      this.props.onPostCreate(newInstitutionIdType);
      this.setState({ showValidationErrors: false });
      this.props.clearNewForm();
    } else {
      this.setState({ showValidationErrors: true });
    }
  };

  render() {
    const { name, showValidationErrors } = this.state;
    const { allInstitutionIdTypes, clearNewForm } = this.props;

    const newInstitutionIdType = {
      name
    };

    return (
      <NewInstitutionIdType
        className="compref"
        refProp={section => {
          this.Compref = section;
        }}
        newInstitutionIdType={newInstitutionIdType}
        handleChange={this.handleChange}
        onPostCreate={this.handleCreateSubmission}
        clearNewForm={clearNewForm}
        allInstitutionIdTypes={allInstitutionIdTypes}
        showValidationErrors={showValidationErrors}
      />
    );
  }
}

NewInstitutionIdTypeContainer.propTypes = {
  institutionIdType: PropTypes.object,
  allInstitutionIdTypes: PropTypes.arrayOf(PropTypes.object),
  onPostCreate: PropTypes.func,
  clearNewForm: PropTypes.func
};
NewInstitutionIdTypeContainer.defaultProps = {
  institutionIdType: {},
  allInstitutionIdTypes: [],
  onPostCreate: undefined,
  clearNewForm: undefined
};

const mapStateToProps = (state, props) => ({
  allInstitutionIdTypes: institutionIdTypesSelector(state, props)
});

const mapDispatchToProps = dispatch => ({
  onPostCreate: newIdType => dispatch(doPostInstitutionIdType(newIdType))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewInstitutionIdTypeContainer);
export { NewInstitutionIdTypeContainer };
