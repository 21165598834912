import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Cipcode from './Cipcode';

import { doGetProgramDisciplines } from '../../../../redux/actions/programDisciplineActions';
import { doDeleteProgramCipcode } from '../../../../redux/actions/programCipcodeActions';
import { makeProgramCipDisciplinesSelector } from '../../../../redux/selectors/programDisciplineSelectors';
import { doGeneralErrorNotification } from '../../../../redux/actions/notificationActions';

class CipcodeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: '',
      newDiscipline: null,
      isDeleteModalOpen: false
    };

    this.getChildDisciplines = this.getChildDisciplines.bind(this);
  }

  getChildDisciplines = () => {
    const { cipcode, disciplines } = this.props;

    if (!disciplines || disciplines.length < 1) {
      this.props.onFetchProgramDisciplines(cipcode.uuid);
    }
  };

  clearNew = () => {
    this.setState({ newDiscipline: null });
  };

  addProgramDiscipline = () => {
    const { newDiscipline } = this.state;

    if (newDiscipline) {
      this.props.onGeneralErrorNotification(
        'Cannot create more than one program discipline simultaneously'
      );
      return;
    }

    const empty = {
      title: '',
      code: '',
      isNew: true
    };

    this.setState({
      newDiscipline: empty
    });
  };

  toggleEditView = () => {
    this.setState(prevState => ({
      view: prevState.view !== 'EDIT' ? 'EDIT' : ''
    }));
  };

  handleDeleteClick = () => {
    this.setState({
      isDeleteModalOpen: true
    });
  };

  handleDeleteModalClose = () => {
    this.setState({
      isDeleteModalOpen: false
    });
  };

  handleDelete = () => {
    const { cipcode } = this.props;

    this.props.onDelete(cipcode.uuid);
    this.handleDeleteModalClose();
  };

  render() {
    const { newDiscipline, view, isDeleteModalOpen } = this.state;
    const { cipcode, disciplines, addProgramCipcode } = this.props;
    return (
      <Cipcode
        view={view}
        cipcode={cipcode}
        disciplines={disciplines}
        newDiscipline={newDiscipline}
        isDeleteModalOpen={isDeleteModalOpen}
        addProgramCipcode={addProgramCipcode}
        addProgramDiscipline={this.addProgramDiscipline}
        handleDeleteClick={this.handleDeleteClick}
        handleDeleteModalClose={this.handleDeleteModalClose}
        handleDelete={this.handleDelete}
        getChildDisciplines={this.getChildDisciplines}
        toggleEditView={this.toggleEditView}
        clearNew={this.clearNew}
      />
    );
  }
}

CipcodeContainer.propTypes = {
  cipcode: PropTypes.object.isRequired,
  disciplines: PropTypes.arrayOf(PropTypes.object),
  addProgramCipcode: PropTypes.func,
  onDelete: PropTypes.func,
  onFetchProgramDisciplines: PropTypes.func,
  onGeneralErrorNotification: PropTypes.func
};
CipcodeContainer.defaultProps = {
  disciplines: [],
  addProgramCipcode: undefined,
  onDelete: undefined,
  onFetchProgramDisciplines: undefined,
  onGeneralErrorNotification: undefined
};

const makeMapStateToProps = () => {
  const getCipcodeDisciplines = makeProgramCipDisciplinesSelector();
  const mapStateToProps = (state, props) => ({
    disciplines: getCipcodeDisciplines(state, props)
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  onFetchProgramDisciplines: cipcodeUuid =>
    dispatch(doGetProgramDisciplines(cipcodeUuid)),
  onGeneralErrorNotification: errorMessage =>
    dispatch(doGeneralErrorNotification(errorMessage)),
  onDelete: uuid => dispatch(doDeleteProgramCipcode(uuid))
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(CipcodeContainer);
