import {
  POST_MICROCOMPETENCY_SUCCESS,
  CLEAR_SUCCESFULLY_CREATED_MICROCOMPETENCY,
  POST_SUBTOPIC_SUCCESS,
  CLEAR_SUCCESFULLY_CREATED_SUBTOPIC,
  POST_TOPIC_SUCCESS,
  CLEAR_SUCCESFULLY_CREATED_TOPIC,
  POST_DISCIPLINE_SUCCESS,
  CLEAR_SUCCESFULLY_CREATED_DISCIPLINE,
  POST_CIP_CODE_SUCCESS,
  CLEAR_SUCCESFULLY_CREATED_CIP_CODE,
  GET_PROGRAM_CODES_SUCCESS
} from '../actions/actionTypes';

const INITIAL_STATE = {
  successfullyFetchedAllProgramCodes: false,
  successfullyCreatedMicrocompetency: false,
  successfullyCreatedSubtopic: false,
  successfullyCreatedTopic: false,
  successfullyCreatedDiscipline: false,
  successfullyCreatedCipcode: false
};

const successfulCreationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_MICROCOMPETENCY_SUCCESS: {
      return {
        ...state,
        successfullyCreatedMicrocompetency:
          action.payload.createdMicrocompetency
      };
    }
    case POST_SUBTOPIC_SUCCESS: {
      return {
        ...state,
        successfullyCreatedSubtopic: action.payload.createdSubtopic
      };
    }
    case POST_TOPIC_SUCCESS: {
      return {
        ...state,
        successfullyCreatedTopic: action.payload.createdTopic
      };
    }
    case POST_DISCIPLINE_SUCCESS: {
      return {
        ...state,
        successfullyCreatedDiscipline: action.payload.createdDiscipline
      };
    }
    case POST_CIP_CODE_SUCCESS: {
      return {
        ...state,
        successfullyCreatedCipcode: action.payload.createdCipcode
      };
    }
    case GET_PROGRAM_CODES_SUCCESS: {
      return {
        ...state,
        successfullyFetchedAllProgramCodes: true
      };
    }
    case CLEAR_SUCCESFULLY_CREATED_MICROCOMPETENCY: {
      return {
        ...state,
        successfullyCreatedMicrocompetency: false
      };
    }
    case CLEAR_SUCCESFULLY_CREATED_SUBTOPIC: {
      return {
        ...state,
        successfullyCreatedSubtopic: false
      };
    }
    case CLEAR_SUCCESFULLY_CREATED_TOPIC: {
      return {
        ...state,
        successfullyCreatedTopic: false
      };
    }
    case CLEAR_SUCCESFULLY_CREATED_DISCIPLINE: {
      return {
        ...state,
        successfullyCreatedDiscipline: false
      };
    }
    case CLEAR_SUCCESFULLY_CREATED_CIP_CODE: {
      return {
        ...state,
        successfullyCreatedCipcode: false
      };
    }
    default:
      return state;
  }
};

export default successfulCreationReducer;

export { INITIAL_STATE };
