import { Model, attr } from 'redux-orm';
import {
  GET_PROGRAM_CIPCODES_SUCCESS,
  POST_PROGRAM_CIPCODE_SUCCESS,
  PUT_PROGRAM_CIPCODE_SUCCESS,
  DELETE_PROGRAM_CIPCODE_SUCCESS,
  CLEAR_PROGRAM_CIPCODES
} from '../actions/actionTypes';

class ProgramCipcode extends Model {
  static reducer(action, ProgramCipcode, session) {
    const { payload, type } = action;

    switch (type) {
      case GET_PROGRAM_CIPCODES_SUCCESS: {
        ProgramCipcode.all().delete();
        const cips = [...payload.programCipcodes];
        return cips.map(cip => {
          const cipUpsert = ProgramCipcode.upsert(cip);
          return cipUpsert;
        });
      }
      case POST_PROGRAM_CIPCODE_SUCCESS: {
        const newProgramCipcode = { ...payload.createdProgramCipcode };
        return ProgramCipcode.upsert(newProgramCipcode);
      }
      case PUT_PROGRAM_CIPCODE_SUCCESS: {
        const updatedProgramCipcode = { ...payload.updatedProgramCipcode };
        return ProgramCipcode.upsert(updatedProgramCipcode);
      }
      case CLEAR_PROGRAM_CIPCODES: {
        ProgramCipcode.all().delete();
        break;
      }
      case DELETE_PROGRAM_CIPCODE_SUCCESS: {
        const { deletedProgramCipcode } = action.payload;
        return ProgramCipcode.withId(deletedProgramCipcode.uuid).delete();
      }
      default:
        return session;
    }
  }
}

ProgramCipcode.fields = {
  uuid: attr(),
  title: attr(),
  code: attr(),
  createdAt: attr(),
  updatedAt: attr()
};

ProgramCipcode.modelName = 'ProgramCipcode';
ProgramCipcode.options = {
  idAttribute: 'uuid'
};

export default ProgramCipcode;
