import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Column,
  ElevatedForm,
  SubmitButton,
  CancelButton,
  TextFieldWithError
} from '../../../Forms/FormComponents';
import { FormTop, FormBottom } from '../../SharedSC/FormSC';
import {
  validateCodeTitle,
  validateDisciplineCode
} from '../../../../helpers/validateCodes';

const ParentCipDigits = styled.div`
  width: 100%;
  text-align: right;
  padding-right: 0.25rem;
`;

const DisciplineForm = ({
  cipcode,
  discipline,
  allDisciplines,
  buttonText,
  handleChange,
  onSubmit,
  showValidationErrors,
  closeForm
}) => (
  <ElevatedForm>
    <FormTop>
      <Column size="3%">
        <ParentCipDigits>{cipcode.code}.</ParentCipDigits>
      </Column>
      <Column size="22%">
        <TextFieldWithError
          id="disciplineCode"
          name="code"
          value={discipline.code}
          onChange={event => handleChange(event)}
          margin="normal"
          InputLabelProps={{ shrink: true }}
          label="Discipline Code*"
          placeholder=""
          variant="outlined"
          validate={() =>
            validateDisciplineCode(discipline.code, allDisciplines)
          }
          showValidationErrors={showValidationErrors}
          paddingRight="1rem"
        />
      </Column>
      <Column size="75%">
        <TextFieldWithError
          id="disciplineTitle"
          name="title"
          value={discipline.title}
          onChange={event => handleChange(event)}
          margin="normal"
          InputLabelProps={{ shrink: true }}
          label="Code Title*"
          placeholder=""
          variant="outlined"
          validate={validateCodeTitle}
          showValidationErrors={showValidationErrors}
        />
      </Column>
    </FormTop>
    <FormBottom>
      <CancelButton onClick={closeForm} noHoverBg>
        Cancel
      </CancelButton>
      <SubmitButton onClick={onSubmit}>{buttonText}</SubmitButton>
    </FormBottom>
  </ElevatedForm>
);

DisciplineForm.propTypes = {
  buttonText: PropTypes.string,
  cipcode: PropTypes.string.isRequired,
  discipline: PropTypes.object,
  showValidationErrors: PropTypes.bool,
  allDisciplines: PropTypes.arrayOf(PropTypes.object),
  handleChange: PropTypes.func,
  onSubmit: PropTypes.func,
  closeForm: PropTypes.func
};
DisciplineForm.defaultProps = {
  buttonText: 'Create',
  discipline: {},
  showValidationErrors: false,
  allDisciplines: [],
  handleChange: undefined,
  onSubmit: undefined,
  closeForm: undefined
};

export default DisciplineForm;
