import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  doPutDegreeLevel,
  doDeleteDegreeLevel
} from '../../../../redux/actions/degreeLevelActions';
import DegreeLevel from './DegreeLevel';

class DegreeLevelContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: '',
      isPublishModalOpen: false,
      isDeleteModalOpen: false
    };
  }

  toggleEditView = () => {
    this.setState(prevState => ({
      view: prevState.view !== 'EDIT' ? 'EDIT' : ''
    }));
  };

  handlePublishModalOpen = () => {
    this.setState({
      isPublishModalOpen: true
    });
  };

  handlePublishModalClose = () => {
    this.setState({
      isPublishModalOpen: false
    });
  };

  onPublish = () => {
    const { degreeLevel } = this.props;
    const payload = {
      ...degreeLevel,
      published: true
    };
    this.props.onPutPublish(payload);
    this.handlePublishModalClose();
  };

  handleDeleteClick = () => {
    this.setState({
      isDeleteModalOpen: true
    });
  };

  handleDeleteModalClose = () => {
    this.setState({
      isDeleteModalOpen: false
    });
  };

  handleConfirmDelete = () => {
    const { degreeLevel } = this.props;

    this.props.onDelete(degreeLevel.uuid);
    this.handleDeleteModalClose();
  };

  render() {
    const { degreeLevel, className, dragIndex, isDraggingOver } = this.props;
    const { view, isPublishModalOpen, isDeleteModalOpen } = this.state;

    return (
      <DegreeLevel
        view={view}
        dragIndex={dragIndex}
        isDraggingOver={isDraggingOver}
        degreeLevel={degreeLevel}
        className={className}
        isPublishModalOpen={isPublishModalOpen}
        isDeleteModalOpen={isDeleteModalOpen}
        onPublish={this.onPublish}
        handlePublishModalOpen={this.handlePublishModalOpen}
        handlePublishModalClose={this.handlePublishModalClose}
        handleDeleteClick={this.handleDeleteClick}
        handleDeleteModalClose={this.handleDeleteModalClose}
        handleConfirmDelete={this.handleConfirmDelete}
        toggleEditView={this.toggleEditView}
      />
    );
  }
}

DegreeLevelContainer.propTypes = {
  degreeLevel: PropTypes.object,
  dragIndex: PropTypes.number.isRequired,
  isDraggingOver: PropTypes.bool,
  className: PropTypes.string,
  onPutPublish: PropTypes.func,
  onDelete: PropTypes.func
};

DegreeLevelContainer.defaultProps = {
  degreeLevel: {},
  isDraggingOver: false,
  className: '',
  onPutPublish: undefined,
  onDelete: undefined
};

const mapDispatchToProps = dispatch => ({
  onPutPublish: updates => dispatch(doPutDegreeLevel(updates)),
  onDelete: uuid => dispatch(doDeleteDegreeLevel(uuid))
});

export default connect(
  undefined,
  mapDispatchToProps
)(DegreeLevelContainer);
