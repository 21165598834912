import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tooltip } from '@material-ui/core';

import { FormTop, FormBottom } from '../../../SharedSC/FormSC';
import { Divider, FormRow } from '../../../SharedSC/ModalForm';
import {
  Column,
  CancelButton,
  SubmitButton,
  TextFieldWithError
} from '../../../../Forms/FormComponents';
import { convertNullToString } from '../../../../../helpers/utilities';
import withValidation from '../../../../FieldWithError/FieldWithError';
import CountrySelect from '../../../../EntitySelectFields/CountrySelect/CountrySelectContainer';
import StateProvinceSelect from '../../../../EntitySelectFields/StateProvinceSelect/StateProvinceSelectContainer';
import InstitutionTypeSelect from '../../../../EntitySelectFields/InstitutionTypeSelect/InstitutionTypeSelectContainer';
import InstitutionSubtypeSelect from '../../../../EntitySelectFields/InstitutionSubtypeSelect/InstitutionSubtypeSelectContainer';
import InstitutionalSystemSelect from '../../../../EntitySelectFields/InstitutionalSystemSelect/InstitutionalSystemSelectContainer';
import SelectUserFieldContainer from '../../../../SelectUserField/SelectUserFieldContainer';

const Layout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`;

const SelectUserFieldWithError = withValidation(SelectUserFieldContainer);

const InstitutionForm = ({
  hasSystemParent,
  users,
  institution,
  locations,
  buttonText,
  isEditForm,
  handleChange,
  handleUserFieldChange,
  onSubmit,
  closeForm,
  showValidationErrors,
  validateName,
  validateCity,
  validateInstitutionCode
}) => {
  const {
    name,
    city,
    institutionCode,
    selectedCountryUuid,
    stateProvinceUuid,
    selectedInstitutionTypeUuid,
    institutionalSystemUuid,
    institutionSubtypeUuid
  } = institution;

  const showFieldsOnly = hasSystemParent || isEditForm;
  const userOptions = users
    ? users.map(user => ({
        value: user.uuid,
        label: `${user.firstName} ${user.lastName}`
      }))
    : [];

  const canSelectSystem = stateProvinceUuid && institutionSubtypeUuid;

  return (
    <Layout>
      <FormTop>
        <FormRow>
          <Column size="50%" paddingRight="1rem">
            {!showFieldsOnly ? (
              <CountrySelect
                selectedCountry={selectedCountryUuid}
                showValidationErrors={showValidationErrors}
                handleCountryChange={handleChange}
              />
            ) : (
              <>
                {locations.country ? (
                  <CountrySelect
                    selectedCountry={locations.country.uuid}
                    showValidationErrors={showValidationErrors}
                    handleCountryChange={handleChange}
                    disabled
                  />
                ) : (
                  'No Country Selected'
                )}
              </>
            )}
          </Column>
          <Column size="50%" paddingLeft="1rem">
            {showFieldsOnly ? (
              <>
                {locations.stateProvince ? (
                  <StateProvinceSelect
                    country={{ uuid: locations.country.uuid }}
                    selectedStateProvince={stateProvinceUuid}
                    showValidationErrors={showValidationErrors}
                    handleChange={handleChange}
                    disabled
                  />
                ) : (
                  'No State Province Selected'
                )}
              </>
            ) : (
              <>
                {!selectedCountryUuid ? (
                  <Tooltip title="Select a country first">
                    <span>
                      <StateProvinceSelect
                        country={{ uuid: selectedCountryUuid }}
                        selectedStateProvince={stateProvinceUuid}
                        showValidationErrors={showValidationErrors}
                        handleChange={handleChange}
                      />
                    </span>
                  </Tooltip>
                ) : (
                  <StateProvinceSelect
                    country={{ uuid: selectedCountryUuid }}
                    selectedStateProvince={stateProvinceUuid}
                    showValidationErrors={showValidationErrors}
                    handleChange={handleChange}
                  />
                )}
              </>
            )}
          </Column>
        </FormRow>
        <FormRow>
          <Column size="50%" paddingRight="1rem">
            <InstitutionTypeSelect
              selectedInstitutionType={selectedInstitutionTypeUuid}
              showValidationErrors={showValidationErrors}
              handleChange={handleChange}
            />
          </Column>
          <Column size="50%" paddingLeft="1rem">
            {!isEditForm && !selectedInstitutionTypeUuid ? (
              <Tooltip title="Select a type first">
                <span>
                  <InstitutionSubtypeSelect
                    institutionType={{ uuid: selectedInstitutionTypeUuid }}
                    selectedInstitutionSubtype={institutionSubtypeUuid}
                    showValidationErrors={showValidationErrors}
                    handleChange={handleChange}
                  />
                </span>
              </Tooltip>
            ) : (
              <InstitutionSubtypeSelect
                institutionType={{ uuid: selectedInstitutionTypeUuid }}
                selectedInstitutionSubtype={institutionSubtypeUuid}
                showValidationErrors={showValidationErrors}
                handleChange={handleChange}
              />
            )}
          </Column>
        </FormRow>
        <Divider />
        <FormRow>
          <Column size="50%" paddingRight="1rem">
            <TextFieldWithError
              id="institutionCode"
              name="institutionCode"
              value={convertNullToString(institutionCode)}
              label="Institution Code"
              onChange={event => handleChange(event)}
              margin="normal"
              variant="outlined"
              validate={validateInstitutionCode}
              showValidationErrors={showValidationErrors}
            />
          </Column>
          <Column size="50%" paddingLeft="1rem">
            <TextFieldWithError
              id="institutionName"
              name="name"
              value={name}
              label="Institution Name"
              onChange={event => handleChange(event)}
              margin="normal"
              variant="outlined"
              validate={validateName}
              showValidationErrors={showValidationErrors}
            />
          </Column>
        </FormRow>
        <FormRow>
          <Column size="50%" paddingRight="1rem">
            <TextFieldWithError
              id="institutionCity"
              name="city"
              value={convertNullToString(city)}
              label="City"
              onChange={event => handleChange(event)}
              margin="normal"
              variant="outlined"
              validate={validateCity}
              showValidationErrors={showValidationErrors}
            />
          </Column>
          <Column size="50%" paddingLeft="1rem">
            {!isEditForm && !canSelectSystem ? (
              <Tooltip title="Select a state/province and subtype first">
                <span>
                  <InstitutionalSystemSelect
                    institutionSubtype={{ uuid: institutionSubtypeUuid }}
                    stateProvince={{ uuid: stateProvinceUuid }}
                    selectedInstitutionalSystem={institutionalSystemUuid}
                    handleChange={handleChange}
                  />
                </span>
              </Tooltip>
            ) : (
              <InstitutionalSystemSelect
                institutionSubtype={{ uuid: institutionSubtypeUuid }}
                stateProvince={{ uuid: stateProvinceUuid }}
                selectedInstitutionalSystem={institutionalSystemUuid}
                handleChange={handleChange}
              />
            )}
          </Column>
        </FormRow>
        <FormRow marginBottom="0">
          <Column size="50%" paddingRight="1rem">
            <SelectUserFieldWithError
              name="institutionAdmins"
              userSelect
              fieldName="institutionAdmins"
              label="Institution Admins*"
              handleChange={handleUserFieldChange}
              usersArray={userOptions}
              placeholder="Select"
              values={institution.institutionAdmins}
            />
          </Column>
        </FormRow>
      </FormTop>
      <FormBottom>
        <CancelButton onClick={closeForm} noHoverBg>
          Cancel
        </CancelButton>
        <SubmitButton onClick={onSubmit}>{buttonText}</SubmitButton>
      </FormBottom>
    </Layout>
  );
};

InstitutionForm.propTypes = {
  institution: PropTypes.object,
  locations: PropTypes.object,
  buttonText: PropTypes.string,
  isEditForm: PropTypes.bool,
  showValidationErrors: PropTypes.bool,
  hasSystemParent: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  users: PropTypes.arrayOf(PropTypes.object),
  handleChange: PropTypes.func,
  handleUserFieldChange: PropTypes.func,
  onSubmit: PropTypes.func,
  closeForm: PropTypes.func,
  validateName: PropTypes.func.isRequired,
  validateCity: PropTypes.func.isRequired,
  validateInstitutionCode: PropTypes.func.isRequired
};

InstitutionForm.defaultProps = {
  institution: {},
  locations: {
    country: {},
    stateProvince: {}
  },
  buttonText: 'Create',
  showValidationErrors: false,
  hasSystemParent: false,
  isEditForm: false,
  users: [],
  handleChange: undefined,
  closeForm: undefined,
  handleUserFieldChange: undefined,
  onSubmit: undefined
};

export default InstitutionForm;
