import React from 'react';
import styled from 'styled-components';
import { Heading1, Subtitle2 } from '@xcomp/xcomp-design-library';
import NotFoundIllustration from '@xcomp/xcomp-design-library/dist/illustrations/NotFoundIllustration';
import { Page } from '../DefinitionDashboards/SharedSC/DashboardSC';

const PageSC = styled(Page)`
  height: calc(100vh - 112px);
`;

const GiantNumber = styled.span`
  && {
    width: 100%;
    display: flex;
    justify-content: center;
    color: ${props => props.theme.colors.primary};
    font-size: 9rem;
    font-weight: bold;
    text-shadow: 4px 3px 0px ${props => props.theme.colors.white},
      9px 8px 0px ${props => props.theme.colors.primaryShades[150]};
  }
`;

const NotFoundContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 112px);
`;

const CenteredContent = styled.div``;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Heading1SC = styled(Heading1)`
  margin: 0.5rem 0;
`;

const Icon = styled.div`
  margin-left: 3rem;
`;

const NoMatch404 = () => (
  <PageSC>
    <NotFoundContainer>
      <CenteredContent>
        <GiantNumber>
          404
          <Icon>
            <NotFoundIllustration />
          </Icon>
        </GiantNumber>

        <TextContainer>
          <Heading1SC>Oops!</Heading1SC>
          <Subtitle2>
            We can&lsquo;t seem to find the page you are looking for.
          </Subtitle2>
        </TextContainer>
      </CenteredContent>
    </NotFoundContainer>
  </PageSC>
);

export default NoMatch404;
