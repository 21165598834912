import { call, put } from 'redux-saga/effects';

import {
  getProgramDisciplines,
  postProgramDiscipline,
  putProgramDiscipline,
  deleteProgramDiscipline
} from '../../api/programDisciplinesApi';
import {
  doGetProgramDisciplinesSuccess,
  doGetProgramDisciplinesError,
  doPostProgramDisciplineSuccess,
  doPostProgramDisciplineError,
  doPutProgramDisciplineSuccess,
  doPutProgramDisciplineError,
  doDeleteProgramDisciplineSuccess,
  doDeleteProgramDisciplineError
} from '../actions/programDisciplineActions';

function* handleFetchProgramDisciplines(action) {
  const { cipcodeUuid } = action.payload;
  try {
    const result = yield call(getProgramDisciplines, cipcodeUuid);
    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doGetProgramDisciplinesSuccess(payload));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doGetProgramDisciplinesError(error));
  }
}

function* handlePostProgramDisciplines(action) {
  const { newProgramDiscipline } = action.payload;
  try {
    const result = yield call(postProgramDiscipline, newProgramDiscipline);
    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doPostProgramDisciplineSuccess(payload));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(doPostProgramDisciplineError(error));
  }
}

function* handlePutUpdateProgramDiscipline(action) {
  const { programDisciplineUpdates } = action.payload;

  try {
    const result = yield call(putProgramDiscipline, programDisciplineUpdates);

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doPutProgramDisciplineSuccess(payload));
    } else {
      const errorText = yield result.text();
      yield put(
        doPutProgramDisciplineError({
          text: errorText
        })
      );
    }
  } catch (error) {
    yield put(doPutProgramDisciplineError());
  }
}

function* handleDeleteProgramDiscipline(action) {
  const { uuid } = action.payload;

  try {
    const result = yield call(deleteProgramDiscipline, uuid);

    if (result.status === 200) {
      const payload = yield result.json();

      yield put(doDeleteProgramDisciplineSuccess(payload));
    } else {
      const errorText = yield result.text();
      yield put(
        doDeleteProgramDisciplineError({
          text: errorText
        })
      );
    }
  } catch (error) {
    yield put(doDeleteProgramDisciplineError());
  }
}

export {
  handleFetchProgramDisciplines,
  handlePostProgramDisciplines,
  handlePutUpdateProgramDiscipline,
  handleDeleteProgramDiscipline
};
