import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { doGetLocations } from '../../../redux/actions/locationActions';
import { makeCountryStateProvincesPublishedSelector } from '../../../redux/selectors/locationsSelectors';
import StateProvinceSelect from './StateProvinceSelect';

class StateProvinceSelectContainer extends Component {
  componentDidMount() {
    const { stateProvinces } = this.props;
    if (!stateProvinces || stateProvinces.length < 1) {
      this.props.onFetchLocations();
    }
  }

  render() {
    const {
      disabled,
      stateProvinces,
      selectedStateProvince,
      showValidationErrors,
      handleChange,
      className
    } = this.props;
    return (
      <StateProvinceSelect
        className={className}
        disabled={disabled}
        stateProvinces={stateProvinces}
        selectedStateProvince={selectedStateProvince}
        showValidationErrors={showValidationErrors}
        handleChange={handleChange}
      />
    );
  }
}

StateProvinceSelectContainer.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  stateProvinces: PropTypes.arrayOf(PropTypes.object),
  showValidationErrors: PropTypes.bool,
  selectedStateProvince: PropTypes.string,
  onFetchLocations: PropTypes.func,
  handleChange: PropTypes.func
};

StateProvinceSelectContainer.defaultProps = {
  className: '',
  disabled: false,
  stateProvinces: [],
  showValidationErrors: false,
  selectedStateProvince: '',
  onFetchLocations: undefined,
  handleChange: undefined
};

const makeMapStateToProps = () => {
  const getCountryStateProvinces = makeCountryStateProvincesPublishedSelector();
  const mapStateToProps = (state, props) => ({
    stateProvinces: getCountryStateProvinces(state, props)
  });
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => ({
  onFetchLocations: () => dispatch(doGetLocations())
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(StateProvinceSelectContainer);
export { StateProvinceSelectContainer };
