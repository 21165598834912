import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import Select from 'react-select';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { blue } from '@material-ui/core/colors';

const selectUserFieldStyles = theme => ({
  root: {
    flexGrow: 1,
    height: 'auto'
  },
  input: {
    display: 'flex',
    minHeight: '48px',
    padding: '4px 14px'
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center'
  },
  chip: {
    margin: '.5em 1em .5em 0',
    backgroundColor: blue
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  placeholder: {
    position: 'absolute',
    left: '14px',
    fontSize: 16
  },
  paper: {
    marginTop: theme.spacing(1)
  },
  divider: {
    height: theme.spacing(2)
  }
});

const NoOptionsMessage = props => (
  <Typography
    color="textSecondary"
    className={props.selectProps.classes.noOptionsMessage}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
);

NoOptionsMessage.propTypes = {
  selectProps: PropTypes.object,
  innerProps: PropTypes.object,
  children: PropTypes.node
};

NoOptionsMessage.defaultProps = {
  selectProps: {},
  innerProps: {},
  children: <div />
};

const inputComponent = ({ inputRef, ...props }) => (
  <div ref={inputRef} {...props} />
);

inputComponent.propTypes = {
  inputRef: PropTypes.func
};

inputComponent.defaultProps = {
  inputRef: undefined
};

const Control = props => (
  <TextField
    fullWidth
    InputProps={{
      inputComponent,
      inputProps: {
        className: props.selectProps.classes.input,
        inputRef: props.innerRef,
        children: props.children,
        ...props.innerProps
      }
    }}
    variant="outlined"
    {...props.selectProps.textFieldProps}
  />
);

Control.propTypes = {
  selectProps: PropTypes.object,
  inputProps: PropTypes.object,
  innerProps: PropTypes.object,
  innerRef: PropTypes.func,
  children: PropTypes.node
};

Control.defaultProps = {
  selectProps: {},
  inputProps: {},
  innerProps: {},
  innerRef: undefined,
  children: <div />
};

const Option = props => (
  <MenuItem
    buttonRef={props.innerRef}
    selected={props.isFocused}
    component="div"
    style={{
      fontWeight: props.isSelected ? 500 : 400
    }}
    {...props.innerProps}
  >
    {props.children}
  </MenuItem>
);

Option.propTypes = {
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  innerProps: PropTypes.object,
  innerRef: PropTypes.func,
  children: PropTypes.node
};

Option.defaultProps = {
  isFocused: false,
  isSelected: false,
  innerProps: {},
  innerRef: undefined,
  children: <div />
};

const Placeholder = props => (
  <Typography
    color="textSecondary"
    className={props.selectProps.classes.placeholder}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
);

Placeholder.propTypes = {
  selectProps: PropTypes.object,
  innerProps: PropTypes.func,
  children: PropTypes.node
};

Placeholder.defaultProps = {
  selectProps: {},
  innerProps: undefined,
  children: <div />
};

const ValueContainer = props => (
  <div className={props.selectProps.classes.valueContainer}>
    {props.children}
  </div>
);

ValueContainer.propTypes = {
  selectProps: PropTypes.object,
  children: PropTypes.node
};

ValueContainer.defaultProps = {
  selectProps: {},
  children: <div />
};

const ChipSC = styled(Chip)`
  && {
    margin: 0.5rem 0.5rem 0.5rem 0;
    background-color: ${props => props.theme.colors.primaryShades[50]};

    &:hover {
      background-color: ${props => props.theme.colors.primaryShades[50]};
    }
  }
`;

const CancelOutlinedSC = styled(CancelOutlined)`
  && {
    width: 18px;
    height: auto;
  }
`;

const MultiValue = props => (
  <ChipSC
    size="small"
    deleteIcon={<CancelOutlinedSC {...props.removeProps} />}
    tabIndex={-1}
    label={props.children}
    onDelete={props.removeProps.onClick}
  />
);

MultiValue.propTypes = {
  children: PropTypes.node,
  removeProps: PropTypes.object
};

MultiValue.defaultProps = {
  children: <div />,
  removeProps: {}
};

const MenuAbsolute = styled(Paper)`
  position: absolute;
  top: 48px;
  width: 100%;
  height: 200;
  left: 0;
`;

const Menu = props => (
  <MenuAbsolute
    id="menu"
    maxMenuHeight="150px"
    square
    className={props.selectProps.classes.paper}
    {...props.innerProps}
  >
    {props.children}
  </MenuAbsolute>
);

Menu.propTypes = {
  selectProps: PropTypes.object,
  innerProps: PropTypes.object,
  children: PropTypes.node
};

Menu.defaultProps = {
  selectProps: {},
  innerProps: {},
  children: <div />
};

const Layout = styled.div`
  min-height: 150px;
`;

const components = {
  Option,
  Control,
  NoOptionsMessage,
  Placeholder,
  MultiValue,
  ValueContainer,
  Menu
};

const SelectUserField = props => {
  const {
    classes,
    theme,
    usersArray,
    value,
    handleChange,
    label,
    placeholder,
    error,
    className,
    singleValue,
    children,
    ...rest
  } = props;

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary
    })
  };

  const SelectSC = styled(Select)`
    position: relative;
  `;

  return (
    <Layout className={`${classes.root} ${className}`}>
      <NoSsr>
        <SelectSC
          classes={classes}
          styles={selectStyles}
          id="selectUserField"
          textFieldProps={{
            label,
            error,
            InputLabelProps: {
              shrink: true
            }
          }}
          options={usersArray}
          components={components}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          isMulti={!singleValue}
          maxMenuHeight={150}
          {...rest}
        />
      </NoSsr>
      {children && children}
    </Layout>
  );
};

SelectUserField.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  usersArray: PropTypes.array,
  value: oneOfType([PropTypes.object, PropTypes.array]),
  handleChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: oneOfType([PropTypes.bool, PropTypes.object]),
  className: PropTypes.string,
  singleValue: PropTypes.bool,
  children: PropTypes.node
};

SelectUserField.defaultProps = {
  usersArray: [],
  value: '',
  handleChange: undefined,
  label: '',
  placeholder: '',
  error: false,
  className: '',
  singleValue: false,
  children: <div />
};

export default withStyles(selectUserFieldStyles, { withTheme: true })(
  SelectUserField
);
export { SelectUserField };
