import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { types } from '../../../../redux/reducers/moveCodeReducer';
import AccordionRowContainer from '../../../AccordionRow/AccordionRowContainer';
import { sortItemsByLevelCodes } from '../../../../helpers/arrayHelpers';

const RowSC = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  background: ${props => props.theme.colors.white};
`;

const L2Selection = ({
  L2Siblings,
  codeType,
  codeToMove,
  destinationCode,
  parentCodeString,
  onCodeSelect
}) => (
  <div>
    {(() => {
      switch (codeType) {
        case types.micro:
          return L2Siblings.sort(sortItemsByLevelCodes).map(sibling => {
            const isCodeToMove =
              codeToMove && sibling && codeToMove.uuid === sibling.uuid;
            return (
              <RowSC key={sibling.uuid}>
                <AccordionRowContainer
                  label="Microcompetency"
                  columnOne={`${parentCodeString}.${sibling.level_one_code}${
                    sibling.level_two_code
                  }`}
                  columnTwo={sibling.title}
                  height="38px"
                  columnGutters="1.5rem"
                  isSelected={
                    destinationCode && destinationCode.uuid === sibling.uuid
                  }
                  disabled={isCodeToMove}
                  onSelect={() =>
                    onCodeSelect(
                      sibling.uuid,
                      `${parentCodeString}.${sibling.level_one_code}${
                        sibling.level_two_code
                      }`,
                      false,
                      false
                    )
                  }
                  leaf
                />
              </RowSC>
            );
          });
        case types.subtopic:
          return L2Siblings.sort(sortItemsByLevelCodes).map(sibling => {
            const isCodeToMove =
              codeToMove && sibling && codeToMove.uuid === sibling.uuid;
            return (
              <RowSC key={sibling.uuid}>
                <AccordionRowContainer
                  label="Subtopic"
                  columnOne={`${parentCodeString}.${sibling.level_one_code}${
                    sibling.level_two_code
                  }`}
                  columnTwo={sibling.title}
                  height="38px"
                  columnGutters="1.5rem"
                  isSelected={
                    destinationCode && destinationCode.uuid === sibling.uuid
                  }
                  disabled={isCodeToMove}
                  onSelect={() =>
                    onCodeSelect(
                      sibling.uuid,
                      `${parentCodeString}.${sibling.level_one_code}${
                        sibling.level_two_code
                      }`,
                      false,
                      false
                    )
                  }
                  leaf
                />
              </RowSC>
            );
          });
        case types.topic:
          return L2Siblings.sort(sortItemsByLevelCodes).map(sibling => {
            const isCodeToMove =
              codeToMove && sibling && codeToMove.uuid === sibling.uuid;
            return (
              <RowSC key={sibling.uuid}>
                <AccordionRowContainer
                  label="Subtopic"
                  columnOne={`${parentCodeString}.${sibling.level_one_code}${
                    sibling.level_two_code
                  }`}
                  columnTwo={sibling.title}
                  height="38px"
                  columnGutters="1.5rem"
                  isSelected={
                    destinationCode && destinationCode.uuid === sibling.uuid
                  }
                  disabled={isCodeToMove}
                  onSelect={() =>
                    onCodeSelect(
                      sibling.uuid,
                      `${parentCodeString}.${sibling.level_one_code}${
                        sibling.level_two_code
                      }`,
                      false,
                      false
                    )
                  }
                  leaf
                />
              </RowSC>
            );
          });
        default:
          return <RowSC />;
      }
    })()}
  </div>
);

L2Selection.propTypes = {
  L2Siblings: PropTypes.arrayOf(PropTypes.object),
  codeToMove: PropTypes.object,
  codeType: PropTypes.oneOf([types.topic, types.subtopic, types.micro, '']),
  parentCodeString: PropTypes.string,
  destinationCode: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  onCodeSelect: PropTypes.func
};

L2Selection.defaultProps = {
  L2Siblings: null,
  codeToMove: {
    title: '',
    code: ''
  },
  codeType: '',
  parentCodeString: '',
  destinationCode: false,
  onCodeSelect: undefined
};
export default L2Selection;
